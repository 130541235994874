import type { AccountsMetadata } from '@/shared/graphql/scalars';
import type { BreakoutsPlatformSettings } from '@/shared/graphql/scalars';
import type { ChannelEvent } from '@/shared/graphql/scalars';
import type { ChannelUserStatusPlatformStates } from '@/shared/graphql/scalars';
import type { ChannelsMute } from '@/shared/graphql/scalars';
import type { ClipboardPrompt } from '@/shared/graphql/scalars';
import type { ClubsDisplaySettings } from '@/shared/graphql/scalars';
import type { ClubsOnboarding } from '@/shared/graphql/scalars';
import type { CompetitionsMeta } from '@/shared/graphql/scalars';
import type { CompetitionsPrizeMeta } from '@/shared/graphql/scalars';
import type { FeedType } from '@/shared/graphql/scalars';
import type { FeedbackSource } from '@/shared/graphql/scalars';
import type { NotificationType } from '@/shared/graphql/scalars';
import type { OtpsData } from '@/shared/graphql/scalars';
import type { ProjectsImages } from '@/shared/graphql/scalars';
import type { CompetitionsPrize } from '@/shared/graphql/scalars';
import type { RoomsHostUserIds } from '@/shared/graphql/scalars';
import type { SchedulesSchedule } from '@/shared/graphql/scalars';
import type { UserAction } from '@/shared/graphql/scalars';
import type { UserFeedItemAuthor } from '@/shared/graphql/scalars';
import type { UsersMeta } from '@/shared/graphql/scalars';
import type { UsersPresenceMeta } from '@/shared/graphql/scalars';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  accounts_metadata: { input: AccountsMetadata; output: AccountsMetadata; }
  bigint: { input: number; output: number; }
  bpchar: { input: string; output: string; }
  breakouts_platform_settings: { input: BreakoutsPlatformSettings; output: BreakoutsPlatformSettings; }
  channel_event: { input: ChannelEvent; output: ChannelEvent; }
  channel_user_status_platform_states: { input: ChannelUserStatusPlatformStates; output: ChannelUserStatusPlatformStates; }
  channels_mute: { input: ChannelsMute; output: ChannelsMute; }
  clipboard_prompt: { input: ClipboardPrompt; output: ClipboardPrompt; }
  clubs_display_settings: { input: ClubsDisplaySettings; output: ClubsDisplaySettings; }
  clubs_onboarding: { input: ClubsOnboarding; output: ClubsOnboarding; }
  competitions_meta: { input: CompetitionsMeta; output: CompetitionsMeta; }
  competitions_prize_meta: { input: CompetitionsPrizeMeta; output: CompetitionsPrizeMeta; }
  date: { input: string; output: string; }
  feed_type: { input: FeedType; output: FeedType; }
  feedback_source: { input: FeedbackSource; output: FeedbackSource; }
  json: { input: unknown; output: unknown; }
  jsonb: { input: unknown; output: unknown; }
  notification_type: { input: NotificationType; output: NotificationType; }
  numeric: { input: number; output: number; }
  otps_data: { input: OtpsData; output: OtpsData; }
  projects_images: { input: ProjectsImages; output: ProjectsImages; }
  projects_prize: { input: CompetitionsPrize; output: CompetitionsPrize; }
  rooms_host_user_ids: { input: RoomsHostUserIds; output: RoomsHostUserIds; }
  schedules_schedule: { input: SchedulesSchedule; output: SchedulesSchedule; }
  timestamptz: { input: string; output: string; }
  user_action: { input: UserAction; output: UserAction; }
  user_feed_item_author: { input: UserFeedItemAuthor; output: UserFeedItemAuthor; }
  users_meta: { input: UsersMeta; output: UsersMeta; }
  users_presence_meta: { input: UsersPresenceMeta; output: UsersPresenceMeta; }
  uuid: { input: string; output: string; }
};

export type AcceptTeamInviteOutput = {
  readonly __typename?: 'AcceptTeamInviteOutput';
  readonly accepted: Scalars['Boolean']['output'];
};

export type BanChannelUserOutput = {
  readonly __typename?: 'BanChannelUserOutput';
  readonly banned: Scalars['Boolean']['output'];
};

export type BookOnboardingOutput = {
  readonly __typename?: 'BookOnboardingOutput';
  readonly roomId?: Maybe<Scalars['uuid']['output']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  readonly _eq?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _gt?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _gte?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['Boolean']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lt?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lte?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['Boolean']['input']>>;
};

export type FeedItem = {
  readonly __typename?: 'FeedItem';
  readonly association_id: Scalars['String']['output'];
  readonly author: Scalars['user_feed_item_author']['output'];
  readonly comments: ReadonlyArray<FeedItemComments>;
  readonly comments_aggregate: FeedItemAggregate;
  readonly created_at: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly payload: Scalars['jsonb']['output'];
  readonly type: Scalars['feed_type']['output'];
  readonly user_vote: ReadonlyArray<FeedItemUserVote>;
  readonly votes_aggregate: FeedItemAggregate;
};

export type FeedItemAggregate = {
  readonly __typename?: 'FeedItemAggregate';
  readonly aggregate?: Maybe<FeedItemAggregateCount>;
};

export type FeedItemAggregateCount = {
  readonly __typename?: 'FeedItemAggregateCount';
  readonly count: Scalars['Int']['output'];
};

export type FeedItemCommentUser = {
  readonly __typename?: 'FeedItemCommentUser';
  readonly first_name?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly last_name?: Maybe<Scalars['String']['output']>;
  readonly meta: Scalars['users_meta']['output'];
  readonly photo: Scalars['String']['output'];
  readonly username?: Maybe<Scalars['String']['output']>;
};

export type FeedItemComments = {
  readonly __typename?: 'FeedItemComments';
  readonly content: Scalars['String']['output'];
  readonly created_at: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly user: FeedItemCommentUser;
  readonly user_vote: ReadonlyArray<FeedItemUserVote>;
  readonly votes_aggregate: FeedItemAggregate;
};

export type FeedItemUserVote = {
  readonly __typename?: 'FeedItemUserVote';
  readonly id: Scalars['String']['output'];
};

export type GetAllAvatarsOutput = {
  readonly __typename?: 'GetAllAvatarsOutput';
  readonly avatars?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  readonly _eq?: InputMaybe<Scalars['Int']['input']>;
  readonly _gt?: InputMaybe<Scalars['Int']['input']>;
  readonly _gte?: InputMaybe<Scalars['Int']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['Int']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lt?: InputMaybe<Scalars['Int']['input']>;
  readonly _lte?: InputMaybe<Scalars['Int']['input']>;
  readonly _neq?: InputMaybe<Scalars['Int']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['Int']['input']>>;
};

export type IssueTransactionsOutput = {
  readonly __typename?: 'IssueTransactionsOutput';
  readonly insert_transactions?: Maybe<Scalars['jsonb']['output']>;
  readonly insert_users?: Maybe<Scalars['jsonb']['output']>;
  readonly insert_users_levels?: Maybe<Scalars['jsonb']['output']>;
};

export type JoinActivityOutput = {
  readonly __typename?: 'JoinActivityOutput';
  readonly callAccessToken?: Maybe<Scalars['String']['output']>;
  readonly channelId: Scalars['String']['output'];
};

export type JoinBreakoutOutput = {
  readonly __typename?: 'JoinBreakoutOutput';
  readonly callAccessToken?: Maybe<Scalars['String']['output']>;
  readonly channelId: Scalars['String']['output'];
};

export type JoinCompetitionOutput = {
  readonly __typename?: 'JoinCompetitionOutput';
  readonly channel_id: Scalars['String']['output'];
  readonly match_username?: Maybe<Scalars['String']['output']>;
  readonly matched: Scalars['Boolean']['output'];
};

export type JoinRoomOutput = {
  readonly __typename?: 'JoinRoomOutput';
  readonly callAccessToken?: Maybe<Scalars['String']['output']>;
  readonly channelId: Scalars['String']['output'];
};

export type LeaveCompetitionOutput = {
  readonly __typename?: 'LeaveCompetitionOutput';
  readonly affected_rows: Scalars['Int']['output'];
};

export type MatchmakingOutput = {
  readonly __typename?: 'MatchmakingOutput';
  readonly matchmaking?: Maybe<Scalars['Boolean']['output']>;
  readonly username?: Maybe<Scalars['String']['output']>;
};

export type PublishToMinecraftOutput = {
  readonly __typename?: 'PublishToMinecraftOutput';
  readonly success: Scalars['Boolean']['output'];
};

export type SendFriendRequestOutput = {
  readonly __typename?: 'SendFriendRequestOutput';
  readonly friendship_id: Scalars['String']['output'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  readonly _contained_in?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** does the array contain the given value */
  readonly _contains?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly _eq?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly _gt?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly _gte?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly _in?: InputMaybe<ReadonlyArray<ReadonlyArray<Scalars['String']['input']>>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lt?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly _lte?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly _neq?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly _nin?: InputMaybe<ReadonlyArray<ReadonlyArray<Scalars['String']['input']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  readonly _eq?: InputMaybe<Scalars['String']['input']>;
  readonly _gt?: InputMaybe<Scalars['String']['input']>;
  readonly _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  readonly _ilike?: InputMaybe<Scalars['String']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  readonly _iregex?: InputMaybe<Scalars['String']['input']>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  readonly _like?: InputMaybe<Scalars['String']['input']>;
  readonly _lt?: InputMaybe<Scalars['String']['input']>;
  readonly _lte?: InputMaybe<Scalars['String']['input']>;
  readonly _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  readonly _nilike?: InputMaybe<Scalars['String']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  readonly _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  readonly _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  readonly _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  readonly _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  readonly _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  readonly _similar?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitChatMessageOutput = {
  readonly __typename?: 'SubmitChatMessageOutput';
  readonly createdAt?: Maybe<Scalars['String']['output']>;
  readonly error?: Maybe<Scalars['String']['output']>;
  readonly message?: Maybe<Scalars['String']['output']>;
};

export type ToggleRoomHostIdOutput = {
  readonly __typename?: 'ToggleRoomHostIdOutput';
  readonly room_host_user_ids?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly success: Scalars['Boolean']['output'];
};

export type Transaction = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly item_id?: InputMaybe<Scalars['String']['input']>;
  readonly kind?: InputMaybe<Transaction_Kind>;
  readonly order_id?: InputMaybe<Scalars['String']['input']>;
  readonly payload?: InputMaybe<Scalars['jsonb']['input']>;
  readonly quantity?: InputMaybe<Scalars['numeric']['input']>;
  readonly recipient_id?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

export type UpdateChannelMuteEnabledInput = {
  readonly channelId: Scalars['uuid']['input'];
  readonly cooldown: Scalars['Int']['input'];
  readonly muteEnabled: Scalars['Boolean']['input'];
};

export type UpdateChannelMuteEnabledOutput = {
  readonly __typename?: 'UpdateChannelMuteEnabledOutput';
  readonly message?: Maybe<Scalars['String']['output']>;
  readonly mute?: Maybe<Scalars['jsonb']['output']>;
  readonly success: Scalars['Boolean']['output'];
};

export type UpdateChannelUserPermissionsOutput = {
  readonly __typename?: 'UpdateChannelUserPermissionsOutput';
  readonly updated: Scalars['Boolean']['output'];
};

export type UpdateChannelsUserForceMutedOutput = {
  readonly __typename?: 'UpdateChannelsUserForceMutedOutput';
  readonly success: Scalars['Boolean']['output'];
};

export type UpdateHandRaisedOutput = {
  readonly __typename?: 'UpdateHandRaisedOutput';
  readonly updated: Scalars['Boolean']['output'];
};

export type UpdatePasswordOutput = {
  readonly __typename?: 'UpdatePasswordOutput';
  readonly id: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
};

export type UpdateUserClubPreferencesOutput = {
  readonly __typename?: 'UpdateUserClubPreferencesOutput';
  readonly updated: Scalars['Boolean']['output'];
};

export type UpsertClubOutput = {
  readonly __typename?: 'UpsertClubOutput';
  readonly errors?: Maybe<UpsertClubOutputErrors>;
  readonly success: Scalars['Boolean']['output'];
};

export type UpsertClubOutputErrors = {
  readonly __typename?: 'UpsertClubOutputErrors';
  readonly backgroundImage?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly navigationColor?: Maybe<Scalars['String']['output']>;
  readonly navigationIcon?: Maybe<Scalars['String']['output']>;
  readonly navigationImage?: Maybe<Scalars['String']['output']>;
  readonly privacy?: Maybe<Scalars['String']['output']>;
};

export type UpsertRoomErrors = {
  readonly __typename?: 'UpsertRoomErrors';
  readonly clipboardPrompt?: Maybe<Scalars['String']['output']>;
  readonly clubId?: Maybe<Scalars['String']['output']>;
  readonly feedbackQuestion?: Maybe<Scalars['String']['output']>;
  readonly iconUrl?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly lockTime?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly privacy?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly unlockTime?: Maybe<Scalars['String']['output']>;
};

export type UpsertRoomOutput = {
  readonly __typename?: 'UpsertRoomOutput';
  readonly errors?: Maybe<UpsertRoomErrors>;
  readonly message: Scalars['String']['output'];
};

export type UpsertScheduleOutput = {
  readonly __typename?: 'UpsertScheduleOutput';
  readonly message?: Maybe<Scalars['String']['output']>;
  readonly success: Scalars['Boolean']['output'];
};

/** columns and relationships of "accounts" */
export type Accounts = {
  readonly __typename?: 'accounts';
  readonly avatar_url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly club: Clubs;
  readonly club_id: Scalars['uuid']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['String']['output'];
  readonly metadata: Scalars['accounts_metadata']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
  readonly username: Scalars['String']['output'];
};


/** columns and relationships of "accounts" */
export type AccountsMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "accounts" */
export type Accounts_Aggregate = {
  readonly __typename?: 'accounts_aggregate';
  readonly aggregate?: Maybe<Accounts_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Accounts>;
};

export type Accounts_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Accounts_Aggregate_Bool_Exp_Count>;
};

export type Accounts_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Accounts_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Accounts_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "accounts" */
export type Accounts_Aggregate_Fields = {
  readonly __typename?: 'accounts_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Accounts_Max_Fields>;
  readonly min?: Maybe<Accounts_Min_Fields>;
};


/** aggregate fields of "accounts" */
export type Accounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "accounts" */
export type Accounts_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Accounts_Max_Order_By>;
  readonly min?: InputMaybe<Accounts_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Accounts_Append_Input = {
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "accounts" */
export type Accounts_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Accounts_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Accounts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "accounts". All fields are combined with a logical 'AND'. */
export type Accounts_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Accounts_Bool_Exp>>;
  readonly _not?: InputMaybe<Accounts_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Accounts_Bool_Exp>>;
  readonly avatar_url?: InputMaybe<String_Comparison_Exp>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly club_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
  readonly username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "accounts" */
export enum Accounts_Constraint {
  /** unique or primary key constraint on columns "id", "club_id" */
  AccountsPkey = 'accounts_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Accounts_Delete_At_Path_Input = {
  readonly metadata?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Accounts_Delete_Elem_Input = {
  readonly metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Accounts_Delete_Key_Input = {
  readonly metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "accounts" */
export type Accounts_Insert_Input = {
  readonly avatar_url?: InputMaybe<Scalars['String']['input']>;
  readonly club?: InputMaybe<Clubs_Obj_Rel_Insert_Input>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly metadata?: InputMaybe<Scalars['accounts_metadata']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Accounts_Max_Fields = {
  readonly __typename?: 'accounts_max_fields';
  readonly avatar_url?: Maybe<Scalars['String']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
  readonly username?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "accounts" */
export type Accounts_Max_Order_By = {
  readonly avatar_url?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly username?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Accounts_Min_Fields = {
  readonly __typename?: 'accounts_min_fields';
  readonly avatar_url?: Maybe<Scalars['String']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
  readonly username?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "accounts" */
export type Accounts_Min_Order_By = {
  readonly avatar_url?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly username?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "accounts" */
export type Accounts_Mutation_Response = {
  readonly __typename?: 'accounts_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Accounts>;
};

/** on_conflict condition type for table "accounts" */
export type Accounts_On_Conflict = {
  readonly constraint: Accounts_Constraint;
  readonly update_columns?: ReadonlyArray<Accounts_Update_Column>;
  readonly where?: InputMaybe<Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "accounts". */
export type Accounts_Order_By = {
  readonly avatar_url?: InputMaybe<Order_By>;
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly metadata?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: accounts */
export type Accounts_Pk_Columns_Input = {
  readonly club_id: Scalars['uuid']['input'];
  readonly id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Accounts_Prepend_Input = {
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "accounts" */
export enum Accounts_Select_Column {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "accounts" */
export type Accounts_Set_Input = {
  readonly avatar_url?: InputMaybe<Scalars['String']['input']>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly metadata?: InputMaybe<Scalars['accounts_metadata']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly username?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "accounts" */
export type Accounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Accounts_Stream_Cursor_Value_Input = {
  readonly avatar_url?: InputMaybe<Scalars['String']['input']>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly username?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "accounts" */
export enum Accounts_Update_Column {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Username = 'username'
}

export type Accounts_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Accounts_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Accounts_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Accounts_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Accounts_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Accounts_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Accounts_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Accounts_Bool_Exp;
};

/** Tracks the mapping between quests on our side and tasks on ASA's side for their EvolveMe platform. */
export type Asa_User_Quest_Task = {
  readonly __typename?: 'asa_user_quest_task';
  readonly asa_step_number: Scalars['Int']['output'];
  readonly asa_task_id: Scalars['String']['output'];
  readonly asa_user_id: Scalars['String']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['String']['output'];
  readonly quest_id: Scalars['uuid']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  readonly user_id: Scalars['String']['output'];
};

/** aggregated selection of "asa_user_quest_task" */
export type Asa_User_Quest_Task_Aggregate = {
  readonly __typename?: 'asa_user_quest_task_aggregate';
  readonly aggregate?: Maybe<Asa_User_Quest_Task_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Asa_User_Quest_Task>;
};

/** aggregate fields of "asa_user_quest_task" */
export type Asa_User_Quest_Task_Aggregate_Fields = {
  readonly __typename?: 'asa_user_quest_task_aggregate_fields';
  readonly avg?: Maybe<Asa_User_Quest_Task_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Asa_User_Quest_Task_Max_Fields>;
  readonly min?: Maybe<Asa_User_Quest_Task_Min_Fields>;
  readonly stddev?: Maybe<Asa_User_Quest_Task_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Asa_User_Quest_Task_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Asa_User_Quest_Task_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Asa_User_Quest_Task_Sum_Fields>;
  readonly var_pop?: Maybe<Asa_User_Quest_Task_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Asa_User_Quest_Task_Var_Samp_Fields>;
  readonly variance?: Maybe<Asa_User_Quest_Task_Variance_Fields>;
};


/** aggregate fields of "asa_user_quest_task" */
export type Asa_User_Quest_Task_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Asa_User_Quest_Task_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Asa_User_Quest_Task_Avg_Fields = {
  readonly __typename?: 'asa_user_quest_task_avg_fields';
  readonly asa_step_number?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "asa_user_quest_task". All fields are combined with a logical 'AND'. */
export type Asa_User_Quest_Task_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Asa_User_Quest_Task_Bool_Exp>>;
  readonly _not?: InputMaybe<Asa_User_Quest_Task_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Asa_User_Quest_Task_Bool_Exp>>;
  readonly asa_step_number?: InputMaybe<Int_Comparison_Exp>;
  readonly asa_task_id?: InputMaybe<String_Comparison_Exp>;
  readonly asa_user_id?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly quest_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "asa_user_quest_task" */
export enum Asa_User_Quest_Task_Constraint {
  /** unique or primary key constraint on columns "id" */
  AsaUserQuestTaskPkey = 'asa_user_quest_task_pkey',
  /** unique or primary key constraint on columns "quest_id", "user_id" */
  AsaUserQuestTaskUserIdQuestIdKey = 'asa_user_quest_task_user_id_quest_id_key'
}

/** input type for incrementing numeric columns in table "asa_user_quest_task" */
export type Asa_User_Quest_Task_Inc_Input = {
  readonly asa_step_number?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "asa_user_quest_task" */
export type Asa_User_Quest_Task_Insert_Input = {
  readonly asa_step_number?: InputMaybe<Scalars['Int']['input']>;
  readonly asa_task_id?: InputMaybe<Scalars['String']['input']>;
  readonly asa_user_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly quest_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Asa_User_Quest_Task_Max_Fields = {
  readonly __typename?: 'asa_user_quest_task_max_fields';
  readonly asa_step_number?: Maybe<Scalars['Int']['output']>;
  readonly asa_task_id?: Maybe<Scalars['String']['output']>;
  readonly asa_user_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly quest_id?: Maybe<Scalars['uuid']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Asa_User_Quest_Task_Min_Fields = {
  readonly __typename?: 'asa_user_quest_task_min_fields';
  readonly asa_step_number?: Maybe<Scalars['Int']['output']>;
  readonly asa_task_id?: Maybe<Scalars['String']['output']>;
  readonly asa_user_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly quest_id?: Maybe<Scalars['uuid']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "asa_user_quest_task" */
export type Asa_User_Quest_Task_Mutation_Response = {
  readonly __typename?: 'asa_user_quest_task_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Asa_User_Quest_Task>;
};

/** on_conflict condition type for table "asa_user_quest_task" */
export type Asa_User_Quest_Task_On_Conflict = {
  readonly constraint: Asa_User_Quest_Task_Constraint;
  readonly update_columns?: ReadonlyArray<Asa_User_Quest_Task_Update_Column>;
  readonly where?: InputMaybe<Asa_User_Quest_Task_Bool_Exp>;
};

/** Ordering options when selecting data from "asa_user_quest_task". */
export type Asa_User_Quest_Task_Order_By = {
  readonly asa_step_number?: InputMaybe<Order_By>;
  readonly asa_task_id?: InputMaybe<Order_By>;
  readonly asa_user_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly quest_id?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: asa_user_quest_task */
export type Asa_User_Quest_Task_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** select columns of table "asa_user_quest_task" */
export enum Asa_User_Quest_Task_Select_Column {
  /** column name */
  AsaStepNumber = 'asa_step_number',
  /** column name */
  AsaTaskId = 'asa_task_id',
  /** column name */
  AsaUserId = 'asa_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestId = 'quest_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "asa_user_quest_task" */
export type Asa_User_Quest_Task_Set_Input = {
  readonly asa_step_number?: InputMaybe<Scalars['Int']['input']>;
  readonly asa_task_id?: InputMaybe<Scalars['String']['input']>;
  readonly asa_user_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly quest_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Asa_User_Quest_Task_Stddev_Fields = {
  readonly __typename?: 'asa_user_quest_task_stddev_fields';
  readonly asa_step_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Asa_User_Quest_Task_Stddev_Pop_Fields = {
  readonly __typename?: 'asa_user_quest_task_stddev_pop_fields';
  readonly asa_step_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Asa_User_Quest_Task_Stddev_Samp_Fields = {
  readonly __typename?: 'asa_user_quest_task_stddev_samp_fields';
  readonly asa_step_number?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "asa_user_quest_task" */
export type Asa_User_Quest_Task_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Asa_User_Quest_Task_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Asa_User_Quest_Task_Stream_Cursor_Value_Input = {
  readonly asa_step_number?: InputMaybe<Scalars['Int']['input']>;
  readonly asa_task_id?: InputMaybe<Scalars['String']['input']>;
  readonly asa_user_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly quest_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Asa_User_Quest_Task_Sum_Fields = {
  readonly __typename?: 'asa_user_quest_task_sum_fields';
  readonly asa_step_number?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "asa_user_quest_task" */
export enum Asa_User_Quest_Task_Update_Column {
  /** column name */
  AsaStepNumber = 'asa_step_number',
  /** column name */
  AsaTaskId = 'asa_task_id',
  /** column name */
  AsaUserId = 'asa_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestId = 'quest_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Asa_User_Quest_Task_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Asa_User_Quest_Task_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Asa_User_Quest_Task_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Asa_User_Quest_Task_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Asa_User_Quest_Task_Var_Pop_Fields = {
  readonly __typename?: 'asa_user_quest_task_var_pop_fields';
  readonly asa_step_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Asa_User_Quest_Task_Var_Samp_Fields = {
  readonly __typename?: 'asa_user_quest_task_var_samp_fields';
  readonly asa_step_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Asa_User_Quest_Task_Variance_Fields = {
  readonly __typename?: 'asa_user_quest_task_variance_fields';
  readonly asa_step_number?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "avatars" */
export type Avatars = {
  readonly __typename?: 'avatars';
  readonly availability: Scalars['String']['output'];
  /** An object relationship */
  readonly club?: Maybe<Clubs>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly id: Scalars['uuid']['output'];
  readonly url: Scalars['String']['output'];
  /** An array relationship */
  readonly user_collected_avatars: ReadonlyArray<User_Collected_Avatars>;
  /** An aggregate relationship */
  readonly user_collected_avatars_aggregate: User_Collected_Avatars_Aggregate;
};


/** columns and relationships of "avatars" */
export type AvatarsUser_Collected_AvatarsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Collected_Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Collected_Avatars_Order_By>>;
  where?: InputMaybe<User_Collected_Avatars_Bool_Exp>;
};


/** columns and relationships of "avatars" */
export type AvatarsUser_Collected_Avatars_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Collected_Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Collected_Avatars_Order_By>>;
  where?: InputMaybe<User_Collected_Avatars_Bool_Exp>;
};

/** aggregated selection of "avatars" */
export type Avatars_Aggregate = {
  readonly __typename?: 'avatars_aggregate';
  readonly aggregate?: Maybe<Avatars_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Avatars>;
};

/** aggregate fields of "avatars" */
export type Avatars_Aggregate_Fields = {
  readonly __typename?: 'avatars_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Avatars_Max_Fields>;
  readonly min?: Maybe<Avatars_Min_Fields>;
};


/** aggregate fields of "avatars" */
export type Avatars_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Avatars_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "avatars". All fields are combined with a logical 'AND'. */
export type Avatars_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Avatars_Bool_Exp>>;
  readonly _not?: InputMaybe<Avatars_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Avatars_Bool_Exp>>;
  readonly availability?: InputMaybe<String_Comparison_Exp>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly club_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly url?: InputMaybe<String_Comparison_Exp>;
  readonly user_collected_avatars?: InputMaybe<User_Collected_Avatars_Bool_Exp>;
  readonly user_collected_avatars_aggregate?: InputMaybe<User_Collected_Avatars_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "avatars" */
export enum Avatars_Constraint {
  /** unique or primary key constraint on columns "id" */
  AvatarsPkey = 'avatars_pkey'
}

/** input type for inserting data into table "avatars" */
export type Avatars_Insert_Input = {
  readonly availability?: InputMaybe<Scalars['String']['input']>;
  readonly club?: InputMaybe<Clubs_Obj_Rel_Insert_Input>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly url?: InputMaybe<Scalars['String']['input']>;
  readonly user_collected_avatars?: InputMaybe<User_Collected_Avatars_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Avatars_Max_Fields = {
  readonly __typename?: 'avatars_max_fields';
  readonly availability?: Maybe<Scalars['String']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Avatars_Min_Fields = {
  readonly __typename?: 'avatars_min_fields';
  readonly availability?: Maybe<Scalars['String']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly url?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "avatars" */
export type Avatars_Mutation_Response = {
  readonly __typename?: 'avatars_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Avatars>;
};

/** input type for inserting object relation for remote table "avatars" */
export type Avatars_Obj_Rel_Insert_Input = {
  readonly data: Avatars_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Avatars_On_Conflict>;
};

/** on_conflict condition type for table "avatars" */
export type Avatars_On_Conflict = {
  readonly constraint: Avatars_Constraint;
  readonly update_columns?: ReadonlyArray<Avatars_Update_Column>;
  readonly where?: InputMaybe<Avatars_Bool_Exp>;
};

/** Ordering options when selecting data from "avatars". */
export type Avatars_Order_By = {
  readonly availability?: InputMaybe<Order_By>;
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly url?: InputMaybe<Order_By>;
  readonly user_collected_avatars_aggregate?: InputMaybe<User_Collected_Avatars_Aggregate_Order_By>;
};

/** primary key columns input for table: avatars */
export type Avatars_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "avatars" */
export enum Avatars_Select_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Id = 'id',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "avatars" */
export type Avatars_Set_Input = {
  readonly availability?: InputMaybe<Scalars['String']['input']>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly url?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "avatars" */
export type Avatars_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Avatars_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Avatars_Stream_Cursor_Value_Input = {
  readonly availability?: InputMaybe<Scalars['String']['input']>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly url?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "avatars" */
export enum Avatars_Update_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Id = 'id',
  /** column name */
  Url = 'url'
}

export type Avatars_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Avatars_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Avatars_Bool_Exp;
};

/** columns and relationships of "badges" */
export type Badges = {
  readonly __typename?: 'badges';
  /** An object relationship */
  readonly club?: Maybe<Clubs>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly image: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly priority: Scalars['Int']['output'];
  readonly slug: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "badges" */
export type Badges_Aggregate = {
  readonly __typename?: 'badges_aggregate';
  readonly aggregate?: Maybe<Badges_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Badges>;
};

export type Badges_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Badges_Aggregate_Bool_Exp_Count>;
};

export type Badges_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Badges_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Badges_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "badges" */
export type Badges_Aggregate_Fields = {
  readonly __typename?: 'badges_aggregate_fields';
  readonly avg?: Maybe<Badges_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Badges_Max_Fields>;
  readonly min?: Maybe<Badges_Min_Fields>;
  readonly stddev?: Maybe<Badges_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Badges_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Badges_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Badges_Sum_Fields>;
  readonly var_pop?: Maybe<Badges_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Badges_Var_Samp_Fields>;
  readonly variance?: Maybe<Badges_Variance_Fields>;
};


/** aggregate fields of "badges" */
export type Badges_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Badges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "badges" */
export type Badges_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Badges_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Badges_Max_Order_By>;
  readonly min?: InputMaybe<Badges_Min_Order_By>;
  readonly stddev?: InputMaybe<Badges_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Badges_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Badges_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Badges_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Badges_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Badges_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Badges_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "badges" */
export type Badges_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Badges_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Badges_On_Conflict>;
};

/** aggregate avg on columns */
export type Badges_Avg_Fields = {
  readonly __typename?: 'badges_avg_fields';
  readonly priority?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "badges" */
export type Badges_Avg_Order_By = {
  readonly priority?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "badges". All fields are combined with a logical 'AND'. */
export type Badges_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Badges_Bool_Exp>>;
  readonly _not?: InputMaybe<Badges_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Badges_Bool_Exp>>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly club_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly image?: InputMaybe<String_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly priority?: InputMaybe<Int_Comparison_Exp>;
  readonly slug?: InputMaybe<String_Comparison_Exp>;
  readonly type?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "badges" */
export enum Badges_Constraint {
  /** unique or primary key constraint on columns "id" */
  BadgesPkey = 'badges_pkey',
  /** unique or primary key constraint on columns "slug" */
  BadgesSlugKey = 'badges_slug_key'
}

/** input type for incrementing numeric columns in table "badges" */
export type Badges_Inc_Input = {
  readonly priority?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "badges" */
export type Badges_Insert_Input = {
  readonly club?: InputMaybe<Clubs_Obj_Rel_Insert_Input>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly image?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly priority?: InputMaybe<Scalars['Int']['input']>;
  readonly slug?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Badges_Max_Fields = {
  readonly __typename?: 'badges_max_fields';
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly image?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly priority?: Maybe<Scalars['Int']['output']>;
  readonly slug?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "badges" */
export type Badges_Max_Order_By = {
  readonly club_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly image?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly priority?: InputMaybe<Order_By>;
  readonly slug?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Badges_Min_Fields = {
  readonly __typename?: 'badges_min_fields';
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly image?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly priority?: Maybe<Scalars['Int']['output']>;
  readonly slug?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "badges" */
export type Badges_Min_Order_By = {
  readonly club_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly image?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly priority?: InputMaybe<Order_By>;
  readonly slug?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "badges" */
export type Badges_Mutation_Response = {
  readonly __typename?: 'badges_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Badges>;
};

/** input type for inserting object relation for remote table "badges" */
export type Badges_Obj_Rel_Insert_Input = {
  readonly data: Badges_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Badges_On_Conflict>;
};

/** on_conflict condition type for table "badges" */
export type Badges_On_Conflict = {
  readonly constraint: Badges_Constraint;
  readonly update_columns?: ReadonlyArray<Badges_Update_Column>;
  readonly where?: InputMaybe<Badges_Bool_Exp>;
};

/** Ordering options when selecting data from "badges". */
export type Badges_Order_By = {
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly image?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly priority?: InputMaybe<Order_By>;
  readonly slug?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: badges */
export type Badges_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "badges" */
export enum Badges_Select_Column {
  /** column name */
  ClubId = 'club_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  Priority = 'priority',
  /** column name */
  Slug = 'slug',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "badges" */
export type Badges_Set_Input = {
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly image?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly priority?: InputMaybe<Scalars['Int']['input']>;
  readonly slug?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Badges_Stddev_Fields = {
  readonly __typename?: 'badges_stddev_fields';
  readonly priority?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "badges" */
export type Badges_Stddev_Order_By = {
  readonly priority?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Badges_Stddev_Pop_Fields = {
  readonly __typename?: 'badges_stddev_pop_fields';
  readonly priority?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "badges" */
export type Badges_Stddev_Pop_Order_By = {
  readonly priority?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Badges_Stddev_Samp_Fields = {
  readonly __typename?: 'badges_stddev_samp_fields';
  readonly priority?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "badges" */
export type Badges_Stddev_Samp_Order_By = {
  readonly priority?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "badges" */
export type Badges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Badges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Badges_Stream_Cursor_Value_Input = {
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly image?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly priority?: InputMaybe<Scalars['Int']['input']>;
  readonly slug?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Badges_Sum_Fields = {
  readonly __typename?: 'badges_sum_fields';
  readonly priority?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "badges" */
export type Badges_Sum_Order_By = {
  readonly priority?: InputMaybe<Order_By>;
};

/** update columns of table "badges" */
export enum Badges_Update_Column {
  /** column name */
  ClubId = 'club_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  Priority = 'priority',
  /** column name */
  Slug = 'slug',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Badges_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Badges_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Badges_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Badges_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Badges_Var_Pop_Fields = {
  readonly __typename?: 'badges_var_pop_fields';
  readonly priority?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "badges" */
export type Badges_Var_Pop_Order_By = {
  readonly priority?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Badges_Var_Samp_Fields = {
  readonly __typename?: 'badges_var_samp_fields';
  readonly priority?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "badges" */
export type Badges_Var_Samp_Order_By = {
  readonly priority?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Badges_Variance_Fields = {
  readonly __typename?: 'badges_variance_fields';
  readonly priority?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "badges" */
export type Badges_Variance_Order_By = {
  readonly priority?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  readonly _eq?: InputMaybe<Scalars['bigint']['input']>;
  readonly _gt?: InputMaybe<Scalars['bigint']['input']>;
  readonly _gte?: InputMaybe<Scalars['bigint']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['bigint']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lt?: InputMaybe<Scalars['bigint']['input']>;
  readonly _lte?: InputMaybe<Scalars['bigint']['input']>;
  readonly _neq?: InputMaybe<Scalars['bigint']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['bigint']['input']>>;
};

/** columns and relationships of "bookings" */
export type Bookings = {
  readonly __typename?: 'bookings';
  readonly associated_object: Scalars['String']['output'];
  readonly booking_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly cancelled?: Maybe<Scalars['Boolean']['output']>;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly data: Scalars['jsonb']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly platform: Scalars['String']['output'];
  readonly platform_id: Scalars['String']['output'];
  /** An object relationship */
  readonly room?: Maybe<Rooms>;
  readonly type: Scalars['String']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  readonly user_id: Scalars['String']['output'];
};


/** columns and relationships of "bookings" */
export type BookingsDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "bookings" */
export type Bookings_Aggregate = {
  readonly __typename?: 'bookings_aggregate';
  readonly aggregate?: Maybe<Bookings_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Bookings>;
};

/** aggregate fields of "bookings" */
export type Bookings_Aggregate_Fields = {
  readonly __typename?: 'bookings_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Bookings_Max_Fields>;
  readonly min?: Maybe<Bookings_Min_Fields>;
};


/** aggregate fields of "bookings" */
export type Bookings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Bookings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Bookings_Append_Input = {
  readonly data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "bookings". All fields are combined with a logical 'AND'. */
export type Bookings_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Bookings_Bool_Exp>>;
  readonly _not?: InputMaybe<Bookings_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Bookings_Bool_Exp>>;
  readonly associated_object?: InputMaybe<String_Comparison_Exp>;
  readonly booking_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly cancelled?: InputMaybe<Boolean_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly data?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly platform?: InputMaybe<String_Comparison_Exp>;
  readonly platform_id?: InputMaybe<String_Comparison_Exp>;
  readonly room?: InputMaybe<Rooms_Bool_Exp>;
  readonly type?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "bookings" */
export enum Bookings_Constraint {
  /** unique or primary key constraint on columns "id" */
  BookingsPkey = 'bookings_pkey',
  /** unique or primary key constraint on columns "platform_id", "platform" */
  BookingsPlatformPlatformIdKey = 'bookings_platform_platform_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Bookings_Delete_At_Path_Input = {
  readonly data?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Bookings_Delete_Elem_Input = {
  readonly data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Bookings_Delete_Key_Input = {
  readonly data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "bookings" */
export type Bookings_Insert_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly booking_time?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly data?: InputMaybe<Scalars['jsonb']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly platform?: InputMaybe<Scalars['String']['input']>;
  readonly platform_id?: InputMaybe<Scalars['String']['input']>;
  readonly room?: InputMaybe<Rooms_Obj_Rel_Insert_Input>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Bookings_Max_Fields = {
  readonly __typename?: 'bookings_max_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly booking_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly platform?: Maybe<Scalars['String']['output']>;
  readonly platform_id?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Bookings_Min_Fields = {
  readonly __typename?: 'bookings_min_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly booking_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly platform?: Maybe<Scalars['String']['output']>;
  readonly platform_id?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "bookings" */
export type Bookings_Mutation_Response = {
  readonly __typename?: 'bookings_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Bookings>;
};

/** on_conflict condition type for table "bookings" */
export type Bookings_On_Conflict = {
  readonly constraint: Bookings_Constraint;
  readonly update_columns?: ReadonlyArray<Bookings_Update_Column>;
  readonly where?: InputMaybe<Bookings_Bool_Exp>;
};

/** Ordering options when selecting data from "bookings". */
export type Bookings_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly booking_time?: InputMaybe<Order_By>;
  readonly cancelled?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly data?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly platform?: InputMaybe<Order_By>;
  readonly platform_id?: InputMaybe<Order_By>;
  readonly room?: InputMaybe<Rooms_Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bookings */
export type Bookings_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Bookings_Prepend_Input = {
  readonly data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "bookings" */
export enum Bookings_Select_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  BookingTime = 'booking_time',
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Platform = 'platform',
  /** column name */
  PlatformId = 'platform_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "bookings" */
export type Bookings_Set_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly booking_time?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly data?: InputMaybe<Scalars['jsonb']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly platform?: InputMaybe<Scalars['String']['input']>;
  readonly platform_id?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "bookings" */
export type Bookings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Bookings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bookings_Stream_Cursor_Value_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly booking_time?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly data?: InputMaybe<Scalars['jsonb']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly platform?: InputMaybe<Scalars['String']['input']>;
  readonly platform_id?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "bookings" */
export enum Bookings_Update_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  BookingTime = 'booking_time',
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Platform = 'platform',
  /** column name */
  PlatformId = 'platform_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Bookings_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Bookings_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Bookings_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Bookings_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Bookings_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Bookings_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Bookings_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Bookings_Bool_Exp;
};

/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
export type Bpchar_Comparison_Exp = {
  readonly _eq?: InputMaybe<Scalars['bpchar']['input']>;
  readonly _gt?: InputMaybe<Scalars['bpchar']['input']>;
  readonly _gte?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column match the given case-insensitive pattern */
  readonly _ilike?: InputMaybe<Scalars['bpchar']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['bpchar']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  readonly _iregex?: InputMaybe<Scalars['bpchar']['input']>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  readonly _like?: InputMaybe<Scalars['bpchar']['input']>;
  readonly _lt?: InputMaybe<Scalars['bpchar']['input']>;
  readonly _lte?: InputMaybe<Scalars['bpchar']['input']>;
  readonly _neq?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  readonly _nilike?: InputMaybe<Scalars['bpchar']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['bpchar']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  readonly _niregex?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column NOT match the given pattern */
  readonly _nlike?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  readonly _nregex?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column NOT match the given SQL regular expression */
  readonly _nsimilar?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  readonly _regex?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column match the given SQL regular expression */
  readonly _similar?: InputMaybe<Scalars['bpchar']['input']>;
};

export enum Breakout_Type {
  Private = 'private',
  Public = 'public'
}

export type Breakout_Type_Bool_Exp = {
  readonly _eq?: InputMaybe<Breakout_Type>;
  readonly _in?: InputMaybe<ReadonlyArray<Breakout_Type>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Breakout_Type>;
  readonly _nin?: InputMaybe<ReadonlyArray<Breakout_Type>>;
};

/** columns and relationships of "breakouts" */
export type Breakouts = {
  readonly __typename?: 'breakouts';
  readonly association_id: Scalars['String']['output'];
  /** An object relationship */
  readonly channel?: Maybe<Channels>;
  readonly channel_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  readonly channel_users: ReadonlyArray<Channels_Users>;
  /** An aggregate relationship */
  readonly channel_users_aggregate: Channels_Users_Aggregate;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly delete_if_inactive: Scalars['Boolean']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly name: Scalars['String']['output'];
  readonly platform_settings?: Maybe<Scalars['breakouts_platform_settings']['output']>;
  /** An object relationship */
  readonly room?: Maybe<Rooms>;
  readonly room_id: Scalars['uuid']['output'];
  readonly sort_order: Scalars['String']['output'];
  readonly type: Breakout_Type;
  readonly updated_at: Scalars['timestamptz']['output'];
  readonly valid_until?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "breakouts" */
export type BreakoutsChannel_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Users_Order_By>>;
  where?: InputMaybe<Channels_Users_Bool_Exp>;
};


/** columns and relationships of "breakouts" */
export type BreakoutsChannel_Users_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Users_Order_By>>;
  where?: InputMaybe<Channels_Users_Bool_Exp>;
};


/** columns and relationships of "breakouts" */
export type BreakoutsPlatform_SettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "breakouts" */
export type Breakouts_Aggregate = {
  readonly __typename?: 'breakouts_aggregate';
  readonly aggregate?: Maybe<Breakouts_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Breakouts>;
};

export type Breakouts_Aggregate_Bool_Exp = {
  readonly bool_and?: InputMaybe<Breakouts_Aggregate_Bool_Exp_Bool_And>;
  readonly bool_or?: InputMaybe<Breakouts_Aggregate_Bool_Exp_Bool_Or>;
  readonly count?: InputMaybe<Breakouts_Aggregate_Bool_Exp_Count>;
};

export type Breakouts_Aggregate_Bool_Exp_Bool_And = {
  readonly arguments: Breakouts_Select_Column_Breakouts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Breakouts_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Breakouts_Aggregate_Bool_Exp_Bool_Or = {
  readonly arguments: Breakouts_Select_Column_Breakouts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Breakouts_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Breakouts_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Breakouts_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Breakouts_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "breakouts" */
export type Breakouts_Aggregate_Fields = {
  readonly __typename?: 'breakouts_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Breakouts_Max_Fields>;
  readonly min?: Maybe<Breakouts_Min_Fields>;
};


/** aggregate fields of "breakouts" */
export type Breakouts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Breakouts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "breakouts" */
export type Breakouts_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Breakouts_Max_Order_By>;
  readonly min?: InputMaybe<Breakouts_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Breakouts_Append_Input = {
  readonly platform_settings?: InputMaybe<Scalars['breakouts_platform_settings']['input']>;
};

/** input type for inserting array relation for remote table "breakouts" */
export type Breakouts_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Breakouts_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Breakouts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "breakouts". All fields are combined with a logical 'AND'. */
export type Breakouts_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Breakouts_Bool_Exp>>;
  readonly _not?: InputMaybe<Breakouts_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Breakouts_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly channel?: InputMaybe<Channels_Bool_Exp>;
  readonly channel_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly channel_users?: InputMaybe<Channels_Users_Bool_Exp>;
  readonly channel_users_aggregate?: InputMaybe<Channels_Users_Aggregate_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly delete_if_inactive?: InputMaybe<Boolean_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly platform_settings?: InputMaybe<Breakouts_Platform_Settings_Bool_Exp>;
  readonly room?: InputMaybe<Rooms_Bool_Exp>;
  readonly room_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly sort_order?: InputMaybe<String_Comparison_Exp>;
  readonly type?: InputMaybe<Breakout_Type_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly valid_until?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "breakouts" */
export enum Breakouts_Constraint {
  /** unique or primary key constraint on columns "id" */
  BreakoutsPkey = 'breakouts_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Breakouts_Delete_At_Path_Input = {
  readonly platform_settings?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Breakouts_Delete_Elem_Input = {
  readonly platform_settings?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Breakouts_Delete_Key_Input = {
  readonly platform_settings?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "breakouts" */
export type Breakouts_Insert_Input = {
  readonly channel?: InputMaybe<Channels_Obj_Rel_Insert_Input>;
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly channel_users?: InputMaybe<Channels_Users_Arr_Rel_Insert_Input>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly delete_if_inactive?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly platform_settings?: InputMaybe<Scalars['breakouts_platform_settings']['input']>;
  readonly room?: InputMaybe<Rooms_Obj_Rel_Insert_Input>;
  readonly room_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly sort_order?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Breakout_Type>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly valid_until?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Breakouts_Max_Fields = {
  readonly __typename?: 'breakouts_max_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly channel_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly room_id?: Maybe<Scalars['uuid']['output']>;
  readonly sort_order?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly valid_until?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "breakouts" */
export type Breakouts_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly room_id?: InputMaybe<Order_By>;
  readonly sort_order?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly valid_until?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Breakouts_Min_Fields = {
  readonly __typename?: 'breakouts_min_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly channel_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly room_id?: Maybe<Scalars['uuid']['output']>;
  readonly sort_order?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly valid_until?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "breakouts" */
export type Breakouts_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly room_id?: InputMaybe<Order_By>;
  readonly sort_order?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly valid_until?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "breakouts" */
export type Breakouts_Mutation_Response = {
  readonly __typename?: 'breakouts_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Breakouts>;
};

/** input type for inserting object relation for remote table "breakouts" */
export type Breakouts_Obj_Rel_Insert_Input = {
  readonly data: Breakouts_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Breakouts_On_Conflict>;
};

/** on_conflict condition type for table "breakouts" */
export type Breakouts_On_Conflict = {
  readonly constraint: Breakouts_Constraint;
  readonly update_columns?: ReadonlyArray<Breakouts_Update_Column>;
  readonly where?: InputMaybe<Breakouts_Bool_Exp>;
};

/** Ordering options when selecting data from "breakouts". */
export type Breakouts_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly channel?: InputMaybe<Channels_Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly channel_users_aggregate?: InputMaybe<Channels_Users_Aggregate_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly delete_if_inactive?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly platform_settings?: InputMaybe<Order_By>;
  readonly room?: InputMaybe<Rooms_Order_By>;
  readonly room_id?: InputMaybe<Order_By>;
  readonly sort_order?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly valid_until?: InputMaybe<Order_By>;
};

/** primary key columns input for table: breakouts */
export type Breakouts_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

export type Breakouts_Platform_Settings_Bool_Exp = {
  readonly _eq?: InputMaybe<Scalars['breakouts_platform_settings']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['breakouts_platform_settings']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Scalars['breakouts_platform_settings']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['breakouts_platform_settings']['input']>>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Breakouts_Prepend_Input = {
  readonly platform_settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "breakouts" */
export enum Breakouts_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeleteIfInactive = 'delete_if_inactive',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PlatformSettings = 'platform_settings',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidUntil = 'valid_until'
}

/** select "breakouts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "breakouts" */
export enum Breakouts_Select_Column_Breakouts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  DeleteIfInactive = 'delete_if_inactive'
}

/** select "breakouts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "breakouts" */
export enum Breakouts_Select_Column_Breakouts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  DeleteIfInactive = 'delete_if_inactive'
}

/** input type for updating data in table "breakouts" */
export type Breakouts_Set_Input = {
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly delete_if_inactive?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly platform_settings?: InputMaybe<Scalars['breakouts_platform_settings']['input']>;
  readonly room_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly sort_order?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Breakout_Type>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly valid_until?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "breakouts" */
export type Breakouts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Breakouts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Breakouts_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly delete_if_inactive?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly platform_settings?: InputMaybe<Scalars['jsonb']['input']>;
  readonly room_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly sort_order?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly valid_until?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "breakouts" */
export enum Breakouts_Update_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeleteIfInactive = 'delete_if_inactive',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PlatformSettings = 'platform_settings',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidUntil = 'valid_until'
}

export type Breakouts_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Breakouts_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Breakouts_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Breakouts_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Breakouts_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Breakouts_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Breakouts_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Breakouts_Bool_Exp;
};

/** Build Battles data model, one entry per match. */
export type Build_Battles = {
  readonly __typename?: 'build_battles';
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly theme: Scalars['String']['output'];
};

/** aggregated selection of "build_battles" */
export type Build_Battles_Aggregate = {
  readonly __typename?: 'build_battles_aggregate';
  readonly aggregate?: Maybe<Build_Battles_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Build_Battles>;
};

/** aggregate fields of "build_battles" */
export type Build_Battles_Aggregate_Fields = {
  readonly __typename?: 'build_battles_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Build_Battles_Max_Fields>;
  readonly min?: Maybe<Build_Battles_Min_Fields>;
};


/** aggregate fields of "build_battles" */
export type Build_Battles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Build_Battles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "build_battles". All fields are combined with a logical 'AND'. */
export type Build_Battles_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Build_Battles_Bool_Exp>>;
  readonly _not?: InputMaybe<Build_Battles_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Build_Battles_Bool_Exp>>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly theme?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "build_battles" */
export enum Build_Battles_Constraint {
  /** unique or primary key constraint on columns "id" */
  BuildBattlesPkey = 'build_battles_pkey'
}

/** input type for inserting data into table "build_battles" */
export type Build_Battles_Insert_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly theme?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Build_Battles_Max_Fields = {
  readonly __typename?: 'build_battles_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly theme?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Build_Battles_Min_Fields = {
  readonly __typename?: 'build_battles_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly theme?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "build_battles" */
export type Build_Battles_Mutation_Response = {
  readonly __typename?: 'build_battles_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Build_Battles>;
};

/** on_conflict condition type for table "build_battles" */
export type Build_Battles_On_Conflict = {
  readonly constraint: Build_Battles_Constraint;
  readonly update_columns?: ReadonlyArray<Build_Battles_Update_Column>;
  readonly where?: InputMaybe<Build_Battles_Bool_Exp>;
};

/** Ordering options when selecting data from "build_battles". */
export type Build_Battles_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly theme?: InputMaybe<Order_By>;
};

/** primary key columns input for table: build_battles */
export type Build_Battles_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** Linking table between build battles and their entries. */
export type Build_Battles_Projects = {
  readonly __typename?: 'build_battles_projects';
  readonly build_battle_id: Scalars['uuid']['output'];
  readonly project_id: Scalars['String']['output'];
};

/** aggregated selection of "build_battles_projects" */
export type Build_Battles_Projects_Aggregate = {
  readonly __typename?: 'build_battles_projects_aggregate';
  readonly aggregate?: Maybe<Build_Battles_Projects_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Build_Battles_Projects>;
};

/** aggregate fields of "build_battles_projects" */
export type Build_Battles_Projects_Aggregate_Fields = {
  readonly __typename?: 'build_battles_projects_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Build_Battles_Projects_Max_Fields>;
  readonly min?: Maybe<Build_Battles_Projects_Min_Fields>;
};


/** aggregate fields of "build_battles_projects" */
export type Build_Battles_Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Build_Battles_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "build_battles_projects". All fields are combined with a logical 'AND'. */
export type Build_Battles_Projects_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Build_Battles_Projects_Bool_Exp>>;
  readonly _not?: InputMaybe<Build_Battles_Projects_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Build_Battles_Projects_Bool_Exp>>;
  readonly build_battle_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly project_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "build_battles_projects" */
export enum Build_Battles_Projects_Constraint {
  /** unique or primary key constraint on columns "project_id", "build_battle_id" */
  BuildBattlesProjectsPkey = 'build_battles_projects_pkey'
}

/** input type for inserting data into table "build_battles_projects" */
export type Build_Battles_Projects_Insert_Input = {
  readonly build_battle_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Build_Battles_Projects_Max_Fields = {
  readonly __typename?: 'build_battles_projects_max_fields';
  readonly build_battle_id?: Maybe<Scalars['uuid']['output']>;
  readonly project_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Build_Battles_Projects_Min_Fields = {
  readonly __typename?: 'build_battles_projects_min_fields';
  readonly build_battle_id?: Maybe<Scalars['uuid']['output']>;
  readonly project_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "build_battles_projects" */
export type Build_Battles_Projects_Mutation_Response = {
  readonly __typename?: 'build_battles_projects_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Build_Battles_Projects>;
};

/** on_conflict condition type for table "build_battles_projects" */
export type Build_Battles_Projects_On_Conflict = {
  readonly constraint: Build_Battles_Projects_Constraint;
  readonly update_columns?: ReadonlyArray<Build_Battles_Projects_Update_Column>;
  readonly where?: InputMaybe<Build_Battles_Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "build_battles_projects". */
export type Build_Battles_Projects_Order_By = {
  readonly build_battle_id?: InputMaybe<Order_By>;
  readonly project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: build_battles_projects */
export type Build_Battles_Projects_Pk_Columns_Input = {
  readonly build_battle_id: Scalars['uuid']['input'];
  readonly project_id: Scalars['String']['input'];
};

/** select columns of table "build_battles_projects" */
export enum Build_Battles_Projects_Select_Column {
  /** column name */
  BuildBattleId = 'build_battle_id',
  /** column name */
  ProjectId = 'project_id'
}

/** input type for updating data in table "build_battles_projects" */
export type Build_Battles_Projects_Set_Input = {
  readonly build_battle_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "build_battles_projects" */
export type Build_Battles_Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Build_Battles_Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Build_Battles_Projects_Stream_Cursor_Value_Input = {
  readonly build_battle_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "build_battles_projects" */
export enum Build_Battles_Projects_Update_Column {
  /** column name */
  BuildBattleId = 'build_battle_id',
  /** column name */
  ProjectId = 'project_id'
}

export type Build_Battles_Projects_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Build_Battles_Projects_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Build_Battles_Projects_Bool_Exp;
};

/** select columns of table "build_battles" */
export enum Build_Battles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Theme = 'theme'
}

/** input type for updating data in table "build_battles" */
export type Build_Battles_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly theme?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "build_battles" */
export type Build_Battles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Build_Battles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Build_Battles_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly theme?: InputMaybe<Scalars['String']['input']>;
};

/** Themes table for build battles exclusively (not the same as competitions themes)! */
export type Build_Battles_Themes = {
  readonly __typename?: 'build_battles_themes';
  readonly id: Scalars['String']['output'];
  readonly meta: Scalars['jsonb']['output'];
};


/** Themes table for build battles exclusively (not the same as competitions themes)! */
export type Build_Battles_ThemesMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "build_battles_themes" */
export type Build_Battles_Themes_Aggregate = {
  readonly __typename?: 'build_battles_themes_aggregate';
  readonly aggregate?: Maybe<Build_Battles_Themes_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Build_Battles_Themes>;
};

/** aggregate fields of "build_battles_themes" */
export type Build_Battles_Themes_Aggregate_Fields = {
  readonly __typename?: 'build_battles_themes_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Build_Battles_Themes_Max_Fields>;
  readonly min?: Maybe<Build_Battles_Themes_Min_Fields>;
};


/** aggregate fields of "build_battles_themes" */
export type Build_Battles_Themes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Build_Battles_Themes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Build_Battles_Themes_Append_Input = {
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "build_battles_themes". All fields are combined with a logical 'AND'. */
export type Build_Battles_Themes_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Build_Battles_Themes_Bool_Exp>>;
  readonly _not?: InputMaybe<Build_Battles_Themes_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Build_Battles_Themes_Bool_Exp>>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly meta?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "build_battles_themes" */
export enum Build_Battles_Themes_Constraint {
  /** unique or primary key constraint on columns "id" */
  BuildBattlesThemesPkey = 'build_battles_themes_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Build_Battles_Themes_Delete_At_Path_Input = {
  readonly meta?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Build_Battles_Themes_Delete_Elem_Input = {
  readonly meta?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Build_Battles_Themes_Delete_Key_Input = {
  readonly meta?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "build_battles_themes" */
export type Build_Battles_Themes_Insert_Input = {
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type Build_Battles_Themes_Max_Fields = {
  readonly __typename?: 'build_battles_themes_max_fields';
  readonly id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Build_Battles_Themes_Min_Fields = {
  readonly __typename?: 'build_battles_themes_min_fields';
  readonly id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "build_battles_themes" */
export type Build_Battles_Themes_Mutation_Response = {
  readonly __typename?: 'build_battles_themes_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Build_Battles_Themes>;
};

/** on_conflict condition type for table "build_battles_themes" */
export type Build_Battles_Themes_On_Conflict = {
  readonly constraint: Build_Battles_Themes_Constraint;
  readonly update_columns?: ReadonlyArray<Build_Battles_Themes_Update_Column>;
  readonly where?: InputMaybe<Build_Battles_Themes_Bool_Exp>;
};

/** Ordering options when selecting data from "build_battles_themes". */
export type Build_Battles_Themes_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly meta?: InputMaybe<Order_By>;
};

/** primary key columns input for table: build_battles_themes */
export type Build_Battles_Themes_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Build_Battles_Themes_Prepend_Input = {
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "build_battles_themes" */
export enum Build_Battles_Themes_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta'
}

/** input type for updating data in table "build_battles_themes" */
export type Build_Battles_Themes_Set_Input = {
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "build_battles_themes" */
export type Build_Battles_Themes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Build_Battles_Themes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Build_Battles_Themes_Stream_Cursor_Value_Input = {
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "build_battles_themes" */
export enum Build_Battles_Themes_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta'
}

export type Build_Battles_Themes_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Build_Battles_Themes_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Build_Battles_Themes_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Build_Battles_Themes_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Build_Battles_Themes_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Build_Battles_Themes_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Build_Battles_Themes_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Build_Battles_Themes_Bool_Exp;
};

/** update columns of table "build_battles" */
export enum Build_Battles_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Theme = 'theme'
}

export type Build_Battles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Build_Battles_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Build_Battles_Bool_Exp;
};

export enum Channel_Type {
  Breakout = 'breakout',
  Competition = 'competition',
  LiveEvent = 'live_event',
  OrientationRoom = 'orientation_room',
  Room = 'room',
  Team = 'team'
}

export type Channel_Type_Bool_Exp = {
  readonly _eq?: InputMaybe<Channel_Type>;
  readonly _in?: InputMaybe<ReadonlyArray<Channel_Type>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Channel_Type>;
  readonly _nin?: InputMaybe<ReadonlyArray<Channel_Type>>;
};

export enum Channel_User_Role {
  Member = 'member',
  Owner = 'owner'
}

export type Channel_User_Role_Bool_Exp = {
  readonly _eq?: InputMaybe<Channel_User_Role>;
  readonly _in?: InputMaybe<ReadonlyArray<Channel_User_Role>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Channel_User_Role>;
  readonly _nin?: InputMaybe<ReadonlyArray<Channel_User_Role>>;
};

export enum Channel_User_Status {
  Active = 'active',
  Joined = 'joined',
  Left = 'left'
}

export type Channel_User_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<Channel_User_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<Channel_User_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Channel_User_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<Channel_User_Status>>;
};

/** columns and relationships of "channels" */
export type Channels = {
  readonly __typename?: 'channels';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly association_id: Scalars['String']['output'];
  /** An object relationship */
  readonly breakout?: Maybe<Breakouts>;
  /** An array relationship */
  readonly channels_users: ReadonlyArray<Channels_Users>;
  /** An aggregate relationship */
  readonly channels_users_aggregate: Channels_Users_Aggregate;
  /** An array relationship */
  readonly chats: ReadonlyArray<Chats>;
  /** An aggregate relationship */
  readonly chats_aggregate: Chats_Aggregate;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "channels_division" */
  readonly division: User_Division;
  readonly frozen: Scalars['Boolean']['output'];
  readonly frozen_division?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['uuid']['output'];
  readonly image?: Maybe<Scalars['String']['output']>;
  readonly matchmaking: Scalars['Boolean']['output'];
  readonly mute: Scalars['channels_mute']['output'];
  readonly name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly project?: Maybe<Projects>;
  /** An object relationship */
  readonly room?: Maybe<Rooms>;
  readonly spam_config?: Maybe<Scalars['jsonb']['output']>;
  readonly sticky: Scalars['Boolean']['output'];
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly team_id?: Maybe<Scalars['String']['output']>;
  readonly type: Channel_Type;
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  readonly user_invites: ReadonlyArray<User_Invites>;
  /** An aggregate relationship */
  readonly user_invites_aggregate: User_Invites_Aggregate;
  readonly user_status_platform_states?: Maybe<Scalars['channel_user_status_platform_states']['output']>;
};


/** columns and relationships of "channels" */
export type ChannelsChannels_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Users_Order_By>>;
  where?: InputMaybe<Channels_Users_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsChannels_Users_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Users_Order_By>>;
  where?: InputMaybe<Channels_Users_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsChatsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Chats_Order_By>>;
  where?: InputMaybe<Chats_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsChats_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Chats_Order_By>>;
  where?: InputMaybe<Chats_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsMuteArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "channels" */
export type ChannelsSpam_ConfigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "channels" */
export type ChannelsUser_InvitesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsUser_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsUser_Status_Platform_StatesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "channels" */
export type Channels_Aggregate = {
  readonly __typename?: 'channels_aggregate';
  readonly aggregate?: Maybe<Channels_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Channels>;
};

/** aggregate fields of "channels" */
export type Channels_Aggregate_Fields = {
  readonly __typename?: 'channels_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Channels_Max_Fields>;
  readonly min?: Maybe<Channels_Min_Fields>;
};


/** aggregate fields of "channels" */
export type Channels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Channels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Channels_Append_Input = {
  readonly mute?: InputMaybe<Scalars['jsonb']['input']>;
  readonly spam_config?: InputMaybe<Scalars['jsonb']['input']>;
  readonly user_status_platform_states?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "channels". All fields are combined with a logical 'AND'. */
export type Channels_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Channels_Bool_Exp>>;
  readonly _not?: InputMaybe<Channels_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Channels_Bool_Exp>>;
  readonly associated_object?: InputMaybe<String_Comparison_Exp>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly breakout?: InputMaybe<Breakouts_Bool_Exp>;
  readonly channels_users?: InputMaybe<Channels_Users_Bool_Exp>;
  readonly channels_users_aggregate?: InputMaybe<Channels_Users_Aggregate_Bool_Exp>;
  readonly chats?: InputMaybe<Chats_Bool_Exp>;
  readonly chats_aggregate?: InputMaybe<Chats_Aggregate_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly division?: InputMaybe<User_Division_Bool_Exp>;
  readonly frozen?: InputMaybe<Boolean_Comparison_Exp>;
  readonly frozen_division?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly image?: InputMaybe<String_Comparison_Exp>;
  readonly matchmaking?: InputMaybe<Boolean_Comparison_Exp>;
  readonly mute?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly project?: InputMaybe<Projects_Bool_Exp>;
  readonly room?: InputMaybe<Rooms_Bool_Exp>;
  readonly spam_config?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly sticky?: InputMaybe<Boolean_Comparison_Exp>;
  readonly tagline?: InputMaybe<String_Comparison_Exp>;
  readonly team_id?: InputMaybe<String_Comparison_Exp>;
  readonly type?: InputMaybe<Channel_Type_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user_invites?: InputMaybe<User_Invites_Bool_Exp>;
  readonly user_invites_aggregate?: InputMaybe<User_Invites_Aggregate_Bool_Exp>;
  readonly user_status_platform_states?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "channels" */
export enum Channels_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChannelsPkey = 'channels_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Channels_Delete_At_Path_Input = {
  readonly mute?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly spam_config?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly user_status_platform_states?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Channels_Delete_Elem_Input = {
  readonly mute?: InputMaybe<Scalars['Int']['input']>;
  readonly spam_config?: InputMaybe<Scalars['Int']['input']>;
  readonly user_status_platform_states?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Channels_Delete_Key_Input = {
  readonly mute?: InputMaybe<Scalars['String']['input']>;
  readonly spam_config?: InputMaybe<Scalars['String']['input']>;
  readonly user_status_platform_states?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "channels_events" */
export type Channels_Events = {
  readonly __typename?: 'channels_events';
  /** An object relationship */
  readonly channel: Channels;
  readonly channel_id: Scalars['uuid']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly data: Scalars['channel_event']['output'];
  readonly fallback_message?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['uuid']['output'];
};


/** columns and relationships of "channels_events" */
export type Channels_EventsDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "channels_events" */
export type Channels_Events_Aggregate = {
  readonly __typename?: 'channels_events_aggregate';
  readonly aggregate?: Maybe<Channels_Events_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Channels_Events>;
};

/** aggregate fields of "channels_events" */
export type Channels_Events_Aggregate_Fields = {
  readonly __typename?: 'channels_events_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Channels_Events_Max_Fields>;
  readonly min?: Maybe<Channels_Events_Min_Fields>;
};


/** aggregate fields of "channels_events" */
export type Channels_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Channels_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Channels_Events_Append_Input = {
  readonly data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "channels_events". All fields are combined with a logical 'AND'. */
export type Channels_Events_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Channels_Events_Bool_Exp>>;
  readonly _not?: InputMaybe<Channels_Events_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Channels_Events_Bool_Exp>>;
  readonly channel?: InputMaybe<Channels_Bool_Exp>;
  readonly channel_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly data?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly fallback_message?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "channels_events" */
export enum Channels_Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChannelsEventsPkey = 'channels_events_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Channels_Events_Delete_At_Path_Input = {
  readonly data?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Channels_Events_Delete_Elem_Input = {
  readonly data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Channels_Events_Delete_Key_Input = {
  readonly data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "channels_events" */
export type Channels_Events_Insert_Input = {
  readonly channel?: InputMaybe<Channels_Obj_Rel_Insert_Input>;
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly data: Scalars['channel_event']['input'];
  readonly fallback_message?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Channels_Events_Max_Fields = {
  readonly __typename?: 'channels_events_max_fields';
  readonly channel_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly fallback_message?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Channels_Events_Min_Fields = {
  readonly __typename?: 'channels_events_min_fields';
  readonly channel_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly fallback_message?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "channels_events" */
export type Channels_Events_Mutation_Response = {
  readonly __typename?: 'channels_events_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Channels_Events>;
};

/** on_conflict condition type for table "channels_events" */
export type Channels_Events_On_Conflict = {
  readonly constraint: Channels_Events_Constraint;
  readonly update_columns?: ReadonlyArray<Channels_Events_Update_Column>;
  readonly where?: InputMaybe<Channels_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "channels_events". */
export type Channels_Events_Order_By = {
  readonly channel?: InputMaybe<Channels_Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly data?: InputMaybe<Order_By>;
  readonly fallback_message?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: channels_events */
export type Channels_Events_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Channels_Events_Prepend_Input = {
  readonly data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "channels_events" */
export enum Channels_Events_Select_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  FallbackMessage = 'fallback_message',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "channels_events" */
export type Channels_Events_Set_Input = {
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly data: Scalars['channel_event']['input'];
  readonly fallback_message?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "channels_events" */
export type Channels_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Channels_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channels_Events_Stream_Cursor_Value_Input = {
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly data?: InputMaybe<Scalars['jsonb']['input']>;
  readonly fallback_message?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "channels_events" */
export enum Channels_Events_Update_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  FallbackMessage = 'fallback_message',
  /** column name */
  Id = 'id'
}

export type Channels_Events_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Channels_Events_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Channels_Events_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Channels_Events_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Channels_Events_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Channels_Events_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Channels_Events_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Channels_Events_Bool_Exp;
};

/** input type for inserting data into table "channels" */
export type Channels_Insert_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly breakout?: InputMaybe<Breakouts_Obj_Rel_Insert_Input>;
  readonly channels_users?: InputMaybe<Channels_Users_Arr_Rel_Insert_Input>;
  readonly chats?: InputMaybe<Chats_Arr_Rel_Insert_Input>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly division?: InputMaybe<User_Division>;
  readonly frozen?: InputMaybe<Scalars['Boolean']['input']>;
  readonly frozen_division?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly image?: InputMaybe<Scalars['String']['input']>;
  readonly matchmaking?: InputMaybe<Scalars['Boolean']['input']>;
  readonly mute?: InputMaybe<Scalars['channels_mute']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  readonly role?: InputMaybe<Channel_User_Role>;
  readonly room?: InputMaybe<Rooms_Obj_Rel_Insert_Input>;
  readonly spam_config?: InputMaybe<Scalars['jsonb']['input']>;
  readonly sticky?: InputMaybe<Scalars['Boolean']['input']>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly team_id?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Channel_Type>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_invites?: InputMaybe<User_Invites_Arr_Rel_Insert_Input>;
  readonly user_status_platform_states?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type Channels_Max_Fields = {
  readonly __typename?: 'channels_max_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "channels_division" */
  readonly division?: Maybe<Scalars['String']['output']>;
  readonly frozen_division?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly image?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly team_id?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Channels_Min_Fields = {
  readonly __typename?: 'channels_min_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "channels_division" */
  readonly division?: Maybe<Scalars['String']['output']>;
  readonly frozen_division?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly image?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly team_id?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "channels" */
export type Channels_Mutation_Response = {
  readonly __typename?: 'channels_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Channels>;
};

/** input type for inserting object relation for remote table "channels" */
export type Channels_Obj_Rel_Insert_Input = {
  readonly data: Channels_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Channels_On_Conflict>;
};

/** on_conflict condition type for table "channels" */
export type Channels_On_Conflict = {
  readonly constraint: Channels_Constraint;
  readonly update_columns?: ReadonlyArray<Channels_Update_Column>;
  readonly where?: InputMaybe<Channels_Bool_Exp>;
};

/** Ordering options when selecting data from "channels". */
export type Channels_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly breakout?: InputMaybe<Breakouts_Order_By>;
  readonly channels_users_aggregate?: InputMaybe<Channels_Users_Aggregate_Order_By>;
  readonly chats_aggregate?: InputMaybe<Chats_Aggregate_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly division?: InputMaybe<Order_By>;
  readonly frozen?: InputMaybe<Order_By>;
  readonly frozen_division?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly image?: InputMaybe<Order_By>;
  readonly matchmaking?: InputMaybe<Order_By>;
  readonly mute?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly project?: InputMaybe<Projects_Order_By>;
  readonly room?: InputMaybe<Rooms_Order_By>;
  readonly spam_config?: InputMaybe<Order_By>;
  readonly sticky?: InputMaybe<Order_By>;
  readonly tagline?: InputMaybe<Order_By>;
  readonly team_id?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_invites_aggregate?: InputMaybe<User_Invites_Aggregate_Order_By>;
  readonly user_status_platform_states?: InputMaybe<Order_By>;
};

/** primary key columns input for table: channels */
export type Channels_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Channels_Prepend_Input = {
  readonly mute?: InputMaybe<Scalars['jsonb']['input']>;
  readonly spam_config?: InputMaybe<Scalars['jsonb']['input']>;
  readonly user_status_platform_states?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "channels" */
export enum Channels_Select_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Frozen = 'frozen',
  /** column name */
  FrozenDivision = 'frozen_division',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Matchmaking = 'matchmaking',
  /** column name */
  Mute = 'mute',
  /** column name */
  Name = 'name',
  /** column name */
  SpamConfig = 'spam_config',
  /** column name */
  Sticky = 'sticky',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserStatusPlatformStates = 'user_status_platform_states'
}

/** input type for updating data in table "channels" */
export type Channels_Set_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly division?: InputMaybe<User_Division>;
  readonly frozen?: InputMaybe<Scalars['Boolean']['input']>;
  readonly frozen_division?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly image?: InputMaybe<Scalars['String']['input']>;
  readonly matchmaking?: InputMaybe<Scalars['Boolean']['input']>;
  readonly mute?: InputMaybe<Scalars['channels_mute']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly role?: InputMaybe<Channel_User_Role>;
  readonly spam_config?: InputMaybe<Scalars['jsonb']['input']>;
  readonly sticky?: InputMaybe<Scalars['Boolean']['input']>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly team_id?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Channel_Type>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_status_platform_states?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "channels" */
export type Channels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Channels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channels_Stream_Cursor_Value_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly frozen?: InputMaybe<Scalars['Boolean']['input']>;
  readonly frozen_division?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly image?: InputMaybe<Scalars['String']['input']>;
  readonly matchmaking?: InputMaybe<Scalars['Boolean']['input']>;
  readonly mute?: InputMaybe<Scalars['jsonb']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly spam_config?: InputMaybe<Scalars['jsonb']['input']>;
  readonly sticky?: InputMaybe<Scalars['Boolean']['input']>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly team_id?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_status_platform_states?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "channels" */
export enum Channels_Update_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Frozen = 'frozen',
  /** column name */
  FrozenDivision = 'frozen_division',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Matchmaking = 'matchmaking',
  /** column name */
  Mute = 'mute',
  /** column name */
  Name = 'name',
  /** column name */
  SpamConfig = 'spam_config',
  /** column name */
  Sticky = 'sticky',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserStatusPlatformStates = 'user_status_platform_states'
}

export type Channels_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Channels_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Channels_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Channels_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Channels_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Channels_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Channels_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Channels_Bool_Exp;
};

/** columns and relationships of "channels_users" */
export type Channels_Users = {
  readonly __typename?: 'channels_users';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly banned: Scalars['Boolean']['output'];
  /** An object relationship */
  readonly channel: Channels;
  readonly channel_id: Scalars['uuid']['output'];
  readonly channel_status?: Maybe<Scalars['String']['output']>;
  readonly connection: Scalars['String']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly force_muted: Scalars['Boolean']['output'];
  readonly hand_raised: Scalars['Boolean']['output'];
  readonly hand_raised_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly host: Scalars['Boolean']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly meta: Scalars['jsonb']['output'];
  readonly microphone_permitted: Scalars['Boolean']['output'];
  readonly role: Channel_User_Role;
  readonly screen_share_permitted: Scalars['Boolean']['output'];
  readonly slow_mode?: Maybe<Scalars['timestamptz']['output']>;
  readonly speaking: Scalars['Boolean']['output'];
  readonly status: Channel_User_Status;
  readonly status_changed_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly status_channel_key?: Maybe<Scalars['String']['output']>;
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
  readonly volume: Scalars['Int']['output'];
  readonly web_cam_permitted: Scalars['Boolean']['output'];
};


/** columns and relationships of "channels_users" */
export type Channels_UsersMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "channels_users" */
export type Channels_Users_Aggregate = {
  readonly __typename?: 'channels_users_aggregate';
  readonly aggregate?: Maybe<Channels_Users_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Channels_Users>;
};

export type Channels_Users_Aggregate_Bool_Exp = {
  readonly bool_and?: InputMaybe<Channels_Users_Aggregate_Bool_Exp_Bool_And>;
  readonly bool_or?: InputMaybe<Channels_Users_Aggregate_Bool_Exp_Bool_Or>;
  readonly count?: InputMaybe<Channels_Users_Aggregate_Bool_Exp_Count>;
};

export type Channels_Users_Aggregate_Bool_Exp_Bool_And = {
  readonly arguments: Channels_Users_Select_Column_Channels_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Channels_Users_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Channels_Users_Aggregate_Bool_Exp_Bool_Or = {
  readonly arguments: Channels_Users_Select_Column_Channels_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Channels_Users_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Channels_Users_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Channels_Users_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Channels_Users_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "channels_users" */
export type Channels_Users_Aggregate_Fields = {
  readonly __typename?: 'channels_users_aggregate_fields';
  readonly avg?: Maybe<Channels_Users_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Channels_Users_Max_Fields>;
  readonly min?: Maybe<Channels_Users_Min_Fields>;
  readonly stddev?: Maybe<Channels_Users_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Channels_Users_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Channels_Users_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Channels_Users_Sum_Fields>;
  readonly var_pop?: Maybe<Channels_Users_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Channels_Users_Var_Samp_Fields>;
  readonly variance?: Maybe<Channels_Users_Variance_Fields>;
};


/** aggregate fields of "channels_users" */
export type Channels_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Channels_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "channels_users" */
export type Channels_Users_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Channels_Users_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Channels_Users_Max_Order_By>;
  readonly min?: InputMaybe<Channels_Users_Min_Order_By>;
  readonly stddev?: InputMaybe<Channels_Users_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Channels_Users_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Channels_Users_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Channels_Users_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Channels_Users_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Channels_Users_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Channels_Users_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Channels_Users_Append_Input = {
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "channels_users" */
export type Channels_Users_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Channels_Users_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Channels_Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Channels_Users_Avg_Fields = {
  readonly __typename?: 'channels_users_avg_fields';
  readonly volume?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "channels_users" */
export type Channels_Users_Avg_Order_By = {
  readonly volume?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "channels_users". All fields are combined with a logical 'AND'. */
export type Channels_Users_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Channels_Users_Bool_Exp>>;
  readonly _not?: InputMaybe<Channels_Users_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Channels_Users_Bool_Exp>>;
  readonly associated_object?: InputMaybe<String_Comparison_Exp>;
  readonly banned?: InputMaybe<Boolean_Comparison_Exp>;
  readonly channel?: InputMaybe<Channels_Bool_Exp>;
  readonly channel_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly channel_status?: InputMaybe<String_Comparison_Exp>;
  readonly connection?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly force_muted?: InputMaybe<Boolean_Comparison_Exp>;
  readonly hand_raised?: InputMaybe<Boolean_Comparison_Exp>;
  readonly hand_raised_updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly host?: InputMaybe<Boolean_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly meta?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly microphone_permitted?: InputMaybe<Boolean_Comparison_Exp>;
  readonly role?: InputMaybe<Channel_User_Role_Bool_Exp>;
  readonly screen_share_permitted?: InputMaybe<Boolean_Comparison_Exp>;
  readonly slow_mode?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly speaking?: InputMaybe<Boolean_Comparison_Exp>;
  readonly status?: InputMaybe<Channel_User_Status_Bool_Exp>;
  readonly status_changed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly status_channel_key?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
  readonly volume?: InputMaybe<Int_Comparison_Exp>;
  readonly web_cam_permitted?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "channels_users" */
export enum Channels_Users_Constraint {
  /** unique or primary key constraint on columns "user_id", "channel_id" */
  ChannelsUsersChannelIdUserIdKey = 'channels_users_channel_id_user_id_key',
  /** unique or primary key constraint on columns "id" */
  ChannelsUsersPkey = 'channels_users_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Channels_Users_Delete_At_Path_Input = {
  readonly meta?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Channels_Users_Delete_Elem_Input = {
  readonly meta?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Channels_Users_Delete_Key_Input = {
  readonly meta?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "channels_users" */
export type Channels_Users_Inc_Input = {
  readonly volume?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "channels_users" */
export type Channels_Users_Insert_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly banned?: InputMaybe<Scalars['Boolean']['input']>;
  readonly channel?: InputMaybe<Channels_Obj_Rel_Insert_Input>;
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly connection?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly force_muted?: InputMaybe<Scalars['Boolean']['input']>;
  readonly hand_raised?: InputMaybe<Scalars['Boolean']['input']>;
  readonly hand_raised_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly host?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly microphone_permitted?: InputMaybe<Scalars['Boolean']['input']>;
  readonly role?: InputMaybe<Scalars['String']['input']>;
  readonly screen_share_permitted?: InputMaybe<Scalars['Boolean']['input']>;
  readonly slow_mode?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly speaking?: InputMaybe<Scalars['Boolean']['input']>;
  readonly status?: InputMaybe<Channel_User_Status>;
  readonly status_changed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly volume?: InputMaybe<Scalars['Int']['input']>;
  readonly web_cam_permitted?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type Channels_Users_Max_Fields = {
  readonly __typename?: 'channels_users_max_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly channel_id?: Maybe<Scalars['uuid']['output']>;
  readonly channel_status?: Maybe<Scalars['String']['output']>;
  readonly connection?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly hand_raised_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly role?: Maybe<Scalars['String']['output']>;
  readonly slow_mode?: Maybe<Scalars['timestamptz']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly status_changed_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly status_channel_key?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
  readonly volume?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "channels_users" */
export type Channels_Users_Max_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly channel_status?: InputMaybe<Order_By>;
  readonly connection?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly hand_raised_updated_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly role?: InputMaybe<Order_By>;
  readonly slow_mode?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly status_changed_at?: InputMaybe<Order_By>;
  readonly status_channel_key?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly volume?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Channels_Users_Min_Fields = {
  readonly __typename?: 'channels_users_min_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly channel_id?: Maybe<Scalars['uuid']['output']>;
  readonly channel_status?: Maybe<Scalars['String']['output']>;
  readonly connection?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly hand_raised_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly role?: Maybe<Scalars['String']['output']>;
  readonly slow_mode?: Maybe<Scalars['timestamptz']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly status_changed_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly status_channel_key?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
  readonly volume?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "channels_users" */
export type Channels_Users_Min_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly channel_status?: InputMaybe<Order_By>;
  readonly connection?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly hand_raised_updated_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly role?: InputMaybe<Order_By>;
  readonly slow_mode?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly status_changed_at?: InputMaybe<Order_By>;
  readonly status_channel_key?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly volume?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "channels_users" */
export type Channels_Users_Mutation_Response = {
  readonly __typename?: 'channels_users_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Channels_Users>;
};

/** on_conflict condition type for table "channels_users" */
export type Channels_Users_On_Conflict = {
  readonly constraint: Channels_Users_Constraint;
  readonly update_columns?: ReadonlyArray<Channels_Users_Update_Column>;
  readonly where?: InputMaybe<Channels_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "channels_users". */
export type Channels_Users_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly banned?: InputMaybe<Order_By>;
  readonly channel?: InputMaybe<Channels_Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly channel_status?: InputMaybe<Order_By>;
  readonly connection?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly force_muted?: InputMaybe<Order_By>;
  readonly hand_raised?: InputMaybe<Order_By>;
  readonly hand_raised_updated_at?: InputMaybe<Order_By>;
  readonly host?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly meta?: InputMaybe<Order_By>;
  readonly microphone_permitted?: InputMaybe<Order_By>;
  readonly role?: InputMaybe<Order_By>;
  readonly screen_share_permitted?: InputMaybe<Order_By>;
  readonly slow_mode?: InputMaybe<Order_By>;
  readonly speaking?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly status_changed_at?: InputMaybe<Order_By>;
  readonly status_channel_key?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly volume?: InputMaybe<Order_By>;
  readonly web_cam_permitted?: InputMaybe<Order_By>;
};

/** primary key columns input for table: channels_users */
export type Channels_Users_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Channels_Users_Prepend_Input = {
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "channels_users" */
export enum Channels_Users_Select_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  Banned = 'banned',
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  ChannelStatus = 'channel_status',
  /** column name */
  Connection = 'connection',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ForceMuted = 'force_muted',
  /** column name */
  HandRaised = 'hand_raised',
  /** column name */
  HandRaisedUpdatedAt = 'hand_raised_updated_at',
  /** column name */
  Host = 'host',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  MicrophonePermitted = 'microphone_permitted',
  /** column name */
  Role = 'role',
  /** column name */
  ScreenSharePermitted = 'screen_share_permitted',
  /** column name */
  SlowMode = 'slow_mode',
  /** column name */
  Speaking = 'speaking',
  /** column name */
  Status = 'status',
  /** column name */
  StatusChangedAt = 'status_changed_at',
  /** column name */
  StatusChannelKey = 'status_channel_key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Volume = 'volume',
  /** column name */
  WebCamPermitted = 'web_cam_permitted'
}

/** select "channels_users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "channels_users" */
export enum Channels_Users_Select_Column_Channels_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Banned = 'banned',
  /** column name */
  ForceMuted = 'force_muted',
  /** column name */
  HandRaised = 'hand_raised',
  /** column name */
  Host = 'host',
  /** column name */
  MicrophonePermitted = 'microphone_permitted',
  /** column name */
  ScreenSharePermitted = 'screen_share_permitted',
  /** column name */
  Speaking = 'speaking',
  /** column name */
  WebCamPermitted = 'web_cam_permitted'
}

/** select "channels_users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "channels_users" */
export enum Channels_Users_Select_Column_Channels_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Banned = 'banned',
  /** column name */
  ForceMuted = 'force_muted',
  /** column name */
  HandRaised = 'hand_raised',
  /** column name */
  Host = 'host',
  /** column name */
  MicrophonePermitted = 'microphone_permitted',
  /** column name */
  ScreenSharePermitted = 'screen_share_permitted',
  /** column name */
  Speaking = 'speaking',
  /** column name */
  WebCamPermitted = 'web_cam_permitted'
}

/** input type for updating data in table "channels_users" */
export type Channels_Users_Set_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly banned?: InputMaybe<Scalars['Boolean']['input']>;
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly connection?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly force_muted?: InputMaybe<Scalars['Boolean']['input']>;
  readonly hand_raised?: InputMaybe<Scalars['Boolean']['input']>;
  readonly hand_raised_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly host?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly microphone_permitted?: InputMaybe<Scalars['Boolean']['input']>;
  readonly role?: InputMaybe<Scalars['String']['input']>;
  readonly screen_share_permitted?: InputMaybe<Scalars['Boolean']['input']>;
  readonly slow_mode?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly speaking?: InputMaybe<Scalars['Boolean']['input']>;
  readonly status?: InputMaybe<Channel_User_Status>;
  readonly status_changed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly volume?: InputMaybe<Scalars['Int']['input']>;
  readonly web_cam_permitted?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type Channels_Users_Stddev_Fields = {
  readonly __typename?: 'channels_users_stddev_fields';
  readonly volume?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "channels_users" */
export type Channels_Users_Stddev_Order_By = {
  readonly volume?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Channels_Users_Stddev_Pop_Fields = {
  readonly __typename?: 'channels_users_stddev_pop_fields';
  readonly volume?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "channels_users" */
export type Channels_Users_Stddev_Pop_Order_By = {
  readonly volume?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Channels_Users_Stddev_Samp_Fields = {
  readonly __typename?: 'channels_users_stddev_samp_fields';
  readonly volume?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "channels_users" */
export type Channels_Users_Stddev_Samp_Order_By = {
  readonly volume?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "channels_users" */
export type Channels_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Channels_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channels_Users_Stream_Cursor_Value_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly banned?: InputMaybe<Scalars['Boolean']['input']>;
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly channel_status?: InputMaybe<Scalars['String']['input']>;
  readonly connection?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly force_muted?: InputMaybe<Scalars['Boolean']['input']>;
  readonly hand_raised?: InputMaybe<Scalars['Boolean']['input']>;
  readonly hand_raised_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly host?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly microphone_permitted?: InputMaybe<Scalars['Boolean']['input']>;
  readonly role?: InputMaybe<Scalars['String']['input']>;
  readonly screen_share_permitted?: InputMaybe<Scalars['Boolean']['input']>;
  readonly slow_mode?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly speaking?: InputMaybe<Scalars['Boolean']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly status_changed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly status_channel_key?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly volume?: InputMaybe<Scalars['Int']['input']>;
  readonly web_cam_permitted?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type Channels_Users_Sum_Fields = {
  readonly __typename?: 'channels_users_sum_fields';
  readonly volume?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "channels_users" */
export type Channels_Users_Sum_Order_By = {
  readonly volume?: InputMaybe<Order_By>;
};

/** update columns of table "channels_users" */
export enum Channels_Users_Update_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  Banned = 'banned',
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  Connection = 'connection',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ForceMuted = 'force_muted',
  /** column name */
  HandRaised = 'hand_raised',
  /** column name */
  HandRaisedUpdatedAt = 'hand_raised_updated_at',
  /** column name */
  Host = 'host',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  MicrophonePermitted = 'microphone_permitted',
  /** column name */
  Role = 'role',
  /** column name */
  ScreenSharePermitted = 'screen_share_permitted',
  /** column name */
  SlowMode = 'slow_mode',
  /** column name */
  Speaking = 'speaking',
  /** column name */
  Status = 'status',
  /** column name */
  StatusChangedAt = 'status_changed_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Volume = 'volume',
  /** column name */
  WebCamPermitted = 'web_cam_permitted'
}

export type Channels_Users_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Channels_Users_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Channels_Users_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Channels_Users_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Channels_Users_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Channels_Users_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Channels_Users_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Channels_Users_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Channels_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Channels_Users_Var_Pop_Fields = {
  readonly __typename?: 'channels_users_var_pop_fields';
  readonly volume?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "channels_users" */
export type Channels_Users_Var_Pop_Order_By = {
  readonly volume?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Channels_Users_Var_Samp_Fields = {
  readonly __typename?: 'channels_users_var_samp_fields';
  readonly volume?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "channels_users" */
export type Channels_Users_Var_Samp_Order_By = {
  readonly volume?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Channels_Users_Variance_Fields = {
  readonly __typename?: 'channels_users_variance_fields';
  readonly volume?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "channels_users" */
export type Channels_Users_Variance_Order_By = {
  readonly volume?: InputMaybe<Order_By>;
};

/** columns and relationships of "chats" */
export type Chats = {
  readonly __typename?: 'chats';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly breakout?: Maybe<Breakouts>;
  /** An object relationship */
  readonly channel: Channels;
  readonly channel_id: Scalars['uuid']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly message: Scalars['String']['output'];
  readonly order_id: Scalars['Int']['output'];
  /** An object relationship */
  readonly project?: Maybe<Projects>;
  /** An object relationship */
  readonly room?: Maybe<Rooms>;
  /** An object relationship */
  readonly sender: Users;
  readonly sender_id: Scalars['String']['output'];
  readonly source: Scalars['String']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "chats" */
export type Chats_Aggregate = {
  readonly __typename?: 'chats_aggregate';
  readonly aggregate?: Maybe<Chats_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Chats>;
};

export type Chats_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Chats_Aggregate_Bool_Exp_Count>;
};

export type Chats_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Chats_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Chats_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chats" */
export type Chats_Aggregate_Fields = {
  readonly __typename?: 'chats_aggregate_fields';
  readonly avg?: Maybe<Chats_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Chats_Max_Fields>;
  readonly min?: Maybe<Chats_Min_Fields>;
  readonly stddev?: Maybe<Chats_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Chats_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Chats_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Chats_Sum_Fields>;
  readonly var_pop?: Maybe<Chats_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Chats_Var_Samp_Fields>;
  readonly variance?: Maybe<Chats_Variance_Fields>;
};


/** aggregate fields of "chats" */
export type Chats_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Chats_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "chats" */
export type Chats_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Chats_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Chats_Max_Order_By>;
  readonly min?: InputMaybe<Chats_Min_Order_By>;
  readonly stddev?: InputMaybe<Chats_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Chats_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Chats_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Chats_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Chats_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Chats_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Chats_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "chats" */
export type Chats_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Chats_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Chats_On_Conflict>;
};

/** aggregate avg on columns */
export type Chats_Avg_Fields = {
  readonly __typename?: 'chats_avg_fields';
  readonly order_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "chats" */
export type Chats_Avg_Order_By = {
  readonly order_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "chats". All fields are combined with a logical 'AND'. */
export type Chats_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Chats_Bool_Exp>>;
  readonly _not?: InputMaybe<Chats_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Chats_Bool_Exp>>;
  readonly associated_object?: InputMaybe<String_Comparison_Exp>;
  readonly breakout?: InputMaybe<Breakouts_Bool_Exp>;
  readonly channel?: InputMaybe<Channels_Bool_Exp>;
  readonly channel_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly message?: InputMaybe<String_Comparison_Exp>;
  readonly order_id?: InputMaybe<Int_Comparison_Exp>;
  readonly project?: InputMaybe<Projects_Bool_Exp>;
  readonly room?: InputMaybe<Rooms_Bool_Exp>;
  readonly sender?: InputMaybe<Users_Bool_Exp>;
  readonly sender_id?: InputMaybe<String_Comparison_Exp>;
  readonly source?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "chats" */
export enum Chats_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChatsPkey = 'chats_pkey'
}

/** input type for incrementing numeric columns in table "chats" */
export type Chats_Inc_Input = {
  readonly order_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "chats" */
export type Chats_Insert_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly breakout?: InputMaybe<Breakouts_Obj_Rel_Insert_Input>;
  readonly channel?: InputMaybe<Channels_Obj_Rel_Insert_Input>;
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly order_id?: InputMaybe<Scalars['Int']['input']>;
  readonly project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  readonly room?: InputMaybe<Rooms_Obj_Rel_Insert_Input>;
  readonly sender?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly sender_id?: InputMaybe<Scalars['String']['input']>;
  readonly source?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Chats_Max_Fields = {
  readonly __typename?: 'chats_max_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly channel_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly message?: Maybe<Scalars['String']['output']>;
  readonly order_id?: Maybe<Scalars['Int']['output']>;
  readonly sender_id?: Maybe<Scalars['String']['output']>;
  readonly source?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "chats" */
export type Chats_Max_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly message?: InputMaybe<Order_By>;
  readonly order_id?: InputMaybe<Order_By>;
  readonly sender_id?: InputMaybe<Order_By>;
  readonly source?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chats_Min_Fields = {
  readonly __typename?: 'chats_min_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly channel_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly message?: Maybe<Scalars['String']['output']>;
  readonly order_id?: Maybe<Scalars['Int']['output']>;
  readonly sender_id?: Maybe<Scalars['String']['output']>;
  readonly source?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "chats" */
export type Chats_Min_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly message?: InputMaybe<Order_By>;
  readonly order_id?: InputMaybe<Order_By>;
  readonly sender_id?: InputMaybe<Order_By>;
  readonly source?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chats" */
export type Chats_Mutation_Response = {
  readonly __typename?: 'chats_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Chats>;
};

/** on_conflict condition type for table "chats" */
export type Chats_On_Conflict = {
  readonly constraint: Chats_Constraint;
  readonly update_columns?: ReadonlyArray<Chats_Update_Column>;
  readonly where?: InputMaybe<Chats_Bool_Exp>;
};

/** Ordering options when selecting data from "chats". */
export type Chats_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly breakout?: InputMaybe<Breakouts_Order_By>;
  readonly channel?: InputMaybe<Channels_Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly message?: InputMaybe<Order_By>;
  readonly order_id?: InputMaybe<Order_By>;
  readonly project?: InputMaybe<Projects_Order_By>;
  readonly room?: InputMaybe<Rooms_Order_By>;
  readonly sender?: InputMaybe<Users_Order_By>;
  readonly sender_id?: InputMaybe<Order_By>;
  readonly source?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chats */
export type Chats_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "chats" */
export enum Chats_Select_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  SenderId = 'sender_id',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "chats" */
export type Chats_Set_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly order_id?: InputMaybe<Scalars['Int']['input']>;
  readonly sender_id?: InputMaybe<Scalars['String']['input']>;
  readonly source?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Chats_Stddev_Fields = {
  readonly __typename?: 'chats_stddev_fields';
  readonly order_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "chats" */
export type Chats_Stddev_Order_By = {
  readonly order_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Chats_Stddev_Pop_Fields = {
  readonly __typename?: 'chats_stddev_pop_fields';
  readonly order_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "chats" */
export type Chats_Stddev_Pop_Order_By = {
  readonly order_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Chats_Stddev_Samp_Fields = {
  readonly __typename?: 'chats_stddev_samp_fields';
  readonly order_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "chats" */
export type Chats_Stddev_Samp_Order_By = {
  readonly order_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "chats" */
export type Chats_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Chats_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chats_Stream_Cursor_Value_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly order_id?: InputMaybe<Scalars['Int']['input']>;
  readonly sender_id?: InputMaybe<Scalars['String']['input']>;
  readonly source?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Chats_Sum_Fields = {
  readonly __typename?: 'chats_sum_fields';
  readonly order_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "chats" */
export type Chats_Sum_Order_By = {
  readonly order_id?: InputMaybe<Order_By>;
};

/** update columns of table "chats" */
export enum Chats_Update_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  SenderId = 'sender_id',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Chats_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Chats_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Chats_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Chats_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Chats_Var_Pop_Fields = {
  readonly __typename?: 'chats_var_pop_fields';
  readonly order_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "chats" */
export type Chats_Var_Pop_Order_By = {
  readonly order_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Chats_Var_Samp_Fields = {
  readonly __typename?: 'chats_var_samp_fields';
  readonly order_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "chats" */
export type Chats_Var_Samp_Order_By = {
  readonly order_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Chats_Variance_Fields = {
  readonly __typename?: 'chats_variance_fields';
  readonly order_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "chats" */
export type Chats_Variance_Order_By = {
  readonly order_id?: InputMaybe<Order_By>;
};

export enum Club_Onboarding_Requirement {
  Linked = 'linked',
  LinkedAcknowledgement = 'linked_acknowledgement',
  Onboarded = 'onboarded',
  Oriented = 'oriented'
}

export type Club_Onboarding_Requirement_Bool_Exp = {
  readonly _contains: Club_Onboarding_Requirement;
  readonly _eq?: InputMaybe<ReadonlyArray<Club_Onboarding_Requirement>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<ReadonlyArray<Club_Onboarding_Requirement>>;
};

export enum Club_Privacy {
  Private = 'private',
  Public = 'public'
}

export type Club_Privacy_Bool_Exp = {
  readonly _eq?: InputMaybe<Club_Privacy>;
  readonly _in?: InputMaybe<ReadonlyArray<Club_Privacy>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Club_Privacy>;
  readonly _nin?: InputMaybe<ReadonlyArray<Club_Privacy>>;
};

export enum Club_Status {
  Closed = 'closed',
  Open = 'open'
}

export type Club_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<Club_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<Club_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Club_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<Club_Status>>;
};

/** columns and relationships of "clubs" */
export type Clubs = {
  readonly __typename?: 'clubs';
  /** An object relationship */
  readonly access_feature?: Maybe<Features>;
  readonly access_feature_id?: Maybe<Scalars['uuid']['output']>;
  readonly association_id: Scalars['String']['output'];
  /** An array relationship */
  readonly badges: ReadonlyArray<Badges>;
  /** An aggregate relationship */
  readonly badges_aggregate: Badges_Aggregate;
  readonly clipboard_prompt?: Maybe<Scalars['clipboard_prompt']['output']>;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly display_settings: Scalars['clubs_display_settings']['output'];
  /** An array relationship */
  readonly feeds: ReadonlyArray<Feeds>;
  /** An aggregate relationship */
  readonly feeds_aggregate: Feeds_Aggregate;
  readonly id: Scalars['uuid']['output'];
  readonly lock_time?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  readonly members: ReadonlyArray<Clubs_Members>;
  /** An aggregate relationship */
  readonly members_aggregate: Clubs_Members_Aggregate;
  readonly name: Scalars['String']['output'];
  readonly onboarding?: Maybe<Scalars['clubs_onboarding']['output']>;
  readonly onboarding_requirements?: Maybe<ReadonlyArray<Club_Onboarding_Requirement>>;
  readonly privacy: Club_Privacy;
  /** An array relationship */
  readonly projects: ReadonlyArray<Projects>;
  /** An aggregate relationship */
  readonly projects_aggregate: Projects_Aggregate;
  /** An array relationship */
  readonly prospective_users_purchases: ReadonlyArray<Prospective_Users_Purchases>;
  /** An aggregate relationship */
  readonly prospective_users_purchases_aggregate: Prospective_Users_Purchases_Aggregate;
  /** An array relationship */
  readonly quests: ReadonlyArray<Quests>;
  /** An aggregate relationship */
  readonly quests_aggregate: Quests_Aggregate;
  /** An array relationship */
  readonly rooms: ReadonlyArray<Rooms>;
  /** An aggregate relationship */
  readonly rooms_aggregate: Rooms_Aggregate;
  /** An object relationship */
  readonly schedule?: Maybe<Schedules>;
  readonly schedule_id?: Maybe<Scalars['uuid']['output']>;
  readonly status: Club_Status;
  /** An array relationship */
  readonly themes: ReadonlyArray<Themes>;
  /** An aggregate relationship */
  readonly themes_aggregate: Themes_Aggregate;
  /** An array relationship */
  readonly tutorials: ReadonlyArray<Tutorials>;
  /** An aggregate relationship */
  readonly tutorials_aggregate: Tutorials_Aggregate;
  readonly typeform_name?: Maybe<Scalars['String']['output']>;
  readonly unlock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at: Scalars['timestamptz']['output'];
  readonly url_name: Scalars['String']['output'];
};


/** columns and relationships of "clubs" */
export type ClubsBadgesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsBadges_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsClipboard_PromptArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "clubs" */
export type ClubsDisplay_SettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "clubs" */
export type ClubsFeedsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsFeeds_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsMembersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Members_Order_By>>;
  where?: InputMaybe<Clubs_Members_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsMembers_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Members_Order_By>>;
  where?: InputMaybe<Clubs_Members_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsOnboardingArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "clubs" */
export type ClubsOnboarding_RequirementsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "clubs" */
export type ClubsProjectsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsProjects_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsProspective_Users_PurchasesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Order_By>>;
  where?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsProspective_Users_Purchases_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Order_By>>;
  where?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsQuestsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Quests_Order_By>>;
  where?: InputMaybe<Quests_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsQuests_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Quests_Order_By>>;
  where?: InputMaybe<Quests_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsRoomsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsRooms_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsThemesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Themes_Order_By>>;
  where?: InputMaybe<Themes_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsThemes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Themes_Order_By>>;
  where?: InputMaybe<Themes_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsTutorialsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Order_By>>;
  where?: InputMaybe<Tutorials_Bool_Exp>;
};


/** columns and relationships of "clubs" */
export type ClubsTutorials_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Order_By>>;
  where?: InputMaybe<Tutorials_Bool_Exp>;
};

/** aggregated selection of "clubs" */
export type Clubs_Aggregate = {
  readonly __typename?: 'clubs_aggregate';
  readonly aggregate?: Maybe<Clubs_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Clubs>;
};

export type Clubs_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Clubs_Aggregate_Bool_Exp_Count>;
};

export type Clubs_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Clubs_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Clubs_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "clubs" */
export type Clubs_Aggregate_Fields = {
  readonly __typename?: 'clubs_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Clubs_Max_Fields>;
  readonly min?: Maybe<Clubs_Min_Fields>;
};


/** aggregate fields of "clubs" */
export type Clubs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Clubs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "clubs" */
export type Clubs_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Clubs_Max_Order_By>;
  readonly min?: InputMaybe<Clubs_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Clubs_Append_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['jsonb']['input']>;
  readonly display_settings?: InputMaybe<Scalars['jsonb']['input']>;
  readonly onboarding?: InputMaybe<Scalars['jsonb']['input']>;
  readonly onboarding_requirements?: InputMaybe<Club_Onboarding_Requirement>;
};

/** input type for inserting array relation for remote table "clubs" */
export type Clubs_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Clubs_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Clubs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "clubs". All fields are combined with a logical 'AND'. */
export type Clubs_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Clubs_Bool_Exp>>;
  readonly _not?: InputMaybe<Clubs_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Clubs_Bool_Exp>>;
  readonly access_feature?: InputMaybe<Features_Bool_Exp>;
  readonly access_feature_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly badges?: InputMaybe<Badges_Bool_Exp>;
  readonly badges_aggregate?: InputMaybe<Badges_Aggregate_Bool_Exp>;
  readonly clipboard_prompt?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly display_settings?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly feeds?: InputMaybe<Feeds_Bool_Exp>;
  readonly feeds_aggregate?: InputMaybe<Feeds_Aggregate_Bool_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly lock_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly members?: InputMaybe<Clubs_Members_Bool_Exp>;
  readonly members_aggregate?: InputMaybe<Clubs_Members_Aggregate_Bool_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly onboarding?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly onboarding_requirements?: InputMaybe<Club_Onboarding_Requirement_Bool_Exp>;
  readonly privacy?: InputMaybe<Club_Privacy_Bool_Exp>;
  readonly projects?: InputMaybe<Projects_Bool_Exp>;
  readonly projects_aggregate?: InputMaybe<Projects_Aggregate_Bool_Exp>;
  readonly prospective_users_purchases?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
  readonly prospective_users_purchases_aggregate?: InputMaybe<Prospective_Users_Purchases_Aggregate_Bool_Exp>;
  readonly quests?: InputMaybe<Quests_Bool_Exp>;
  readonly quests_aggregate?: InputMaybe<Quests_Aggregate_Bool_Exp>;
  readonly rooms?: InputMaybe<Rooms_Bool_Exp>;
  readonly rooms_aggregate?: InputMaybe<Rooms_Aggregate_Bool_Exp>;
  readonly schedule?: InputMaybe<Schedules_Bool_Exp>;
  readonly schedule_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly status?: InputMaybe<Club_Status_Bool_Exp>;
  readonly themes?: InputMaybe<Themes_Bool_Exp>;
  readonly themes_aggregate?: InputMaybe<Themes_Aggregate_Bool_Exp>;
  readonly tutorials?: InputMaybe<Tutorials_Bool_Exp>;
  readonly tutorials_aggregate?: InputMaybe<Tutorials_Aggregate_Bool_Exp>;
  readonly typeform_name?: InputMaybe<String_Comparison_Exp>;
  readonly unlock_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly url_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "clubs" */
export enum Clubs_Constraint {
  /** unique or primary key constraint on columns "association_id" */
  ClubsAssociationIdKey = 'clubs_association_id_key',
  /** unique or primary key constraint on columns "id" */
  ClubsPkey = 'clubs_pkey',
  /** unique or primary key constraint on columns "url_name" */
  ClubsUrlNameKey = 'clubs_url_name_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Clubs_Delete_At_Path_Input = {
  readonly clipboard_prompt?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly display_settings?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly onboarding?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly onboarding_requirements?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Clubs_Delete_Elem_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['Int']['input']>;
  readonly display_settings?: InputMaybe<Scalars['Int']['input']>;
  readonly onboarding?: InputMaybe<Scalars['Int']['input']>;
  readonly onboarding_requirements?: InputMaybe<Club_Onboarding_Requirement>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Clubs_Delete_Key_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['String']['input']>;
  readonly display_settings?: InputMaybe<Scalars['String']['input']>;
  readonly onboarding?: InputMaybe<Scalars['String']['input']>;
  readonly onboarding_requirements?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "clubs" */
export type Clubs_Insert_Input = {
  readonly access_feature?: InputMaybe<Features_Obj_Rel_Insert_Input>;
  readonly access_feature_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly badges?: InputMaybe<Badges_Arr_Rel_Insert_Input>;
  readonly clipboard_prompt?: InputMaybe<Scalars['clipboard_prompt']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly display_settings?: InputMaybe<Scalars['jsonb']['input']>;
  readonly feeds?: InputMaybe<Feeds_Arr_Rel_Insert_Input>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly lock_time?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly members?: InputMaybe<Clubs_Members_Arr_Rel_Insert_Input>;
  readonly mute?: InputMaybe<Scalars['clubs_display_settings']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly onboarding?: InputMaybe<Scalars['clubs_onboarding']['input']>;
  readonly onboarding_requirements?: InputMaybe<ReadonlyArray<Club_Onboarding_Requirement>>;
  readonly privacy?: InputMaybe<Club_Privacy>;
  readonly projects?: InputMaybe<Projects_Arr_Rel_Insert_Input>;
  readonly prospective_users_purchases?: InputMaybe<Prospective_Users_Purchases_Arr_Rel_Insert_Input>;
  readonly quests?: InputMaybe<Quests_Arr_Rel_Insert_Input>;
  readonly rooms?: InputMaybe<Rooms_Arr_Rel_Insert_Input>;
  readonly schedule?: InputMaybe<Schedules_Obj_Rel_Insert_Input>;
  readonly schedule_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly status?: InputMaybe<Club_Status>;
  readonly themes?: InputMaybe<Themes_Arr_Rel_Insert_Input>;
  readonly tutorials?: InputMaybe<Tutorials_Arr_Rel_Insert_Input>;
  readonly typeform_name?: InputMaybe<Scalars['String']['input']>;
  readonly unlock_time?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly url_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Clubs_Max_Fields = {
  readonly __typename?: 'clubs_max_fields';
  readonly access_feature_id?: Maybe<Scalars['uuid']['output']>;
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly lock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly privacy?: Maybe<Scalars['String']['output']>;
  readonly schedule_id?: Maybe<Scalars['uuid']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly typeform_name?: Maybe<Scalars['String']['output']>;
  readonly unlock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly url_name?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "clubs" */
export type Clubs_Max_Order_By = {
  readonly access_feature_id?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly lock_time?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly privacy?: InputMaybe<Order_By>;
  readonly schedule_id?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly typeform_name?: InputMaybe<Order_By>;
  readonly unlock_time?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly url_name?: InputMaybe<Order_By>;
};

/** columns and relationships of "clubs_members" */
export type Clubs_Members = {
  readonly __typename?: 'clubs_members';
  /** An object relationship */
  readonly club: Clubs;
  readonly club_id: Scalars['uuid']['output'];
  readonly last_join?: Maybe<Scalars['date']['output']>;
  readonly onboarded: Scalars['Boolean']['output'];
  readonly onboarding?: Maybe<ReadonlyArray<Club_Onboarding_Requirement>>;
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};


/** columns and relationships of "clubs_members" */
export type Clubs_MembersOnboardingArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "clubs_members" */
export type Clubs_Members_Aggregate = {
  readonly __typename?: 'clubs_members_aggregate';
  readonly aggregate?: Maybe<Clubs_Members_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Clubs_Members>;
};

export type Clubs_Members_Aggregate_Bool_Exp = {
  readonly bool_and?: InputMaybe<Clubs_Members_Aggregate_Bool_Exp_Bool_And>;
  readonly bool_or?: InputMaybe<Clubs_Members_Aggregate_Bool_Exp_Bool_Or>;
  readonly count?: InputMaybe<Clubs_Members_Aggregate_Bool_Exp_Count>;
};

export type Clubs_Members_Aggregate_Bool_Exp_Bool_And = {
  readonly arguments: Clubs_Members_Select_Column_Clubs_Members_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Clubs_Members_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Clubs_Members_Aggregate_Bool_Exp_Bool_Or = {
  readonly arguments: Clubs_Members_Select_Column_Clubs_Members_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Clubs_Members_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Clubs_Members_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Clubs_Members_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Clubs_Members_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "clubs_members" */
export type Clubs_Members_Aggregate_Fields = {
  readonly __typename?: 'clubs_members_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Clubs_Members_Max_Fields>;
  readonly min?: Maybe<Clubs_Members_Min_Fields>;
};


/** aggregate fields of "clubs_members" */
export type Clubs_Members_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Clubs_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "clubs_members" */
export type Clubs_Members_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Clubs_Members_Max_Order_By>;
  readonly min?: InputMaybe<Clubs_Members_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Clubs_Members_Append_Input = {
  readonly onboarding?: InputMaybe<Club_Onboarding_Requirement>;
};

/** input type for inserting array relation for remote table "clubs_members" */
export type Clubs_Members_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Clubs_Members_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Clubs_Members_On_Conflict>;
};

/** Boolean expression to filter rows from the table "clubs_members". All fields are combined with a logical 'AND'. */
export type Clubs_Members_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Clubs_Members_Bool_Exp>>;
  readonly _not?: InputMaybe<Clubs_Members_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Clubs_Members_Bool_Exp>>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly club_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly last_join?: InputMaybe<Date_Comparison_Exp>;
  readonly onboarded?: InputMaybe<Boolean_Comparison_Exp>;
  readonly onboarding?: InputMaybe<Club_Onboarding_Requirement_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "clubs_members" */
export enum Clubs_Members_Constraint {
  /** unique or primary key constraint on columns "user_id", "club_id" */
  ClubsMembersPkey = 'clubs_members_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Clubs_Members_Delete_At_Path_Input = {
  readonly onboarding?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Clubs_Members_Delete_Elem_Input = {
  readonly onboarding?: InputMaybe<Club_Onboarding_Requirement>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Clubs_Members_Delete_Key_Input = {
  readonly onboarding?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "clubs_members" */
export type Clubs_Members_Insert_Input = {
  readonly club?: InputMaybe<Clubs_Obj_Rel_Insert_Input>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly last_join?: InputMaybe<Scalars['date']['input']>;
  readonly onboarded?: InputMaybe<Scalars['Boolean']['input']>;
  readonly onboarding?: InputMaybe<ReadonlyArray<Club_Onboarding_Requirement>>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Clubs_Members_Max_Fields = {
  readonly __typename?: 'clubs_members_max_fields';
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly last_join?: Maybe<Scalars['date']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "clubs_members" */
export type Clubs_Members_Max_Order_By = {
  readonly club_id?: InputMaybe<Order_By>;
  readonly last_join?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Clubs_Members_Min_Fields = {
  readonly __typename?: 'clubs_members_min_fields';
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly last_join?: Maybe<Scalars['date']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "clubs_members" */
export type Clubs_Members_Min_Order_By = {
  readonly club_id?: InputMaybe<Order_By>;
  readonly last_join?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "clubs_members" */
export type Clubs_Members_Mutation_Response = {
  readonly __typename?: 'clubs_members_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Clubs_Members>;
};

/** on_conflict condition type for table "clubs_members" */
export type Clubs_Members_On_Conflict = {
  readonly constraint: Clubs_Members_Constraint;
  readonly update_columns?: ReadonlyArray<Clubs_Members_Update_Column>;
  readonly where?: InputMaybe<Clubs_Members_Bool_Exp>;
};

/** Ordering options when selecting data from "clubs_members". */
export type Clubs_Members_Order_By = {
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly last_join?: InputMaybe<Order_By>;
  readonly onboarded?: InputMaybe<Order_By>;
  readonly onboarding?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clubs_members */
export type Clubs_Members_Pk_Columns_Input = {
  readonly club_id: Scalars['uuid']['input'];
  readonly user_id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Clubs_Members_Prepend_Input = {
  readonly onboarding?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "clubs_members" */
export enum Clubs_Members_Select_Column {
  /** column name */
  ClubId = 'club_id',
  /** column name */
  LastJoin = 'last_join',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  Onboarding = 'onboarding',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "clubs_members_aggregate_bool_exp_bool_and_arguments_columns" columns of table "clubs_members" */
export enum Clubs_Members_Select_Column_Clubs_Members_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Onboarded = 'onboarded'
}

/** select "clubs_members_aggregate_bool_exp_bool_or_arguments_columns" columns of table "clubs_members" */
export enum Clubs_Members_Select_Column_Clubs_Members_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Onboarded = 'onboarded'
}

/** input type for updating data in table "clubs_members" */
export type Clubs_Members_Set_Input = {
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly last_join?: InputMaybe<Scalars['date']['input']>;
  readonly onboarded?: InputMaybe<Scalars['Boolean']['input']>;
  readonly onboarding?: InputMaybe<ReadonlyArray<Club_Onboarding_Requirement>>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "clubs_members" */
export type Clubs_Members_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Clubs_Members_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clubs_Members_Stream_Cursor_Value_Input = {
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly last_join?: InputMaybe<Scalars['date']['input']>;
  readonly onboarded?: InputMaybe<Scalars['Boolean']['input']>;
  readonly onboarding?: InputMaybe<Scalars['jsonb']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "clubs_members" */
export enum Clubs_Members_Update_Column {
  /** column name */
  ClubId = 'club_id',
  /** column name */
  LastJoin = 'last_join',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  Onboarding = 'onboarding',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Clubs_Members_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Clubs_Members_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Clubs_Members_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Clubs_Members_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Clubs_Members_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Clubs_Members_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Clubs_Members_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Clubs_Members_Bool_Exp;
};

/** aggregate min on columns */
export type Clubs_Min_Fields = {
  readonly __typename?: 'clubs_min_fields';
  readonly access_feature_id?: Maybe<Scalars['uuid']['output']>;
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly lock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly privacy?: Maybe<Scalars['String']['output']>;
  readonly schedule_id?: Maybe<Scalars['uuid']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly typeform_name?: Maybe<Scalars['String']['output']>;
  readonly unlock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly url_name?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "clubs" */
export type Clubs_Min_Order_By = {
  readonly access_feature_id?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly lock_time?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly privacy?: InputMaybe<Order_By>;
  readonly schedule_id?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly typeform_name?: InputMaybe<Order_By>;
  readonly unlock_time?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly url_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "clubs" */
export type Clubs_Mutation_Response = {
  readonly __typename?: 'clubs_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Clubs>;
};

/** input type for inserting object relation for remote table "clubs" */
export type Clubs_Obj_Rel_Insert_Input = {
  readonly data: Clubs_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Clubs_On_Conflict>;
};

/** on_conflict condition type for table "clubs" */
export type Clubs_On_Conflict = {
  readonly constraint: Clubs_Constraint;
  readonly update_columns?: ReadonlyArray<Clubs_Update_Column>;
  readonly where?: InputMaybe<Clubs_Bool_Exp>;
};

/** Ordering options when selecting data from "clubs". */
export type Clubs_Order_By = {
  readonly access_feature?: InputMaybe<Features_Order_By>;
  readonly access_feature_id?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly badges_aggregate?: InputMaybe<Badges_Aggregate_Order_By>;
  readonly clipboard_prompt?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly display_settings?: InputMaybe<Order_By>;
  readonly feeds_aggregate?: InputMaybe<Feeds_Aggregate_Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly lock_time?: InputMaybe<Order_By>;
  readonly members_aggregate?: InputMaybe<Clubs_Members_Aggregate_Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly onboarding?: InputMaybe<Order_By>;
  readonly onboarding_requirements?: InputMaybe<Order_By>;
  readonly privacy?: InputMaybe<Order_By>;
  readonly projects_aggregate?: InputMaybe<Projects_Aggregate_Order_By>;
  readonly prospective_users_purchases_aggregate?: InputMaybe<Prospective_Users_Purchases_Aggregate_Order_By>;
  readonly quests_aggregate?: InputMaybe<Quests_Aggregate_Order_By>;
  readonly rooms_aggregate?: InputMaybe<Rooms_Aggregate_Order_By>;
  readonly schedule?: InputMaybe<Schedules_Order_By>;
  readonly schedule_id?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly themes_aggregate?: InputMaybe<Themes_Aggregate_Order_By>;
  readonly tutorials_aggregate?: InputMaybe<Tutorials_Aggregate_Order_By>;
  readonly typeform_name?: InputMaybe<Order_By>;
  readonly unlock_time?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly url_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clubs */
export type Clubs_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Clubs_Prepend_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['jsonb']['input']>;
  readonly display_settings?: InputMaybe<Scalars['jsonb']['input']>;
  readonly onboarding?: InputMaybe<Scalars['jsonb']['input']>;
  readonly onboarding_requirements?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "clubs" */
export enum Clubs_Select_Column {
  /** column name */
  AccessFeatureId = 'access_feature_id',
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  ClipboardPrompt = 'clipboard_prompt',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplaySettings = 'display_settings',
  /** column name */
  Id = 'id',
  /** column name */
  LockTime = 'lock_time',
  /** column name */
  Name = 'name',
  /** column name */
  Onboarding = 'onboarding',
  /** column name */
  OnboardingRequirements = 'onboarding_requirements',
  /** column name */
  Privacy = 'privacy',
  /** column name */
  ScheduleId = 'schedule_id',
  /** column name */
  Status = 'status',
  /** column name */
  TypeformName = 'typeform_name',
  /** column name */
  UnlockTime = 'unlock_time',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UrlName = 'url_name'
}

/** input type for updating data in table "clubs" */
export type Clubs_Set_Input = {
  readonly access_feature_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly clipboard_prompt?: InputMaybe<Scalars['clipboard_prompt']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly display_settings?: InputMaybe<Scalars['jsonb']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly lock_time?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly mute?: InputMaybe<Scalars['clubs_display_settings']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly onboarding?: InputMaybe<Scalars['clubs_onboarding']['input']>;
  readonly onboarding_requirements?: InputMaybe<ReadonlyArray<Club_Onboarding_Requirement>>;
  readonly privacy?: InputMaybe<Club_Privacy>;
  readonly schedule_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly status?: InputMaybe<Club_Status>;
  readonly typeform_name?: InputMaybe<Scalars['String']['input']>;
  readonly unlock_time?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly url_name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "clubs" */
export type Clubs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Clubs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clubs_Stream_Cursor_Value_Input = {
  readonly access_feature_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly clipboard_prompt?: InputMaybe<Scalars['jsonb']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly display_settings?: InputMaybe<Scalars['jsonb']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly lock_time?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly onboarding?: InputMaybe<Scalars['jsonb']['input']>;
  readonly onboarding_requirements?: InputMaybe<Scalars['jsonb']['input']>;
  readonly privacy?: InputMaybe<Scalars['String']['input']>;
  readonly schedule_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly typeform_name?: InputMaybe<Scalars['String']['input']>;
  readonly unlock_time?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly url_name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "clubs" */
export enum Clubs_Update_Column {
  /** column name */
  AccessFeatureId = 'access_feature_id',
  /** column name */
  ClipboardPrompt = 'clipboard_prompt',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplaySettings = 'display_settings',
  /** column name */
  Id = 'id',
  /** column name */
  LockTime = 'lock_time',
  /** column name */
  Name = 'name',
  /** column name */
  Onboarding = 'onboarding',
  /** column name */
  OnboardingRequirements = 'onboarding_requirements',
  /** column name */
  Privacy = 'privacy',
  /** column name */
  ScheduleId = 'schedule_id',
  /** column name */
  Status = 'status',
  /** column name */
  TypeformName = 'typeform_name',
  /** column name */
  UnlockTime = 'unlock_time',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UrlName = 'url_name'
}

export type Clubs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Clubs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Clubs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Clubs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Clubs_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Clubs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Clubs_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Clubs_Bool_Exp;
};

export enum Comment_Object_Type {
  Competitions = 'competitions',
  Orders = 'orders',
  Posts = 'posts',
  Projects = 'projects',
  Themes = 'themes'
}

export type Comment_Object_Type_Bool_Exp = {
  readonly _eq?: InputMaybe<Comment_Object_Type>;
  readonly _in?: InputMaybe<ReadonlyArray<Comment_Object_Type>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Comment_Object_Type>;
  readonly _nin?: InputMaybe<ReadonlyArray<Comment_Object_Type>>;
};

/** columns and relationships of "comments" */
export type Comments = {
  readonly __typename?: 'comments';
  readonly association_id: Scalars['String']['output'];
  readonly content: Scalars['String']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id: Scalars['uuid']['output'];
  readonly object_id: Scalars['String']['output'];
  readonly object_type: Comment_Object_Type;
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
  /** An array relationship */
  readonly votes: ReadonlyArray<Comments_Votes>;
  /** An aggregate relationship */
  readonly votes_aggregate: Comments_Votes_Aggregate;
};


/** columns and relationships of "comments" */
export type CommentsVotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Votes_Order_By>>;
  where?: InputMaybe<Comments_Votes_Bool_Exp>;
};


/** columns and relationships of "comments" */
export type CommentsVotes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Votes_Order_By>>;
  where?: InputMaybe<Comments_Votes_Bool_Exp>;
};

/** aggregated selection of "comments" */
export type Comments_Aggregate = {
  readonly __typename?: 'comments_aggregate';
  readonly aggregate?: Maybe<Comments_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Comments>;
};

export type Comments_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Comments_Aggregate_Bool_Exp_Count>;
};

export type Comments_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Comments_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "comments" */
export type Comments_Aggregate_Fields = {
  readonly __typename?: 'comments_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Comments_Max_Fields>;
  readonly min?: Maybe<Comments_Min_Fields>;
};


/** aggregate fields of "comments" */
export type Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "comments" */
export type Comments_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Comments_Max_Order_By>;
  readonly min?: InputMaybe<Comments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "comments" */
export type Comments_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Comments_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Comments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "comments". All fields are combined with a logical 'AND'. */
export type Comments_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Comments_Bool_Exp>>;
  readonly _not?: InputMaybe<Comments_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Comments_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly content?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly object_id?: InputMaybe<String_Comparison_Exp>;
  readonly object_type?: InputMaybe<Comment_Object_Type_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
  readonly votes?: InputMaybe<Comments_Votes_Bool_Exp>;
  readonly votes_aggregate?: InputMaybe<Comments_Votes_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "comments" */
export enum Comments_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectsCommentsPkey = 'projects_comments_pkey'
}

export type Comments_Delete_Args = {
  readonly comment_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** input type for inserting data into table "comments" */
export type Comments_Insert_Input = {
  readonly content?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly object_id?: InputMaybe<Scalars['String']['input']>;
  readonly object_type?: InputMaybe<Comment_Object_Type>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly votes?: InputMaybe<Comments_Votes_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Comments_Max_Fields = {
  readonly __typename?: 'comments_max_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly content?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly object_id?: Maybe<Scalars['String']['output']>;
  readonly object_type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "comments" */
export type Comments_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly content?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly object_id?: InputMaybe<Order_By>;
  readonly object_type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Comments_Min_Fields = {
  readonly __typename?: 'comments_min_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly content?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly object_id?: Maybe<Scalars['String']['output']>;
  readonly object_type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "comments" */
export type Comments_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly content?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly object_id?: InputMaybe<Order_By>;
  readonly object_type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "comments" */
export type Comments_Mutation_Response = {
  readonly __typename?: 'comments_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Comments>;
};

/** input type for inserting object relation for remote table "comments" */
export type Comments_Obj_Rel_Insert_Input = {
  readonly data: Comments_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Comments_On_Conflict>;
};

/** on_conflict condition type for table "comments" */
export type Comments_On_Conflict = {
  readonly constraint: Comments_Constraint;
  readonly update_columns?: ReadonlyArray<Comments_Update_Column>;
  readonly where?: InputMaybe<Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "comments". */
export type Comments_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly content?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly object_id?: InputMaybe<Order_By>;
  readonly object_type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly votes_aggregate?: InputMaybe<Comments_Votes_Aggregate_Order_By>;
};

/** primary key columns input for table: comments */
export type Comments_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "comments" */
export enum Comments_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectId = 'object_id',
  /** column name */
  ObjectType = 'object_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "comments" */
export type Comments_Set_Input = {
  readonly content?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly object_id?: InputMaybe<Scalars['String']['input']>;
  readonly object_type?: InputMaybe<Comment_Object_Type>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "comments" */
export type Comments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Comments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comments_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly content?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly object_id?: InputMaybe<Scalars['String']['input']>;
  readonly object_type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "comments" */
export enum Comments_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectId = 'object_id',
  /** column name */
  ObjectType = 'object_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Comments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Comments_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Comments_Bool_Exp;
};

/** columns and relationships of "comments_votes" */
export type Comments_Votes = {
  readonly __typename?: 'comments_votes';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly comment: Comments;
  readonly comment_id: Scalars['uuid']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly emote_hex: Scalars['String']['output'];
  readonly emote_name: Scalars['String']['output'];
  readonly id: Scalars['uuid']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};

/** aggregated selection of "comments_votes" */
export type Comments_Votes_Aggregate = {
  readonly __typename?: 'comments_votes_aggregate';
  readonly aggregate?: Maybe<Comments_Votes_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Comments_Votes>;
};

export type Comments_Votes_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Comments_Votes_Aggregate_Bool_Exp_Count>;
};

export type Comments_Votes_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Comments_Votes_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Comments_Votes_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "comments_votes" */
export type Comments_Votes_Aggregate_Fields = {
  readonly __typename?: 'comments_votes_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Comments_Votes_Max_Fields>;
  readonly min?: Maybe<Comments_Votes_Min_Fields>;
};


/** aggregate fields of "comments_votes" */
export type Comments_Votes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Comments_Votes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "comments_votes" */
export type Comments_Votes_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Comments_Votes_Max_Order_By>;
  readonly min?: InputMaybe<Comments_Votes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "comments_votes" */
export type Comments_Votes_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Comments_Votes_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Comments_Votes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "comments_votes". All fields are combined with a logical 'AND'. */
export type Comments_Votes_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Comments_Votes_Bool_Exp>>;
  readonly _not?: InputMaybe<Comments_Votes_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Comments_Votes_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly comment?: InputMaybe<Comments_Bool_Exp>;
  readonly comment_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly emote_hex?: InputMaybe<String_Comparison_Exp>;
  readonly emote_name?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "comments_votes" */
export enum Comments_Votes_Constraint {
  /** unique or primary key constraint on columns "comment_id", "user_id", "emote_name" */
  CommentsVotesCommentIdUserIdEmoteNameKey = 'comments_votes_comment_id_user_id_emote_name_key',
  /** unique or primary key constraint on columns "id" */
  CommentsVotesPkey = 'comments_votes_pkey'
}

/** input type for inserting data into table "comments_votes" */
export type Comments_Votes_Insert_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly comment?: InputMaybe<Comments_Obj_Rel_Insert_Input>;
  readonly comment_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly emote_hex?: InputMaybe<Scalars['String']['input']>;
  readonly emote_name?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Comments_Votes_Max_Fields = {
  readonly __typename?: 'comments_votes_max_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly comment_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly emote_hex?: Maybe<Scalars['String']['output']>;
  readonly emote_name?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "comments_votes" */
export type Comments_Votes_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly comment_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly emote_hex?: InputMaybe<Order_By>;
  readonly emote_name?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Comments_Votes_Min_Fields = {
  readonly __typename?: 'comments_votes_min_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly comment_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly emote_hex?: Maybe<Scalars['String']['output']>;
  readonly emote_name?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "comments_votes" */
export type Comments_Votes_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly comment_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly emote_hex?: InputMaybe<Order_By>;
  readonly emote_name?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "comments_votes" */
export type Comments_Votes_Mutation_Response = {
  readonly __typename?: 'comments_votes_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Comments_Votes>;
};

/** on_conflict condition type for table "comments_votes" */
export type Comments_Votes_On_Conflict = {
  readonly constraint: Comments_Votes_Constraint;
  readonly update_columns?: ReadonlyArray<Comments_Votes_Update_Column>;
  readonly where?: InputMaybe<Comments_Votes_Bool_Exp>;
};

/** Ordering options when selecting data from "comments_votes". */
export type Comments_Votes_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly comment?: InputMaybe<Comments_Order_By>;
  readonly comment_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly emote_hex?: InputMaybe<Order_By>;
  readonly emote_name?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: comments_votes */
export type Comments_Votes_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "comments_votes" */
export enum Comments_Votes_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmoteHex = 'emote_hex',
  /** column name */
  EmoteName = 'emote_name',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "comments_votes" */
export type Comments_Votes_Set_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly comment_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly emote_hex?: InputMaybe<Scalars['String']['input']>;
  readonly emote_name?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "comments_votes" */
export type Comments_Votes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Comments_Votes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comments_Votes_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly comment_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly emote_hex?: InputMaybe<Scalars['String']['input']>;
  readonly emote_name?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "comments_votes" */
export enum Comments_Votes_Update_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmoteHex = 'emote_hex',
  /** column name */
  EmoteName = 'emote_name',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export type Comments_Votes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Comments_Votes_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Comments_Votes_Bool_Exp;
};

export enum Competition_Status {
  Active = 'active',
  Ended = 'ended',
  Hidden = 'hidden',
  Preregistration = 'preregistration',
  Review = 'review',
  Submission = 'submission'
}

export type Competition_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<Competition_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<Competition_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Competition_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<Competition_Status>>;
};

export enum Competition_Type {
  Challenge = 'challenge',
  Live = 'live',
  Long = 'long'
}

export type Competition_Type_Bool_Exp = {
  readonly _eq?: InputMaybe<Competition_Type>;
  readonly _in?: InputMaybe<ReadonlyArray<Competition_Type>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Competition_Type>;
  readonly _nin?: InputMaybe<ReadonlyArray<Competition_Type>>;
};

/** columns and relationships of "competitions" */
export type Competitions = {
  readonly __typename?: 'competitions';
  readonly association_id: Scalars['String']['output'];
  /** An array relationship */
  readonly comments: ReadonlyArray<Comments>;
  /** An aggregate relationship */
  readonly comments_aggregate: Comments_Aggregate;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly default_project_name?: Maybe<Scalars['String']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly due_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly ends_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly gif?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly host: Users;
  readonly host_id: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  /** An array relationship */
  readonly judges: ReadonlyArray<Competitions_Judges>;
  /** An aggregate relationship */
  readonly judges_aggregate: Competitions_Judges_Aggregate;
  readonly max_age_allowed?: Maybe<Scalars['Int']['output']>;
  readonly max_team_size: Scalars['Int']['output'];
  readonly meta?: Maybe<Scalars['competitions_meta']['output']>;
  readonly name: Scalars['String']['output'];
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  readonly photo: Scalars['String']['output'];
  readonly prize_meta?: Maybe<Scalars['competitions_prize_meta']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
  readonly prizes_distributed: Scalars['Boolean']['output'];
  /** An array relationship */
  readonly projects: ReadonlyArray<Projects>;
  /** An aggregate relationship */
  readonly projects_aggregate: Projects_Aggregate;
  /** An object relationship */
  readonly room?: Maybe<Rooms>;
  readonly room_id?: Maybe<Scalars['uuid']['output']>;
  readonly rules?: Maybe<Scalars['String']['output']>;
  readonly special_prizes?: Maybe<Scalars['jsonb']['output']>;
  readonly starts_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly status: Competition_Status;
  readonly submission_form_link?: Maybe<Scalars['String']['output']>;
  readonly submissions_reviewed: Scalars['Boolean']['output'];
  readonly tagline?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly tags: ReadonlyArray<Competitions_Tags>;
  /** An aggregate relationship */
  readonly tags_aggregate: Competitions_Tags_Aggregate;
  /** An object relationship */
  readonly theme?: Maybe<Themes>;
  readonly theme_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  readonly tool?: Maybe<Tools>;
  readonly tool_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly topic?: Maybe<Topics>;
  readonly topic_id?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly transactions: ReadonlyArray<Transactions>;
  /** An aggregate relationship */
  readonly transactions_aggregate: Transactions_Aggregate;
  /** An array relationship */
  readonly tutorials: ReadonlyArray<Competitions_Tutorials>;
  /** An aggregate relationship */
  readonly tutorials_aggregate: Competitions_Tutorials_Aggregate;
  readonly type: Competition_Type;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly url_name: Scalars['String']['output'];
  /** An array relationship */
  readonly votes: ReadonlyArray<Votes>;
  /** An aggregate relationship */
  readonly votes_aggregate: Votes_Aggregate;
};


/** columns and relationships of "competitions" */
export type CompetitionsCommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsComments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsJudgesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Judges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Judges_Order_By>>;
  where?: InputMaybe<Competitions_Judges_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsJudges_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Judges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Judges_Order_By>>;
  where?: InputMaybe<Competitions_Judges_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "competitions" */
export type CompetitionsPrize_MetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "competitions" */
export type CompetitionsProjectsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsProjects_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsSpecial_PrizesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "competitions" */
export type CompetitionsTagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tags_Order_By>>;
  where?: InputMaybe<Competitions_Tags_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsTags_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tags_Order_By>>;
  where?: InputMaybe<Competitions_Tags_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsTransactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsTutorialsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Order_By>>;
  where?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsTutorials_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Order_By>>;
  where?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsVotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


/** columns and relationships of "competitions" */
export type CompetitionsVotes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};

/** aggregated selection of "competitions" */
export type Competitions_Aggregate = {
  readonly __typename?: 'competitions_aggregate';
  readonly aggregate?: Maybe<Competitions_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Competitions>;
};

export type Competitions_Aggregate_Bool_Exp = {
  readonly bool_and?: InputMaybe<Competitions_Aggregate_Bool_Exp_Bool_And>;
  readonly bool_or?: InputMaybe<Competitions_Aggregate_Bool_Exp_Bool_Or>;
  readonly count?: InputMaybe<Competitions_Aggregate_Bool_Exp_Count>;
};

export type Competitions_Aggregate_Bool_Exp_Bool_And = {
  readonly arguments: Competitions_Select_Column_Competitions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Competitions_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Competitions_Aggregate_Bool_Exp_Bool_Or = {
  readonly arguments: Competitions_Select_Column_Competitions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Competitions_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Competitions_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Competitions_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "competitions" */
export type Competitions_Aggregate_Fields = {
  readonly __typename?: 'competitions_aggregate_fields';
  readonly avg?: Maybe<Competitions_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Competitions_Max_Fields>;
  readonly min?: Maybe<Competitions_Min_Fields>;
  readonly stddev?: Maybe<Competitions_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Competitions_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Competitions_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Competitions_Sum_Fields>;
  readonly var_pop?: Maybe<Competitions_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Competitions_Var_Samp_Fields>;
  readonly variance?: Maybe<Competitions_Variance_Fields>;
};


/** aggregate fields of "competitions" */
export type Competitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "competitions" */
export type Competitions_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Competitions_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Competitions_Max_Order_By>;
  readonly min?: InputMaybe<Competitions_Min_Order_By>;
  readonly stddev?: InputMaybe<Competitions_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Competitions_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Competitions_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Competitions_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Competitions_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Competitions_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Competitions_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Competitions_Append_Input = {
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly prize_meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "competitions" */
export type Competitions_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Competitions_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Competitions_On_Conflict>;
};

/** aggregate avg on columns */
export type Competitions_Avg_Fields = {
  readonly __typename?: 'competitions_avg_fields';
  readonly max_age_allowed?: Maybe<Scalars['Float']['output']>;
  readonly max_team_size?: Maybe<Scalars['Float']['output']>;
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
};

/** order by avg() on columns of table "competitions" */
export type Competitions_Avg_Order_By = {
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "competitions". All fields are combined with a logical 'AND'. */
export type Competitions_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Competitions_Bool_Exp>>;
  readonly _not?: InputMaybe<Competitions_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Competitions_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly comments?: InputMaybe<Comments_Bool_Exp>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly default_project_name?: InputMaybe<String_Comparison_Exp>;
  readonly deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly description?: InputMaybe<String_Comparison_Exp>;
  readonly due_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly ends_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly gif?: InputMaybe<String_Comparison_Exp>;
  readonly host?: InputMaybe<Users_Bool_Exp>;
  readonly host_id?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly judges?: InputMaybe<Competitions_Judges_Bool_Exp>;
  readonly judges_aggregate?: InputMaybe<Competitions_Judges_Aggregate_Bool_Exp>;
  readonly max_age_allowed?: InputMaybe<Int_Comparison_Exp>;
  readonly max_team_size?: InputMaybe<Int_Comparison_Exp>;
  readonly meta?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly num_contestants?: InputMaybe<Bigint_Comparison_Exp>;
  readonly photo?: InputMaybe<String_Comparison_Exp>;
  readonly prize_meta?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly prize_pool?: InputMaybe<Numeric_Comparison_Exp>;
  readonly prizes_distributed?: InputMaybe<Boolean_Comparison_Exp>;
  readonly projects?: InputMaybe<Projects_Bool_Exp>;
  readonly projects_aggregate?: InputMaybe<Projects_Aggregate_Bool_Exp>;
  readonly room?: InputMaybe<Rooms_Bool_Exp>;
  readonly room_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly rules?: InputMaybe<String_Comparison_Exp>;
  readonly special_prizes?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly starts_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly status?: InputMaybe<Competition_Status_Bool_Exp>;
  readonly submission_form_link?: InputMaybe<String_Comparison_Exp>;
  readonly submissions_reviewed?: InputMaybe<Boolean_Comparison_Exp>;
  readonly tagline?: InputMaybe<String_Comparison_Exp>;
  readonly tags?: InputMaybe<Competitions_Tags_Bool_Exp>;
  readonly tags_aggregate?: InputMaybe<Competitions_Tags_Aggregate_Bool_Exp>;
  readonly theme?: InputMaybe<Themes_Bool_Exp>;
  readonly theme_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly tool?: InputMaybe<Tools_Bool_Exp>;
  readonly tool_id?: InputMaybe<String_Comparison_Exp>;
  readonly topic?: InputMaybe<Topics_Bool_Exp>;
  readonly topic_id?: InputMaybe<String_Comparison_Exp>;
  readonly transactions?: InputMaybe<Transactions_Bool_Exp>;
  readonly transactions_aggregate?: InputMaybe<Transactions_Aggregate_Bool_Exp>;
  readonly tutorials?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
  readonly tutorials_aggregate?: InputMaybe<Competitions_Tutorials_Aggregate_Bool_Exp>;
  readonly type?: InputMaybe<Competition_Type_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly url_name?: InputMaybe<String_Comparison_Exp>;
  readonly votes?: InputMaybe<Votes_Bool_Exp>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "competitions" */
export enum Competitions_Constraint {
  /** unique or primary key constraint on columns "name" */
  CompetitionsNameKey = 'competitions_name_key',
  /** unique or primary key constraint on columns "id" */
  CompetitionsPkey = 'competitions_pkey',
  /** unique or primary key constraint on columns "url_name" */
  CompetitionsUrlNameKey = 'competitions_url_name_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Competitions_Delete_At_Path_Input = {
  readonly meta?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly prize_meta?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly special_prizes?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Competitions_Delete_Elem_Input = {
  readonly meta?: InputMaybe<Scalars['Int']['input']>;
  readonly prize_meta?: InputMaybe<Scalars['Int']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Competitions_Delete_Key_Input = {
  readonly meta?: InputMaybe<Scalars['String']['input']>;
  readonly prize_meta?: InputMaybe<Scalars['String']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "competitions" */
export type Competitions_Inc_Input = {
  readonly max_age_allowed?: InputMaybe<Scalars['Int']['input']>;
  readonly max_team_size?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "competitions" */
export type Competitions_Insert_Input = {
  readonly comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly default_project_name?: InputMaybe<Scalars['String']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly due_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly ends_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly gif?: InputMaybe<Scalars['String']['input']>;
  readonly host?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly host_id?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly judges?: InputMaybe<Competitions_Judges_Arr_Rel_Insert_Input>;
  readonly max_age_allowed?: InputMaybe<Scalars['Int']['input']>;
  readonly max_team_size?: InputMaybe<Scalars['Int']['input']>;
  readonly meta?: InputMaybe<Scalars['competitions_meta']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly prize_meta?: InputMaybe<Scalars['competitions_prize_meta']['input']>;
  readonly prizes_distributed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly projects?: InputMaybe<Projects_Arr_Rel_Insert_Input>;
  readonly room?: InputMaybe<Rooms_Obj_Rel_Insert_Input>;
  readonly room_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly rules?: InputMaybe<Scalars['String']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['jsonb']['input']>;
  readonly starts_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly status?: InputMaybe<Competition_Status>;
  readonly submission_form_link?: InputMaybe<Scalars['String']['input']>;
  readonly submissions_reviewed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly tags?: InputMaybe<Competitions_Tags_Arr_Rel_Insert_Input>;
  readonly theme?: InputMaybe<Themes_Obj_Rel_Insert_Input>;
  readonly theme_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly tool?: InputMaybe<Tools_Obj_Rel_Insert_Input>;
  readonly tool_id?: InputMaybe<Scalars['String']['input']>;
  readonly topic?: InputMaybe<Topics_Obj_Rel_Insert_Input>;
  readonly topic_id?: InputMaybe<Scalars['String']['input']>;
  readonly transactions?: InputMaybe<Transactions_Arr_Rel_Insert_Input>;
  readonly tutorials?: InputMaybe<Competitions_Tutorials_Arr_Rel_Insert_Input>;
  readonly type?: InputMaybe<Competition_Type>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly url_name?: InputMaybe<Scalars['String']['input']>;
  readonly votes?: InputMaybe<Votes_Arr_Rel_Insert_Input>;
};

/** columns and relationships of "competitions_judges" */
export type Competitions_Judges = {
  readonly __typename?: 'competitions_judges';
  /** An object relationship */
  readonly competition: Competitions;
  readonly competition_id: Scalars['String']['output'];
  /** An object relationship */
  readonly judge: Users;
  readonly judge_id: Scalars['String']['output'];
};

/** aggregated selection of "competitions_judges" */
export type Competitions_Judges_Aggregate = {
  readonly __typename?: 'competitions_judges_aggregate';
  readonly aggregate?: Maybe<Competitions_Judges_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Competitions_Judges>;
};

export type Competitions_Judges_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Competitions_Judges_Aggregate_Bool_Exp_Count>;
};

export type Competitions_Judges_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Competitions_Judges_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Competitions_Judges_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "competitions_judges" */
export type Competitions_Judges_Aggregate_Fields = {
  readonly __typename?: 'competitions_judges_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Competitions_Judges_Max_Fields>;
  readonly min?: Maybe<Competitions_Judges_Min_Fields>;
};


/** aggregate fields of "competitions_judges" */
export type Competitions_Judges_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Competitions_Judges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "competitions_judges" */
export type Competitions_Judges_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Competitions_Judges_Max_Order_By>;
  readonly min?: InputMaybe<Competitions_Judges_Min_Order_By>;
};

/** input type for inserting array relation for remote table "competitions_judges" */
export type Competitions_Judges_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Competitions_Judges_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Competitions_Judges_On_Conflict>;
};

/** Boolean expression to filter rows from the table "competitions_judges". All fields are combined with a logical 'AND'. */
export type Competitions_Judges_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Competitions_Judges_Bool_Exp>>;
  readonly _not?: InputMaybe<Competitions_Judges_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Competitions_Judges_Bool_Exp>>;
  readonly competition?: InputMaybe<Competitions_Bool_Exp>;
  readonly competition_id?: InputMaybe<String_Comparison_Exp>;
  readonly judge?: InputMaybe<Users_Bool_Exp>;
  readonly judge_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "competitions_judges" */
export enum Competitions_Judges_Constraint {
  /** unique or primary key constraint on columns "judge_id", "competition_id" */
  CompetitionsJudgesPkey = 'competitions_judges_pkey'
}

/** input type for inserting data into table "competitions_judges" */
export type Competitions_Judges_Insert_Input = {
  readonly competition?: InputMaybe<Competitions_Obj_Rel_Insert_Input>;
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly judge?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly judge_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Competitions_Judges_Max_Fields = {
  readonly __typename?: 'competitions_judges_max_fields';
  readonly competition_id?: Maybe<Scalars['String']['output']>;
  readonly judge_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "competitions_judges" */
export type Competitions_Judges_Max_Order_By = {
  readonly competition_id?: InputMaybe<Order_By>;
  readonly judge_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Competitions_Judges_Min_Fields = {
  readonly __typename?: 'competitions_judges_min_fields';
  readonly competition_id?: Maybe<Scalars['String']['output']>;
  readonly judge_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "competitions_judges" */
export type Competitions_Judges_Min_Order_By = {
  readonly competition_id?: InputMaybe<Order_By>;
  readonly judge_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "competitions_judges" */
export type Competitions_Judges_Mutation_Response = {
  readonly __typename?: 'competitions_judges_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Competitions_Judges>;
};

/** on_conflict condition type for table "competitions_judges" */
export type Competitions_Judges_On_Conflict = {
  readonly constraint: Competitions_Judges_Constraint;
  readonly update_columns?: ReadonlyArray<Competitions_Judges_Update_Column>;
  readonly where?: InputMaybe<Competitions_Judges_Bool_Exp>;
};

/** Ordering options when selecting data from "competitions_judges". */
export type Competitions_Judges_Order_By = {
  readonly competition?: InputMaybe<Competitions_Order_By>;
  readonly competition_id?: InputMaybe<Order_By>;
  readonly judge?: InputMaybe<Users_Order_By>;
  readonly judge_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: competitions_judges */
export type Competitions_Judges_Pk_Columns_Input = {
  readonly competition_id: Scalars['String']['input'];
  readonly judge_id: Scalars['String']['input'];
};

/** select columns of table "competitions_judges" */
export enum Competitions_Judges_Select_Column {
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  JudgeId = 'judge_id'
}

/** input type for updating data in table "competitions_judges" */
export type Competitions_Judges_Set_Input = {
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly judge_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "competitions_judges" */
export type Competitions_Judges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Competitions_Judges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Judges_Stream_Cursor_Value_Input = {
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly judge_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "competitions_judges" */
export enum Competitions_Judges_Update_Column {
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  JudgeId = 'judge_id'
}

export type Competitions_Judges_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Competitions_Judges_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Competitions_Judges_Bool_Exp;
};

/** aggregate max on columns */
export type Competitions_Max_Fields = {
  readonly __typename?: 'competitions_max_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly default_project_name?: Maybe<Scalars['String']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly due_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly ends_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly gif?: Maybe<Scalars['String']['output']>;
  readonly host_id?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly max_age_allowed?: Maybe<Scalars['Int']['output']>;
  readonly max_team_size?: Maybe<Scalars['Int']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  readonly photo?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
  readonly room_id?: Maybe<Scalars['uuid']['output']>;
  readonly rules?: Maybe<Scalars['String']['output']>;
  readonly starts_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly submission_form_link?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly theme_id?: Maybe<Scalars['uuid']['output']>;
  readonly tool_id?: Maybe<Scalars['String']['output']>;
  readonly topic_id?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly url_name?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "competitions" */
export type Competitions_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly default_project_name?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly due_at?: InputMaybe<Order_By>;
  readonly ends_at?: InputMaybe<Order_By>;
  readonly gif?: InputMaybe<Order_By>;
  readonly host_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly room_id?: InputMaybe<Order_By>;
  readonly rules?: InputMaybe<Order_By>;
  readonly starts_at?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly submission_form_link?: InputMaybe<Order_By>;
  readonly tagline?: InputMaybe<Order_By>;
  readonly theme_id?: InputMaybe<Order_By>;
  readonly tool_id?: InputMaybe<Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly url_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Competitions_Min_Fields = {
  readonly __typename?: 'competitions_min_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly default_project_name?: Maybe<Scalars['String']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly due_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly ends_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly gif?: Maybe<Scalars['String']['output']>;
  readonly host_id?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly max_age_allowed?: Maybe<Scalars['Int']['output']>;
  readonly max_team_size?: Maybe<Scalars['Int']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  readonly photo?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
  readonly room_id?: Maybe<Scalars['uuid']['output']>;
  readonly rules?: Maybe<Scalars['String']['output']>;
  readonly starts_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly submission_form_link?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly theme_id?: Maybe<Scalars['uuid']['output']>;
  readonly tool_id?: Maybe<Scalars['String']['output']>;
  readonly topic_id?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly url_name?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "competitions" */
export type Competitions_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly default_project_name?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly due_at?: InputMaybe<Order_By>;
  readonly ends_at?: InputMaybe<Order_By>;
  readonly gif?: InputMaybe<Order_By>;
  readonly host_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly room_id?: InputMaybe<Order_By>;
  readonly rules?: InputMaybe<Order_By>;
  readonly starts_at?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly submission_form_link?: InputMaybe<Order_By>;
  readonly tagline?: InputMaybe<Order_By>;
  readonly theme_id?: InputMaybe<Order_By>;
  readonly tool_id?: InputMaybe<Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly url_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "competitions" */
export type Competitions_Mutation_Response = {
  readonly __typename?: 'competitions_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Competitions>;
};

/** input type for inserting object relation for remote table "competitions" */
export type Competitions_Obj_Rel_Insert_Input = {
  readonly data: Competitions_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Competitions_On_Conflict>;
};

/** on_conflict condition type for table "competitions" */
export type Competitions_On_Conflict = {
  readonly constraint: Competitions_Constraint;
  readonly update_columns?: ReadonlyArray<Competitions_Update_Column>;
  readonly where?: InputMaybe<Competitions_Bool_Exp>;
};

/** Ordering options when selecting data from "competitions". */
export type Competitions_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly default_project_name?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly due_at?: InputMaybe<Order_By>;
  readonly ends_at?: InputMaybe<Order_By>;
  readonly gif?: InputMaybe<Order_By>;
  readonly host?: InputMaybe<Users_Order_By>;
  readonly host_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly judges_aggregate?: InputMaybe<Competitions_Judges_Aggregate_Order_By>;
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
  readonly meta?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly num_contestants?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly prize_meta?: InputMaybe<Order_By>;
  readonly prize_pool?: InputMaybe<Order_By>;
  readonly prizes_distributed?: InputMaybe<Order_By>;
  readonly projects_aggregate?: InputMaybe<Projects_Aggregate_Order_By>;
  readonly room?: InputMaybe<Rooms_Order_By>;
  readonly room_id?: InputMaybe<Order_By>;
  readonly rules?: InputMaybe<Order_By>;
  readonly special_prizes?: InputMaybe<Order_By>;
  readonly starts_at?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly submission_form_link?: InputMaybe<Order_By>;
  readonly submissions_reviewed?: InputMaybe<Order_By>;
  readonly tagline?: InputMaybe<Order_By>;
  readonly tags_aggregate?: InputMaybe<Competitions_Tags_Aggregate_Order_By>;
  readonly theme?: InputMaybe<Themes_Order_By>;
  readonly theme_id?: InputMaybe<Order_By>;
  readonly tool?: InputMaybe<Tools_Order_By>;
  readonly tool_id?: InputMaybe<Order_By>;
  readonly topic?: InputMaybe<Topics_Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
  readonly transactions_aggregate?: InputMaybe<Transactions_Aggregate_Order_By>;
  readonly tutorials_aggregate?: InputMaybe<Competitions_Tutorials_Aggregate_Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly url_name?: InputMaybe<Order_By>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Order_By>;
};

/** primary key columns input for table: competitions */
export type Competitions_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Competitions_Prepend_Input = {
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly prize_meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "competitions" */
export enum Competitions_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultProjectName = 'default_project_name',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  DueAt = 'due_at',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  Gif = 'gif',
  /** column name */
  HostId = 'host_id',
  /** column name */
  Id = 'id',
  /** column name */
  MaxAgeAllowed = 'max_age_allowed',
  /** column name */
  MaxTeamSize = 'max_team_size',
  /** column name */
  Meta = 'meta',
  /** column name */
  Name = 'name',
  /** column name */
  Photo = 'photo',
  /** column name */
  PrizeMeta = 'prize_meta',
  /** column name */
  PrizesDistributed = 'prizes_distributed',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  Rules = 'rules',
  /** column name */
  SpecialPrizes = 'special_prizes',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  Status = 'status',
  /** column name */
  SubmissionFormLink = 'submission_form_link',
  /** column name */
  SubmissionsReviewed = 'submissions_reviewed',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  ThemeId = 'theme_id',
  /** column name */
  ToolId = 'tool_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UrlName = 'url_name'
}

/** select "competitions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "competitions" */
export enum Competitions_Select_Column_Competitions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  PrizesDistributed = 'prizes_distributed',
  /** column name */
  SubmissionsReviewed = 'submissions_reviewed'
}

/** select "competitions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "competitions" */
export enum Competitions_Select_Column_Competitions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  PrizesDistributed = 'prizes_distributed',
  /** column name */
  SubmissionsReviewed = 'submissions_reviewed'
}

/** input type for updating data in table "competitions" */
export type Competitions_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly default_project_name?: InputMaybe<Scalars['String']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly due_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly ends_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly gif?: InputMaybe<Scalars['String']['input']>;
  readonly host_id?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly max_age_allowed?: InputMaybe<Scalars['Int']['input']>;
  readonly max_team_size?: InputMaybe<Scalars['Int']['input']>;
  readonly meta?: InputMaybe<Scalars['competitions_meta']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly prize_meta?: InputMaybe<Scalars['competitions_prize_meta']['input']>;
  readonly prizes_distributed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly room_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly rules?: InputMaybe<Scalars['String']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['jsonb']['input']>;
  readonly starts_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly status?: InputMaybe<Competition_Status>;
  readonly submission_form_link?: InputMaybe<Scalars['String']['input']>;
  readonly submissions_reviewed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly theme_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly tool_id?: InputMaybe<Scalars['String']['input']>;
  readonly topic_id?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Competition_Type>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly url_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Competitions_Stddev_Fields = {
  readonly __typename?: 'competitions_stddev_fields';
  readonly max_age_allowed?: Maybe<Scalars['Float']['output']>;
  readonly max_team_size?: Maybe<Scalars['Float']['output']>;
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
};

/** order by stddev() on columns of table "competitions" */
export type Competitions_Stddev_Order_By = {
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Competitions_Stddev_Pop_Fields = {
  readonly __typename?: 'competitions_stddev_pop_fields';
  readonly max_age_allowed?: Maybe<Scalars['Float']['output']>;
  readonly max_team_size?: Maybe<Scalars['Float']['output']>;
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
};

/** order by stddev_pop() on columns of table "competitions" */
export type Competitions_Stddev_Pop_Order_By = {
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Competitions_Stddev_Samp_Fields = {
  readonly __typename?: 'competitions_stddev_samp_fields';
  readonly max_age_allowed?: Maybe<Scalars['Float']['output']>;
  readonly max_team_size?: Maybe<Scalars['Float']['output']>;
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
};

/** order by stddev_samp() on columns of table "competitions" */
export type Competitions_Stddev_Samp_Order_By = {
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "competitions" */
export type Competitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Competitions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly default_project_name?: InputMaybe<Scalars['String']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly due_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly ends_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly gif?: InputMaybe<Scalars['String']['input']>;
  readonly host_id?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly max_age_allowed?: InputMaybe<Scalars['Int']['input']>;
  readonly max_team_size?: InputMaybe<Scalars['Int']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly prize_meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly prizes_distributed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly room_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly rules?: InputMaybe<Scalars['String']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['jsonb']['input']>;
  readonly starts_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly submission_form_link?: InputMaybe<Scalars['String']['input']>;
  readonly submissions_reviewed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly theme_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly tool_id?: InputMaybe<Scalars['String']['input']>;
  readonly topic_id?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly url_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Competitions_Sum_Fields = {
  readonly __typename?: 'competitions_sum_fields';
  readonly max_age_allowed?: Maybe<Scalars['Int']['output']>;
  readonly max_team_size?: Maybe<Scalars['Int']['output']>;
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "competitions" */
export type Competitions_Sum_Order_By = {
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
};

/** columns and relationships of "competitions_tags" */
export type Competitions_Tags = {
  readonly __typename?: 'competitions_tags';
  /** An object relationship */
  readonly competition: Competitions;
  readonly competition_id: Scalars['String']['output'];
  /** An object relationship */
  readonly tag: Tags;
  readonly tag_id: Scalars['String']['output'];
};

/** aggregated selection of "competitions_tags" */
export type Competitions_Tags_Aggregate = {
  readonly __typename?: 'competitions_tags_aggregate';
  readonly aggregate?: Maybe<Competitions_Tags_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Competitions_Tags>;
};

export type Competitions_Tags_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Competitions_Tags_Aggregate_Bool_Exp_Count>;
};

export type Competitions_Tags_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Competitions_Tags_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Competitions_Tags_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "competitions_tags" */
export type Competitions_Tags_Aggregate_Fields = {
  readonly __typename?: 'competitions_tags_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Competitions_Tags_Max_Fields>;
  readonly min?: Maybe<Competitions_Tags_Min_Fields>;
};


/** aggregate fields of "competitions_tags" */
export type Competitions_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Competitions_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "competitions_tags" */
export type Competitions_Tags_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Competitions_Tags_Max_Order_By>;
  readonly min?: InputMaybe<Competitions_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "competitions_tags" */
export type Competitions_Tags_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Competitions_Tags_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Competitions_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "competitions_tags". All fields are combined with a logical 'AND'. */
export type Competitions_Tags_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Competitions_Tags_Bool_Exp>>;
  readonly _not?: InputMaybe<Competitions_Tags_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Competitions_Tags_Bool_Exp>>;
  readonly competition?: InputMaybe<Competitions_Bool_Exp>;
  readonly competition_id?: InputMaybe<String_Comparison_Exp>;
  readonly tag?: InputMaybe<Tags_Bool_Exp>;
  readonly tag_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "competitions_tags" */
export enum Competitions_Tags_Constraint {
  /** unique or primary key constraint on columns "competition_id", "tag_id" */
  CompetitionsTagsPkey = 'competitions_tags_pkey'
}

/** input type for inserting data into table "competitions_tags" */
export type Competitions_Tags_Insert_Input = {
  readonly competition?: InputMaybe<Competitions_Obj_Rel_Insert_Input>;
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly tag?: InputMaybe<Tags_Obj_Rel_Insert_Input>;
  readonly tag_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Competitions_Tags_Max_Fields = {
  readonly __typename?: 'competitions_tags_max_fields';
  readonly competition_id?: Maybe<Scalars['String']['output']>;
  readonly tag_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "competitions_tags" */
export type Competitions_Tags_Max_Order_By = {
  readonly competition_id?: InputMaybe<Order_By>;
  readonly tag_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Competitions_Tags_Min_Fields = {
  readonly __typename?: 'competitions_tags_min_fields';
  readonly competition_id?: Maybe<Scalars['String']['output']>;
  readonly tag_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "competitions_tags" */
export type Competitions_Tags_Min_Order_By = {
  readonly competition_id?: InputMaybe<Order_By>;
  readonly tag_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "competitions_tags" */
export type Competitions_Tags_Mutation_Response = {
  readonly __typename?: 'competitions_tags_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Competitions_Tags>;
};

/** on_conflict condition type for table "competitions_tags" */
export type Competitions_Tags_On_Conflict = {
  readonly constraint: Competitions_Tags_Constraint;
  readonly update_columns?: ReadonlyArray<Competitions_Tags_Update_Column>;
  readonly where?: InputMaybe<Competitions_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "competitions_tags". */
export type Competitions_Tags_Order_By = {
  readonly competition?: InputMaybe<Competitions_Order_By>;
  readonly competition_id?: InputMaybe<Order_By>;
  readonly tag?: InputMaybe<Tags_Order_By>;
  readonly tag_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: competitions_tags */
export type Competitions_Tags_Pk_Columns_Input = {
  readonly competition_id: Scalars['String']['input'];
  readonly tag_id: Scalars['String']['input'];
};

/** select columns of table "competitions_tags" */
export enum Competitions_Tags_Select_Column {
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  TagId = 'tag_id'
}

/** input type for updating data in table "competitions_tags" */
export type Competitions_Tags_Set_Input = {
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly tag_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "competitions_tags" */
export type Competitions_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Competitions_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Tags_Stream_Cursor_Value_Input = {
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly tag_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "competitions_tags" */
export enum Competitions_Tags_Update_Column {
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  TagId = 'tag_id'
}

export type Competitions_Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Competitions_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Competitions_Tags_Bool_Exp;
};

/** columns and relationships of "competitions_tutorials" */
export type Competitions_Tutorials = {
  readonly __typename?: 'competitions_tutorials';
  /** An object relationship */
  readonly competition: Competitions;
  readonly competition_id: Scalars['String']['output'];
  /** An object relationship */
  readonly tutorial: Tutorials;
  readonly tutorial_id: Scalars['String']['output'];
};

/** aggregated selection of "competitions_tutorials" */
export type Competitions_Tutorials_Aggregate = {
  readonly __typename?: 'competitions_tutorials_aggregate';
  readonly aggregate?: Maybe<Competitions_Tutorials_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Competitions_Tutorials>;
};

export type Competitions_Tutorials_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Competitions_Tutorials_Aggregate_Bool_Exp_Count>;
};

export type Competitions_Tutorials_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "competitions_tutorials" */
export type Competitions_Tutorials_Aggregate_Fields = {
  readonly __typename?: 'competitions_tutorials_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Competitions_Tutorials_Max_Fields>;
  readonly min?: Maybe<Competitions_Tutorials_Min_Fields>;
};


/** aggregate fields of "competitions_tutorials" */
export type Competitions_Tutorials_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "competitions_tutorials" */
export type Competitions_Tutorials_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Competitions_Tutorials_Max_Order_By>;
  readonly min?: InputMaybe<Competitions_Tutorials_Min_Order_By>;
};

/** input type for inserting array relation for remote table "competitions_tutorials" */
export type Competitions_Tutorials_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Competitions_Tutorials_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Competitions_Tutorials_On_Conflict>;
};

/** Boolean expression to filter rows from the table "competitions_tutorials". All fields are combined with a logical 'AND'. */
export type Competitions_Tutorials_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Bool_Exp>>;
  readonly _not?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Bool_Exp>>;
  readonly competition?: InputMaybe<Competitions_Bool_Exp>;
  readonly competition_id?: InputMaybe<String_Comparison_Exp>;
  readonly tutorial?: InputMaybe<Tutorials_Bool_Exp>;
  readonly tutorial_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "competitions_tutorials" */
export enum Competitions_Tutorials_Constraint {
  /** unique or primary key constraint on columns "competition_id", "tutorial_id" */
  CompetitionsTutorialsPkey = 'competitions_tutorials_pkey'
}

/** input type for inserting data into table "competitions_tutorials" */
export type Competitions_Tutorials_Insert_Input = {
  readonly competition?: InputMaybe<Competitions_Obj_Rel_Insert_Input>;
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly tutorial?: InputMaybe<Tutorials_Obj_Rel_Insert_Input>;
  readonly tutorial_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Competitions_Tutorials_Max_Fields = {
  readonly __typename?: 'competitions_tutorials_max_fields';
  readonly competition_id?: Maybe<Scalars['String']['output']>;
  readonly tutorial_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "competitions_tutorials" */
export type Competitions_Tutorials_Max_Order_By = {
  readonly competition_id?: InputMaybe<Order_By>;
  readonly tutorial_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Competitions_Tutorials_Min_Fields = {
  readonly __typename?: 'competitions_tutorials_min_fields';
  readonly competition_id?: Maybe<Scalars['String']['output']>;
  readonly tutorial_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "competitions_tutorials" */
export type Competitions_Tutorials_Min_Order_By = {
  readonly competition_id?: InputMaybe<Order_By>;
  readonly tutorial_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "competitions_tutorials" */
export type Competitions_Tutorials_Mutation_Response = {
  readonly __typename?: 'competitions_tutorials_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Competitions_Tutorials>;
};

/** on_conflict condition type for table "competitions_tutorials" */
export type Competitions_Tutorials_On_Conflict = {
  readonly constraint: Competitions_Tutorials_Constraint;
  readonly update_columns?: ReadonlyArray<Competitions_Tutorials_Update_Column>;
  readonly where?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
};

/** Ordering options when selecting data from "competitions_tutorials". */
export type Competitions_Tutorials_Order_By = {
  readonly competition?: InputMaybe<Competitions_Order_By>;
  readonly competition_id?: InputMaybe<Order_By>;
  readonly tutorial?: InputMaybe<Tutorials_Order_By>;
  readonly tutorial_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: competitions_tutorials */
export type Competitions_Tutorials_Pk_Columns_Input = {
  readonly competition_id: Scalars['String']['input'];
  readonly tutorial_id: Scalars['String']['input'];
};

/** select columns of table "competitions_tutorials" */
export enum Competitions_Tutorials_Select_Column {
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  TutorialId = 'tutorial_id'
}

/** input type for updating data in table "competitions_tutorials" */
export type Competitions_Tutorials_Set_Input = {
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly tutorial_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "competitions_tutorials" */
export type Competitions_Tutorials_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Competitions_Tutorials_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Tutorials_Stream_Cursor_Value_Input = {
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly tutorial_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "competitions_tutorials" */
export enum Competitions_Tutorials_Update_Column {
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  TutorialId = 'tutorial_id'
}

export type Competitions_Tutorials_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Competitions_Tutorials_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Competitions_Tutorials_Bool_Exp;
};

/** update columns of table "competitions" */
export enum Competitions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultProjectName = 'default_project_name',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  DueAt = 'due_at',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  Gif = 'gif',
  /** column name */
  HostId = 'host_id',
  /** column name */
  Id = 'id',
  /** column name */
  MaxAgeAllowed = 'max_age_allowed',
  /** column name */
  MaxTeamSize = 'max_team_size',
  /** column name */
  Meta = 'meta',
  /** column name */
  Name = 'name',
  /** column name */
  Photo = 'photo',
  /** column name */
  PrizeMeta = 'prize_meta',
  /** column name */
  PrizesDistributed = 'prizes_distributed',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  Rules = 'rules',
  /** column name */
  SpecialPrizes = 'special_prizes',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  Status = 'status',
  /** column name */
  SubmissionFormLink = 'submission_form_link',
  /** column name */
  SubmissionsReviewed = 'submissions_reviewed',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  ThemeId = 'theme_id',
  /** column name */
  ToolId = 'tool_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UrlName = 'url_name'
}

export type Competitions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Competitions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Competitions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Competitions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Competitions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Competitions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Competitions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Competitions_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Competitions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Competitions_Var_Pop_Fields = {
  readonly __typename?: 'competitions_var_pop_fields';
  readonly max_age_allowed?: Maybe<Scalars['Float']['output']>;
  readonly max_team_size?: Maybe<Scalars['Float']['output']>;
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
};

/** order by var_pop() on columns of table "competitions" */
export type Competitions_Var_Pop_Order_By = {
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Competitions_Var_Samp_Fields = {
  readonly __typename?: 'competitions_var_samp_fields';
  readonly max_age_allowed?: Maybe<Scalars['Float']['output']>;
  readonly max_team_size?: Maybe<Scalars['Float']['output']>;
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
};

/** order by var_samp() on columns of table "competitions" */
export type Competitions_Var_Samp_Order_By = {
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Competitions_Variance_Fields = {
  readonly __typename?: 'competitions_variance_fields';
  readonly max_age_allowed?: Maybe<Scalars['Float']['output']>;
  readonly max_team_size?: Maybe<Scalars['Float']['output']>;
  readonly num_contestants?: Maybe<Scalars['bigint']['output']>;
  /** A computed field, executes function "prize_pool" */
  readonly prize_pool?: Maybe<Scalars['numeric']['output']>;
};

/** order by variance() on columns of table "competitions" */
export type Competitions_Variance_Order_By = {
  readonly max_age_allowed?: InputMaybe<Order_By>;
  readonly max_team_size?: InputMaybe<Order_By>;
};

export type Complete_Clubs_Members_Onboarding_Requirement_Args = {
  readonly _club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly _requirement?: InputMaybe<Scalars['String']['input']>;
  readonly _user_id?: InputMaybe<Scalars['String']['input']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  readonly _eq?: InputMaybe<Scalars['date']['input']>;
  readonly _gt?: InputMaybe<Scalars['date']['input']>;
  readonly _gte?: InputMaybe<Scalars['date']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['date']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lt?: InputMaybe<Scalars['date']['input']>;
  readonly _lte?: InputMaybe<Scalars['date']['input']>;
  readonly _neq?: InputMaybe<Scalars['date']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['date']['input']>>;
};

export type Delete_Inactive_Breakout_Args = {
  readonly _associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly _channel_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Comments on Bans */
export type Eban_Comments = {
  readonly __typename?: 'eban_comments';
  /** An object relationship */
  readonly ban: Ebans;
  readonly comment: Scalars['String']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly eban_id: Scalars['Int']['output'];
  readonly id: Scalars['Int']['output'];
  /** An object relationship */
  readonly issuer: Users;
  readonly issuer_id: Scalars['String']['output'];
};

/** aggregated selection of "eban_comments" */
export type Eban_Comments_Aggregate = {
  readonly __typename?: 'eban_comments_aggregate';
  readonly aggregate?: Maybe<Eban_Comments_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Eban_Comments>;
};

export type Eban_Comments_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Eban_Comments_Aggregate_Bool_Exp_Count>;
};

export type Eban_Comments_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Eban_Comments_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Eban_Comments_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "eban_comments" */
export type Eban_Comments_Aggregate_Fields = {
  readonly __typename?: 'eban_comments_aggregate_fields';
  readonly avg?: Maybe<Eban_Comments_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Eban_Comments_Max_Fields>;
  readonly min?: Maybe<Eban_Comments_Min_Fields>;
  readonly stddev?: Maybe<Eban_Comments_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Eban_Comments_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Eban_Comments_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Eban_Comments_Sum_Fields>;
  readonly var_pop?: Maybe<Eban_Comments_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Eban_Comments_Var_Samp_Fields>;
  readonly variance?: Maybe<Eban_Comments_Variance_Fields>;
};


/** aggregate fields of "eban_comments" */
export type Eban_Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Eban_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "eban_comments" */
export type Eban_Comments_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Eban_Comments_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Eban_Comments_Max_Order_By>;
  readonly min?: InputMaybe<Eban_Comments_Min_Order_By>;
  readonly stddev?: InputMaybe<Eban_Comments_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Eban_Comments_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Eban_Comments_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Eban_Comments_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Eban_Comments_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Eban_Comments_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Eban_Comments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "eban_comments" */
export type Eban_Comments_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Eban_Comments_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Eban_Comments_On_Conflict>;
};

/** aggregate avg on columns */
export type Eban_Comments_Avg_Fields = {
  readonly __typename?: 'eban_comments_avg_fields';
  readonly eban_id?: Maybe<Scalars['Float']['output']>;
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "eban_comments" */
export type Eban_Comments_Avg_Order_By = {
  readonly eban_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "eban_comments". All fields are combined with a logical 'AND'. */
export type Eban_Comments_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Eban_Comments_Bool_Exp>>;
  readonly _not?: InputMaybe<Eban_Comments_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Eban_Comments_Bool_Exp>>;
  readonly ban?: InputMaybe<Ebans_Bool_Exp>;
  readonly comment?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly eban_id?: InputMaybe<Int_Comparison_Exp>;
  readonly id?: InputMaybe<Int_Comparison_Exp>;
  readonly issuer?: InputMaybe<Users_Bool_Exp>;
  readonly issuer_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "eban_comments" */
export enum Eban_Comments_Constraint {
  /** unique or primary key constraint on columns "id" */
  EbanCommentsPkey = 'eban_comments_pkey'
}

/** input type for incrementing numeric columns in table "eban_comments" */
export type Eban_Comments_Inc_Input = {
  readonly eban_id?: InputMaybe<Scalars['Int']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "eban_comments" */
export type Eban_Comments_Insert_Input = {
  readonly ban?: InputMaybe<Ebans_Obj_Rel_Insert_Input>;
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly eban_id?: InputMaybe<Scalars['Int']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly issuer?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly issuer_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Eban_Comments_Max_Fields = {
  readonly __typename?: 'eban_comments_max_fields';
  readonly comment?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly eban_id?: Maybe<Scalars['Int']['output']>;
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly issuer_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "eban_comments" */
export type Eban_Comments_Max_Order_By = {
  readonly comment?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly eban_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly issuer_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Eban_Comments_Min_Fields = {
  readonly __typename?: 'eban_comments_min_fields';
  readonly comment?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly eban_id?: Maybe<Scalars['Int']['output']>;
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly issuer_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "eban_comments" */
export type Eban_Comments_Min_Order_By = {
  readonly comment?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly eban_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly issuer_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "eban_comments" */
export type Eban_Comments_Mutation_Response = {
  readonly __typename?: 'eban_comments_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Eban_Comments>;
};

/** on_conflict condition type for table "eban_comments" */
export type Eban_Comments_On_Conflict = {
  readonly constraint: Eban_Comments_Constraint;
  readonly update_columns?: ReadonlyArray<Eban_Comments_Update_Column>;
  readonly where?: InputMaybe<Eban_Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "eban_comments". */
export type Eban_Comments_Order_By = {
  readonly ban?: InputMaybe<Ebans_Order_By>;
  readonly comment?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly eban_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly issuer?: InputMaybe<Users_Order_By>;
  readonly issuer_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: eban_comments */
export type Eban_Comments_Pk_Columns_Input = {
  readonly id: Scalars['Int']['input'];
};

/** select columns of table "eban_comments" */
export enum Eban_Comments_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EbanId = 'eban_id',
  /** column name */
  Id = 'id',
  /** column name */
  IssuerId = 'issuer_id'
}

/** input type for updating data in table "eban_comments" */
export type Eban_Comments_Set_Input = {
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly eban_id?: InputMaybe<Scalars['Int']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly issuer_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Eban_Comments_Stddev_Fields = {
  readonly __typename?: 'eban_comments_stddev_fields';
  readonly eban_id?: Maybe<Scalars['Float']['output']>;
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "eban_comments" */
export type Eban_Comments_Stddev_Order_By = {
  readonly eban_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Eban_Comments_Stddev_Pop_Fields = {
  readonly __typename?: 'eban_comments_stddev_pop_fields';
  readonly eban_id?: Maybe<Scalars['Float']['output']>;
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "eban_comments" */
export type Eban_Comments_Stddev_Pop_Order_By = {
  readonly eban_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Eban_Comments_Stddev_Samp_Fields = {
  readonly __typename?: 'eban_comments_stddev_samp_fields';
  readonly eban_id?: Maybe<Scalars['Float']['output']>;
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "eban_comments" */
export type Eban_Comments_Stddev_Samp_Order_By = {
  readonly eban_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "eban_comments" */
export type Eban_Comments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Eban_Comments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eban_Comments_Stream_Cursor_Value_Input = {
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly eban_id?: InputMaybe<Scalars['Int']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly issuer_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Eban_Comments_Sum_Fields = {
  readonly __typename?: 'eban_comments_sum_fields';
  readonly eban_id?: Maybe<Scalars['Int']['output']>;
  readonly id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "eban_comments" */
export type Eban_Comments_Sum_Order_By = {
  readonly eban_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
};

/** update columns of table "eban_comments" */
export enum Eban_Comments_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EbanId = 'eban_id',
  /** column name */
  Id = 'id',
  /** column name */
  IssuerId = 'issuer_id'
}

export type Eban_Comments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Eban_Comments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Eban_Comments_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Eban_Comments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Eban_Comments_Var_Pop_Fields = {
  readonly __typename?: 'eban_comments_var_pop_fields';
  readonly eban_id?: Maybe<Scalars['Float']['output']>;
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "eban_comments" */
export type Eban_Comments_Var_Pop_Order_By = {
  readonly eban_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Eban_Comments_Var_Samp_Fields = {
  readonly __typename?: 'eban_comments_var_samp_fields';
  readonly eban_id?: Maybe<Scalars['Float']['output']>;
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "eban_comments" */
export type Eban_Comments_Var_Samp_Order_By = {
  readonly eban_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Eban_Comments_Variance_Fields = {
  readonly __typename?: 'eban_comments_variance_fields';
  readonly eban_id?: Maybe<Scalars['Float']['output']>;
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "eban_comments" */
export type Eban_Comments_Variance_Order_By = {
  readonly eban_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
};

/** Ban Types */
export type Eban_Types = {
  readonly __typename?: 'eban_types';
  readonly id: Scalars['Int']['output'];
  readonly name: Scalars['String']['output'];
};

/** aggregated selection of "eban_types" */
export type Eban_Types_Aggregate = {
  readonly __typename?: 'eban_types_aggregate';
  readonly aggregate?: Maybe<Eban_Types_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Eban_Types>;
};

/** aggregate fields of "eban_types" */
export type Eban_Types_Aggregate_Fields = {
  readonly __typename?: 'eban_types_aggregate_fields';
  readonly avg?: Maybe<Eban_Types_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Eban_Types_Max_Fields>;
  readonly min?: Maybe<Eban_Types_Min_Fields>;
  readonly stddev?: Maybe<Eban_Types_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Eban_Types_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Eban_Types_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Eban_Types_Sum_Fields>;
  readonly var_pop?: Maybe<Eban_Types_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Eban_Types_Var_Samp_Fields>;
  readonly variance?: Maybe<Eban_Types_Variance_Fields>;
};


/** aggregate fields of "eban_types" */
export type Eban_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Eban_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Eban_Types_Avg_Fields = {
  readonly __typename?: 'eban_types_avg_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "eban_types". All fields are combined with a logical 'AND'. */
export type Eban_Types_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Eban_Types_Bool_Exp>>;
  readonly _not?: InputMaybe<Eban_Types_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Eban_Types_Bool_Exp>>;
  readonly id?: InputMaybe<Int_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "eban_types" */
export enum Eban_Types_Constraint {
  /** unique or primary key constraint on columns "id" */
  EbanTypesPkey = 'eban_types_pkey'
}

/** input type for incrementing numeric columns in table "eban_types" */
export type Eban_Types_Inc_Input = {
  readonly id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "eban_types" */
export type Eban_Types_Insert_Input = {
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Eban_Types_Max_Fields = {
  readonly __typename?: 'eban_types_max_fields';
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Eban_Types_Min_Fields = {
  readonly __typename?: 'eban_types_min_fields';
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "eban_types" */
export type Eban_Types_Mutation_Response = {
  readonly __typename?: 'eban_types_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Eban_Types>;
};

/** on_conflict condition type for table "eban_types" */
export type Eban_Types_On_Conflict = {
  readonly constraint: Eban_Types_Constraint;
  readonly update_columns?: ReadonlyArray<Eban_Types_Update_Column>;
  readonly where?: InputMaybe<Eban_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "eban_types". */
export type Eban_Types_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: eban_types */
export type Eban_Types_Pk_Columns_Input = {
  readonly id: Scalars['Int']['input'];
};

/** select columns of table "eban_types" */
export enum Eban_Types_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "eban_types" */
export type Eban_Types_Set_Input = {
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Eban_Types_Stddev_Fields = {
  readonly __typename?: 'eban_types_stddev_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Eban_Types_Stddev_Pop_Fields = {
  readonly __typename?: 'eban_types_stddev_pop_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Eban_Types_Stddev_Samp_Fields = {
  readonly __typename?: 'eban_types_stddev_samp_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "eban_types" */
export type Eban_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Eban_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Eban_Types_Stream_Cursor_Value_Input = {
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Eban_Types_Sum_Fields = {
  readonly __typename?: 'eban_types_sum_fields';
  readonly id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "eban_types" */
export enum Eban_Types_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Eban_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Eban_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Eban_Types_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Eban_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Eban_Types_Var_Pop_Fields = {
  readonly __typename?: 'eban_types_var_pop_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Eban_Types_Var_Samp_Fields = {
  readonly __typename?: 'eban_types_var_samp_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Eban_Types_Variance_Fields = {
  readonly __typename?: 'eban_types_variance_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** Ender Bans */
export type Ebans = {
  readonly __typename?: 'ebans';
  /** An array relationship */
  readonly comments: ReadonlyArray<Eban_Comments>;
  /** An aggregate relationship */
  readonly comments_aggregate: Eban_Comments_Aggregate;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly ender_id?: Maybe<Scalars['String']['output']>;
  readonly expires_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id: Scalars['Int']['output'];
  /** An object relationship */
  readonly issuer: Users;
  readonly issuer_id: Scalars['String']['output'];
  readonly player_id: Scalars['uuid']['output'];
  readonly reason: Scalars['String']['output'];
  readonly type: Scalars['Int']['output'];
  readonly unban_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly unban_issuer?: Maybe<Users>;
  readonly unban_reason?: Maybe<Scalars['String']['output']>;
  readonly unbanned_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  readonly user?: Maybe<Users>;
};


/** Ender Bans */
export type EbansCommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Eban_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Eban_Comments_Order_By>>;
  where?: InputMaybe<Eban_Comments_Bool_Exp>;
};


/** Ender Bans */
export type EbansComments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Eban_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Eban_Comments_Order_By>>;
  where?: InputMaybe<Eban_Comments_Bool_Exp>;
};

/** aggregated selection of "ebans" */
export type Ebans_Aggregate = {
  readonly __typename?: 'ebans_aggregate';
  readonly aggregate?: Maybe<Ebans_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Ebans>;
};

/** aggregate fields of "ebans" */
export type Ebans_Aggregate_Fields = {
  readonly __typename?: 'ebans_aggregate_fields';
  readonly avg?: Maybe<Ebans_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Ebans_Max_Fields>;
  readonly min?: Maybe<Ebans_Min_Fields>;
  readonly stddev?: Maybe<Ebans_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Ebans_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Ebans_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Ebans_Sum_Fields>;
  readonly var_pop?: Maybe<Ebans_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Ebans_Var_Samp_Fields>;
  readonly variance?: Maybe<Ebans_Variance_Fields>;
};


/** aggregate fields of "ebans" */
export type Ebans_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Ebans_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Ebans_Avg_Fields = {
  readonly __typename?: 'ebans_avg_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly type?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "ebans". All fields are combined with a logical 'AND'. */
export type Ebans_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Ebans_Bool_Exp>>;
  readonly _not?: InputMaybe<Ebans_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Ebans_Bool_Exp>>;
  readonly comments?: InputMaybe<Eban_Comments_Bool_Exp>;
  readonly comments_aggregate?: InputMaybe<Eban_Comments_Aggregate_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly ender_id?: InputMaybe<String_Comparison_Exp>;
  readonly expires_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Int_Comparison_Exp>;
  readonly issuer?: InputMaybe<Users_Bool_Exp>;
  readonly issuer_id?: InputMaybe<String_Comparison_Exp>;
  readonly player_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly reason?: InputMaybe<String_Comparison_Exp>;
  readonly type?: InputMaybe<Int_Comparison_Exp>;
  readonly unban_id?: InputMaybe<String_Comparison_Exp>;
  readonly unban_issuer?: InputMaybe<Users_Bool_Exp>;
  readonly unban_reason?: InputMaybe<String_Comparison_Exp>;
  readonly unbanned_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "ebans" */
export enum Ebans_Constraint {
  /** unique or primary key constraint on columns "id" */
  EbansPkey = 'ebans_pkey'
}

/** input type for incrementing numeric columns in table "ebans" */
export type Ebans_Inc_Input = {
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly type?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "ebans" */
export type Ebans_Insert_Input = {
  readonly comments?: InputMaybe<Eban_Comments_Arr_Rel_Insert_Input>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly ender_id?: InputMaybe<Scalars['String']['input']>;
  readonly expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly issuer?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly issuer_id?: InputMaybe<Scalars['String']['input']>;
  readonly player_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly reason?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['Int']['input']>;
  readonly unban_id?: InputMaybe<Scalars['String']['input']>;
  readonly unban_issuer?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly unban_reason?: InputMaybe<Scalars['String']['input']>;
  readonly unbanned_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Ebans_Max_Fields = {
  readonly __typename?: 'ebans_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly ender_id?: Maybe<Scalars['String']['output']>;
  readonly expires_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly issuer_id?: Maybe<Scalars['String']['output']>;
  readonly player_id?: Maybe<Scalars['uuid']['output']>;
  readonly reason?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['Int']['output']>;
  readonly unban_id?: Maybe<Scalars['String']['output']>;
  readonly unban_reason?: Maybe<Scalars['String']['output']>;
  readonly unbanned_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Ebans_Min_Fields = {
  readonly __typename?: 'ebans_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly ender_id?: Maybe<Scalars['String']['output']>;
  readonly expires_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly issuer_id?: Maybe<Scalars['String']['output']>;
  readonly player_id?: Maybe<Scalars['uuid']['output']>;
  readonly reason?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['Int']['output']>;
  readonly unban_id?: Maybe<Scalars['String']['output']>;
  readonly unban_reason?: Maybe<Scalars['String']['output']>;
  readonly unbanned_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "ebans" */
export type Ebans_Mutation_Response = {
  readonly __typename?: 'ebans_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Ebans>;
};

/** input type for inserting object relation for remote table "ebans" */
export type Ebans_Obj_Rel_Insert_Input = {
  readonly data: Ebans_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Ebans_On_Conflict>;
};

/** on_conflict condition type for table "ebans" */
export type Ebans_On_Conflict = {
  readonly constraint: Ebans_Constraint;
  readonly update_columns?: ReadonlyArray<Ebans_Update_Column>;
  readonly where?: InputMaybe<Ebans_Bool_Exp>;
};

/** Ordering options when selecting data from "ebans". */
export type Ebans_Order_By = {
  readonly comments_aggregate?: InputMaybe<Eban_Comments_Aggregate_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly ender_id?: InputMaybe<Order_By>;
  readonly expires_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly issuer?: InputMaybe<Users_Order_By>;
  readonly issuer_id?: InputMaybe<Order_By>;
  readonly player_id?: InputMaybe<Order_By>;
  readonly reason?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly unban_id?: InputMaybe<Order_By>;
  readonly unban_issuer?: InputMaybe<Users_Order_By>;
  readonly unban_reason?: InputMaybe<Order_By>;
  readonly unbanned_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: ebans */
export type Ebans_Pk_Columns_Input = {
  readonly id: Scalars['Int']['input'];
};

/** select columns of table "ebans" */
export enum Ebans_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnderId = 'ender_id',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  IssuerId = 'issuer_id',
  /** column name */
  PlayerId = 'player_id',
  /** column name */
  Reason = 'reason',
  /** column name */
  Type = 'type',
  /** column name */
  UnbanId = 'unban_id',
  /** column name */
  UnbanReason = 'unban_reason',
  /** column name */
  UnbannedAt = 'unbanned_at'
}

/** input type for updating data in table "ebans" */
export type Ebans_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly ender_id?: InputMaybe<Scalars['String']['input']>;
  readonly expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly issuer_id?: InputMaybe<Scalars['String']['input']>;
  readonly player_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly reason?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['Int']['input']>;
  readonly unban_id?: InputMaybe<Scalars['String']['input']>;
  readonly unban_reason?: InputMaybe<Scalars['String']['input']>;
  readonly unbanned_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Ebans_Stddev_Fields = {
  readonly __typename?: 'ebans_stddev_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly type?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Ebans_Stddev_Pop_Fields = {
  readonly __typename?: 'ebans_stddev_pop_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly type?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Ebans_Stddev_Samp_Fields = {
  readonly __typename?: 'ebans_stddev_samp_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly type?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "ebans" */
export type Ebans_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Ebans_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ebans_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly ender_id?: InputMaybe<Scalars['String']['input']>;
  readonly expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly issuer_id?: InputMaybe<Scalars['String']['input']>;
  readonly player_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly reason?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['Int']['input']>;
  readonly unban_id?: InputMaybe<Scalars['String']['input']>;
  readonly unban_reason?: InputMaybe<Scalars['String']['input']>;
  readonly unbanned_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Ebans_Sum_Fields = {
  readonly __typename?: 'ebans_sum_fields';
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly type?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "ebans" */
export enum Ebans_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnderId = 'ender_id',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  IssuerId = 'issuer_id',
  /** column name */
  PlayerId = 'player_id',
  /** column name */
  Reason = 'reason',
  /** column name */
  Type = 'type',
  /** column name */
  UnbanId = 'unban_id',
  /** column name */
  UnbanReason = 'unban_reason',
  /** column name */
  UnbannedAt = 'unbanned_at'
}

export type Ebans_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Ebans_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Ebans_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Ebans_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ebans_Var_Pop_Fields = {
  readonly __typename?: 'ebans_var_pop_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly type?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Ebans_Var_Samp_Fields = {
  readonly __typename?: 'ebans_var_samp_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly type?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Ebans_Variance_Fields = {
  readonly __typename?: 'ebans_variance_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly type?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "environment" */
export type Environment = {
  readonly __typename?: 'environment';
  readonly key: Scalars['String']['output'];
  readonly value?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "environment" */
export type Environment_Aggregate = {
  readonly __typename?: 'environment_aggregate';
  readonly aggregate?: Maybe<Environment_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Environment>;
};

/** aggregate fields of "environment" */
export type Environment_Aggregate_Fields = {
  readonly __typename?: 'environment_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Environment_Max_Fields>;
  readonly min?: Maybe<Environment_Min_Fields>;
};


/** aggregate fields of "environment" */
export type Environment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Environment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "environment". All fields are combined with a logical 'AND'. */
export type Environment_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Environment_Bool_Exp>>;
  readonly _not?: InputMaybe<Environment_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Environment_Bool_Exp>>;
  readonly key?: InputMaybe<String_Comparison_Exp>;
  readonly value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "environment" */
export enum Environment_Constraint {
  /** unique or primary key constraint on columns "key" */
  EnvironmentPkey = 'environment_pkey'
}

/** input type for inserting data into table "environment" */
export type Environment_Insert_Input = {
  readonly key?: InputMaybe<Scalars['String']['input']>;
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Environment_Max_Fields = {
  readonly __typename?: 'environment_max_fields';
  readonly key?: Maybe<Scalars['String']['output']>;
  readonly value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Environment_Min_Fields = {
  readonly __typename?: 'environment_min_fields';
  readonly key?: Maybe<Scalars['String']['output']>;
  readonly value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "environment" */
export type Environment_Mutation_Response = {
  readonly __typename?: 'environment_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Environment>;
};

/** on_conflict condition type for table "environment" */
export type Environment_On_Conflict = {
  readonly constraint: Environment_Constraint;
  readonly update_columns?: ReadonlyArray<Environment_Update_Column>;
  readonly where?: InputMaybe<Environment_Bool_Exp>;
};

/** Ordering options when selecting data from "environment". */
export type Environment_Order_By = {
  readonly key?: InputMaybe<Order_By>;
  readonly value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: environment */
export type Environment_Pk_Columns_Input = {
  readonly key: Scalars['String']['input'];
};

/** select columns of table "environment" */
export enum Environment_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "environment" */
export type Environment_Set_Input = {
  readonly key?: InputMaybe<Scalars['String']['input']>;
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "environment" */
export type Environment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Environment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Environment_Stream_Cursor_Value_Input = {
  readonly key?: InputMaybe<Scalars['String']['input']>;
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "environment" */
export enum Environment_Update_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value'
}

export type Environment_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Environment_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Environment_Bool_Exp;
};

export type Execute_Shared_Cached_Query_Args = {
  readonly _params?: InputMaybe<Scalars['json']['input']>;
  readonly _query_key?: InputMaybe<Scalars['String']['input']>;
  readonly _subscription?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Execute_Users_Cached_Query_Args = {
  readonly _document?: InputMaybe<Scalars['String']['input']>;
  readonly _key?: InputMaybe<Scalars['String']['input']>;
  readonly _max_age_ms?: InputMaybe<Scalars['Int']['input']>;
  readonly _params?: InputMaybe<Scalars['json']['input']>;
  readonly _subscription?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A list of product features */
export type Features = {
  readonly __typename?: 'features';
  readonly active: Scalars['Boolean']['output'];
  /** An array relationship */
  readonly clubs: ReadonlyArray<Clubs>;
  /** An aggregate relationship */
  readonly clubs_aggregate: Clubs_Aggregate;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly products: ReadonlyArray<Features_Products>;
  /** An aggregate relationship */
  readonly products_aggregate: Features_Products_Aggregate;
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  readonly users: ReadonlyArray<Users_Features>;
  /** An aggregate relationship */
  readonly users_aggregate: Users_Features_Aggregate;
};


/** A list of product features */
export type FeaturesClubsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Order_By>>;
  where?: InputMaybe<Clubs_Bool_Exp>;
};


/** A list of product features */
export type FeaturesClubs_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Order_By>>;
  where?: InputMaybe<Clubs_Bool_Exp>;
};


/** A list of product features */
export type FeaturesProductsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Features_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Features_Products_Order_By>>;
  where?: InputMaybe<Features_Products_Bool_Exp>;
};


/** A list of product features */
export type FeaturesProducts_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Features_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Features_Products_Order_By>>;
  where?: InputMaybe<Features_Products_Bool_Exp>;
};


/** A list of product features */
export type FeaturesUsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Features_Order_By>>;
  where?: InputMaybe<Users_Features_Bool_Exp>;
};


/** A list of product features */
export type FeaturesUsers_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Features_Order_By>>;
  where?: InputMaybe<Users_Features_Bool_Exp>;
};

/** aggregated selection of "features" */
export type Features_Aggregate = {
  readonly __typename?: 'features_aggregate';
  readonly aggregate?: Maybe<Features_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Features>;
};

/** aggregate fields of "features" */
export type Features_Aggregate_Fields = {
  readonly __typename?: 'features_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Features_Max_Fields>;
  readonly min?: Maybe<Features_Min_Fields>;
};


/** aggregate fields of "features" */
export type Features_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Features_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "features". All fields are combined with a logical 'AND'. */
export type Features_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Features_Bool_Exp>>;
  readonly _not?: InputMaybe<Features_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Features_Bool_Exp>>;
  readonly active?: InputMaybe<Boolean_Comparison_Exp>;
  readonly clubs?: InputMaybe<Clubs_Bool_Exp>;
  readonly clubs_aggregate?: InputMaybe<Clubs_Aggregate_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly products?: InputMaybe<Features_Products_Bool_Exp>;
  readonly products_aggregate?: InputMaybe<Features_Products_Aggregate_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly users?: InputMaybe<Users_Features_Bool_Exp>;
  readonly users_aggregate?: InputMaybe<Users_Features_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "features" */
export enum Features_Constraint {
  /** unique or primary key constraint on columns "name" */
  FeaturesNameKey = 'features_name_key',
  /** unique or primary key constraint on columns "id" */
  FeaturesPkey = 'features_pkey'
}

/** input type for inserting data into table "features" */
export type Features_Insert_Input = {
  readonly active?: InputMaybe<Scalars['Boolean']['input']>;
  readonly clubs?: InputMaybe<Clubs_Arr_Rel_Insert_Input>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly products?: InputMaybe<Features_Products_Arr_Rel_Insert_Input>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly users?: InputMaybe<Users_Features_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Features_Max_Fields = {
  readonly __typename?: 'features_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Features_Min_Fields = {
  readonly __typename?: 'features_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "features" */
export type Features_Mutation_Response = {
  readonly __typename?: 'features_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Features>;
};

/** input type for inserting object relation for remote table "features" */
export type Features_Obj_Rel_Insert_Input = {
  readonly data: Features_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Features_On_Conflict>;
};

/** on_conflict condition type for table "features" */
export type Features_On_Conflict = {
  readonly constraint: Features_Constraint;
  readonly update_columns?: ReadonlyArray<Features_Update_Column>;
  readonly where?: InputMaybe<Features_Bool_Exp>;
};

/** Ordering options when selecting data from "features". */
export type Features_Order_By = {
  readonly active?: InputMaybe<Order_By>;
  readonly clubs_aggregate?: InputMaybe<Clubs_Aggregate_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly products_aggregate?: InputMaybe<Features_Products_Aggregate_Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly users_aggregate?: InputMaybe<Users_Features_Aggregate_Order_By>;
};

/** primary key columns input for table: features */
export type Features_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** Association table that maps Stripe products to our features */
export type Features_Products = {
  readonly __typename?: 'features_products';
  /** An object relationship */
  readonly feature: Features;
  readonly feature_id: Scalars['uuid']['output'];
  readonly product_id: Scalars['String']['output'];
  readonly product_name?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "features_products" */
export type Features_Products_Aggregate = {
  readonly __typename?: 'features_products_aggregate';
  readonly aggregate?: Maybe<Features_Products_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Features_Products>;
};

export type Features_Products_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Features_Products_Aggregate_Bool_Exp_Count>;
};

export type Features_Products_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Features_Products_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Features_Products_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "features_products" */
export type Features_Products_Aggregate_Fields = {
  readonly __typename?: 'features_products_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Features_Products_Max_Fields>;
  readonly min?: Maybe<Features_Products_Min_Fields>;
};


/** aggregate fields of "features_products" */
export type Features_Products_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Features_Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "features_products" */
export type Features_Products_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Features_Products_Max_Order_By>;
  readonly min?: InputMaybe<Features_Products_Min_Order_By>;
};

/** input type for inserting array relation for remote table "features_products" */
export type Features_Products_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Features_Products_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Features_Products_On_Conflict>;
};

/** Boolean expression to filter rows from the table "features_products". All fields are combined with a logical 'AND'. */
export type Features_Products_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Features_Products_Bool_Exp>>;
  readonly _not?: InputMaybe<Features_Products_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Features_Products_Bool_Exp>>;
  readonly feature?: InputMaybe<Features_Bool_Exp>;
  readonly feature_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly product_id?: InputMaybe<String_Comparison_Exp>;
  readonly product_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "features_products" */
export enum Features_Products_Constraint {
  /** unique or primary key constraint on columns "product_id", "feature_id" */
  FeaturesProductsPkey = 'features_products_pkey'
}

/** input type for inserting data into table "features_products" */
export type Features_Products_Insert_Input = {
  readonly feature?: InputMaybe<Features_Obj_Rel_Insert_Input>;
  readonly feature_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly product_id?: InputMaybe<Scalars['String']['input']>;
  readonly product_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Features_Products_Max_Fields = {
  readonly __typename?: 'features_products_max_fields';
  readonly feature_id?: Maybe<Scalars['uuid']['output']>;
  readonly product_id?: Maybe<Scalars['String']['output']>;
  readonly product_name?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "features_products" */
export type Features_Products_Max_Order_By = {
  readonly feature_id?: InputMaybe<Order_By>;
  readonly product_id?: InputMaybe<Order_By>;
  readonly product_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Features_Products_Min_Fields = {
  readonly __typename?: 'features_products_min_fields';
  readonly feature_id?: Maybe<Scalars['uuid']['output']>;
  readonly product_id?: Maybe<Scalars['String']['output']>;
  readonly product_name?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "features_products" */
export type Features_Products_Min_Order_By = {
  readonly feature_id?: InputMaybe<Order_By>;
  readonly product_id?: InputMaybe<Order_By>;
  readonly product_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "features_products" */
export type Features_Products_Mutation_Response = {
  readonly __typename?: 'features_products_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Features_Products>;
};

/** on_conflict condition type for table "features_products" */
export type Features_Products_On_Conflict = {
  readonly constraint: Features_Products_Constraint;
  readonly update_columns?: ReadonlyArray<Features_Products_Update_Column>;
  readonly where?: InputMaybe<Features_Products_Bool_Exp>;
};

/** Ordering options when selecting data from "features_products". */
export type Features_Products_Order_By = {
  readonly feature?: InputMaybe<Features_Order_By>;
  readonly feature_id?: InputMaybe<Order_By>;
  readonly product_id?: InputMaybe<Order_By>;
  readonly product_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: features_products */
export type Features_Products_Pk_Columns_Input = {
  readonly feature_id: Scalars['uuid']['input'];
  readonly product_id: Scalars['String']['input'];
};

/** select columns of table "features_products" */
export enum Features_Products_Select_Column {
  /** column name */
  FeatureId = 'feature_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductName = 'product_name'
}

/** input type for updating data in table "features_products" */
export type Features_Products_Set_Input = {
  readonly feature_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly product_id?: InputMaybe<Scalars['String']['input']>;
  readonly product_name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "features_products" */
export type Features_Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Features_Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Features_Products_Stream_Cursor_Value_Input = {
  readonly feature_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly product_id?: InputMaybe<Scalars['String']['input']>;
  readonly product_name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "features_products" */
export enum Features_Products_Update_Column {
  /** column name */
  FeatureId = 'feature_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductName = 'product_name'
}

export type Features_Products_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Features_Products_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Features_Products_Bool_Exp;
};

/** select columns of table "features" */
export enum Features_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "features" */
export type Features_Set_Input = {
  readonly active?: InputMaybe<Scalars['Boolean']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "features" */
export type Features_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Features_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Features_Stream_Cursor_Value_Input = {
  readonly active?: InputMaybe<Scalars['Boolean']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "features" */
export enum Features_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Features_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Features_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Features_Bool_Exp;
};

export type Feed_Type_Bool_Exp = {
  readonly _eq?: InputMaybe<Scalars['feed_type']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['feed_type']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Scalars['feed_type']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['feed_type']['input']>>;
};

/** Suggested prize store items */
export type Feedback = {
  readonly __typename?: 'feedback';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly club?: Maybe<Clubs>;
  /** An object relationship */
  readonly competition?: Maybe<Competitions>;
  readonly created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly creator: Users;
  readonly creator_id: Scalars['String']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly idea: Scalars['String']['output'];
  /** An object relationship */
  readonly live_event?: Maybe<Live_Events>;
  /** An object relationship */
  readonly project?: Maybe<Projects>;
  readonly reviewed_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  readonly room?: Maybe<Rooms>;
  readonly sentiment: Feedback_Sentiment;
  readonly source: Scalars['feedback_source']['output'];
};

/** aggregated selection of "feedback" */
export type Feedback_Aggregate = {
  readonly __typename?: 'feedback_aggregate';
  readonly aggregate?: Maybe<Feedback_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Feedback>;
};

export type Feedback_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Feedback_Aggregate_Bool_Exp_Count>;
};

export type Feedback_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Feedback_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Feedback_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "feedback" */
export type Feedback_Aggregate_Fields = {
  readonly __typename?: 'feedback_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Feedback_Max_Fields>;
  readonly min?: Maybe<Feedback_Min_Fields>;
};


/** aggregate fields of "feedback" */
export type Feedback_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Feedback_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "feedback" */
export type Feedback_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Feedback_Max_Order_By>;
  readonly min?: InputMaybe<Feedback_Min_Order_By>;
};

/** input type for inserting array relation for remote table "feedback" */
export type Feedback_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Feedback_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Feedback_On_Conflict>;
};

/** Boolean expression to filter rows from the table "feedback". All fields are combined with a logical 'AND'. */
export type Feedback_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Feedback_Bool_Exp>>;
  readonly _not?: InputMaybe<Feedback_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Feedback_Bool_Exp>>;
  readonly associated_object?: InputMaybe<String_Comparison_Exp>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly competition?: InputMaybe<Competitions_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly creator?: InputMaybe<Users_Bool_Exp>;
  readonly creator_id?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly idea?: InputMaybe<String_Comparison_Exp>;
  readonly live_event?: InputMaybe<Live_Events_Bool_Exp>;
  readonly project?: InputMaybe<Projects_Bool_Exp>;
  readonly reviewed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly room?: InputMaybe<Rooms_Bool_Exp>;
  readonly sentiment?: InputMaybe<Feedback_Sentiment_Bool_Exp>;
  readonly source?: InputMaybe<Feedback_Source_Bool_Exp>;
};

/** unique or primary key constraints on table "feedback" */
export enum Feedback_Constraint {
  /** unique or primary key constraint on columns "id" */
  ItemSuggestionsPkey = 'item_suggestions_pkey'
}

/** input type for inserting data into table "feedback" */
export type Feedback_Insert_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly club?: InputMaybe<Clubs_Obj_Rel_Insert_Input>;
  readonly competition?: InputMaybe<Competitions_Obj_Rel_Insert_Input>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly creator?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly creator_id?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly idea?: InputMaybe<Scalars['String']['input']>;
  readonly live_event?: InputMaybe<Live_Events_Obj_Rel_Insert_Input>;
  readonly project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  readonly reviewed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly room?: InputMaybe<Rooms_Obj_Rel_Insert_Input>;
  readonly sentiment?: InputMaybe<Feedback_Sentiment>;
  readonly source?: InputMaybe<Scalars['feedback_source']['input']>;
};

/** aggregate max on columns */
export type Feedback_Max_Fields = {
  readonly __typename?: 'feedback_max_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly creator_id?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly idea?: Maybe<Scalars['String']['output']>;
  readonly reviewed_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly sentiment?: Maybe<Scalars['String']['output']>;
  readonly source?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "feedback" */
export type Feedback_Max_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly creator_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly idea?: InputMaybe<Order_By>;
  readonly reviewed_at?: InputMaybe<Order_By>;
  readonly sentiment?: InputMaybe<Order_By>;
  readonly source?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Feedback_Min_Fields = {
  readonly __typename?: 'feedback_min_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly creator_id?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly idea?: Maybe<Scalars['String']['output']>;
  readonly reviewed_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly sentiment?: Maybe<Scalars['String']['output']>;
  readonly source?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "feedback" */
export type Feedback_Min_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly creator_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly idea?: InputMaybe<Order_By>;
  readonly reviewed_at?: InputMaybe<Order_By>;
  readonly sentiment?: InputMaybe<Order_By>;
  readonly source?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "feedback" */
export type Feedback_Mutation_Response = {
  readonly __typename?: 'feedback_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Feedback>;
};

/** on_conflict condition type for table "feedback" */
export type Feedback_On_Conflict = {
  readonly constraint: Feedback_Constraint;
  readonly update_columns?: ReadonlyArray<Feedback_Update_Column>;
  readonly where?: InputMaybe<Feedback_Bool_Exp>;
};

/** Ordering options when selecting data from "feedback". */
export type Feedback_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly competition?: InputMaybe<Competitions_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly creator?: InputMaybe<Users_Order_By>;
  readonly creator_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly idea?: InputMaybe<Order_By>;
  readonly live_event?: InputMaybe<Live_Events_Order_By>;
  readonly project?: InputMaybe<Projects_Order_By>;
  readonly reviewed_at?: InputMaybe<Order_By>;
  readonly room?: InputMaybe<Rooms_Order_By>;
  readonly sentiment?: InputMaybe<Order_By>;
  readonly source?: InputMaybe<Order_By>;
};

/** primary key columns input for table: feedback */
export type Feedback_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "feedback" */
export enum Feedback_Select_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Id = 'id',
  /** column name */
  Idea = 'idea',
  /** column name */
  ReviewedAt = 'reviewed_at',
  /** column name */
  Sentiment = 'sentiment',
  /** column name */
  Source = 'source'
}

export enum Feedback_Sentiment {
  Bad = 'bad',
  Good = 'good',
  Neutral = 'neutral'
}

export type Feedback_Sentiment_Bool_Exp = {
  readonly _eq?: InputMaybe<Feedback_Sentiment>;
  readonly _in?: InputMaybe<ReadonlyArray<Feedback_Sentiment>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Feedback_Sentiment>;
  readonly _nin?: InputMaybe<ReadonlyArray<Feedback_Sentiment>>;
};

/** input type for updating data in table "feedback" */
export type Feedback_Set_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly creator_id?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly idea?: InputMaybe<Scalars['String']['input']>;
  readonly reviewed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly sentiment?: InputMaybe<Feedback_Sentiment>;
  readonly source?: InputMaybe<Scalars['feedback_source']['input']>;
};

export type Feedback_Source_Bool_Exp = {
  readonly _eq?: InputMaybe<Scalars['feedback_source']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['feedback_source']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Scalars['feedback_source']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['feedback_source']['input']>>;
};

/** Streaming cursor of the table "feedback" */
export type Feedback_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Feedback_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feedback_Stream_Cursor_Value_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly creator_id?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly idea?: InputMaybe<Scalars['String']['input']>;
  readonly reviewed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly sentiment?: InputMaybe<Scalars['String']['input']>;
  readonly source?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "feedback" */
export enum Feedback_Update_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Id = 'id',
  /** column name */
  Idea = 'idea',
  /** column name */
  ReviewedAt = 'reviewed_at',
  /** column name */
  Sentiment = 'sentiment',
  /** column name */
  Source = 'source'
}

export type Feedback_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Feedback_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Feedback_Bool_Exp;
};

/** columns and relationships of "feeds" */
export type Feeds = {
  readonly __typename?: 'feeds';
  readonly actor_id: Scalars['String']['output'];
  readonly association_id: Scalars['String']['output'];
  /** An object relationship */
  readonly club?: Maybe<Clubs>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  readonly comments: ReadonlyArray<Comments>;
  /** An aggregate relationship */
  readonly comments_aggregate: Comments_Aggregate;
  /** An array relationship */
  readonly comments_votes: ReadonlyArray<Comments_Votes>;
  /** An aggregate relationship */
  readonly comments_votes_aggregate: Comments_Votes_Aggregate;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly payload: Scalars['jsonb']['output'];
  readonly type: Scalars['feed_type']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  readonly votes: ReadonlyArray<Votes>;
  /** An aggregate relationship */
  readonly votes_aggregate: Votes_Aggregate;
};


/** columns and relationships of "feeds" */
export type FeedsCommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "feeds" */
export type FeedsComments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "feeds" */
export type FeedsComments_VotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Votes_Order_By>>;
  where?: InputMaybe<Comments_Votes_Bool_Exp>;
};


/** columns and relationships of "feeds" */
export type FeedsComments_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Votes_Order_By>>;
  where?: InputMaybe<Comments_Votes_Bool_Exp>;
};


/** columns and relationships of "feeds" */
export type FeedsPayloadArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "feeds" */
export type FeedsVotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


/** columns and relationships of "feeds" */
export type FeedsVotes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};

/** aggregated selection of "feeds" */
export type Feeds_Aggregate = {
  readonly __typename?: 'feeds_aggregate';
  readonly aggregate?: Maybe<Feeds_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Feeds>;
};

export type Feeds_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Feeds_Aggregate_Bool_Exp_Count>;
};

export type Feeds_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Feeds_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "feeds" */
export type Feeds_Aggregate_Fields = {
  readonly __typename?: 'feeds_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Feeds_Max_Fields>;
  readonly min?: Maybe<Feeds_Min_Fields>;
};


/** aggregate fields of "feeds" */
export type Feeds_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "feeds" */
export type Feeds_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Feeds_Max_Order_By>;
  readonly min?: InputMaybe<Feeds_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Feeds_Append_Input = {
  readonly payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "feeds" */
export type Feeds_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Feeds_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Feeds_On_Conflict>;
};

/** Boolean expression to filter rows from the table "feeds". All fields are combined with a logical 'AND'. */
export type Feeds_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Feeds_Bool_Exp>>;
  readonly _not?: InputMaybe<Feeds_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Feeds_Bool_Exp>>;
  readonly actor_id?: InputMaybe<String_Comparison_Exp>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly club_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly comments?: InputMaybe<Comments_Bool_Exp>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  readonly comments_votes?: InputMaybe<Comments_Votes_Bool_Exp>;
  readonly comments_votes_aggregate?: InputMaybe<Comments_Votes_Aggregate_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly payload?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly type?: InputMaybe<Feed_Type_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly votes?: InputMaybe<Votes_Bool_Exp>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "feeds" */
export enum Feeds_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeedsPkey = 'feeds_pkey'
}

export type Feeds_Delete_Args = {
  readonly feed_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Feeds_Delete_At_Path_Input = {
  readonly payload?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Feeds_Delete_Elem_Input = {
  readonly payload?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Feeds_Delete_Key_Input = {
  readonly payload?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "feeds" */
export type Feeds_Insert_Input = {
  readonly actor_id?: InputMaybe<Scalars['String']['input']>;
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly club?: InputMaybe<Clubs_Obj_Rel_Insert_Input>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  readonly comments_votes?: InputMaybe<Comments_Votes_Arr_Rel_Insert_Input>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly payload?: InputMaybe<Scalars['jsonb']['input']>;
  readonly type?: InputMaybe<Scalars['feed_type']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly votes?: InputMaybe<Votes_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Feeds_Max_Fields = {
  readonly __typename?: 'feeds_max_fields';
  readonly actor_id?: Maybe<Scalars['String']['output']>;
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "feeds" */
export type Feeds_Max_Order_By = {
  readonly actor_id?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Feeds_Min_Fields = {
  readonly __typename?: 'feeds_min_fields';
  readonly actor_id?: Maybe<Scalars['String']['output']>;
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "feeds" */
export type Feeds_Min_Order_By = {
  readonly actor_id?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "feeds" */
export type Feeds_Mutation_Response = {
  readonly __typename?: 'feeds_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Feeds>;
};

/** on_conflict condition type for table "feeds" */
export type Feeds_On_Conflict = {
  readonly constraint: Feeds_Constraint;
  readonly update_columns?: ReadonlyArray<Feeds_Update_Column>;
  readonly where?: InputMaybe<Feeds_Bool_Exp>;
};

/** Ordering options when selecting data from "feeds". */
export type Feeds_Order_By = {
  readonly actor_id?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  readonly comments_votes_aggregate?: InputMaybe<Comments_Votes_Aggregate_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly payload?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Order_By>;
};

/** primary key columns input for table: feeds */
export type Feeds_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Feeds_Prepend_Input = {
  readonly payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "feeds" */
export enum Feeds_Select_Column {
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "feeds" */
export type Feeds_Set_Input = {
  readonly actor_id?: InputMaybe<Scalars['String']['input']>;
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly payload?: InputMaybe<Scalars['jsonb']['input']>;
  readonly type?: InputMaybe<Scalars['feed_type']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "feeds" */
export type Feeds_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Feeds_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feeds_Stream_Cursor_Value_Input = {
  readonly actor_id?: InputMaybe<Scalars['String']['input']>;
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly payload?: InputMaybe<Scalars['jsonb']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "feeds" */
export enum Feeds_Update_Column {
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Feeds_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Feeds_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Feeds_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Feeds_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Feeds_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Feeds_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Feeds_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Feeds_Bool_Exp;
};

/** columns and relationships of "follows" */
export type Follows = {
  readonly __typename?: 'follows';
  readonly created_at: Scalars['timestamptz']['output'];
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  readonly follower: Users;
  readonly follower_id: Scalars['String']['output'];
  readonly id: Scalars['uuid']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};

/** aggregated selection of "follows" */
export type Follows_Aggregate = {
  readonly __typename?: 'follows_aggregate';
  readonly aggregate?: Maybe<Follows_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Follows>;
};

export type Follows_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Follows_Aggregate_Bool_Exp_Count>;
};

export type Follows_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Follows_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Follows_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "follows" */
export type Follows_Aggregate_Fields = {
  readonly __typename?: 'follows_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Follows_Max_Fields>;
  readonly min?: Maybe<Follows_Min_Fields>;
};


/** aggregate fields of "follows" */
export type Follows_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Follows_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "follows" */
export type Follows_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Follows_Max_Order_By>;
  readonly min?: InputMaybe<Follows_Min_Order_By>;
};

/** input type for inserting array relation for remote table "follows" */
export type Follows_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Follows_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Follows_On_Conflict>;
};

/** Boolean expression to filter rows from the table "follows". All fields are combined with a logical 'AND'. */
export type Follows_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Follows_Bool_Exp>>;
  readonly _not?: InputMaybe<Follows_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Follows_Bool_Exp>>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly follower?: InputMaybe<Users_Bool_Exp>;
  readonly follower_id?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "follows" */
export enum Follows_Constraint {
  /** unique or primary key constraint on columns "user_id", "follower_id" */
  FollowsFollowerIdUserIdKey = 'follows_follower_id_user_id_key',
  /** unique or primary key constraint on columns "id" */
  FollowsPkey = 'follows_pkey'
}

/** input type for inserting data into table "follows" */
export type Follows_Insert_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly follower?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly follower_id?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Follows_Max_Fields = {
  readonly __typename?: 'follows_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly follower_id?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "follows" */
export type Follows_Max_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly follower_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Follows_Min_Fields = {
  readonly __typename?: 'follows_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly follower_id?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "follows" */
export type Follows_Min_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly follower_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "follows" */
export type Follows_Mutation_Response = {
  readonly __typename?: 'follows_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Follows>;
};

/** on_conflict condition type for table "follows" */
export type Follows_On_Conflict = {
  readonly constraint: Follows_Constraint;
  readonly update_columns?: ReadonlyArray<Follows_Update_Column>;
  readonly where?: InputMaybe<Follows_Bool_Exp>;
};

/** Ordering options when selecting data from "follows". */
export type Follows_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly follower?: InputMaybe<Users_Order_By>;
  readonly follower_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: follows */
export type Follows_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "follows" */
export enum Follows_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FollowerId = 'follower_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "follows" */
export type Follows_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly follower_id?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "follows" */
export type Follows_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Follows_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Follows_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly follower_id?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "follows" */
export enum Follows_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FollowerId = 'follower_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export type Follows_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Follows_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Follows_Bool_Exp;
};

/** columns and relationships of "friends" */
export type Friends = {
  readonly __typename?: 'friends';
  readonly accepted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['Int']['output'];
  readonly receiver_id: Scalars['String']['output'];
  readonly sender_id: Scalars['String']['output'];
};

/** aggregated selection of "friends" */
export type Friends_Aggregate = {
  readonly __typename?: 'friends_aggregate';
  readonly aggregate?: Maybe<Friends_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Friends>;
};

/** aggregate fields of "friends" */
export type Friends_Aggregate_Fields = {
  readonly __typename?: 'friends_aggregate_fields';
  readonly avg?: Maybe<Friends_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Friends_Max_Fields>;
  readonly min?: Maybe<Friends_Min_Fields>;
  readonly stddev?: Maybe<Friends_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Friends_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Friends_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Friends_Sum_Fields>;
  readonly var_pop?: Maybe<Friends_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Friends_Var_Samp_Fields>;
  readonly variance?: Maybe<Friends_Variance_Fields>;
};


/** aggregate fields of "friends" */
export type Friends_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Friends_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Friends_Avg_Fields = {
  readonly __typename?: 'friends_avg_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "friends". All fields are combined with a logical 'AND'. */
export type Friends_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Friends_Bool_Exp>>;
  readonly _not?: InputMaybe<Friends_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Friends_Bool_Exp>>;
  readonly accepted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Int_Comparison_Exp>;
  readonly receiver_id?: InputMaybe<String_Comparison_Exp>;
  readonly sender_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "friends" */
export enum Friends_Constraint {
  /** unique or primary key constraint on columns "id" */
  FriendsPkey = 'friends_pkey',
  /** unique or primary key constraint on columns "receiver_id", "sender_id" */
  FriendsSenderIdReceiverIdKey = 'friends_sender_id_receiver_id_key'
}

/** input type for incrementing numeric columns in table "friends" */
export type Friends_Inc_Input = {
  readonly id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "friends" */
export type Friends_Insert_Input = {
  readonly accepted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly receiver_id?: InputMaybe<Scalars['String']['input']>;
  readonly sender_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Friends_Max_Fields = {
  readonly __typename?: 'friends_max_fields';
  readonly accepted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly receiver_id?: Maybe<Scalars['String']['output']>;
  readonly sender_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Friends_Min_Fields = {
  readonly __typename?: 'friends_min_fields';
  readonly accepted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly receiver_id?: Maybe<Scalars['String']['output']>;
  readonly sender_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "friends" */
export type Friends_Mutation_Response = {
  readonly __typename?: 'friends_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Friends>;
};

/** on_conflict condition type for table "friends" */
export type Friends_On_Conflict = {
  readonly constraint: Friends_Constraint;
  readonly update_columns?: ReadonlyArray<Friends_Update_Column>;
  readonly where?: InputMaybe<Friends_Bool_Exp>;
};

/** Ordering options when selecting data from "friends". */
export type Friends_Order_By = {
  readonly accepted_at?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly receiver_id?: InputMaybe<Order_By>;
  readonly sender_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: friends */
export type Friends_Pk_Columns_Input = {
  readonly id: Scalars['Int']['input'];
};

/** select columns of table "friends" */
export enum Friends_Select_Column {
  /** column name */
  AcceptedAt = 'accepted_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReceiverId = 'receiver_id',
  /** column name */
  SenderId = 'sender_id'
}

/** input type for updating data in table "friends" */
export type Friends_Set_Input = {
  readonly accepted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly receiver_id?: InputMaybe<Scalars['String']['input']>;
  readonly sender_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Friends_Stddev_Fields = {
  readonly __typename?: 'friends_stddev_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Friends_Stddev_Pop_Fields = {
  readonly __typename?: 'friends_stddev_pop_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Friends_Stddev_Samp_Fields = {
  readonly __typename?: 'friends_stddev_samp_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "friends" */
export type Friends_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Friends_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Friends_Stream_Cursor_Value_Input = {
  readonly accepted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly receiver_id?: InputMaybe<Scalars['String']['input']>;
  readonly sender_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Friends_Sum_Fields = {
  readonly __typename?: 'friends_sum_fields';
  readonly id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "friends" */
export enum Friends_Update_Column {
  /** column name */
  AcceptedAt = 'accepted_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReceiverId = 'receiver_id',
  /** column name */
  SenderId = 'sender_id'
}

export type Friends_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Friends_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Friends_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Friends_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Friends_Var_Pop_Fields = {
  readonly __typename?: 'friends_var_pop_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Friends_Var_Samp_Fields = {
  readonly __typename?: 'friends_var_samp_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Friends_Variance_Fields = {
  readonly __typename?: 'friends_variance_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "friendships" */
export type Friendships = {
  readonly __typename?: 'friendships';
  readonly created_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  readonly friendships_users: ReadonlyArray<Friendships_Users>;
  /** An aggregate relationship */
  readonly friendships_users_aggregate: Friendships_Users_Aggregate;
  readonly id: Scalars['uuid']['output'];
  readonly initiated_by: Scalars['String']['output'];
  /** An object relationship */
  readonly initiator: Users;
  /** A computed field, executes function "get_friendship_status" */
  readonly status: Friendships_Status;
};


/** columns and relationships of "friendships" */
export type FriendshipsFriendships_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friendships_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friendships_Users_Order_By>>;
  where?: InputMaybe<Friendships_Users_Bool_Exp>;
};


/** columns and relationships of "friendships" */
export type FriendshipsFriendships_Users_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friendships_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friendships_Users_Order_By>>;
  where?: InputMaybe<Friendships_Users_Bool_Exp>;
};

/** aggregated selection of "friendships" */
export type Friendships_Aggregate = {
  readonly __typename?: 'friendships_aggregate';
  readonly aggregate?: Maybe<Friendships_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Friendships>;
};

/** aggregate fields of "friendships" */
export type Friendships_Aggregate_Fields = {
  readonly __typename?: 'friendships_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Friendships_Max_Fields>;
  readonly min?: Maybe<Friendships_Min_Fields>;
};


/** aggregate fields of "friendships" */
export type Friendships_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Friendships_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "friendships". All fields are combined with a logical 'AND'. */
export type Friendships_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Friendships_Bool_Exp>>;
  readonly _not?: InputMaybe<Friendships_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Friendships_Bool_Exp>>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly friendships_users?: InputMaybe<Friendships_Users_Bool_Exp>;
  readonly friendships_users_aggregate?: InputMaybe<Friendships_Users_Aggregate_Bool_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly initiated_by?: InputMaybe<String_Comparison_Exp>;
  readonly initiator?: InputMaybe<Users_Bool_Exp>;
  readonly status?: InputMaybe<Friendships_Status_Bool_Exp>;
};

/** unique or primary key constraints on table "friendships" */
export enum Friendships_Constraint {
  /** unique or primary key constraint on columns "id" */
  FriendshipsPkey = 'friendships_pkey'
}

/** input type for inserting data into table "friendships" */
export type Friendships_Insert_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly friendships_users?: InputMaybe<Friendships_Users_Arr_Rel_Insert_Input>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly initiated_by?: InputMaybe<Scalars['String']['input']>;
  readonly initiator?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Friendships_Max_Fields = {
  readonly __typename?: 'friendships_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly initiated_by?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "get_friendship_status" */
  readonly status?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Friendships_Min_Fields = {
  readonly __typename?: 'friendships_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly initiated_by?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "get_friendship_status" */
  readonly status?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "friendships" */
export type Friendships_Mutation_Response = {
  readonly __typename?: 'friendships_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Friendships>;
};

/** input type for inserting object relation for remote table "friendships" */
export type Friendships_Obj_Rel_Insert_Input = {
  readonly data: Friendships_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Friendships_On_Conflict>;
};

/** on_conflict condition type for table "friendships" */
export type Friendships_On_Conflict = {
  readonly constraint: Friendships_Constraint;
  readonly update_columns?: ReadonlyArray<Friendships_Update_Column>;
  readonly where?: InputMaybe<Friendships_Bool_Exp>;
};

/** Ordering options when selecting data from "friendships". */
export type Friendships_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly friendships_users_aggregate?: InputMaybe<Friendships_Users_Aggregate_Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly initiated_by?: InputMaybe<Order_By>;
  readonly initiator?: InputMaybe<Users_Order_By>;
  readonly status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: friendships */
export type Friendships_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "friendships" */
export enum Friendships_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InitiatedBy = 'initiated_by'
}

/** input type for updating data in table "friendships" */
export type Friendships_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly initiated_by?: InputMaybe<Scalars['String']['input']>;
};

export enum Friendships_Status {
  Active = 'active',
  Pending = 'pending',
  Terminated = 'terminated'
}

export type Friendships_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<Friendships_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<Friendships_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Friendships_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<Friendships_Status>>;
};

/** Streaming cursor of the table "friendships" */
export type Friendships_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Friendships_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Friendships_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly initiated_by?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "friendships" */
export enum Friendships_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InitiatedBy = 'initiated_by'
}

export type Friendships_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Friendships_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Friendships_Bool_Exp;
};

/** columns and relationships of "friendships_users" */
export type Friendships_Users = {
  readonly __typename?: 'friendships_users';
  readonly created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly friendship: Friendships;
  readonly friendship_id: Scalars['uuid']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly status: Friendships_Users_Status;
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};

/** aggregated selection of "friendships_users" */
export type Friendships_Users_Aggregate = {
  readonly __typename?: 'friendships_users_aggregate';
  readonly aggregate?: Maybe<Friendships_Users_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Friendships_Users>;
};

export type Friendships_Users_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Friendships_Users_Aggregate_Bool_Exp_Count>;
};

export type Friendships_Users_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Friendships_Users_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Friendships_Users_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "friendships_users" */
export type Friendships_Users_Aggregate_Fields = {
  readonly __typename?: 'friendships_users_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Friendships_Users_Max_Fields>;
  readonly min?: Maybe<Friendships_Users_Min_Fields>;
};


/** aggregate fields of "friendships_users" */
export type Friendships_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Friendships_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "friendships_users" */
export type Friendships_Users_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Friendships_Users_Max_Order_By>;
  readonly min?: InputMaybe<Friendships_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "friendships_users" */
export type Friendships_Users_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Friendships_Users_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Friendships_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "friendships_users". All fields are combined with a logical 'AND'. */
export type Friendships_Users_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Friendships_Users_Bool_Exp>>;
  readonly _not?: InputMaybe<Friendships_Users_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Friendships_Users_Bool_Exp>>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly friendship?: InputMaybe<Friendships_Bool_Exp>;
  readonly friendship_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly status?: InputMaybe<Friendships_Users_Status_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "friendships_users" */
export enum Friendships_Users_Constraint {
  /** unique or primary key constraint on columns "user_id", "friendship_id" */
  FriendshipsUsersFriendshipIdUserIdKey = 'friendships_users_friendship_id_user_id_key',
  /** unique or primary key constraint on columns "id" */
  FriendshipsUsersPkey = 'friendships_users_pkey'
}

/** input type for inserting data into table "friendships_users" */
export type Friendships_Users_Insert_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly friendship?: InputMaybe<Friendships_Obj_Rel_Insert_Input>;
  readonly friendship_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly status?: InputMaybe<Friendships_Users_Status>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Friendships_Users_Max_Fields = {
  readonly __typename?: 'friendships_users_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly friendship_id?: Maybe<Scalars['uuid']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "friendships_users" */
export type Friendships_Users_Max_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly friendship_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Friendships_Users_Min_Fields = {
  readonly __typename?: 'friendships_users_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly friendship_id?: Maybe<Scalars['uuid']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "friendships_users" */
export type Friendships_Users_Min_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly friendship_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "friendships_users" */
export type Friendships_Users_Mutation_Response = {
  readonly __typename?: 'friendships_users_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Friendships_Users>;
};

/** on_conflict condition type for table "friendships_users" */
export type Friendships_Users_On_Conflict = {
  readonly constraint: Friendships_Users_Constraint;
  readonly update_columns?: ReadonlyArray<Friendships_Users_Update_Column>;
  readonly where?: InputMaybe<Friendships_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "friendships_users". */
export type Friendships_Users_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly friendship?: InputMaybe<Friendships_Order_By>;
  readonly friendship_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: friendships_users */
export type Friendships_Users_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "friendships_users" */
export enum Friendships_Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FriendshipId = 'friendship_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "friendships_users" */
export type Friendships_Users_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly friendship_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly status?: InputMaybe<Friendships_Users_Status>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

export enum Friendships_Users_Status {
  Active = 'active',
  Pending = 'pending',
  Terminated = 'terminated'
}

export type Friendships_Users_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<Friendships_Users_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<Friendships_Users_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Friendships_Users_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<Friendships_Users_Status>>;
};

/** Streaming cursor of the table "friendships_users" */
export type Friendships_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Friendships_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Friendships_Users_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly friendship_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "friendships_users" */
export enum Friendships_Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FriendshipId = 'friendship_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Friendships_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Friendships_Users_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Friendships_Users_Bool_Exp;
};

/** Minecraft server status reporting. */
export type Game_Servers = {
  readonly __typename?: 'game_servers';
  readonly game_counts: Scalars['jsonb']['output'];
  readonly name: Scalars['String']['output'];
  readonly player_count: Scalars['Int']['output'];
};


/** Minecraft server status reporting. */
export type Game_ServersGame_CountsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "game_servers" */
export type Game_Servers_Aggregate = {
  readonly __typename?: 'game_servers_aggregate';
  readonly aggregate?: Maybe<Game_Servers_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Game_Servers>;
};

/** aggregate fields of "game_servers" */
export type Game_Servers_Aggregate_Fields = {
  readonly __typename?: 'game_servers_aggregate_fields';
  readonly avg?: Maybe<Game_Servers_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Game_Servers_Max_Fields>;
  readonly min?: Maybe<Game_Servers_Min_Fields>;
  readonly stddev?: Maybe<Game_Servers_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Game_Servers_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Game_Servers_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Game_Servers_Sum_Fields>;
  readonly var_pop?: Maybe<Game_Servers_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Game_Servers_Var_Samp_Fields>;
  readonly variance?: Maybe<Game_Servers_Variance_Fields>;
};


/** aggregate fields of "game_servers" */
export type Game_Servers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Game_Servers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Game_Servers_Append_Input = {
  readonly game_counts?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Game_Servers_Avg_Fields = {
  readonly __typename?: 'game_servers_avg_fields';
  readonly player_count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "game_servers". All fields are combined with a logical 'AND'. */
export type Game_Servers_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Game_Servers_Bool_Exp>>;
  readonly _not?: InputMaybe<Game_Servers_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Game_Servers_Bool_Exp>>;
  readonly game_counts?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly player_count?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "game_servers" */
export enum Game_Servers_Constraint {
  /** unique or primary key constraint on columns "name" */
  GameServersPkey = 'game_servers_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Game_Servers_Delete_At_Path_Input = {
  readonly game_counts?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Game_Servers_Delete_Elem_Input = {
  readonly game_counts?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Game_Servers_Delete_Key_Input = {
  readonly game_counts?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "game_servers" */
export type Game_Servers_Inc_Input = {
  readonly player_count?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "game_servers" */
export type Game_Servers_Insert_Input = {
  readonly game_counts?: InputMaybe<Scalars['jsonb']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly player_count?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Game_Servers_Max_Fields = {
  readonly __typename?: 'game_servers_max_fields';
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly player_count?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Game_Servers_Min_Fields = {
  readonly __typename?: 'game_servers_min_fields';
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly player_count?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "game_servers" */
export type Game_Servers_Mutation_Response = {
  readonly __typename?: 'game_servers_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Game_Servers>;
};

/** on_conflict condition type for table "game_servers" */
export type Game_Servers_On_Conflict = {
  readonly constraint: Game_Servers_Constraint;
  readonly update_columns?: ReadonlyArray<Game_Servers_Update_Column>;
  readonly where?: InputMaybe<Game_Servers_Bool_Exp>;
};

/** Ordering options when selecting data from "game_servers". */
export type Game_Servers_Order_By = {
  readonly game_counts?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly player_count?: InputMaybe<Order_By>;
};

/** primary key columns input for table: game_servers */
export type Game_Servers_Pk_Columns_Input = {
  readonly name: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Game_Servers_Prepend_Input = {
  readonly game_counts?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "game_servers" */
export enum Game_Servers_Select_Column {
  /** column name */
  GameCounts = 'game_counts',
  /** column name */
  Name = 'name',
  /** column name */
  PlayerCount = 'player_count'
}

/** input type for updating data in table "game_servers" */
export type Game_Servers_Set_Input = {
  readonly game_counts?: InputMaybe<Scalars['jsonb']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly player_count?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Game_Servers_Stddev_Fields = {
  readonly __typename?: 'game_servers_stddev_fields';
  readonly player_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Game_Servers_Stddev_Pop_Fields = {
  readonly __typename?: 'game_servers_stddev_pop_fields';
  readonly player_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Game_Servers_Stddev_Samp_Fields = {
  readonly __typename?: 'game_servers_stddev_samp_fields';
  readonly player_count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "game_servers" */
export type Game_Servers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Game_Servers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Game_Servers_Stream_Cursor_Value_Input = {
  readonly game_counts?: InputMaybe<Scalars['jsonb']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly player_count?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Game_Servers_Sum_Fields = {
  readonly __typename?: 'game_servers_sum_fields';
  readonly player_count?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "game_servers" */
export enum Game_Servers_Update_Column {
  /** column name */
  GameCounts = 'game_counts',
  /** column name */
  Name = 'name',
  /** column name */
  PlayerCount = 'player_count'
}

export type Game_Servers_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Game_Servers_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Game_Servers_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Game_Servers_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Game_Servers_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Game_Servers_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Game_Servers_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Game_Servers_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Game_Servers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Game_Servers_Var_Pop_Fields = {
  readonly __typename?: 'game_servers_var_pop_fields';
  readonly player_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Game_Servers_Var_Samp_Fields = {
  readonly __typename?: 'game_servers_var_samp_fields';
  readonly player_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Game_Servers_Variance_Fields = {
  readonly __typename?: 'game_servers_variance_fields';
  readonly player_count?: Maybe<Scalars['Float']['output']>;
};

export type GenerateCallAccessTokenOutput = {
  readonly __typename?: 'generateCallAccessTokenOutput';
  readonly exp: Scalars['Int']['output'];
  readonly token: Scalars['String']['output'];
};

/** Private user spaces */
export type Homes = {
  readonly __typename?: 'homes';
  readonly id: Scalars['uuid']['output'];
  readonly meta: Scalars['jsonb']['output'];
  readonly project_id: Scalars['String']['output'];
  readonly user_id: Scalars['String']['output'];
};


/** Private user spaces */
export type HomesMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "homes" */
export type Homes_Aggregate = {
  readonly __typename?: 'homes_aggregate';
  readonly aggregate?: Maybe<Homes_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Homes>;
};

/** aggregate fields of "homes" */
export type Homes_Aggregate_Fields = {
  readonly __typename?: 'homes_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Homes_Max_Fields>;
  readonly min?: Maybe<Homes_Min_Fields>;
};


/** aggregate fields of "homes" */
export type Homes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Homes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Homes_Append_Input = {
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "homes". All fields are combined with a logical 'AND'. */
export type Homes_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Homes_Bool_Exp>>;
  readonly _not?: InputMaybe<Homes_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Homes_Bool_Exp>>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly meta?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly project_id?: InputMaybe<String_Comparison_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "homes" */
export enum Homes_Constraint {
  /** unique or primary key constraint on columns "id" */
  HomesPkey = 'homes_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Homes_Delete_At_Path_Input = {
  readonly meta?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Homes_Delete_Elem_Input = {
  readonly meta?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Homes_Delete_Key_Input = {
  readonly meta?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "homes" */
export type Homes_Insert_Input = {
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Homes_Max_Fields = {
  readonly __typename?: 'homes_max_fields';
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly project_id?: Maybe<Scalars['String']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Homes_Min_Fields = {
  readonly __typename?: 'homes_min_fields';
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly project_id?: Maybe<Scalars['String']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "homes" */
export type Homes_Mutation_Response = {
  readonly __typename?: 'homes_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Homes>;
};

/** on_conflict condition type for table "homes" */
export type Homes_On_Conflict = {
  readonly constraint: Homes_Constraint;
  readonly update_columns?: ReadonlyArray<Homes_Update_Column>;
  readonly where?: InputMaybe<Homes_Bool_Exp>;
};

/** Ordering options when selecting data from "homes". */
export type Homes_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly meta?: InputMaybe<Order_By>;
  readonly project_id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: homes */
export type Homes_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Homes_Prepend_Input = {
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "homes" */
export enum Homes_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "homes" */
export type Homes_Set_Input = {
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "homes" */
export type Homes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Homes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Homes_Stream_Cursor_Value_Input = {
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "homes" */
export enum Homes_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UserId = 'user_id'
}

export type Homes_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Homes_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Homes_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Homes_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Homes_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Homes_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Homes_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Homes_Bool_Exp;
};

/** columns and relationships of "image_cache" */
export type Image_Cache = {
  readonly __typename?: 'image_cache';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly hash: Scalars['bpchar']['output'];
  readonly id: Scalars['Int']['output'];
  readonly size?: Maybe<Scalars['Int']['output']>;
  readonly url: Scalars['String']['output'];
};

/** aggregated selection of "image_cache" */
export type Image_Cache_Aggregate = {
  readonly __typename?: 'image_cache_aggregate';
  readonly aggregate?: Maybe<Image_Cache_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Image_Cache>;
};

/** aggregate fields of "image_cache" */
export type Image_Cache_Aggregate_Fields = {
  readonly __typename?: 'image_cache_aggregate_fields';
  readonly avg?: Maybe<Image_Cache_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Image_Cache_Max_Fields>;
  readonly min?: Maybe<Image_Cache_Min_Fields>;
  readonly stddev?: Maybe<Image_Cache_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Image_Cache_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Image_Cache_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Image_Cache_Sum_Fields>;
  readonly var_pop?: Maybe<Image_Cache_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Image_Cache_Var_Samp_Fields>;
  readonly variance?: Maybe<Image_Cache_Variance_Fields>;
};


/** aggregate fields of "image_cache" */
export type Image_Cache_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Image_Cache_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Image_Cache_Avg_Fields = {
  readonly __typename?: 'image_cache_avg_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly size?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "image_cache". All fields are combined with a logical 'AND'. */
export type Image_Cache_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Image_Cache_Bool_Exp>>;
  readonly _not?: InputMaybe<Image_Cache_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Image_Cache_Bool_Exp>>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly hash?: InputMaybe<Bpchar_Comparison_Exp>;
  readonly id?: InputMaybe<Int_Comparison_Exp>;
  readonly size?: InputMaybe<Int_Comparison_Exp>;
  readonly url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "image_cache" */
export enum Image_Cache_Constraint {
  /** unique or primary key constraint on columns "hash" */
  ImageCacheHashKey = 'image_cache_hash_key',
  /** unique or primary key constraint on columns "id" */
  ImageCachePkey = 'image_cache_pkey'
}

/** input type for incrementing numeric columns in table "image_cache" */
export type Image_Cache_Inc_Input = {
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly size?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "image_cache" */
export type Image_Cache_Insert_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly hash?: InputMaybe<Scalars['bpchar']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly size?: InputMaybe<Scalars['Int']['input']>;
  readonly url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Image_Cache_Max_Fields = {
  readonly __typename?: 'image_cache_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly hash?: Maybe<Scalars['bpchar']['output']>;
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly size?: Maybe<Scalars['Int']['output']>;
  readonly url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Image_Cache_Min_Fields = {
  readonly __typename?: 'image_cache_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly hash?: Maybe<Scalars['bpchar']['output']>;
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly size?: Maybe<Scalars['Int']['output']>;
  readonly url?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "image_cache" */
export type Image_Cache_Mutation_Response = {
  readonly __typename?: 'image_cache_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Image_Cache>;
};

/** on_conflict condition type for table "image_cache" */
export type Image_Cache_On_Conflict = {
  readonly constraint: Image_Cache_Constraint;
  readonly update_columns?: ReadonlyArray<Image_Cache_Update_Column>;
  readonly where?: InputMaybe<Image_Cache_Bool_Exp>;
};

/** Ordering options when selecting data from "image_cache". */
export type Image_Cache_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly hash?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly size?: InputMaybe<Order_By>;
  readonly url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: image_cache */
export type Image_Cache_Pk_Columns_Input = {
  readonly id: Scalars['Int']['input'];
};

/** select columns of table "image_cache" */
export enum Image_Cache_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Size = 'size',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "image_cache" */
export type Image_Cache_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly hash?: InputMaybe<Scalars['bpchar']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly size?: InputMaybe<Scalars['Int']['input']>;
  readonly url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Image_Cache_Stddev_Fields = {
  readonly __typename?: 'image_cache_stddev_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly size?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Image_Cache_Stddev_Pop_Fields = {
  readonly __typename?: 'image_cache_stddev_pop_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly size?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Image_Cache_Stddev_Samp_Fields = {
  readonly __typename?: 'image_cache_stddev_samp_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly size?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "image_cache" */
export type Image_Cache_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Image_Cache_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Image_Cache_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly hash?: InputMaybe<Scalars['bpchar']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly size?: InputMaybe<Scalars['Int']['input']>;
  readonly url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Image_Cache_Sum_Fields = {
  readonly __typename?: 'image_cache_sum_fields';
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly size?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "image_cache" */
export enum Image_Cache_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Size = 'size',
  /** column name */
  Url = 'url'
}

export type Image_Cache_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Image_Cache_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Image_Cache_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Image_Cache_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Image_Cache_Var_Pop_Fields = {
  readonly __typename?: 'image_cache_var_pop_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly size?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Image_Cache_Var_Samp_Fields = {
  readonly __typename?: 'image_cache_var_samp_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly size?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Image_Cache_Variance_Fields = {
  readonly __typename?: 'image_cache_variance_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly size?: Maybe<Scalars['Float']['output']>;
};

export type Infrastructure_Operation_Type = {
  readonly _eq?: InputMaybe<Infrastructure_Operations_Type>;
  readonly _in?: InputMaybe<ReadonlyArray<Infrastructure_Operations_Type>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Infrastructure_Operations_Type>;
  readonly _nin?: InputMaybe<ReadonlyArray<Infrastructure_Operations_Type>>;
};

/** columns and relationships of "infrastructure_operations" */
export type Infrastructure_Operations = {
  readonly __typename?: 'infrastructure_operations';
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly options: Scalars['jsonb']['output'];
  readonly type: Infrastructure_Operations_Type;
};


/** columns and relationships of "infrastructure_operations" */
export type Infrastructure_OperationsOptionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "infrastructure_operations" */
export type Infrastructure_Operations_Aggregate = {
  readonly __typename?: 'infrastructure_operations_aggregate';
  readonly aggregate?: Maybe<Infrastructure_Operations_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Infrastructure_Operations>;
};

/** aggregate fields of "infrastructure_operations" */
export type Infrastructure_Operations_Aggregate_Fields = {
  readonly __typename?: 'infrastructure_operations_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Infrastructure_Operations_Max_Fields>;
  readonly min?: Maybe<Infrastructure_Operations_Min_Fields>;
};


/** aggregate fields of "infrastructure_operations" */
export type Infrastructure_Operations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Infrastructure_Operations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Infrastructure_Operations_Append_Input = {
  readonly options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "infrastructure_operations". All fields are combined with a logical 'AND'. */
export type Infrastructure_Operations_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Infrastructure_Operations_Bool_Exp>>;
  readonly _not?: InputMaybe<Infrastructure_Operations_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Infrastructure_Operations_Bool_Exp>>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly options?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly type?: InputMaybe<Infrastructure_Operation_Type>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Infrastructure_Operations_Delete_At_Path_Input = {
  readonly options?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Infrastructure_Operations_Delete_Elem_Input = {
  readonly options?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Infrastructure_Operations_Delete_Key_Input = {
  readonly options?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "infrastructure_operations" */
export type Infrastructure_Operations_Insert_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly options?: InputMaybe<Scalars['jsonb']['input']>;
  readonly type?: InputMaybe<Infrastructure_Operations_Type>;
};

/** aggregate max on columns */
export type Infrastructure_Operations_Max_Fields = {
  readonly __typename?: 'infrastructure_operations_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Infrastructure_Operations_Min_Fields = {
  readonly __typename?: 'infrastructure_operations_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "infrastructure_operations" */
export type Infrastructure_Operations_Mutation_Response = {
  readonly __typename?: 'infrastructure_operations_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Infrastructure_Operations>;
};

/** Ordering options when selecting data from "infrastructure_operations". */
export type Infrastructure_Operations_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly options?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Infrastructure_Operations_Prepend_Input = {
  readonly options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "infrastructure_operations" */
export enum Infrastructure_Operations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Options = 'options',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "infrastructure_operations" */
export type Infrastructure_Operations_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly options?: InputMaybe<Scalars['jsonb']['input']>;
  readonly type?: InputMaybe<Comment_Object_Type>;
};

/** Streaming cursor of the table "infrastructure_operations" */
export type Infrastructure_Operations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Infrastructure_Operations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Infrastructure_Operations_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly options?: InputMaybe<Scalars['jsonb']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
};

export enum Infrastructure_Operations_Type {
  ClearHasuraLogs = 'clear_hasura_logs',
  ClearInfrastructureOperations = 'clear_infrastructure_operations'
}

export type Infrastructure_Operations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Infrastructure_Operations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Infrastructure_Operations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Infrastructure_Operations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Infrastructure_Operations_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Infrastructure_Operations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Infrastructure_Operations_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Infrastructure_Operations_Bool_Exp;
};

export type Insert_Channel_User_By_Association_Args = {
  readonly _associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly _status?: InputMaybe<Scalars['String']['input']>;
  readonly _user_id?: InputMaybe<Scalars['String']['input']>;
};

export type Insert_Initial_Minecraft_User_Presence_Args = {
  readonly account_id?: InputMaybe<Scalars['String']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
};

export enum Item_Status {
  Active = 'active',
  Hidden = 'hidden',
  Review = 'review'
}

export type Item_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<Item_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<Item_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Item_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<Item_Status>>;
};

/** columns and relationships of "items" */
export type Items = {
  readonly __typename?: 'items';
  readonly created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly creator?: Maybe<Users>;
  readonly creator_id?: Maybe<Scalars['String']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly level_required: Scalars['Int']['output'];
  readonly max_purchase?: Maybe<Scalars['Int']['output']>;
  readonly meta: Scalars['jsonb']['output'];
  readonly name: Scalars['String']['output'];
  /** An array relationship */
  readonly orders: ReadonlyArray<Orders>;
  /** An aggregate relationship */
  readonly orders_aggregate: Orders_Aggregate;
  readonly photo: Scalars['String']['output'];
  readonly price: Scalars['numeric']['output'];
  readonly purchase_link?: Maybe<Scalars['String']['output']>;
  readonly status: Item_Status;
  readonly submission_form_link?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly tags: ReadonlyArray<Items_Tags>;
  /** An aggregate relationship */
  readonly tags_aggregate: Items_Tags_Aggregate;
  /** An array relationship */
  readonly transactions: ReadonlyArray<Transactions>;
  /** An aggregate relationship */
  readonly transactions_aggregate: Transactions_Aggregate;
};


/** columns and relationships of "items" */
export type ItemsMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "items" */
export type ItemsOrdersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


/** columns and relationships of "items" */
export type ItemsOrders_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


/** columns and relationships of "items" */
export type ItemsTagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Items_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Items_Tags_Order_By>>;
  where?: InputMaybe<Items_Tags_Bool_Exp>;
};


/** columns and relationships of "items" */
export type ItemsTags_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Items_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Items_Tags_Order_By>>;
  where?: InputMaybe<Items_Tags_Bool_Exp>;
};


/** columns and relationships of "items" */
export type ItemsTransactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


/** columns and relationships of "items" */
export type ItemsTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};

/** aggregated selection of "items" */
export type Items_Aggregate = {
  readonly __typename?: 'items_aggregate';
  readonly aggregate?: Maybe<Items_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Items>;
};

/** aggregate fields of "items" */
export type Items_Aggregate_Fields = {
  readonly __typename?: 'items_aggregate_fields';
  readonly avg?: Maybe<Items_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Items_Max_Fields>;
  readonly min?: Maybe<Items_Min_Fields>;
  readonly stddev?: Maybe<Items_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Items_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Items_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Items_Sum_Fields>;
  readonly var_pop?: Maybe<Items_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Items_Var_Samp_Fields>;
  readonly variance?: Maybe<Items_Variance_Fields>;
};


/** aggregate fields of "items" */
export type Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Items_Append_Input = {
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Items_Avg_Fields = {
  readonly __typename?: 'items_avg_fields';
  readonly level_required?: Maybe<Scalars['Float']['output']>;
  readonly max_purchase?: Maybe<Scalars['Float']['output']>;
  readonly price?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "items". All fields are combined with a logical 'AND'. */
export type Items_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Items_Bool_Exp>>;
  readonly _not?: InputMaybe<Items_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Items_Bool_Exp>>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly creator?: InputMaybe<Users_Bool_Exp>;
  readonly creator_id?: InputMaybe<String_Comparison_Exp>;
  readonly deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly description?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly level_required?: InputMaybe<Int_Comparison_Exp>;
  readonly max_purchase?: InputMaybe<Int_Comparison_Exp>;
  readonly meta?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly orders?: InputMaybe<Orders_Bool_Exp>;
  readonly orders_aggregate?: InputMaybe<Orders_Aggregate_Bool_Exp>;
  readonly photo?: InputMaybe<String_Comparison_Exp>;
  readonly price?: InputMaybe<Numeric_Comparison_Exp>;
  readonly purchase_link?: InputMaybe<String_Comparison_Exp>;
  readonly status?: InputMaybe<Item_Status_Bool_Exp>;
  readonly submission_form_link?: InputMaybe<String_Comparison_Exp>;
  readonly tags?: InputMaybe<Items_Tags_Bool_Exp>;
  readonly tags_aggregate?: InputMaybe<Items_Tags_Aggregate_Bool_Exp>;
  readonly transactions?: InputMaybe<Transactions_Bool_Exp>;
  readonly transactions_aggregate?: InputMaybe<Transactions_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "items" */
export enum Items_Constraint {
  /** unique or primary key constraint on columns "id" */
  ItemsPkey = 'items_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Items_Delete_At_Path_Input = {
  readonly meta?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Items_Delete_Elem_Input = {
  readonly meta?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Items_Delete_Key_Input = {
  readonly meta?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "items" */
export type Items_Inc_Input = {
  readonly level_required?: InputMaybe<Scalars['Int']['input']>;
  readonly max_purchase?: InputMaybe<Scalars['Int']['input']>;
  readonly price?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "items" */
export type Items_Insert_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly creator?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly creator_id?: InputMaybe<Scalars['String']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly level_required?: InputMaybe<Scalars['Int']['input']>;
  readonly max_purchase?: InputMaybe<Scalars['Int']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly orders?: InputMaybe<Orders_Arr_Rel_Insert_Input>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly price?: InputMaybe<Scalars['numeric']['input']>;
  readonly purchase_link?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Item_Status>;
  readonly submission_form_link?: InputMaybe<Scalars['String']['input']>;
  readonly tags?: InputMaybe<Items_Tags_Arr_Rel_Insert_Input>;
  readonly transactions?: InputMaybe<Transactions_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Items_Max_Fields = {
  readonly __typename?: 'items_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly creator_id?: Maybe<Scalars['String']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly level_required?: Maybe<Scalars['Int']['output']>;
  readonly max_purchase?: Maybe<Scalars['Int']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly photo?: Maybe<Scalars['String']['output']>;
  readonly price?: Maybe<Scalars['numeric']['output']>;
  readonly purchase_link?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly submission_form_link?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Items_Min_Fields = {
  readonly __typename?: 'items_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly creator_id?: Maybe<Scalars['String']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly level_required?: Maybe<Scalars['Int']['output']>;
  readonly max_purchase?: Maybe<Scalars['Int']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly photo?: Maybe<Scalars['String']['output']>;
  readonly price?: Maybe<Scalars['numeric']['output']>;
  readonly purchase_link?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly submission_form_link?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "items" */
export type Items_Mutation_Response = {
  readonly __typename?: 'items_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Items>;
};

/** input type for inserting object relation for remote table "items" */
export type Items_Obj_Rel_Insert_Input = {
  readonly data: Items_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Items_On_Conflict>;
};

/** on_conflict condition type for table "items" */
export type Items_On_Conflict = {
  readonly constraint: Items_Constraint;
  readonly update_columns?: ReadonlyArray<Items_Update_Column>;
  readonly where?: InputMaybe<Items_Bool_Exp>;
};

/** Ordering options when selecting data from "items". */
export type Items_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly creator?: InputMaybe<Users_Order_By>;
  readonly creator_id?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly level_required?: InputMaybe<Order_By>;
  readonly max_purchase?: InputMaybe<Order_By>;
  readonly meta?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly orders_aggregate?: InputMaybe<Orders_Aggregate_Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly price?: InputMaybe<Order_By>;
  readonly purchase_link?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly submission_form_link?: InputMaybe<Order_By>;
  readonly tags_aggregate?: InputMaybe<Items_Tags_Aggregate_Order_By>;
  readonly transactions_aggregate?: InputMaybe<Transactions_Aggregate_Order_By>;
};

/** primary key columns input for table: items */
export type Items_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Items_Prepend_Input = {
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "items" */
export enum Items_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LevelRequired = 'level_required',
  /** column name */
  MaxPurchase = 'max_purchase',
  /** column name */
  Meta = 'meta',
  /** column name */
  Name = 'name',
  /** column name */
  Photo = 'photo',
  /** column name */
  Price = 'price',
  /** column name */
  PurchaseLink = 'purchase_link',
  /** column name */
  Status = 'status',
  /** column name */
  SubmissionFormLink = 'submission_form_link'
}

/** input type for updating data in table "items" */
export type Items_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly creator_id?: InputMaybe<Scalars['String']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly level_required?: InputMaybe<Scalars['Int']['input']>;
  readonly max_purchase?: InputMaybe<Scalars['Int']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly price?: InputMaybe<Scalars['numeric']['input']>;
  readonly purchase_link?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Item_Status>;
  readonly submission_form_link?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Items_Stddev_Fields = {
  readonly __typename?: 'items_stddev_fields';
  readonly level_required?: Maybe<Scalars['Float']['output']>;
  readonly max_purchase?: Maybe<Scalars['Float']['output']>;
  readonly price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Items_Stddev_Pop_Fields = {
  readonly __typename?: 'items_stddev_pop_fields';
  readonly level_required?: Maybe<Scalars['Float']['output']>;
  readonly max_purchase?: Maybe<Scalars['Float']['output']>;
  readonly price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Items_Stddev_Samp_Fields = {
  readonly __typename?: 'items_stddev_samp_fields';
  readonly level_required?: Maybe<Scalars['Float']['output']>;
  readonly max_purchase?: Maybe<Scalars['Float']['output']>;
  readonly price?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "items" */
export type Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly creator_id?: InputMaybe<Scalars['String']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly level_required?: InputMaybe<Scalars['Int']['input']>;
  readonly max_purchase?: InputMaybe<Scalars['Int']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly price?: InputMaybe<Scalars['numeric']['input']>;
  readonly purchase_link?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly submission_form_link?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Items_Sum_Fields = {
  readonly __typename?: 'items_sum_fields';
  readonly level_required?: Maybe<Scalars['Int']['output']>;
  readonly max_purchase?: Maybe<Scalars['Int']['output']>;
  readonly price?: Maybe<Scalars['numeric']['output']>;
};

/** columns and relationships of "items_tags" */
export type Items_Tags = {
  readonly __typename?: 'items_tags';
  /** An object relationship */
  readonly item: Items;
  readonly item_id: Scalars['String']['output'];
  /** An object relationship */
  readonly tag: Tags;
  readonly tag_id: Scalars['String']['output'];
};

/** aggregated selection of "items_tags" */
export type Items_Tags_Aggregate = {
  readonly __typename?: 'items_tags_aggregate';
  readonly aggregate?: Maybe<Items_Tags_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Items_Tags>;
};

export type Items_Tags_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Items_Tags_Aggregate_Bool_Exp_Count>;
};

export type Items_Tags_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Items_Tags_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Items_Tags_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "items_tags" */
export type Items_Tags_Aggregate_Fields = {
  readonly __typename?: 'items_tags_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Items_Tags_Max_Fields>;
  readonly min?: Maybe<Items_Tags_Min_Fields>;
};


/** aggregate fields of "items_tags" */
export type Items_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Items_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "items_tags" */
export type Items_Tags_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Items_Tags_Max_Order_By>;
  readonly min?: InputMaybe<Items_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "items_tags" */
export type Items_Tags_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Items_Tags_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Items_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "items_tags". All fields are combined with a logical 'AND'. */
export type Items_Tags_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Items_Tags_Bool_Exp>>;
  readonly _not?: InputMaybe<Items_Tags_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Items_Tags_Bool_Exp>>;
  readonly item?: InputMaybe<Items_Bool_Exp>;
  readonly item_id?: InputMaybe<String_Comparison_Exp>;
  readonly tag?: InputMaybe<Tags_Bool_Exp>;
  readonly tag_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "items_tags" */
export enum Items_Tags_Constraint {
  /** unique or primary key constraint on columns "item_id", "tag_id" */
  ItemsTagsPkey = 'items_tags_pkey'
}

/** input type for inserting data into table "items_tags" */
export type Items_Tags_Insert_Input = {
  readonly item?: InputMaybe<Items_Obj_Rel_Insert_Input>;
  readonly item_id?: InputMaybe<Scalars['String']['input']>;
  readonly tag?: InputMaybe<Tags_Obj_Rel_Insert_Input>;
  readonly tag_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Items_Tags_Max_Fields = {
  readonly __typename?: 'items_tags_max_fields';
  readonly item_id?: Maybe<Scalars['String']['output']>;
  readonly tag_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "items_tags" */
export type Items_Tags_Max_Order_By = {
  readonly item_id?: InputMaybe<Order_By>;
  readonly tag_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Items_Tags_Min_Fields = {
  readonly __typename?: 'items_tags_min_fields';
  readonly item_id?: Maybe<Scalars['String']['output']>;
  readonly tag_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "items_tags" */
export type Items_Tags_Min_Order_By = {
  readonly item_id?: InputMaybe<Order_By>;
  readonly tag_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "items_tags" */
export type Items_Tags_Mutation_Response = {
  readonly __typename?: 'items_tags_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Items_Tags>;
};

/** on_conflict condition type for table "items_tags" */
export type Items_Tags_On_Conflict = {
  readonly constraint: Items_Tags_Constraint;
  readonly update_columns?: ReadonlyArray<Items_Tags_Update_Column>;
  readonly where?: InputMaybe<Items_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "items_tags". */
export type Items_Tags_Order_By = {
  readonly item?: InputMaybe<Items_Order_By>;
  readonly item_id?: InputMaybe<Order_By>;
  readonly tag?: InputMaybe<Tags_Order_By>;
  readonly tag_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items_tags */
export type Items_Tags_Pk_Columns_Input = {
  readonly item_id: Scalars['String']['input'];
  readonly tag_id: Scalars['String']['input'];
};

/** select columns of table "items_tags" */
export enum Items_Tags_Select_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  TagId = 'tag_id'
}

/** input type for updating data in table "items_tags" */
export type Items_Tags_Set_Input = {
  readonly item_id?: InputMaybe<Scalars['String']['input']>;
  readonly tag_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "items_tags" */
export type Items_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Items_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Tags_Stream_Cursor_Value_Input = {
  readonly item_id?: InputMaybe<Scalars['String']['input']>;
  readonly tag_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "items_tags" */
export enum Items_Tags_Update_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  TagId = 'tag_id'
}

export type Items_Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Items_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Items_Tags_Bool_Exp;
};

/** update columns of table "items" */
export enum Items_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LevelRequired = 'level_required',
  /** column name */
  MaxPurchase = 'max_purchase',
  /** column name */
  Meta = 'meta',
  /** column name */
  Name = 'name',
  /** column name */
  Photo = 'photo',
  /** column name */
  Price = 'price',
  /** column name */
  PurchaseLink = 'purchase_link',
  /** column name */
  Status = 'status',
  /** column name */
  SubmissionFormLink = 'submission_form_link'
}

export type Items_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Items_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Items_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Items_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Items_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Items_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Items_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Items_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Items_Var_Pop_Fields = {
  readonly __typename?: 'items_var_pop_fields';
  readonly level_required?: Maybe<Scalars['Float']['output']>;
  readonly max_purchase?: Maybe<Scalars['Float']['output']>;
  readonly price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Items_Var_Samp_Fields = {
  readonly __typename?: 'items_var_samp_fields';
  readonly level_required?: Maybe<Scalars['Float']['output']>;
  readonly max_purchase?: Maybe<Scalars['Float']['output']>;
  readonly price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Items_Variance_Fields = {
  readonly __typename?: 'items_variance_fields';
  readonly level_required?: Maybe<Scalars['Float']['output']>;
  readonly max_purchase?: Maybe<Scalars['Float']['output']>;
  readonly price?: Maybe<Scalars['Float']['output']>;
};

export type Jsonb_Cast_Exp = {
  readonly String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  readonly _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  readonly _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  readonly _contains?: InputMaybe<Scalars['jsonb']['input']>;
  readonly _eq?: InputMaybe<Scalars['jsonb']['input']>;
  readonly _gt?: InputMaybe<Scalars['jsonb']['input']>;
  readonly _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  readonly _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  readonly _has_keys_all?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  readonly _has_keys_any?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['jsonb']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lt?: InputMaybe<Scalars['jsonb']['input']>;
  readonly _lte?: InputMaybe<Scalars['jsonb']['input']>;
  readonly _neq?: InputMaybe<Scalars['jsonb']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['jsonb']['input']>>;
};

export type Level_Reward = {
  readonly __typename?: 'level_reward';
  readonly associated_object: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
};

export type Level_Reward_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "levels" */
export type Levels = {
  readonly __typename?: 'levels';
  readonly created_at: Scalars['timestamptz']['output'];
  readonly level: Scalars['Int']['output'];
  readonly rewards: ReadonlyArray<Level_Reward>;
  readonly updated_at: Scalars['timestamptz']['output'];
  readonly xp: Scalars['Int']['output'];
};


/** columns and relationships of "levels" */
export type LevelsRewardsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "levels" */
export type Levels_Aggregate = {
  readonly __typename?: 'levels_aggregate';
  readonly aggregate?: Maybe<Levels_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Levels>;
};

/** aggregate fields of "levels" */
export type Levels_Aggregate_Fields = {
  readonly __typename?: 'levels_aggregate_fields';
  readonly avg?: Maybe<Levels_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Levels_Max_Fields>;
  readonly min?: Maybe<Levels_Min_Fields>;
  readonly stddev?: Maybe<Levels_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Levels_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Levels_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Levels_Sum_Fields>;
  readonly var_pop?: Maybe<Levels_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Levels_Var_Samp_Fields>;
  readonly variance?: Maybe<Levels_Variance_Fields>;
};


/** aggregate fields of "levels" */
export type Levels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Levels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Levels_Append_Input = {
  readonly rewards?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Levels_Avg_Fields = {
  readonly __typename?: 'levels_avg_fields';
  readonly level?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "levels". All fields are combined with a logical 'AND'. */
export type Levels_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Levels_Bool_Exp>>;
  readonly _not?: InputMaybe<Levels_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Levels_Bool_Exp>>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly level?: InputMaybe<Int_Comparison_Exp>;
  readonly rewards?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly xp?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "levels" */
export enum Levels_Constraint {
  /** unique or primary key constraint on columns "level" */
  LevelsPkey = 'levels_pkey',
  /** unique or primary key constraint on columns "xp" */
  LevelsXpIndex = 'levels_xp_index'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Levels_Delete_At_Path_Input = {
  readonly rewards?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Levels_Delete_Elem_Input = {
  readonly rewards?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Levels_Delete_Key_Input = {
  readonly rewards?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "levels" */
export type Levels_Inc_Input = {
  readonly level?: InputMaybe<Scalars['Int']['input']>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "levels" */
export type Levels_Insert_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly level?: InputMaybe<Scalars['Int']['input']>;
  readonly rewards?: InputMaybe<ReadonlyArray<InputMaybe<Level_Reward_Input>>>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Levels_Max_Fields = {
  readonly __typename?: 'levels_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly level?: Maybe<Scalars['Int']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly xp?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Levels_Min_Fields = {
  readonly __typename?: 'levels_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly level?: Maybe<Scalars['Int']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly xp?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "levels" */
export type Levels_Mutation_Response = {
  readonly __typename?: 'levels_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Levels>;
};

/** on_conflict condition type for table "levels" */
export type Levels_On_Conflict = {
  readonly constraint: Levels_Constraint;
  readonly update_columns?: ReadonlyArray<Levels_Update_Column>;
  readonly where?: InputMaybe<Levels_Bool_Exp>;
};

/** Ordering options when selecting data from "levels". */
export type Levels_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly rewards?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** primary key columns input for table: levels */
export type Levels_Pk_Columns_Input = {
  readonly level: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Levels_Prepend_Input = {
  readonly rewards?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "levels" */
export enum Levels_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Level = 'level',
  /** column name */
  Rewards = 'rewards',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Xp = 'xp'
}

/** input type for updating data in table "levels" */
export type Levels_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly level?: InputMaybe<Scalars['Int']['input']>;
  readonly rewards?: InputMaybe<Scalars['jsonb']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Levels_Stddev_Fields = {
  readonly __typename?: 'levels_stddev_fields';
  readonly level?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Levels_Stddev_Pop_Fields = {
  readonly __typename?: 'levels_stddev_pop_fields';
  readonly level?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Levels_Stddev_Samp_Fields = {
  readonly __typename?: 'levels_stddev_samp_fields';
  readonly level?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "levels" */
export type Levels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Levels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Levels_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly level?: InputMaybe<Scalars['Int']['input']>;
  readonly rewards?: InputMaybe<Scalars['jsonb']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Levels_Sum_Fields = {
  readonly __typename?: 'levels_sum_fields';
  readonly level?: Maybe<Scalars['Int']['output']>;
  readonly xp?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "levels" */
export enum Levels_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Level = 'level',
  /** column name */
  Rewards = 'rewards',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Xp = 'xp'
}

export type Levels_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Levels_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Levels_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Levels_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Levels_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Levels_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Levels_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Levels_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Levels_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Levels_Var_Pop_Fields = {
  readonly __typename?: 'levels_var_pop_fields';
  readonly level?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Levels_Var_Samp_Fields = {
  readonly __typename?: 'levels_var_samp_fields';
  readonly level?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Levels_Variance_Fields = {
  readonly __typename?: 'levels_variance_fields';
  readonly level?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

export enum Live_Event_Recurring {
  Daily = 'daily',
  Monthly = 'monthly',
  Weekly = 'weekly'
}

export type Live_Event_Recurring_Bool_Exp = {
  readonly _eq?: InputMaybe<Live_Event_Recurring>;
  readonly _in?: InputMaybe<ReadonlyArray<Live_Event_Recurring>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Live_Event_Recurring>;
  readonly _nin?: InputMaybe<ReadonlyArray<Live_Event_Recurring>>;
};

export enum Live_Event_Status {
  Cancelled = 'cancelled',
  Complete = 'complete',
  Hidden = 'hidden',
  Live = 'live',
  Open = 'open',
  Upcoming = 'upcoming'
}

export type Live_Event_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<Live_Event_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<Live_Event_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Live_Event_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<Live_Event_Status>>;
};

/** columns and relationships of "live_events" */
export type Live_Events = {
  readonly __typename?: 'live_events';
  readonly agenda: Scalars['String']['output'];
  readonly association_id: Scalars['String']['output'];
  readonly background_image_url?: Maybe<Scalars['String']['output']>;
  readonly clipboard_prompt?: Maybe<Scalars['clipboard_prompt']['output']>;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly ends_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly feedback_question?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly hosts: ReadonlyArray<Live_Events_Hosts>;
  /** An aggregate relationship */
  readonly hosts_aggregate: Live_Events_Hosts_Aggregate;
  readonly id: Scalars['uuid']['output'];
  readonly members_only: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
  /** An object relationship */
  readonly next_event?: Maybe<Live_Events>;
  /** An object relationship */
  readonly previous_event?: Maybe<Live_Events>;
  readonly previous_event_id?: Maybe<Scalars['uuid']['output']>;
  readonly promo_image_url: Scalars['String']['output'];
  readonly recurring: Live_Event_Recurring;
  readonly requires_host: Scalars['Boolean']['output'];
  /** An object relationship */
  readonly room?: Maybe<Rooms>;
  readonly room_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  readonly schedule?: Maybe<Schedules>;
  readonly schedule_id?: Maybe<Scalars['uuid']['output']>;
  readonly starts_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly status: Live_Event_Status;
  readonly tagline: Scalars['String']['output'];
  readonly thumbnail_image_url?: Maybe<Scalars['String']['output']>;
  readonly updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "live_events" */
export type Live_EventsClipboard_PromptArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "live_events" */
export type Live_EventsHostsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Order_By>>;
  where?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
};


/** columns and relationships of "live_events" */
export type Live_EventsHosts_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Order_By>>;
  where?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
};

/** aggregated selection of "live_events" */
export type Live_Events_Aggregate = {
  readonly __typename?: 'live_events_aggregate';
  readonly aggregate?: Maybe<Live_Events_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Live_Events>;
};

export type Live_Events_Aggregate_Bool_Exp = {
  readonly bool_and?: InputMaybe<Live_Events_Aggregate_Bool_Exp_Bool_And>;
  readonly bool_or?: InputMaybe<Live_Events_Aggregate_Bool_Exp_Bool_Or>;
  readonly count?: InputMaybe<Live_Events_Aggregate_Bool_Exp_Count>;
};

export type Live_Events_Aggregate_Bool_Exp_Bool_And = {
  readonly arguments: Live_Events_Select_Column_Live_Events_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Live_Events_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Live_Events_Aggregate_Bool_Exp_Bool_Or = {
  readonly arguments: Live_Events_Select_Column_Live_Events_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Live_Events_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Live_Events_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Live_Events_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Live_Events_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "live_events" */
export type Live_Events_Aggregate_Fields = {
  readonly __typename?: 'live_events_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Live_Events_Max_Fields>;
  readonly min?: Maybe<Live_Events_Min_Fields>;
};


/** aggregate fields of "live_events" */
export type Live_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Live_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "live_events" */
export type Live_Events_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Live_Events_Max_Order_By>;
  readonly min?: InputMaybe<Live_Events_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Live_Events_Append_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "live_events" */
export type Live_Events_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Live_Events_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Live_Events_On_Conflict>;
};

/** Boolean expression to filter rows from the table "live_events". All fields are combined with a logical 'AND'. */
export type Live_Events_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Live_Events_Bool_Exp>>;
  readonly _not?: InputMaybe<Live_Events_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Live_Events_Bool_Exp>>;
  readonly agenda?: InputMaybe<String_Comparison_Exp>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly background_image_url?: InputMaybe<String_Comparison_Exp>;
  readonly clipboard_prompt?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly ends_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly feedback_question?: InputMaybe<String_Comparison_Exp>;
  readonly hosts?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
  readonly hosts_aggregate?: InputMaybe<Live_Events_Hosts_Aggregate_Bool_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly members_only?: InputMaybe<Boolean_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly next_event?: InputMaybe<Live_Events_Bool_Exp>;
  readonly previous_event?: InputMaybe<Live_Events_Bool_Exp>;
  readonly previous_event_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly promo_image_url?: InputMaybe<String_Comparison_Exp>;
  readonly recurring?: InputMaybe<Live_Event_Recurring_Bool_Exp>;
  readonly requires_host?: InputMaybe<Boolean_Comparison_Exp>;
  readonly room?: InputMaybe<Rooms_Bool_Exp>;
  readonly room_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly schedule?: InputMaybe<Schedules_Bool_Exp>;
  readonly schedule_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly starts_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly status?: InputMaybe<Live_Event_Status_Bool_Exp>;
  readonly tagline?: InputMaybe<String_Comparison_Exp>;
  readonly thumbnail_image_url?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "live_events" */
export enum Live_Events_Constraint {
  /** unique or primary key constraint on columns "association_id" */
  LiveEventsAssociationIdKey = 'live_events_association_id_key',
  /** unique or primary key constraint on columns "id" */
  LiveEventsPkey = 'live_events_pkey',
  /** unique or primary key constraint on columns "previous_event_id" */
  LiveEventsPreviousEventIdKey = 'live_events_previous_event_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Live_Events_Delete_At_Path_Input = {
  readonly clipboard_prompt?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Live_Events_Delete_Elem_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Live_Events_Delete_Key_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "live_events_hosts" */
export type Live_Events_Hosts = {
  readonly __typename?: 'live_events_hosts';
  /** An object relationship */
  readonly live_event: Live_Events;
  readonly live_event_id: Scalars['uuid']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};

/** aggregated selection of "live_events_hosts" */
export type Live_Events_Hosts_Aggregate = {
  readonly __typename?: 'live_events_hosts_aggregate';
  readonly aggregate?: Maybe<Live_Events_Hosts_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Live_Events_Hosts>;
};

export type Live_Events_Hosts_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Live_Events_Hosts_Aggregate_Bool_Exp_Count>;
};

export type Live_Events_Hosts_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "live_events_hosts" */
export type Live_Events_Hosts_Aggregate_Fields = {
  readonly __typename?: 'live_events_hosts_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Live_Events_Hosts_Max_Fields>;
  readonly min?: Maybe<Live_Events_Hosts_Min_Fields>;
};


/** aggregate fields of "live_events_hosts" */
export type Live_Events_Hosts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "live_events_hosts" */
export type Live_Events_Hosts_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Live_Events_Hosts_Max_Order_By>;
  readonly min?: InputMaybe<Live_Events_Hosts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "live_events_hosts" */
export type Live_Events_Hosts_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Live_Events_Hosts_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Live_Events_Hosts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "live_events_hosts". All fields are combined with a logical 'AND'. */
export type Live_Events_Hosts_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Bool_Exp>>;
  readonly _not?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Bool_Exp>>;
  readonly live_event?: InputMaybe<Live_Events_Bool_Exp>;
  readonly live_event_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "live_events_hosts" */
export enum Live_Events_Hosts_Constraint {
  /** unique or primary key constraint on columns "user_id", "live_event_id" */
  LiveEventsHostsPkey = 'live_events_hosts_pkey'
}

/** input type for inserting data into table "live_events_hosts" */
export type Live_Events_Hosts_Insert_Input = {
  readonly live_event?: InputMaybe<Live_Events_Obj_Rel_Insert_Input>;
  readonly live_event_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Live_Events_Hosts_Max_Fields = {
  readonly __typename?: 'live_events_hosts_max_fields';
  readonly live_event_id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "live_events_hosts" */
export type Live_Events_Hosts_Max_Order_By = {
  readonly live_event_id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Live_Events_Hosts_Min_Fields = {
  readonly __typename?: 'live_events_hosts_min_fields';
  readonly live_event_id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "live_events_hosts" */
export type Live_Events_Hosts_Min_Order_By = {
  readonly live_event_id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "live_events_hosts" */
export type Live_Events_Hosts_Mutation_Response = {
  readonly __typename?: 'live_events_hosts_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Live_Events_Hosts>;
};

/** on_conflict condition type for table "live_events_hosts" */
export type Live_Events_Hosts_On_Conflict = {
  readonly constraint: Live_Events_Hosts_Constraint;
  readonly update_columns?: ReadonlyArray<Live_Events_Hosts_Update_Column>;
  readonly where?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
};

/** Ordering options when selecting data from "live_events_hosts". */
export type Live_Events_Hosts_Order_By = {
  readonly live_event?: InputMaybe<Live_Events_Order_By>;
  readonly live_event_id?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: live_events_hosts */
export type Live_Events_Hosts_Pk_Columns_Input = {
  readonly live_event_id: Scalars['uuid']['input'];
  readonly user_id: Scalars['String']['input'];
};

/** select columns of table "live_events_hosts" */
export enum Live_Events_Hosts_Select_Column {
  /** column name */
  LiveEventId = 'live_event_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "live_events_hosts" */
export type Live_Events_Hosts_Set_Input = {
  readonly live_event_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "live_events_hosts" */
export type Live_Events_Hosts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Live_Events_Hosts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Live_Events_Hosts_Stream_Cursor_Value_Input = {
  readonly live_event_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "live_events_hosts" */
export enum Live_Events_Hosts_Update_Column {
  /** column name */
  LiveEventId = 'live_event_id',
  /** column name */
  UserId = 'user_id'
}

export type Live_Events_Hosts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Live_Events_Hosts_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Live_Events_Hosts_Bool_Exp;
};

/** input type for inserting data into table "live_events" */
export type Live_Events_Insert_Input = {
  readonly agenda?: InputMaybe<Scalars['String']['input']>;
  readonly background_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly clipboard_prompt?: InputMaybe<Scalars['clipboard_prompt']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly ends_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly feedback_question?: InputMaybe<Scalars['String']['input']>;
  readonly hosts?: InputMaybe<Live_Events_Hosts_Arr_Rel_Insert_Input>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly members_only?: InputMaybe<Scalars['Boolean']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly next_event?: InputMaybe<Live_Events_Obj_Rel_Insert_Input>;
  readonly previous_event?: InputMaybe<Live_Events_Obj_Rel_Insert_Input>;
  readonly previous_event_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly promo_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly recurring?: InputMaybe<Live_Event_Recurring>;
  readonly requires_host?: InputMaybe<Scalars['Boolean']['input']>;
  readonly room?: InputMaybe<Rooms_Obj_Rel_Insert_Input>;
  readonly room_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly schedule?: InputMaybe<Schedules_Obj_Rel_Insert_Input>;
  readonly schedule_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly starts_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly status?: InputMaybe<Live_Event_Status>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly thumbnail_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Live_Events_Max_Fields = {
  readonly __typename?: 'live_events_max_fields';
  readonly agenda?: Maybe<Scalars['String']['output']>;
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly background_image_url?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly ends_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly feedback_question?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly previous_event_id?: Maybe<Scalars['uuid']['output']>;
  readonly promo_image_url?: Maybe<Scalars['String']['output']>;
  readonly recurring?: Maybe<Scalars['String']['output']>;
  readonly room_id?: Maybe<Scalars['uuid']['output']>;
  readonly schedule_id?: Maybe<Scalars['uuid']['output']>;
  readonly starts_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly thumbnail_image_url?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "live_events" */
export type Live_Events_Max_Order_By = {
  readonly agenda?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly background_image_url?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly ends_at?: InputMaybe<Order_By>;
  readonly feedback_question?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly previous_event_id?: InputMaybe<Order_By>;
  readonly promo_image_url?: InputMaybe<Order_By>;
  readonly recurring?: InputMaybe<Order_By>;
  readonly room_id?: InputMaybe<Order_By>;
  readonly schedule_id?: InputMaybe<Order_By>;
  readonly starts_at?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly tagline?: InputMaybe<Order_By>;
  readonly thumbnail_image_url?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Live_Events_Min_Fields = {
  readonly __typename?: 'live_events_min_fields';
  readonly agenda?: Maybe<Scalars['String']['output']>;
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly background_image_url?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly ends_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly feedback_question?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly previous_event_id?: Maybe<Scalars['uuid']['output']>;
  readonly promo_image_url?: Maybe<Scalars['String']['output']>;
  readonly recurring?: Maybe<Scalars['String']['output']>;
  readonly room_id?: Maybe<Scalars['uuid']['output']>;
  readonly schedule_id?: Maybe<Scalars['uuid']['output']>;
  readonly starts_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly thumbnail_image_url?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "live_events" */
export type Live_Events_Min_Order_By = {
  readonly agenda?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly background_image_url?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly ends_at?: InputMaybe<Order_By>;
  readonly feedback_question?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly previous_event_id?: InputMaybe<Order_By>;
  readonly promo_image_url?: InputMaybe<Order_By>;
  readonly recurring?: InputMaybe<Order_By>;
  readonly room_id?: InputMaybe<Order_By>;
  readonly schedule_id?: InputMaybe<Order_By>;
  readonly starts_at?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly tagline?: InputMaybe<Order_By>;
  readonly thumbnail_image_url?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "live_events" */
export type Live_Events_Mutation_Response = {
  readonly __typename?: 'live_events_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Live_Events>;
};

/** input type for inserting object relation for remote table "live_events" */
export type Live_Events_Obj_Rel_Insert_Input = {
  readonly data: Live_Events_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Live_Events_On_Conflict>;
};

/** on_conflict condition type for table "live_events" */
export type Live_Events_On_Conflict = {
  readonly constraint: Live_Events_Constraint;
  readonly update_columns?: ReadonlyArray<Live_Events_Update_Column>;
  readonly where?: InputMaybe<Live_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "live_events". */
export type Live_Events_Order_By = {
  readonly agenda?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly background_image_url?: InputMaybe<Order_By>;
  readonly clipboard_prompt?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly ends_at?: InputMaybe<Order_By>;
  readonly feedback_question?: InputMaybe<Order_By>;
  readonly hosts_aggregate?: InputMaybe<Live_Events_Hosts_Aggregate_Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly members_only?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly next_event?: InputMaybe<Live_Events_Order_By>;
  readonly previous_event?: InputMaybe<Live_Events_Order_By>;
  readonly previous_event_id?: InputMaybe<Order_By>;
  readonly promo_image_url?: InputMaybe<Order_By>;
  readonly recurring?: InputMaybe<Order_By>;
  readonly requires_host?: InputMaybe<Order_By>;
  readonly room?: InputMaybe<Rooms_Order_By>;
  readonly room_id?: InputMaybe<Order_By>;
  readonly schedule?: InputMaybe<Schedules_Order_By>;
  readonly schedule_id?: InputMaybe<Order_By>;
  readonly starts_at?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly tagline?: InputMaybe<Order_By>;
  readonly thumbnail_image_url?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: live_events */
export type Live_Events_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Live_Events_Prepend_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "live_events" */
export enum Live_Events_Select_Column {
  /** column name */
  Agenda = 'agenda',
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  BackgroundImageUrl = 'background_image_url',
  /** column name */
  ClipboardPrompt = 'clipboard_prompt',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  FeedbackQuestion = 'feedback_question',
  /** column name */
  Id = 'id',
  /** column name */
  MembersOnly = 'members_only',
  /** column name */
  Name = 'name',
  /** column name */
  PreviousEventId = 'previous_event_id',
  /** column name */
  PromoImageUrl = 'promo_image_url',
  /** column name */
  Recurring = 'recurring',
  /** column name */
  RequiresHost = 'requires_host',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  ScheduleId = 'schedule_id',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  Status = 'status',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  ThumbnailImageUrl = 'thumbnail_image_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "live_events_aggregate_bool_exp_bool_and_arguments_columns" columns of table "live_events" */
export enum Live_Events_Select_Column_Live_Events_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  MembersOnly = 'members_only',
  /** column name */
  RequiresHost = 'requires_host'
}

/** select "live_events_aggregate_bool_exp_bool_or_arguments_columns" columns of table "live_events" */
export enum Live_Events_Select_Column_Live_Events_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  MembersOnly = 'members_only',
  /** column name */
  RequiresHost = 'requires_host'
}

/** input type for updating data in table "live_events" */
export type Live_Events_Set_Input = {
  readonly agenda?: InputMaybe<Scalars['String']['input']>;
  readonly background_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly clipboard_prompt?: InputMaybe<Scalars['clipboard_prompt']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly ends_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly feedback_question?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly members_only?: InputMaybe<Scalars['Boolean']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly previous_event_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly promo_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly recurring?: InputMaybe<Live_Event_Recurring>;
  readonly requires_host?: InputMaybe<Scalars['Boolean']['input']>;
  readonly room_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly schedule_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly starts_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly status?: InputMaybe<Live_Event_Status>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly thumbnail_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "live_events" */
export type Live_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Live_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Live_Events_Stream_Cursor_Value_Input = {
  readonly agenda?: InputMaybe<Scalars['String']['input']>;
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly background_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly clipboard_prompt?: InputMaybe<Scalars['jsonb']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly ends_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly feedback_question?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly members_only?: InputMaybe<Scalars['Boolean']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly previous_event_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly promo_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly recurring?: InputMaybe<Scalars['String']['input']>;
  readonly requires_host?: InputMaybe<Scalars['Boolean']['input']>;
  readonly room_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly schedule_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly starts_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly thumbnail_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "live_events" */
export enum Live_Events_Update_Column {
  /** column name */
  Agenda = 'agenda',
  /** column name */
  BackgroundImageUrl = 'background_image_url',
  /** column name */
  ClipboardPrompt = 'clipboard_prompt',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  FeedbackQuestion = 'feedback_question',
  /** column name */
  Id = 'id',
  /** column name */
  MembersOnly = 'members_only',
  /** column name */
  Name = 'name',
  /** column name */
  PreviousEventId = 'previous_event_id',
  /** column name */
  PromoImageUrl = 'promo_image_url',
  /** column name */
  Recurring = 'recurring',
  /** column name */
  RequiresHost = 'requires_host',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  ScheduleId = 'schedule_id',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  Status = 'status',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  ThumbnailImageUrl = 'thumbnail_image_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Live_Events_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Live_Events_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Live_Events_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Live_Events_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Live_Events_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Live_Events_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Live_Events_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Live_Events_Bool_Exp;
};

/** columns and relationships of "messages" */
export type Messages = {
  readonly __typename?: 'messages';
  readonly attachments: Scalars['jsonb']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly deleted_at?: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['uuid']['output'];
  readonly receiver: Scalars['String']['output'];
  readonly sender_id: Scalars['String']['output'];
  readonly text: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "messages" */
export type MessagesAttachmentsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "messages" */
export type Messages_Aggregate = {
  readonly __typename?: 'messages_aggregate';
  readonly aggregate?: Maybe<Messages_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Messages>;
};

/** aggregate fields of "messages" */
export type Messages_Aggregate_Fields = {
  readonly __typename?: 'messages_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Messages_Max_Fields>;
  readonly min?: Maybe<Messages_Min_Fields>;
};


/** aggregate fields of "messages" */
export type Messages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Messages_Append_Input = {
  readonly attachments?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "messages". All fields are combined with a logical 'AND'. */
export type Messages_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Messages_Bool_Exp>>;
  readonly _not?: InputMaybe<Messages_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Messages_Bool_Exp>>;
  readonly attachments?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly deleted_at?: InputMaybe<Boolean_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly receiver?: InputMaybe<String_Comparison_Exp>;
  readonly sender_id?: InputMaybe<String_Comparison_Exp>;
  readonly text?: InputMaybe<String_Comparison_Exp>;
  readonly type?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "messages" */
export enum Messages_Constraint {
  /** unique or primary key constraint on columns "id" */
  MessagesPkey = 'messages_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Messages_Delete_At_Path_Input = {
  readonly attachments?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Messages_Delete_Elem_Input = {
  readonly attachments?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Messages_Delete_Key_Input = {
  readonly attachments?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "messages" */
export type Messages_Insert_Input = {
  readonly attachments?: InputMaybe<Scalars['jsonb']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly receiver?: InputMaybe<Scalars['String']['input']>;
  readonly sender_id?: InputMaybe<Scalars['String']['input']>;
  readonly text?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Messages_Max_Fields = {
  readonly __typename?: 'messages_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly receiver?: Maybe<Scalars['String']['output']>;
  readonly sender_id?: Maybe<Scalars['String']['output']>;
  readonly text?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Messages_Min_Fields = {
  readonly __typename?: 'messages_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly receiver?: Maybe<Scalars['String']['output']>;
  readonly sender_id?: Maybe<Scalars['String']['output']>;
  readonly text?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "messages" */
export type Messages_Mutation_Response = {
  readonly __typename?: 'messages_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Messages>;
};

/** on_conflict condition type for table "messages" */
export type Messages_On_Conflict = {
  readonly constraint: Messages_Constraint;
  readonly update_columns?: ReadonlyArray<Messages_Update_Column>;
  readonly where?: InputMaybe<Messages_Bool_Exp>;
};

/** Ordering options when selecting data from "messages". */
export type Messages_Order_By = {
  readonly attachments?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly receiver?: InputMaybe<Order_By>;
  readonly sender_id?: InputMaybe<Order_By>;
  readonly text?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: messages */
export type Messages_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Messages_Prepend_Input = {
  readonly attachments?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "messages" */
export enum Messages_Select_Column {
  /** column name */
  Attachments = 'attachments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Receiver = 'receiver',
  /** column name */
  SenderId = 'sender_id',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "messages" */
export type Messages_Set_Input = {
  readonly attachments?: InputMaybe<Scalars['jsonb']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly receiver?: InputMaybe<Scalars['String']['input']>;
  readonly sender_id?: InputMaybe<Scalars['String']['input']>;
  readonly text?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "messages" */
export type Messages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Messages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Messages_Stream_Cursor_Value_Input = {
  readonly attachments?: InputMaybe<Scalars['jsonb']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly receiver?: InputMaybe<Scalars['String']['input']>;
  readonly sender_id?: InputMaybe<Scalars['String']['input']>;
  readonly text?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "messages" */
export enum Messages_Update_Column {
  /** column name */
  Attachments = 'attachments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Receiver = 'receiver',
  /** column name */
  SenderId = 'sender_id',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Messages_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Messages_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Messages_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Messages_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Messages_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Messages_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Messages_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Messages_Bool_Exp;
};

/** columns and relationships of "minecraft_announcement" */
export type Minecraft_Announcement = {
  readonly __typename?: 'minecraft_announcement';
  readonly created_at: Scalars['timestamptz']['output'];
  readonly data: Scalars['jsonb']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly rooms: ReadonlyArray<Scalars['uuid']['output']>;
  readonly type: Scalars['String']['output'];
  readonly valid_until: Scalars['timestamptz']['output'];
};


/** columns and relationships of "minecraft_announcement" */
export type Minecraft_AnnouncementDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "minecraft_announcement" */
export type Minecraft_Announcement_Aggregate = {
  readonly __typename?: 'minecraft_announcement_aggregate';
  readonly aggregate?: Maybe<Minecraft_Announcement_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Minecraft_Announcement>;
};

/** aggregate fields of "minecraft_announcement" */
export type Minecraft_Announcement_Aggregate_Fields = {
  readonly __typename?: 'minecraft_announcement_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Minecraft_Announcement_Max_Fields>;
  readonly min?: Maybe<Minecraft_Announcement_Min_Fields>;
};


/** aggregate fields of "minecraft_announcement" */
export type Minecraft_Announcement_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Minecraft_Announcement_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Minecraft_Announcement_Append_Input = {
  readonly data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "minecraft_announcement". All fields are combined with a logical 'AND'. */
export type Minecraft_Announcement_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Minecraft_Announcement_Bool_Exp>>;
  readonly _not?: InputMaybe<Minecraft_Announcement_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Minecraft_Announcement_Bool_Exp>>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly data?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly rooms?: InputMaybe<Uuid_Array_Comparison_Exp>;
  readonly type?: InputMaybe<String_Comparison_Exp>;
  readonly valid_until?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "minecraft_announcement" */
export enum Minecraft_Announcement_Constraint {
  /** unique or primary key constraint on columns "id" */
  MinecraftAnnouncementPkey = 'minecraft_announcement_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Minecraft_Announcement_Delete_At_Path_Input = {
  readonly data?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Minecraft_Announcement_Delete_Elem_Input = {
  readonly data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Minecraft_Announcement_Delete_Key_Input = {
  readonly data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "minecraft_announcement" */
export type Minecraft_Announcement_Insert_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly data?: InputMaybe<Scalars['jsonb']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly rooms?: InputMaybe<ReadonlyArray<Scalars['uuid']['input']>>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly valid_until?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Minecraft_Announcement_Max_Fields = {
  readonly __typename?: 'minecraft_announcement_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly rooms?: Maybe<ReadonlyArray<Scalars['uuid']['output']>>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly valid_until?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Minecraft_Announcement_Min_Fields = {
  readonly __typename?: 'minecraft_announcement_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly rooms?: Maybe<ReadonlyArray<Scalars['uuid']['output']>>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly valid_until?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "minecraft_announcement" */
export type Minecraft_Announcement_Mutation_Response = {
  readonly __typename?: 'minecraft_announcement_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Minecraft_Announcement>;
};

/** on_conflict condition type for table "minecraft_announcement" */
export type Minecraft_Announcement_On_Conflict = {
  readonly constraint: Minecraft_Announcement_Constraint;
  readonly update_columns?: ReadonlyArray<Minecraft_Announcement_Update_Column>;
  readonly where?: InputMaybe<Minecraft_Announcement_Bool_Exp>;
};

/** Ordering options when selecting data from "minecraft_announcement". */
export type Minecraft_Announcement_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly data?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly rooms?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly valid_until?: InputMaybe<Order_By>;
};

/** primary key columns input for table: minecraft_announcement */
export type Minecraft_Announcement_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Minecraft_Announcement_Prepend_Input = {
  readonly data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "minecraft_announcement" */
export enum Minecraft_Announcement_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Rooms = 'rooms',
  /** column name */
  Type = 'type',
  /** column name */
  ValidUntil = 'valid_until'
}

/** input type for updating data in table "minecraft_announcement" */
export type Minecraft_Announcement_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly data?: InputMaybe<Scalars['jsonb']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly rooms?: InputMaybe<ReadonlyArray<Scalars['uuid']['input']>>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly valid_until?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "minecraft_announcement" */
export type Minecraft_Announcement_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Minecraft_Announcement_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Minecraft_Announcement_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly data?: InputMaybe<Scalars['jsonb']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly rooms?: InputMaybe<ReadonlyArray<Scalars['uuid']['input']>>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly valid_until?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "minecraft_announcement" */
export enum Minecraft_Announcement_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Rooms = 'rooms',
  /** column name */
  Type = 'type',
  /** column name */
  ValidUntil = 'valid_until'
}

export type Minecraft_Announcement_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Minecraft_Announcement_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Minecraft_Announcement_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Minecraft_Announcement_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Minecraft_Announcement_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Minecraft_Announcement_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Minecraft_Announcement_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Minecraft_Announcement_Bool_Exp;
};

/** Table that essentially manages the "presence" of any given minecraft server. */
export type Minecraft_Servers = {
  readonly __typename?: 'minecraft_servers';
  readonly club_id: Scalars['uuid']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly last_seen: Scalars['timestamptz']['output'];
  readonly name: Scalars['String']['output'];
  readonly platform: Scalars['String']['output'];
  readonly plugin_version: Scalars['String']['output'];
  readonly status: Scalars['String']['output'];
};

/** aggregated selection of "minecraft_servers" */
export type Minecraft_Servers_Aggregate = {
  readonly __typename?: 'minecraft_servers_aggregate';
  readonly aggregate?: Maybe<Minecraft_Servers_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Minecraft_Servers>;
};

/** aggregate fields of "minecraft_servers" */
export type Minecraft_Servers_Aggregate_Fields = {
  readonly __typename?: 'minecraft_servers_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Minecraft_Servers_Max_Fields>;
  readonly min?: Maybe<Minecraft_Servers_Min_Fields>;
};


/** aggregate fields of "minecraft_servers" */
export type Minecraft_Servers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Minecraft_Servers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "minecraft_servers". All fields are combined with a logical 'AND'. */
export type Minecraft_Servers_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Minecraft_Servers_Bool_Exp>>;
  readonly _not?: InputMaybe<Minecraft_Servers_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Minecraft_Servers_Bool_Exp>>;
  readonly club_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly last_seen?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly platform?: InputMaybe<String_Comparison_Exp>;
  readonly plugin_version?: InputMaybe<String_Comparison_Exp>;
  readonly status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "minecraft_servers" */
export enum Minecraft_Servers_Constraint {
  /** unique or primary key constraint on columns "id" */
  MinecraftServersPkey = 'minecraft_servers_pkey'
}

/** input type for inserting data into table "minecraft_servers" */
export type Minecraft_Servers_Insert_Input = {
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly last_seen?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly platform?: InputMaybe<Scalars['String']['input']>;
  readonly plugin_version?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Minecraft_Servers_Max_Fields = {
  readonly __typename?: 'minecraft_servers_max_fields';
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly last_seen?: Maybe<Scalars['timestamptz']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly platform?: Maybe<Scalars['String']['output']>;
  readonly plugin_version?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Minecraft_Servers_Min_Fields = {
  readonly __typename?: 'minecraft_servers_min_fields';
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly last_seen?: Maybe<Scalars['timestamptz']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly platform?: Maybe<Scalars['String']['output']>;
  readonly plugin_version?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "minecraft_servers" */
export type Minecraft_Servers_Mutation_Response = {
  readonly __typename?: 'minecraft_servers_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Minecraft_Servers>;
};

/** on_conflict condition type for table "minecraft_servers" */
export type Minecraft_Servers_On_Conflict = {
  readonly constraint: Minecraft_Servers_Constraint;
  readonly update_columns?: ReadonlyArray<Minecraft_Servers_Update_Column>;
  readonly where?: InputMaybe<Minecraft_Servers_Bool_Exp>;
};

/** Ordering options when selecting data from "minecraft_servers". */
export type Minecraft_Servers_Order_By = {
  readonly club_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly last_seen?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly platform?: InputMaybe<Order_By>;
  readonly plugin_version?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: minecraft_servers */
export type Minecraft_Servers_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "minecraft_servers" */
export enum Minecraft_Servers_Select_Column {
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  Name = 'name',
  /** column name */
  Platform = 'platform',
  /** column name */
  PluginVersion = 'plugin_version',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "minecraft_servers" */
export type Minecraft_Servers_Set_Input = {
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly last_seen?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly platform?: InputMaybe<Scalars['String']['input']>;
  readonly plugin_version?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "minecraft_servers" */
export type Minecraft_Servers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Minecraft_Servers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Minecraft_Servers_Stream_Cursor_Value_Input = {
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly last_seen?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly platform?: InputMaybe<Scalars['String']['input']>;
  readonly plugin_version?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "minecraft_servers" */
export enum Minecraft_Servers_Update_Column {
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  Name = 'name',
  /** column name */
  Platform = 'platform',
  /** column name */
  PluginVersion = 'plugin_version',
  /** column name */
  Status = 'status'
}

export type Minecraft_Servers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Minecraft_Servers_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Minecraft_Servers_Bool_Exp;
};

/** columns and relationships of "minecraft_skins" */
export type Minecraft_Skins = {
  readonly __typename?: 'minecraft_skins';
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['String']['output'];
  readonly signature: Scalars['String']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
  readonly value: Scalars['String']['output'];
};

/** aggregated selection of "minecraft_skins" */
export type Minecraft_Skins_Aggregate = {
  readonly __typename?: 'minecraft_skins_aggregate';
  readonly aggregate?: Maybe<Minecraft_Skins_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Minecraft_Skins>;
};

/** aggregate fields of "minecraft_skins" */
export type Minecraft_Skins_Aggregate_Fields = {
  readonly __typename?: 'minecraft_skins_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Minecraft_Skins_Max_Fields>;
  readonly min?: Maybe<Minecraft_Skins_Min_Fields>;
};


/** aggregate fields of "minecraft_skins" */
export type Minecraft_Skins_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Minecraft_Skins_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "minecraft_skins". All fields are combined with a logical 'AND'. */
export type Minecraft_Skins_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Minecraft_Skins_Bool_Exp>>;
  readonly _not?: InputMaybe<Minecraft_Skins_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Minecraft_Skins_Bool_Exp>>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly signature?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
  readonly value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "minecraft_skins" */
export enum Minecraft_Skins_Constraint {
  /** unique or primary key constraint on columns "id" */
  MinecraftSkinsPkey = 'minecraft_skins_pkey',
  /** unique or primary key constraint on columns "user_id" */
  MinecraftSkinsUserIdKey = 'minecraft_skins_user_id_key'
}

/** input type for inserting data into table "minecraft_skins" */
export type Minecraft_Skins_Insert_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly signature?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Minecraft_Skins_Max_Fields = {
  readonly __typename?: 'minecraft_skins_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly signature?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
  readonly value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Minecraft_Skins_Min_Fields = {
  readonly __typename?: 'minecraft_skins_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly signature?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
  readonly value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "minecraft_skins" */
export type Minecraft_Skins_Mutation_Response = {
  readonly __typename?: 'minecraft_skins_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Minecraft_Skins>;
};

/** input type for inserting object relation for remote table "minecraft_skins" */
export type Minecraft_Skins_Obj_Rel_Insert_Input = {
  readonly data: Minecraft_Skins_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Minecraft_Skins_On_Conflict>;
};

/** on_conflict condition type for table "minecraft_skins" */
export type Minecraft_Skins_On_Conflict = {
  readonly constraint: Minecraft_Skins_Constraint;
  readonly update_columns?: ReadonlyArray<Minecraft_Skins_Update_Column>;
  readonly where?: InputMaybe<Minecraft_Skins_Bool_Exp>;
};

/** Ordering options when selecting data from "minecraft_skins". */
export type Minecraft_Skins_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly signature?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: minecraft_skins */
export type Minecraft_Skins_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** select columns of table "minecraft_skins" */
export enum Minecraft_Skins_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Signature = 'signature',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "minecraft_skins" */
export type Minecraft_Skins_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly signature?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "minecraft_skins" */
export type Minecraft_Skins_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Minecraft_Skins_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Minecraft_Skins_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly signature?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "minecraft_skins" */
export enum Minecraft_Skins_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Signature = 'signature',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type Minecraft_Skins_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Minecraft_Skins_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Minecraft_Skins_Bool_Exp;
};

/** columns and relationships of "minecraft_tutorial" */
export type Minecraft_Tutorial = {
  readonly __typename?: 'minecraft_tutorial';
  readonly progress: Scalars['Int']['output'];
  readonly requirement_id: Scalars['uuid']['output'];
  readonly stage_id: Scalars['uuid']['output'];
  readonly tutorial_id: Scalars['uuid']['output'];
  readonly user_id: Scalars['String']['output'];
};

/** aggregated selection of "minecraft_tutorial" */
export type Minecraft_Tutorial_Aggregate = {
  readonly __typename?: 'minecraft_tutorial_aggregate';
  readonly aggregate?: Maybe<Minecraft_Tutorial_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Minecraft_Tutorial>;
};

/** aggregate fields of "minecraft_tutorial" */
export type Minecraft_Tutorial_Aggregate_Fields = {
  readonly __typename?: 'minecraft_tutorial_aggregate_fields';
  readonly avg?: Maybe<Minecraft_Tutorial_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Minecraft_Tutorial_Max_Fields>;
  readonly min?: Maybe<Minecraft_Tutorial_Min_Fields>;
  readonly stddev?: Maybe<Minecraft_Tutorial_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Minecraft_Tutorial_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Minecraft_Tutorial_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Minecraft_Tutorial_Sum_Fields>;
  readonly var_pop?: Maybe<Minecraft_Tutorial_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Minecraft_Tutorial_Var_Samp_Fields>;
  readonly variance?: Maybe<Minecraft_Tutorial_Variance_Fields>;
};


/** aggregate fields of "minecraft_tutorial" */
export type Minecraft_Tutorial_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Minecraft_Tutorial_Avg_Fields = {
  readonly __typename?: 'minecraft_tutorial_avg_fields';
  readonly progress?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "minecraft_tutorial". All fields are combined with a logical 'AND'. */
export type Minecraft_Tutorial_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Bool_Exp>>;
  readonly _not?: InputMaybe<Minecraft_Tutorial_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Bool_Exp>>;
  readonly progress?: InputMaybe<Int_Comparison_Exp>;
  readonly requirement_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly stage_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly tutorial_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "minecraft_tutorial" */
export enum Minecraft_Tutorial_Constraint {
  /** unique or primary key constraint on columns "user_id", "tutorial_id", "stage_id", "requirement_id" */
  MinecraftTutorialPkey = 'minecraft_tutorial_pkey'
}

/** input type for incrementing numeric columns in table "minecraft_tutorial" */
export type Minecraft_Tutorial_Inc_Input = {
  readonly progress?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "minecraft_tutorial" */
export type Minecraft_Tutorial_Insert_Input = {
  readonly progress?: InputMaybe<Scalars['Int']['input']>;
  readonly requirement_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly stage_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly tutorial_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Minecraft_Tutorial_Max_Fields = {
  readonly __typename?: 'minecraft_tutorial_max_fields';
  readonly progress?: Maybe<Scalars['Int']['output']>;
  readonly requirement_id?: Maybe<Scalars['uuid']['output']>;
  readonly stage_id?: Maybe<Scalars['uuid']['output']>;
  readonly tutorial_id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Minecraft_Tutorial_Min_Fields = {
  readonly __typename?: 'minecraft_tutorial_min_fields';
  readonly progress?: Maybe<Scalars['Int']['output']>;
  readonly requirement_id?: Maybe<Scalars['uuid']['output']>;
  readonly stage_id?: Maybe<Scalars['uuid']['output']>;
  readonly tutorial_id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "minecraft_tutorial" */
export type Minecraft_Tutorial_Mutation_Response = {
  readonly __typename?: 'minecraft_tutorial_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Minecraft_Tutorial>;
};

/** on_conflict condition type for table "minecraft_tutorial" */
export type Minecraft_Tutorial_On_Conflict = {
  readonly constraint: Minecraft_Tutorial_Constraint;
  readonly update_columns?: ReadonlyArray<Minecraft_Tutorial_Update_Column>;
  readonly where?: InputMaybe<Minecraft_Tutorial_Bool_Exp>;
};

/** Ordering options when selecting data from "minecraft_tutorial". */
export type Minecraft_Tutorial_Order_By = {
  readonly progress?: InputMaybe<Order_By>;
  readonly requirement_id?: InputMaybe<Order_By>;
  readonly stage_id?: InputMaybe<Order_By>;
  readonly tutorial_id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: minecraft_tutorial */
export type Minecraft_Tutorial_Pk_Columns_Input = {
  readonly requirement_id: Scalars['uuid']['input'];
  readonly stage_id: Scalars['uuid']['input'];
  readonly tutorial_id: Scalars['uuid']['input'];
  readonly user_id: Scalars['String']['input'];
};

/** select columns of table "minecraft_tutorial" */
export enum Minecraft_Tutorial_Select_Column {
  /** column name */
  Progress = 'progress',
  /** column name */
  RequirementId = 'requirement_id',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  TutorialId = 'tutorial_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "minecraft_tutorial" */
export type Minecraft_Tutorial_Set_Input = {
  readonly progress?: InputMaybe<Scalars['Int']['input']>;
  readonly requirement_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly stage_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly tutorial_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "minecraft_tutorial_status" */
export type Minecraft_Tutorial_Status = {
  readonly __typename?: 'minecraft_tutorial_status';
  readonly stage: Scalars['Int']['output'];
  readonly status: Scalars['String']['output'];
  readonly tutorial_id: Scalars['uuid']['output'];
  readonly user_id: Scalars['String']['output'];
};

/** aggregated selection of "minecraft_tutorial_status" */
export type Minecraft_Tutorial_Status_Aggregate = {
  readonly __typename?: 'minecraft_tutorial_status_aggregate';
  readonly aggregate?: Maybe<Minecraft_Tutorial_Status_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Minecraft_Tutorial_Status>;
};

/** aggregate fields of "minecraft_tutorial_status" */
export type Minecraft_Tutorial_Status_Aggregate_Fields = {
  readonly __typename?: 'minecraft_tutorial_status_aggregate_fields';
  readonly avg?: Maybe<Minecraft_Tutorial_Status_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Minecraft_Tutorial_Status_Max_Fields>;
  readonly min?: Maybe<Minecraft_Tutorial_Status_Min_Fields>;
  readonly stddev?: Maybe<Minecraft_Tutorial_Status_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Minecraft_Tutorial_Status_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Minecraft_Tutorial_Status_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Minecraft_Tutorial_Status_Sum_Fields>;
  readonly var_pop?: Maybe<Minecraft_Tutorial_Status_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Minecraft_Tutorial_Status_Var_Samp_Fields>;
  readonly variance?: Maybe<Minecraft_Tutorial_Status_Variance_Fields>;
};


/** aggregate fields of "minecraft_tutorial_status" */
export type Minecraft_Tutorial_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Minecraft_Tutorial_Status_Avg_Fields = {
  readonly __typename?: 'minecraft_tutorial_status_avg_fields';
  readonly stage?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "minecraft_tutorial_status". All fields are combined with a logical 'AND'. */
export type Minecraft_Tutorial_Status_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Status_Bool_Exp>>;
  readonly _not?: InputMaybe<Minecraft_Tutorial_Status_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Status_Bool_Exp>>;
  readonly stage?: InputMaybe<Int_Comparison_Exp>;
  readonly status?: InputMaybe<String_Comparison_Exp>;
  readonly tutorial_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "minecraft_tutorial_status" */
export enum Minecraft_Tutorial_Status_Constraint {
  /** unique or primary key constraint on columns "user_id", "tutorial_id" */
  MinecraftTutorialStatusPkey = 'minecraft_tutorial_status_pkey'
}

/** input type for incrementing numeric columns in table "minecraft_tutorial_status" */
export type Minecraft_Tutorial_Status_Inc_Input = {
  readonly stage?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "minecraft_tutorial_status" */
export type Minecraft_Tutorial_Status_Insert_Input = {
  readonly stage?: InputMaybe<Scalars['Int']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly tutorial_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Minecraft_Tutorial_Status_Max_Fields = {
  readonly __typename?: 'minecraft_tutorial_status_max_fields';
  readonly stage?: Maybe<Scalars['Int']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly tutorial_id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Minecraft_Tutorial_Status_Min_Fields = {
  readonly __typename?: 'minecraft_tutorial_status_min_fields';
  readonly stage?: Maybe<Scalars['Int']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly tutorial_id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "minecraft_tutorial_status" */
export type Minecraft_Tutorial_Status_Mutation_Response = {
  readonly __typename?: 'minecraft_tutorial_status_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Minecraft_Tutorial_Status>;
};

/** on_conflict condition type for table "minecraft_tutorial_status" */
export type Minecraft_Tutorial_Status_On_Conflict = {
  readonly constraint: Minecraft_Tutorial_Status_Constraint;
  readonly update_columns?: ReadonlyArray<Minecraft_Tutorial_Status_Update_Column>;
  readonly where?: InputMaybe<Minecraft_Tutorial_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "minecraft_tutorial_status". */
export type Minecraft_Tutorial_Status_Order_By = {
  readonly stage?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly tutorial_id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: minecraft_tutorial_status */
export type Minecraft_Tutorial_Status_Pk_Columns_Input = {
  readonly tutorial_id: Scalars['uuid']['input'];
  readonly user_id: Scalars['String']['input'];
};

/** select columns of table "minecraft_tutorial_status" */
export enum Minecraft_Tutorial_Status_Select_Column {
  /** column name */
  Stage = 'stage',
  /** column name */
  Status = 'status',
  /** column name */
  TutorialId = 'tutorial_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "minecraft_tutorial_status" */
export type Minecraft_Tutorial_Status_Set_Input = {
  readonly stage?: InputMaybe<Scalars['Int']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly tutorial_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Minecraft_Tutorial_Status_Stddev_Fields = {
  readonly __typename?: 'minecraft_tutorial_status_stddev_fields';
  readonly stage?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Minecraft_Tutorial_Status_Stddev_Pop_Fields = {
  readonly __typename?: 'minecraft_tutorial_status_stddev_pop_fields';
  readonly stage?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Minecraft_Tutorial_Status_Stddev_Samp_Fields = {
  readonly __typename?: 'minecraft_tutorial_status_stddev_samp_fields';
  readonly stage?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "minecraft_tutorial_status" */
export type Minecraft_Tutorial_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Minecraft_Tutorial_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Minecraft_Tutorial_Status_Stream_Cursor_Value_Input = {
  readonly stage?: InputMaybe<Scalars['Int']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly tutorial_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Minecraft_Tutorial_Status_Sum_Fields = {
  readonly __typename?: 'minecraft_tutorial_status_sum_fields';
  readonly stage?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "minecraft_tutorial_status" */
export enum Minecraft_Tutorial_Status_Update_Column {
  /** column name */
  Stage = 'stage',
  /** column name */
  Status = 'status',
  /** column name */
  TutorialId = 'tutorial_id',
  /** column name */
  UserId = 'user_id'
}

export type Minecraft_Tutorial_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Minecraft_Tutorial_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Minecraft_Tutorial_Status_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Minecraft_Tutorial_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Minecraft_Tutorial_Status_Var_Pop_Fields = {
  readonly __typename?: 'minecraft_tutorial_status_var_pop_fields';
  readonly stage?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Minecraft_Tutorial_Status_Var_Samp_Fields = {
  readonly __typename?: 'minecraft_tutorial_status_var_samp_fields';
  readonly stage?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Minecraft_Tutorial_Status_Variance_Fields = {
  readonly __typename?: 'minecraft_tutorial_status_variance_fields';
  readonly stage?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev on columns */
export type Minecraft_Tutorial_Stddev_Fields = {
  readonly __typename?: 'minecraft_tutorial_stddev_fields';
  readonly progress?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Minecraft_Tutorial_Stddev_Pop_Fields = {
  readonly __typename?: 'minecraft_tutorial_stddev_pop_fields';
  readonly progress?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Minecraft_Tutorial_Stddev_Samp_Fields = {
  readonly __typename?: 'minecraft_tutorial_stddev_samp_fields';
  readonly progress?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "minecraft_tutorial" */
export type Minecraft_Tutorial_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Minecraft_Tutorial_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Minecraft_Tutorial_Stream_Cursor_Value_Input = {
  readonly progress?: InputMaybe<Scalars['Int']['input']>;
  readonly requirement_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly stage_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly tutorial_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Minecraft_Tutorial_Sum_Fields = {
  readonly __typename?: 'minecraft_tutorial_sum_fields';
  readonly progress?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "minecraft_tutorial" */
export enum Minecraft_Tutorial_Update_Column {
  /** column name */
  Progress = 'progress',
  /** column name */
  RequirementId = 'requirement_id',
  /** column name */
  StageId = 'stage_id',
  /** column name */
  TutorialId = 'tutorial_id',
  /** column name */
  UserId = 'user_id'
}

export type Minecraft_Tutorial_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Minecraft_Tutorial_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Minecraft_Tutorial_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Minecraft_Tutorial_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Minecraft_Tutorial_Var_Pop_Fields = {
  readonly __typename?: 'minecraft_tutorial_var_pop_fields';
  readonly progress?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Minecraft_Tutorial_Var_Samp_Fields = {
  readonly __typename?: 'minecraft_tutorial_var_samp_fields';
  readonly progress?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Minecraft_Tutorial_Variance_Fields = {
  readonly __typename?: 'minecraft_tutorial_variance_fields';
  readonly progress?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "minecraft_user" */
export type Minecraft_User = {
  readonly __typename?: 'minecraft_user';
  readonly account_type?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly mc_id?: Maybe<Scalars['String']['output']>;
  readonly mc_username?: Maybe<Scalars['String']['output']>;
  readonly metadata?: Maybe<Scalars['jsonb']['output']>;
  readonly online?: Maybe<Scalars['Boolean']['output']>;
  readonly platform_state?: Maybe<Scalars['jsonb']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly username?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "minecraft_user" */
export type Minecraft_UserMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "minecraft_user" */
export type Minecraft_UserPlatform_StateArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "minecraft_user" */
export type Minecraft_User_Aggregate = {
  readonly __typename?: 'minecraft_user_aggregate';
  readonly aggregate?: Maybe<Minecraft_User_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Minecraft_User>;
};

/** aggregate fields of "minecraft_user" */
export type Minecraft_User_Aggregate_Fields = {
  readonly __typename?: 'minecraft_user_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Minecraft_User_Max_Fields>;
  readonly min?: Maybe<Minecraft_User_Min_Fields>;
};


/** aggregate fields of "minecraft_user" */
export type Minecraft_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Minecraft_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "minecraft_user". All fields are combined with a logical 'AND'. */
export type Minecraft_User_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Minecraft_User_Bool_Exp>>;
  readonly _not?: InputMaybe<Minecraft_User_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Minecraft_User_Bool_Exp>>;
  readonly account_type?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly mc_id?: InputMaybe<String_Comparison_Exp>;
  readonly mc_username?: InputMaybe<String_Comparison_Exp>;
  readonly metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly online?: InputMaybe<Boolean_Comparison_Exp>;
  readonly platform_state?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly username?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "minecraft_user" */
export type Minecraft_User_Insert_Input = {
  readonly account_type?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly mc_id?: InputMaybe<Scalars['String']['input']>;
  readonly mc_username?: InputMaybe<Scalars['String']['input']>;
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
  readonly online?: InputMaybe<Scalars['Boolean']['input']>;
  readonly platform_state?: InputMaybe<Scalars['jsonb']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Minecraft_User_Max_Fields = {
  readonly __typename?: 'minecraft_user_max_fields';
  readonly account_type?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly mc_id?: Maybe<Scalars['String']['output']>;
  readonly mc_username?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly username?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Minecraft_User_Min_Fields = {
  readonly __typename?: 'minecraft_user_min_fields';
  readonly account_type?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly mc_id?: Maybe<Scalars['String']['output']>;
  readonly mc_username?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly username?: Maybe<Scalars['String']['output']>;
};

/** input type for inserting object relation for remote table "minecraft_user" */
export type Minecraft_User_Obj_Rel_Insert_Input = {
  readonly data: Minecraft_User_Insert_Input;
};

/** Ordering options when selecting data from "minecraft_user". */
export type Minecraft_User_Order_By = {
  readonly account_type?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly mc_id?: InputMaybe<Order_By>;
  readonly mc_username?: InputMaybe<Order_By>;
  readonly metadata?: InputMaybe<Order_By>;
  readonly online?: InputMaybe<Order_By>;
  readonly platform_state?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly username?: InputMaybe<Order_By>;
};

/** select columns of table "minecraft_user" */
export enum Minecraft_User_Select_Column {
  /** column name */
  AccountType = 'account_type',
  /** column name */
  Id = 'id',
  /** column name */
  McId = 'mc_id',
  /** column name */
  McUsername = 'mc_username',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Online = 'online',
  /** column name */
  PlatformState = 'platform_state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** Streaming cursor of the table "minecraft_user" */
export type Minecraft_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Minecraft_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Minecraft_User_Stream_Cursor_Value_Input = {
  readonly account_type?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly mc_id?: InputMaybe<Scalars['String']['input']>;
  readonly mc_username?: InputMaybe<Scalars['String']['input']>;
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
  readonly online?: InputMaybe<Scalars['Boolean']['input']>;
  readonly platform_state?: InputMaybe<Scalars['jsonb']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly username?: InputMaybe<Scalars['String']['input']>;
};

/** mutation root */
export type Mutation_Root = {
  readonly __typename?: 'mutation_root';
  readonly acceptTeamInvite?: Maybe<AcceptTeamInviteOutput>;
  readonly awardPrizes?: Maybe<UpdatedData>;
  readonly banChannelUser?: Maybe<BanChannelUserOutput>;
  readonly bookOnboarding?: Maybe<BookOnboardingOutput>;
  /** execute VOLATILE function "comments_delete" which returns "comments" */
  readonly comments_delete?: Maybe<Comments>;
  /** execute VOLATILE function "complete_clubs_members_onboarding_requirement" which returns "clubs_members" */
  readonly complete_clubs_members_onboarding_requirement: ReadonlyArray<Clubs_Members>;
  /** delete data from the table: "accounts" */
  readonly delete_accounts?: Maybe<Accounts_Mutation_Response>;
  /** delete single row from the table: "accounts" */
  readonly delete_accounts_by_pk?: Maybe<Accounts>;
  /** delete data from the table: "asa_user_quest_task" */
  readonly delete_asa_user_quest_task?: Maybe<Asa_User_Quest_Task_Mutation_Response>;
  /** delete single row from the table: "asa_user_quest_task" */
  readonly delete_asa_user_quest_task_by_pk?: Maybe<Asa_User_Quest_Task>;
  /** delete data from the table: "avatars" */
  readonly delete_avatars?: Maybe<Avatars_Mutation_Response>;
  /** delete single row from the table: "avatars" */
  readonly delete_avatars_by_pk?: Maybe<Avatars>;
  /** delete data from the table: "badges" */
  readonly delete_badges?: Maybe<Badges_Mutation_Response>;
  /** delete single row from the table: "badges" */
  readonly delete_badges_by_pk?: Maybe<Badges>;
  /** delete data from the table: "bookings" */
  readonly delete_bookings?: Maybe<Bookings_Mutation_Response>;
  /** delete single row from the table: "bookings" */
  readonly delete_bookings_by_pk?: Maybe<Bookings>;
  /** delete data from the table: "breakouts" */
  readonly delete_breakouts?: Maybe<Breakouts_Mutation_Response>;
  /** delete single row from the table: "breakouts" */
  readonly delete_breakouts_by_pk?: Maybe<Breakouts>;
  /** delete data from the table: "build_battles" */
  readonly delete_build_battles?: Maybe<Build_Battles_Mutation_Response>;
  /** delete single row from the table: "build_battles" */
  readonly delete_build_battles_by_pk?: Maybe<Build_Battles>;
  /** delete data from the table: "build_battles_projects" */
  readonly delete_build_battles_projects?: Maybe<Build_Battles_Projects_Mutation_Response>;
  /** delete single row from the table: "build_battles_projects" */
  readonly delete_build_battles_projects_by_pk?: Maybe<Build_Battles_Projects>;
  /** delete data from the table: "build_battles_themes" */
  readonly delete_build_battles_themes?: Maybe<Build_Battles_Themes_Mutation_Response>;
  /** delete single row from the table: "build_battles_themes" */
  readonly delete_build_battles_themes_by_pk?: Maybe<Build_Battles_Themes>;
  /** delete data from the table: "channels" */
  readonly delete_channels?: Maybe<Channels_Mutation_Response>;
  /** delete single row from the table: "channels" */
  readonly delete_channels_by_pk?: Maybe<Channels>;
  /** delete data from the table: "channels_events" */
  readonly delete_channels_events?: Maybe<Channels_Events_Mutation_Response>;
  /** delete single row from the table: "channels_events" */
  readonly delete_channels_events_by_pk?: Maybe<Channels_Events>;
  /** delete data from the table: "channels_users" */
  readonly delete_channels_users?: Maybe<Channels_Users_Mutation_Response>;
  /** delete single row from the table: "channels_users" */
  readonly delete_channels_users_by_pk?: Maybe<Channels_Users>;
  /** delete data from the table: "chats" */
  readonly delete_chats?: Maybe<Chats_Mutation_Response>;
  /** delete single row from the table: "chats" */
  readonly delete_chats_by_pk?: Maybe<Chats>;
  /** delete data from the table: "clubs" */
  readonly delete_clubs?: Maybe<Clubs_Mutation_Response>;
  /** delete single row from the table: "clubs" */
  readonly delete_clubs_by_pk?: Maybe<Clubs>;
  /** delete data from the table: "clubs_members" */
  readonly delete_clubs_members?: Maybe<Clubs_Members_Mutation_Response>;
  /** delete single row from the table: "clubs_members" */
  readonly delete_clubs_members_by_pk?: Maybe<Clubs_Members>;
  /** delete data from the table: "comments" */
  readonly delete_comments?: Maybe<Comments_Mutation_Response>;
  /** delete single row from the table: "comments" */
  readonly delete_comments_by_pk?: Maybe<Comments>;
  /** delete data from the table: "comments_votes" */
  readonly delete_comments_votes?: Maybe<Comments_Votes_Mutation_Response>;
  /** delete single row from the table: "comments_votes" */
  readonly delete_comments_votes_by_pk?: Maybe<Comments_Votes>;
  /** delete data from the table: "competitions" */
  readonly delete_competitions?: Maybe<Competitions_Mutation_Response>;
  /** delete single row from the table: "competitions" */
  readonly delete_competitions_by_pk?: Maybe<Competitions>;
  /** delete data from the table: "competitions_judges" */
  readonly delete_competitions_judges?: Maybe<Competitions_Judges_Mutation_Response>;
  /** delete single row from the table: "competitions_judges" */
  readonly delete_competitions_judges_by_pk?: Maybe<Competitions_Judges>;
  /** delete data from the table: "competitions_tags" */
  readonly delete_competitions_tags?: Maybe<Competitions_Tags_Mutation_Response>;
  /** delete single row from the table: "competitions_tags" */
  readonly delete_competitions_tags_by_pk?: Maybe<Competitions_Tags>;
  /** delete data from the table: "competitions_tutorials" */
  readonly delete_competitions_tutorials?: Maybe<Competitions_Tutorials_Mutation_Response>;
  /** delete single row from the table: "competitions_tutorials" */
  readonly delete_competitions_tutorials_by_pk?: Maybe<Competitions_Tutorials>;
  /** delete data from the table: "eban_comments" */
  readonly delete_eban_comments?: Maybe<Eban_Comments_Mutation_Response>;
  /** delete single row from the table: "eban_comments" */
  readonly delete_eban_comments_by_pk?: Maybe<Eban_Comments>;
  /** delete data from the table: "eban_types" */
  readonly delete_eban_types?: Maybe<Eban_Types_Mutation_Response>;
  /** delete single row from the table: "eban_types" */
  readonly delete_eban_types_by_pk?: Maybe<Eban_Types>;
  /** delete data from the table: "ebans" */
  readonly delete_ebans?: Maybe<Ebans_Mutation_Response>;
  /** delete single row from the table: "ebans" */
  readonly delete_ebans_by_pk?: Maybe<Ebans>;
  /** delete data from the table: "environment" */
  readonly delete_environment?: Maybe<Environment_Mutation_Response>;
  /** delete single row from the table: "environment" */
  readonly delete_environment_by_pk?: Maybe<Environment>;
  /** delete data from the table: "features" */
  readonly delete_features?: Maybe<Features_Mutation_Response>;
  /** delete single row from the table: "features" */
  readonly delete_features_by_pk?: Maybe<Features>;
  /** delete data from the table: "features_products" */
  readonly delete_features_products?: Maybe<Features_Products_Mutation_Response>;
  /** delete single row from the table: "features_products" */
  readonly delete_features_products_by_pk?: Maybe<Features_Products>;
  /** delete data from the table: "feedback" */
  readonly delete_feedback?: Maybe<Feedback_Mutation_Response>;
  /** delete single row from the table: "feedback" */
  readonly delete_feedback_by_pk?: Maybe<Feedback>;
  /** delete data from the table: "feeds" */
  readonly delete_feeds?: Maybe<Feeds_Mutation_Response>;
  /** delete single row from the table: "feeds" */
  readonly delete_feeds_by_pk?: Maybe<Feeds>;
  /** delete data from the table: "follows" */
  readonly delete_follows?: Maybe<Follows_Mutation_Response>;
  /** delete single row from the table: "follows" */
  readonly delete_follows_by_pk?: Maybe<Follows>;
  /** delete data from the table: "friends" */
  readonly delete_friends?: Maybe<Friends_Mutation_Response>;
  /** delete single row from the table: "friends" */
  readonly delete_friends_by_pk?: Maybe<Friends>;
  /** delete data from the table: "friendships" */
  readonly delete_friendships?: Maybe<Friendships_Mutation_Response>;
  /** delete single row from the table: "friendships" */
  readonly delete_friendships_by_pk?: Maybe<Friendships>;
  /** delete data from the table: "friendships_users" */
  readonly delete_friendships_users?: Maybe<Friendships_Users_Mutation_Response>;
  /** delete single row from the table: "friendships_users" */
  readonly delete_friendships_users_by_pk?: Maybe<Friendships_Users>;
  /** delete data from the table: "game_servers" */
  readonly delete_game_servers?: Maybe<Game_Servers_Mutation_Response>;
  /** delete single row from the table: "game_servers" */
  readonly delete_game_servers_by_pk?: Maybe<Game_Servers>;
  /** delete data from the table: "homes" */
  readonly delete_homes?: Maybe<Homes_Mutation_Response>;
  /** delete single row from the table: "homes" */
  readonly delete_homes_by_pk?: Maybe<Homes>;
  /** delete data from the table: "image_cache" */
  readonly delete_image_cache?: Maybe<Image_Cache_Mutation_Response>;
  /** delete single row from the table: "image_cache" */
  readonly delete_image_cache_by_pk?: Maybe<Image_Cache>;
  /** execute VOLATILE function "delete_inactive_breakout" which returns "breakouts" */
  readonly delete_inactive_breakout: ReadonlyArray<Breakouts>;
  /** delete data from the table: "infrastructure_operations" */
  readonly delete_infrastructure_operations?: Maybe<Infrastructure_Operations_Mutation_Response>;
  /** delete data from the table: "items" */
  readonly delete_items?: Maybe<Items_Mutation_Response>;
  /** delete single row from the table: "items" */
  readonly delete_items_by_pk?: Maybe<Items>;
  /** delete data from the table: "items_tags" */
  readonly delete_items_tags?: Maybe<Items_Tags_Mutation_Response>;
  /** delete single row from the table: "items_tags" */
  readonly delete_items_tags_by_pk?: Maybe<Items_Tags>;
  /** delete data from the table: "levels" */
  readonly delete_levels?: Maybe<Levels_Mutation_Response>;
  /** delete single row from the table: "levels" */
  readonly delete_levels_by_pk?: Maybe<Levels>;
  /** delete data from the table: "live_events" */
  readonly delete_live_events?: Maybe<Live_Events_Mutation_Response>;
  /** delete single row from the table: "live_events" */
  readonly delete_live_events_by_pk?: Maybe<Live_Events>;
  /** delete data from the table: "live_events_hosts" */
  readonly delete_live_events_hosts?: Maybe<Live_Events_Hosts_Mutation_Response>;
  /** delete single row from the table: "live_events_hosts" */
  readonly delete_live_events_hosts_by_pk?: Maybe<Live_Events_Hosts>;
  /** delete data from the table: "messages" */
  readonly delete_messages?: Maybe<Messages_Mutation_Response>;
  /** delete single row from the table: "messages" */
  readonly delete_messages_by_pk?: Maybe<Messages>;
  /** delete data from the table: "minecraft_announcement" */
  readonly delete_minecraft_announcement?: Maybe<Minecraft_Announcement_Mutation_Response>;
  /** delete single row from the table: "minecraft_announcement" */
  readonly delete_minecraft_announcement_by_pk?: Maybe<Minecraft_Announcement>;
  /** delete data from the table: "minecraft_servers" */
  readonly delete_minecraft_servers?: Maybe<Minecraft_Servers_Mutation_Response>;
  /** delete single row from the table: "minecraft_servers" */
  readonly delete_minecraft_servers_by_pk?: Maybe<Minecraft_Servers>;
  /** delete data from the table: "minecraft_skins" */
  readonly delete_minecraft_skins?: Maybe<Minecraft_Skins_Mutation_Response>;
  /** delete single row from the table: "minecraft_skins" */
  readonly delete_minecraft_skins_by_pk?: Maybe<Minecraft_Skins>;
  /** delete data from the table: "minecraft_tutorial" */
  readonly delete_minecraft_tutorial?: Maybe<Minecraft_Tutorial_Mutation_Response>;
  /** delete single row from the table: "minecraft_tutorial" */
  readonly delete_minecraft_tutorial_by_pk?: Maybe<Minecraft_Tutorial>;
  /** delete data from the table: "minecraft_tutorial_status" */
  readonly delete_minecraft_tutorial_status?: Maybe<Minecraft_Tutorial_Status_Mutation_Response>;
  /** delete single row from the table: "minecraft_tutorial_status" */
  readonly delete_minecraft_tutorial_status_by_pk?: Maybe<Minecraft_Tutorial_Status>;
  /** delete data from the table: "notifications" */
  readonly delete_notifications?: Maybe<Notifications_Mutation_Response>;
  /** delete single row from the table: "notifications" */
  readonly delete_notifications_by_pk?: Maybe<Notifications>;
  /** delete data from the table: "orders" */
  readonly delete_orders?: Maybe<Orders_Mutation_Response>;
  /** delete single row from the table: "orders" */
  readonly delete_orders_by_pk?: Maybe<Orders>;
  /** delete data from the table: "otps" */
  readonly delete_otps?: Maybe<Otps_Mutation_Response>;
  /** delete single row from the table: "otps" */
  readonly delete_otps_by_pk?: Maybe<Otps>;
  /** delete data from the table: "pages" */
  readonly delete_pages?: Maybe<Pages_Mutation_Response>;
  /** delete single row from the table: "pages" */
  readonly delete_pages_by_pk?: Maybe<Pages>;
  /** delete data from the table: "player_worlds" */
  readonly delete_player_worlds?: Maybe<Player_Worlds_Mutation_Response>;
  /** delete single row from the table: "player_worlds" */
  readonly delete_player_worlds_by_pk?: Maybe<Player_Worlds>;
  /** delete data from the table: "posts" */
  readonly delete_posts?: Maybe<Posts_Mutation_Response>;
  /** delete single row from the table: "posts" */
  readonly delete_posts_by_pk?: Maybe<Posts>;
  /** delete data from the table: "projects" */
  readonly delete_projects?: Maybe<Projects_Mutation_Response>;
  /** delete single row from the table: "projects" */
  readonly delete_projects_by_pk?: Maybe<Projects>;
  /** delete data from the table: "projects_collaborators" */
  readonly delete_projects_collaborators?: Maybe<Projects_Collaborators_Mutation_Response>;
  /** delete single row from the table: "projects_collaborators" */
  readonly delete_projects_collaborators_by_pk?: Maybe<Projects_Collaborators>;
  /** delete data from the table: "projects_editables" */
  readonly delete_projects_editables?: Maybe<Projects_Editables_Mutation_Response>;
  /** delete data from the table: "projects_leaderboards" */
  readonly delete_projects_leaderboards?: Maybe<Projects_Leaderboards_Mutation_Response>;
  /** delete single row from the table: "projects_leaderboards" */
  readonly delete_projects_leaderboards_by_pk?: Maybe<Projects_Leaderboards>;
  /** delete data from the table: "projects_publications" */
  readonly delete_projects_publications?: Maybe<Projects_Publications_Mutation_Response>;
  /** delete single row from the table: "projects_publications" */
  readonly delete_projects_publications_by_pk?: Maybe<Projects_Publications>;
  /** delete data from the table: "projects_publications_votes" */
  readonly delete_projects_publications_votes?: Maybe<Projects_Publications_Votes_Mutation_Response>;
  /** delete single row from the table: "projects_publications_votes" */
  readonly delete_projects_publications_votes_by_pk?: Maybe<Projects_Publications_Votes>;
  /** delete data from the table: "projects_templates" */
  readonly delete_projects_templates?: Maybe<Projects_Templates_Mutation_Response>;
  /** delete single row from the table: "projects_templates" */
  readonly delete_projects_templates_by_pk?: Maybe<Projects_Templates>;
  /** delete data from the table: "prospective_users" */
  readonly delete_prospective_users?: Maybe<Prospective_Users_Mutation_Response>;
  /** delete single row from the table: "prospective_users" */
  readonly delete_prospective_users_by_pk?: Maybe<Prospective_Users>;
  /** delete data from the table: "prospective_users_purchases" */
  readonly delete_prospective_users_purchases?: Maybe<Prospective_Users_Purchases_Mutation_Response>;
  /** delete single row from the table: "prospective_users_purchases" */
  readonly delete_prospective_users_purchases_by_pk?: Maybe<Prospective_Users_Purchases>;
  /** delete data from the table: "quests" */
  readonly delete_quests?: Maybe<Quests_Mutation_Response>;
  /** delete single row from the table: "quests" */
  readonly delete_quests_by_pk?: Maybe<Quests>;
  /** delete data from the table: "queue_user_info" */
  readonly delete_queue_user_info?: Maybe<Queue_User_Info_Mutation_Response>;
  /** delete single row from the table: "queue_user_info" */
  readonly delete_queue_user_info_by_pk?: Maybe<Queue_User_Info>;
  /** delete data from the table: "queues" */
  readonly delete_queues?: Maybe<Queues_Mutation_Response>;
  /** delete single row from the table: "queues" */
  readonly delete_queues_by_pk?: Maybe<Queues>;
  /** delete data from the table: "render_jobs" */
  readonly delete_render_jobs?: Maybe<Render_Jobs_Mutation_Response>;
  /** delete single row from the table: "render_jobs" */
  readonly delete_render_jobs_by_pk?: Maybe<Render_Jobs>;
  /** delete data from the table: "reports" */
  readonly delete_reports?: Maybe<Reports_Mutation_Response>;
  /** delete single row from the table: "reports" */
  readonly delete_reports_by_pk?: Maybe<Reports>;
  /** delete data from the table: "rooms" */
  readonly delete_rooms?: Maybe<Rooms_Mutation_Response>;
  /** delete single row from the table: "rooms" */
  readonly delete_rooms_by_pk?: Maybe<Rooms>;
  /** delete data from the table: "schedules" */
  readonly delete_schedules?: Maybe<Schedules_Mutation_Response>;
  /** delete single row from the table: "schedules" */
  readonly delete_schedules_by_pk?: Maybe<Schedules>;
  /** delete data from the table: "shared_cached_queries" */
  readonly delete_shared_cached_queries?: Maybe<Shared_Cached_Queries_Mutation_Response>;
  /** delete single row from the table: "shared_cached_queries" */
  readonly delete_shared_cached_queries_by_pk?: Maybe<Shared_Cached_Queries>;
  /** delete data from the table: "short_urls" */
  readonly delete_short_urls?: Maybe<Short_Urls_Mutation_Response>;
  /** delete single row from the table: "short_urls" */
  readonly delete_short_urls_by_pk?: Maybe<Short_Urls>;
  /** delete data from the table: "subscriptions" */
  readonly delete_subscriptions?: Maybe<Subscriptions_Mutation_Response>;
  /** delete single row from the table: "subscriptions" */
  readonly delete_subscriptions_by_pk?: Maybe<Subscriptions>;
  /** delete data from the table: "tags" */
  readonly delete_tags?: Maybe<Tags_Mutation_Response>;
  /** delete data from the table: "tags_associations" */
  readonly delete_tags_associations?: Maybe<Tags_Associations_Mutation_Response>;
  /** delete single row from the table: "tags_associations" */
  readonly delete_tags_associations_by_pk?: Maybe<Tags_Associations>;
  /** delete single row from the table: "tags" */
  readonly delete_tags_by_pk?: Maybe<Tags>;
  /** delete data from the table: "themes" */
  readonly delete_themes?: Maybe<Themes_Mutation_Response>;
  /** delete single row from the table: "themes" */
  readonly delete_themes_by_pk?: Maybe<Themes>;
  /** delete data from the table: "tools" */
  readonly delete_tools?: Maybe<Tools_Mutation_Response>;
  /** delete single row from the table: "tools" */
  readonly delete_tools_by_pk?: Maybe<Tools>;
  /** delete data from the table: "topics" */
  readonly delete_topics?: Maybe<Topics_Mutation_Response>;
  /** delete single row from the table: "topics" */
  readonly delete_topics_by_pk?: Maybe<Topics>;
  /** delete data from the table: "topics_tools" */
  readonly delete_topics_tools?: Maybe<Topics_Tools_Mutation_Response>;
  /** delete single row from the table: "topics_tools" */
  readonly delete_topics_tools_by_pk?: Maybe<Topics_Tools>;
  /** delete data from the table: "transactions" */
  readonly delete_transactions?: Maybe<Transactions_Mutation_Response>;
  /** delete single row from the table: "transactions" */
  readonly delete_transactions_by_pk?: Maybe<Transactions>;
  /** delete data from the table: "tutorials" */
  readonly delete_tutorials?: Maybe<Tutorials_Mutation_Response>;
  /** delete single row from the table: "tutorials" */
  readonly delete_tutorials_by_pk?: Maybe<Tutorials>;
  /** delete data from the table: "tutorials_tags" */
  readonly delete_tutorials_tags?: Maybe<Tutorials_Tags_Mutation_Response>;
  /** delete single row from the table: "tutorials_tags" */
  readonly delete_tutorials_tags_by_pk?: Maybe<Tutorials_Tags>;
  /** delete data from the table: "user_collected_avatars" */
  readonly delete_user_collected_avatars?: Maybe<User_Collected_Avatars_Mutation_Response>;
  /** delete single row from the table: "user_collected_avatars" */
  readonly delete_user_collected_avatars_by_pk?: Maybe<User_Collected_Avatars>;
  /** delete data from the table: "user_invites" */
  readonly delete_user_invites?: Maybe<User_Invites_Mutation_Response>;
  /** delete single row from the table: "user_invites" */
  readonly delete_user_invites_by_pk?: Maybe<User_Invites>;
  /** delete data from the table: "user_presence" */
  readonly delete_user_presence?: Maybe<User_Presence_Mutation_Response>;
  /** delete single row from the table: "user_presence" */
  readonly delete_user_presence_by_pk?: Maybe<User_Presence>;
  /** delete data from the table: "users" */
  readonly delete_users?: Maybe<Users_Mutation_Response>;
  /** delete data from the table: "users_actions" */
  readonly delete_users_actions?: Maybe<Users_Actions_Mutation_Response>;
  /** delete single row from the table: "users_actions" */
  readonly delete_users_actions_by_pk?: Maybe<Users_Actions>;
  /** delete data from the table: "users_badges" */
  readonly delete_users_badges?: Maybe<Users_Badges_Mutation_Response>;
  /** delete single row from the table: "users_badges" */
  readonly delete_users_badges_by_pk?: Maybe<Users_Badges>;
  /** delete single row from the table: "users" */
  readonly delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "users_cached_queries" */
  readonly delete_users_cached_queries?: Maybe<Users_Cached_Queries_Mutation_Response>;
  /** delete single row from the table: "users_cached_queries" */
  readonly delete_users_cached_queries_by_pk?: Maybe<Users_Cached_Queries>;
  /** delete data from the table: "users_features" */
  readonly delete_users_features?: Maybe<Users_Features_Mutation_Response>;
  /** delete single row from the table: "users_features" */
  readonly delete_users_features_by_pk?: Maybe<Users_Features>;
  /** delete data from the table: "users_levels" */
  readonly delete_users_levels?: Maybe<Users_Levels_Mutation_Response>;
  /** delete single row from the table: "users_levels" */
  readonly delete_users_levels_by_pk?: Maybe<Users_Levels>;
  /** delete data from the table: "users_notifications" */
  readonly delete_users_notifications?: Maybe<Users_Notifications_Mutation_Response>;
  /** delete single row from the table: "users_notifications" */
  readonly delete_users_notifications_by_pk?: Maybe<Users_Notifications>;
  /** delete data from the table: "users_presence" */
  readonly delete_users_presence?: Maybe<Users_Presence_Mutation_Response>;
  /** delete data from the table: "users_progression" */
  readonly delete_users_progression?: Maybe<Users_Progression_Mutation_Response>;
  /** delete single row from the table: "users_progression" */
  readonly delete_users_progression_by_pk?: Maybe<Users_Progression>;
  /** delete data from the table: "users_quests" */
  readonly delete_users_quests?: Maybe<Users_Quests_Mutation_Response>;
  /** delete single row from the table: "users_quests" */
  readonly delete_users_quests_by_pk?: Maybe<Users_Quests>;
  /** delete data from the table: "users_stale_web_presence" */
  readonly delete_users_stale_web_presence?: Maybe<Users_Stale_Web_Presence_Mutation_Response>;
  /** delete data from the table: "users_stats" */
  readonly delete_users_stats?: Maybe<Users_Stats_Mutation_Response>;
  /** delete single row from the table: "users_stats" */
  readonly delete_users_stats_by_pk?: Maybe<Users_Stats>;
  /** delete data from the table: "votes" */
  readonly delete_votes?: Maybe<Votes_Mutation_Response>;
  /** delete single row from the table: "votes" */
  readonly delete_votes_by_pk?: Maybe<Votes>;
  /** execute VOLATILE function "feeds_delete" which returns "feeds" */
  readonly feeds_delete?: Maybe<Feeds>;
  readonly generateCallAccessToken?: Maybe<GenerateCallAccessTokenOutput>;
  /** insert data into the table: "accounts" */
  readonly insert_accounts?: Maybe<Accounts_Mutation_Response>;
  /** insert a single row into the table: "accounts" */
  readonly insert_accounts_one?: Maybe<Accounts>;
  /** insert data into the table: "asa_user_quest_task" */
  readonly insert_asa_user_quest_task?: Maybe<Asa_User_Quest_Task_Mutation_Response>;
  /** insert a single row into the table: "asa_user_quest_task" */
  readonly insert_asa_user_quest_task_one?: Maybe<Asa_User_Quest_Task>;
  /** insert data into the table: "avatars" */
  readonly insert_avatars?: Maybe<Avatars_Mutation_Response>;
  /** insert a single row into the table: "avatars" */
  readonly insert_avatars_one?: Maybe<Avatars>;
  /** insert data into the table: "badges" */
  readonly insert_badges?: Maybe<Badges_Mutation_Response>;
  /** insert a single row into the table: "badges" */
  readonly insert_badges_one?: Maybe<Badges>;
  /** insert data into the table: "bookings" */
  readonly insert_bookings?: Maybe<Bookings_Mutation_Response>;
  /** insert a single row into the table: "bookings" */
  readonly insert_bookings_one?: Maybe<Bookings>;
  /** insert data into the table: "breakouts" */
  readonly insert_breakouts?: Maybe<Breakouts_Mutation_Response>;
  /** insert a single row into the table: "breakouts" */
  readonly insert_breakouts_one?: Maybe<Breakouts>;
  /** insert data into the table: "build_battles" */
  readonly insert_build_battles?: Maybe<Build_Battles_Mutation_Response>;
  /** insert a single row into the table: "build_battles" */
  readonly insert_build_battles_one?: Maybe<Build_Battles>;
  /** insert data into the table: "build_battles_projects" */
  readonly insert_build_battles_projects?: Maybe<Build_Battles_Projects_Mutation_Response>;
  /** insert a single row into the table: "build_battles_projects" */
  readonly insert_build_battles_projects_one?: Maybe<Build_Battles_Projects>;
  /** insert data into the table: "build_battles_themes" */
  readonly insert_build_battles_themes?: Maybe<Build_Battles_Themes_Mutation_Response>;
  /** insert a single row into the table: "build_battles_themes" */
  readonly insert_build_battles_themes_one?: Maybe<Build_Battles_Themes>;
  /** execute VOLATILE function "insert_channel_user_by_association" which returns "channels_users" */
  readonly insert_channel_user_by_association: ReadonlyArray<Channels_Users>;
  /** insert data into the table: "channels" */
  readonly insert_channels?: Maybe<Channels_Mutation_Response>;
  /** insert data into the table: "channels_events" */
  readonly insert_channels_events?: Maybe<Channels_Events_Mutation_Response>;
  /** insert a single row into the table: "channels_events" */
  readonly insert_channels_events_one?: Maybe<Channels_Events>;
  /** insert a single row into the table: "channels" */
  readonly insert_channels_one?: Maybe<Channels>;
  /** insert data into the table: "channels_users" */
  readonly insert_channels_users?: Maybe<Channels_Users_Mutation_Response>;
  /** insert a single row into the table: "channels_users" */
  readonly insert_channels_users_one?: Maybe<Channels_Users>;
  /** insert data into the table: "chats" */
  readonly insert_chats?: Maybe<Chats_Mutation_Response>;
  /** insert a single row into the table: "chats" */
  readonly insert_chats_one?: Maybe<Chats>;
  /** insert data into the table: "clubs" */
  readonly insert_clubs?: Maybe<Clubs_Mutation_Response>;
  /** insert data into the table: "clubs_members" */
  readonly insert_clubs_members?: Maybe<Clubs_Members_Mutation_Response>;
  /** insert a single row into the table: "clubs_members" */
  readonly insert_clubs_members_one?: Maybe<Clubs_Members>;
  /** insert a single row into the table: "clubs" */
  readonly insert_clubs_one?: Maybe<Clubs>;
  /** insert data into the table: "comments" */
  readonly insert_comments?: Maybe<Comments_Mutation_Response>;
  /** insert a single row into the table: "comments" */
  readonly insert_comments_one?: Maybe<Comments>;
  /** insert data into the table: "comments_votes" */
  readonly insert_comments_votes?: Maybe<Comments_Votes_Mutation_Response>;
  /** insert a single row into the table: "comments_votes" */
  readonly insert_comments_votes_one?: Maybe<Comments_Votes>;
  /** insert data into the table: "competitions" */
  readonly insert_competitions?: Maybe<Competitions_Mutation_Response>;
  /** insert data into the table: "competitions_judges" */
  readonly insert_competitions_judges?: Maybe<Competitions_Judges_Mutation_Response>;
  /** insert a single row into the table: "competitions_judges" */
  readonly insert_competitions_judges_one?: Maybe<Competitions_Judges>;
  /** insert a single row into the table: "competitions" */
  readonly insert_competitions_one?: Maybe<Competitions>;
  /** insert data into the table: "competitions_tags" */
  readonly insert_competitions_tags?: Maybe<Competitions_Tags_Mutation_Response>;
  /** insert a single row into the table: "competitions_tags" */
  readonly insert_competitions_tags_one?: Maybe<Competitions_Tags>;
  /** insert data into the table: "competitions_tutorials" */
  readonly insert_competitions_tutorials?: Maybe<Competitions_Tutorials_Mutation_Response>;
  /** insert a single row into the table: "competitions_tutorials" */
  readonly insert_competitions_tutorials_one?: Maybe<Competitions_Tutorials>;
  /** insert data into the table: "eban_comments" */
  readonly insert_eban_comments?: Maybe<Eban_Comments_Mutation_Response>;
  /** insert a single row into the table: "eban_comments" */
  readonly insert_eban_comments_one?: Maybe<Eban_Comments>;
  /** insert data into the table: "eban_types" */
  readonly insert_eban_types?: Maybe<Eban_Types_Mutation_Response>;
  /** insert a single row into the table: "eban_types" */
  readonly insert_eban_types_one?: Maybe<Eban_Types>;
  /** insert data into the table: "ebans" */
  readonly insert_ebans?: Maybe<Ebans_Mutation_Response>;
  /** insert a single row into the table: "ebans" */
  readonly insert_ebans_one?: Maybe<Ebans>;
  /** insert data into the table: "environment" */
  readonly insert_environment?: Maybe<Environment_Mutation_Response>;
  /** insert a single row into the table: "environment" */
  readonly insert_environment_one?: Maybe<Environment>;
  /** insert data into the table: "features" */
  readonly insert_features?: Maybe<Features_Mutation_Response>;
  /** insert a single row into the table: "features" */
  readonly insert_features_one?: Maybe<Features>;
  /** insert data into the table: "features_products" */
  readonly insert_features_products?: Maybe<Features_Products_Mutation_Response>;
  /** insert a single row into the table: "features_products" */
  readonly insert_features_products_one?: Maybe<Features_Products>;
  /** insert data into the table: "feedback" */
  readonly insert_feedback?: Maybe<Feedback_Mutation_Response>;
  /** insert a single row into the table: "feedback" */
  readonly insert_feedback_one?: Maybe<Feedback>;
  /** insert data into the table: "feeds" */
  readonly insert_feeds?: Maybe<Feeds_Mutation_Response>;
  /** insert a single row into the table: "feeds" */
  readonly insert_feeds_one?: Maybe<Feeds>;
  /** insert data into the table: "follows" */
  readonly insert_follows?: Maybe<Follows_Mutation_Response>;
  /** insert a single row into the table: "follows" */
  readonly insert_follows_one?: Maybe<Follows>;
  /** insert data into the table: "friends" */
  readonly insert_friends?: Maybe<Friends_Mutation_Response>;
  /** insert a single row into the table: "friends" */
  readonly insert_friends_one?: Maybe<Friends>;
  /** insert data into the table: "friendships" */
  readonly insert_friendships?: Maybe<Friendships_Mutation_Response>;
  /** insert a single row into the table: "friendships" */
  readonly insert_friendships_one?: Maybe<Friendships>;
  /** insert data into the table: "friendships_users" */
  readonly insert_friendships_users?: Maybe<Friendships_Users_Mutation_Response>;
  /** insert a single row into the table: "friendships_users" */
  readonly insert_friendships_users_one?: Maybe<Friendships_Users>;
  /** insert data into the table: "game_servers" */
  readonly insert_game_servers?: Maybe<Game_Servers_Mutation_Response>;
  /** insert a single row into the table: "game_servers" */
  readonly insert_game_servers_one?: Maybe<Game_Servers>;
  /** insert data into the table: "homes" */
  readonly insert_homes?: Maybe<Homes_Mutation_Response>;
  /** insert a single row into the table: "homes" */
  readonly insert_homes_one?: Maybe<Homes>;
  /** insert data into the table: "image_cache" */
  readonly insert_image_cache?: Maybe<Image_Cache_Mutation_Response>;
  /** insert a single row into the table: "image_cache" */
  readonly insert_image_cache_one?: Maybe<Image_Cache>;
  /** insert data into the table: "infrastructure_operations" */
  readonly insert_infrastructure_operations?: Maybe<Infrastructure_Operations_Mutation_Response>;
  /** insert a single row into the table: "infrastructure_operations" */
  readonly insert_infrastructure_operations_one?: Maybe<Infrastructure_Operations>;
  /** execute VOLATILE function "insert_initial_minecraft_user_presence" which returns "user_presence" */
  readonly insert_initial_minecraft_user_presence: ReadonlyArray<User_Presence>;
  /** insert data into the table: "items" */
  readonly insert_items?: Maybe<Items_Mutation_Response>;
  /** insert a single row into the table: "items" */
  readonly insert_items_one?: Maybe<Items>;
  /** insert data into the table: "items_tags" */
  readonly insert_items_tags?: Maybe<Items_Tags_Mutation_Response>;
  /** insert a single row into the table: "items_tags" */
  readonly insert_items_tags_one?: Maybe<Items_Tags>;
  /** insert data into the table: "levels" */
  readonly insert_levels?: Maybe<Levels_Mutation_Response>;
  /** insert a single row into the table: "levels" */
  readonly insert_levels_one?: Maybe<Levels>;
  /** insert data into the table: "live_events" */
  readonly insert_live_events?: Maybe<Live_Events_Mutation_Response>;
  /** insert data into the table: "live_events_hosts" */
  readonly insert_live_events_hosts?: Maybe<Live_Events_Hosts_Mutation_Response>;
  /** insert a single row into the table: "live_events_hosts" */
  readonly insert_live_events_hosts_one?: Maybe<Live_Events_Hosts>;
  /** insert a single row into the table: "live_events" */
  readonly insert_live_events_one?: Maybe<Live_Events>;
  /** insert data into the table: "messages" */
  readonly insert_messages?: Maybe<Messages_Mutation_Response>;
  /** insert a single row into the table: "messages" */
  readonly insert_messages_one?: Maybe<Messages>;
  /** insert data into the table: "minecraft_announcement" */
  readonly insert_minecraft_announcement?: Maybe<Minecraft_Announcement_Mutation_Response>;
  /** insert a single row into the table: "minecraft_announcement" */
  readonly insert_minecraft_announcement_one?: Maybe<Minecraft_Announcement>;
  /** insert data into the table: "minecraft_servers" */
  readonly insert_minecraft_servers?: Maybe<Minecraft_Servers_Mutation_Response>;
  /** insert a single row into the table: "minecraft_servers" */
  readonly insert_minecraft_servers_one?: Maybe<Minecraft_Servers>;
  /** insert data into the table: "minecraft_skins" */
  readonly insert_minecraft_skins?: Maybe<Minecraft_Skins_Mutation_Response>;
  /** insert a single row into the table: "minecraft_skins" */
  readonly insert_minecraft_skins_one?: Maybe<Minecraft_Skins>;
  /** insert data into the table: "minecraft_tutorial" */
  readonly insert_minecraft_tutorial?: Maybe<Minecraft_Tutorial_Mutation_Response>;
  /** insert a single row into the table: "minecraft_tutorial" */
  readonly insert_minecraft_tutorial_one?: Maybe<Minecraft_Tutorial>;
  /** insert data into the table: "minecraft_tutorial_status" */
  readonly insert_minecraft_tutorial_status?: Maybe<Minecraft_Tutorial_Status_Mutation_Response>;
  /** insert a single row into the table: "minecraft_tutorial_status" */
  readonly insert_minecraft_tutorial_status_one?: Maybe<Minecraft_Tutorial_Status>;
  /** insert data into the table: "notifications" */
  readonly insert_notifications?: Maybe<Notifications_Mutation_Response>;
  /** insert a single row into the table: "notifications" */
  readonly insert_notifications_one?: Maybe<Notifications>;
  /** insert data into the table: "orders" */
  readonly insert_orders?: Maybe<Orders_Mutation_Response>;
  /** insert a single row into the table: "orders" */
  readonly insert_orders_one?: Maybe<Orders>;
  /** insert data into the table: "otps" */
  readonly insert_otps?: Maybe<Otps_Mutation_Response>;
  /** insert a single row into the table: "otps" */
  readonly insert_otps_one?: Maybe<Otps>;
  /** insert data into the table: "pages" */
  readonly insert_pages?: Maybe<Pages_Mutation_Response>;
  /** insert a single row into the table: "pages" */
  readonly insert_pages_one?: Maybe<Pages>;
  /** insert data into the table: "player_worlds" */
  readonly insert_player_worlds?: Maybe<Player_Worlds_Mutation_Response>;
  /** insert a single row into the table: "player_worlds" */
  readonly insert_player_worlds_one?: Maybe<Player_Worlds>;
  /** insert data into the table: "posts" */
  readonly insert_posts?: Maybe<Posts_Mutation_Response>;
  /** insert a single row into the table: "posts" */
  readonly insert_posts_one?: Maybe<Posts>;
  /** insert data into the table: "projects" */
  readonly insert_projects?: Maybe<Projects_Mutation_Response>;
  /** insert data into the table: "projects_collaborators" */
  readonly insert_projects_collaborators?: Maybe<Projects_Collaborators_Mutation_Response>;
  /** insert a single row into the table: "projects_collaborators" */
  readonly insert_projects_collaborators_one?: Maybe<Projects_Collaborators>;
  /** insert data into the table: "projects_editables" */
  readonly insert_projects_editables?: Maybe<Projects_Editables_Mutation_Response>;
  /** insert a single row into the table: "projects_editables" */
  readonly insert_projects_editables_one?: Maybe<Projects_Editables>;
  /** insert data into the table: "projects_leaderboards" */
  readonly insert_projects_leaderboards?: Maybe<Projects_Leaderboards_Mutation_Response>;
  /** insert a single row into the table: "projects_leaderboards" */
  readonly insert_projects_leaderboards_one?: Maybe<Projects_Leaderboards>;
  /** insert a single row into the table: "projects" */
  readonly insert_projects_one?: Maybe<Projects>;
  /** insert data into the table: "projects_publications" */
  readonly insert_projects_publications?: Maybe<Projects_Publications_Mutation_Response>;
  /** insert a single row into the table: "projects_publications" */
  readonly insert_projects_publications_one?: Maybe<Projects_Publications>;
  /** insert data into the table: "projects_publications_votes" */
  readonly insert_projects_publications_votes?: Maybe<Projects_Publications_Votes_Mutation_Response>;
  /** insert a single row into the table: "projects_publications_votes" */
  readonly insert_projects_publications_votes_one?: Maybe<Projects_Publications_Votes>;
  /** insert data into the table: "projects_templates" */
  readonly insert_projects_templates?: Maybe<Projects_Templates_Mutation_Response>;
  /** insert a single row into the table: "projects_templates" */
  readonly insert_projects_templates_one?: Maybe<Projects_Templates>;
  /** insert data into the table: "prospective_users" */
  readonly insert_prospective_users?: Maybe<Prospective_Users_Mutation_Response>;
  /** insert a single row into the table: "prospective_users" */
  readonly insert_prospective_users_one?: Maybe<Prospective_Users>;
  /** insert data into the table: "prospective_users_purchases" */
  readonly insert_prospective_users_purchases?: Maybe<Prospective_Users_Purchases_Mutation_Response>;
  /** insert a single row into the table: "prospective_users_purchases" */
  readonly insert_prospective_users_purchases_one?: Maybe<Prospective_Users_Purchases>;
  /** insert data into the table: "quests" */
  readonly insert_quests?: Maybe<Quests_Mutation_Response>;
  /** insert a single row into the table: "quests" */
  readonly insert_quests_one?: Maybe<Quests>;
  /** insert data into the table: "queue_user_info" */
  readonly insert_queue_user_info?: Maybe<Queue_User_Info_Mutation_Response>;
  /** insert a single row into the table: "queue_user_info" */
  readonly insert_queue_user_info_one?: Maybe<Queue_User_Info>;
  /** insert data into the table: "queues" */
  readonly insert_queues?: Maybe<Queues_Mutation_Response>;
  /** insert a single row into the table: "queues" */
  readonly insert_queues_one?: Maybe<Queues>;
  /** insert data into the table: "render_jobs" */
  readonly insert_render_jobs?: Maybe<Render_Jobs_Mutation_Response>;
  /** insert a single row into the table: "render_jobs" */
  readonly insert_render_jobs_one?: Maybe<Render_Jobs>;
  /** insert data into the table: "reports" */
  readonly insert_reports?: Maybe<Reports_Mutation_Response>;
  /** insert a single row into the table: "reports" */
  readonly insert_reports_one?: Maybe<Reports>;
  /** insert data into the table: "rooms" */
  readonly insert_rooms?: Maybe<Rooms_Mutation_Response>;
  /** insert a single row into the table: "rooms" */
  readonly insert_rooms_one?: Maybe<Rooms>;
  /** insert data into the table: "schedules" */
  readonly insert_schedules?: Maybe<Schedules_Mutation_Response>;
  /** insert a single row into the table: "schedules" */
  readonly insert_schedules_one?: Maybe<Schedules>;
  /** insert data into the table: "shared_cached_queries" */
  readonly insert_shared_cached_queries?: Maybe<Shared_Cached_Queries_Mutation_Response>;
  /** insert a single row into the table: "shared_cached_queries" */
  readonly insert_shared_cached_queries_one?: Maybe<Shared_Cached_Queries>;
  /** insert data into the table: "short_urls" */
  readonly insert_short_urls?: Maybe<Short_Urls_Mutation_Response>;
  /** insert a single row into the table: "short_urls" */
  readonly insert_short_urls_one?: Maybe<Short_Urls>;
  /** insert data into the table: "subscriptions" */
  readonly insert_subscriptions?: Maybe<Subscriptions_Mutation_Response>;
  /** insert a single row into the table: "subscriptions" */
  readonly insert_subscriptions_one?: Maybe<Subscriptions>;
  /** insert data into the table: "tags" */
  readonly insert_tags?: Maybe<Tags_Mutation_Response>;
  /** insert data into the table: "tags_associations" */
  readonly insert_tags_associations?: Maybe<Tags_Associations_Mutation_Response>;
  /** insert a single row into the table: "tags_associations" */
  readonly insert_tags_associations_one?: Maybe<Tags_Associations>;
  /** insert a single row into the table: "tags" */
  readonly insert_tags_one?: Maybe<Tags>;
  /** insert data into the table: "themes" */
  readonly insert_themes?: Maybe<Themes_Mutation_Response>;
  /** insert a single row into the table: "themes" */
  readonly insert_themes_one?: Maybe<Themes>;
  /** insert data into the table: "tools" */
  readonly insert_tools?: Maybe<Tools_Mutation_Response>;
  /** insert a single row into the table: "tools" */
  readonly insert_tools_one?: Maybe<Tools>;
  /** insert data into the table: "topics" */
  readonly insert_topics?: Maybe<Topics_Mutation_Response>;
  /** insert a single row into the table: "topics" */
  readonly insert_topics_one?: Maybe<Topics>;
  /** insert data into the table: "topics_tools" */
  readonly insert_topics_tools?: Maybe<Topics_Tools_Mutation_Response>;
  /** insert a single row into the table: "topics_tools" */
  readonly insert_topics_tools_one?: Maybe<Topics_Tools>;
  /** insert data into the table: "transactions" */
  readonly insert_transactions?: Maybe<Transactions_Mutation_Response>;
  /** insert a single row into the table: "transactions" */
  readonly insert_transactions_one?: Maybe<Transactions>;
  /** insert data into the table: "tutorials" */
  readonly insert_tutorials?: Maybe<Tutorials_Mutation_Response>;
  /** insert a single row into the table: "tutorials" */
  readonly insert_tutorials_one?: Maybe<Tutorials>;
  /** insert data into the table: "tutorials_tags" */
  readonly insert_tutorials_tags?: Maybe<Tutorials_Tags_Mutation_Response>;
  /** insert a single row into the table: "tutorials_tags" */
  readonly insert_tutorials_tags_one?: Maybe<Tutorials_Tags>;
  /** insert data into the table: "user_collected_avatars" */
  readonly insert_user_collected_avatars?: Maybe<User_Collected_Avatars_Mutation_Response>;
  /** insert a single row into the table: "user_collected_avatars" */
  readonly insert_user_collected_avatars_one?: Maybe<User_Collected_Avatars>;
  /** insert data into the table: "user_invites" */
  readonly insert_user_invites?: Maybe<User_Invites_Mutation_Response>;
  /** insert a single row into the table: "user_invites" */
  readonly insert_user_invites_one?: Maybe<User_Invites>;
  /** insert data into the table: "user_presence" */
  readonly insert_user_presence?: Maybe<User_Presence_Mutation_Response>;
  /** insert a single row into the table: "user_presence" */
  readonly insert_user_presence_one?: Maybe<User_Presence>;
  /** insert data into the table: "users" */
  readonly insert_users?: Maybe<Users_Mutation_Response>;
  /** insert data into the table: "users_actions" */
  readonly insert_users_actions?: Maybe<Users_Actions_Mutation_Response>;
  /** insert a single row into the table: "users_actions" */
  readonly insert_users_actions_one?: Maybe<Users_Actions>;
  /** insert data into the table: "users_badges" */
  readonly insert_users_badges?: Maybe<Users_Badges_Mutation_Response>;
  /** insert a single row into the table: "users_badges" */
  readonly insert_users_badges_one?: Maybe<Users_Badges>;
  /** insert data into the table: "users_cached_queries" */
  readonly insert_users_cached_queries?: Maybe<Users_Cached_Queries_Mutation_Response>;
  /** insert a single row into the table: "users_cached_queries" */
  readonly insert_users_cached_queries_one?: Maybe<Users_Cached_Queries>;
  /** insert data into the table: "users_features" */
  readonly insert_users_features?: Maybe<Users_Features_Mutation_Response>;
  /** insert a single row into the table: "users_features" */
  readonly insert_users_features_one?: Maybe<Users_Features>;
  /** insert data into the table: "users_levels" */
  readonly insert_users_levels?: Maybe<Users_Levels_Mutation_Response>;
  /** insert a single row into the table: "users_levels" */
  readonly insert_users_levels_one?: Maybe<Users_Levels>;
  /** insert data into the table: "users_notifications" */
  readonly insert_users_notifications?: Maybe<Users_Notifications_Mutation_Response>;
  /** insert a single row into the table: "users_notifications" */
  readonly insert_users_notifications_one?: Maybe<Users_Notifications>;
  /** insert a single row into the table: "users" */
  readonly insert_users_one?: Maybe<Users>;
  /** insert data into the table: "users_presence" */
  readonly insert_users_presence?: Maybe<Users_Presence_Mutation_Response>;
  /** insert a single row into the table: "users_presence" */
  readonly insert_users_presence_one?: Maybe<Users_Presence>;
  /** insert data into the table: "users_progression" */
  readonly insert_users_progression?: Maybe<Users_Progression_Mutation_Response>;
  /** insert a single row into the table: "users_progression" */
  readonly insert_users_progression_one?: Maybe<Users_Progression>;
  /** insert data into the table: "users_quests" */
  readonly insert_users_quests?: Maybe<Users_Quests_Mutation_Response>;
  /** insert a single row into the table: "users_quests" */
  readonly insert_users_quests_one?: Maybe<Users_Quests>;
  /** insert data into the table: "users_stale_web_presence" */
  readonly insert_users_stale_web_presence?: Maybe<Users_Stale_Web_Presence_Mutation_Response>;
  /** insert a single row into the table: "users_stale_web_presence" */
  readonly insert_users_stale_web_presence_one?: Maybe<Users_Stale_Web_Presence>;
  /** insert data into the table: "users_stats" */
  readonly insert_users_stats?: Maybe<Users_Stats_Mutation_Response>;
  /** insert a single row into the table: "users_stats" */
  readonly insert_users_stats_one?: Maybe<Users_Stats>;
  /** insert data into the table: "votes" */
  readonly insert_votes?: Maybe<Votes_Mutation_Response>;
  /** insert a single row into the table: "votes" */
  readonly insert_votes_one?: Maybe<Votes>;
  readonly issueTransactions?: Maybe<IssueTransactionsOutput>;
  /** Joins a channel for an activity */
  readonly joinActivity: JoinActivityOutput;
  readonly joinBreakout: JoinBreakoutOutput;
  readonly joinCompetition?: Maybe<JoinCompetitionOutput>;
  readonly joinRoom: JoinRoomOutput;
  readonly leaveCompetition?: Maybe<LeaveCompetitionOutput>;
  /** execute VOLATILE function "posts_delete" which returns "posts" */
  readonly posts_delete?: Maybe<Posts>;
  readonly publishToMinecraft?: Maybe<PublishToMinecraftOutput>;
  /** execute VOLATILE function "remove_club_requirement" which returns "clubs_members" */
  readonly remove_club_requirement: ReadonlyArray<Clubs_Members>;
  /** execute VOLATILE function "remove_progression_claimed_levels" which returns "users_progression" */
  readonly remove_progression_claimed_levels: ReadonlyArray<Users_Progression>;
  /** sendFriendRequest */
  readonly sendFriendRequest?: Maybe<SendFriendRequestOutput>;
  readonly sendPasswordReset?: Maybe<SendPasswordResetOutput>;
  readonly submitChatMessage?: Maybe<SubmitChatMessageOutput>;
  /** execute VOLATILE function "submit_chat_message" which returns "chats" */
  readonly submit_chat_message: ReadonlyArray<Chats>;
  /** Toggles matchmaking */
  readonly toggleMatchmaking: MatchmakingOutput;
  /** toggleRoomHostId */
  readonly toggleRoomHostId: ToggleRoomHostIdOutput;
  /** execute VOLATILE function "toggle_host_user_id" which returns "rooms" */
  readonly toggle_host_user_id: ReadonlyArray<Rooms>;
  readonly unlinkDiscord?: Maybe<UnlinkDiscordOutput>;
  readonly unlinkXbox?: Maybe<UnlinkXboxOutput>;
  readonly updateChannelMuteEnabled?: Maybe<UpdateChannelMuteEnabledOutput>;
  readonly updateChannelUserPermissions?: Maybe<UpdateChannelUserPermissionsOutput>;
  /** updateChannelsUserForceMuted */
  readonly updateChannelsUserForceMuted?: Maybe<UpdateChannelsUserForceMutedOutput>;
  readonly updateHandRaised?: Maybe<UpdateHandRaisedOutput>;
  readonly updatePassword?: Maybe<UpdatePasswordOutput>;
  /** Adds a user's club preferences to their meta */
  readonly updateUserClubPreferences?: Maybe<UpdateUserClubPreferencesOutput>;
  /** update data of the table: "accounts" */
  readonly update_accounts?: Maybe<Accounts_Mutation_Response>;
  /** update single row of the table: "accounts" */
  readonly update_accounts_by_pk?: Maybe<Accounts>;
  /** update multiples rows of table: "accounts" */
  readonly update_accounts_many?: Maybe<ReadonlyArray<Maybe<Accounts_Mutation_Response>>>;
  /** update data of the table: "asa_user_quest_task" */
  readonly update_asa_user_quest_task?: Maybe<Asa_User_Quest_Task_Mutation_Response>;
  /** update single row of the table: "asa_user_quest_task" */
  readonly update_asa_user_quest_task_by_pk?: Maybe<Asa_User_Quest_Task>;
  /** update multiples rows of table: "asa_user_quest_task" */
  readonly update_asa_user_quest_task_many?: Maybe<ReadonlyArray<Maybe<Asa_User_Quest_Task_Mutation_Response>>>;
  /** update data of the table: "avatars" */
  readonly update_avatars?: Maybe<Avatars_Mutation_Response>;
  /** update single row of the table: "avatars" */
  readonly update_avatars_by_pk?: Maybe<Avatars>;
  /** update multiples rows of table: "avatars" */
  readonly update_avatars_many?: Maybe<ReadonlyArray<Maybe<Avatars_Mutation_Response>>>;
  /** update data of the table: "badges" */
  readonly update_badges?: Maybe<Badges_Mutation_Response>;
  /** update single row of the table: "badges" */
  readonly update_badges_by_pk?: Maybe<Badges>;
  /** update multiples rows of table: "badges" */
  readonly update_badges_many?: Maybe<ReadonlyArray<Maybe<Badges_Mutation_Response>>>;
  /** update data of the table: "bookings" */
  readonly update_bookings?: Maybe<Bookings_Mutation_Response>;
  /** update single row of the table: "bookings" */
  readonly update_bookings_by_pk?: Maybe<Bookings>;
  /** update multiples rows of table: "bookings" */
  readonly update_bookings_many?: Maybe<ReadonlyArray<Maybe<Bookings_Mutation_Response>>>;
  /** update data of the table: "breakouts" */
  readonly update_breakouts?: Maybe<Breakouts_Mutation_Response>;
  /** update single row of the table: "breakouts" */
  readonly update_breakouts_by_pk?: Maybe<Breakouts>;
  /** update multiples rows of table: "breakouts" */
  readonly update_breakouts_many?: Maybe<ReadonlyArray<Maybe<Breakouts_Mutation_Response>>>;
  /** update data of the table: "build_battles" */
  readonly update_build_battles?: Maybe<Build_Battles_Mutation_Response>;
  /** update single row of the table: "build_battles" */
  readonly update_build_battles_by_pk?: Maybe<Build_Battles>;
  /** update multiples rows of table: "build_battles" */
  readonly update_build_battles_many?: Maybe<ReadonlyArray<Maybe<Build_Battles_Mutation_Response>>>;
  /** update data of the table: "build_battles_projects" */
  readonly update_build_battles_projects?: Maybe<Build_Battles_Projects_Mutation_Response>;
  /** update single row of the table: "build_battles_projects" */
  readonly update_build_battles_projects_by_pk?: Maybe<Build_Battles_Projects>;
  /** update multiples rows of table: "build_battles_projects" */
  readonly update_build_battles_projects_many?: Maybe<ReadonlyArray<Maybe<Build_Battles_Projects_Mutation_Response>>>;
  /** update data of the table: "build_battles_themes" */
  readonly update_build_battles_themes?: Maybe<Build_Battles_Themes_Mutation_Response>;
  /** update single row of the table: "build_battles_themes" */
  readonly update_build_battles_themes_by_pk?: Maybe<Build_Battles_Themes>;
  /** update multiples rows of table: "build_battles_themes" */
  readonly update_build_battles_themes_many?: Maybe<ReadonlyArray<Maybe<Build_Battles_Themes_Mutation_Response>>>;
  /** execute VOLATILE function "update_channel_mute_enabled" which returns "channels" */
  readonly update_channel_mute_enabled: ReadonlyArray<Channels>;
  /** update data of the table: "channels" */
  readonly update_channels?: Maybe<Channels_Mutation_Response>;
  /** update single row of the table: "channels" */
  readonly update_channels_by_pk?: Maybe<Channels>;
  /** update data of the table: "channels_events" */
  readonly update_channels_events?: Maybe<Channels_Events_Mutation_Response>;
  /** update single row of the table: "channels_events" */
  readonly update_channels_events_by_pk?: Maybe<Channels_Events>;
  /** update multiples rows of table: "channels_events" */
  readonly update_channels_events_many?: Maybe<ReadonlyArray<Maybe<Channels_Events_Mutation_Response>>>;
  /** update multiples rows of table: "channels" */
  readonly update_channels_many?: Maybe<ReadonlyArray<Maybe<Channels_Mutation_Response>>>;
  /** update data of the table: "channels_users" */
  readonly update_channels_users?: Maybe<Channels_Users_Mutation_Response>;
  /** update single row of the table: "channels_users" */
  readonly update_channels_users_by_pk?: Maybe<Channels_Users>;
  /** update multiples rows of table: "channels_users" */
  readonly update_channels_users_many?: Maybe<ReadonlyArray<Maybe<Channels_Users_Mutation_Response>>>;
  /** update data of the table: "chats" */
  readonly update_chats?: Maybe<Chats_Mutation_Response>;
  /** update single row of the table: "chats" */
  readonly update_chats_by_pk?: Maybe<Chats>;
  /** update multiples rows of table: "chats" */
  readonly update_chats_many?: Maybe<ReadonlyArray<Maybe<Chats_Mutation_Response>>>;
  /** execute VOLATILE function "update_club_schedule_lock_unlock_times" which returns "clubs" */
  readonly update_club_schedule_lock_unlock_times: ReadonlyArray<Clubs>;
  /** execute VOLATILE function "update_club_status" which returns "clubs" */
  readonly update_club_status: ReadonlyArray<Clubs>;
  /** update data of the table: "clubs" */
  readonly update_clubs?: Maybe<Clubs_Mutation_Response>;
  /** update single row of the table: "clubs" */
  readonly update_clubs_by_pk?: Maybe<Clubs>;
  /** update multiples rows of table: "clubs" */
  readonly update_clubs_many?: Maybe<ReadonlyArray<Maybe<Clubs_Mutation_Response>>>;
  /** update data of the table: "clubs_members" */
  readonly update_clubs_members?: Maybe<Clubs_Members_Mutation_Response>;
  /** update single row of the table: "clubs_members" */
  readonly update_clubs_members_by_pk?: Maybe<Clubs_Members>;
  /** update multiples rows of table: "clubs_members" */
  readonly update_clubs_members_many?: Maybe<ReadonlyArray<Maybe<Clubs_Members_Mutation_Response>>>;
  /** update data of the table: "comments" */
  readonly update_comments?: Maybe<Comments_Mutation_Response>;
  /** update single row of the table: "comments" */
  readonly update_comments_by_pk?: Maybe<Comments>;
  /** update multiples rows of table: "comments" */
  readonly update_comments_many?: Maybe<ReadonlyArray<Maybe<Comments_Mutation_Response>>>;
  /** update data of the table: "comments_votes" */
  readonly update_comments_votes?: Maybe<Comments_Votes_Mutation_Response>;
  /** update single row of the table: "comments_votes" */
  readonly update_comments_votes_by_pk?: Maybe<Comments_Votes>;
  /** update multiples rows of table: "comments_votes" */
  readonly update_comments_votes_many?: Maybe<ReadonlyArray<Maybe<Comments_Votes_Mutation_Response>>>;
  /** update data of the table: "competitions" */
  readonly update_competitions?: Maybe<Competitions_Mutation_Response>;
  /** update single row of the table: "competitions" */
  readonly update_competitions_by_pk?: Maybe<Competitions>;
  /** update data of the table: "competitions_judges" */
  readonly update_competitions_judges?: Maybe<Competitions_Judges_Mutation_Response>;
  /** update single row of the table: "competitions_judges" */
  readonly update_competitions_judges_by_pk?: Maybe<Competitions_Judges>;
  /** update multiples rows of table: "competitions_judges" */
  readonly update_competitions_judges_many?: Maybe<ReadonlyArray<Maybe<Competitions_Judges_Mutation_Response>>>;
  /** update multiples rows of table: "competitions" */
  readonly update_competitions_many?: Maybe<ReadonlyArray<Maybe<Competitions_Mutation_Response>>>;
  /** update data of the table: "competitions_tags" */
  readonly update_competitions_tags?: Maybe<Competitions_Tags_Mutation_Response>;
  /** update single row of the table: "competitions_tags" */
  readonly update_competitions_tags_by_pk?: Maybe<Competitions_Tags>;
  /** update multiples rows of table: "competitions_tags" */
  readonly update_competitions_tags_many?: Maybe<ReadonlyArray<Maybe<Competitions_Tags_Mutation_Response>>>;
  /** update data of the table: "competitions_tutorials" */
  readonly update_competitions_tutorials?: Maybe<Competitions_Tutorials_Mutation_Response>;
  /** update single row of the table: "competitions_tutorials" */
  readonly update_competitions_tutorials_by_pk?: Maybe<Competitions_Tutorials>;
  /** update multiples rows of table: "competitions_tutorials" */
  readonly update_competitions_tutorials_many?: Maybe<ReadonlyArray<Maybe<Competitions_Tutorials_Mutation_Response>>>;
  /** update data of the table: "eban_comments" */
  readonly update_eban_comments?: Maybe<Eban_Comments_Mutation_Response>;
  /** update single row of the table: "eban_comments" */
  readonly update_eban_comments_by_pk?: Maybe<Eban_Comments>;
  /** update multiples rows of table: "eban_comments" */
  readonly update_eban_comments_many?: Maybe<ReadonlyArray<Maybe<Eban_Comments_Mutation_Response>>>;
  /** update data of the table: "eban_types" */
  readonly update_eban_types?: Maybe<Eban_Types_Mutation_Response>;
  /** update single row of the table: "eban_types" */
  readonly update_eban_types_by_pk?: Maybe<Eban_Types>;
  /** update multiples rows of table: "eban_types" */
  readonly update_eban_types_many?: Maybe<ReadonlyArray<Maybe<Eban_Types_Mutation_Response>>>;
  /** update data of the table: "ebans" */
  readonly update_ebans?: Maybe<Ebans_Mutation_Response>;
  /** update single row of the table: "ebans" */
  readonly update_ebans_by_pk?: Maybe<Ebans>;
  /** update multiples rows of table: "ebans" */
  readonly update_ebans_many?: Maybe<ReadonlyArray<Maybe<Ebans_Mutation_Response>>>;
  /** update data of the table: "environment" */
  readonly update_environment?: Maybe<Environment_Mutation_Response>;
  /** update single row of the table: "environment" */
  readonly update_environment_by_pk?: Maybe<Environment>;
  /** update multiples rows of table: "environment" */
  readonly update_environment_many?: Maybe<ReadonlyArray<Maybe<Environment_Mutation_Response>>>;
  /** update data of the table: "features" */
  readonly update_features?: Maybe<Features_Mutation_Response>;
  /** update single row of the table: "features" */
  readonly update_features_by_pk?: Maybe<Features>;
  /** update multiples rows of table: "features" */
  readonly update_features_many?: Maybe<ReadonlyArray<Maybe<Features_Mutation_Response>>>;
  /** update data of the table: "features_products" */
  readonly update_features_products?: Maybe<Features_Products_Mutation_Response>;
  /** update single row of the table: "features_products" */
  readonly update_features_products_by_pk?: Maybe<Features_Products>;
  /** update multiples rows of table: "features_products" */
  readonly update_features_products_many?: Maybe<ReadonlyArray<Maybe<Features_Products_Mutation_Response>>>;
  /** update data of the table: "feedback" */
  readonly update_feedback?: Maybe<Feedback_Mutation_Response>;
  /** update single row of the table: "feedback" */
  readonly update_feedback_by_pk?: Maybe<Feedback>;
  /** update multiples rows of table: "feedback" */
  readonly update_feedback_many?: Maybe<ReadonlyArray<Maybe<Feedback_Mutation_Response>>>;
  /** update data of the table: "feeds" */
  readonly update_feeds?: Maybe<Feeds_Mutation_Response>;
  /** update single row of the table: "feeds" */
  readonly update_feeds_by_pk?: Maybe<Feeds>;
  /** update multiples rows of table: "feeds" */
  readonly update_feeds_many?: Maybe<ReadonlyArray<Maybe<Feeds_Mutation_Response>>>;
  /** update data of the table: "follows" */
  readonly update_follows?: Maybe<Follows_Mutation_Response>;
  /** update single row of the table: "follows" */
  readonly update_follows_by_pk?: Maybe<Follows>;
  /** update multiples rows of table: "follows" */
  readonly update_follows_many?: Maybe<ReadonlyArray<Maybe<Follows_Mutation_Response>>>;
  /** update data of the table: "friends" */
  readonly update_friends?: Maybe<Friends_Mutation_Response>;
  /** update single row of the table: "friends" */
  readonly update_friends_by_pk?: Maybe<Friends>;
  /** update multiples rows of table: "friends" */
  readonly update_friends_many?: Maybe<ReadonlyArray<Maybe<Friends_Mutation_Response>>>;
  /** update data of the table: "friendships" */
  readonly update_friendships?: Maybe<Friendships_Mutation_Response>;
  /** update single row of the table: "friendships" */
  readonly update_friendships_by_pk?: Maybe<Friendships>;
  /** update multiples rows of table: "friendships" */
  readonly update_friendships_many?: Maybe<ReadonlyArray<Maybe<Friendships_Mutation_Response>>>;
  /** update data of the table: "friendships_users" */
  readonly update_friendships_users?: Maybe<Friendships_Users_Mutation_Response>;
  /** update single row of the table: "friendships_users" */
  readonly update_friendships_users_by_pk?: Maybe<Friendships_Users>;
  /** update multiples rows of table: "friendships_users" */
  readonly update_friendships_users_many?: Maybe<ReadonlyArray<Maybe<Friendships_Users_Mutation_Response>>>;
  /** update data of the table: "game_servers" */
  readonly update_game_servers?: Maybe<Game_Servers_Mutation_Response>;
  /** update single row of the table: "game_servers" */
  readonly update_game_servers_by_pk?: Maybe<Game_Servers>;
  /** update multiples rows of table: "game_servers" */
  readonly update_game_servers_many?: Maybe<ReadonlyArray<Maybe<Game_Servers_Mutation_Response>>>;
  /** update data of the table: "homes" */
  readonly update_homes?: Maybe<Homes_Mutation_Response>;
  /** update single row of the table: "homes" */
  readonly update_homes_by_pk?: Maybe<Homes>;
  /** update multiples rows of table: "homes" */
  readonly update_homes_many?: Maybe<ReadonlyArray<Maybe<Homes_Mutation_Response>>>;
  /** update data of the table: "image_cache" */
  readonly update_image_cache?: Maybe<Image_Cache_Mutation_Response>;
  /** update single row of the table: "image_cache" */
  readonly update_image_cache_by_pk?: Maybe<Image_Cache>;
  /** update multiples rows of table: "image_cache" */
  readonly update_image_cache_many?: Maybe<ReadonlyArray<Maybe<Image_Cache_Mutation_Response>>>;
  /** update data of the table: "infrastructure_operations" */
  readonly update_infrastructure_operations?: Maybe<Infrastructure_Operations_Mutation_Response>;
  /** update multiples rows of table: "infrastructure_operations" */
  readonly update_infrastructure_operations_many?: Maybe<ReadonlyArray<Maybe<Infrastructure_Operations_Mutation_Response>>>;
  /** update data of the table: "items" */
  readonly update_items?: Maybe<Items_Mutation_Response>;
  /** update single row of the table: "items" */
  readonly update_items_by_pk?: Maybe<Items>;
  /** update multiples rows of table: "items" */
  readonly update_items_many?: Maybe<ReadonlyArray<Maybe<Items_Mutation_Response>>>;
  /** update data of the table: "items_tags" */
  readonly update_items_tags?: Maybe<Items_Tags_Mutation_Response>;
  /** update single row of the table: "items_tags" */
  readonly update_items_tags_by_pk?: Maybe<Items_Tags>;
  /** update multiples rows of table: "items_tags" */
  readonly update_items_tags_many?: Maybe<ReadonlyArray<Maybe<Items_Tags_Mutation_Response>>>;
  /** update data of the table: "levels" */
  readonly update_levels?: Maybe<Levels_Mutation_Response>;
  /** update single row of the table: "levels" */
  readonly update_levels_by_pk?: Maybe<Levels>;
  /** update multiples rows of table: "levels" */
  readonly update_levels_many?: Maybe<ReadonlyArray<Maybe<Levels_Mutation_Response>>>;
  /** update data of the table: "live_events" */
  readonly update_live_events?: Maybe<Live_Events_Mutation_Response>;
  /** update single row of the table: "live_events" */
  readonly update_live_events_by_pk?: Maybe<Live_Events>;
  /** update data of the table: "live_events_hosts" */
  readonly update_live_events_hosts?: Maybe<Live_Events_Hosts_Mutation_Response>;
  /** update single row of the table: "live_events_hosts" */
  readonly update_live_events_hosts_by_pk?: Maybe<Live_Events_Hosts>;
  /** update multiples rows of table: "live_events_hosts" */
  readonly update_live_events_hosts_many?: Maybe<ReadonlyArray<Maybe<Live_Events_Hosts_Mutation_Response>>>;
  /** update multiples rows of table: "live_events" */
  readonly update_live_events_many?: Maybe<ReadonlyArray<Maybe<Live_Events_Mutation_Response>>>;
  /** execute VOLATILE function "update_live_events_schedule" which returns "live_events" */
  readonly update_live_events_schedule: ReadonlyArray<Live_Events>;
  /** update data of the table: "messages" */
  readonly update_messages?: Maybe<Messages_Mutation_Response>;
  /** update single row of the table: "messages" */
  readonly update_messages_by_pk?: Maybe<Messages>;
  /** update multiples rows of table: "messages" */
  readonly update_messages_many?: Maybe<ReadonlyArray<Maybe<Messages_Mutation_Response>>>;
  /** update data of the table: "minecraft_announcement" */
  readonly update_minecraft_announcement?: Maybe<Minecraft_Announcement_Mutation_Response>;
  /** update single row of the table: "minecraft_announcement" */
  readonly update_minecraft_announcement_by_pk?: Maybe<Minecraft_Announcement>;
  /** update multiples rows of table: "minecraft_announcement" */
  readonly update_minecraft_announcement_many?: Maybe<ReadonlyArray<Maybe<Minecraft_Announcement_Mutation_Response>>>;
  /** update data of the table: "minecraft_servers" */
  readonly update_minecraft_servers?: Maybe<Minecraft_Servers_Mutation_Response>;
  /** update single row of the table: "minecraft_servers" */
  readonly update_minecraft_servers_by_pk?: Maybe<Minecraft_Servers>;
  /** update multiples rows of table: "minecraft_servers" */
  readonly update_minecraft_servers_many?: Maybe<ReadonlyArray<Maybe<Minecraft_Servers_Mutation_Response>>>;
  /** update data of the table: "minecraft_skins" */
  readonly update_minecraft_skins?: Maybe<Minecraft_Skins_Mutation_Response>;
  /** update single row of the table: "minecraft_skins" */
  readonly update_minecraft_skins_by_pk?: Maybe<Minecraft_Skins>;
  /** update multiples rows of table: "minecraft_skins" */
  readonly update_minecraft_skins_many?: Maybe<ReadonlyArray<Maybe<Minecraft_Skins_Mutation_Response>>>;
  /** update data of the table: "minecraft_tutorial" */
  readonly update_minecraft_tutorial?: Maybe<Minecraft_Tutorial_Mutation_Response>;
  /** update single row of the table: "minecraft_tutorial" */
  readonly update_minecraft_tutorial_by_pk?: Maybe<Minecraft_Tutorial>;
  /** update multiples rows of table: "minecraft_tutorial" */
  readonly update_minecraft_tutorial_many?: Maybe<ReadonlyArray<Maybe<Minecraft_Tutorial_Mutation_Response>>>;
  /** update data of the table: "minecraft_tutorial_status" */
  readonly update_minecraft_tutorial_status?: Maybe<Minecraft_Tutorial_Status_Mutation_Response>;
  /** update single row of the table: "minecraft_tutorial_status" */
  readonly update_minecraft_tutorial_status_by_pk?: Maybe<Minecraft_Tutorial_Status>;
  /** update multiples rows of table: "minecraft_tutorial_status" */
  readonly update_minecraft_tutorial_status_many?: Maybe<ReadonlyArray<Maybe<Minecraft_Tutorial_Status_Mutation_Response>>>;
  /** update data of the table: "notifications" */
  readonly update_notifications?: Maybe<Notifications_Mutation_Response>;
  /** update single row of the table: "notifications" */
  readonly update_notifications_by_pk?: Maybe<Notifications>;
  /** update multiples rows of table: "notifications" */
  readonly update_notifications_many?: Maybe<ReadonlyArray<Maybe<Notifications_Mutation_Response>>>;
  /** update data of the table: "orders" */
  readonly update_orders?: Maybe<Orders_Mutation_Response>;
  /** update single row of the table: "orders" */
  readonly update_orders_by_pk?: Maybe<Orders>;
  /** update multiples rows of table: "orders" */
  readonly update_orders_many?: Maybe<ReadonlyArray<Maybe<Orders_Mutation_Response>>>;
  /** update data of the table: "otps" */
  readonly update_otps?: Maybe<Otps_Mutation_Response>;
  /** update single row of the table: "otps" */
  readonly update_otps_by_pk?: Maybe<Otps>;
  /** update multiples rows of table: "otps" */
  readonly update_otps_many?: Maybe<ReadonlyArray<Maybe<Otps_Mutation_Response>>>;
  /** update data of the table: "pages" */
  readonly update_pages?: Maybe<Pages_Mutation_Response>;
  /** update single row of the table: "pages" */
  readonly update_pages_by_pk?: Maybe<Pages>;
  /** update multiples rows of table: "pages" */
  readonly update_pages_many?: Maybe<ReadonlyArray<Maybe<Pages_Mutation_Response>>>;
  /** update data of the table: "player_worlds" */
  readonly update_player_worlds?: Maybe<Player_Worlds_Mutation_Response>;
  /** update single row of the table: "player_worlds" */
  readonly update_player_worlds_by_pk?: Maybe<Player_Worlds>;
  /** update multiples rows of table: "player_worlds" */
  readonly update_player_worlds_many?: Maybe<ReadonlyArray<Maybe<Player_Worlds_Mutation_Response>>>;
  /** update data of the table: "posts" */
  readonly update_posts?: Maybe<Posts_Mutation_Response>;
  /** update single row of the table: "posts" */
  readonly update_posts_by_pk?: Maybe<Posts>;
  /** update multiples rows of table: "posts" */
  readonly update_posts_many?: Maybe<ReadonlyArray<Maybe<Posts_Mutation_Response>>>;
  /** update data of the table: "projects" */
  readonly update_projects?: Maybe<Projects_Mutation_Response>;
  /** update single row of the table: "projects" */
  readonly update_projects_by_pk?: Maybe<Projects>;
  /** update data of the table: "projects_collaborators" */
  readonly update_projects_collaborators?: Maybe<Projects_Collaborators_Mutation_Response>;
  /** update single row of the table: "projects_collaborators" */
  readonly update_projects_collaborators_by_pk?: Maybe<Projects_Collaborators>;
  /** update multiples rows of table: "projects_collaborators" */
  readonly update_projects_collaborators_many?: Maybe<ReadonlyArray<Maybe<Projects_Collaborators_Mutation_Response>>>;
  /** update data of the table: "projects_editables" */
  readonly update_projects_editables?: Maybe<Projects_Editables_Mutation_Response>;
  /** update multiples rows of table: "projects_editables" */
  readonly update_projects_editables_many?: Maybe<ReadonlyArray<Maybe<Projects_Editables_Mutation_Response>>>;
  /** update data of the table: "projects_leaderboards" */
  readonly update_projects_leaderboards?: Maybe<Projects_Leaderboards_Mutation_Response>;
  /** update single row of the table: "projects_leaderboards" */
  readonly update_projects_leaderboards_by_pk?: Maybe<Projects_Leaderboards>;
  /** update multiples rows of table: "projects_leaderboards" */
  readonly update_projects_leaderboards_many?: Maybe<ReadonlyArray<Maybe<Projects_Leaderboards_Mutation_Response>>>;
  /** update multiples rows of table: "projects" */
  readonly update_projects_many?: Maybe<ReadonlyArray<Maybe<Projects_Mutation_Response>>>;
  /** update data of the table: "projects_publications" */
  readonly update_projects_publications?: Maybe<Projects_Publications_Mutation_Response>;
  /** update single row of the table: "projects_publications" */
  readonly update_projects_publications_by_pk?: Maybe<Projects_Publications>;
  /** update multiples rows of table: "projects_publications" */
  readonly update_projects_publications_many?: Maybe<ReadonlyArray<Maybe<Projects_Publications_Mutation_Response>>>;
  /** update data of the table: "projects_publications_votes" */
  readonly update_projects_publications_votes?: Maybe<Projects_Publications_Votes_Mutation_Response>;
  /** update single row of the table: "projects_publications_votes" */
  readonly update_projects_publications_votes_by_pk?: Maybe<Projects_Publications_Votes>;
  /** update multiples rows of table: "projects_publications_votes" */
  readonly update_projects_publications_votes_many?: Maybe<ReadonlyArray<Maybe<Projects_Publications_Votes_Mutation_Response>>>;
  /** update data of the table: "projects_templates" */
  readonly update_projects_templates?: Maybe<Projects_Templates_Mutation_Response>;
  /** update single row of the table: "projects_templates" */
  readonly update_projects_templates_by_pk?: Maybe<Projects_Templates>;
  /** update multiples rows of table: "projects_templates" */
  readonly update_projects_templates_many?: Maybe<ReadonlyArray<Maybe<Projects_Templates_Mutation_Response>>>;
  /** update data of the table: "prospective_users" */
  readonly update_prospective_users?: Maybe<Prospective_Users_Mutation_Response>;
  /** update single row of the table: "prospective_users" */
  readonly update_prospective_users_by_pk?: Maybe<Prospective_Users>;
  /** update multiples rows of table: "prospective_users" */
  readonly update_prospective_users_many?: Maybe<ReadonlyArray<Maybe<Prospective_Users_Mutation_Response>>>;
  /** update data of the table: "prospective_users_purchases" */
  readonly update_prospective_users_purchases?: Maybe<Prospective_Users_Purchases_Mutation_Response>;
  /** update single row of the table: "prospective_users_purchases" */
  readonly update_prospective_users_purchases_by_pk?: Maybe<Prospective_Users_Purchases>;
  /** update multiples rows of table: "prospective_users_purchases" */
  readonly update_prospective_users_purchases_many?: Maybe<ReadonlyArray<Maybe<Prospective_Users_Purchases_Mutation_Response>>>;
  /** update data of the table: "quests" */
  readonly update_quests?: Maybe<Quests_Mutation_Response>;
  /** update single row of the table: "quests" */
  readonly update_quests_by_pk?: Maybe<Quests>;
  /** update multiples rows of table: "quests" */
  readonly update_quests_many?: Maybe<ReadonlyArray<Maybe<Quests_Mutation_Response>>>;
  /** update data of the table: "queue_user_info" */
  readonly update_queue_user_info?: Maybe<Queue_User_Info_Mutation_Response>;
  /** update single row of the table: "queue_user_info" */
  readonly update_queue_user_info_by_pk?: Maybe<Queue_User_Info>;
  /** update multiples rows of table: "queue_user_info" */
  readonly update_queue_user_info_many?: Maybe<ReadonlyArray<Maybe<Queue_User_Info_Mutation_Response>>>;
  /** update data of the table: "queues" */
  readonly update_queues?: Maybe<Queues_Mutation_Response>;
  /** update single row of the table: "queues" */
  readonly update_queues_by_pk?: Maybe<Queues>;
  /** update multiples rows of table: "queues" */
  readonly update_queues_many?: Maybe<ReadonlyArray<Maybe<Queues_Mutation_Response>>>;
  /** update data of the table: "render_jobs" */
  readonly update_render_jobs?: Maybe<Render_Jobs_Mutation_Response>;
  /** update single row of the table: "render_jobs" */
  readonly update_render_jobs_by_pk?: Maybe<Render_Jobs>;
  /** update multiples rows of table: "render_jobs" */
  readonly update_render_jobs_many?: Maybe<ReadonlyArray<Maybe<Render_Jobs_Mutation_Response>>>;
  /** update data of the table: "reports" */
  readonly update_reports?: Maybe<Reports_Mutation_Response>;
  /** update single row of the table: "reports" */
  readonly update_reports_by_pk?: Maybe<Reports>;
  /** update multiples rows of table: "reports" */
  readonly update_reports_many?: Maybe<ReadonlyArray<Maybe<Reports_Mutation_Response>>>;
  /** execute VOLATILE function "update_room_schedule_lock_unlock_times" which returns "rooms" */
  readonly update_room_schedule_lock_unlock_times: ReadonlyArray<Rooms>;
  /** execute VOLATILE function "update_room_status" which returns "rooms" */
  readonly update_room_status: ReadonlyArray<Rooms>;
  /** update data of the table: "rooms" */
  readonly update_rooms?: Maybe<Rooms_Mutation_Response>;
  /** update single row of the table: "rooms" */
  readonly update_rooms_by_pk?: Maybe<Rooms>;
  /** update multiples rows of table: "rooms" */
  readonly update_rooms_many?: Maybe<ReadonlyArray<Maybe<Rooms_Mutation_Response>>>;
  /** update data of the table: "schedules" */
  readonly update_schedules?: Maybe<Schedules_Mutation_Response>;
  /** update single row of the table: "schedules" */
  readonly update_schedules_by_pk?: Maybe<Schedules>;
  /** update multiples rows of table: "schedules" */
  readonly update_schedules_many?: Maybe<ReadonlyArray<Maybe<Schedules_Mutation_Response>>>;
  /** update data of the table: "shared_cached_queries" */
  readonly update_shared_cached_queries?: Maybe<Shared_Cached_Queries_Mutation_Response>;
  /** update single row of the table: "shared_cached_queries" */
  readonly update_shared_cached_queries_by_pk?: Maybe<Shared_Cached_Queries>;
  /** update multiples rows of table: "shared_cached_queries" */
  readonly update_shared_cached_queries_many?: Maybe<ReadonlyArray<Maybe<Shared_Cached_Queries_Mutation_Response>>>;
  /** update data of the table: "short_urls" */
  readonly update_short_urls?: Maybe<Short_Urls_Mutation_Response>;
  /** update single row of the table: "short_urls" */
  readonly update_short_urls_by_pk?: Maybe<Short_Urls>;
  /** update multiples rows of table: "short_urls" */
  readonly update_short_urls_many?: Maybe<ReadonlyArray<Maybe<Short_Urls_Mutation_Response>>>;
  /** update data of the table: "subscriptions" */
  readonly update_subscriptions?: Maybe<Subscriptions_Mutation_Response>;
  /** update single row of the table: "subscriptions" */
  readonly update_subscriptions_by_pk?: Maybe<Subscriptions>;
  /** update multiples rows of table: "subscriptions" */
  readonly update_subscriptions_many?: Maybe<ReadonlyArray<Maybe<Subscriptions_Mutation_Response>>>;
  /** update data of the table: "tags" */
  readonly update_tags?: Maybe<Tags_Mutation_Response>;
  /** update data of the table: "tags_associations" */
  readonly update_tags_associations?: Maybe<Tags_Associations_Mutation_Response>;
  /** update single row of the table: "tags_associations" */
  readonly update_tags_associations_by_pk?: Maybe<Tags_Associations>;
  /** update multiples rows of table: "tags_associations" */
  readonly update_tags_associations_many?: Maybe<ReadonlyArray<Maybe<Tags_Associations_Mutation_Response>>>;
  /** update single row of the table: "tags" */
  readonly update_tags_by_pk?: Maybe<Tags>;
  /** update multiples rows of table: "tags" */
  readonly update_tags_many?: Maybe<ReadonlyArray<Maybe<Tags_Mutation_Response>>>;
  /** update data of the table: "themes" */
  readonly update_themes?: Maybe<Themes_Mutation_Response>;
  /** update single row of the table: "themes" */
  readonly update_themes_by_pk?: Maybe<Themes>;
  /** update multiples rows of table: "themes" */
  readonly update_themes_many?: Maybe<ReadonlyArray<Maybe<Themes_Mutation_Response>>>;
  /** update data of the table: "tools" */
  readonly update_tools?: Maybe<Tools_Mutation_Response>;
  /** update single row of the table: "tools" */
  readonly update_tools_by_pk?: Maybe<Tools>;
  /** update multiples rows of table: "tools" */
  readonly update_tools_many?: Maybe<ReadonlyArray<Maybe<Tools_Mutation_Response>>>;
  /** update data of the table: "topics" */
  readonly update_topics?: Maybe<Topics_Mutation_Response>;
  /** update single row of the table: "topics" */
  readonly update_topics_by_pk?: Maybe<Topics>;
  /** update multiples rows of table: "topics" */
  readonly update_topics_many?: Maybe<ReadonlyArray<Maybe<Topics_Mutation_Response>>>;
  /** update data of the table: "topics_tools" */
  readonly update_topics_tools?: Maybe<Topics_Tools_Mutation_Response>;
  /** update single row of the table: "topics_tools" */
  readonly update_topics_tools_by_pk?: Maybe<Topics_Tools>;
  /** update multiples rows of table: "topics_tools" */
  readonly update_topics_tools_many?: Maybe<ReadonlyArray<Maybe<Topics_Tools_Mutation_Response>>>;
  /** update data of the table: "transactions" */
  readonly update_transactions?: Maybe<Transactions_Mutation_Response>;
  /** update single row of the table: "transactions" */
  readonly update_transactions_by_pk?: Maybe<Transactions>;
  /** update multiples rows of table: "transactions" */
  readonly update_transactions_many?: Maybe<ReadonlyArray<Maybe<Transactions_Mutation_Response>>>;
  /** update data of the table: "tutorials" */
  readonly update_tutorials?: Maybe<Tutorials_Mutation_Response>;
  /** update single row of the table: "tutorials" */
  readonly update_tutorials_by_pk?: Maybe<Tutorials>;
  /** update multiples rows of table: "tutorials" */
  readonly update_tutorials_many?: Maybe<ReadonlyArray<Maybe<Tutorials_Mutation_Response>>>;
  /** update data of the table: "tutorials_tags" */
  readonly update_tutorials_tags?: Maybe<Tutorials_Tags_Mutation_Response>;
  /** update single row of the table: "tutorials_tags" */
  readonly update_tutorials_tags_by_pk?: Maybe<Tutorials_Tags>;
  /** update multiples rows of table: "tutorials_tags" */
  readonly update_tutorials_tags_many?: Maybe<ReadonlyArray<Maybe<Tutorials_Tags_Mutation_Response>>>;
  /** update data of the table: "user_collected_avatars" */
  readonly update_user_collected_avatars?: Maybe<User_Collected_Avatars_Mutation_Response>;
  /** update single row of the table: "user_collected_avatars" */
  readonly update_user_collected_avatars_by_pk?: Maybe<User_Collected_Avatars>;
  /** update multiples rows of table: "user_collected_avatars" */
  readonly update_user_collected_avatars_many?: Maybe<ReadonlyArray<Maybe<User_Collected_Avatars_Mutation_Response>>>;
  /** update data of the table: "user_invites" */
  readonly update_user_invites?: Maybe<User_Invites_Mutation_Response>;
  /** update single row of the table: "user_invites" */
  readonly update_user_invites_by_pk?: Maybe<User_Invites>;
  /** update multiples rows of table: "user_invites" */
  readonly update_user_invites_many?: Maybe<ReadonlyArray<Maybe<User_Invites_Mutation_Response>>>;
  /** update data of the table: "user_presence" */
  readonly update_user_presence?: Maybe<User_Presence_Mutation_Response>;
  /** update single row of the table: "user_presence" */
  readonly update_user_presence_by_pk?: Maybe<User_Presence>;
  /** execute VOLATILE function "update_user_presence_delete_platform_state_keys" which returns "user_presence" */
  readonly update_user_presence_delete_platform_state_keys: ReadonlyArray<User_Presence>;
  /** update multiples rows of table: "user_presence" */
  readonly update_user_presence_many?: Maybe<ReadonlyArray<Maybe<User_Presence_Mutation_Response>>>;
  /** update data of the table: "users" */
  readonly update_users?: Maybe<Users_Mutation_Response>;
  /** update data of the table: "users_actions" */
  readonly update_users_actions?: Maybe<Users_Actions_Mutation_Response>;
  /** update single row of the table: "users_actions" */
  readonly update_users_actions_by_pk?: Maybe<Users_Actions>;
  /** update multiples rows of table: "users_actions" */
  readonly update_users_actions_many?: Maybe<ReadonlyArray<Maybe<Users_Actions_Mutation_Response>>>;
  /** update data of the table: "users_badges" */
  readonly update_users_badges?: Maybe<Users_Badges_Mutation_Response>;
  /** update single row of the table: "users_badges" */
  readonly update_users_badges_by_pk?: Maybe<Users_Badges>;
  /** update multiples rows of table: "users_badges" */
  readonly update_users_badges_many?: Maybe<ReadonlyArray<Maybe<Users_Badges_Mutation_Response>>>;
  /** update single row of the table: "users" */
  readonly update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "users_cached_queries" */
  readonly update_users_cached_queries?: Maybe<Users_Cached_Queries_Mutation_Response>;
  /** update single row of the table: "users_cached_queries" */
  readonly update_users_cached_queries_by_pk?: Maybe<Users_Cached_Queries>;
  /** update multiples rows of table: "users_cached_queries" */
  readonly update_users_cached_queries_many?: Maybe<ReadonlyArray<Maybe<Users_Cached_Queries_Mutation_Response>>>;
  /** update data of the table: "users_features" */
  readonly update_users_features?: Maybe<Users_Features_Mutation_Response>;
  /** update single row of the table: "users_features" */
  readonly update_users_features_by_pk?: Maybe<Users_Features>;
  /** update multiples rows of table: "users_features" */
  readonly update_users_features_many?: Maybe<ReadonlyArray<Maybe<Users_Features_Mutation_Response>>>;
  /** update data of the table: "users_levels" */
  readonly update_users_levels?: Maybe<Users_Levels_Mutation_Response>;
  /** update single row of the table: "users_levels" */
  readonly update_users_levels_by_pk?: Maybe<Users_Levels>;
  /** update multiples rows of table: "users_levels" */
  readonly update_users_levels_many?: Maybe<ReadonlyArray<Maybe<Users_Levels_Mutation_Response>>>;
  /** update multiples rows of table: "users" */
  readonly update_users_many?: Maybe<ReadonlyArray<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "users_notifications" */
  readonly update_users_notifications?: Maybe<Users_Notifications_Mutation_Response>;
  /** update single row of the table: "users_notifications" */
  readonly update_users_notifications_by_pk?: Maybe<Users_Notifications>;
  /** update multiples rows of table: "users_notifications" */
  readonly update_users_notifications_many?: Maybe<ReadonlyArray<Maybe<Users_Notifications_Mutation_Response>>>;
  /** update data of the table: "users_presence" */
  readonly update_users_presence?: Maybe<Users_Presence_Mutation_Response>;
  /** update multiples rows of table: "users_presence" */
  readonly update_users_presence_many?: Maybe<ReadonlyArray<Maybe<Users_Presence_Mutation_Response>>>;
  /** update data of the table: "users_progression" */
  readonly update_users_progression?: Maybe<Users_Progression_Mutation_Response>;
  /** update single row of the table: "users_progression" */
  readonly update_users_progression_by_pk?: Maybe<Users_Progression>;
  /** update multiples rows of table: "users_progression" */
  readonly update_users_progression_many?: Maybe<ReadonlyArray<Maybe<Users_Progression_Mutation_Response>>>;
  /** update data of the table: "users_quests" */
  readonly update_users_quests?: Maybe<Users_Quests_Mutation_Response>;
  /** update single row of the table: "users_quests" */
  readonly update_users_quests_by_pk?: Maybe<Users_Quests>;
  /** update multiples rows of table: "users_quests" */
  readonly update_users_quests_many?: Maybe<ReadonlyArray<Maybe<Users_Quests_Mutation_Response>>>;
  /** update data of the table: "users_stale_web_presence" */
  readonly update_users_stale_web_presence?: Maybe<Users_Stale_Web_Presence_Mutation_Response>;
  /** update multiples rows of table: "users_stale_web_presence" */
  readonly update_users_stale_web_presence_many?: Maybe<ReadonlyArray<Maybe<Users_Stale_Web_Presence_Mutation_Response>>>;
  /** update data of the table: "users_stats" */
  readonly update_users_stats?: Maybe<Users_Stats_Mutation_Response>;
  /** update single row of the table: "users_stats" */
  readonly update_users_stats_by_pk?: Maybe<Users_Stats>;
  /** update multiples rows of table: "users_stats" */
  readonly update_users_stats_many?: Maybe<ReadonlyArray<Maybe<Users_Stats_Mutation_Response>>>;
  /** update data of the table: "votes" */
  readonly update_votes?: Maybe<Votes_Mutation_Response>;
  /** update single row of the table: "votes" */
  readonly update_votes_by_pk?: Maybe<Votes>;
  /** update multiples rows of table: "votes" */
  readonly update_votes_many?: Maybe<ReadonlyArray<Maybe<Votes_Mutation_Response>>>;
  readonly upsertClub?: Maybe<UpsertClubOutput>;
  /** Inserts or updates a room. */
  readonly upsertRoom?: Maybe<UpsertRoomOutput>;
  readonly upsertSchedule?: Maybe<UpsertScheduleOutput>;
  /** execute VOLATILE function "upsert_user_action" which returns "users_actions" */
  readonly upsert_user_action: ReadonlyArray<Users_Actions>;
};


/** mutation root */
export type Mutation_RootAcceptTeamInviteArgs = {
  channelId: Scalars['uuid']['input'];
  inviterId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootAwardPrizesArgs = {
  preview_only: Scalars['Boolean']['input'];
  url_name: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootBanChannelUserArgs = {
  channelId: Scalars['uuid']['input'];
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootBookOnboardingArgs = {
  clubId: Scalars['uuid']['input'];
  date: Scalars['timestamptz']['input'];
  platform: Scalars['String']['input'];
  platformId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootComments_DeleteArgs = {
  args: Comments_Delete_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootComplete_Clubs_Members_Onboarding_RequirementArgs = {
  args: Complete_Clubs_Members_Onboarding_Requirement_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Members_Order_By>>;
  where?: InputMaybe<Clubs_Members_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootDelete_AccountsArgs = {
  where: Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Accounts_By_PkArgs = {
  club_id: Scalars['uuid']['input'];
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Asa_User_Quest_TaskArgs = {
  where: Asa_User_Quest_Task_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Asa_User_Quest_Task_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_AvatarsArgs = {
  where: Avatars_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Avatars_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_BadgesArgs = {
  where: Badges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Badges_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_BookingsArgs = {
  where: Bookings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bookings_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_BreakoutsArgs = {
  where: Breakouts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Breakouts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Build_BattlesArgs = {
  where: Build_Battles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Build_Battles_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Build_Battles_ProjectsArgs = {
  where: Build_Battles_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Build_Battles_Projects_By_PkArgs = {
  build_battle_id: Scalars['uuid']['input'];
  project_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Build_Battles_ThemesArgs = {
  where: Build_Battles_Themes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Build_Battles_Themes_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ChannelsArgs = {
  where: Channels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Channels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Channels_EventsArgs = {
  where: Channels_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Channels_Events_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Channels_UsersArgs = {
  where: Channels_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Channels_Users_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ChatsArgs = {
  where: Chats_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Chats_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ClubsArgs = {
  where: Clubs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Clubs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Clubs_MembersArgs = {
  where: Clubs_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Clubs_Members_By_PkArgs = {
  club_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CommentsArgs = {
  where: Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Comments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Comments_VotesArgs = {
  where: Comments_Votes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Comments_Votes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CompetitionsArgs = {
  where: Competitions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Competitions_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Competitions_JudgesArgs = {
  where: Competitions_Judges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Competitions_Judges_By_PkArgs = {
  competition_id: Scalars['String']['input'];
  judge_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Competitions_TagsArgs = {
  where: Competitions_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Competitions_Tags_By_PkArgs = {
  competition_id: Scalars['String']['input'];
  tag_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Competitions_TutorialsArgs = {
  where: Competitions_Tutorials_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Competitions_Tutorials_By_PkArgs = {
  competition_id: Scalars['String']['input'];
  tutorial_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Eban_CommentsArgs = {
  where: Eban_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Eban_Comments_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Eban_TypesArgs = {
  where: Eban_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Eban_Types_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_EbansArgs = {
  where: Ebans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ebans_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_EnvironmentArgs = {
  where: Environment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Environment_By_PkArgs = {
  key: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FeaturesArgs = {
  where: Features_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Features_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Features_ProductsArgs = {
  where: Features_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Features_Products_By_PkArgs = {
  feature_id: Scalars['uuid']['input'];
  product_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FeedbackArgs = {
  where: Feedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Feedback_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FeedsArgs = {
  where: Feeds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Feeds_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FollowsArgs = {
  where: Follows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Follows_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FriendsArgs = {
  where: Friends_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Friends_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FriendshipsArgs = {
  where: Friendships_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Friendships_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Friendships_UsersArgs = {
  where: Friendships_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Friendships_Users_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Game_ServersArgs = {
  where: Game_Servers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Game_Servers_By_PkArgs = {
  name: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_HomesArgs = {
  where: Homes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Image_CacheArgs = {
  where: Image_Cache_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Image_Cache_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Inactive_BreakoutArgs = {
  args: Delete_Inactive_Breakout_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Breakouts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Breakouts_Order_By>>;
  where?: InputMaybe<Breakouts_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootDelete_Infrastructure_OperationsArgs = {
  where: Infrastructure_Operations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ItemsArgs = {
  where: Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Items_TagsArgs = {
  where: Items_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_Tags_By_PkArgs = {
  item_id: Scalars['String']['input'];
  tag_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_LevelsArgs = {
  where: Levels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Levels_By_PkArgs = {
  level: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Live_EventsArgs = {
  where: Live_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Live_Events_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Live_Events_HostsArgs = {
  where: Live_Events_Hosts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Live_Events_Hosts_By_PkArgs = {
  live_event_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_MessagesArgs = {
  where: Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Messages_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Minecraft_AnnouncementArgs = {
  where: Minecraft_Announcement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Minecraft_Announcement_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Minecraft_ServersArgs = {
  where: Minecraft_Servers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Minecraft_Servers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Minecraft_SkinsArgs = {
  where: Minecraft_Skins_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Minecraft_Skins_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Minecraft_TutorialArgs = {
  where: Minecraft_Tutorial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Minecraft_Tutorial_By_PkArgs = {
  requirement_id: Scalars['uuid']['input'];
  stage_id: Scalars['uuid']['input'];
  tutorial_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Minecraft_Tutorial_StatusArgs = {
  where: Minecraft_Tutorial_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Minecraft_Tutorial_Status_By_PkArgs = {
  tutorial_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_NotificationsArgs = {
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_OrdersArgs = {
  where: Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Orders_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_OtpsArgs = {
  where: Otps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Otps_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_PagesArgs = {
  where: Pages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pages_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Player_WorldsArgs = {
  where: Player_Worlds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Player_Worlds_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_PostsArgs = {
  where: Posts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Posts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ProjectsArgs = {
  where: Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Projects_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Projects_CollaboratorsArgs = {
  where: Projects_Collaborators_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Projects_Collaborators_By_PkArgs = {
  collaborator_id: Scalars['String']['input'];
  project_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Projects_EditablesArgs = {
  where: Projects_Editables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Projects_LeaderboardsArgs = {
  where: Projects_Leaderboards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Projects_Leaderboards_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Projects_PublicationsArgs = {
  where: Projects_Publications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Projects_Publications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Projects_Publications_VotesArgs = {
  where: Projects_Publications_Votes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Projects_Publications_Votes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Projects_TemplatesArgs = {
  where: Projects_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Projects_Templates_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Prospective_UsersArgs = {
  where: Prospective_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Prospective_Users_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Prospective_Users_PurchasesArgs = {
  where: Prospective_Users_Purchases_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Prospective_Users_Purchases_By_PkArgs = {
  club_id: Scalars['uuid']['input'];
  prospective_user_id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_QuestsArgs = {
  where: Quests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Quests_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Queue_User_InfoArgs = {
  where: Queue_User_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Queue_User_Info_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_QueuesArgs = {
  where: Queues_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Queues_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Render_JobsArgs = {
  where: Render_Jobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Render_Jobs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ReportsArgs = {
  where: Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reports_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_RoomsArgs = {
  where: Rooms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rooms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_SchedulesArgs = {
  where: Schedules_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Schedules_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Shared_Cached_QueriesArgs = {
  where: Shared_Cached_Queries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Shared_Cached_Queries_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Short_UrlsArgs = {
  where: Short_Urls_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Short_Urls_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_SubscriptionsArgs = {
  where: Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subscriptions_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TagsArgs = {
  where: Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tags_AssociationsArgs = {
  where: Tags_Associations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tags_Associations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tags_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ThemesArgs = {
  where: Themes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Themes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ToolsArgs = {
  where: Tools_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tools_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TopicsArgs = {
  where: Topics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Topics_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Topics_ToolsArgs = {
  where: Topics_Tools_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Topics_Tools_By_PkArgs = {
  tool_id: Scalars['String']['input'];
  topic_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TransactionsArgs = {
  where: Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Transactions_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TutorialsArgs = {
  where: Tutorials_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tutorials_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tutorials_TagsArgs = {
  where: Tutorials_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tutorials_Tags_By_PkArgs = {
  tag_id: Scalars['String']['input'];
  tutorial_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Collected_AvatarsArgs = {
  where: User_Collected_Avatars_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Collected_Avatars_By_PkArgs = {
  avatar_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_InvitesArgs = {
  where: User_Invites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Invites_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_PresenceArgs = {
  where: User_Presence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Presence_By_PkArgs = {
  platform: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_ActionsArgs = {
  where: Users_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Actions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_BadgesArgs = {
  where: Users_Badges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Badges_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_Cached_QueriesArgs = {
  where: Users_Cached_Queries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Cached_Queries_By_PkArgs = {
  key: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_FeaturesArgs = {
  where: Users_Features_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Features_By_PkArgs = {
  feature_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_LevelsArgs = {
  where: Users_Levels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Levels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_NotificationsArgs = {
  where: Users_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Notifications_By_PkArgs = {
  notification_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_PresenceArgs = {
  where: Users_Presence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_ProgressionArgs = {
  where: Users_Progression_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Progression_By_PkArgs = {
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_QuestsArgs = {
  where: Users_Quests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Quests_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_Stale_Web_PresenceArgs = {
  where: Users_Stale_Web_Presence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_StatsArgs = {
  where: Users_Stats_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Stats_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_VotesArgs = {
  where: Votes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Votes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootFeeds_DeleteArgs = {
  args: Feeds_Delete_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootGenerateCallAccessTokenArgs = {
  channelId: Scalars['uuid']['input'];
  roomId: Scalars['uuid']['input'];
  startAudioOff?: InputMaybe<Scalars['Boolean']['input']>;
};


/** mutation root */
export type Mutation_RootInsert_AccountsArgs = {
  objects: ReadonlyArray<Accounts_Insert_Input>;
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_OneArgs = {
  object: Accounts_Insert_Input;
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Asa_User_Quest_TaskArgs = {
  objects: ReadonlyArray<Asa_User_Quest_Task_Insert_Input>;
  on_conflict?: InputMaybe<Asa_User_Quest_Task_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Asa_User_Quest_Task_OneArgs = {
  object: Asa_User_Quest_Task_Insert_Input;
  on_conflict?: InputMaybe<Asa_User_Quest_Task_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AvatarsArgs = {
  objects: ReadonlyArray<Avatars_Insert_Input>;
  on_conflict?: InputMaybe<Avatars_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Avatars_OneArgs = {
  object: Avatars_Insert_Input;
  on_conflict?: InputMaybe<Avatars_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BadgesArgs = {
  objects: ReadonlyArray<Badges_Insert_Input>;
  on_conflict?: InputMaybe<Badges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Badges_OneArgs = {
  object: Badges_Insert_Input;
  on_conflict?: InputMaybe<Badges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BookingsArgs = {
  objects: ReadonlyArray<Bookings_Insert_Input>;
  on_conflict?: InputMaybe<Bookings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bookings_OneArgs = {
  object: Bookings_Insert_Input;
  on_conflict?: InputMaybe<Bookings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BreakoutsArgs = {
  objects: ReadonlyArray<Breakouts_Insert_Input>;
  on_conflict?: InputMaybe<Breakouts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Breakouts_OneArgs = {
  object: Breakouts_Insert_Input;
  on_conflict?: InputMaybe<Breakouts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Build_BattlesArgs = {
  objects: ReadonlyArray<Build_Battles_Insert_Input>;
  on_conflict?: InputMaybe<Build_Battles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Build_Battles_OneArgs = {
  object: Build_Battles_Insert_Input;
  on_conflict?: InputMaybe<Build_Battles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Build_Battles_ProjectsArgs = {
  objects: ReadonlyArray<Build_Battles_Projects_Insert_Input>;
  on_conflict?: InputMaybe<Build_Battles_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Build_Battles_Projects_OneArgs = {
  object: Build_Battles_Projects_Insert_Input;
  on_conflict?: InputMaybe<Build_Battles_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Build_Battles_ThemesArgs = {
  objects: ReadonlyArray<Build_Battles_Themes_Insert_Input>;
  on_conflict?: InputMaybe<Build_Battles_Themes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Build_Battles_Themes_OneArgs = {
  object: Build_Battles_Themes_Insert_Input;
  on_conflict?: InputMaybe<Build_Battles_Themes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channel_User_By_AssociationArgs = {
  args: Insert_Channel_User_By_Association_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Users_Order_By>>;
  where?: InputMaybe<Channels_Users_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootInsert_ChannelsArgs = {
  objects: ReadonlyArray<Channels_Insert_Input>;
  on_conflict?: InputMaybe<Channels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channels_EventsArgs = {
  objects: ReadonlyArray<Channels_Events_Insert_Input>;
  on_conflict?: InputMaybe<Channels_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channels_Events_OneArgs = {
  object: Channels_Events_Insert_Input;
  on_conflict?: InputMaybe<Channels_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channels_OneArgs = {
  object: Channels_Insert_Input;
  on_conflict?: InputMaybe<Channels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channels_UsersArgs = {
  objects: ReadonlyArray<Channels_Users_Insert_Input>;
  on_conflict?: InputMaybe<Channels_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channels_Users_OneArgs = {
  object: Channels_Users_Insert_Input;
  on_conflict?: InputMaybe<Channels_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ChatsArgs = {
  objects: ReadonlyArray<Chats_Insert_Input>;
  on_conflict?: InputMaybe<Chats_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chats_OneArgs = {
  object: Chats_Insert_Input;
  on_conflict?: InputMaybe<Chats_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClubsArgs = {
  objects: ReadonlyArray<Clubs_Insert_Input>;
  on_conflict?: InputMaybe<Clubs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Clubs_MembersArgs = {
  objects: ReadonlyArray<Clubs_Members_Insert_Input>;
  on_conflict?: InputMaybe<Clubs_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Clubs_Members_OneArgs = {
  object: Clubs_Members_Insert_Input;
  on_conflict?: InputMaybe<Clubs_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Clubs_OneArgs = {
  object: Clubs_Insert_Input;
  on_conflict?: InputMaybe<Clubs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CommentsArgs = {
  objects: ReadonlyArray<Comments_Insert_Input>;
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comments_OneArgs = {
  object: Comments_Insert_Input;
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comments_VotesArgs = {
  objects: ReadonlyArray<Comments_Votes_Insert_Input>;
  on_conflict?: InputMaybe<Comments_Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comments_Votes_OneArgs = {
  object: Comments_Votes_Insert_Input;
  on_conflict?: InputMaybe<Comments_Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompetitionsArgs = {
  objects: ReadonlyArray<Competitions_Insert_Input>;
  on_conflict?: InputMaybe<Competitions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Competitions_JudgesArgs = {
  objects: ReadonlyArray<Competitions_Judges_Insert_Input>;
  on_conflict?: InputMaybe<Competitions_Judges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Competitions_Judges_OneArgs = {
  object: Competitions_Judges_Insert_Input;
  on_conflict?: InputMaybe<Competitions_Judges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Competitions_OneArgs = {
  object: Competitions_Insert_Input;
  on_conflict?: InputMaybe<Competitions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Competitions_TagsArgs = {
  objects: ReadonlyArray<Competitions_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Competitions_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Competitions_Tags_OneArgs = {
  object: Competitions_Tags_Insert_Input;
  on_conflict?: InputMaybe<Competitions_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Competitions_TutorialsArgs = {
  objects: ReadonlyArray<Competitions_Tutorials_Insert_Input>;
  on_conflict?: InputMaybe<Competitions_Tutorials_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Competitions_Tutorials_OneArgs = {
  object: Competitions_Tutorials_Insert_Input;
  on_conflict?: InputMaybe<Competitions_Tutorials_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Eban_CommentsArgs = {
  objects: ReadonlyArray<Eban_Comments_Insert_Input>;
  on_conflict?: InputMaybe<Eban_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Eban_Comments_OneArgs = {
  object: Eban_Comments_Insert_Input;
  on_conflict?: InputMaybe<Eban_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Eban_TypesArgs = {
  objects: ReadonlyArray<Eban_Types_Insert_Input>;
  on_conflict?: InputMaybe<Eban_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Eban_Types_OneArgs = {
  object: Eban_Types_Insert_Input;
  on_conflict?: InputMaybe<Eban_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EbansArgs = {
  objects: ReadonlyArray<Ebans_Insert_Input>;
  on_conflict?: InputMaybe<Ebans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ebans_OneArgs = {
  object: Ebans_Insert_Input;
  on_conflict?: InputMaybe<Ebans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EnvironmentArgs = {
  objects: ReadonlyArray<Environment_Insert_Input>;
  on_conflict?: InputMaybe<Environment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Environment_OneArgs = {
  object: Environment_Insert_Input;
  on_conflict?: InputMaybe<Environment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeaturesArgs = {
  objects: ReadonlyArray<Features_Insert_Input>;
  on_conflict?: InputMaybe<Features_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Features_OneArgs = {
  object: Features_Insert_Input;
  on_conflict?: InputMaybe<Features_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Features_ProductsArgs = {
  objects: ReadonlyArray<Features_Products_Insert_Input>;
  on_conflict?: InputMaybe<Features_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Features_Products_OneArgs = {
  object: Features_Products_Insert_Input;
  on_conflict?: InputMaybe<Features_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeedbackArgs = {
  objects: ReadonlyArray<Feedback_Insert_Input>;
  on_conflict?: InputMaybe<Feedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Feedback_OneArgs = {
  object: Feedback_Insert_Input;
  on_conflict?: InputMaybe<Feedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeedsArgs = {
  objects: ReadonlyArray<Feeds_Insert_Input>;
  on_conflict?: InputMaybe<Feeds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Feeds_OneArgs = {
  object: Feeds_Insert_Input;
  on_conflict?: InputMaybe<Feeds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FollowsArgs = {
  objects: ReadonlyArray<Follows_Insert_Input>;
  on_conflict?: InputMaybe<Follows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Follows_OneArgs = {
  object: Follows_Insert_Input;
  on_conflict?: InputMaybe<Follows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FriendsArgs = {
  objects: ReadonlyArray<Friends_Insert_Input>;
  on_conflict?: InputMaybe<Friends_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Friends_OneArgs = {
  object: Friends_Insert_Input;
  on_conflict?: InputMaybe<Friends_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FriendshipsArgs = {
  objects: ReadonlyArray<Friendships_Insert_Input>;
  on_conflict?: InputMaybe<Friendships_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Friendships_OneArgs = {
  object: Friendships_Insert_Input;
  on_conflict?: InputMaybe<Friendships_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Friendships_UsersArgs = {
  objects: ReadonlyArray<Friendships_Users_Insert_Input>;
  on_conflict?: InputMaybe<Friendships_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Friendships_Users_OneArgs = {
  object: Friendships_Users_Insert_Input;
  on_conflict?: InputMaybe<Friendships_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Game_ServersArgs = {
  objects: ReadonlyArray<Game_Servers_Insert_Input>;
  on_conflict?: InputMaybe<Game_Servers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Game_Servers_OneArgs = {
  object: Game_Servers_Insert_Input;
  on_conflict?: InputMaybe<Game_Servers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HomesArgs = {
  objects: ReadonlyArray<Homes_Insert_Input>;
  on_conflict?: InputMaybe<Homes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homes_OneArgs = {
  object: Homes_Insert_Input;
  on_conflict?: InputMaybe<Homes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Image_CacheArgs = {
  objects: ReadonlyArray<Image_Cache_Insert_Input>;
  on_conflict?: InputMaybe<Image_Cache_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Image_Cache_OneArgs = {
  object: Image_Cache_Insert_Input;
  on_conflict?: InputMaybe<Image_Cache_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Infrastructure_OperationsArgs = {
  objects: ReadonlyArray<Infrastructure_Operations_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Infrastructure_Operations_OneArgs = {
  object: Infrastructure_Operations_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Initial_Minecraft_User_PresenceArgs = {
  args: Insert_Initial_Minecraft_User_Presence_Args;
  distinct_on?: InputMaybe<ReadonlyArray<User_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Presence_Order_By>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootInsert_ItemsArgs = {
  objects: ReadonlyArray<Items_Insert_Input>;
  on_conflict?: InputMaybe<Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_OneArgs = {
  object: Items_Insert_Input;
  on_conflict?: InputMaybe<Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_TagsArgs = {
  objects: ReadonlyArray<Items_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Items_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_Tags_OneArgs = {
  object: Items_Tags_Insert_Input;
  on_conflict?: InputMaybe<Items_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LevelsArgs = {
  objects: ReadonlyArray<Levels_Insert_Input>;
  on_conflict?: InputMaybe<Levels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Levels_OneArgs = {
  object: Levels_Insert_Input;
  on_conflict?: InputMaybe<Levels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Live_EventsArgs = {
  objects: ReadonlyArray<Live_Events_Insert_Input>;
  on_conflict?: InputMaybe<Live_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Live_Events_HostsArgs = {
  objects: ReadonlyArray<Live_Events_Hosts_Insert_Input>;
  on_conflict?: InputMaybe<Live_Events_Hosts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Live_Events_Hosts_OneArgs = {
  object: Live_Events_Hosts_Insert_Input;
  on_conflict?: InputMaybe<Live_Events_Hosts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Live_Events_OneArgs = {
  object: Live_Events_Insert_Input;
  on_conflict?: InputMaybe<Live_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MessagesArgs = {
  objects: ReadonlyArray<Messages_Insert_Input>;
  on_conflict?: InputMaybe<Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Messages_OneArgs = {
  object: Messages_Insert_Input;
  on_conflict?: InputMaybe<Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Minecraft_AnnouncementArgs = {
  objects: ReadonlyArray<Minecraft_Announcement_Insert_Input>;
  on_conflict?: InputMaybe<Minecraft_Announcement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Minecraft_Announcement_OneArgs = {
  object: Minecraft_Announcement_Insert_Input;
  on_conflict?: InputMaybe<Minecraft_Announcement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Minecraft_ServersArgs = {
  objects: ReadonlyArray<Minecraft_Servers_Insert_Input>;
  on_conflict?: InputMaybe<Minecraft_Servers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Minecraft_Servers_OneArgs = {
  object: Minecraft_Servers_Insert_Input;
  on_conflict?: InputMaybe<Minecraft_Servers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Minecraft_SkinsArgs = {
  objects: ReadonlyArray<Minecraft_Skins_Insert_Input>;
  on_conflict?: InputMaybe<Minecraft_Skins_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Minecraft_Skins_OneArgs = {
  object: Minecraft_Skins_Insert_Input;
  on_conflict?: InputMaybe<Minecraft_Skins_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Minecraft_TutorialArgs = {
  objects: ReadonlyArray<Minecraft_Tutorial_Insert_Input>;
  on_conflict?: InputMaybe<Minecraft_Tutorial_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Minecraft_Tutorial_OneArgs = {
  object: Minecraft_Tutorial_Insert_Input;
  on_conflict?: InputMaybe<Minecraft_Tutorial_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Minecraft_Tutorial_StatusArgs = {
  objects: ReadonlyArray<Minecraft_Tutorial_Status_Insert_Input>;
  on_conflict?: InputMaybe<Minecraft_Tutorial_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Minecraft_Tutorial_Status_OneArgs = {
  object: Minecraft_Tutorial_Status_Insert_Input;
  on_conflict?: InputMaybe<Minecraft_Tutorial_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotificationsArgs = {
  objects: ReadonlyArray<Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notifications_OneArgs = {
  object: Notifications_Insert_Input;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrdersArgs = {
  objects: ReadonlyArray<Orders_Insert_Input>;
  on_conflict?: InputMaybe<Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Orders_OneArgs = {
  object: Orders_Insert_Input;
  on_conflict?: InputMaybe<Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OtpsArgs = {
  objects: ReadonlyArray<Otps_Insert_Input>;
  on_conflict?: InputMaybe<Otps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Otps_OneArgs = {
  object: Otps_Insert_Input;
  on_conflict?: InputMaybe<Otps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PagesArgs = {
  objects: ReadonlyArray<Pages_Insert_Input>;
  on_conflict?: InputMaybe<Pages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pages_OneArgs = {
  object: Pages_Insert_Input;
  on_conflict?: InputMaybe<Pages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Player_WorldsArgs = {
  objects: ReadonlyArray<Player_Worlds_Insert_Input>;
  on_conflict?: InputMaybe<Player_Worlds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Player_Worlds_OneArgs = {
  object: Player_Worlds_Insert_Input;
  on_conflict?: InputMaybe<Player_Worlds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PostsArgs = {
  objects: ReadonlyArray<Posts_Insert_Input>;
  on_conflict?: InputMaybe<Posts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Posts_OneArgs = {
  object: Posts_Insert_Input;
  on_conflict?: InputMaybe<Posts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProjectsArgs = {
  objects: ReadonlyArray<Projects_Insert_Input>;
  on_conflict?: InputMaybe<Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_CollaboratorsArgs = {
  objects: ReadonlyArray<Projects_Collaborators_Insert_Input>;
  on_conflict?: InputMaybe<Projects_Collaborators_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_Collaborators_OneArgs = {
  object: Projects_Collaborators_Insert_Input;
  on_conflict?: InputMaybe<Projects_Collaborators_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_EditablesArgs = {
  objects: ReadonlyArray<Projects_Editables_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_Editables_OneArgs = {
  object: Projects_Editables_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Projects_LeaderboardsArgs = {
  objects: ReadonlyArray<Projects_Leaderboards_Insert_Input>;
  on_conflict?: InputMaybe<Projects_Leaderboards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_Leaderboards_OneArgs = {
  object: Projects_Leaderboards_Insert_Input;
  on_conflict?: InputMaybe<Projects_Leaderboards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_OneArgs = {
  object: Projects_Insert_Input;
  on_conflict?: InputMaybe<Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_PublicationsArgs = {
  objects: ReadonlyArray<Projects_Publications_Insert_Input>;
  on_conflict?: InputMaybe<Projects_Publications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_Publications_OneArgs = {
  object: Projects_Publications_Insert_Input;
  on_conflict?: InputMaybe<Projects_Publications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_Publications_VotesArgs = {
  objects: ReadonlyArray<Projects_Publications_Votes_Insert_Input>;
  on_conflict?: InputMaybe<Projects_Publications_Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_Publications_Votes_OneArgs = {
  object: Projects_Publications_Votes_Insert_Input;
  on_conflict?: InputMaybe<Projects_Publications_Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_TemplatesArgs = {
  objects: ReadonlyArray<Projects_Templates_Insert_Input>;
  on_conflict?: InputMaybe<Projects_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_Templates_OneArgs = {
  object: Projects_Templates_Insert_Input;
  on_conflict?: InputMaybe<Projects_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Prospective_UsersArgs = {
  objects: ReadonlyArray<Prospective_Users_Insert_Input>;
  on_conflict?: InputMaybe<Prospective_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Prospective_Users_OneArgs = {
  object: Prospective_Users_Insert_Input;
  on_conflict?: InputMaybe<Prospective_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Prospective_Users_PurchasesArgs = {
  objects: ReadonlyArray<Prospective_Users_Purchases_Insert_Input>;
  on_conflict?: InputMaybe<Prospective_Users_Purchases_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Prospective_Users_Purchases_OneArgs = {
  object: Prospective_Users_Purchases_Insert_Input;
  on_conflict?: InputMaybe<Prospective_Users_Purchases_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestsArgs = {
  objects: ReadonlyArray<Quests_Insert_Input>;
  on_conflict?: InputMaybe<Quests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Quests_OneArgs = {
  object: Quests_Insert_Input;
  on_conflict?: InputMaybe<Quests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Queue_User_InfoArgs = {
  objects: ReadonlyArray<Queue_User_Info_Insert_Input>;
  on_conflict?: InputMaybe<Queue_User_Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Queue_User_Info_OneArgs = {
  object: Queue_User_Info_Insert_Input;
  on_conflict?: InputMaybe<Queue_User_Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QueuesArgs = {
  objects: ReadonlyArray<Queues_Insert_Input>;
  on_conflict?: InputMaybe<Queues_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Queues_OneArgs = {
  object: Queues_Insert_Input;
  on_conflict?: InputMaybe<Queues_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Render_JobsArgs = {
  objects: ReadonlyArray<Render_Jobs_Insert_Input>;
  on_conflict?: InputMaybe<Render_Jobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Render_Jobs_OneArgs = {
  object: Render_Jobs_Insert_Input;
  on_conflict?: InputMaybe<Render_Jobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReportsArgs = {
  objects: ReadonlyArray<Reports_Insert_Input>;
  on_conflict?: InputMaybe<Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reports_OneArgs = {
  object: Reports_Insert_Input;
  on_conflict?: InputMaybe<Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RoomsArgs = {
  objects: ReadonlyArray<Rooms_Insert_Input>;
  on_conflict?: InputMaybe<Rooms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rooms_OneArgs = {
  object: Rooms_Insert_Input;
  on_conflict?: InputMaybe<Rooms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SchedulesArgs = {
  objects: ReadonlyArray<Schedules_Insert_Input>;
  on_conflict?: InputMaybe<Schedules_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Schedules_OneArgs = {
  object: Schedules_Insert_Input;
  on_conflict?: InputMaybe<Schedules_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Shared_Cached_QueriesArgs = {
  objects: ReadonlyArray<Shared_Cached_Queries_Insert_Input>;
  on_conflict?: InputMaybe<Shared_Cached_Queries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Shared_Cached_Queries_OneArgs = {
  object: Shared_Cached_Queries_Insert_Input;
  on_conflict?: InputMaybe<Shared_Cached_Queries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Short_UrlsArgs = {
  objects: ReadonlyArray<Short_Urls_Insert_Input>;
  on_conflict?: InputMaybe<Short_Urls_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Short_Urls_OneArgs = {
  object: Short_Urls_Insert_Input;
  on_conflict?: InputMaybe<Short_Urls_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SubscriptionsArgs = {
  objects: ReadonlyArray<Subscriptions_Insert_Input>;
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscriptions_OneArgs = {
  object: Subscriptions_Insert_Input;
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TagsArgs = {
  objects: ReadonlyArray<Tags_Insert_Input>;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tags_AssociationsArgs = {
  objects: ReadonlyArray<Tags_Associations_Insert_Input>;
  on_conflict?: InputMaybe<Tags_Associations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tags_Associations_OneArgs = {
  object: Tags_Associations_Insert_Input;
  on_conflict?: InputMaybe<Tags_Associations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tags_OneArgs = {
  object: Tags_Insert_Input;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ThemesArgs = {
  objects: ReadonlyArray<Themes_Insert_Input>;
  on_conflict?: InputMaybe<Themes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Themes_OneArgs = {
  object: Themes_Insert_Input;
  on_conflict?: InputMaybe<Themes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ToolsArgs = {
  objects: ReadonlyArray<Tools_Insert_Input>;
  on_conflict?: InputMaybe<Tools_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tools_OneArgs = {
  object: Tools_Insert_Input;
  on_conflict?: InputMaybe<Tools_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TopicsArgs = {
  objects: ReadonlyArray<Topics_Insert_Input>;
  on_conflict?: InputMaybe<Topics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Topics_OneArgs = {
  object: Topics_Insert_Input;
  on_conflict?: InputMaybe<Topics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Topics_ToolsArgs = {
  objects: ReadonlyArray<Topics_Tools_Insert_Input>;
  on_conflict?: InputMaybe<Topics_Tools_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Topics_Tools_OneArgs = {
  object: Topics_Tools_Insert_Input;
  on_conflict?: InputMaybe<Topics_Tools_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TransactionsArgs = {
  objects: ReadonlyArray<Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transactions_OneArgs = {
  object: Transactions_Insert_Input;
  on_conflict?: InputMaybe<Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TutorialsArgs = {
  objects: ReadonlyArray<Tutorials_Insert_Input>;
  on_conflict?: InputMaybe<Tutorials_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tutorials_OneArgs = {
  object: Tutorials_Insert_Input;
  on_conflict?: InputMaybe<Tutorials_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tutorials_TagsArgs = {
  objects: ReadonlyArray<Tutorials_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Tutorials_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tutorials_Tags_OneArgs = {
  object: Tutorials_Tags_Insert_Input;
  on_conflict?: InputMaybe<Tutorials_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Collected_AvatarsArgs = {
  objects: ReadonlyArray<User_Collected_Avatars_Insert_Input>;
  on_conflict?: InputMaybe<User_Collected_Avatars_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Collected_Avatars_OneArgs = {
  object: User_Collected_Avatars_Insert_Input;
  on_conflict?: InputMaybe<User_Collected_Avatars_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_InvitesArgs = {
  objects: ReadonlyArray<User_Invites_Insert_Input>;
  on_conflict?: InputMaybe<User_Invites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Invites_OneArgs = {
  object: User_Invites_Insert_Input;
  on_conflict?: InputMaybe<User_Invites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_PresenceArgs = {
  objects: ReadonlyArray<User_Presence_Insert_Input>;
  on_conflict?: InputMaybe<User_Presence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Presence_OneArgs = {
  object: User_Presence_Insert_Input;
  on_conflict?: InputMaybe<User_Presence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: ReadonlyArray<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_ActionsArgs = {
  objects: ReadonlyArray<Users_Actions_Insert_Input>;
  on_conflict?: InputMaybe<Users_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Actions_OneArgs = {
  object: Users_Actions_Insert_Input;
  on_conflict?: InputMaybe<Users_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_BadgesArgs = {
  objects: ReadonlyArray<Users_Badges_Insert_Input>;
  on_conflict?: InputMaybe<Users_Badges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Badges_OneArgs = {
  object: Users_Badges_Insert_Input;
  on_conflict?: InputMaybe<Users_Badges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Cached_QueriesArgs = {
  objects: ReadonlyArray<Users_Cached_Queries_Insert_Input>;
  on_conflict?: InputMaybe<Users_Cached_Queries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Cached_Queries_OneArgs = {
  object: Users_Cached_Queries_Insert_Input;
  on_conflict?: InputMaybe<Users_Cached_Queries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_FeaturesArgs = {
  objects: ReadonlyArray<Users_Features_Insert_Input>;
  on_conflict?: InputMaybe<Users_Features_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Features_OneArgs = {
  object: Users_Features_Insert_Input;
  on_conflict?: InputMaybe<Users_Features_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_LevelsArgs = {
  objects: ReadonlyArray<Users_Levels_Insert_Input>;
  on_conflict?: InputMaybe<Users_Levels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Levels_OneArgs = {
  object: Users_Levels_Insert_Input;
  on_conflict?: InputMaybe<Users_Levels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_NotificationsArgs = {
  objects: ReadonlyArray<Users_Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Users_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Notifications_OneArgs = {
  object: Users_Notifications_Insert_Input;
  on_conflict?: InputMaybe<Users_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_PresenceArgs = {
  objects: ReadonlyArray<Users_Presence_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Presence_OneArgs = {
  object: Users_Presence_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Users_ProgressionArgs = {
  objects: ReadonlyArray<Users_Progression_Insert_Input>;
  on_conflict?: InputMaybe<Users_Progression_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Progression_OneArgs = {
  object: Users_Progression_Insert_Input;
  on_conflict?: InputMaybe<Users_Progression_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_QuestsArgs = {
  objects: ReadonlyArray<Users_Quests_Insert_Input>;
  on_conflict?: InputMaybe<Users_Quests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Quests_OneArgs = {
  object: Users_Quests_Insert_Input;
  on_conflict?: InputMaybe<Users_Quests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Stale_Web_PresenceArgs = {
  objects: ReadonlyArray<Users_Stale_Web_Presence_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Stale_Web_Presence_OneArgs = {
  object: Users_Stale_Web_Presence_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Users_StatsArgs = {
  objects: ReadonlyArray<Users_Stats_Insert_Input>;
  on_conflict?: InputMaybe<Users_Stats_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Stats_OneArgs = {
  object: Users_Stats_Insert_Input;
  on_conflict?: InputMaybe<Users_Stats_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VotesArgs = {
  objects: ReadonlyArray<Votes_Insert_Input>;
  on_conflict?: InputMaybe<Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Votes_OneArgs = {
  object: Votes_Insert_Input;
  on_conflict?: InputMaybe<Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootIssueTransactionsArgs = {
  transactions: ReadonlyArray<Transaction>;
};


/** mutation root */
export type Mutation_RootJoinActivityArgs = {
  activityId: Scalars['String']['input'];
  joinCall?: InputMaybe<Scalars['Boolean']['input']>;
  startAudioOff?: InputMaybe<Scalars['Boolean']['input']>;
};


/** mutation root */
export type Mutation_RootJoinBreakoutArgs = {
  activeStatus?: InputMaybe<Scalars['Boolean']['input']>;
  breakoutId: Scalars['uuid']['input'];
  clubId: Scalars['uuid']['input'];
  joinCall?: InputMaybe<Scalars['Boolean']['input']>;
  roomId: Scalars['uuid']['input'];
  startAudioOff?: InputMaybe<Scalars['Boolean']['input']>;
};


/** mutation root */
export type Mutation_RootJoinCompetitionArgs = {
  associated_competition_id: Scalars['String']['input'];
  matchmaking?: InputMaybe<Scalars['Boolean']['input']>;
};


/** mutation root */
export type Mutation_RootJoinRoomArgs = {
  activeStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clubId: Scalars['uuid']['input'];
  joinCall?: InputMaybe<Scalars['Boolean']['input']>;
  roomId: Scalars['uuid']['input'];
  startAudioOff?: InputMaybe<Scalars['Boolean']['input']>;
};


/** mutation root */
export type Mutation_RootLeaveCompetitionArgs = {
  channel_id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootPosts_DeleteArgs = {
  args: Posts_Delete_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootPublishToMinecraftArgs = {
  channel: Scalars['String']['input'];
  payload: Scalars['jsonb']['input'];
};


/** mutation root */
export type Mutation_RootRemove_Club_RequirementArgs = {
  args: Remove_Club_Requirement_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Members_Order_By>>;
  where?: InputMaybe<Clubs_Members_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootRemove_Progression_Claimed_LevelsArgs = {
  args: Remove_Progression_Claimed_Levels_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Users_Progression_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Progression_Order_By>>;
  where?: InputMaybe<Users_Progression_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootSendFriendRequestArgs = {
  recipient_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootSendPasswordResetArgs = {
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootSubmitChatMessageArgs = {
  channelId: Scalars['uuid']['input'];
  id: Scalars['uuid']['input'];
  message: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootSubmit_Chat_MessageArgs = {
  args: Submit_Chat_Message_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Chats_Order_By>>;
  where?: InputMaybe<Chats_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootToggleMatchmakingArgs = {
  channelId: Scalars['uuid']['input'];
  competitionId: Scalars['uuid']['input'];
  matchmaking: Scalars['Boolean']['input'];
  userId: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootToggleRoomHostIdArgs = {
  roomId: Scalars['uuid']['input'];
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootToggle_Host_User_IdArgs = {
  args: Toggle_Host_User_Id_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootUnlinkDiscordArgs = {
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUnlinkXboxArgs = {
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdateChannelMuteEnabledArgs = {
  args: UpdateChannelMuteEnabledInput;
};


/** mutation root */
export type Mutation_RootUpdateChannelUserPermissionsArgs = {
  channelId: Scalars['uuid']['input'];
  handRaised?: InputMaybe<Scalars['Boolean']['input']>;
  host?: InputMaybe<Scalars['Boolean']['input']>;
  microphone?: InputMaybe<Scalars['Boolean']['input']>;
  screenShare?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['String']['input'];
  volume?: InputMaybe<Scalars['Int']['input']>;
  webCam?: InputMaybe<Scalars['Boolean']['input']>;
};


/** mutation root */
export type Mutation_RootUpdateChannelsUserForceMutedArgs = {
  channelId: Scalars['uuid']['input'];
  forceMuted: Scalars['Boolean']['input'];
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdateHandRaisedArgs = {
  channelId: Scalars['uuid']['input'];
  force?: InputMaybe<Scalars['Boolean']['input']>;
  handRaised: Scalars['Boolean']['input'];
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdatePasswordArgs = {
  password: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdateUserClubPreferencesArgs = {
  clubId: Scalars['uuid']['input'];
  device?: InputMaybe<Scalars['String']['input']>;
  gameMode?: InputMaybe<Scalars['String']['input']>;
  orientationDate?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpdate_AccountsArgs = {
  _append?: InputMaybe<Accounts_Append_Input>;
  _delete_at_path?: InputMaybe<Accounts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Accounts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Accounts_Delete_Key_Input>;
  _prepend?: InputMaybe<Accounts_Prepend_Input>;
  _set?: InputMaybe<Accounts_Set_Input>;
  where: Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_By_PkArgs = {
  _append?: InputMaybe<Accounts_Append_Input>;
  _delete_at_path?: InputMaybe<Accounts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Accounts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Accounts_Delete_Key_Input>;
  _prepend?: InputMaybe<Accounts_Prepend_Input>;
  _set?: InputMaybe<Accounts_Set_Input>;
  pk_columns: Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_ManyArgs = {
  updates: ReadonlyArray<Accounts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Asa_User_Quest_TaskArgs = {
  _inc?: InputMaybe<Asa_User_Quest_Task_Inc_Input>;
  _set?: InputMaybe<Asa_User_Quest_Task_Set_Input>;
  where: Asa_User_Quest_Task_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Asa_User_Quest_Task_By_PkArgs = {
  _inc?: InputMaybe<Asa_User_Quest_Task_Inc_Input>;
  _set?: InputMaybe<Asa_User_Quest_Task_Set_Input>;
  pk_columns: Asa_User_Quest_Task_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Asa_User_Quest_Task_ManyArgs = {
  updates: ReadonlyArray<Asa_User_Quest_Task_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AvatarsArgs = {
  _set?: InputMaybe<Avatars_Set_Input>;
  where: Avatars_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Avatars_By_PkArgs = {
  _set?: InputMaybe<Avatars_Set_Input>;
  pk_columns: Avatars_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Avatars_ManyArgs = {
  updates: ReadonlyArray<Avatars_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BadgesArgs = {
  _inc?: InputMaybe<Badges_Inc_Input>;
  _set?: InputMaybe<Badges_Set_Input>;
  where: Badges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Badges_By_PkArgs = {
  _inc?: InputMaybe<Badges_Inc_Input>;
  _set?: InputMaybe<Badges_Set_Input>;
  pk_columns: Badges_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Badges_ManyArgs = {
  updates: ReadonlyArray<Badges_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BookingsArgs = {
  _append?: InputMaybe<Bookings_Append_Input>;
  _delete_at_path?: InputMaybe<Bookings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Bookings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Bookings_Delete_Key_Input>;
  _prepend?: InputMaybe<Bookings_Prepend_Input>;
  _set?: InputMaybe<Bookings_Set_Input>;
  where: Bookings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bookings_By_PkArgs = {
  _append?: InputMaybe<Bookings_Append_Input>;
  _delete_at_path?: InputMaybe<Bookings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Bookings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Bookings_Delete_Key_Input>;
  _prepend?: InputMaybe<Bookings_Prepend_Input>;
  _set?: InputMaybe<Bookings_Set_Input>;
  pk_columns: Bookings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bookings_ManyArgs = {
  updates: ReadonlyArray<Bookings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BreakoutsArgs = {
  _append?: InputMaybe<Breakouts_Append_Input>;
  _delete_at_path?: InputMaybe<Breakouts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Breakouts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Breakouts_Delete_Key_Input>;
  _prepend?: InputMaybe<Breakouts_Prepend_Input>;
  _set?: InputMaybe<Breakouts_Set_Input>;
  where: Breakouts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Breakouts_By_PkArgs = {
  _append?: InputMaybe<Breakouts_Append_Input>;
  _delete_at_path?: InputMaybe<Breakouts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Breakouts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Breakouts_Delete_Key_Input>;
  _prepend?: InputMaybe<Breakouts_Prepend_Input>;
  _set?: InputMaybe<Breakouts_Set_Input>;
  pk_columns: Breakouts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Breakouts_ManyArgs = {
  updates: ReadonlyArray<Breakouts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Build_BattlesArgs = {
  _set?: InputMaybe<Build_Battles_Set_Input>;
  where: Build_Battles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Build_Battles_By_PkArgs = {
  _set?: InputMaybe<Build_Battles_Set_Input>;
  pk_columns: Build_Battles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Build_Battles_ManyArgs = {
  updates: ReadonlyArray<Build_Battles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Build_Battles_ProjectsArgs = {
  _set?: InputMaybe<Build_Battles_Projects_Set_Input>;
  where: Build_Battles_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Build_Battles_Projects_By_PkArgs = {
  _set?: InputMaybe<Build_Battles_Projects_Set_Input>;
  pk_columns: Build_Battles_Projects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Build_Battles_Projects_ManyArgs = {
  updates: ReadonlyArray<Build_Battles_Projects_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Build_Battles_ThemesArgs = {
  _append?: InputMaybe<Build_Battles_Themes_Append_Input>;
  _delete_at_path?: InputMaybe<Build_Battles_Themes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Build_Battles_Themes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Build_Battles_Themes_Delete_Key_Input>;
  _prepend?: InputMaybe<Build_Battles_Themes_Prepend_Input>;
  _set?: InputMaybe<Build_Battles_Themes_Set_Input>;
  where: Build_Battles_Themes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Build_Battles_Themes_By_PkArgs = {
  _append?: InputMaybe<Build_Battles_Themes_Append_Input>;
  _delete_at_path?: InputMaybe<Build_Battles_Themes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Build_Battles_Themes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Build_Battles_Themes_Delete_Key_Input>;
  _prepend?: InputMaybe<Build_Battles_Themes_Prepend_Input>;
  _set?: InputMaybe<Build_Battles_Themes_Set_Input>;
  pk_columns: Build_Battles_Themes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Build_Battles_Themes_ManyArgs = {
  updates: ReadonlyArray<Build_Battles_Themes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Channel_Mute_EnabledArgs = {
  args: Update_Channel_Mute_Enabled_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootUpdate_ChannelsArgs = {
  _append?: InputMaybe<Channels_Append_Input>;
  _delete_at_path?: InputMaybe<Channels_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Channels_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Channels_Delete_Key_Input>;
  _prepend?: InputMaybe<Channels_Prepend_Input>;
  _set?: InputMaybe<Channels_Set_Input>;
  where: Channels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_By_PkArgs = {
  _append?: InputMaybe<Channels_Append_Input>;
  _delete_at_path?: InputMaybe<Channels_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Channels_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Channels_Delete_Key_Input>;
  _prepend?: InputMaybe<Channels_Prepend_Input>;
  _set?: InputMaybe<Channels_Set_Input>;
  pk_columns: Channels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_EventsArgs = {
  _append?: InputMaybe<Channels_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Channels_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Channels_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Channels_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Channels_Events_Prepend_Input>;
  _set?: InputMaybe<Channels_Events_Set_Input>;
  where: Channels_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_Events_By_PkArgs = {
  _append?: InputMaybe<Channels_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Channels_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Channels_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Channels_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Channels_Events_Prepend_Input>;
  _set?: InputMaybe<Channels_Events_Set_Input>;
  pk_columns: Channels_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_Events_ManyArgs = {
  updates: ReadonlyArray<Channels_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_ManyArgs = {
  updates: ReadonlyArray<Channels_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_UsersArgs = {
  _append?: InputMaybe<Channels_Users_Append_Input>;
  _delete_at_path?: InputMaybe<Channels_Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Channels_Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Channels_Users_Delete_Key_Input>;
  _inc?: InputMaybe<Channels_Users_Inc_Input>;
  _prepend?: InputMaybe<Channels_Users_Prepend_Input>;
  _set?: InputMaybe<Channels_Users_Set_Input>;
  where: Channels_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_Users_By_PkArgs = {
  _append?: InputMaybe<Channels_Users_Append_Input>;
  _delete_at_path?: InputMaybe<Channels_Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Channels_Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Channels_Users_Delete_Key_Input>;
  _inc?: InputMaybe<Channels_Users_Inc_Input>;
  _prepend?: InputMaybe<Channels_Users_Prepend_Input>;
  _set?: InputMaybe<Channels_Users_Set_Input>;
  pk_columns: Channels_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_Users_ManyArgs = {
  updates: ReadonlyArray<Channels_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ChatsArgs = {
  _inc?: InputMaybe<Chats_Inc_Input>;
  _set?: InputMaybe<Chats_Set_Input>;
  where: Chats_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Chats_By_PkArgs = {
  _inc?: InputMaybe<Chats_Inc_Input>;
  _set?: InputMaybe<Chats_Set_Input>;
  pk_columns: Chats_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Chats_ManyArgs = {
  updates: ReadonlyArray<Chats_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Club_Schedule_Lock_Unlock_TimesArgs = {
  args?: InputMaybe<Update_Club_Schedule_Lock_Unlock_Times_Args>;
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Order_By>>;
  where?: InputMaybe<Clubs_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootUpdate_Club_StatusArgs = {
  args: Update_Club_Status_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Order_By>>;
  where?: InputMaybe<Clubs_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootUpdate_ClubsArgs = {
  _append?: InputMaybe<Clubs_Append_Input>;
  _delete_at_path?: InputMaybe<Clubs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Clubs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Clubs_Delete_Key_Input>;
  _prepend?: InputMaybe<Clubs_Prepend_Input>;
  _set?: InputMaybe<Clubs_Set_Input>;
  where: Clubs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Clubs_By_PkArgs = {
  _append?: InputMaybe<Clubs_Append_Input>;
  _delete_at_path?: InputMaybe<Clubs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Clubs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Clubs_Delete_Key_Input>;
  _prepend?: InputMaybe<Clubs_Prepend_Input>;
  _set?: InputMaybe<Clubs_Set_Input>;
  pk_columns: Clubs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Clubs_ManyArgs = {
  updates: ReadonlyArray<Clubs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Clubs_MembersArgs = {
  _append?: InputMaybe<Clubs_Members_Append_Input>;
  _delete_at_path?: InputMaybe<Clubs_Members_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Clubs_Members_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Clubs_Members_Delete_Key_Input>;
  _prepend?: InputMaybe<Clubs_Members_Prepend_Input>;
  _set?: InputMaybe<Clubs_Members_Set_Input>;
  where: Clubs_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Clubs_Members_By_PkArgs = {
  _append?: InputMaybe<Clubs_Members_Append_Input>;
  _delete_at_path?: InputMaybe<Clubs_Members_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Clubs_Members_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Clubs_Members_Delete_Key_Input>;
  _prepend?: InputMaybe<Clubs_Members_Prepend_Input>;
  _set?: InputMaybe<Clubs_Members_Set_Input>;
  pk_columns: Clubs_Members_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Clubs_Members_ManyArgs = {
  updates: ReadonlyArray<Clubs_Members_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CommentsArgs = {
  _set?: InputMaybe<Comments_Set_Input>;
  where: Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Comments_By_PkArgs = {
  _set?: InputMaybe<Comments_Set_Input>;
  pk_columns: Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Comments_ManyArgs = {
  updates: ReadonlyArray<Comments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Comments_VotesArgs = {
  _set?: InputMaybe<Comments_Votes_Set_Input>;
  where: Comments_Votes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Comments_Votes_By_PkArgs = {
  _set?: InputMaybe<Comments_Votes_Set_Input>;
  pk_columns: Comments_Votes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Comments_Votes_ManyArgs = {
  updates: ReadonlyArray<Comments_Votes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompetitionsArgs = {
  _append?: InputMaybe<Competitions_Append_Input>;
  _delete_at_path?: InputMaybe<Competitions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Competitions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Competitions_Delete_Key_Input>;
  _inc?: InputMaybe<Competitions_Inc_Input>;
  _prepend?: InputMaybe<Competitions_Prepend_Input>;
  _set?: InputMaybe<Competitions_Set_Input>;
  where: Competitions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Competitions_By_PkArgs = {
  _append?: InputMaybe<Competitions_Append_Input>;
  _delete_at_path?: InputMaybe<Competitions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Competitions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Competitions_Delete_Key_Input>;
  _inc?: InputMaybe<Competitions_Inc_Input>;
  _prepend?: InputMaybe<Competitions_Prepend_Input>;
  _set?: InputMaybe<Competitions_Set_Input>;
  pk_columns: Competitions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Competitions_JudgesArgs = {
  _set?: InputMaybe<Competitions_Judges_Set_Input>;
  where: Competitions_Judges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Competitions_Judges_By_PkArgs = {
  _set?: InputMaybe<Competitions_Judges_Set_Input>;
  pk_columns: Competitions_Judges_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Competitions_Judges_ManyArgs = {
  updates: ReadonlyArray<Competitions_Judges_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Competitions_ManyArgs = {
  updates: ReadonlyArray<Competitions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Competitions_TagsArgs = {
  _set?: InputMaybe<Competitions_Tags_Set_Input>;
  where: Competitions_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Competitions_Tags_By_PkArgs = {
  _set?: InputMaybe<Competitions_Tags_Set_Input>;
  pk_columns: Competitions_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Competitions_Tags_ManyArgs = {
  updates: ReadonlyArray<Competitions_Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Competitions_TutorialsArgs = {
  _set?: InputMaybe<Competitions_Tutorials_Set_Input>;
  where: Competitions_Tutorials_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Competitions_Tutorials_By_PkArgs = {
  _set?: InputMaybe<Competitions_Tutorials_Set_Input>;
  pk_columns: Competitions_Tutorials_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Competitions_Tutorials_ManyArgs = {
  updates: ReadonlyArray<Competitions_Tutorials_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Eban_CommentsArgs = {
  _inc?: InputMaybe<Eban_Comments_Inc_Input>;
  _set?: InputMaybe<Eban_Comments_Set_Input>;
  where: Eban_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Eban_Comments_By_PkArgs = {
  _inc?: InputMaybe<Eban_Comments_Inc_Input>;
  _set?: InputMaybe<Eban_Comments_Set_Input>;
  pk_columns: Eban_Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Eban_Comments_ManyArgs = {
  updates: ReadonlyArray<Eban_Comments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Eban_TypesArgs = {
  _inc?: InputMaybe<Eban_Types_Inc_Input>;
  _set?: InputMaybe<Eban_Types_Set_Input>;
  where: Eban_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Eban_Types_By_PkArgs = {
  _inc?: InputMaybe<Eban_Types_Inc_Input>;
  _set?: InputMaybe<Eban_Types_Set_Input>;
  pk_columns: Eban_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Eban_Types_ManyArgs = {
  updates: ReadonlyArray<Eban_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EbansArgs = {
  _inc?: InputMaybe<Ebans_Inc_Input>;
  _set?: InputMaybe<Ebans_Set_Input>;
  where: Ebans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ebans_By_PkArgs = {
  _inc?: InputMaybe<Ebans_Inc_Input>;
  _set?: InputMaybe<Ebans_Set_Input>;
  pk_columns: Ebans_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ebans_ManyArgs = {
  updates: ReadonlyArray<Ebans_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EnvironmentArgs = {
  _set?: InputMaybe<Environment_Set_Input>;
  where: Environment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Environment_By_PkArgs = {
  _set?: InputMaybe<Environment_Set_Input>;
  pk_columns: Environment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Environment_ManyArgs = {
  updates: ReadonlyArray<Environment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FeaturesArgs = {
  _set?: InputMaybe<Features_Set_Input>;
  where: Features_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Features_By_PkArgs = {
  _set?: InputMaybe<Features_Set_Input>;
  pk_columns: Features_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Features_ManyArgs = {
  updates: ReadonlyArray<Features_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Features_ProductsArgs = {
  _set?: InputMaybe<Features_Products_Set_Input>;
  where: Features_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Features_Products_By_PkArgs = {
  _set?: InputMaybe<Features_Products_Set_Input>;
  pk_columns: Features_Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Features_Products_ManyArgs = {
  updates: ReadonlyArray<Features_Products_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FeedbackArgs = {
  _set?: InputMaybe<Feedback_Set_Input>;
  where: Feedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Feedback_By_PkArgs = {
  _set?: InputMaybe<Feedback_Set_Input>;
  pk_columns: Feedback_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Feedback_ManyArgs = {
  updates: ReadonlyArray<Feedback_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FeedsArgs = {
  _append?: InputMaybe<Feeds_Append_Input>;
  _delete_at_path?: InputMaybe<Feeds_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Feeds_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Feeds_Delete_Key_Input>;
  _prepend?: InputMaybe<Feeds_Prepend_Input>;
  _set?: InputMaybe<Feeds_Set_Input>;
  where: Feeds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Feeds_By_PkArgs = {
  _append?: InputMaybe<Feeds_Append_Input>;
  _delete_at_path?: InputMaybe<Feeds_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Feeds_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Feeds_Delete_Key_Input>;
  _prepend?: InputMaybe<Feeds_Prepend_Input>;
  _set?: InputMaybe<Feeds_Set_Input>;
  pk_columns: Feeds_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Feeds_ManyArgs = {
  updates: ReadonlyArray<Feeds_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FollowsArgs = {
  _set?: InputMaybe<Follows_Set_Input>;
  where: Follows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Follows_By_PkArgs = {
  _set?: InputMaybe<Follows_Set_Input>;
  pk_columns: Follows_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Follows_ManyArgs = {
  updates: ReadonlyArray<Follows_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FriendsArgs = {
  _inc?: InputMaybe<Friends_Inc_Input>;
  _set?: InputMaybe<Friends_Set_Input>;
  where: Friends_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Friends_By_PkArgs = {
  _inc?: InputMaybe<Friends_Inc_Input>;
  _set?: InputMaybe<Friends_Set_Input>;
  pk_columns: Friends_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Friends_ManyArgs = {
  updates: ReadonlyArray<Friends_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FriendshipsArgs = {
  _set?: InputMaybe<Friendships_Set_Input>;
  where: Friendships_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Friendships_By_PkArgs = {
  _set?: InputMaybe<Friendships_Set_Input>;
  pk_columns: Friendships_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Friendships_ManyArgs = {
  updates: ReadonlyArray<Friendships_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Friendships_UsersArgs = {
  _set?: InputMaybe<Friendships_Users_Set_Input>;
  where: Friendships_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Friendships_Users_By_PkArgs = {
  _set?: InputMaybe<Friendships_Users_Set_Input>;
  pk_columns: Friendships_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Friendships_Users_ManyArgs = {
  updates: ReadonlyArray<Friendships_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Game_ServersArgs = {
  _append?: InputMaybe<Game_Servers_Append_Input>;
  _delete_at_path?: InputMaybe<Game_Servers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Game_Servers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Game_Servers_Delete_Key_Input>;
  _inc?: InputMaybe<Game_Servers_Inc_Input>;
  _prepend?: InputMaybe<Game_Servers_Prepend_Input>;
  _set?: InputMaybe<Game_Servers_Set_Input>;
  where: Game_Servers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Game_Servers_By_PkArgs = {
  _append?: InputMaybe<Game_Servers_Append_Input>;
  _delete_at_path?: InputMaybe<Game_Servers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Game_Servers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Game_Servers_Delete_Key_Input>;
  _inc?: InputMaybe<Game_Servers_Inc_Input>;
  _prepend?: InputMaybe<Game_Servers_Prepend_Input>;
  _set?: InputMaybe<Game_Servers_Set_Input>;
  pk_columns: Game_Servers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Game_Servers_ManyArgs = {
  updates: ReadonlyArray<Game_Servers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_HomesArgs = {
  _append?: InputMaybe<Homes_Append_Input>;
  _delete_at_path?: InputMaybe<Homes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Homes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Homes_Delete_Key_Input>;
  _prepend?: InputMaybe<Homes_Prepend_Input>;
  _set?: InputMaybe<Homes_Set_Input>;
  where: Homes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Homes_By_PkArgs = {
  _append?: InputMaybe<Homes_Append_Input>;
  _delete_at_path?: InputMaybe<Homes_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Homes_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Homes_Delete_Key_Input>;
  _prepend?: InputMaybe<Homes_Prepend_Input>;
  _set?: InputMaybe<Homes_Set_Input>;
  pk_columns: Homes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Homes_ManyArgs = {
  updates: ReadonlyArray<Homes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Image_CacheArgs = {
  _inc?: InputMaybe<Image_Cache_Inc_Input>;
  _set?: InputMaybe<Image_Cache_Set_Input>;
  where: Image_Cache_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Image_Cache_By_PkArgs = {
  _inc?: InputMaybe<Image_Cache_Inc_Input>;
  _set?: InputMaybe<Image_Cache_Set_Input>;
  pk_columns: Image_Cache_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Image_Cache_ManyArgs = {
  updates: ReadonlyArray<Image_Cache_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Infrastructure_OperationsArgs = {
  _append?: InputMaybe<Infrastructure_Operations_Append_Input>;
  _delete_at_path?: InputMaybe<Infrastructure_Operations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Infrastructure_Operations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Infrastructure_Operations_Delete_Key_Input>;
  _prepend?: InputMaybe<Infrastructure_Operations_Prepend_Input>;
  _set?: InputMaybe<Infrastructure_Operations_Set_Input>;
  where: Infrastructure_Operations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Infrastructure_Operations_ManyArgs = {
  updates: ReadonlyArray<Infrastructure_Operations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ItemsArgs = {
  _append?: InputMaybe<Items_Append_Input>;
  _delete_at_path?: InputMaybe<Items_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Items_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Items_Delete_Key_Input>;
  _inc?: InputMaybe<Items_Inc_Input>;
  _prepend?: InputMaybe<Items_Prepend_Input>;
  _set?: InputMaybe<Items_Set_Input>;
  where: Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_By_PkArgs = {
  _append?: InputMaybe<Items_Append_Input>;
  _delete_at_path?: InputMaybe<Items_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Items_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Items_Delete_Key_Input>;
  _inc?: InputMaybe<Items_Inc_Input>;
  _prepend?: InputMaybe<Items_Prepend_Input>;
  _set?: InputMaybe<Items_Set_Input>;
  pk_columns: Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_ManyArgs = {
  updates: ReadonlyArray<Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Items_TagsArgs = {
  _set?: InputMaybe<Items_Tags_Set_Input>;
  where: Items_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Tags_By_PkArgs = {
  _set?: InputMaybe<Items_Tags_Set_Input>;
  pk_columns: Items_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Tags_ManyArgs = {
  updates: ReadonlyArray<Items_Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LevelsArgs = {
  _append?: InputMaybe<Levels_Append_Input>;
  _delete_at_path?: InputMaybe<Levels_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Levels_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Levels_Delete_Key_Input>;
  _inc?: InputMaybe<Levels_Inc_Input>;
  _prepend?: InputMaybe<Levels_Prepend_Input>;
  _set?: InputMaybe<Levels_Set_Input>;
  where: Levels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Levels_By_PkArgs = {
  _append?: InputMaybe<Levels_Append_Input>;
  _delete_at_path?: InputMaybe<Levels_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Levels_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Levels_Delete_Key_Input>;
  _inc?: InputMaybe<Levels_Inc_Input>;
  _prepend?: InputMaybe<Levels_Prepend_Input>;
  _set?: InputMaybe<Levels_Set_Input>;
  pk_columns: Levels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Levels_ManyArgs = {
  updates: ReadonlyArray<Levels_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Live_EventsArgs = {
  _append?: InputMaybe<Live_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Live_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Live_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Live_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Live_Events_Prepend_Input>;
  _set?: InputMaybe<Live_Events_Set_Input>;
  where: Live_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Live_Events_By_PkArgs = {
  _append?: InputMaybe<Live_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Live_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Live_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Live_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Live_Events_Prepend_Input>;
  _set?: InputMaybe<Live_Events_Set_Input>;
  pk_columns: Live_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Live_Events_HostsArgs = {
  _set?: InputMaybe<Live_Events_Hosts_Set_Input>;
  where: Live_Events_Hosts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Live_Events_Hosts_By_PkArgs = {
  _set?: InputMaybe<Live_Events_Hosts_Set_Input>;
  pk_columns: Live_Events_Hosts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Live_Events_Hosts_ManyArgs = {
  updates: ReadonlyArray<Live_Events_Hosts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Live_Events_ManyArgs = {
  updates: ReadonlyArray<Live_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Live_Events_ScheduleArgs = {
  args?: InputMaybe<Update_Live_Events_Schedule_Args>;
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Order_By>>;
  where?: InputMaybe<Live_Events_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootUpdate_MessagesArgs = {
  _append?: InputMaybe<Messages_Append_Input>;
  _delete_at_path?: InputMaybe<Messages_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Messages_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Messages_Delete_Key_Input>;
  _prepend?: InputMaybe<Messages_Prepend_Input>;
  _set?: InputMaybe<Messages_Set_Input>;
  where: Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Messages_By_PkArgs = {
  _append?: InputMaybe<Messages_Append_Input>;
  _delete_at_path?: InputMaybe<Messages_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Messages_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Messages_Delete_Key_Input>;
  _prepend?: InputMaybe<Messages_Prepend_Input>;
  _set?: InputMaybe<Messages_Set_Input>;
  pk_columns: Messages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Messages_ManyArgs = {
  updates: ReadonlyArray<Messages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Minecraft_AnnouncementArgs = {
  _append?: InputMaybe<Minecraft_Announcement_Append_Input>;
  _delete_at_path?: InputMaybe<Minecraft_Announcement_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Minecraft_Announcement_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Minecraft_Announcement_Delete_Key_Input>;
  _prepend?: InputMaybe<Minecraft_Announcement_Prepend_Input>;
  _set?: InputMaybe<Minecraft_Announcement_Set_Input>;
  where: Minecraft_Announcement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Minecraft_Announcement_By_PkArgs = {
  _append?: InputMaybe<Minecraft_Announcement_Append_Input>;
  _delete_at_path?: InputMaybe<Minecraft_Announcement_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Minecraft_Announcement_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Minecraft_Announcement_Delete_Key_Input>;
  _prepend?: InputMaybe<Minecraft_Announcement_Prepend_Input>;
  _set?: InputMaybe<Minecraft_Announcement_Set_Input>;
  pk_columns: Minecraft_Announcement_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Minecraft_Announcement_ManyArgs = {
  updates: ReadonlyArray<Minecraft_Announcement_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Minecraft_ServersArgs = {
  _set?: InputMaybe<Minecraft_Servers_Set_Input>;
  where: Minecraft_Servers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Minecraft_Servers_By_PkArgs = {
  _set?: InputMaybe<Minecraft_Servers_Set_Input>;
  pk_columns: Minecraft_Servers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Minecraft_Servers_ManyArgs = {
  updates: ReadonlyArray<Minecraft_Servers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Minecraft_SkinsArgs = {
  _set?: InputMaybe<Minecraft_Skins_Set_Input>;
  where: Minecraft_Skins_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Minecraft_Skins_By_PkArgs = {
  _set?: InputMaybe<Minecraft_Skins_Set_Input>;
  pk_columns: Minecraft_Skins_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Minecraft_Skins_ManyArgs = {
  updates: ReadonlyArray<Minecraft_Skins_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Minecraft_TutorialArgs = {
  _inc?: InputMaybe<Minecraft_Tutorial_Inc_Input>;
  _set?: InputMaybe<Minecraft_Tutorial_Set_Input>;
  where: Minecraft_Tutorial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Minecraft_Tutorial_By_PkArgs = {
  _inc?: InputMaybe<Minecraft_Tutorial_Inc_Input>;
  _set?: InputMaybe<Minecraft_Tutorial_Set_Input>;
  pk_columns: Minecraft_Tutorial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Minecraft_Tutorial_ManyArgs = {
  updates: ReadonlyArray<Minecraft_Tutorial_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Minecraft_Tutorial_StatusArgs = {
  _inc?: InputMaybe<Minecraft_Tutorial_Status_Inc_Input>;
  _set?: InputMaybe<Minecraft_Tutorial_Status_Set_Input>;
  where: Minecraft_Tutorial_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Minecraft_Tutorial_Status_By_PkArgs = {
  _inc?: InputMaybe<Minecraft_Tutorial_Status_Inc_Input>;
  _set?: InputMaybe<Minecraft_Tutorial_Status_Set_Input>;
  pk_columns: Minecraft_Tutorial_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Minecraft_Tutorial_Status_ManyArgs = {
  updates: ReadonlyArray<Minecraft_Tutorial_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_NotificationsArgs = {
  _append?: InputMaybe<Notifications_Append_Input>;
  _delete_at_path?: InputMaybe<Notifications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Notifications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Notifications_Delete_Key_Input>;
  _prepend?: InputMaybe<Notifications_Prepend_Input>;
  _set?: InputMaybe<Notifications_Set_Input>;
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_By_PkArgs = {
  _append?: InputMaybe<Notifications_Append_Input>;
  _delete_at_path?: InputMaybe<Notifications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Notifications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Notifications_Delete_Key_Input>;
  _prepend?: InputMaybe<Notifications_Prepend_Input>;
  _set?: InputMaybe<Notifications_Set_Input>;
  pk_columns: Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_ManyArgs = {
  updates: ReadonlyArray<Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OrdersArgs = {
  _set?: InputMaybe<Orders_Set_Input>;
  where: Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Orders_By_PkArgs = {
  _set?: InputMaybe<Orders_Set_Input>;
  pk_columns: Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Orders_ManyArgs = {
  updates: ReadonlyArray<Orders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OtpsArgs = {
  _append?: InputMaybe<Otps_Append_Input>;
  _delete_at_path?: InputMaybe<Otps_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Otps_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Otps_Delete_Key_Input>;
  _prepend?: InputMaybe<Otps_Prepend_Input>;
  _set?: InputMaybe<Otps_Set_Input>;
  where: Otps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Otps_By_PkArgs = {
  _append?: InputMaybe<Otps_Append_Input>;
  _delete_at_path?: InputMaybe<Otps_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Otps_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Otps_Delete_Key_Input>;
  _prepend?: InputMaybe<Otps_Prepend_Input>;
  _set?: InputMaybe<Otps_Set_Input>;
  pk_columns: Otps_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Otps_ManyArgs = {
  updates: ReadonlyArray<Otps_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PagesArgs = {
  _set?: InputMaybe<Pages_Set_Input>;
  where: Pages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pages_By_PkArgs = {
  _set?: InputMaybe<Pages_Set_Input>;
  pk_columns: Pages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pages_ManyArgs = {
  updates: ReadonlyArray<Pages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Player_WorldsArgs = {
  _set?: InputMaybe<Player_Worlds_Set_Input>;
  where: Player_Worlds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Player_Worlds_By_PkArgs = {
  _set?: InputMaybe<Player_Worlds_Set_Input>;
  pk_columns: Player_Worlds_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Player_Worlds_ManyArgs = {
  updates: ReadonlyArray<Player_Worlds_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PostsArgs = {
  _set?: InputMaybe<Posts_Set_Input>;
  where: Posts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Posts_By_PkArgs = {
  _set?: InputMaybe<Posts_Set_Input>;
  pk_columns: Posts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Posts_ManyArgs = {
  updates: ReadonlyArray<Posts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectsArgs = {
  _append?: InputMaybe<Projects_Append_Input>;
  _delete_at_path?: InputMaybe<Projects_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Projects_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Projects_Delete_Key_Input>;
  _inc?: InputMaybe<Projects_Inc_Input>;
  _prepend?: InputMaybe<Projects_Prepend_Input>;
  _set?: InputMaybe<Projects_Set_Input>;
  where: Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_By_PkArgs = {
  _append?: InputMaybe<Projects_Append_Input>;
  _delete_at_path?: InputMaybe<Projects_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Projects_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Projects_Delete_Key_Input>;
  _inc?: InputMaybe<Projects_Inc_Input>;
  _prepend?: InputMaybe<Projects_Prepend_Input>;
  _set?: InputMaybe<Projects_Set_Input>;
  pk_columns: Projects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_CollaboratorsArgs = {
  _set?: InputMaybe<Projects_Collaborators_Set_Input>;
  where: Projects_Collaborators_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_Collaborators_By_PkArgs = {
  _set?: InputMaybe<Projects_Collaborators_Set_Input>;
  pk_columns: Projects_Collaborators_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_Collaborators_ManyArgs = {
  updates: ReadonlyArray<Projects_Collaborators_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_EditablesArgs = {
  _append?: InputMaybe<Projects_Editables_Append_Input>;
  _delete_at_path?: InputMaybe<Projects_Editables_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Projects_Editables_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Projects_Editables_Delete_Key_Input>;
  _prepend?: InputMaybe<Projects_Editables_Prepend_Input>;
  _set?: InputMaybe<Projects_Editables_Set_Input>;
  where: Projects_Editables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_Editables_ManyArgs = {
  updates: ReadonlyArray<Projects_Editables_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_LeaderboardsArgs = {
  _inc?: InputMaybe<Projects_Leaderboards_Inc_Input>;
  _set?: InputMaybe<Projects_Leaderboards_Set_Input>;
  where: Projects_Leaderboards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_Leaderboards_By_PkArgs = {
  _inc?: InputMaybe<Projects_Leaderboards_Inc_Input>;
  _set?: InputMaybe<Projects_Leaderboards_Set_Input>;
  pk_columns: Projects_Leaderboards_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_Leaderboards_ManyArgs = {
  updates: ReadonlyArray<Projects_Leaderboards_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_ManyArgs = {
  updates: ReadonlyArray<Projects_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_PublicationsArgs = {
  _inc?: InputMaybe<Projects_Publications_Inc_Input>;
  _set?: InputMaybe<Projects_Publications_Set_Input>;
  where: Projects_Publications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_Publications_By_PkArgs = {
  _inc?: InputMaybe<Projects_Publications_Inc_Input>;
  _set?: InputMaybe<Projects_Publications_Set_Input>;
  pk_columns: Projects_Publications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_Publications_ManyArgs = {
  updates: ReadonlyArray<Projects_Publications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_Publications_VotesArgs = {
  _inc?: InputMaybe<Projects_Publications_Votes_Inc_Input>;
  _set?: InputMaybe<Projects_Publications_Votes_Set_Input>;
  where: Projects_Publications_Votes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_Publications_Votes_By_PkArgs = {
  _inc?: InputMaybe<Projects_Publications_Votes_Inc_Input>;
  _set?: InputMaybe<Projects_Publications_Votes_Set_Input>;
  pk_columns: Projects_Publications_Votes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_Publications_Votes_ManyArgs = {
  updates: ReadonlyArray<Projects_Publications_Votes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_TemplatesArgs = {
  _append?: InputMaybe<Projects_Templates_Append_Input>;
  _delete_at_path?: InputMaybe<Projects_Templates_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Projects_Templates_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Projects_Templates_Delete_Key_Input>;
  _prepend?: InputMaybe<Projects_Templates_Prepend_Input>;
  _set?: InputMaybe<Projects_Templates_Set_Input>;
  where: Projects_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_Templates_By_PkArgs = {
  _append?: InputMaybe<Projects_Templates_Append_Input>;
  _delete_at_path?: InputMaybe<Projects_Templates_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Projects_Templates_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Projects_Templates_Delete_Key_Input>;
  _prepend?: InputMaybe<Projects_Templates_Prepend_Input>;
  _set?: InputMaybe<Projects_Templates_Set_Input>;
  pk_columns: Projects_Templates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_Templates_ManyArgs = {
  updates: ReadonlyArray<Projects_Templates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Prospective_UsersArgs = {
  _inc?: InputMaybe<Prospective_Users_Inc_Input>;
  _set?: InputMaybe<Prospective_Users_Set_Input>;
  where: Prospective_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Prospective_Users_By_PkArgs = {
  _inc?: InputMaybe<Prospective_Users_Inc_Input>;
  _set?: InputMaybe<Prospective_Users_Set_Input>;
  pk_columns: Prospective_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Prospective_Users_ManyArgs = {
  updates: ReadonlyArray<Prospective_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Prospective_Users_PurchasesArgs = {
  _set?: InputMaybe<Prospective_Users_Purchases_Set_Input>;
  where: Prospective_Users_Purchases_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Prospective_Users_Purchases_By_PkArgs = {
  _set?: InputMaybe<Prospective_Users_Purchases_Set_Input>;
  pk_columns: Prospective_Users_Purchases_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Prospective_Users_Purchases_ManyArgs = {
  updates: ReadonlyArray<Prospective_Users_Purchases_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_QuestsArgs = {
  _append?: InputMaybe<Quests_Append_Input>;
  _delete_at_path?: InputMaybe<Quests_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Quests_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Quests_Delete_Key_Input>;
  _inc?: InputMaybe<Quests_Inc_Input>;
  _prepend?: InputMaybe<Quests_Prepend_Input>;
  _set?: InputMaybe<Quests_Set_Input>;
  where: Quests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Quests_By_PkArgs = {
  _append?: InputMaybe<Quests_Append_Input>;
  _delete_at_path?: InputMaybe<Quests_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Quests_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Quests_Delete_Key_Input>;
  _inc?: InputMaybe<Quests_Inc_Input>;
  _prepend?: InputMaybe<Quests_Prepend_Input>;
  _set?: InputMaybe<Quests_Set_Input>;
  pk_columns: Quests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Quests_ManyArgs = {
  updates: ReadonlyArray<Quests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Queue_User_InfoArgs = {
  _inc?: InputMaybe<Queue_User_Info_Inc_Input>;
  _set?: InputMaybe<Queue_User_Info_Set_Input>;
  where: Queue_User_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Queue_User_Info_By_PkArgs = {
  _inc?: InputMaybe<Queue_User_Info_Inc_Input>;
  _set?: InputMaybe<Queue_User_Info_Set_Input>;
  pk_columns: Queue_User_Info_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Queue_User_Info_ManyArgs = {
  updates: ReadonlyArray<Queue_User_Info_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_QueuesArgs = {
  _inc?: InputMaybe<Queues_Inc_Input>;
  _set?: InputMaybe<Queues_Set_Input>;
  where: Queues_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Queues_By_PkArgs = {
  _inc?: InputMaybe<Queues_Inc_Input>;
  _set?: InputMaybe<Queues_Set_Input>;
  pk_columns: Queues_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Queues_ManyArgs = {
  updates: ReadonlyArray<Queues_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Render_JobsArgs = {
  _append?: InputMaybe<Render_Jobs_Append_Input>;
  _delete_at_path?: InputMaybe<Render_Jobs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Render_Jobs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Render_Jobs_Delete_Key_Input>;
  _inc?: InputMaybe<Render_Jobs_Inc_Input>;
  _prepend?: InputMaybe<Render_Jobs_Prepend_Input>;
  _set?: InputMaybe<Render_Jobs_Set_Input>;
  where: Render_Jobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Render_Jobs_By_PkArgs = {
  _append?: InputMaybe<Render_Jobs_Append_Input>;
  _delete_at_path?: InputMaybe<Render_Jobs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Render_Jobs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Render_Jobs_Delete_Key_Input>;
  _inc?: InputMaybe<Render_Jobs_Inc_Input>;
  _prepend?: InputMaybe<Render_Jobs_Prepend_Input>;
  _set?: InputMaybe<Render_Jobs_Set_Input>;
  pk_columns: Render_Jobs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Render_Jobs_ManyArgs = {
  updates: ReadonlyArray<Render_Jobs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReportsArgs = {
  _set?: InputMaybe<Reports_Set_Input>;
  where: Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reports_By_PkArgs = {
  _set?: InputMaybe<Reports_Set_Input>;
  pk_columns: Reports_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reports_ManyArgs = {
  updates: ReadonlyArray<Reports_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Room_Schedule_Lock_Unlock_TimesArgs = {
  args?: InputMaybe<Update_Room_Schedule_Lock_Unlock_Times_Args>;
  distinct_on?: InputMaybe<ReadonlyArray<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootUpdate_Room_StatusArgs = {
  args: Update_Room_Status_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootUpdate_RoomsArgs = {
  _append?: InputMaybe<Rooms_Append_Input>;
  _delete_at_path?: InputMaybe<Rooms_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Rooms_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Rooms_Delete_Key_Input>;
  _inc?: InputMaybe<Rooms_Inc_Input>;
  _prepend?: InputMaybe<Rooms_Prepend_Input>;
  _set?: InputMaybe<Rooms_Set_Input>;
  where: Rooms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rooms_By_PkArgs = {
  _append?: InputMaybe<Rooms_Append_Input>;
  _delete_at_path?: InputMaybe<Rooms_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Rooms_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Rooms_Delete_Key_Input>;
  _inc?: InputMaybe<Rooms_Inc_Input>;
  _prepend?: InputMaybe<Rooms_Prepend_Input>;
  _set?: InputMaybe<Rooms_Set_Input>;
  pk_columns: Rooms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rooms_ManyArgs = {
  updates: ReadonlyArray<Rooms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SchedulesArgs = {
  _append?: InputMaybe<Schedules_Append_Input>;
  _delete_at_path?: InputMaybe<Schedules_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Schedules_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Schedules_Delete_Key_Input>;
  _prepend?: InputMaybe<Schedules_Prepend_Input>;
  _set?: InputMaybe<Schedules_Set_Input>;
  where: Schedules_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Schedules_By_PkArgs = {
  _append?: InputMaybe<Schedules_Append_Input>;
  _delete_at_path?: InputMaybe<Schedules_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Schedules_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Schedules_Delete_Key_Input>;
  _prepend?: InputMaybe<Schedules_Prepend_Input>;
  _set?: InputMaybe<Schedules_Set_Input>;
  pk_columns: Schedules_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Schedules_ManyArgs = {
  updates: ReadonlyArray<Schedules_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Shared_Cached_QueriesArgs = {
  _inc?: InputMaybe<Shared_Cached_Queries_Inc_Input>;
  _set?: InputMaybe<Shared_Cached_Queries_Set_Input>;
  where: Shared_Cached_Queries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Shared_Cached_Queries_By_PkArgs = {
  _inc?: InputMaybe<Shared_Cached_Queries_Inc_Input>;
  _set?: InputMaybe<Shared_Cached_Queries_Set_Input>;
  pk_columns: Shared_Cached_Queries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Shared_Cached_Queries_ManyArgs = {
  updates: ReadonlyArray<Shared_Cached_Queries_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Short_UrlsArgs = {
  _set?: InputMaybe<Short_Urls_Set_Input>;
  where: Short_Urls_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Short_Urls_By_PkArgs = {
  _set?: InputMaybe<Short_Urls_Set_Input>;
  pk_columns: Short_Urls_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Short_Urls_ManyArgs = {
  updates: ReadonlyArray<Short_Urls_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SubscriptionsArgs = {
  _append?: InputMaybe<Subscriptions_Append_Input>;
  _delete_at_path?: InputMaybe<Subscriptions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Subscriptions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Subscriptions_Delete_Key_Input>;
  _inc?: InputMaybe<Subscriptions_Inc_Input>;
  _prepend?: InputMaybe<Subscriptions_Prepend_Input>;
  _set?: InputMaybe<Subscriptions_Set_Input>;
  where: Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subscriptions_By_PkArgs = {
  _append?: InputMaybe<Subscriptions_Append_Input>;
  _delete_at_path?: InputMaybe<Subscriptions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Subscriptions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Subscriptions_Delete_Key_Input>;
  _inc?: InputMaybe<Subscriptions_Inc_Input>;
  _prepend?: InputMaybe<Subscriptions_Prepend_Input>;
  _set?: InputMaybe<Subscriptions_Set_Input>;
  pk_columns: Subscriptions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Subscriptions_ManyArgs = {
  updates: ReadonlyArray<Subscriptions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TagsArgs = {
  _set?: InputMaybe<Tags_Set_Input>;
  where: Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tags_AssociationsArgs = {
  _set?: InputMaybe<Tags_Associations_Set_Input>;
  where: Tags_Associations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tags_Associations_By_PkArgs = {
  _set?: InputMaybe<Tags_Associations_Set_Input>;
  pk_columns: Tags_Associations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tags_Associations_ManyArgs = {
  updates: ReadonlyArray<Tags_Associations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tags_By_PkArgs = {
  _set?: InputMaybe<Tags_Set_Input>;
  pk_columns: Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tags_ManyArgs = {
  updates: ReadonlyArray<Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ThemesArgs = {
  _set?: InputMaybe<Themes_Set_Input>;
  where: Themes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Themes_By_PkArgs = {
  _set?: InputMaybe<Themes_Set_Input>;
  pk_columns: Themes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Themes_ManyArgs = {
  updates: ReadonlyArray<Themes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ToolsArgs = {
  _append?: InputMaybe<Tools_Append_Input>;
  _delete_at_path?: InputMaybe<Tools_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tools_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tools_Delete_Key_Input>;
  _prepend?: InputMaybe<Tools_Prepend_Input>;
  _set?: InputMaybe<Tools_Set_Input>;
  where: Tools_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tools_By_PkArgs = {
  _append?: InputMaybe<Tools_Append_Input>;
  _delete_at_path?: InputMaybe<Tools_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tools_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tools_Delete_Key_Input>;
  _prepend?: InputMaybe<Tools_Prepend_Input>;
  _set?: InputMaybe<Tools_Set_Input>;
  pk_columns: Tools_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tools_ManyArgs = {
  updates: ReadonlyArray<Tools_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TopicsArgs = {
  _set?: InputMaybe<Topics_Set_Input>;
  where: Topics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Topics_By_PkArgs = {
  _set?: InputMaybe<Topics_Set_Input>;
  pk_columns: Topics_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Topics_ManyArgs = {
  updates: ReadonlyArray<Topics_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Topics_ToolsArgs = {
  _set?: InputMaybe<Topics_Tools_Set_Input>;
  where: Topics_Tools_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Topics_Tools_By_PkArgs = {
  _set?: InputMaybe<Topics_Tools_Set_Input>;
  pk_columns: Topics_Tools_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Topics_Tools_ManyArgs = {
  updates: ReadonlyArray<Topics_Tools_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TransactionsArgs = {
  _append?: InputMaybe<Transactions_Append_Input>;
  _delete_at_path?: InputMaybe<Transactions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Transactions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Transactions_Delete_Key_Input>;
  _inc?: InputMaybe<Transactions_Inc_Input>;
  _prepend?: InputMaybe<Transactions_Prepend_Input>;
  _set?: InputMaybe<Transactions_Set_Input>;
  where: Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Transactions_By_PkArgs = {
  _append?: InputMaybe<Transactions_Append_Input>;
  _delete_at_path?: InputMaybe<Transactions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Transactions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Transactions_Delete_Key_Input>;
  _inc?: InputMaybe<Transactions_Inc_Input>;
  _prepend?: InputMaybe<Transactions_Prepend_Input>;
  _set?: InputMaybe<Transactions_Set_Input>;
  pk_columns: Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Transactions_ManyArgs = {
  updates: ReadonlyArray<Transactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TutorialsArgs = {
  _inc?: InputMaybe<Tutorials_Inc_Input>;
  _set?: InputMaybe<Tutorials_Set_Input>;
  where: Tutorials_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tutorials_By_PkArgs = {
  _inc?: InputMaybe<Tutorials_Inc_Input>;
  _set?: InputMaybe<Tutorials_Set_Input>;
  pk_columns: Tutorials_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tutorials_ManyArgs = {
  updates: ReadonlyArray<Tutorials_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tutorials_TagsArgs = {
  _set?: InputMaybe<Tutorials_Tags_Set_Input>;
  where: Tutorials_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tutorials_Tags_By_PkArgs = {
  _set?: InputMaybe<Tutorials_Tags_Set_Input>;
  pk_columns: Tutorials_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tutorials_Tags_ManyArgs = {
  updates: ReadonlyArray<Tutorials_Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Collected_AvatarsArgs = {
  _set?: InputMaybe<User_Collected_Avatars_Set_Input>;
  where: User_Collected_Avatars_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Collected_Avatars_By_PkArgs = {
  _set?: InputMaybe<User_Collected_Avatars_Set_Input>;
  pk_columns: User_Collected_Avatars_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Collected_Avatars_ManyArgs = {
  updates: ReadonlyArray<User_Collected_Avatars_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_InvitesArgs = {
  _set?: InputMaybe<User_Invites_Set_Input>;
  where: User_Invites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Invites_By_PkArgs = {
  _set?: InputMaybe<User_Invites_Set_Input>;
  pk_columns: User_Invites_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Invites_ManyArgs = {
  updates: ReadonlyArray<User_Invites_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_PresenceArgs = {
  _append?: InputMaybe<User_Presence_Append_Input>;
  _delete_at_path?: InputMaybe<User_Presence_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Presence_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Presence_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Presence_Prepend_Input>;
  _set?: InputMaybe<User_Presence_Set_Input>;
  where: User_Presence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Presence_By_PkArgs = {
  _append?: InputMaybe<User_Presence_Append_Input>;
  _delete_at_path?: InputMaybe<User_Presence_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Presence_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Presence_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Presence_Prepend_Input>;
  _set?: InputMaybe<User_Presence_Set_Input>;
  pk_columns: User_Presence_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Presence_Delete_Platform_State_KeysArgs = {
  args: Update_User_Presence_Delete_Platform_State_Keys_Args;
  distinct_on?: InputMaybe<ReadonlyArray<User_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Presence_Order_By>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Presence_ManyArgs = {
  updates: ReadonlyArray<User_Presence_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Inc_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ActionsArgs = {
  _append?: InputMaybe<Users_Actions_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Actions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Actions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Actions_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Actions_Prepend_Input>;
  _set?: InputMaybe<Users_Actions_Set_Input>;
  where: Users_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Actions_By_PkArgs = {
  _append?: InputMaybe<Users_Actions_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Actions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Actions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Actions_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Actions_Prepend_Input>;
  _set?: InputMaybe<Users_Actions_Set_Input>;
  pk_columns: Users_Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Actions_ManyArgs = {
  updates: ReadonlyArray<Users_Actions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_BadgesArgs = {
  _inc?: InputMaybe<Users_Badges_Inc_Input>;
  _set?: InputMaybe<Users_Badges_Set_Input>;
  where: Users_Badges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Badges_By_PkArgs = {
  _inc?: InputMaybe<Users_Badges_Inc_Input>;
  _set?: InputMaybe<Users_Badges_Set_Input>;
  pk_columns: Users_Badges_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Badges_ManyArgs = {
  updates: ReadonlyArray<Users_Badges_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Inc_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Cached_QueriesArgs = {
  _set?: InputMaybe<Users_Cached_Queries_Set_Input>;
  where: Users_Cached_Queries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Cached_Queries_By_PkArgs = {
  _set?: InputMaybe<Users_Cached_Queries_Set_Input>;
  pk_columns: Users_Cached_Queries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Cached_Queries_ManyArgs = {
  updates: ReadonlyArray<Users_Cached_Queries_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_FeaturesArgs = {
  _append?: InputMaybe<Users_Features_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Features_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Features_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Features_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Features_Inc_Input>;
  _prepend?: InputMaybe<Users_Features_Prepend_Input>;
  _set?: InputMaybe<Users_Features_Set_Input>;
  where: Users_Features_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Features_By_PkArgs = {
  _append?: InputMaybe<Users_Features_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Features_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Features_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Features_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Features_Inc_Input>;
  _prepend?: InputMaybe<Users_Features_Prepend_Input>;
  _set?: InputMaybe<Users_Features_Set_Input>;
  pk_columns: Users_Features_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Features_ManyArgs = {
  updates: ReadonlyArray<Users_Features_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_LevelsArgs = {
  _inc?: InputMaybe<Users_Levels_Inc_Input>;
  _set?: InputMaybe<Users_Levels_Set_Input>;
  where: Users_Levels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Levels_By_PkArgs = {
  _inc?: InputMaybe<Users_Levels_Inc_Input>;
  _set?: InputMaybe<Users_Levels_Set_Input>;
  pk_columns: Users_Levels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Levels_ManyArgs = {
  updates: ReadonlyArray<Users_Levels_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: ReadonlyArray<Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_NotificationsArgs = {
  _set?: InputMaybe<Users_Notifications_Set_Input>;
  where: Users_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Notifications_By_PkArgs = {
  _set?: InputMaybe<Users_Notifications_Set_Input>;
  pk_columns: Users_Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Notifications_ManyArgs = {
  updates: ReadonlyArray<Users_Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_PresenceArgs = {
  _append?: InputMaybe<Users_Presence_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Presence_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Presence_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Presence_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Presence_Prepend_Input>;
  _set?: InputMaybe<Users_Presence_Set_Input>;
  where: Users_Presence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Presence_ManyArgs = {
  updates: ReadonlyArray<Users_Presence_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ProgressionArgs = {
  _append?: InputMaybe<Users_Progression_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Progression_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Progression_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Progression_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Progression_Inc_Input>;
  _prepend?: InputMaybe<Users_Progression_Prepend_Input>;
  _set?: InputMaybe<Users_Progression_Set_Input>;
  where: Users_Progression_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Progression_By_PkArgs = {
  _append?: InputMaybe<Users_Progression_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Progression_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Progression_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Progression_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Progression_Inc_Input>;
  _prepend?: InputMaybe<Users_Progression_Prepend_Input>;
  _set?: InputMaybe<Users_Progression_Set_Input>;
  pk_columns: Users_Progression_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Progression_ManyArgs = {
  updates: ReadonlyArray<Users_Progression_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_QuestsArgs = {
  _set?: InputMaybe<Users_Quests_Set_Input>;
  where: Users_Quests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Quests_By_PkArgs = {
  _set?: InputMaybe<Users_Quests_Set_Input>;
  pk_columns: Users_Quests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Quests_ManyArgs = {
  updates: ReadonlyArray<Users_Quests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Stale_Web_PresenceArgs = {
  _append?: InputMaybe<Users_Stale_Web_Presence_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Stale_Web_Presence_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Stale_Web_Presence_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Stale_Web_Presence_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Stale_Web_Presence_Prepend_Input>;
  _set?: InputMaybe<Users_Stale_Web_Presence_Set_Input>;
  where: Users_Stale_Web_Presence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Stale_Web_Presence_ManyArgs = {
  updates: ReadonlyArray<Users_Stale_Web_Presence_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_StatsArgs = {
  _inc?: InputMaybe<Users_Stats_Inc_Input>;
  _set?: InputMaybe<Users_Stats_Set_Input>;
  where: Users_Stats_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Stats_By_PkArgs = {
  _inc?: InputMaybe<Users_Stats_Inc_Input>;
  _set?: InputMaybe<Users_Stats_Set_Input>;
  pk_columns: Users_Stats_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Stats_ManyArgs = {
  updates: ReadonlyArray<Users_Stats_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VotesArgs = {
  _set?: InputMaybe<Votes_Set_Input>;
  where: Votes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Votes_By_PkArgs = {
  _set?: InputMaybe<Votes_Set_Input>;
  pk_columns: Votes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Votes_ManyArgs = {
  updates: ReadonlyArray<Votes_Updates>;
};


/** mutation root */
export type Mutation_RootUpsertClubArgs = {
  displaySettings?: InputMaybe<Scalars['jsonb']['input']>;
  id: Scalars['uuid']['input'];
  name: Scalars['String']['input'];
  privacy: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpsertRoomArgs = {
  clipboardPrompt?: InputMaybe<Scalars['jsonb']['input']>;
  clubId?: InputMaybe<Scalars['uuid']['input']>;
  feedbackQuestion?: InputMaybe<Scalars['String']['input']>;
  iconUrl?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['uuid']['input'];
  lockTime?: InputMaybe<Scalars['timestamptz']['input']>;
  name: Scalars['String']['input'];
  privacy?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  tagline: Scalars['String']['input'];
  unlockTime?: InputMaybe<Scalars['timestamptz']['input']>;
};


/** mutation root */
export type Mutation_RootUpsertScheduleArgs = {
  clubs?: InputMaybe<ReadonlyArray<Scalars['uuid']['input']>>;
  id: Scalars['uuid']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  rooms?: InputMaybe<ReadonlyArray<Scalars['uuid']['input']>>;
  schedule: Scalars['jsonb']['input'];
  timezone: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootUpsert_User_ActionArgs = {
  args: Upsert_User_Action_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Users_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Actions_Order_By>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};

export enum Notification_Target {
  Announcement = 'announcement',
  Discord = 'discord',
  Email = 'email',
  Inapp = 'inapp',
  Sms = 'sms'
}

export type Notification_Target_Bool_Exp = {
  readonly _eq?: InputMaybe<Notification_Target>;
  readonly _in?: InputMaybe<ReadonlyArray<Notification_Target>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Notification_Target>;
  readonly _nin?: InputMaybe<ReadonlyArray<Notification_Target>>;
};

export type Notification_Type_Bool_Exp = {
  readonly _eq?: InputMaybe<Scalars['notification_type']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['notification_type']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Scalars['notification_type']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['notification_type']['input']>>;
};

/** columns and relationships of "notifications" */
export type Notifications = {
  readonly __typename?: 'notifications';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly payload?: Maybe<Scalars['jsonb']['output']>;
  readonly receiver_ids: ReadonlyArray<Scalars['String']['output']>;
  readonly sender_id?: Maybe<Scalars['String']['output']>;
  readonly target: Notification_Target;
  readonly type: Scalars['notification_type']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly userBySenderId?: Maybe<Users>;
  /** An array relationship */
  readonly users_notifications: ReadonlyArray<Users_Notifications>;
  /** An aggregate relationship */
  readonly users_notifications_aggregate: Users_Notifications_Aggregate;
  readonly valid_from?: Maybe<Scalars['timestamptz']['output']>;
  readonly valid_till?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "notifications" */
export type NotificationsPayloadArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "notifications" */
export type NotificationsUsers_NotificationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Notifications_Order_By>>;
  where?: InputMaybe<Users_Notifications_Bool_Exp>;
};


/** columns and relationships of "notifications" */
export type NotificationsUsers_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Notifications_Order_By>>;
  where?: InputMaybe<Users_Notifications_Bool_Exp>;
};

/** aggregated selection of "notifications" */
export type Notifications_Aggregate = {
  readonly __typename?: 'notifications_aggregate';
  readonly aggregate?: Maybe<Notifications_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Notifications>;
};

export type Notifications_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Notifications_Aggregate_Bool_Exp_Count>;
};

export type Notifications_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Notifications_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Notifications_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "notifications" */
export type Notifications_Aggregate_Fields = {
  readonly __typename?: 'notifications_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Notifications_Max_Fields>;
  readonly min?: Maybe<Notifications_Min_Fields>;
};


/** aggregate fields of "notifications" */
export type Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "notifications" */
export type Notifications_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Notifications_Max_Order_By>;
  readonly min?: InputMaybe<Notifications_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Notifications_Append_Input = {
  readonly payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "notifications" */
export type Notifications_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Notifications_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Notifications_On_Conflict>;
};

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export type Notifications_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Notifications_Bool_Exp>>;
  readonly _not?: InputMaybe<Notifications_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Notifications_Bool_Exp>>;
  readonly associated_object?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly payload?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly receiver_ids?: InputMaybe<String_Array_Comparison_Exp>;
  readonly sender_id?: InputMaybe<String_Comparison_Exp>;
  readonly target?: InputMaybe<Notification_Target_Bool_Exp>;
  readonly type?: InputMaybe<Notification_Type_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly userBySenderId?: InputMaybe<Users_Bool_Exp>;
  readonly users_notifications?: InputMaybe<Users_Notifications_Bool_Exp>;
  readonly users_notifications_aggregate?: InputMaybe<Users_Notifications_Aggregate_Bool_Exp>;
  readonly valid_from?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly valid_till?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "notifications" */
export enum Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationsPkey = 'notifications_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Notifications_Delete_At_Path_Input = {
  readonly payload?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Notifications_Delete_Elem_Input = {
  readonly payload?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Notifications_Delete_Key_Input = {
  readonly payload?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "notifications" */
export type Notifications_Insert_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly payload?: InputMaybe<Scalars['jsonb']['input']>;
  readonly receiver_ids?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly sender_id?: InputMaybe<Scalars['String']['input']>;
  readonly target: Notification_Target;
  readonly type?: InputMaybe<Scalars['notification_type']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly userBySenderId?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly users_notifications?: InputMaybe<Users_Notifications_Arr_Rel_Insert_Input>;
  readonly valid_from?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly valid_till?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Notifications_Max_Fields = {
  readonly __typename?: 'notifications_max_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly receiver_ids?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly sender_id?: Maybe<Scalars['String']['output']>;
  readonly target?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly valid_from?: Maybe<Scalars['timestamptz']['output']>;
  readonly valid_till?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "notifications" */
export type Notifications_Max_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly receiver_ids?: InputMaybe<Order_By>;
  readonly sender_id?: InputMaybe<Order_By>;
  readonly target?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly valid_from?: InputMaybe<Order_By>;
  readonly valid_till?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Notifications_Min_Fields = {
  readonly __typename?: 'notifications_min_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly receiver_ids?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly sender_id?: Maybe<Scalars['String']['output']>;
  readonly target?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly valid_from?: Maybe<Scalars['timestamptz']['output']>;
  readonly valid_till?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "notifications" */
export type Notifications_Min_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly receiver_ids?: InputMaybe<Order_By>;
  readonly sender_id?: InputMaybe<Order_By>;
  readonly target?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly valid_from?: InputMaybe<Order_By>;
  readonly valid_till?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "notifications" */
export type Notifications_Mutation_Response = {
  readonly __typename?: 'notifications_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Notifications>;
};

/** input type for inserting object relation for remote table "notifications" */
export type Notifications_Obj_Rel_Insert_Input = {
  readonly data: Notifications_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Notifications_On_Conflict>;
};

/** on_conflict condition type for table "notifications" */
export type Notifications_On_Conflict = {
  readonly constraint: Notifications_Constraint;
  readonly update_columns?: ReadonlyArray<Notifications_Update_Column>;
  readonly where?: InputMaybe<Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "notifications". */
export type Notifications_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly payload?: InputMaybe<Order_By>;
  readonly receiver_ids?: InputMaybe<Order_By>;
  readonly sender_id?: InputMaybe<Order_By>;
  readonly target?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly userBySenderId?: InputMaybe<Users_Order_By>;
  readonly users_notifications_aggregate?: InputMaybe<Users_Notifications_Aggregate_Order_By>;
  readonly valid_from?: InputMaybe<Order_By>;
  readonly valid_till?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notifications */
export type Notifications_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Notifications_Prepend_Input = {
  readonly payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "notifications" */
export enum Notifications_Select_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  ReceiverIds = 'receiver_ids',
  /** column name */
  SenderId = 'sender_id',
  /** column name */
  Target = 'target',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidFrom = 'valid_from',
  /** column name */
  ValidTill = 'valid_till'
}

/** input type for updating data in table "notifications" */
export type Notifications_Set_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly payload?: InputMaybe<Scalars['jsonb']['input']>;
  readonly receiver_ids?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly sender_id?: InputMaybe<Scalars['String']['input']>;
  readonly target?: InputMaybe<Notification_Target>;
  readonly type?: InputMaybe<Scalars['notification_type']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly valid_from?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly valid_till?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "notifications" */
export type Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notifications_Stream_Cursor_Value_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly payload?: InputMaybe<Scalars['jsonb']['input']>;
  readonly receiver_ids?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly sender_id?: InputMaybe<Scalars['String']['input']>;
  readonly target?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly valid_from?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly valid_till?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "notifications" */
export enum Notifications_Update_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  ReceiverIds = 'receiver_ids',
  /** column name */
  SenderId = 'sender_id',
  /** column name */
  Target = 'target',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidFrom = 'valid_from',
  /** column name */
  ValidTill = 'valid_till'
}

export type Notifications_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Notifications_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Notifications_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Notifications_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Notifications_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Notifications_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Notifications_Bool_Exp;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  readonly _eq?: InputMaybe<Scalars['numeric']['input']>;
  readonly _gt?: InputMaybe<Scalars['numeric']['input']>;
  readonly _gte?: InputMaybe<Scalars['numeric']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['numeric']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lt?: InputMaybe<Scalars['numeric']['input']>;
  readonly _lte?: InputMaybe<Scalars['numeric']['input']>;
  readonly _neq?: InputMaybe<Scalars['numeric']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['numeric']['input']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "orders" */
export type Orders = {
  readonly __typename?: 'orders';
  readonly association_id: Scalars['String']['output'];
  /** An array relationship */
  readonly comments: ReadonlyArray<Comments>;
  /** An aggregate relationship */
  readonly comments_aggregate: Comments_Aggregate;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly feeds: ReadonlyArray<Feeds>;
  /** An aggregate relationship */
  readonly feeds_aggregate: Feeds_Aggregate;
  readonly id: Scalars['String']['output'];
  /** An object relationship */
  readonly item: Items;
  readonly item_id: Scalars['String']['output'];
  readonly notes?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly purchasor: Users;
  readonly purhcasor_id: Scalars['String']['output'];
  readonly shipping_address: Scalars['String']['output'];
  readonly status: Scalars['String']['output'];
  readonly tracking_url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly transaction?: Maybe<Transactions>;
  /** An array relationship */
  readonly transactions: ReadonlyArray<Transactions>;
  /** An aggregate relationship */
  readonly transactions_aggregate: Transactions_Aggregate;
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  readonly votes: ReadonlyArray<Votes>;
  /** An aggregate relationship */
  readonly votes_aggregate: Votes_Aggregate;
};


/** columns and relationships of "orders" */
export type OrdersCommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersComments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersFeedsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersFeeds_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersTransactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersVotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


/** columns and relationships of "orders" */
export type OrdersVotes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};

/** aggregated selection of "orders" */
export type Orders_Aggregate = {
  readonly __typename?: 'orders_aggregate';
  readonly aggregate?: Maybe<Orders_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Orders>;
};

export type Orders_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Orders_Aggregate_Bool_Exp_Count>;
};

export type Orders_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Orders_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Orders_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "orders" */
export type Orders_Aggregate_Fields = {
  readonly __typename?: 'orders_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Orders_Max_Fields>;
  readonly min?: Maybe<Orders_Min_Fields>;
};


/** aggregate fields of "orders" */
export type Orders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "orders" */
export type Orders_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Orders_Max_Order_By>;
  readonly min?: InputMaybe<Orders_Min_Order_By>;
};

/** input type for inserting array relation for remote table "orders" */
export type Orders_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Orders_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Orders_On_Conflict>;
};

/** Boolean expression to filter rows from the table "orders". All fields are combined with a logical 'AND'. */
export type Orders_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Orders_Bool_Exp>>;
  readonly _not?: InputMaybe<Orders_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Orders_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly comments?: InputMaybe<Comments_Bool_Exp>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly description?: InputMaybe<String_Comparison_Exp>;
  readonly feeds?: InputMaybe<Feeds_Bool_Exp>;
  readonly feeds_aggregate?: InputMaybe<Feeds_Aggregate_Bool_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly item?: InputMaybe<Items_Bool_Exp>;
  readonly item_id?: InputMaybe<String_Comparison_Exp>;
  readonly notes?: InputMaybe<String_Comparison_Exp>;
  readonly purchasor?: InputMaybe<Users_Bool_Exp>;
  readonly purhcasor_id?: InputMaybe<String_Comparison_Exp>;
  readonly shipping_address?: InputMaybe<String_Comparison_Exp>;
  readonly status?: InputMaybe<String_Comparison_Exp>;
  readonly tracking_url?: InputMaybe<String_Comparison_Exp>;
  readonly transaction?: InputMaybe<Transactions_Bool_Exp>;
  readonly transactions?: InputMaybe<Transactions_Bool_Exp>;
  readonly transactions_aggregate?: InputMaybe<Transactions_Aggregate_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly votes?: InputMaybe<Votes_Bool_Exp>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "orders" */
export enum Orders_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrdersPkey = 'orders_pkey'
}

/** input type for inserting data into table "orders" */
export type Orders_Insert_Input = {
  readonly comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly feeds?: InputMaybe<Feeds_Arr_Rel_Insert_Input>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly item?: InputMaybe<Items_Obj_Rel_Insert_Input>;
  readonly item_id?: InputMaybe<Scalars['String']['input']>;
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly purchasor?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly purhcasor_id?: InputMaybe<Scalars['String']['input']>;
  readonly shipping_address?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly tracking_url?: InputMaybe<Scalars['String']['input']>;
  readonly transaction?: InputMaybe<Transactions_Obj_Rel_Insert_Input>;
  readonly transactions?: InputMaybe<Transactions_Arr_Rel_Insert_Input>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly votes?: InputMaybe<Votes_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Orders_Max_Fields = {
  readonly __typename?: 'orders_max_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly item_id?: Maybe<Scalars['String']['output']>;
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly purhcasor_id?: Maybe<Scalars['String']['output']>;
  readonly shipping_address?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly tracking_url?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "orders" */
export type Orders_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly item_id?: InputMaybe<Order_By>;
  readonly notes?: InputMaybe<Order_By>;
  readonly purhcasor_id?: InputMaybe<Order_By>;
  readonly shipping_address?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly tracking_url?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Orders_Min_Fields = {
  readonly __typename?: 'orders_min_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly item_id?: Maybe<Scalars['String']['output']>;
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly purhcasor_id?: Maybe<Scalars['String']['output']>;
  readonly shipping_address?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly tracking_url?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "orders" */
export type Orders_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly item_id?: InputMaybe<Order_By>;
  readonly notes?: InputMaybe<Order_By>;
  readonly purhcasor_id?: InputMaybe<Order_By>;
  readonly shipping_address?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly tracking_url?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "orders" */
export type Orders_Mutation_Response = {
  readonly __typename?: 'orders_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Orders>;
};

/** input type for inserting object relation for remote table "orders" */
export type Orders_Obj_Rel_Insert_Input = {
  readonly data: Orders_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Orders_On_Conflict>;
};

/** on_conflict condition type for table "orders" */
export type Orders_On_Conflict = {
  readonly constraint: Orders_Constraint;
  readonly update_columns?: ReadonlyArray<Orders_Update_Column>;
  readonly where?: InputMaybe<Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "orders". */
export type Orders_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly feeds_aggregate?: InputMaybe<Feeds_Aggregate_Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly item?: InputMaybe<Items_Order_By>;
  readonly item_id?: InputMaybe<Order_By>;
  readonly notes?: InputMaybe<Order_By>;
  readonly purchasor?: InputMaybe<Users_Order_By>;
  readonly purhcasor_id?: InputMaybe<Order_By>;
  readonly shipping_address?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly tracking_url?: InputMaybe<Order_By>;
  readonly transaction?: InputMaybe<Transactions_Order_By>;
  readonly transactions_aggregate?: InputMaybe<Transactions_Aggregate_Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Order_By>;
};

/** primary key columns input for table: orders */
export type Orders_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** select columns of table "orders" */
export enum Orders_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PurhcasorId = 'purhcasor_id',
  /** column name */
  ShippingAddress = 'shipping_address',
  /** column name */
  Status = 'status',
  /** column name */
  TrackingUrl = 'tracking_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "orders" */
export type Orders_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly item_id?: InputMaybe<Scalars['String']['input']>;
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly purhcasor_id?: InputMaybe<Scalars['String']['input']>;
  readonly shipping_address?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly tracking_url?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "orders" */
export type Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Orders_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly item_id?: InputMaybe<Scalars['String']['input']>;
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly purhcasor_id?: InputMaybe<Scalars['String']['input']>;
  readonly shipping_address?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly tracking_url?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "orders" */
export enum Orders_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PurhcasorId = 'purhcasor_id',
  /** column name */
  ShippingAddress = 'shipping_address',
  /** column name */
  Status = 'status',
  /** column name */
  TrackingUrl = 'tracking_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Orders_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Orders_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Orders_Bool_Exp;
};

export enum Otp_Recipient_Type {
  Email = 'email',
  Minecraft = 'minecraft',
  Phone = 'phone',
  Platform = 'platform'
}

export type Otp_Recipient_Type_Bool_Exp = {
  readonly _eq?: InputMaybe<Otp_Recipient_Type>;
  readonly _in?: InputMaybe<ReadonlyArray<Otp_Recipient_Type>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Otp_Recipient_Type>;
  readonly _nin?: InputMaybe<ReadonlyArray<Otp_Recipient_Type>>;
};

/** columns and relationships of "otps" */
export type Otps = {
  readonly __typename?: 'otps';
  readonly authorized?: Maybe<Scalars['Boolean']['output']>;
  readonly code: Scalars['String']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly data: Scalars['otps_data']['output'];
  readonly expires_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['String']['output'];
  readonly recipient_address: Scalars['String']['output'];
  readonly recipient_type: Otp_Recipient_Type;
  readonly updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "otps" */
export type OtpsDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "otps" */
export type Otps_Aggregate = {
  readonly __typename?: 'otps_aggregate';
  readonly aggregate?: Maybe<Otps_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Otps>;
};

/** aggregate fields of "otps" */
export type Otps_Aggregate_Fields = {
  readonly __typename?: 'otps_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Otps_Max_Fields>;
  readonly min?: Maybe<Otps_Min_Fields>;
};


/** aggregate fields of "otps" */
export type Otps_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Otps_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Otps_Append_Input = {
  readonly data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "otps". All fields are combined with a logical 'AND'. */
export type Otps_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Otps_Bool_Exp>>;
  readonly _not?: InputMaybe<Otps_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Otps_Bool_Exp>>;
  readonly authorized?: InputMaybe<Boolean_Comparison_Exp>;
  readonly code?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly data?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly expires_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly recipient_address?: InputMaybe<String_Comparison_Exp>;
  readonly recipient_type?: InputMaybe<Otp_Recipient_Type_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "otps" */
export enum Otps_Constraint {
  /** unique or primary key constraint on columns "code" */
  OtpsCodeKey = 'otps_code_key',
  /** unique or primary key constraint on columns "id" */
  OtpsPkey = 'otps_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Otps_Delete_At_Path_Input = {
  readonly data?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Otps_Delete_Elem_Input = {
  readonly data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Otps_Delete_Key_Input = {
  readonly data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "otps" */
export type Otps_Insert_Input = {
  readonly authorized?: InputMaybe<Scalars['Boolean']['input']>;
  readonly code?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly data?: InputMaybe<Scalars['otps_data']['input']>;
  readonly expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly recipient_address?: InputMaybe<Scalars['String']['input']>;
  readonly recipient_type?: InputMaybe<Otp_Recipient_Type>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Otps_Max_Fields = {
  readonly __typename?: 'otps_max_fields';
  readonly code?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly expires_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly recipient_address?: Maybe<Scalars['String']['output']>;
  readonly recipient_type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Otps_Min_Fields = {
  readonly __typename?: 'otps_min_fields';
  readonly code?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly expires_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly recipient_address?: Maybe<Scalars['String']['output']>;
  readonly recipient_type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "otps" */
export type Otps_Mutation_Response = {
  readonly __typename?: 'otps_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Otps>;
};

/** on_conflict condition type for table "otps" */
export type Otps_On_Conflict = {
  readonly constraint: Otps_Constraint;
  readonly update_columns?: ReadonlyArray<Otps_Update_Column>;
  readonly where?: InputMaybe<Otps_Bool_Exp>;
};

/** Ordering options when selecting data from "otps". */
export type Otps_Order_By = {
  readonly authorized?: InputMaybe<Order_By>;
  readonly code?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly data?: InputMaybe<Order_By>;
  readonly expires_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly recipient_address?: InputMaybe<Order_By>;
  readonly recipient_type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: otps */
export type Otps_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Otps_Prepend_Input = {
  readonly data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "otps" */
export enum Otps_Select_Column {
  /** column name */
  Authorized = 'authorized',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  RecipientAddress = 'recipient_address',
  /** column name */
  RecipientType = 'recipient_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "otps" */
export type Otps_Set_Input = {
  readonly authorized?: InputMaybe<Scalars['Boolean']['input']>;
  readonly code?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly data?: InputMaybe<Scalars['otps_data']['input']>;
  readonly expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly recipient_address?: InputMaybe<Scalars['String']['input']>;
  readonly recipient_type?: InputMaybe<Otp_Recipient_Type>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "otps" */
export type Otps_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Otps_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Otps_Stream_Cursor_Value_Input = {
  readonly authorized?: InputMaybe<Scalars['Boolean']['input']>;
  readonly code?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly data?: InputMaybe<Scalars['jsonb']['input']>;
  readonly expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly recipient_address?: InputMaybe<Scalars['String']['input']>;
  readonly recipient_type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "otps" */
export enum Otps_Update_Column {
  /** column name */
  Authorized = 'authorized',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  RecipientAddress = 'recipient_address',
  /** column name */
  RecipientType = 'recipient_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Otps_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Otps_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Otps_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Otps_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Otps_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Otps_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Otps_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Otps_Bool_Exp;
};

/** columns and relationships of "pages" */
export type Pages = {
  readonly __typename?: 'pages';
  readonly content: Scalars['String']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly slug: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  readonly user_id: Scalars['String']['output'];
};

/** aggregated selection of "pages" */
export type Pages_Aggregate = {
  readonly __typename?: 'pages_aggregate';
  readonly aggregate?: Maybe<Pages_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Pages>;
};

/** aggregate fields of "pages" */
export type Pages_Aggregate_Fields = {
  readonly __typename?: 'pages_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Pages_Max_Fields>;
  readonly min?: Maybe<Pages_Min_Fields>;
};


/** aggregate fields of "pages" */
export type Pages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Pages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "pages". All fields are combined with a logical 'AND'. */
export type Pages_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Pages_Bool_Exp>>;
  readonly _not?: InputMaybe<Pages_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Pages_Bool_Exp>>;
  readonly content?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly slug?: InputMaybe<String_Comparison_Exp>;
  readonly title?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "pages" */
export enum Pages_Constraint {
  /** unique or primary key constraint on columns "id" */
  PagesPkey = 'pages_pkey',
  /** unique or primary key constraint on columns "slug" */
  PagesSlugKey = 'pages_slug_key'
}

/** input type for inserting data into table "pages" */
export type Pages_Insert_Input = {
  readonly content?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly slug?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Pages_Max_Fields = {
  readonly __typename?: 'pages_max_fields';
  readonly content?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly slug?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Pages_Min_Fields = {
  readonly __typename?: 'pages_min_fields';
  readonly content?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly slug?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "pages" */
export type Pages_Mutation_Response = {
  readonly __typename?: 'pages_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Pages>;
};

/** on_conflict condition type for table "pages" */
export type Pages_On_Conflict = {
  readonly constraint: Pages_Constraint;
  readonly update_columns?: ReadonlyArray<Pages_Update_Column>;
  readonly where?: InputMaybe<Pages_Bool_Exp>;
};

/** Ordering options when selecting data from "pages". */
export type Pages_Order_By = {
  readonly content?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly slug?: InputMaybe<Order_By>;
  readonly title?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pages */
export type Pages_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "pages" */
export enum Pages_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "pages" */
export type Pages_Set_Input = {
  readonly content?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly slug?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "pages" */
export type Pages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Pages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pages_Stream_Cursor_Value_Input = {
  readonly content?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly slug?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "pages" */
export enum Pages_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Pages_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Pages_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Pages_Bool_Exp;
};

/** columns and relationships of "player_worlds" */
export type Player_Worlds = {
  readonly __typename?: 'player_worlds';
  readonly id: Scalars['String']['output'];
  readonly members: ReadonlyArray<Scalars['String']['output']>;
  readonly owner_id: Scalars['String']['output'];
};

/** aggregated selection of "player_worlds" */
export type Player_Worlds_Aggregate = {
  readonly __typename?: 'player_worlds_aggregate';
  readonly aggregate?: Maybe<Player_Worlds_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Player_Worlds>;
};

/** aggregate fields of "player_worlds" */
export type Player_Worlds_Aggregate_Fields = {
  readonly __typename?: 'player_worlds_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Player_Worlds_Max_Fields>;
  readonly min?: Maybe<Player_Worlds_Min_Fields>;
};


/** aggregate fields of "player_worlds" */
export type Player_Worlds_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Player_Worlds_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "player_worlds". All fields are combined with a logical 'AND'. */
export type Player_Worlds_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Player_Worlds_Bool_Exp>>;
  readonly _not?: InputMaybe<Player_Worlds_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Player_Worlds_Bool_Exp>>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly members?: InputMaybe<String_Array_Comparison_Exp>;
  readonly owner_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "player_worlds" */
export enum Player_Worlds_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlayerWorldsPkey = 'player_worlds_pkey'
}

/** input type for inserting data into table "player_worlds" */
export type Player_Worlds_Insert_Input = {
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly members?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly owner_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Player_Worlds_Max_Fields = {
  readonly __typename?: 'player_worlds_max_fields';
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly members?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly owner_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Player_Worlds_Min_Fields = {
  readonly __typename?: 'player_worlds_min_fields';
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly members?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly owner_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "player_worlds" */
export type Player_Worlds_Mutation_Response = {
  readonly __typename?: 'player_worlds_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Player_Worlds>;
};

/** on_conflict condition type for table "player_worlds" */
export type Player_Worlds_On_Conflict = {
  readonly constraint: Player_Worlds_Constraint;
  readonly update_columns?: ReadonlyArray<Player_Worlds_Update_Column>;
  readonly where?: InputMaybe<Player_Worlds_Bool_Exp>;
};

/** Ordering options when selecting data from "player_worlds". */
export type Player_Worlds_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly members?: InputMaybe<Order_By>;
  readonly owner_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: player_worlds */
export type Player_Worlds_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** select columns of table "player_worlds" */
export enum Player_Worlds_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Members = 'members',
  /** column name */
  OwnerId = 'owner_id'
}

/** input type for updating data in table "player_worlds" */
export type Player_Worlds_Set_Input = {
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly members?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly owner_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "player_worlds" */
export type Player_Worlds_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Player_Worlds_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Player_Worlds_Stream_Cursor_Value_Input = {
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly members?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly owner_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "player_worlds" */
export enum Player_Worlds_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Members = 'members',
  /** column name */
  OwnerId = 'owner_id'
}

export type Player_Worlds_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Player_Worlds_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Player_Worlds_Bool_Exp;
};

/** columns and relationships of "posts" */
export type Posts = {
  readonly __typename?: 'posts';
  readonly association_id: Scalars['String']['output'];
  /** An array relationship */
  readonly comments: ReadonlyArray<Comments>;
  /** An aggregate relationship */
  readonly comments_aggregate: Comments_Aggregate;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  readonly feeds: ReadonlyArray<Feeds>;
  /** An aggregate relationship */
  readonly feeds_aggregate: Feeds_Aggregate;
  readonly id: Scalars['uuid']['output'];
  readonly message: Scalars['String']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
  /** An array relationship */
  readonly votes: ReadonlyArray<Votes>;
  /** An aggregate relationship */
  readonly votes_aggregate: Votes_Aggregate;
};


/** columns and relationships of "posts" */
export type PostsCommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsComments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsFeedsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsFeeds_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsVotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsVotes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};

/** aggregated selection of "posts" */
export type Posts_Aggregate = {
  readonly __typename?: 'posts_aggregate';
  readonly aggregate?: Maybe<Posts_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Posts>;
};

export type Posts_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Posts_Aggregate_Bool_Exp_Count>;
};

export type Posts_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Posts_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Posts_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "posts" */
export type Posts_Aggregate_Fields = {
  readonly __typename?: 'posts_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Posts_Max_Fields>;
  readonly min?: Maybe<Posts_Min_Fields>;
};


/** aggregate fields of "posts" */
export type Posts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Posts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "posts" */
export type Posts_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Posts_Max_Order_By>;
  readonly min?: InputMaybe<Posts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "posts" */
export type Posts_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Posts_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Posts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "posts". All fields are combined with a logical 'AND'. */
export type Posts_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Posts_Bool_Exp>>;
  readonly _not?: InputMaybe<Posts_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Posts_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly comments?: InputMaybe<Comments_Bool_Exp>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly feeds?: InputMaybe<Feeds_Bool_Exp>;
  readonly feeds_aggregate?: InputMaybe<Feeds_Aggregate_Bool_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly message?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
  readonly votes?: InputMaybe<Votes_Bool_Exp>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "posts" */
export enum Posts_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostsPkey = 'posts_pkey'
}

export type Posts_Delete_Args = {
  readonly post_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** input type for inserting data into table "posts" */
export type Posts_Insert_Input = {
  readonly comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly feeds?: InputMaybe<Feeds_Arr_Rel_Insert_Input>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly votes?: InputMaybe<Votes_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Posts_Max_Fields = {
  readonly __typename?: 'posts_max_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly message?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "posts" */
export type Posts_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly message?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Posts_Min_Fields = {
  readonly __typename?: 'posts_min_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly message?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "posts" */
export type Posts_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly message?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "posts" */
export type Posts_Mutation_Response = {
  readonly __typename?: 'posts_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Posts>;
};

/** input type for inserting object relation for remote table "posts" */
export type Posts_Obj_Rel_Insert_Input = {
  readonly data: Posts_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Posts_On_Conflict>;
};

/** on_conflict condition type for table "posts" */
export type Posts_On_Conflict = {
  readonly constraint: Posts_Constraint;
  readonly update_columns?: ReadonlyArray<Posts_Update_Column>;
  readonly where?: InputMaybe<Posts_Bool_Exp>;
};

/** Ordering options when selecting data from "posts". */
export type Posts_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly feeds_aggregate?: InputMaybe<Feeds_Aggregate_Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly message?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Order_By>;
};

/** primary key columns input for table: posts */
export type Posts_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "posts" */
export enum Posts_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "posts" */
export type Posts_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "posts" */
export type Posts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Posts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Posts_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "posts" */
export enum Posts_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Posts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Posts_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Posts_Bool_Exp;
};

export enum Project_Status {
  Created = 'created',
  Deleted = 'deleted',
  Disqualified = 'disqualified',
  Draft = 'draft',
  Reviewed = 'reviewed'
}

export type Project_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<Project_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<Project_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Project_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<Project_Status>>;
};

/** columns and relationships of "projects" */
export type Projects = {
  readonly __typename?: 'projects';
  readonly association_id: Scalars['String']['output'];
  readonly build_code?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  readonly channel?: Maybe<Channels>;
  /** An object relationship */
  readonly club?: Maybe<Clubs>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  readonly collaborators: ReadonlyArray<Projects_Collaborators>;
  /** An aggregate relationship */
  readonly collaborators_aggregate: Projects_Collaborators_Aggregate;
  /** An array relationship */
  readonly comments: ReadonlyArray<Comments>;
  /** An aggregate relationship */
  readonly comments_aggregate: Comments_Aggregate;
  /** An object relationship */
  readonly competition?: Maybe<Competitions>;
  readonly competition_id?: Maybe<Scalars['String']['output']>;
  readonly created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly creator?: Maybe<Users>;
  readonly creator_id?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly feeds: ReadonlyArray<Feeds>;
  /** An aggregate relationship */
  readonly feeds_aggregate: Feeds_Aggregate;
  readonly files: Scalars['jsonb']['output'];
  readonly highlights: Scalars['jsonb']['output'];
  readonly id: Scalars['String']['output'];
  readonly images?: Maybe<Scalars['projects_images']['output']>;
  /** An object relationship */
  readonly judge?: Maybe<Users>;
  readonly judge_id?: Maybe<Scalars['String']['output']>;
  readonly meta: Scalars['jsonb']['output'];
  readonly money?: Maybe<Scalars['numeric']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly place?: Maybe<Scalars['Int']['output']>;
  readonly prize?: Maybe<Scalars['projects_prize']['output']>;
  /** An array relationship */
  readonly projects_publications: ReadonlyArray<Projects_Publications>;
  /** An aggregate relationship */
  readonly projects_publications_aggregate: Projects_Publications_Aggregate;
  readonly render?: Maybe<Scalars['String']['output']>;
  readonly review_text?: Maybe<Scalars['String']['output']>;
  readonly rubric?: Maybe<Scalars['jsonb']['output']>;
  readonly score?: Maybe<Scalars['Int']['output']>;
  readonly special_prizes?: Maybe<Scalars['jsonb']['output']>;
  readonly status: Project_Status;
  /** An object relationship */
  readonly submitter?: Maybe<Users>;
  readonly submitter_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly topic?: Maybe<Topics>;
  readonly topic_id?: Maybe<Scalars['String']['output']>;
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  readonly votes: ReadonlyArray<Votes>;
  /** An aggregate relationship */
  readonly votes_aggregate: Votes_Aggregate;
  readonly xp?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "projects" */
export type ProjectsCollaboratorsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Collaborators_Order_By>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsCollaborators_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Collaborators_Order_By>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsCommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsComments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsFeedsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsFeeds_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsFilesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "projects" */
export type ProjectsHighlightsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "projects" */
export type ProjectsImagesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "projects" */
export type ProjectsMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "projects" */
export type ProjectsPrizeArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "projects" */
export type ProjectsProjects_PublicationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Publications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Publications_Order_By>>;
  where?: InputMaybe<Projects_Publications_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProjects_Publications_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Publications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Publications_Order_By>>;
  where?: InputMaybe<Projects_Publications_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsRubricArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "projects" */
export type ProjectsSpecial_PrizesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "projects" */
export type ProjectsVotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsVotes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};

/** aggregated selection of "projects" */
export type Projects_Aggregate = {
  readonly __typename?: 'projects_aggregate';
  readonly aggregate?: Maybe<Projects_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Projects>;
};

export type Projects_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Projects_Aggregate_Bool_Exp_Count>;
};

export type Projects_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Projects_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "projects" */
export type Projects_Aggregate_Fields = {
  readonly __typename?: 'projects_aggregate_fields';
  readonly avg?: Maybe<Projects_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Projects_Max_Fields>;
  readonly min?: Maybe<Projects_Min_Fields>;
  readonly stddev?: Maybe<Projects_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Projects_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Projects_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Projects_Sum_Fields>;
  readonly var_pop?: Maybe<Projects_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Projects_Var_Samp_Fields>;
  readonly variance?: Maybe<Projects_Variance_Fields>;
};


/** aggregate fields of "projects" */
export type Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "projects" */
export type Projects_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Projects_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Projects_Max_Order_By>;
  readonly min?: InputMaybe<Projects_Min_Order_By>;
  readonly stddev?: InputMaybe<Projects_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Projects_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Projects_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Projects_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Projects_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Projects_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Projects_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Projects_Append_Input = {
  readonly files?: InputMaybe<Scalars['jsonb']['input']>;
  readonly highlights?: InputMaybe<Scalars['jsonb']['input']>;
  readonly images?: InputMaybe<Scalars['jsonb']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly prize?: InputMaybe<Scalars['jsonb']['input']>;
  readonly rubric?: InputMaybe<Scalars['jsonb']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "projects" */
export type Projects_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Projects_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Projects_On_Conflict>;
};

/** aggregate avg on columns */
export type Projects_Avg_Fields = {
  readonly __typename?: 'projects_avg_fields';
  readonly build_code?: Maybe<Scalars['Float']['output']>;
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly place?: Maybe<Scalars['Float']['output']>;
  readonly score?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "projects" */
export type Projects_Avg_Order_By = {
  readonly build_code?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "projects". All fields are combined with a logical 'AND'. */
export type Projects_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Projects_Bool_Exp>>;
  readonly _not?: InputMaybe<Projects_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Projects_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly build_code?: InputMaybe<Int_Comparison_Exp>;
  readonly channel?: InputMaybe<Channels_Bool_Exp>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly club_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly collaborators?: InputMaybe<Projects_Collaborators_Bool_Exp>;
  readonly collaborators_aggregate?: InputMaybe<Projects_Collaborators_Aggregate_Bool_Exp>;
  readonly comments?: InputMaybe<Comments_Bool_Exp>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  readonly competition?: InputMaybe<Competitions_Bool_Exp>;
  readonly competition_id?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly creator?: InputMaybe<Users_Bool_Exp>;
  readonly creator_id?: InputMaybe<String_Comparison_Exp>;
  readonly description?: InputMaybe<String_Comparison_Exp>;
  readonly feeds?: InputMaybe<Feeds_Bool_Exp>;
  readonly feeds_aggregate?: InputMaybe<Feeds_Aggregate_Bool_Exp>;
  readonly files?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly highlights?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly images?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly judge?: InputMaybe<Users_Bool_Exp>;
  readonly judge_id?: InputMaybe<String_Comparison_Exp>;
  readonly meta?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly money?: InputMaybe<Numeric_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly place?: InputMaybe<Int_Comparison_Exp>;
  readonly prize?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly projects_publications?: InputMaybe<Projects_Publications_Bool_Exp>;
  readonly projects_publications_aggregate?: InputMaybe<Projects_Publications_Aggregate_Bool_Exp>;
  readonly render?: InputMaybe<String_Comparison_Exp>;
  readonly review_text?: InputMaybe<String_Comparison_Exp>;
  readonly rubric?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly score?: InputMaybe<Int_Comparison_Exp>;
  readonly special_prizes?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly status?: InputMaybe<Project_Status_Bool_Exp>;
  readonly submitter?: InputMaybe<Users_Bool_Exp>;
  readonly submitter_id?: InputMaybe<String_Comparison_Exp>;
  readonly topic?: InputMaybe<Topics_Bool_Exp>;
  readonly topic_id?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly votes?: InputMaybe<Votes_Bool_Exp>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Bool_Exp>;
  readonly xp?: InputMaybe<Int_Comparison_Exp>;
};

/** columns and relationships of "projects_collaborators" */
export type Projects_Collaborators = {
  readonly __typename?: 'projects_collaborators';
  /** An object relationship */
  readonly collaborator: Users;
  readonly collaborator_id: Scalars['String']['output'];
  /** An object relationship */
  readonly project: Projects;
  readonly project_id: Scalars['String']['output'];
};

/** aggregated selection of "projects_collaborators" */
export type Projects_Collaborators_Aggregate = {
  readonly __typename?: 'projects_collaborators_aggregate';
  readonly aggregate?: Maybe<Projects_Collaborators_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Projects_Collaborators>;
};

export type Projects_Collaborators_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Projects_Collaborators_Aggregate_Bool_Exp_Count>;
};

export type Projects_Collaborators_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Projects_Collaborators_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "projects_collaborators" */
export type Projects_Collaborators_Aggregate_Fields = {
  readonly __typename?: 'projects_collaborators_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Projects_Collaborators_Max_Fields>;
  readonly min?: Maybe<Projects_Collaborators_Min_Fields>;
};


/** aggregate fields of "projects_collaborators" */
export type Projects_Collaborators_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "projects_collaborators" */
export type Projects_Collaborators_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Projects_Collaborators_Max_Order_By>;
  readonly min?: InputMaybe<Projects_Collaborators_Min_Order_By>;
};

/** input type for inserting array relation for remote table "projects_collaborators" */
export type Projects_Collaborators_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Projects_Collaborators_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Projects_Collaborators_On_Conflict>;
};

/** Boolean expression to filter rows from the table "projects_collaborators". All fields are combined with a logical 'AND'. */
export type Projects_Collaborators_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Projects_Collaborators_Bool_Exp>>;
  readonly _not?: InputMaybe<Projects_Collaborators_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Projects_Collaborators_Bool_Exp>>;
  readonly collaborator?: InputMaybe<Users_Bool_Exp>;
  readonly collaborator_id?: InputMaybe<String_Comparison_Exp>;
  readonly project?: InputMaybe<Projects_Bool_Exp>;
  readonly project_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "projects_collaborators" */
export enum Projects_Collaborators_Constraint {
  /** unique or primary key constraint on columns "project_id", "collaborator_id" */
  ProjectsCollaboratorsPkey = 'projects_collaborators_pkey'
}

/** input type for inserting data into table "projects_collaborators" */
export type Projects_Collaborators_Insert_Input = {
  readonly collaborator?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly collaborator_id?: InputMaybe<Scalars['String']['input']>;
  readonly project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Projects_Collaborators_Max_Fields = {
  readonly __typename?: 'projects_collaborators_max_fields';
  readonly collaborator_id?: Maybe<Scalars['String']['output']>;
  readonly project_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "projects_collaborators" */
export type Projects_Collaborators_Max_Order_By = {
  readonly collaborator_id?: InputMaybe<Order_By>;
  readonly project_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Projects_Collaborators_Min_Fields = {
  readonly __typename?: 'projects_collaborators_min_fields';
  readonly collaborator_id?: Maybe<Scalars['String']['output']>;
  readonly project_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "projects_collaborators" */
export type Projects_Collaborators_Min_Order_By = {
  readonly collaborator_id?: InputMaybe<Order_By>;
  readonly project_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "projects_collaborators" */
export type Projects_Collaborators_Mutation_Response = {
  readonly __typename?: 'projects_collaborators_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Projects_Collaborators>;
};

/** on_conflict condition type for table "projects_collaborators" */
export type Projects_Collaborators_On_Conflict = {
  readonly constraint: Projects_Collaborators_Constraint;
  readonly update_columns?: ReadonlyArray<Projects_Collaborators_Update_Column>;
  readonly where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};

/** Ordering options when selecting data from "projects_collaborators". */
export type Projects_Collaborators_Order_By = {
  readonly collaborator?: InputMaybe<Users_Order_By>;
  readonly collaborator_id?: InputMaybe<Order_By>;
  readonly project?: InputMaybe<Projects_Order_By>;
  readonly project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: projects_collaborators */
export type Projects_Collaborators_Pk_Columns_Input = {
  readonly collaborator_id: Scalars['String']['input'];
  readonly project_id: Scalars['String']['input'];
};

/** select columns of table "projects_collaborators" */
export enum Projects_Collaborators_Select_Column {
  /** column name */
  CollaboratorId = 'collaborator_id',
  /** column name */
  ProjectId = 'project_id'
}

/** input type for updating data in table "projects_collaborators" */
export type Projects_Collaborators_Set_Input = {
  readonly collaborator_id?: InputMaybe<Scalars['String']['input']>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "projects_collaborators" */
export type Projects_Collaborators_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Projects_Collaborators_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Projects_Collaborators_Stream_Cursor_Value_Input = {
  readonly collaborator_id?: InputMaybe<Scalars['String']['input']>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "projects_collaborators" */
export enum Projects_Collaborators_Update_Column {
  /** column name */
  CollaboratorId = 'collaborator_id',
  /** column name */
  ProjectId = 'project_id'
}

export type Projects_Collaborators_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Projects_Collaborators_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Projects_Collaborators_Bool_Exp;
};

/** unique or primary key constraints on table "projects" */
export enum Projects_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectsPkey = 'projects_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Projects_Delete_At_Path_Input = {
  readonly files?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly highlights?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly images?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly meta?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly prize?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly rubric?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly special_prizes?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Projects_Delete_Elem_Input = {
  readonly files?: InputMaybe<Scalars['Int']['input']>;
  readonly highlights?: InputMaybe<Scalars['Int']['input']>;
  readonly images?: InputMaybe<Scalars['Int']['input']>;
  readonly meta?: InputMaybe<Scalars['Int']['input']>;
  readonly prize?: InputMaybe<Scalars['Int']['input']>;
  readonly rubric?: InputMaybe<Scalars['Int']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Projects_Delete_Key_Input = {
  readonly files?: InputMaybe<Scalars['String']['input']>;
  readonly highlights?: InputMaybe<Scalars['String']['input']>;
  readonly images?: InputMaybe<Scalars['String']['input']>;
  readonly meta?: InputMaybe<Scalars['String']['input']>;
  readonly prize?: InputMaybe<Scalars['String']['input']>;
  readonly rubric?: InputMaybe<Scalars['String']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "projects_editables" */
export type Projects_Editables = {
  readonly __typename?: 'projects_editables';
  /** An array relationship */
  readonly collaborators: ReadonlyArray<Projects_Collaborators>;
  /** An aggregate relationship */
  readonly collaborators_aggregate: Projects_Collaborators_Aggregate;
  /** An object relationship */
  readonly competition?: Maybe<Competitions>;
  readonly competition_id?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly highlights?: Maybe<Scalars['jsonb']['output']>;
  readonly meta?: Maybe<Scalars['jsonb']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly project_id?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "projects_editables" */
export type Projects_EditablesCollaboratorsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Collaborators_Order_By>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


/** columns and relationships of "projects_editables" */
export type Projects_EditablesCollaborators_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Collaborators_Order_By>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


/** columns and relationships of "projects_editables" */
export type Projects_EditablesHighlightsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "projects_editables" */
export type Projects_EditablesMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "projects_editables" */
export type Projects_Editables_Aggregate = {
  readonly __typename?: 'projects_editables_aggregate';
  readonly aggregate?: Maybe<Projects_Editables_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Projects_Editables>;
};

/** aggregate fields of "projects_editables" */
export type Projects_Editables_Aggregate_Fields = {
  readonly __typename?: 'projects_editables_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Projects_Editables_Max_Fields>;
  readonly min?: Maybe<Projects_Editables_Min_Fields>;
};


/** aggregate fields of "projects_editables" */
export type Projects_Editables_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Projects_Editables_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Projects_Editables_Append_Input = {
  readonly highlights?: InputMaybe<Scalars['jsonb']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "projects_editables". All fields are combined with a logical 'AND'. */
export type Projects_Editables_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Projects_Editables_Bool_Exp>>;
  readonly _not?: InputMaybe<Projects_Editables_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Projects_Editables_Bool_Exp>>;
  readonly collaborators?: InputMaybe<Projects_Collaborators_Bool_Exp>;
  readonly collaborators_aggregate?: InputMaybe<Projects_Collaborators_Aggregate_Bool_Exp>;
  readonly competition?: InputMaybe<Competitions_Bool_Exp>;
  readonly competition_id?: InputMaybe<String_Comparison_Exp>;
  readonly description?: InputMaybe<String_Comparison_Exp>;
  readonly highlights?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly meta?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly project_id?: InputMaybe<String_Comparison_Exp>;
  readonly status?: InputMaybe<String_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Projects_Editables_Delete_At_Path_Input = {
  readonly highlights?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly meta?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Projects_Editables_Delete_Elem_Input = {
  readonly highlights?: InputMaybe<Scalars['Int']['input']>;
  readonly meta?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Projects_Editables_Delete_Key_Input = {
  readonly highlights?: InputMaybe<Scalars['String']['input']>;
  readonly meta?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "projects_editables" */
export type Projects_Editables_Insert_Input = {
  readonly collaborators?: InputMaybe<Projects_Collaborators_Arr_Rel_Insert_Input>;
  readonly competition?: InputMaybe<Competitions_Obj_Rel_Insert_Input>;
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly highlights?: InputMaybe<Scalars['jsonb']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Projects_Editables_Max_Fields = {
  readonly __typename?: 'projects_editables_max_fields';
  readonly competition_id?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly project_id?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Projects_Editables_Min_Fields = {
  readonly __typename?: 'projects_editables_min_fields';
  readonly competition_id?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly project_id?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "projects_editables" */
export type Projects_Editables_Mutation_Response = {
  readonly __typename?: 'projects_editables_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Projects_Editables>;
};

/** Ordering options when selecting data from "projects_editables". */
export type Projects_Editables_Order_By = {
  readonly collaborators_aggregate?: InputMaybe<Projects_Collaborators_Aggregate_Order_By>;
  readonly competition?: InputMaybe<Competitions_Order_By>;
  readonly competition_id?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly highlights?: InputMaybe<Order_By>;
  readonly meta?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly project_id?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Projects_Editables_Prepend_Input = {
  readonly highlights?: InputMaybe<Scalars['jsonb']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "projects_editables" */
export enum Projects_Editables_Select_Column {
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  Description = 'description',
  /** column name */
  Highlights = 'highlights',
  /** column name */
  Meta = 'meta',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "projects_editables" */
export type Projects_Editables_Set_Input = {
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly highlights?: InputMaybe<Scalars['jsonb']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "projects_editables" */
export type Projects_Editables_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Projects_Editables_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Projects_Editables_Stream_Cursor_Value_Input = {
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly highlights?: InputMaybe<Scalars['jsonb']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
};

export type Projects_Editables_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Projects_Editables_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Projects_Editables_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Projects_Editables_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Projects_Editables_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Projects_Editables_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Projects_Editables_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Projects_Editables_Bool_Exp;
};

/** input type for incrementing numeric columns in table "projects" */
export type Projects_Inc_Input = {
  readonly build_code?: InputMaybe<Scalars['Int']['input']>;
  readonly money?: InputMaybe<Scalars['numeric']['input']>;
  readonly place?: InputMaybe<Scalars['Int']['input']>;
  readonly score?: InputMaybe<Scalars['Int']['input']>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "projects" */
export type Projects_Insert_Input = {
  readonly build_code?: InputMaybe<Scalars['Int']['input']>;
  readonly channel?: InputMaybe<Channels_Obj_Rel_Insert_Input>;
  readonly club?: InputMaybe<Clubs_Obj_Rel_Insert_Input>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly collaborators?: InputMaybe<Projects_Collaborators_Arr_Rel_Insert_Input>;
  readonly comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  readonly competition?: InputMaybe<Competitions_Obj_Rel_Insert_Input>;
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly creator?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly creator_id?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly feeds?: InputMaybe<Feeds_Arr_Rel_Insert_Input>;
  readonly files?: InputMaybe<Scalars['jsonb']['input']>;
  readonly highlights?: InputMaybe<Scalars['jsonb']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly images?: InputMaybe<Scalars['projects_images']['input']>;
  readonly judge?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly judge_id?: InputMaybe<Scalars['String']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly money?: InputMaybe<Scalars['numeric']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly place?: InputMaybe<Scalars['Int']['input']>;
  readonly prize?: InputMaybe<Scalars['projects_prize']['input']>;
  readonly projects_publications?: InputMaybe<Projects_Publications_Arr_Rel_Insert_Input>;
  readonly render?: InputMaybe<Scalars['String']['input']>;
  readonly review_text?: InputMaybe<Scalars['String']['input']>;
  readonly rubric?: InputMaybe<Scalars['jsonb']['input']>;
  readonly score?: InputMaybe<Scalars['Int']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['jsonb']['input']>;
  readonly status?: InputMaybe<Project_Status>;
  readonly submitter?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly submitter_id?: InputMaybe<Scalars['String']['input']>;
  readonly topic?: InputMaybe<Topics_Obj_Rel_Insert_Input>;
  readonly topic_id?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly votes?: InputMaybe<Votes_Arr_Rel_Insert_Input>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

/** Data entries for completed runs through project games. */
export type Projects_Leaderboards = {
  readonly __typename?: 'projects_leaderboards';
  readonly created_at: Scalars['timestamptz']['output'];
  readonly deaths?: Maybe<Scalars['Int']['output']>;
  readonly duration: Scalars['bigint']['output'];
  readonly id: Scalars['uuid']['output'];
  /** An object relationship */
  readonly publication: Projects_Publications;
  readonly publication_id: Scalars['uuid']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};

/** aggregated selection of "projects_leaderboards" */
export type Projects_Leaderboards_Aggregate = {
  readonly __typename?: 'projects_leaderboards_aggregate';
  readonly aggregate?: Maybe<Projects_Leaderboards_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Projects_Leaderboards>;
};

/** aggregate fields of "projects_leaderboards" */
export type Projects_Leaderboards_Aggregate_Fields = {
  readonly __typename?: 'projects_leaderboards_aggregate_fields';
  readonly avg?: Maybe<Projects_Leaderboards_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Projects_Leaderboards_Max_Fields>;
  readonly min?: Maybe<Projects_Leaderboards_Min_Fields>;
  readonly stddev?: Maybe<Projects_Leaderboards_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Projects_Leaderboards_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Projects_Leaderboards_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Projects_Leaderboards_Sum_Fields>;
  readonly var_pop?: Maybe<Projects_Leaderboards_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Projects_Leaderboards_Var_Samp_Fields>;
  readonly variance?: Maybe<Projects_Leaderboards_Variance_Fields>;
};


/** aggregate fields of "projects_leaderboards" */
export type Projects_Leaderboards_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Projects_Leaderboards_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Projects_Leaderboards_Avg_Fields = {
  readonly __typename?: 'projects_leaderboards_avg_fields';
  readonly deaths?: Maybe<Scalars['Float']['output']>;
  readonly duration?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "projects_leaderboards". All fields are combined with a logical 'AND'. */
export type Projects_Leaderboards_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Projects_Leaderboards_Bool_Exp>>;
  readonly _not?: InputMaybe<Projects_Leaderboards_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Projects_Leaderboards_Bool_Exp>>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly deaths?: InputMaybe<Int_Comparison_Exp>;
  readonly duration?: InputMaybe<Bigint_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly publication?: InputMaybe<Projects_Publications_Bool_Exp>;
  readonly publication_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "projects_leaderboards" */
export enum Projects_Leaderboards_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectsLeaderboardsPkey = 'projects_leaderboards_pkey'
}

/** input type for incrementing numeric columns in table "projects_leaderboards" */
export type Projects_Leaderboards_Inc_Input = {
  readonly deaths?: InputMaybe<Scalars['Int']['input']>;
  readonly duration?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "projects_leaderboards" */
export type Projects_Leaderboards_Insert_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deaths?: InputMaybe<Scalars['Int']['input']>;
  readonly duration?: InputMaybe<Scalars['bigint']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly publication?: InputMaybe<Projects_Publications_Obj_Rel_Insert_Input>;
  readonly publication_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Projects_Leaderboards_Max_Fields = {
  readonly __typename?: 'projects_leaderboards_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly deaths?: Maybe<Scalars['Int']['output']>;
  readonly duration?: Maybe<Scalars['bigint']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly publication_id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Projects_Leaderboards_Min_Fields = {
  readonly __typename?: 'projects_leaderboards_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly deaths?: Maybe<Scalars['Int']['output']>;
  readonly duration?: Maybe<Scalars['bigint']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly publication_id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "projects_leaderboards" */
export type Projects_Leaderboards_Mutation_Response = {
  readonly __typename?: 'projects_leaderboards_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Projects_Leaderboards>;
};

/** on_conflict condition type for table "projects_leaderboards" */
export type Projects_Leaderboards_On_Conflict = {
  readonly constraint: Projects_Leaderboards_Constraint;
  readonly update_columns?: ReadonlyArray<Projects_Leaderboards_Update_Column>;
  readonly where?: InputMaybe<Projects_Leaderboards_Bool_Exp>;
};

/** Ordering options when selecting data from "projects_leaderboards". */
export type Projects_Leaderboards_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly deaths?: InputMaybe<Order_By>;
  readonly duration?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly publication?: InputMaybe<Projects_Publications_Order_By>;
  readonly publication_id?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: projects_leaderboards */
export type Projects_Leaderboards_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "projects_leaderboards" */
export enum Projects_Leaderboards_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deaths = 'deaths',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "projects_leaderboards" */
export type Projects_Leaderboards_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deaths?: InputMaybe<Scalars['Int']['input']>;
  readonly duration?: InputMaybe<Scalars['bigint']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly publication_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Projects_Leaderboards_Stddev_Fields = {
  readonly __typename?: 'projects_leaderboards_stddev_fields';
  readonly deaths?: Maybe<Scalars['Float']['output']>;
  readonly duration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Projects_Leaderboards_Stddev_Pop_Fields = {
  readonly __typename?: 'projects_leaderboards_stddev_pop_fields';
  readonly deaths?: Maybe<Scalars['Float']['output']>;
  readonly duration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Projects_Leaderboards_Stddev_Samp_Fields = {
  readonly __typename?: 'projects_leaderboards_stddev_samp_fields';
  readonly deaths?: Maybe<Scalars['Float']['output']>;
  readonly duration?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "projects_leaderboards" */
export type Projects_Leaderboards_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Projects_Leaderboards_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Projects_Leaderboards_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deaths?: InputMaybe<Scalars['Int']['input']>;
  readonly duration?: InputMaybe<Scalars['bigint']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly publication_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Projects_Leaderboards_Sum_Fields = {
  readonly __typename?: 'projects_leaderboards_sum_fields';
  readonly deaths?: Maybe<Scalars['Int']['output']>;
  readonly duration?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "projects_leaderboards" */
export enum Projects_Leaderboards_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deaths = 'deaths',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  UserId = 'user_id'
}

export type Projects_Leaderboards_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Projects_Leaderboards_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Projects_Leaderboards_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Projects_Leaderboards_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Projects_Leaderboards_Var_Pop_Fields = {
  readonly __typename?: 'projects_leaderboards_var_pop_fields';
  readonly deaths?: Maybe<Scalars['Float']['output']>;
  readonly duration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Projects_Leaderboards_Var_Samp_Fields = {
  readonly __typename?: 'projects_leaderboards_var_samp_fields';
  readonly deaths?: Maybe<Scalars['Float']['output']>;
  readonly duration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Projects_Leaderboards_Variance_Fields = {
  readonly __typename?: 'projects_leaderboards_variance_fields';
  readonly deaths?: Maybe<Scalars['Float']['output']>;
  readonly duration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate max on columns */
export type Projects_Max_Fields = {
  readonly __typename?: 'projects_max_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly build_code?: Maybe<Scalars['Int']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly competition_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly creator_id?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly judge_id?: Maybe<Scalars['String']['output']>;
  readonly money?: Maybe<Scalars['numeric']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly place?: Maybe<Scalars['Int']['output']>;
  readonly render?: Maybe<Scalars['String']['output']>;
  readonly review_text?: Maybe<Scalars['String']['output']>;
  readonly score?: Maybe<Scalars['Int']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly submitter_id?: Maybe<Scalars['String']['output']>;
  readonly topic_id?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly xp?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "projects" */
export type Projects_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly build_code?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly competition_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly creator_id?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly judge_id?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly render?: InputMaybe<Order_By>;
  readonly review_text?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly submitter_id?: InputMaybe<Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Projects_Min_Fields = {
  readonly __typename?: 'projects_min_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly build_code?: Maybe<Scalars['Int']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly competition_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly creator_id?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly judge_id?: Maybe<Scalars['String']['output']>;
  readonly money?: Maybe<Scalars['numeric']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly place?: Maybe<Scalars['Int']['output']>;
  readonly render?: Maybe<Scalars['String']['output']>;
  readonly review_text?: Maybe<Scalars['String']['output']>;
  readonly score?: Maybe<Scalars['Int']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly submitter_id?: Maybe<Scalars['String']['output']>;
  readonly topic_id?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly xp?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "projects" */
export type Projects_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly build_code?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly competition_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly creator_id?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly judge_id?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly render?: InputMaybe<Order_By>;
  readonly review_text?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly submitter_id?: InputMaybe<Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "projects" */
export type Projects_Mutation_Response = {
  readonly __typename?: 'projects_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Projects>;
};

/** input type for inserting object relation for remote table "projects" */
export type Projects_Obj_Rel_Insert_Input = {
  readonly data: Projects_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Projects_On_Conflict>;
};

/** on_conflict condition type for table "projects" */
export type Projects_On_Conflict = {
  readonly constraint: Projects_Constraint;
  readonly update_columns?: ReadonlyArray<Projects_Update_Column>;
  readonly where?: InputMaybe<Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "projects". */
export type Projects_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly build_code?: InputMaybe<Order_By>;
  readonly channel?: InputMaybe<Channels_Order_By>;
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly collaborators_aggregate?: InputMaybe<Projects_Collaborators_Aggregate_Order_By>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  readonly competition?: InputMaybe<Competitions_Order_By>;
  readonly competition_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly creator?: InputMaybe<Users_Order_By>;
  readonly creator_id?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly feeds_aggregate?: InputMaybe<Feeds_Aggregate_Order_By>;
  readonly files?: InputMaybe<Order_By>;
  readonly highlights?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly images?: InputMaybe<Order_By>;
  readonly judge?: InputMaybe<Users_Order_By>;
  readonly judge_id?: InputMaybe<Order_By>;
  readonly meta?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly prize?: InputMaybe<Order_By>;
  readonly projects_publications_aggregate?: InputMaybe<Projects_Publications_Aggregate_Order_By>;
  readonly render?: InputMaybe<Order_By>;
  readonly review_text?: InputMaybe<Order_By>;
  readonly rubric?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly special_prizes?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly submitter?: InputMaybe<Users_Order_By>;
  readonly submitter_id?: InputMaybe<Order_By>;
  readonly topic?: InputMaybe<Topics_Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** primary key columns input for table: projects */
export type Projects_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Projects_Prepend_Input = {
  readonly files?: InputMaybe<Scalars['jsonb']['input']>;
  readonly highlights?: InputMaybe<Scalars['jsonb']['input']>;
  readonly images?: InputMaybe<Scalars['jsonb']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly prize?: InputMaybe<Scalars['jsonb']['input']>;
  readonly rubric?: InputMaybe<Scalars['jsonb']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Represents a published project for featuring in the discovery interface. */
export type Projects_Publications = {
  readonly __typename?: 'projects_publications';
  readonly created_at: Scalars['timestamptz']['output'];
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id: Scalars['uuid']['output'];
  /** An object relationship */
  readonly project: Projects;
  readonly project_id: Scalars['String']['output'];
  readonly release_notes?: Maybe<Scalars['String']['output']>;
  readonly updated_at: Scalars['timestamptz']['output'];
  readonly version: Scalars['Int']['output'];
};

/** aggregated selection of "projects_publications" */
export type Projects_Publications_Aggregate = {
  readonly __typename?: 'projects_publications_aggregate';
  readonly aggregate?: Maybe<Projects_Publications_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Projects_Publications>;
};

export type Projects_Publications_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Projects_Publications_Aggregate_Bool_Exp_Count>;
};

export type Projects_Publications_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Projects_Publications_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Projects_Publications_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "projects_publications" */
export type Projects_Publications_Aggregate_Fields = {
  readonly __typename?: 'projects_publications_aggregate_fields';
  readonly avg?: Maybe<Projects_Publications_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Projects_Publications_Max_Fields>;
  readonly min?: Maybe<Projects_Publications_Min_Fields>;
  readonly stddev?: Maybe<Projects_Publications_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Projects_Publications_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Projects_Publications_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Projects_Publications_Sum_Fields>;
  readonly var_pop?: Maybe<Projects_Publications_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Projects_Publications_Var_Samp_Fields>;
  readonly variance?: Maybe<Projects_Publications_Variance_Fields>;
};


/** aggregate fields of "projects_publications" */
export type Projects_Publications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Projects_Publications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "projects_publications" */
export type Projects_Publications_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Projects_Publications_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Projects_Publications_Max_Order_By>;
  readonly min?: InputMaybe<Projects_Publications_Min_Order_By>;
  readonly stddev?: InputMaybe<Projects_Publications_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Projects_Publications_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Projects_Publications_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Projects_Publications_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Projects_Publications_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Projects_Publications_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Projects_Publications_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "projects_publications" */
export type Projects_Publications_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Projects_Publications_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Projects_Publications_On_Conflict>;
};

/** aggregate avg on columns */
export type Projects_Publications_Avg_Fields = {
  readonly __typename?: 'projects_publications_avg_fields';
  readonly version?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "projects_publications" */
export type Projects_Publications_Avg_Order_By = {
  readonly version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "projects_publications". All fields are combined with a logical 'AND'. */
export type Projects_Publications_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Projects_Publications_Bool_Exp>>;
  readonly _not?: InputMaybe<Projects_Publications_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Projects_Publications_Bool_Exp>>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly project?: InputMaybe<Projects_Bool_Exp>;
  readonly project_id?: InputMaybe<String_Comparison_Exp>;
  readonly release_notes?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly version?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "projects_publications" */
export enum Projects_Publications_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectsPublicationsPkey = 'projects_publications_pkey',
  /** unique or primary key constraint on columns "project_id", "version" */
  ProjectsPublicationsProjectIdVersionKey = 'projects_publications_project_id_version_key'
}

/** input type for incrementing numeric columns in table "projects_publications" */
export type Projects_Publications_Inc_Input = {
  readonly version?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "projects_publications" */
export type Projects_Publications_Insert_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
  readonly release_notes?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly version?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Projects_Publications_Max_Fields = {
  readonly __typename?: 'projects_publications_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly project_id?: Maybe<Scalars['String']['output']>;
  readonly release_notes?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly version?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "projects_publications" */
export type Projects_Publications_Max_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly project_id?: InputMaybe<Order_By>;
  readonly release_notes?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Projects_Publications_Min_Fields = {
  readonly __typename?: 'projects_publications_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly project_id?: Maybe<Scalars['String']['output']>;
  readonly release_notes?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly version?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "projects_publications" */
export type Projects_Publications_Min_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly project_id?: InputMaybe<Order_By>;
  readonly release_notes?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "projects_publications" */
export type Projects_Publications_Mutation_Response = {
  readonly __typename?: 'projects_publications_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Projects_Publications>;
};

/** input type for inserting object relation for remote table "projects_publications" */
export type Projects_Publications_Obj_Rel_Insert_Input = {
  readonly data: Projects_Publications_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Projects_Publications_On_Conflict>;
};

/** on_conflict condition type for table "projects_publications" */
export type Projects_Publications_On_Conflict = {
  readonly constraint: Projects_Publications_Constraint;
  readonly update_columns?: ReadonlyArray<Projects_Publications_Update_Column>;
  readonly where?: InputMaybe<Projects_Publications_Bool_Exp>;
};

/** Ordering options when selecting data from "projects_publications". */
export type Projects_Publications_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly project?: InputMaybe<Projects_Order_By>;
  readonly project_id?: InputMaybe<Order_By>;
  readonly release_notes?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: projects_publications */
export type Projects_Publications_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "projects_publications" */
export enum Projects_Publications_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ReleaseNotes = 'release_notes',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "projects_publications" */
export type Projects_Publications_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
  readonly release_notes?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly version?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Projects_Publications_Stddev_Fields = {
  readonly __typename?: 'projects_publications_stddev_fields';
  readonly version?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "projects_publications" */
export type Projects_Publications_Stddev_Order_By = {
  readonly version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Projects_Publications_Stddev_Pop_Fields = {
  readonly __typename?: 'projects_publications_stddev_pop_fields';
  readonly version?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "projects_publications" */
export type Projects_Publications_Stddev_Pop_Order_By = {
  readonly version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Projects_Publications_Stddev_Samp_Fields = {
  readonly __typename?: 'projects_publications_stddev_samp_fields';
  readonly version?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "projects_publications" */
export type Projects_Publications_Stddev_Samp_Order_By = {
  readonly version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "projects_publications" */
export type Projects_Publications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Projects_Publications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Projects_Publications_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
  readonly release_notes?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly version?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Projects_Publications_Sum_Fields = {
  readonly __typename?: 'projects_publications_sum_fields';
  readonly version?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "projects_publications" */
export type Projects_Publications_Sum_Order_By = {
  readonly version?: InputMaybe<Order_By>;
};

/** update columns of table "projects_publications" */
export enum Projects_Publications_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ReleaseNotes = 'release_notes',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version'
}

export type Projects_Publications_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Projects_Publications_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Projects_Publications_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Projects_Publications_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Projects_Publications_Var_Pop_Fields = {
  readonly __typename?: 'projects_publications_var_pop_fields';
  readonly version?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "projects_publications" */
export type Projects_Publications_Var_Pop_Order_By = {
  readonly version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Projects_Publications_Var_Samp_Fields = {
  readonly __typename?: 'projects_publications_var_samp_fields';
  readonly version?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "projects_publications" */
export type Projects_Publications_Var_Samp_Order_By = {
  readonly version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Projects_Publications_Variance_Fields = {
  readonly __typename?: 'projects_publications_variance_fields';
  readonly version?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "projects_publications" */
export type Projects_Publications_Variance_Order_By = {
  readonly version?: InputMaybe<Order_By>;
};

/** Voting/ratings for project publications. */
export type Projects_Publications_Votes = {
  readonly __typename?: 'projects_publications_votes';
  readonly id: Scalars['uuid']['output'];
  /** An object relationship */
  readonly publication: Projects_Publications;
  readonly publication_id: Scalars['uuid']['output'];
  readonly rating: Scalars['Int']['output'];
  readonly recommend: Scalars['Boolean']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};

/** aggregated selection of "projects_publications_votes" */
export type Projects_Publications_Votes_Aggregate = {
  readonly __typename?: 'projects_publications_votes_aggregate';
  readonly aggregate?: Maybe<Projects_Publications_Votes_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Projects_Publications_Votes>;
};

/** aggregate fields of "projects_publications_votes" */
export type Projects_Publications_Votes_Aggregate_Fields = {
  readonly __typename?: 'projects_publications_votes_aggregate_fields';
  readonly avg?: Maybe<Projects_Publications_Votes_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Projects_Publications_Votes_Max_Fields>;
  readonly min?: Maybe<Projects_Publications_Votes_Min_Fields>;
  readonly stddev?: Maybe<Projects_Publications_Votes_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Projects_Publications_Votes_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Projects_Publications_Votes_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Projects_Publications_Votes_Sum_Fields>;
  readonly var_pop?: Maybe<Projects_Publications_Votes_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Projects_Publications_Votes_Var_Samp_Fields>;
  readonly variance?: Maybe<Projects_Publications_Votes_Variance_Fields>;
};


/** aggregate fields of "projects_publications_votes" */
export type Projects_Publications_Votes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Projects_Publications_Votes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Projects_Publications_Votes_Avg_Fields = {
  readonly __typename?: 'projects_publications_votes_avg_fields';
  readonly rating?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "projects_publications_votes". All fields are combined with a logical 'AND'. */
export type Projects_Publications_Votes_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Projects_Publications_Votes_Bool_Exp>>;
  readonly _not?: InputMaybe<Projects_Publications_Votes_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Projects_Publications_Votes_Bool_Exp>>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly publication?: InputMaybe<Projects_Publications_Bool_Exp>;
  readonly publication_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly rating?: InputMaybe<Int_Comparison_Exp>;
  readonly recommend?: InputMaybe<Boolean_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "projects_publications_votes" */
export enum Projects_Publications_Votes_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectsPublicationsVotesPkey = 'projects_publications_votes_pkey'
}

/** input type for incrementing numeric columns in table "projects_publications_votes" */
export type Projects_Publications_Votes_Inc_Input = {
  readonly rating?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "projects_publications_votes" */
export type Projects_Publications_Votes_Insert_Input = {
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly publication?: InputMaybe<Projects_Publications_Obj_Rel_Insert_Input>;
  readonly publication_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly rating?: InputMaybe<Scalars['Int']['input']>;
  readonly recommend?: InputMaybe<Scalars['Boolean']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Projects_Publications_Votes_Max_Fields = {
  readonly __typename?: 'projects_publications_votes_max_fields';
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly publication_id?: Maybe<Scalars['uuid']['output']>;
  readonly rating?: Maybe<Scalars['Int']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Projects_Publications_Votes_Min_Fields = {
  readonly __typename?: 'projects_publications_votes_min_fields';
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly publication_id?: Maybe<Scalars['uuid']['output']>;
  readonly rating?: Maybe<Scalars['Int']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "projects_publications_votes" */
export type Projects_Publications_Votes_Mutation_Response = {
  readonly __typename?: 'projects_publications_votes_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Projects_Publications_Votes>;
};

/** on_conflict condition type for table "projects_publications_votes" */
export type Projects_Publications_Votes_On_Conflict = {
  readonly constraint: Projects_Publications_Votes_Constraint;
  readonly update_columns?: ReadonlyArray<Projects_Publications_Votes_Update_Column>;
  readonly where?: InputMaybe<Projects_Publications_Votes_Bool_Exp>;
};

/** Ordering options when selecting data from "projects_publications_votes". */
export type Projects_Publications_Votes_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly publication?: InputMaybe<Projects_Publications_Order_By>;
  readonly publication_id?: InputMaybe<Order_By>;
  readonly rating?: InputMaybe<Order_By>;
  readonly recommend?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: projects_publications_votes */
export type Projects_Publications_Votes_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "projects_publications_votes" */
export enum Projects_Publications_Votes_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  Rating = 'rating',
  /** column name */
  Recommend = 'recommend',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "projects_publications_votes" */
export type Projects_Publications_Votes_Set_Input = {
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly publication_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly rating?: InputMaybe<Scalars['Int']['input']>;
  readonly recommend?: InputMaybe<Scalars['Boolean']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Projects_Publications_Votes_Stddev_Fields = {
  readonly __typename?: 'projects_publications_votes_stddev_fields';
  readonly rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Projects_Publications_Votes_Stddev_Pop_Fields = {
  readonly __typename?: 'projects_publications_votes_stddev_pop_fields';
  readonly rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Projects_Publications_Votes_Stddev_Samp_Fields = {
  readonly __typename?: 'projects_publications_votes_stddev_samp_fields';
  readonly rating?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "projects_publications_votes" */
export type Projects_Publications_Votes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Projects_Publications_Votes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Projects_Publications_Votes_Stream_Cursor_Value_Input = {
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly publication_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly rating?: InputMaybe<Scalars['Int']['input']>;
  readonly recommend?: InputMaybe<Scalars['Boolean']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Projects_Publications_Votes_Sum_Fields = {
  readonly __typename?: 'projects_publications_votes_sum_fields';
  readonly rating?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "projects_publications_votes" */
export enum Projects_Publications_Votes_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  Rating = 'rating',
  /** column name */
  Recommend = 'recommend',
  /** column name */
  UserId = 'user_id'
}

export type Projects_Publications_Votes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Projects_Publications_Votes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Projects_Publications_Votes_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Projects_Publications_Votes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Projects_Publications_Votes_Var_Pop_Fields = {
  readonly __typename?: 'projects_publications_votes_var_pop_fields';
  readonly rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Projects_Publications_Votes_Var_Samp_Fields = {
  readonly __typename?: 'projects_publications_votes_var_samp_fields';
  readonly rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Projects_Publications_Votes_Variance_Fields = {
  readonly __typename?: 'projects_publications_votes_variance_fields';
  readonly rating?: Maybe<Scalars['Float']['output']>;
};

/** select columns of table "projects" */
export enum Projects_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  BuildCode = 'build_code',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Description = 'description',
  /** column name */
  Files = 'files',
  /** column name */
  Highlights = 'highlights',
  /** column name */
  Id = 'id',
  /** column name */
  Images = 'images',
  /** column name */
  JudgeId = 'judge_id',
  /** column name */
  Meta = 'meta',
  /** column name */
  Money = 'money',
  /** column name */
  Name = 'name',
  /** column name */
  Place = 'place',
  /** column name */
  Prize = 'prize',
  /** column name */
  Render = 'render',
  /** column name */
  ReviewText = 'review_text',
  /** column name */
  Rubric = 'rubric',
  /** column name */
  Score = 'score',
  /** column name */
  SpecialPrizes = 'special_prizes',
  /** column name */
  Status = 'status',
  /** column name */
  SubmitterId = 'submitter_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Xp = 'xp'
}

/** input type for updating data in table "projects" */
export type Projects_Set_Input = {
  readonly build_code?: InputMaybe<Scalars['Int']['input']>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly creator_id?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly files?: InputMaybe<Scalars['jsonb']['input']>;
  readonly highlights?: InputMaybe<Scalars['jsonb']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly images?: InputMaybe<Scalars['projects_images']['input']>;
  readonly judge_id?: InputMaybe<Scalars['String']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly money?: InputMaybe<Scalars['numeric']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly place?: InputMaybe<Scalars['Int']['input']>;
  readonly prize?: InputMaybe<Scalars['projects_prize']['input']>;
  readonly render?: InputMaybe<Scalars['String']['input']>;
  readonly review_text?: InputMaybe<Scalars['String']['input']>;
  readonly rubric?: InputMaybe<Scalars['jsonb']['input']>;
  readonly score?: InputMaybe<Scalars['Int']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['jsonb']['input']>;
  readonly status?: InputMaybe<Project_Status>;
  readonly submitter_id?: InputMaybe<Scalars['String']['input']>;
  readonly topic_id?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Projects_Stddev_Fields = {
  readonly __typename?: 'projects_stddev_fields';
  readonly build_code?: Maybe<Scalars['Float']['output']>;
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly place?: Maybe<Scalars['Float']['output']>;
  readonly score?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "projects" */
export type Projects_Stddev_Order_By = {
  readonly build_code?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Projects_Stddev_Pop_Fields = {
  readonly __typename?: 'projects_stddev_pop_fields';
  readonly build_code?: Maybe<Scalars['Float']['output']>;
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly place?: Maybe<Scalars['Float']['output']>;
  readonly score?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "projects" */
export type Projects_Stddev_Pop_Order_By = {
  readonly build_code?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Projects_Stddev_Samp_Fields = {
  readonly __typename?: 'projects_stddev_samp_fields';
  readonly build_code?: Maybe<Scalars['Float']['output']>;
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly place?: Maybe<Scalars['Float']['output']>;
  readonly score?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "projects" */
export type Projects_Stddev_Samp_Order_By = {
  readonly build_code?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "projects" */
export type Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Projects_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly build_code?: InputMaybe<Scalars['Int']['input']>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly creator_id?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly files?: InputMaybe<Scalars['jsonb']['input']>;
  readonly highlights?: InputMaybe<Scalars['jsonb']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly images?: InputMaybe<Scalars['jsonb']['input']>;
  readonly judge_id?: InputMaybe<Scalars['String']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly money?: InputMaybe<Scalars['numeric']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly place?: InputMaybe<Scalars['Int']['input']>;
  readonly prize?: InputMaybe<Scalars['jsonb']['input']>;
  readonly render?: InputMaybe<Scalars['String']['input']>;
  readonly review_text?: InputMaybe<Scalars['String']['input']>;
  readonly rubric?: InputMaybe<Scalars['jsonb']['input']>;
  readonly score?: InputMaybe<Scalars['Int']['input']>;
  readonly special_prizes?: InputMaybe<Scalars['jsonb']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly submitter_id?: InputMaybe<Scalars['String']['input']>;
  readonly topic_id?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Projects_Sum_Fields = {
  readonly __typename?: 'projects_sum_fields';
  readonly build_code?: Maybe<Scalars['Int']['output']>;
  readonly money?: Maybe<Scalars['numeric']['output']>;
  readonly place?: Maybe<Scalars['Int']['output']>;
  readonly score?: Maybe<Scalars['Int']['output']>;
  readonly xp?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "projects" */
export type Projects_Sum_Order_By = {
  readonly build_code?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** columns and relationships of "projects_templates" */
export type Projects_Templates = {
  readonly __typename?: 'projects_templates';
  readonly created_at: Scalars['timestamptz']['output'];
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id: Scalars['String']['output'];
  readonly meta?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  readonly project: Projects;
  readonly project_id: Scalars['String']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "projects_templates" */
export type Projects_TemplatesMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "projects_templates" */
export type Projects_Templates_Aggregate = {
  readonly __typename?: 'projects_templates_aggregate';
  readonly aggregate?: Maybe<Projects_Templates_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Projects_Templates>;
};

/** aggregate fields of "projects_templates" */
export type Projects_Templates_Aggregate_Fields = {
  readonly __typename?: 'projects_templates_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Projects_Templates_Max_Fields>;
  readonly min?: Maybe<Projects_Templates_Min_Fields>;
};


/** aggregate fields of "projects_templates" */
export type Projects_Templates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Projects_Templates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Projects_Templates_Append_Input = {
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "projects_templates". All fields are combined with a logical 'AND'. */
export type Projects_Templates_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Projects_Templates_Bool_Exp>>;
  readonly _not?: InputMaybe<Projects_Templates_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Projects_Templates_Bool_Exp>>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly meta?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly project?: InputMaybe<Projects_Bool_Exp>;
  readonly project_id?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "projects_templates" */
export enum Projects_Templates_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectsTemplatesPkey = 'projects_templates_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Projects_Templates_Delete_At_Path_Input = {
  readonly meta?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Projects_Templates_Delete_Elem_Input = {
  readonly meta?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Projects_Templates_Delete_Key_Input = {
  readonly meta?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "projects_templates" */
export type Projects_Templates_Insert_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Projects_Templates_Max_Fields = {
  readonly __typename?: 'projects_templates_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly project_id?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Projects_Templates_Min_Fields = {
  readonly __typename?: 'projects_templates_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly project_id?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "projects_templates" */
export type Projects_Templates_Mutation_Response = {
  readonly __typename?: 'projects_templates_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Projects_Templates>;
};

/** on_conflict condition type for table "projects_templates" */
export type Projects_Templates_On_Conflict = {
  readonly constraint: Projects_Templates_Constraint;
  readonly update_columns?: ReadonlyArray<Projects_Templates_Update_Column>;
  readonly where?: InputMaybe<Projects_Templates_Bool_Exp>;
};

/** Ordering options when selecting data from "projects_templates". */
export type Projects_Templates_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly meta?: InputMaybe<Order_By>;
  readonly project?: InputMaybe<Projects_Order_By>;
  readonly project_id?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: projects_templates */
export type Projects_Templates_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Projects_Templates_Prepend_Input = {
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "projects_templates" */
export enum Projects_Templates_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "projects_templates" */
export type Projects_Templates_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "projects_templates" */
export type Projects_Templates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Projects_Templates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Projects_Templates_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "projects_templates" */
export enum Projects_Templates_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Projects_Templates_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Projects_Templates_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Projects_Templates_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Projects_Templates_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Projects_Templates_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Projects_Templates_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Projects_Templates_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Projects_Templates_Bool_Exp;
};

/** update columns of table "projects" */
export enum Projects_Update_Column {
  /** column name */
  BuildCode = 'build_code',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Description = 'description',
  /** column name */
  Files = 'files',
  /** column name */
  Highlights = 'highlights',
  /** column name */
  Id = 'id',
  /** column name */
  Images = 'images',
  /** column name */
  JudgeId = 'judge_id',
  /** column name */
  Meta = 'meta',
  /** column name */
  Money = 'money',
  /** column name */
  Name = 'name',
  /** column name */
  Place = 'place',
  /** column name */
  Prize = 'prize',
  /** column name */
  Render = 'render',
  /** column name */
  ReviewText = 'review_text',
  /** column name */
  Rubric = 'rubric',
  /** column name */
  Score = 'score',
  /** column name */
  SpecialPrizes = 'special_prizes',
  /** column name */
  Status = 'status',
  /** column name */
  SubmitterId = 'submitter_id',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Xp = 'xp'
}

export type Projects_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Projects_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Projects_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Projects_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Projects_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Projects_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Projects_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Projects_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Projects_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Projects_Var_Pop_Fields = {
  readonly __typename?: 'projects_var_pop_fields';
  readonly build_code?: Maybe<Scalars['Float']['output']>;
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly place?: Maybe<Scalars['Float']['output']>;
  readonly score?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "projects" */
export type Projects_Var_Pop_Order_By = {
  readonly build_code?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Projects_Var_Samp_Fields = {
  readonly __typename?: 'projects_var_samp_fields';
  readonly build_code?: Maybe<Scalars['Float']['output']>;
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly place?: Maybe<Scalars['Float']['output']>;
  readonly score?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "projects" */
export type Projects_Var_Samp_Order_By = {
  readonly build_code?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Projects_Variance_Fields = {
  readonly __typename?: 'projects_variance_fields';
  readonly build_code?: Maybe<Scalars['Float']['output']>;
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly place?: Maybe<Scalars['Float']['output']>;
  readonly score?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "projects" */
export type Projects_Variance_Order_By = {
  readonly build_code?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly place?: InputMaybe<Order_By>;
  readonly score?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** columns and relationships of "prospective_users" */
export type Prospective_Users = {
  readonly __typename?: 'prospective_users';
  readonly age?: Maybe<Scalars['Int']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly first_name?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['uuid']['output'];
  readonly last_name?: Maybe<Scalars['String']['output']>;
  readonly parent_email?: Maybe<Scalars['String']['output']>;
  readonly parent_first_name?: Maybe<Scalars['String']['output']>;
  readonly parent_last_name?: Maybe<Scalars['String']['output']>;
  readonly parent_phone?: Maybe<Scalars['String']['output']>;
  readonly phone?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly purchases: ReadonlyArray<Prospective_Users_Purchases>;
  /** An aggregate relationship */
  readonly purchases_aggregate: Prospective_Users_Purchases_Aggregate;
  /** An object relationship */
  readonly user?: Maybe<Users>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "prospective_users" */
export type Prospective_UsersPurchasesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Order_By>>;
  where?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
};


/** columns and relationships of "prospective_users" */
export type Prospective_UsersPurchases_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Order_By>>;
  where?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
};

/** aggregated selection of "prospective_users" */
export type Prospective_Users_Aggregate = {
  readonly __typename?: 'prospective_users_aggregate';
  readonly aggregate?: Maybe<Prospective_Users_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Prospective_Users>;
};

/** aggregate fields of "prospective_users" */
export type Prospective_Users_Aggregate_Fields = {
  readonly __typename?: 'prospective_users_aggregate_fields';
  readonly avg?: Maybe<Prospective_Users_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Prospective_Users_Max_Fields>;
  readonly min?: Maybe<Prospective_Users_Min_Fields>;
  readonly stddev?: Maybe<Prospective_Users_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Prospective_Users_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Prospective_Users_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Prospective_Users_Sum_Fields>;
  readonly var_pop?: Maybe<Prospective_Users_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Prospective_Users_Var_Samp_Fields>;
  readonly variance?: Maybe<Prospective_Users_Variance_Fields>;
};


/** aggregate fields of "prospective_users" */
export type Prospective_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Prospective_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Prospective_Users_Avg_Fields = {
  readonly __typename?: 'prospective_users_avg_fields';
  readonly age?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "prospective_users". All fields are combined with a logical 'AND'. */
export type Prospective_Users_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Prospective_Users_Bool_Exp>>;
  readonly _not?: InputMaybe<Prospective_Users_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Prospective_Users_Bool_Exp>>;
  readonly age?: InputMaybe<Int_Comparison_Exp>;
  readonly email?: InputMaybe<String_Comparison_Exp>;
  readonly first_name?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly last_name?: InputMaybe<String_Comparison_Exp>;
  readonly parent_email?: InputMaybe<String_Comparison_Exp>;
  readonly parent_first_name?: InputMaybe<String_Comparison_Exp>;
  readonly parent_last_name?: InputMaybe<String_Comparison_Exp>;
  readonly parent_phone?: InputMaybe<String_Comparison_Exp>;
  readonly phone?: InputMaybe<String_Comparison_Exp>;
  readonly purchases?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
  readonly purchases_aggregate?: InputMaybe<Prospective_Users_Purchases_Aggregate_Bool_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "prospective_users" */
export enum Prospective_Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProspectiveUsersPkey = 'prospective_users_pkey'
}

/** input type for incrementing numeric columns in table "prospective_users" */
export type Prospective_Users_Inc_Input = {
  readonly age?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "prospective_users" */
export type Prospective_Users_Insert_Input = {
  readonly age?: InputMaybe<Scalars['Int']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly first_name?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly last_name?: InputMaybe<Scalars['String']['input']>;
  readonly parent_email?: InputMaybe<Scalars['String']['input']>;
  readonly parent_first_name?: InputMaybe<Scalars['String']['input']>;
  readonly parent_last_name?: InputMaybe<Scalars['String']['input']>;
  readonly parent_phone?: InputMaybe<Scalars['String']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly purchases?: InputMaybe<Prospective_Users_Purchases_Arr_Rel_Insert_Input>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Prospective_Users_Max_Fields = {
  readonly __typename?: 'prospective_users_max_fields';
  readonly age?: Maybe<Scalars['Int']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly first_name?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly last_name?: Maybe<Scalars['String']['output']>;
  readonly parent_email?: Maybe<Scalars['String']['output']>;
  readonly parent_first_name?: Maybe<Scalars['String']['output']>;
  readonly parent_last_name?: Maybe<Scalars['String']['output']>;
  readonly parent_phone?: Maybe<Scalars['String']['output']>;
  readonly phone?: Maybe<Scalars['String']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Prospective_Users_Min_Fields = {
  readonly __typename?: 'prospective_users_min_fields';
  readonly age?: Maybe<Scalars['Int']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly first_name?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly last_name?: Maybe<Scalars['String']['output']>;
  readonly parent_email?: Maybe<Scalars['String']['output']>;
  readonly parent_first_name?: Maybe<Scalars['String']['output']>;
  readonly parent_last_name?: Maybe<Scalars['String']['output']>;
  readonly parent_phone?: Maybe<Scalars['String']['output']>;
  readonly phone?: Maybe<Scalars['String']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "prospective_users" */
export type Prospective_Users_Mutation_Response = {
  readonly __typename?: 'prospective_users_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Prospective_Users>;
};

/** input type for inserting object relation for remote table "prospective_users" */
export type Prospective_Users_Obj_Rel_Insert_Input = {
  readonly data: Prospective_Users_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Prospective_Users_On_Conflict>;
};

/** on_conflict condition type for table "prospective_users" */
export type Prospective_Users_On_Conflict = {
  readonly constraint: Prospective_Users_Constraint;
  readonly update_columns?: ReadonlyArray<Prospective_Users_Update_Column>;
  readonly where?: InputMaybe<Prospective_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "prospective_users". */
export type Prospective_Users_Order_By = {
  readonly age?: InputMaybe<Order_By>;
  readonly email?: InputMaybe<Order_By>;
  readonly first_name?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly last_name?: InputMaybe<Order_By>;
  readonly parent_email?: InputMaybe<Order_By>;
  readonly parent_first_name?: InputMaybe<Order_By>;
  readonly parent_last_name?: InputMaybe<Order_By>;
  readonly parent_phone?: InputMaybe<Order_By>;
  readonly phone?: InputMaybe<Order_By>;
  readonly purchases_aggregate?: InputMaybe<Prospective_Users_Purchases_Aggregate_Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: prospective_users */
export type Prospective_Users_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** columns and relationships of "prospective_users_purchases" */
export type Prospective_Users_Purchases = {
  readonly __typename?: 'prospective_users_purchases';
  readonly checkout_session_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly club: Clubs;
  readonly club_id: Scalars['uuid']['output'];
  readonly payment_status: Scalars['String']['output'];
  /** An object relationship */
  readonly prospective_user: Prospective_Users;
  readonly prospective_user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "prospective_users_purchases" */
export type Prospective_Users_Purchases_Aggregate = {
  readonly __typename?: 'prospective_users_purchases_aggregate';
  readonly aggregate?: Maybe<Prospective_Users_Purchases_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Prospective_Users_Purchases>;
};

export type Prospective_Users_Purchases_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Prospective_Users_Purchases_Aggregate_Bool_Exp_Count>;
};

export type Prospective_Users_Purchases_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "prospective_users_purchases" */
export type Prospective_Users_Purchases_Aggregate_Fields = {
  readonly __typename?: 'prospective_users_purchases_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Prospective_Users_Purchases_Max_Fields>;
  readonly min?: Maybe<Prospective_Users_Purchases_Min_Fields>;
};


/** aggregate fields of "prospective_users_purchases" */
export type Prospective_Users_Purchases_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "prospective_users_purchases" */
export type Prospective_Users_Purchases_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Prospective_Users_Purchases_Max_Order_By>;
  readonly min?: InputMaybe<Prospective_Users_Purchases_Min_Order_By>;
};

/** input type for inserting array relation for remote table "prospective_users_purchases" */
export type Prospective_Users_Purchases_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Prospective_Users_Purchases_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Prospective_Users_Purchases_On_Conflict>;
};

/** Boolean expression to filter rows from the table "prospective_users_purchases". All fields are combined with a logical 'AND'. */
export type Prospective_Users_Purchases_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Bool_Exp>>;
  readonly _not?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Bool_Exp>>;
  readonly checkout_session_id?: InputMaybe<String_Comparison_Exp>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly club_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly payment_status?: InputMaybe<String_Comparison_Exp>;
  readonly prospective_user?: InputMaybe<Prospective_Users_Bool_Exp>;
  readonly prospective_user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "prospective_users_purchases" */
export enum Prospective_Users_Purchases_Constraint {
  /** unique or primary key constraint on columns "club_id", "prospective_user_id" */
  ProspectiveUsersPurchasesPkey = 'prospective_users_purchases_pkey'
}

/** input type for inserting data into table "prospective_users_purchases" */
export type Prospective_Users_Purchases_Insert_Input = {
  readonly checkout_session_id?: InputMaybe<Scalars['String']['input']>;
  readonly club?: InputMaybe<Clubs_Obj_Rel_Insert_Input>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly payment_status?: InputMaybe<Scalars['String']['input']>;
  readonly prospective_user?: InputMaybe<Prospective_Users_Obj_Rel_Insert_Input>;
  readonly prospective_user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Prospective_Users_Purchases_Max_Fields = {
  readonly __typename?: 'prospective_users_purchases_max_fields';
  readonly checkout_session_id?: Maybe<Scalars['String']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly payment_status?: Maybe<Scalars['String']['output']>;
  readonly prospective_user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "prospective_users_purchases" */
export type Prospective_Users_Purchases_Max_Order_By = {
  readonly checkout_session_id?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly payment_status?: InputMaybe<Order_By>;
  readonly prospective_user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Prospective_Users_Purchases_Min_Fields = {
  readonly __typename?: 'prospective_users_purchases_min_fields';
  readonly checkout_session_id?: Maybe<Scalars['String']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly payment_status?: Maybe<Scalars['String']['output']>;
  readonly prospective_user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "prospective_users_purchases" */
export type Prospective_Users_Purchases_Min_Order_By = {
  readonly checkout_session_id?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly payment_status?: InputMaybe<Order_By>;
  readonly prospective_user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "prospective_users_purchases" */
export type Prospective_Users_Purchases_Mutation_Response = {
  readonly __typename?: 'prospective_users_purchases_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Prospective_Users_Purchases>;
};

/** on_conflict condition type for table "prospective_users_purchases" */
export type Prospective_Users_Purchases_On_Conflict = {
  readonly constraint: Prospective_Users_Purchases_Constraint;
  readonly update_columns?: ReadonlyArray<Prospective_Users_Purchases_Update_Column>;
  readonly where?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
};

/** Ordering options when selecting data from "prospective_users_purchases". */
export type Prospective_Users_Purchases_Order_By = {
  readonly checkout_session_id?: InputMaybe<Order_By>;
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly payment_status?: InputMaybe<Order_By>;
  readonly prospective_user?: InputMaybe<Prospective_Users_Order_By>;
  readonly prospective_user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: prospective_users_purchases */
export type Prospective_Users_Purchases_Pk_Columns_Input = {
  readonly club_id: Scalars['uuid']['input'];
  readonly prospective_user_id: Scalars['uuid']['input'];
};

/** select columns of table "prospective_users_purchases" */
export enum Prospective_Users_Purchases_Select_Column {
  /** column name */
  CheckoutSessionId = 'checkout_session_id',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  PaymentStatus = 'payment_status',
  /** column name */
  ProspectiveUserId = 'prospective_user_id'
}

/** input type for updating data in table "prospective_users_purchases" */
export type Prospective_Users_Purchases_Set_Input = {
  readonly checkout_session_id?: InputMaybe<Scalars['String']['input']>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly payment_status?: InputMaybe<Scalars['String']['input']>;
  readonly prospective_user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "prospective_users_purchases" */
export type Prospective_Users_Purchases_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Prospective_Users_Purchases_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prospective_Users_Purchases_Stream_Cursor_Value_Input = {
  readonly checkout_session_id?: InputMaybe<Scalars['String']['input']>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly payment_status?: InputMaybe<Scalars['String']['input']>;
  readonly prospective_user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "prospective_users_purchases" */
export enum Prospective_Users_Purchases_Update_Column {
  /** column name */
  CheckoutSessionId = 'checkout_session_id',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  PaymentStatus = 'payment_status',
  /** column name */
  ProspectiveUserId = 'prospective_user_id'
}

export type Prospective_Users_Purchases_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Prospective_Users_Purchases_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Prospective_Users_Purchases_Bool_Exp;
};

/** select columns of table "prospective_users" */
export enum Prospective_Users_Select_Column {
  /** column name */
  Age = 'age',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  ParentEmail = 'parent_email',
  /** column name */
  ParentFirstName = 'parent_first_name',
  /** column name */
  ParentLastName = 'parent_last_name',
  /** column name */
  ParentPhone = 'parent_phone',
  /** column name */
  Phone = 'phone',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "prospective_users" */
export type Prospective_Users_Set_Input = {
  readonly age?: InputMaybe<Scalars['Int']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly first_name?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly last_name?: InputMaybe<Scalars['String']['input']>;
  readonly parent_email?: InputMaybe<Scalars['String']['input']>;
  readonly parent_first_name?: InputMaybe<Scalars['String']['input']>;
  readonly parent_last_name?: InputMaybe<Scalars['String']['input']>;
  readonly parent_phone?: InputMaybe<Scalars['String']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Prospective_Users_Stddev_Fields = {
  readonly __typename?: 'prospective_users_stddev_fields';
  readonly age?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Prospective_Users_Stddev_Pop_Fields = {
  readonly __typename?: 'prospective_users_stddev_pop_fields';
  readonly age?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Prospective_Users_Stddev_Samp_Fields = {
  readonly __typename?: 'prospective_users_stddev_samp_fields';
  readonly age?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "prospective_users" */
export type Prospective_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Prospective_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prospective_Users_Stream_Cursor_Value_Input = {
  readonly age?: InputMaybe<Scalars['Int']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly first_name?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly last_name?: InputMaybe<Scalars['String']['input']>;
  readonly parent_email?: InputMaybe<Scalars['String']['input']>;
  readonly parent_first_name?: InputMaybe<Scalars['String']['input']>;
  readonly parent_last_name?: InputMaybe<Scalars['String']['input']>;
  readonly parent_phone?: InputMaybe<Scalars['String']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Prospective_Users_Sum_Fields = {
  readonly __typename?: 'prospective_users_sum_fields';
  readonly age?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "prospective_users" */
export enum Prospective_Users_Update_Column {
  /** column name */
  Age = 'age',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  ParentEmail = 'parent_email',
  /** column name */
  ParentFirstName = 'parent_first_name',
  /** column name */
  ParentLastName = 'parent_last_name',
  /** column name */
  ParentPhone = 'parent_phone',
  /** column name */
  Phone = 'phone',
  /** column name */
  UserId = 'user_id'
}

export type Prospective_Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Prospective_Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Prospective_Users_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Prospective_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Prospective_Users_Var_Pop_Fields = {
  readonly __typename?: 'prospective_users_var_pop_fields';
  readonly age?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Prospective_Users_Var_Samp_Fields = {
  readonly __typename?: 'prospective_users_var_samp_fields';
  readonly age?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Prospective_Users_Variance_Fields = {
  readonly __typename?: 'prospective_users_variance_fields';
  readonly age?: Maybe<Scalars['Float']['output']>;
};

export type Query_Root = {
  readonly __typename?: 'query_root';
  /** An array relationship */
  readonly accounts: ReadonlyArray<Accounts>;
  /** An aggregate relationship */
  readonly accounts_aggregate: Accounts_Aggregate;
  /** fetch data from the table: "accounts" using primary key columns */
  readonly accounts_by_pk?: Maybe<Accounts>;
  /** fetch data from the table: "asa_user_quest_task" */
  readonly asa_user_quest_task: ReadonlyArray<Asa_User_Quest_Task>;
  /** fetch aggregated fields from the table: "asa_user_quest_task" */
  readonly asa_user_quest_task_aggregate: Asa_User_Quest_Task_Aggregate;
  /** fetch data from the table: "asa_user_quest_task" using primary key columns */
  readonly asa_user_quest_task_by_pk?: Maybe<Asa_User_Quest_Task>;
  /** fetch data from the table: "avatars" */
  readonly avatars: ReadonlyArray<Avatars>;
  /** fetch aggregated fields from the table: "avatars" */
  readonly avatars_aggregate: Avatars_Aggregate;
  /** fetch data from the table: "avatars" using primary key columns */
  readonly avatars_by_pk?: Maybe<Avatars>;
  /** An array relationship */
  readonly badges: ReadonlyArray<Badges>;
  /** An aggregate relationship */
  readonly badges_aggregate: Badges_Aggregate;
  /** fetch data from the table: "badges" using primary key columns */
  readonly badges_by_pk?: Maybe<Badges>;
  /** fetch data from the table: "bookings" */
  readonly bookings: ReadonlyArray<Bookings>;
  /** fetch aggregated fields from the table: "bookings" */
  readonly bookings_aggregate: Bookings_Aggregate;
  /** fetch data from the table: "bookings" using primary key columns */
  readonly bookings_by_pk?: Maybe<Bookings>;
  /** An array relationship */
  readonly breakouts: ReadonlyArray<Breakouts>;
  /** An aggregate relationship */
  readonly breakouts_aggregate: Breakouts_Aggregate;
  /** fetch data from the table: "breakouts" using primary key columns */
  readonly breakouts_by_pk?: Maybe<Breakouts>;
  /** fetch data from the table: "build_battles" */
  readonly build_battles: ReadonlyArray<Build_Battles>;
  /** fetch aggregated fields from the table: "build_battles" */
  readonly build_battles_aggregate: Build_Battles_Aggregate;
  /** fetch data from the table: "build_battles" using primary key columns */
  readonly build_battles_by_pk?: Maybe<Build_Battles>;
  /** fetch data from the table: "build_battles_projects" */
  readonly build_battles_projects: ReadonlyArray<Build_Battles_Projects>;
  /** fetch aggregated fields from the table: "build_battles_projects" */
  readonly build_battles_projects_aggregate: Build_Battles_Projects_Aggregate;
  /** fetch data from the table: "build_battles_projects" using primary key columns */
  readonly build_battles_projects_by_pk?: Maybe<Build_Battles_Projects>;
  /** fetch data from the table: "build_battles_themes" */
  readonly build_battles_themes: ReadonlyArray<Build_Battles_Themes>;
  /** fetch aggregated fields from the table: "build_battles_themes" */
  readonly build_battles_themes_aggregate: Build_Battles_Themes_Aggregate;
  /** fetch data from the table: "build_battles_themes" using primary key columns */
  readonly build_battles_themes_by_pk?: Maybe<Build_Battles_Themes>;
  /** fetch data from the table: "channels" */
  readonly channels: ReadonlyArray<Channels>;
  /** fetch aggregated fields from the table: "channels" */
  readonly channels_aggregate: Channels_Aggregate;
  /** fetch data from the table: "channels" using primary key columns */
  readonly channels_by_pk?: Maybe<Channels>;
  /** fetch data from the table: "channels_events" */
  readonly channels_events: ReadonlyArray<Channels_Events>;
  /** fetch aggregated fields from the table: "channels_events" */
  readonly channels_events_aggregate: Channels_Events_Aggregate;
  /** fetch data from the table: "channels_events" using primary key columns */
  readonly channels_events_by_pk?: Maybe<Channels_Events>;
  /** An array relationship */
  readonly channels_users: ReadonlyArray<Channels_Users>;
  /** An aggregate relationship */
  readonly channels_users_aggregate: Channels_Users_Aggregate;
  /** fetch data from the table: "channels_users" using primary key columns */
  readonly channels_users_by_pk?: Maybe<Channels_Users>;
  /** An array relationship */
  readonly chats: ReadonlyArray<Chats>;
  /** An aggregate relationship */
  readonly chats_aggregate: Chats_Aggregate;
  /** fetch data from the table: "chats" using primary key columns */
  readonly chats_by_pk?: Maybe<Chats>;
  /** An array relationship */
  readonly clubs: ReadonlyArray<Clubs>;
  /** An aggregate relationship */
  readonly clubs_aggregate: Clubs_Aggregate;
  /** fetch data from the table: "clubs" using primary key columns */
  readonly clubs_by_pk?: Maybe<Clubs>;
  /** fetch data from the table: "clubs_members" */
  readonly clubs_members: ReadonlyArray<Clubs_Members>;
  /** fetch aggregated fields from the table: "clubs_members" */
  readonly clubs_members_aggregate: Clubs_Members_Aggregate;
  /** fetch data from the table: "clubs_members" using primary key columns */
  readonly clubs_members_by_pk?: Maybe<Clubs_Members>;
  /** An array relationship */
  readonly comments: ReadonlyArray<Comments>;
  /** An aggregate relationship */
  readonly comments_aggregate: Comments_Aggregate;
  /** fetch data from the table: "comments" using primary key columns */
  readonly comments_by_pk?: Maybe<Comments>;
  /** An array relationship */
  readonly comments_votes: ReadonlyArray<Comments_Votes>;
  /** An aggregate relationship */
  readonly comments_votes_aggregate: Comments_Votes_Aggregate;
  /** fetch data from the table: "comments_votes" using primary key columns */
  readonly comments_votes_by_pk?: Maybe<Comments_Votes>;
  /** An array relationship */
  readonly competitions: ReadonlyArray<Competitions>;
  /** An aggregate relationship */
  readonly competitions_aggregate: Competitions_Aggregate;
  /** fetch data from the table: "competitions" using primary key columns */
  readonly competitions_by_pk?: Maybe<Competitions>;
  /** fetch data from the table: "competitions_judges" */
  readonly competitions_judges: ReadonlyArray<Competitions_Judges>;
  /** fetch aggregated fields from the table: "competitions_judges" */
  readonly competitions_judges_aggregate: Competitions_Judges_Aggregate;
  /** fetch data from the table: "competitions_judges" using primary key columns */
  readonly competitions_judges_by_pk?: Maybe<Competitions_Judges>;
  /** fetch data from the table: "competitions_tags" */
  readonly competitions_tags: ReadonlyArray<Competitions_Tags>;
  /** fetch aggregated fields from the table: "competitions_tags" */
  readonly competitions_tags_aggregate: Competitions_Tags_Aggregate;
  /** fetch data from the table: "competitions_tags" using primary key columns */
  readonly competitions_tags_by_pk?: Maybe<Competitions_Tags>;
  /** fetch data from the table: "competitions_tutorials" */
  readonly competitions_tutorials: ReadonlyArray<Competitions_Tutorials>;
  /** fetch aggregated fields from the table: "competitions_tutorials" */
  readonly competitions_tutorials_aggregate: Competitions_Tutorials_Aggregate;
  /** fetch data from the table: "competitions_tutorials" using primary key columns */
  readonly competitions_tutorials_by_pk?: Maybe<Competitions_Tutorials>;
  /** fetch data from the table: "eban_comments" */
  readonly eban_comments: ReadonlyArray<Eban_Comments>;
  /** fetch aggregated fields from the table: "eban_comments" */
  readonly eban_comments_aggregate: Eban_Comments_Aggregate;
  /** fetch data from the table: "eban_comments" using primary key columns */
  readonly eban_comments_by_pk?: Maybe<Eban_Comments>;
  /** fetch data from the table: "eban_types" */
  readonly eban_types: ReadonlyArray<Eban_Types>;
  /** fetch aggregated fields from the table: "eban_types" */
  readonly eban_types_aggregate: Eban_Types_Aggregate;
  /** fetch data from the table: "eban_types" using primary key columns */
  readonly eban_types_by_pk?: Maybe<Eban_Types>;
  /** fetch data from the table: "ebans" */
  readonly ebans: ReadonlyArray<Ebans>;
  /** fetch aggregated fields from the table: "ebans" */
  readonly ebans_aggregate: Ebans_Aggregate;
  /** fetch data from the table: "ebans" using primary key columns */
  readonly ebans_by_pk?: Maybe<Ebans>;
  /** fetch data from the table: "environment" */
  readonly environment: ReadonlyArray<Environment>;
  /** fetch aggregated fields from the table: "environment" */
  readonly environment_aggregate: Environment_Aggregate;
  /** fetch data from the table: "environment" using primary key columns */
  readonly environment_by_pk?: Maybe<Environment>;
  /** Fetches a result from the shared_cached_queries. If no such entry exists or it's stale (max_age exceeded), hits /api/cache/shared to perform the query. */
  readonly execute_shared_cached_query: ReadonlyArray<Shared_Cached_Queries>;
  /** execute function "execute_shared_cached_query" and query aggregates on result of table type "shared_cached_queries" */
  readonly execute_shared_cached_query_aggregate: Shared_Cached_Queries_Aggregate;
  /** Taking into consideration max age, fetches a result from the users_cached_queries. If no such entry exists, hits /api/cache/user to perform the query. */
  readonly execute_users_cached_query: ReadonlyArray<Users_Cached_Queries>;
  /** execute function "execute_users_cached_query" and query aggregates on result of table type "users_cached_queries" */
  readonly execute_users_cached_query_aggregate: Users_Cached_Queries_Aggregate;
  /** fetch data from the table: "features" */
  readonly features: ReadonlyArray<Features>;
  /** fetch aggregated fields from the table: "features" */
  readonly features_aggregate: Features_Aggregate;
  /** fetch data from the table: "features" using primary key columns */
  readonly features_by_pk?: Maybe<Features>;
  /** fetch data from the table: "features_products" */
  readonly features_products: ReadonlyArray<Features_Products>;
  /** fetch aggregated fields from the table: "features_products" */
  readonly features_products_aggregate: Features_Products_Aggregate;
  /** fetch data from the table: "features_products" using primary key columns */
  readonly features_products_by_pk?: Maybe<Features_Products>;
  /** An array relationship */
  readonly feedback: ReadonlyArray<Feedback>;
  /** An aggregate relationship */
  readonly feedback_aggregate: Feedback_Aggregate;
  /** fetch data from the table: "feedback" using primary key columns */
  readonly feedback_by_pk?: Maybe<Feedback>;
  /** An array relationship */
  readonly feeds: ReadonlyArray<Feeds>;
  /** An aggregate relationship */
  readonly feeds_aggregate: Feeds_Aggregate;
  /** fetch data from the table: "feeds" using primary key columns */
  readonly feeds_by_pk?: Maybe<Feeds>;
  /** fetch data from the table: "follows" */
  readonly follows: ReadonlyArray<Follows>;
  /** fetch aggregated fields from the table: "follows" */
  readonly follows_aggregate: Follows_Aggregate;
  /** fetch data from the table: "follows" using primary key columns */
  readonly follows_by_pk?: Maybe<Follows>;
  /** fetch data from the table: "friends" */
  readonly friends: ReadonlyArray<Friends>;
  /** fetch aggregated fields from the table: "friends" */
  readonly friends_aggregate: Friends_Aggregate;
  /** fetch data from the table: "friends" using primary key columns */
  readonly friends_by_pk?: Maybe<Friends>;
  /** fetch data from the table: "friendships" */
  readonly friendships: ReadonlyArray<Friendships>;
  /** fetch aggregated fields from the table: "friendships" */
  readonly friendships_aggregate: Friendships_Aggregate;
  /** fetch data from the table: "friendships" using primary key columns */
  readonly friendships_by_pk?: Maybe<Friendships>;
  /** An array relationship */
  readonly friendships_users: ReadonlyArray<Friendships_Users>;
  /** An aggregate relationship */
  readonly friendships_users_aggregate: Friendships_Users_Aggregate;
  /** fetch data from the table: "friendships_users" using primary key columns */
  readonly friendships_users_by_pk?: Maybe<Friendships_Users>;
  /** fetch data from the table: "game_servers" */
  readonly game_servers: ReadonlyArray<Game_Servers>;
  /** fetch aggregated fields from the table: "game_servers" */
  readonly game_servers_aggregate: Game_Servers_Aggregate;
  /** fetch data from the table: "game_servers" using primary key columns */
  readonly game_servers_by_pk?: Maybe<Game_Servers>;
  readonly getAllAvatars?: Maybe<GetAllAvatarsOutput>;
  /** fetch data from the table: "homes" */
  readonly homes: ReadonlyArray<Homes>;
  /** fetch aggregated fields from the table: "homes" */
  readonly homes_aggregate: Homes_Aggregate;
  /** fetch data from the table: "homes" using primary key columns */
  readonly homes_by_pk?: Maybe<Homes>;
  /** fetch data from the table: "image_cache" */
  readonly image_cache: ReadonlyArray<Image_Cache>;
  /** fetch aggregated fields from the table: "image_cache" */
  readonly image_cache_aggregate: Image_Cache_Aggregate;
  /** fetch data from the table: "image_cache" using primary key columns */
  readonly image_cache_by_pk?: Maybe<Image_Cache>;
  /** fetch data from the table: "infrastructure_operations" */
  readonly infrastructure_operations: ReadonlyArray<Infrastructure_Operations>;
  /** fetch aggregated fields from the table: "infrastructure_operations" */
  readonly infrastructure_operations_aggregate: Infrastructure_Operations_Aggregate;
  /** fetch data from the table: "items" */
  readonly items: ReadonlyArray<Items>;
  /** fetch aggregated fields from the table: "items" */
  readonly items_aggregate: Items_Aggregate;
  /** fetch data from the table: "items" using primary key columns */
  readonly items_by_pk?: Maybe<Items>;
  /** fetch data from the table: "items_tags" */
  readonly items_tags: ReadonlyArray<Items_Tags>;
  /** fetch aggregated fields from the table: "items_tags" */
  readonly items_tags_aggregate: Items_Tags_Aggregate;
  /** fetch data from the table: "items_tags" using primary key columns */
  readonly items_tags_by_pk?: Maybe<Items_Tags>;
  /** fetch data from the table: "levels" */
  readonly levels: ReadonlyArray<Levels>;
  /** fetch aggregated fields from the table: "levels" */
  readonly levels_aggregate: Levels_Aggregate;
  /** fetch data from the table: "levels" using primary key columns */
  readonly levels_by_pk?: Maybe<Levels>;
  /** An array relationship */
  readonly live_events: ReadonlyArray<Live_Events>;
  /** An aggregate relationship */
  readonly live_events_aggregate: Live_Events_Aggregate;
  /** fetch data from the table: "live_events" using primary key columns */
  readonly live_events_by_pk?: Maybe<Live_Events>;
  /** fetch data from the table: "live_events_hosts" */
  readonly live_events_hosts: ReadonlyArray<Live_Events_Hosts>;
  /** fetch aggregated fields from the table: "live_events_hosts" */
  readonly live_events_hosts_aggregate: Live_Events_Hosts_Aggregate;
  /** fetch data from the table: "live_events_hosts" using primary key columns */
  readonly live_events_hosts_by_pk?: Maybe<Live_Events_Hosts>;
  /** fetch data from the table: "messages" */
  readonly messages: ReadonlyArray<Messages>;
  /** fetch aggregated fields from the table: "messages" */
  readonly messages_aggregate: Messages_Aggregate;
  /** fetch data from the table: "messages" using primary key columns */
  readonly messages_by_pk?: Maybe<Messages>;
  /** fetch data from the table: "minecraft_announcement" */
  readonly minecraft_announcement: ReadonlyArray<Minecraft_Announcement>;
  /** fetch aggregated fields from the table: "minecraft_announcement" */
  readonly minecraft_announcement_aggregate: Minecraft_Announcement_Aggregate;
  /** fetch data from the table: "minecraft_announcement" using primary key columns */
  readonly minecraft_announcement_by_pk?: Maybe<Minecraft_Announcement>;
  /** fetch data from the table: "minecraft_servers" */
  readonly minecraft_servers: ReadonlyArray<Minecraft_Servers>;
  /** fetch aggregated fields from the table: "minecraft_servers" */
  readonly minecraft_servers_aggregate: Minecraft_Servers_Aggregate;
  /** fetch data from the table: "minecraft_servers" using primary key columns */
  readonly minecraft_servers_by_pk?: Maybe<Minecraft_Servers>;
  /** fetch data from the table: "minecraft_skins" */
  readonly minecraft_skins: ReadonlyArray<Minecraft_Skins>;
  /** fetch aggregated fields from the table: "minecraft_skins" */
  readonly minecraft_skins_aggregate: Minecraft_Skins_Aggregate;
  /** fetch data from the table: "minecraft_skins" using primary key columns */
  readonly minecraft_skins_by_pk?: Maybe<Minecraft_Skins>;
  /** fetch data from the table: "minecraft_tutorial" */
  readonly minecraft_tutorial: ReadonlyArray<Minecraft_Tutorial>;
  /** fetch aggregated fields from the table: "minecraft_tutorial" */
  readonly minecraft_tutorial_aggregate: Minecraft_Tutorial_Aggregate;
  /** fetch data from the table: "minecraft_tutorial" using primary key columns */
  readonly minecraft_tutorial_by_pk?: Maybe<Minecraft_Tutorial>;
  /** fetch data from the table: "minecraft_tutorial_status" */
  readonly minecraft_tutorial_status: ReadonlyArray<Minecraft_Tutorial_Status>;
  /** fetch aggregated fields from the table: "minecraft_tutorial_status" */
  readonly minecraft_tutorial_status_aggregate: Minecraft_Tutorial_Status_Aggregate;
  /** fetch data from the table: "minecraft_tutorial_status" using primary key columns */
  readonly minecraft_tutorial_status_by_pk?: Maybe<Minecraft_Tutorial_Status>;
  /** fetch data from the table: "minecraft_user" */
  readonly minecraft_user: ReadonlyArray<Minecraft_User>;
  /** fetch aggregated fields from the table: "minecraft_user" */
  readonly minecraft_user_aggregate: Minecraft_User_Aggregate;
  /** An array relationship */
  readonly notifications: ReadonlyArray<Notifications>;
  /** An aggregate relationship */
  readonly notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  readonly notifications_by_pk?: Maybe<Notifications>;
  /** An array relationship */
  readonly orders: ReadonlyArray<Orders>;
  /** An aggregate relationship */
  readonly orders_aggregate: Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  readonly orders_by_pk?: Maybe<Orders>;
  /** fetch data from the table: "otps" */
  readonly otps: ReadonlyArray<Otps>;
  /** fetch aggregated fields from the table: "otps" */
  readonly otps_aggregate: Otps_Aggregate;
  /** fetch data from the table: "otps" using primary key columns */
  readonly otps_by_pk?: Maybe<Otps>;
  /** fetch data from the table: "pages" */
  readonly pages: ReadonlyArray<Pages>;
  /** fetch aggregated fields from the table: "pages" */
  readonly pages_aggregate: Pages_Aggregate;
  /** fetch data from the table: "pages" using primary key columns */
  readonly pages_by_pk?: Maybe<Pages>;
  /** fetch data from the table: "player_worlds" */
  readonly player_worlds: ReadonlyArray<Player_Worlds>;
  /** fetch aggregated fields from the table: "player_worlds" */
  readonly player_worlds_aggregate: Player_Worlds_Aggregate;
  /** fetch data from the table: "player_worlds" using primary key columns */
  readonly player_worlds_by_pk?: Maybe<Player_Worlds>;
  /** An array relationship */
  readonly posts: ReadonlyArray<Posts>;
  /** An aggregate relationship */
  readonly posts_aggregate: Posts_Aggregate;
  /** fetch data from the table: "posts" using primary key columns */
  readonly posts_by_pk?: Maybe<Posts>;
  readonly profile_feed: ReadonlyArray<FeedItem>;
  /** An array relationship */
  readonly projects: ReadonlyArray<Projects>;
  /** An aggregate relationship */
  readonly projects_aggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  readonly projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table: "projects_collaborators" */
  readonly projects_collaborators: ReadonlyArray<Projects_Collaborators>;
  /** fetch aggregated fields from the table: "projects_collaborators" */
  readonly projects_collaborators_aggregate: Projects_Collaborators_Aggregate;
  /** fetch data from the table: "projects_collaborators" using primary key columns */
  readonly projects_collaborators_by_pk?: Maybe<Projects_Collaborators>;
  /** fetch data from the table: "projects_editables" */
  readonly projects_editables: ReadonlyArray<Projects_Editables>;
  /** fetch aggregated fields from the table: "projects_editables" */
  readonly projects_editables_aggregate: Projects_Editables_Aggregate;
  /** fetch data from the table: "projects_leaderboards" */
  readonly projects_leaderboards: ReadonlyArray<Projects_Leaderboards>;
  /** fetch aggregated fields from the table: "projects_leaderboards" */
  readonly projects_leaderboards_aggregate: Projects_Leaderboards_Aggregate;
  /** fetch data from the table: "projects_leaderboards" using primary key columns */
  readonly projects_leaderboards_by_pk?: Maybe<Projects_Leaderboards>;
  /** An array relationship */
  readonly projects_publications: ReadonlyArray<Projects_Publications>;
  /** An aggregate relationship */
  readonly projects_publications_aggregate: Projects_Publications_Aggregate;
  /** fetch data from the table: "projects_publications" using primary key columns */
  readonly projects_publications_by_pk?: Maybe<Projects_Publications>;
  /** fetch data from the table: "projects_publications_votes" */
  readonly projects_publications_votes: ReadonlyArray<Projects_Publications_Votes>;
  /** fetch aggregated fields from the table: "projects_publications_votes" */
  readonly projects_publications_votes_aggregate: Projects_Publications_Votes_Aggregate;
  /** fetch data from the table: "projects_publications_votes" using primary key columns */
  readonly projects_publications_votes_by_pk?: Maybe<Projects_Publications_Votes>;
  /** fetch data from the table: "projects_templates" */
  readonly projects_templates: ReadonlyArray<Projects_Templates>;
  /** fetch aggregated fields from the table: "projects_templates" */
  readonly projects_templates_aggregate: Projects_Templates_Aggregate;
  /** fetch data from the table: "projects_templates" using primary key columns */
  readonly projects_templates_by_pk?: Maybe<Projects_Templates>;
  /** fetch data from the table: "prospective_users" */
  readonly prospective_users: ReadonlyArray<Prospective_Users>;
  /** fetch aggregated fields from the table: "prospective_users" */
  readonly prospective_users_aggregate: Prospective_Users_Aggregate;
  /** fetch data from the table: "prospective_users" using primary key columns */
  readonly prospective_users_by_pk?: Maybe<Prospective_Users>;
  /** An array relationship */
  readonly prospective_users_purchases: ReadonlyArray<Prospective_Users_Purchases>;
  /** An aggregate relationship */
  readonly prospective_users_purchases_aggregate: Prospective_Users_Purchases_Aggregate;
  /** fetch data from the table: "prospective_users_purchases" using primary key columns */
  readonly prospective_users_purchases_by_pk?: Maybe<Prospective_Users_Purchases>;
  /** An array relationship */
  readonly quests: ReadonlyArray<Quests>;
  /** An aggregate relationship */
  readonly quests_aggregate: Quests_Aggregate;
  /** fetch data from the table: "quests" using primary key columns */
  readonly quests_by_pk?: Maybe<Quests>;
  /** fetch data from the table: "queue_user_info" */
  readonly queue_user_info: ReadonlyArray<Queue_User_Info>;
  /** fetch aggregated fields from the table: "queue_user_info" */
  readonly queue_user_info_aggregate: Queue_User_Info_Aggregate;
  /** fetch data from the table: "queue_user_info" using primary key columns */
  readonly queue_user_info_by_pk?: Maybe<Queue_User_Info>;
  /** fetch data from the table: "queues" */
  readonly queues: ReadonlyArray<Queues>;
  /** fetch aggregated fields from the table: "queues" */
  readonly queues_aggregate: Queues_Aggregate;
  /** fetch data from the table: "queues" using primary key columns */
  readonly queues_by_pk?: Maybe<Queues>;
  /** fetch data from the table: "render_jobs" */
  readonly render_jobs: ReadonlyArray<Render_Jobs>;
  /** fetch aggregated fields from the table: "render_jobs" */
  readonly render_jobs_aggregate: Render_Jobs_Aggregate;
  /** fetch data from the table: "render_jobs" using primary key columns */
  readonly render_jobs_by_pk?: Maybe<Render_Jobs>;
  /** fetch data from the table: "reports" */
  readonly reports: ReadonlyArray<Reports>;
  /** fetch aggregated fields from the table: "reports" */
  readonly reports_aggregate: Reports_Aggregate;
  /** fetch data from the table: "reports" using primary key columns */
  readonly reports_by_pk?: Maybe<Reports>;
  /** An array relationship */
  readonly rooms: ReadonlyArray<Rooms>;
  /** An aggregate relationship */
  readonly rooms_aggregate: Rooms_Aggregate;
  /** fetch data from the table: "rooms" using primary key columns */
  readonly rooms_by_pk?: Maybe<Rooms>;
  /** fetch data from the table: "schedules" */
  readonly schedules: ReadonlyArray<Schedules>;
  /** fetch aggregated fields from the table: "schedules" */
  readonly schedules_aggregate: Schedules_Aggregate;
  /** fetch data from the table: "schedules" using primary key columns */
  readonly schedules_by_pk?: Maybe<Schedules>;
  /** fetch data from the table: "shared_cached_queries" */
  readonly shared_cached_queries: ReadonlyArray<Shared_Cached_Queries>;
  /** fetch aggregated fields from the table: "shared_cached_queries" */
  readonly shared_cached_queries_aggregate: Shared_Cached_Queries_Aggregate;
  /** fetch data from the table: "shared_cached_queries" using primary key columns */
  readonly shared_cached_queries_by_pk?: Maybe<Shared_Cached_Queries>;
  /** fetch data from the table: "short_urls" */
  readonly short_urls: ReadonlyArray<Short_Urls>;
  /** fetch aggregated fields from the table: "short_urls" */
  readonly short_urls_aggregate: Short_Urls_Aggregate;
  /** fetch data from the table: "short_urls" using primary key columns */
  readonly short_urls_by_pk?: Maybe<Short_Urls>;
  /** An array relationship */
  readonly subscriptions: ReadonlyArray<Subscriptions>;
  /** An aggregate relationship */
  readonly subscriptions_aggregate: Subscriptions_Aggregate;
  /** fetch data from the table: "subscriptions" using primary key columns */
  readonly subscriptions_by_pk?: Maybe<Subscriptions>;
  /** fetch data from the table: "tags" */
  readonly tags: ReadonlyArray<Tags>;
  /** fetch aggregated fields from the table: "tags" */
  readonly tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "tags_associations" */
  readonly tags_associations: ReadonlyArray<Tags_Associations>;
  /** fetch aggregated fields from the table: "tags_associations" */
  readonly tags_associations_aggregate: Tags_Associations_Aggregate;
  /** fetch data from the table: "tags_associations" using primary key columns */
  readonly tags_associations_by_pk?: Maybe<Tags_Associations>;
  /** fetch data from the table: "tags" using primary key columns */
  readonly tags_by_pk?: Maybe<Tags>;
  /** An array relationship */
  readonly themes: ReadonlyArray<Themes>;
  /** An aggregate relationship */
  readonly themes_aggregate: Themes_Aggregate;
  /** fetch data from the table: "themes" using primary key columns */
  readonly themes_by_pk?: Maybe<Themes>;
  /** fetch data from the table: "tools" */
  readonly tools: ReadonlyArray<Tools>;
  /** fetch aggregated fields from the table: "tools" */
  readonly tools_aggregate: Tools_Aggregate;
  /** fetch data from the table: "tools" using primary key columns */
  readonly tools_by_pk?: Maybe<Tools>;
  /** fetch data from the table: "topics" */
  readonly topics: ReadonlyArray<Topics>;
  /** fetch aggregated fields from the table: "topics" */
  readonly topics_aggregate: Topics_Aggregate;
  /** fetch data from the table: "topics" using primary key columns */
  readonly topics_by_pk?: Maybe<Topics>;
  /** fetch data from the table: "topics_tools" */
  readonly topics_tools: ReadonlyArray<Topics_Tools>;
  /** fetch aggregated fields from the table: "topics_tools" */
  readonly topics_tools_aggregate: Topics_Tools_Aggregate;
  /** fetch data from the table: "topics_tools" using primary key columns */
  readonly topics_tools_by_pk?: Maybe<Topics_Tools>;
  /** An array relationship */
  readonly transactions: ReadonlyArray<Transactions>;
  /** An aggregate relationship */
  readonly transactions_aggregate: Transactions_Aggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  readonly transactions_by_pk?: Maybe<Transactions>;
  /** An array relationship */
  readonly tutorials: ReadonlyArray<Tutorials>;
  /** An aggregate relationship */
  readonly tutorials_aggregate: Tutorials_Aggregate;
  /** fetch data from the table: "tutorials" using primary key columns */
  readonly tutorials_by_pk?: Maybe<Tutorials>;
  /** fetch data from the table: "tutorials_tags" */
  readonly tutorials_tags: ReadonlyArray<Tutorials_Tags>;
  /** fetch aggregated fields from the table: "tutorials_tags" */
  readonly tutorials_tags_aggregate: Tutorials_Tags_Aggregate;
  /** fetch data from the table: "tutorials_tags" using primary key columns */
  readonly tutorials_tags_by_pk?: Maybe<Tutorials_Tags>;
  /** An array relationship */
  readonly user_collected_avatars: ReadonlyArray<User_Collected_Avatars>;
  /** An aggregate relationship */
  readonly user_collected_avatars_aggregate: User_Collected_Avatars_Aggregate;
  /** fetch data from the table: "user_collected_avatars" using primary key columns */
  readonly user_collected_avatars_by_pk?: Maybe<User_Collected_Avatars>;
  readonly user_feed: ReadonlyArray<FeedItem>;
  /** An array relationship */
  readonly user_invites: ReadonlyArray<User_Invites>;
  /** An aggregate relationship */
  readonly user_invites_aggregate: User_Invites_Aggregate;
  /** fetch data from the table: "user_invites" using primary key columns */
  readonly user_invites_by_pk?: Maybe<User_Invites>;
  /** fetch data from the table: "user_presence" */
  readonly user_presence: ReadonlyArray<User_Presence>;
  /** fetch aggregated fields from the table: "user_presence" */
  readonly user_presence_aggregate: User_Presence_Aggregate;
  /** fetch data from the table: "user_presence" using primary key columns */
  readonly user_presence_by_pk?: Maybe<User_Presence>;
  /** fetch data from the table: "users" */
  readonly users: ReadonlyArray<Users>;
  /** fetch data from the table: "users_actions" */
  readonly users_actions: ReadonlyArray<Users_Actions>;
  /** fetch aggregated fields from the table: "users_actions" */
  readonly users_actions_aggregate: Users_Actions_Aggregate;
  /** fetch data from the table: "users_actions" using primary key columns */
  readonly users_actions_by_pk?: Maybe<Users_Actions>;
  /** fetch aggregated fields from the table: "users" */
  readonly users_aggregate: Users_Aggregate;
  /** An array relationship */
  readonly users_badges: ReadonlyArray<Users_Badges>;
  /** An aggregate relationship */
  readonly users_badges_aggregate: Users_Badges_Aggregate;
  /** fetch data from the table: "users_badges" using primary key columns */
  readonly users_badges_by_pk?: Maybe<Users_Badges>;
  /** fetch data from the table: "users" using primary key columns */
  readonly users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "users_cached_queries" */
  readonly users_cached_queries: ReadonlyArray<Users_Cached_Queries>;
  /** fetch aggregated fields from the table: "users_cached_queries" */
  readonly users_cached_queries_aggregate: Users_Cached_Queries_Aggregate;
  /** fetch data from the table: "users_cached_queries" using primary key columns */
  readonly users_cached_queries_by_pk?: Maybe<Users_Cached_Queries>;
  /** fetch data from the table: "users_features" */
  readonly users_features: ReadonlyArray<Users_Features>;
  /** fetch aggregated fields from the table: "users_features" */
  readonly users_features_aggregate: Users_Features_Aggregate;
  /** fetch data from the table: "users_features" using primary key columns */
  readonly users_features_by_pk?: Maybe<Users_Features>;
  /** An array relationship */
  readonly users_levels: ReadonlyArray<Users_Levels>;
  /** An aggregate relationship */
  readonly users_levels_aggregate: Users_Levels_Aggregate;
  /** fetch data from the table: "users_levels" using primary key columns */
  readonly users_levels_by_pk?: Maybe<Users_Levels>;
  /** An array relationship */
  readonly users_notifications: ReadonlyArray<Users_Notifications>;
  /** An aggregate relationship */
  readonly users_notifications_aggregate: Users_Notifications_Aggregate;
  /** fetch data from the table: "users_notifications" using primary key columns */
  readonly users_notifications_by_pk?: Maybe<Users_Notifications>;
  /** fetch data from the table: "users_presence" */
  readonly users_presence: ReadonlyArray<Users_Presence>;
  /** fetch aggregated fields from the table: "users_presence" */
  readonly users_presence_aggregate: Users_Presence_Aggregate;
  /** fetch data from the table: "users_progression" */
  readonly users_progression: ReadonlyArray<Users_Progression>;
  /** fetch aggregated fields from the table: "users_progression" */
  readonly users_progression_aggregate: Users_Progression_Aggregate;
  /** fetch data from the table: "users_progression" using primary key columns */
  readonly users_progression_by_pk?: Maybe<Users_Progression>;
  /** An array relationship */
  readonly users_quests: ReadonlyArray<Users_Quests>;
  /** An aggregate relationship */
  readonly users_quests_aggregate: Users_Quests_Aggregate;
  /** fetch data from the table: "users_quests" using primary key columns */
  readonly users_quests_by_pk?: Maybe<Users_Quests>;
  /** fetch data from the table: "users_stale_web_presence" */
  readonly users_stale_web_presence: ReadonlyArray<Users_Stale_Web_Presence>;
  /** fetch aggregated fields from the table: "users_stale_web_presence" */
  readonly users_stale_web_presence_aggregate: Users_Stale_Web_Presence_Aggregate;
  /** fetch data from the table: "users_stats" */
  readonly users_stats: ReadonlyArray<Users_Stats>;
  /** fetch aggregated fields from the table: "users_stats" */
  readonly users_stats_aggregate: Users_Stats_Aggregate;
  /** fetch data from the table: "users_stats" using primary key columns */
  readonly users_stats_by_pk?: Maybe<Users_Stats>;
  /** An array relationship */
  readonly votes: ReadonlyArray<Votes>;
  /** An aggregate relationship */
  readonly votes_aggregate: Votes_Aggregate;
  /** fetch data from the table: "votes" using primary key columns */
  readonly votes_by_pk?: Maybe<Votes>;
};


export type Query_RootAccountsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Query_RootAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Query_RootAccounts_By_PkArgs = {
  club_id: Scalars['uuid']['input'];
  id: Scalars['String']['input'];
};


export type Query_RootAsa_User_Quest_TaskArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Asa_User_Quest_Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Asa_User_Quest_Task_Order_By>>;
  where?: InputMaybe<Asa_User_Quest_Task_Bool_Exp>;
};


export type Query_RootAsa_User_Quest_Task_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Asa_User_Quest_Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Asa_User_Quest_Task_Order_By>>;
  where?: InputMaybe<Asa_User_Quest_Task_Bool_Exp>;
};


export type Query_RootAsa_User_Quest_Task_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootAvatarsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Avatars_Order_By>>;
  where?: InputMaybe<Avatars_Bool_Exp>;
};


export type Query_RootAvatars_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Avatars_Order_By>>;
  where?: InputMaybe<Avatars_Bool_Exp>;
};


export type Query_RootAvatars_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootBadgesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Query_RootBadges_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Query_RootBadges_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootBookingsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Bookings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Bookings_Order_By>>;
  where?: InputMaybe<Bookings_Bool_Exp>;
};


export type Query_RootBookings_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Bookings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Bookings_Order_By>>;
  where?: InputMaybe<Bookings_Bool_Exp>;
};


export type Query_RootBookings_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootBreakoutsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Breakouts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Breakouts_Order_By>>;
  where?: InputMaybe<Breakouts_Bool_Exp>;
};


export type Query_RootBreakouts_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Breakouts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Breakouts_Order_By>>;
  where?: InputMaybe<Breakouts_Bool_Exp>;
};


export type Query_RootBreakouts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootBuild_BattlesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Build_Battles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Build_Battles_Order_By>>;
  where?: InputMaybe<Build_Battles_Bool_Exp>;
};


export type Query_RootBuild_Battles_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Build_Battles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Build_Battles_Order_By>>;
  where?: InputMaybe<Build_Battles_Bool_Exp>;
};


export type Query_RootBuild_Battles_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootBuild_Battles_ProjectsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Build_Battles_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Build_Battles_Projects_Order_By>>;
  where?: InputMaybe<Build_Battles_Projects_Bool_Exp>;
};


export type Query_RootBuild_Battles_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Build_Battles_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Build_Battles_Projects_Order_By>>;
  where?: InputMaybe<Build_Battles_Projects_Bool_Exp>;
};


export type Query_RootBuild_Battles_Projects_By_PkArgs = {
  build_battle_id: Scalars['uuid']['input'];
  project_id: Scalars['String']['input'];
};


export type Query_RootBuild_Battles_ThemesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Build_Battles_Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Build_Battles_Themes_Order_By>>;
  where?: InputMaybe<Build_Battles_Themes_Bool_Exp>;
};


export type Query_RootBuild_Battles_Themes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Build_Battles_Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Build_Battles_Themes_Order_By>>;
  where?: InputMaybe<Build_Battles_Themes_Bool_Exp>;
};


export type Query_RootBuild_Battles_Themes_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootChannelsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Query_RootChannels_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Query_RootChannels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootChannels_EventsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Events_Order_By>>;
  where?: InputMaybe<Channels_Events_Bool_Exp>;
};


export type Query_RootChannels_Events_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Events_Order_By>>;
  where?: InputMaybe<Channels_Events_Bool_Exp>;
};


export type Query_RootChannels_Events_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootChannels_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Users_Order_By>>;
  where?: InputMaybe<Channels_Users_Bool_Exp>;
};


export type Query_RootChannels_Users_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Users_Order_By>>;
  where?: InputMaybe<Channels_Users_Bool_Exp>;
};


export type Query_RootChannels_Users_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootChatsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Chats_Order_By>>;
  where?: InputMaybe<Chats_Bool_Exp>;
};


export type Query_RootChats_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Chats_Order_By>>;
  where?: InputMaybe<Chats_Bool_Exp>;
};


export type Query_RootChats_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootClubsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Order_By>>;
  where?: InputMaybe<Clubs_Bool_Exp>;
};


export type Query_RootClubs_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Order_By>>;
  where?: InputMaybe<Clubs_Bool_Exp>;
};


export type Query_RootClubs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootClubs_MembersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Members_Order_By>>;
  where?: InputMaybe<Clubs_Members_Bool_Exp>;
};


export type Query_RootClubs_Members_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Members_Order_By>>;
  where?: InputMaybe<Clubs_Members_Bool_Exp>;
};


export type Query_RootClubs_Members_By_PkArgs = {
  club_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


export type Query_RootCommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


export type Query_RootComments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


export type Query_RootComments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootComments_VotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Votes_Order_By>>;
  where?: InputMaybe<Comments_Votes_Bool_Exp>;
};


export type Query_RootComments_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Votes_Order_By>>;
  where?: InputMaybe<Comments_Votes_Bool_Exp>;
};


export type Query_RootComments_Votes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootCompetitionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


export type Query_RootCompetitions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


export type Query_RootCompetitions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootCompetitions_JudgesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Judges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Judges_Order_By>>;
  where?: InputMaybe<Competitions_Judges_Bool_Exp>;
};


export type Query_RootCompetitions_Judges_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Judges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Judges_Order_By>>;
  where?: InputMaybe<Competitions_Judges_Bool_Exp>;
};


export type Query_RootCompetitions_Judges_By_PkArgs = {
  competition_id: Scalars['String']['input'];
  judge_id: Scalars['String']['input'];
};


export type Query_RootCompetitions_TagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tags_Order_By>>;
  where?: InputMaybe<Competitions_Tags_Bool_Exp>;
};


export type Query_RootCompetitions_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tags_Order_By>>;
  where?: InputMaybe<Competitions_Tags_Bool_Exp>;
};


export type Query_RootCompetitions_Tags_By_PkArgs = {
  competition_id: Scalars['String']['input'];
  tag_id: Scalars['String']['input'];
};


export type Query_RootCompetitions_TutorialsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Order_By>>;
  where?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
};


export type Query_RootCompetitions_Tutorials_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Order_By>>;
  where?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
};


export type Query_RootCompetitions_Tutorials_By_PkArgs = {
  competition_id: Scalars['String']['input'];
  tutorial_id: Scalars['String']['input'];
};


export type Query_RootEban_CommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Eban_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Eban_Comments_Order_By>>;
  where?: InputMaybe<Eban_Comments_Bool_Exp>;
};


export type Query_RootEban_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Eban_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Eban_Comments_Order_By>>;
  where?: InputMaybe<Eban_Comments_Bool_Exp>;
};


export type Query_RootEban_Comments_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootEban_TypesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Eban_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Eban_Types_Order_By>>;
  where?: InputMaybe<Eban_Types_Bool_Exp>;
};


export type Query_RootEban_Types_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Eban_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Eban_Types_Order_By>>;
  where?: InputMaybe<Eban_Types_Bool_Exp>;
};


export type Query_RootEban_Types_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootEbansArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Ebans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Ebans_Order_By>>;
  where?: InputMaybe<Ebans_Bool_Exp>;
};


export type Query_RootEbans_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Ebans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Ebans_Order_By>>;
  where?: InputMaybe<Ebans_Bool_Exp>;
};


export type Query_RootEbans_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootEnvironmentArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Environment_Order_By>>;
  where?: InputMaybe<Environment_Bool_Exp>;
};


export type Query_RootEnvironment_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Environment_Order_By>>;
  where?: InputMaybe<Environment_Bool_Exp>;
};


export type Query_RootEnvironment_By_PkArgs = {
  key: Scalars['String']['input'];
};


export type Query_RootExecute_Shared_Cached_QueryArgs = {
  args: Execute_Shared_Cached_Query_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Shared_Cached_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Shared_Cached_Queries_Order_By>>;
  where?: InputMaybe<Shared_Cached_Queries_Bool_Exp>;
};


export type Query_RootExecute_Shared_Cached_Query_AggregateArgs = {
  args: Execute_Shared_Cached_Query_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Shared_Cached_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Shared_Cached_Queries_Order_By>>;
  where?: InputMaybe<Shared_Cached_Queries_Bool_Exp>;
};


export type Query_RootExecute_Users_Cached_QueryArgs = {
  args: Execute_Users_Cached_Query_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Users_Cached_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Cached_Queries_Order_By>>;
  where?: InputMaybe<Users_Cached_Queries_Bool_Exp>;
};


export type Query_RootExecute_Users_Cached_Query_AggregateArgs = {
  args: Execute_Users_Cached_Query_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Users_Cached_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Cached_Queries_Order_By>>;
  where?: InputMaybe<Users_Cached_Queries_Bool_Exp>;
};


export type Query_RootFeaturesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Features_Order_By>>;
  where?: InputMaybe<Features_Bool_Exp>;
};


export type Query_RootFeatures_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Features_Order_By>>;
  where?: InputMaybe<Features_Bool_Exp>;
};


export type Query_RootFeatures_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootFeatures_ProductsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Features_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Features_Products_Order_By>>;
  where?: InputMaybe<Features_Products_Bool_Exp>;
};


export type Query_RootFeatures_Products_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Features_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Features_Products_Order_By>>;
  where?: InputMaybe<Features_Products_Bool_Exp>;
};


export type Query_RootFeatures_Products_By_PkArgs = {
  feature_id: Scalars['uuid']['input'];
  product_id: Scalars['String']['input'];
};


export type Query_RootFeedbackArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feedback_Order_By>>;
  where?: InputMaybe<Feedback_Bool_Exp>;
};


export type Query_RootFeedback_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feedback_Order_By>>;
  where?: InputMaybe<Feedback_Bool_Exp>;
};


export type Query_RootFeedback_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootFeedsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


export type Query_RootFeeds_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


export type Query_RootFeeds_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootFollowsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


export type Query_RootFollows_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


export type Query_RootFollows_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootFriendsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friends_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friends_Order_By>>;
  where?: InputMaybe<Friends_Bool_Exp>;
};


export type Query_RootFriends_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friends_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friends_Order_By>>;
  where?: InputMaybe<Friends_Bool_Exp>;
};


export type Query_RootFriends_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootFriendshipsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friendships_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friendships_Order_By>>;
  where?: InputMaybe<Friendships_Bool_Exp>;
};


export type Query_RootFriendships_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friendships_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friendships_Order_By>>;
  where?: InputMaybe<Friendships_Bool_Exp>;
};


export type Query_RootFriendships_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootFriendships_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friendships_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friendships_Users_Order_By>>;
  where?: InputMaybe<Friendships_Users_Bool_Exp>;
};


export type Query_RootFriendships_Users_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friendships_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friendships_Users_Order_By>>;
  where?: InputMaybe<Friendships_Users_Bool_Exp>;
};


export type Query_RootFriendships_Users_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootGame_ServersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Game_Servers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Game_Servers_Order_By>>;
  where?: InputMaybe<Game_Servers_Bool_Exp>;
};


export type Query_RootGame_Servers_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Game_Servers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Game_Servers_Order_By>>;
  where?: InputMaybe<Game_Servers_Bool_Exp>;
};


export type Query_RootGame_Servers_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Query_RootHomesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Homes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Homes_Order_By>>;
  where?: InputMaybe<Homes_Bool_Exp>;
};


export type Query_RootHomes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Homes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Homes_Order_By>>;
  where?: InputMaybe<Homes_Bool_Exp>;
};


export type Query_RootHomes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootImage_CacheArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Image_Cache_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Image_Cache_Order_By>>;
  where?: InputMaybe<Image_Cache_Bool_Exp>;
};


export type Query_RootImage_Cache_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Image_Cache_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Image_Cache_Order_By>>;
  where?: InputMaybe<Image_Cache_Bool_Exp>;
};


export type Query_RootImage_Cache_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootInfrastructure_OperationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Infrastructure_Operations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Infrastructure_Operations_Order_By>>;
  where?: InputMaybe<Infrastructure_Operations_Bool_Exp>;
};


export type Query_RootInfrastructure_Operations_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Infrastructure_Operations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Infrastructure_Operations_Order_By>>;
  where?: InputMaybe<Infrastructure_Operations_Bool_Exp>;
};


export type Query_RootItemsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Query_RootItems_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Query_RootItems_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootItems_TagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Items_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Items_Tags_Order_By>>;
  where?: InputMaybe<Items_Tags_Bool_Exp>;
};


export type Query_RootItems_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Items_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Items_Tags_Order_By>>;
  where?: InputMaybe<Items_Tags_Bool_Exp>;
};


export type Query_RootItems_Tags_By_PkArgs = {
  item_id: Scalars['String']['input'];
  tag_id: Scalars['String']['input'];
};


export type Query_RootLevelsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Levels_Order_By>>;
  where?: InputMaybe<Levels_Bool_Exp>;
};


export type Query_RootLevels_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Levels_Order_By>>;
  where?: InputMaybe<Levels_Bool_Exp>;
};


export type Query_RootLevels_By_PkArgs = {
  level: Scalars['Int']['input'];
};


export type Query_RootLive_EventsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Order_By>>;
  where?: InputMaybe<Live_Events_Bool_Exp>;
};


export type Query_RootLive_Events_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Order_By>>;
  where?: InputMaybe<Live_Events_Bool_Exp>;
};


export type Query_RootLive_Events_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootLive_Events_HostsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Order_By>>;
  where?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
};


export type Query_RootLive_Events_Hosts_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Order_By>>;
  where?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
};


export type Query_RootLive_Events_Hosts_By_PkArgs = {
  live_event_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


export type Query_RootMessagesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


export type Query_RootMessages_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


export type Query_RootMessages_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootMinecraft_AnnouncementArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_Announcement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_Announcement_Order_By>>;
  where?: InputMaybe<Minecraft_Announcement_Bool_Exp>;
};


export type Query_RootMinecraft_Announcement_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_Announcement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_Announcement_Order_By>>;
  where?: InputMaybe<Minecraft_Announcement_Bool_Exp>;
};


export type Query_RootMinecraft_Announcement_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootMinecraft_ServersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_Servers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_Servers_Order_By>>;
  where?: InputMaybe<Minecraft_Servers_Bool_Exp>;
};


export type Query_RootMinecraft_Servers_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_Servers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_Servers_Order_By>>;
  where?: InputMaybe<Minecraft_Servers_Bool_Exp>;
};


export type Query_RootMinecraft_Servers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootMinecraft_SkinsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_Skins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_Skins_Order_By>>;
  where?: InputMaybe<Minecraft_Skins_Bool_Exp>;
};


export type Query_RootMinecraft_Skins_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_Skins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_Skins_Order_By>>;
  where?: InputMaybe<Minecraft_Skins_Bool_Exp>;
};


export type Query_RootMinecraft_Skins_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootMinecraft_TutorialArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Order_By>>;
  where?: InputMaybe<Minecraft_Tutorial_Bool_Exp>;
};


export type Query_RootMinecraft_Tutorial_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Order_By>>;
  where?: InputMaybe<Minecraft_Tutorial_Bool_Exp>;
};


export type Query_RootMinecraft_Tutorial_By_PkArgs = {
  requirement_id: Scalars['uuid']['input'];
  stage_id: Scalars['uuid']['input'];
  tutorial_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


export type Query_RootMinecraft_Tutorial_StatusArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Status_Order_By>>;
  where?: InputMaybe<Minecraft_Tutorial_Status_Bool_Exp>;
};


export type Query_RootMinecraft_Tutorial_Status_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Status_Order_By>>;
  where?: InputMaybe<Minecraft_Tutorial_Status_Bool_Exp>;
};


export type Query_RootMinecraft_Tutorial_Status_By_PkArgs = {
  tutorial_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


export type Query_RootMinecraft_UserArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_User_Order_By>>;
  where?: InputMaybe<Minecraft_User_Bool_Exp>;
};


export type Query_RootMinecraft_User_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_User_Order_By>>;
  where?: InputMaybe<Minecraft_User_Bool_Exp>;
};


export type Query_RootNotificationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootOrdersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


export type Query_RootOrders_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


export type Query_RootOrders_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootOtpsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Otps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Otps_Order_By>>;
  where?: InputMaybe<Otps_Bool_Exp>;
};


export type Query_RootOtps_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Otps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Otps_Order_By>>;
  where?: InputMaybe<Otps_Bool_Exp>;
};


export type Query_RootOtps_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootPagesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Pages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Pages_Order_By>>;
  where?: InputMaybe<Pages_Bool_Exp>;
};


export type Query_RootPages_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Pages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Pages_Order_By>>;
  where?: InputMaybe<Pages_Bool_Exp>;
};


export type Query_RootPages_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootPlayer_WorldsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Player_Worlds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Player_Worlds_Order_By>>;
  where?: InputMaybe<Player_Worlds_Bool_Exp>;
};


export type Query_RootPlayer_Worlds_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Player_Worlds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Player_Worlds_Order_By>>;
  where?: InputMaybe<Player_Worlds_Bool_Exp>;
};


export type Query_RootPlayer_Worlds_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootPostsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


export type Query_RootPosts_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


export type Query_RootPosts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProfile_FeedArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  username: Scalars['String']['input'];
};


export type Query_RootProjectsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Query_RootProjects_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Query_RootProjects_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootProjects_CollaboratorsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Collaborators_Order_By>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


export type Query_RootProjects_Collaborators_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Collaborators_Order_By>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


export type Query_RootProjects_Collaborators_By_PkArgs = {
  collaborator_id: Scalars['String']['input'];
  project_id: Scalars['String']['input'];
};


export type Query_RootProjects_EditablesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Editables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Editables_Order_By>>;
  where?: InputMaybe<Projects_Editables_Bool_Exp>;
};


export type Query_RootProjects_Editables_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Editables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Editables_Order_By>>;
  where?: InputMaybe<Projects_Editables_Bool_Exp>;
};


export type Query_RootProjects_LeaderboardsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Leaderboards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Leaderboards_Order_By>>;
  where?: InputMaybe<Projects_Leaderboards_Bool_Exp>;
};


export type Query_RootProjects_Leaderboards_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Leaderboards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Leaderboards_Order_By>>;
  where?: InputMaybe<Projects_Leaderboards_Bool_Exp>;
};


export type Query_RootProjects_Leaderboards_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProjects_PublicationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Publications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Publications_Order_By>>;
  where?: InputMaybe<Projects_Publications_Bool_Exp>;
};


export type Query_RootProjects_Publications_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Publications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Publications_Order_By>>;
  where?: InputMaybe<Projects_Publications_Bool_Exp>;
};


export type Query_RootProjects_Publications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProjects_Publications_VotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Publications_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Publications_Votes_Order_By>>;
  where?: InputMaybe<Projects_Publications_Votes_Bool_Exp>;
};


export type Query_RootProjects_Publications_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Publications_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Publications_Votes_Order_By>>;
  where?: InputMaybe<Projects_Publications_Votes_Bool_Exp>;
};


export type Query_RootProjects_Publications_Votes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProjects_TemplatesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Templates_Order_By>>;
  where?: InputMaybe<Projects_Templates_Bool_Exp>;
};


export type Query_RootProjects_Templates_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Templates_Order_By>>;
  where?: InputMaybe<Projects_Templates_Bool_Exp>;
};


export type Query_RootProjects_Templates_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootProspective_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Prospective_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Prospective_Users_Order_By>>;
  where?: InputMaybe<Prospective_Users_Bool_Exp>;
};


export type Query_RootProspective_Users_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Prospective_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Prospective_Users_Order_By>>;
  where?: InputMaybe<Prospective_Users_Bool_Exp>;
};


export type Query_RootProspective_Users_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootProspective_Users_PurchasesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Order_By>>;
  where?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
};


export type Query_RootProspective_Users_Purchases_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Order_By>>;
  where?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
};


export type Query_RootProspective_Users_Purchases_By_PkArgs = {
  club_id: Scalars['uuid']['input'];
  prospective_user_id: Scalars['uuid']['input'];
};


export type Query_RootQuestsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Quests_Order_By>>;
  where?: InputMaybe<Quests_Bool_Exp>;
};


export type Query_RootQuests_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Quests_Order_By>>;
  where?: InputMaybe<Quests_Bool_Exp>;
};


export type Query_RootQuests_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootQueue_User_InfoArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Queue_User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Queue_User_Info_Order_By>>;
  where?: InputMaybe<Queue_User_Info_Bool_Exp>;
};


export type Query_RootQueue_User_Info_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Queue_User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Queue_User_Info_Order_By>>;
  where?: InputMaybe<Queue_User_Info_Bool_Exp>;
};


export type Query_RootQueue_User_Info_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootQueuesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Queues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Queues_Order_By>>;
  where?: InputMaybe<Queues_Bool_Exp>;
};


export type Query_RootQueues_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Queues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Queues_Order_By>>;
  where?: InputMaybe<Queues_Bool_Exp>;
};


export type Query_RootQueues_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootRender_JobsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Render_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Render_Jobs_Order_By>>;
  where?: InputMaybe<Render_Jobs_Bool_Exp>;
};


export type Query_RootRender_Jobs_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Render_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Render_Jobs_Order_By>>;
  where?: InputMaybe<Render_Jobs_Bool_Exp>;
};


export type Query_RootRender_Jobs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootReportsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Query_RootReports_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Query_RootReports_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootRoomsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


export type Query_RootRooms_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


export type Query_RootRooms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootSchedulesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Schedules_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Schedules_Order_By>>;
  where?: InputMaybe<Schedules_Bool_Exp>;
};


export type Query_RootSchedules_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Schedules_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Schedules_Order_By>>;
  where?: InputMaybe<Schedules_Bool_Exp>;
};


export type Query_RootSchedules_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootShared_Cached_QueriesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Shared_Cached_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Shared_Cached_Queries_Order_By>>;
  where?: InputMaybe<Shared_Cached_Queries_Bool_Exp>;
};


export type Query_RootShared_Cached_Queries_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Shared_Cached_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Shared_Cached_Queries_Order_By>>;
  where?: InputMaybe<Shared_Cached_Queries_Bool_Exp>;
};


export type Query_RootShared_Cached_Queries_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootShort_UrlsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Short_Urls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Short_Urls_Order_By>>;
  where?: InputMaybe<Short_Urls_Bool_Exp>;
};


export type Query_RootShort_Urls_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Short_Urls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Short_Urls_Order_By>>;
  where?: InputMaybe<Short_Urls_Bool_Exp>;
};


export type Query_RootShort_Urls_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootSubscriptionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Query_RootSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Query_RootSubscriptions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Query_RootTags_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Query_RootTags_AssociationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tags_Associations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tags_Associations_Order_By>>;
  where?: InputMaybe<Tags_Associations_Bool_Exp>;
};


export type Query_RootTags_Associations_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tags_Associations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tags_Associations_Order_By>>;
  where?: InputMaybe<Tags_Associations_Bool_Exp>;
};


export type Query_RootTags_Associations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootTags_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootThemesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Themes_Order_By>>;
  where?: InputMaybe<Themes_Bool_Exp>;
};


export type Query_RootThemes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Themes_Order_By>>;
  where?: InputMaybe<Themes_Bool_Exp>;
};


export type Query_RootThemes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootToolsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tools_Order_By>>;
  where?: InputMaybe<Tools_Bool_Exp>;
};


export type Query_RootTools_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tools_Order_By>>;
  where?: InputMaybe<Tools_Bool_Exp>;
};


export type Query_RootTools_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTopicsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Topics_Order_By>>;
  where?: InputMaybe<Topics_Bool_Exp>;
};


export type Query_RootTopics_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Topics_Order_By>>;
  where?: InputMaybe<Topics_Bool_Exp>;
};


export type Query_RootTopics_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTopics_ToolsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Topics_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Topics_Tools_Order_By>>;
  where?: InputMaybe<Topics_Tools_Bool_Exp>;
};


export type Query_RootTopics_Tools_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Topics_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Topics_Tools_Order_By>>;
  where?: InputMaybe<Topics_Tools_Bool_Exp>;
};


export type Query_RootTopics_Tools_By_PkArgs = {
  tool_id: Scalars['String']['input'];
  topic_id: Scalars['String']['input'];
};


export type Query_RootTransactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


export type Query_RootTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


export type Query_RootTransactions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTutorialsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Order_By>>;
  where?: InputMaybe<Tutorials_Bool_Exp>;
};


export type Query_RootTutorials_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Order_By>>;
  where?: InputMaybe<Tutorials_Bool_Exp>;
};


export type Query_RootTutorials_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTutorials_TagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Tags_Order_By>>;
  where?: InputMaybe<Tutorials_Tags_Bool_Exp>;
};


export type Query_RootTutorials_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Tags_Order_By>>;
  where?: InputMaybe<Tutorials_Tags_Bool_Exp>;
};


export type Query_RootTutorials_Tags_By_PkArgs = {
  tag_id: Scalars['String']['input'];
  tutorial_id: Scalars['String']['input'];
};


export type Query_RootUser_Collected_AvatarsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Collected_Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Collected_Avatars_Order_By>>;
  where?: InputMaybe<User_Collected_Avatars_Bool_Exp>;
};


export type Query_RootUser_Collected_Avatars_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Collected_Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Collected_Avatars_Order_By>>;
  where?: InputMaybe<User_Collected_Avatars_Bool_Exp>;
};


export type Query_RootUser_Collected_Avatars_By_PkArgs = {
  avatar_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


export type Query_RootUser_FeedArgs = {
  all: Scalars['Boolean']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type Query_RootUser_InvitesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Query_RootUser_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Query_RootUser_Invites_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUser_PresenceArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Presence_Order_By>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};


export type Query_RootUser_Presence_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Presence_Order_By>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};


export type Query_RootUser_Presence_By_PkArgs = {
  platform: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_ActionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Actions_Order_By>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


export type Query_RootUsers_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Actions_Order_By>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


export type Query_RootUsers_Actions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_BadgesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Badges_Order_By>>;
  where?: InputMaybe<Users_Badges_Bool_Exp>;
};


export type Query_RootUsers_Badges_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Badges_Order_By>>;
  where?: InputMaybe<Users_Badges_Bool_Exp>;
};


export type Query_RootUsers_Badges_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUsers_Cached_QueriesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Cached_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Cached_Queries_Order_By>>;
  where?: InputMaybe<Users_Cached_Queries_Bool_Exp>;
};


export type Query_RootUsers_Cached_Queries_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Cached_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Cached_Queries_Order_By>>;
  where?: InputMaybe<Users_Cached_Queries_Bool_Exp>;
};


export type Query_RootUsers_Cached_Queries_By_PkArgs = {
  key: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type Query_RootUsers_FeaturesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Features_Order_By>>;
  where?: InputMaybe<Users_Features_Bool_Exp>;
};


export type Query_RootUsers_Features_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Features_Order_By>>;
  where?: InputMaybe<Users_Features_Bool_Exp>;
};


export type Query_RootUsers_Features_By_PkArgs = {
  feature_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


export type Query_RootUsers_LevelsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Levels_Order_By>>;
  where?: InputMaybe<Users_Levels_Bool_Exp>;
};


export type Query_RootUsers_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Levels_Order_By>>;
  where?: InputMaybe<Users_Levels_Bool_Exp>;
};


export type Query_RootUsers_Levels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUsers_NotificationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Notifications_Order_By>>;
  where?: InputMaybe<Users_Notifications_Bool_Exp>;
};


export type Query_RootUsers_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Notifications_Order_By>>;
  where?: InputMaybe<Users_Notifications_Bool_Exp>;
};


export type Query_RootUsers_Notifications_By_PkArgs = {
  notification_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


export type Query_RootUsers_PresenceArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Presence_Order_By>>;
  where?: InputMaybe<Users_Presence_Bool_Exp>;
};


export type Query_RootUsers_Presence_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Presence_Order_By>>;
  where?: InputMaybe<Users_Presence_Bool_Exp>;
};


export type Query_RootUsers_ProgressionArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Progression_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Progression_Order_By>>;
  where?: InputMaybe<Users_Progression_Bool_Exp>;
};


export type Query_RootUsers_Progression_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Progression_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Progression_Order_By>>;
  where?: InputMaybe<Users_Progression_Bool_Exp>;
};


export type Query_RootUsers_Progression_By_PkArgs = {
  user_id: Scalars['String']['input'];
};


export type Query_RootUsers_QuestsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Quests_Order_By>>;
  where?: InputMaybe<Users_Quests_Bool_Exp>;
};


export type Query_RootUsers_Quests_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Quests_Order_By>>;
  where?: InputMaybe<Users_Quests_Bool_Exp>;
};


export type Query_RootUsers_Quests_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUsers_Stale_Web_PresenceArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Stale_Web_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Stale_Web_Presence_Order_By>>;
  where?: InputMaybe<Users_Stale_Web_Presence_Bool_Exp>;
};


export type Query_RootUsers_Stale_Web_Presence_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Stale_Web_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Stale_Web_Presence_Order_By>>;
  where?: InputMaybe<Users_Stale_Web_Presence_Bool_Exp>;
};


export type Query_RootUsers_StatsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Stats_Order_By>>;
  where?: InputMaybe<Users_Stats_Bool_Exp>;
};


export type Query_RootUsers_Stats_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Stats_Order_By>>;
  where?: InputMaybe<Users_Stats_Bool_Exp>;
};


export type Query_RootUsers_Stats_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootVotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


export type Query_RootVotes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


export type Query_RootVotes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** columns and relationships of "quests" */
export type Quests = {
  readonly __typename?: 'quests';
  readonly association_id: Scalars['String']['output'];
  /** An object relationship */
  readonly club?: Maybe<Clubs>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly completed_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly description: Scalars['String']['output'];
  readonly expires_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly icon: Scalars['String']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly name: Scalars['String']['output'];
  readonly rewards: Scalars['jsonb']['output'];
  readonly stat?: Maybe<Scalars['String']['output']>;
  readonly stat_scope?: Maybe<Scalars['String']['output']>;
  readonly stat_value?: Maybe<Scalars['Int']['output']>;
  readonly title: Scalars['String']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  readonly users_quests: ReadonlyArray<Users_Quests>;
  /** An aggregate relationship */
  readonly users_quests_aggregate: Users_Quests_Aggregate;
};


/** columns and relationships of "quests" */
export type QuestsRewardsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "quests" */
export type QuestsUsers_QuestsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Quests_Order_By>>;
  where?: InputMaybe<Users_Quests_Bool_Exp>;
};


/** columns and relationships of "quests" */
export type QuestsUsers_Quests_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Quests_Order_By>>;
  where?: InputMaybe<Users_Quests_Bool_Exp>;
};

/** aggregated selection of "quests" */
export type Quests_Aggregate = {
  readonly __typename?: 'quests_aggregate';
  readonly aggregate?: Maybe<Quests_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Quests>;
};

export type Quests_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Quests_Aggregate_Bool_Exp_Count>;
};

export type Quests_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Quests_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Quests_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "quests" */
export type Quests_Aggregate_Fields = {
  readonly __typename?: 'quests_aggregate_fields';
  readonly avg?: Maybe<Quests_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Quests_Max_Fields>;
  readonly min?: Maybe<Quests_Min_Fields>;
  readonly stddev?: Maybe<Quests_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Quests_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Quests_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Quests_Sum_Fields>;
  readonly var_pop?: Maybe<Quests_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Quests_Var_Samp_Fields>;
  readonly variance?: Maybe<Quests_Variance_Fields>;
};


/** aggregate fields of "quests" */
export type Quests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Quests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "quests" */
export type Quests_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Quests_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Quests_Max_Order_By>;
  readonly min?: InputMaybe<Quests_Min_Order_By>;
  readonly stddev?: InputMaybe<Quests_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Quests_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Quests_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Quests_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Quests_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Quests_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Quests_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Quests_Append_Input = {
  readonly rewards?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "quests" */
export type Quests_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Quests_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Quests_On_Conflict>;
};

/** aggregate avg on columns */
export type Quests_Avg_Fields = {
  readonly __typename?: 'quests_avg_fields';
  readonly stat_value?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "quests" */
export type Quests_Avg_Order_By = {
  readonly stat_value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "quests". All fields are combined with a logical 'AND'. */
export type Quests_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Quests_Bool_Exp>>;
  readonly _not?: InputMaybe<Quests_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Quests_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly club_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly description?: InputMaybe<String_Comparison_Exp>;
  readonly expires_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly icon?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly rewards?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly stat?: InputMaybe<String_Comparison_Exp>;
  readonly stat_scope?: InputMaybe<String_Comparison_Exp>;
  readonly stat_value?: InputMaybe<Int_Comparison_Exp>;
  readonly title?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly users_quests?: InputMaybe<Users_Quests_Bool_Exp>;
  readonly users_quests_aggregate?: InputMaybe<Users_Quests_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "quests" */
export enum Quests_Constraint {
  /** unique or primary key constraint on columns "association_id" */
  QuestsAssociationIdKey = 'quests_association_id_key',
  /** unique or primary key constraint on columns "name" */
  QuestsNameKey = 'quests_name_key',
  /** unique or primary key constraint on columns "id" */
  QuestsPkey = 'quests_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Quests_Delete_At_Path_Input = {
  readonly rewards?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Quests_Delete_Elem_Input = {
  readonly rewards?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Quests_Delete_Key_Input = {
  readonly rewards?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "quests" */
export type Quests_Inc_Input = {
  readonly stat_value?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "quests" */
export type Quests_Insert_Input = {
  readonly club?: InputMaybe<Clubs_Obj_Rel_Insert_Input>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly icon?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly rewards?: InputMaybe<Scalars['jsonb']['input']>;
  readonly stat?: InputMaybe<Scalars['String']['input']>;
  readonly stat_scope?: InputMaybe<Scalars['String']['input']>;
  readonly stat_value?: InputMaybe<Scalars['Int']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly users_quests?: InputMaybe<Users_Quests_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Quests_Max_Fields = {
  readonly __typename?: 'quests_max_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly completed_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly expires_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly icon?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly stat?: Maybe<Scalars['String']['output']>;
  readonly stat_scope?: Maybe<Scalars['String']['output']>;
  readonly stat_value?: Maybe<Scalars['Int']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "quests" */
export type Quests_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly expires_at?: InputMaybe<Order_By>;
  readonly icon?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly stat?: InputMaybe<Order_By>;
  readonly stat_scope?: InputMaybe<Order_By>;
  readonly stat_value?: InputMaybe<Order_By>;
  readonly title?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Quests_Min_Fields = {
  readonly __typename?: 'quests_min_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly completed_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly expires_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly icon?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly stat?: Maybe<Scalars['String']['output']>;
  readonly stat_scope?: Maybe<Scalars['String']['output']>;
  readonly stat_value?: Maybe<Scalars['Int']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "quests" */
export type Quests_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly expires_at?: InputMaybe<Order_By>;
  readonly icon?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly stat?: InputMaybe<Order_By>;
  readonly stat_scope?: InputMaybe<Order_By>;
  readonly stat_value?: InputMaybe<Order_By>;
  readonly title?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "quests" */
export type Quests_Mutation_Response = {
  readonly __typename?: 'quests_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Quests>;
};

/** input type for inserting object relation for remote table "quests" */
export type Quests_Obj_Rel_Insert_Input = {
  readonly data: Quests_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Quests_On_Conflict>;
};

/** on_conflict condition type for table "quests" */
export type Quests_On_Conflict = {
  readonly constraint: Quests_Constraint;
  readonly update_columns?: ReadonlyArray<Quests_Update_Column>;
  readonly where?: InputMaybe<Quests_Bool_Exp>;
};

/** Ordering options when selecting data from "quests". */
export type Quests_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly completed_at?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly expires_at?: InputMaybe<Order_By>;
  readonly icon?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly rewards?: InputMaybe<Order_By>;
  readonly stat?: InputMaybe<Order_By>;
  readonly stat_scope?: InputMaybe<Order_By>;
  readonly stat_value?: InputMaybe<Order_By>;
  readonly title?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly users_quests_aggregate?: InputMaybe<Users_Quests_Aggregate_Order_By>;
};

/** primary key columns input for table: quests */
export type Quests_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Quests_Prepend_Input = {
  readonly rewards?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "quests" */
export enum Quests_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Rewards = 'rewards',
  /** column name */
  Stat = 'stat',
  /** column name */
  StatScope = 'stat_scope',
  /** column name */
  StatValue = 'stat_value',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "quests" */
export type Quests_Set_Input = {
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly icon?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly rewards?: InputMaybe<Scalars['jsonb']['input']>;
  readonly stat?: InputMaybe<Scalars['String']['input']>;
  readonly stat_scope?: InputMaybe<Scalars['String']['input']>;
  readonly stat_value?: InputMaybe<Scalars['Int']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Quests_Stddev_Fields = {
  readonly __typename?: 'quests_stddev_fields';
  readonly stat_value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "quests" */
export type Quests_Stddev_Order_By = {
  readonly stat_value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Quests_Stddev_Pop_Fields = {
  readonly __typename?: 'quests_stddev_pop_fields';
  readonly stat_value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "quests" */
export type Quests_Stddev_Pop_Order_By = {
  readonly stat_value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Quests_Stddev_Samp_Fields = {
  readonly __typename?: 'quests_stddev_samp_fields';
  readonly stat_value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "quests" */
export type Quests_Stddev_Samp_Order_By = {
  readonly stat_value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "quests" */
export type Quests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Quests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Quests_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly icon?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly rewards?: InputMaybe<Scalars['jsonb']['input']>;
  readonly stat?: InputMaybe<Scalars['String']['input']>;
  readonly stat_scope?: InputMaybe<Scalars['String']['input']>;
  readonly stat_value?: InputMaybe<Scalars['Int']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Quests_Sum_Fields = {
  readonly __typename?: 'quests_sum_fields';
  readonly stat_value?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "quests" */
export type Quests_Sum_Order_By = {
  readonly stat_value?: InputMaybe<Order_By>;
};

/** update columns of table "quests" */
export enum Quests_Update_Column {
  /** column name */
  ClubId = 'club_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Rewards = 'rewards',
  /** column name */
  Stat = 'stat',
  /** column name */
  StatScope = 'stat_scope',
  /** column name */
  StatValue = 'stat_value',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Quests_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Quests_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Quests_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Quests_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Quests_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Quests_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Quests_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Quests_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Quests_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Quests_Var_Pop_Fields = {
  readonly __typename?: 'quests_var_pop_fields';
  readonly stat_value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "quests" */
export type Quests_Var_Pop_Order_By = {
  readonly stat_value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Quests_Var_Samp_Fields = {
  readonly __typename?: 'quests_var_samp_fields';
  readonly stat_value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "quests" */
export type Quests_Var_Samp_Order_By = {
  readonly stat_value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Quests_Variance_Fields = {
  readonly __typename?: 'quests_variance_fields';
  readonly stat_value?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "quests" */
export type Quests_Variance_Order_By = {
  readonly stat_value?: InputMaybe<Order_By>;
};

/** columns and relationships of "queue_user_info" */
export type Queue_User_Info = {
  readonly __typename?: 'queue_user_info';
  readonly id: Scalars['Int']['output'];
  readonly queue_id: Scalars['Int']['output'];
  readonly queued_at: Scalars['timestamptz']['output'];
  readonly user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "queue_user_info" */
export type Queue_User_Info_Aggregate = {
  readonly __typename?: 'queue_user_info_aggregate';
  readonly aggregate?: Maybe<Queue_User_Info_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Queue_User_Info>;
};

/** aggregate fields of "queue_user_info" */
export type Queue_User_Info_Aggregate_Fields = {
  readonly __typename?: 'queue_user_info_aggregate_fields';
  readonly avg?: Maybe<Queue_User_Info_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Queue_User_Info_Max_Fields>;
  readonly min?: Maybe<Queue_User_Info_Min_Fields>;
  readonly stddev?: Maybe<Queue_User_Info_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Queue_User_Info_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Queue_User_Info_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Queue_User_Info_Sum_Fields>;
  readonly var_pop?: Maybe<Queue_User_Info_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Queue_User_Info_Var_Samp_Fields>;
  readonly variance?: Maybe<Queue_User_Info_Variance_Fields>;
};


/** aggregate fields of "queue_user_info" */
export type Queue_User_Info_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Queue_User_Info_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Queue_User_Info_Avg_Fields = {
  readonly __typename?: 'queue_user_info_avg_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly queue_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "queue_user_info". All fields are combined with a logical 'AND'. */
export type Queue_User_Info_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Queue_User_Info_Bool_Exp>>;
  readonly _not?: InputMaybe<Queue_User_Info_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Queue_User_Info_Bool_Exp>>;
  readonly id?: InputMaybe<Int_Comparison_Exp>;
  readonly queue_id?: InputMaybe<Int_Comparison_Exp>;
  readonly queued_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "queue_user_info" */
export enum Queue_User_Info_Constraint {
  /** unique or primary key constraint on columns "id" */
  QueueUserInfoPkey = 'queue_user_info_pkey',
  /** unique or primary key constraint on columns "user_id" */
  QueueUserInfoUserIdKey = 'queue_user_info_user_id_key'
}

/** input type for incrementing numeric columns in table "queue_user_info" */
export type Queue_User_Info_Inc_Input = {
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly queue_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "queue_user_info" */
export type Queue_User_Info_Insert_Input = {
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly queue_id?: InputMaybe<Scalars['Int']['input']>;
  readonly queued_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Queue_User_Info_Max_Fields = {
  readonly __typename?: 'queue_user_info_max_fields';
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly queue_id?: Maybe<Scalars['Int']['output']>;
  readonly queued_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Queue_User_Info_Min_Fields = {
  readonly __typename?: 'queue_user_info_min_fields';
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly queue_id?: Maybe<Scalars['Int']['output']>;
  readonly queued_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "queue_user_info" */
export type Queue_User_Info_Mutation_Response = {
  readonly __typename?: 'queue_user_info_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Queue_User_Info>;
};

/** on_conflict condition type for table "queue_user_info" */
export type Queue_User_Info_On_Conflict = {
  readonly constraint: Queue_User_Info_Constraint;
  readonly update_columns?: ReadonlyArray<Queue_User_Info_Update_Column>;
  readonly where?: InputMaybe<Queue_User_Info_Bool_Exp>;
};

/** Ordering options when selecting data from "queue_user_info". */
export type Queue_User_Info_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly queue_id?: InputMaybe<Order_By>;
  readonly queued_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: queue_user_info */
export type Queue_User_Info_Pk_Columns_Input = {
  readonly id: Scalars['Int']['input'];
};

/** select columns of table "queue_user_info" */
export enum Queue_User_Info_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  QueueId = 'queue_id',
  /** column name */
  QueuedAt = 'queued_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "queue_user_info" */
export type Queue_User_Info_Set_Input = {
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly queue_id?: InputMaybe<Scalars['Int']['input']>;
  readonly queued_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type Queue_User_Info_Stddev_Fields = {
  readonly __typename?: 'queue_user_info_stddev_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly queue_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Queue_User_Info_Stddev_Pop_Fields = {
  readonly __typename?: 'queue_user_info_stddev_pop_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly queue_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Queue_User_Info_Stddev_Samp_Fields = {
  readonly __typename?: 'queue_user_info_stddev_samp_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly queue_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "queue_user_info" */
export type Queue_User_Info_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Queue_User_Info_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Queue_User_Info_Stream_Cursor_Value_Input = {
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly queue_id?: InputMaybe<Scalars['Int']['input']>;
  readonly queued_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type Queue_User_Info_Sum_Fields = {
  readonly __typename?: 'queue_user_info_sum_fields';
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly queue_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "queue_user_info" */
export enum Queue_User_Info_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  QueueId = 'queue_id',
  /** column name */
  QueuedAt = 'queued_at',
  /** column name */
  UserId = 'user_id'
}

export type Queue_User_Info_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Queue_User_Info_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Queue_User_Info_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Queue_User_Info_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Queue_User_Info_Var_Pop_Fields = {
  readonly __typename?: 'queue_user_info_var_pop_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly queue_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Queue_User_Info_Var_Samp_Fields = {
  readonly __typename?: 'queue_user_info_var_samp_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly queue_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Queue_User_Info_Variance_Fields = {
  readonly __typename?: 'queue_user_info_variance_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly queue_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "queues" */
export type Queues = {
  readonly __typename?: 'queues';
  readonly category: Scalars['String']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['Int']['output'];
  readonly starting: Scalars['Boolean']['output'];
  readonly task_id?: Maybe<Scalars['uuid']['output']>;
  readonly type: Scalars['String']['output'];
};

/** aggregated selection of "queues" */
export type Queues_Aggregate = {
  readonly __typename?: 'queues_aggregate';
  readonly aggregate?: Maybe<Queues_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Queues>;
};

/** aggregate fields of "queues" */
export type Queues_Aggregate_Fields = {
  readonly __typename?: 'queues_aggregate_fields';
  readonly avg?: Maybe<Queues_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Queues_Max_Fields>;
  readonly min?: Maybe<Queues_Min_Fields>;
  readonly stddev?: Maybe<Queues_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Queues_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Queues_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Queues_Sum_Fields>;
  readonly var_pop?: Maybe<Queues_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Queues_Var_Samp_Fields>;
  readonly variance?: Maybe<Queues_Variance_Fields>;
};


/** aggregate fields of "queues" */
export type Queues_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Queues_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Queues_Avg_Fields = {
  readonly __typename?: 'queues_avg_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "queues". All fields are combined with a logical 'AND'. */
export type Queues_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Queues_Bool_Exp>>;
  readonly _not?: InputMaybe<Queues_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Queues_Bool_Exp>>;
  readonly category?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Int_Comparison_Exp>;
  readonly starting?: InputMaybe<Boolean_Comparison_Exp>;
  readonly task_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "queues" */
export enum Queues_Constraint {
  /** unique or primary key constraint on columns "id" */
  QueuesPkey = 'queues_pkey'
}

/** input type for incrementing numeric columns in table "queues" */
export type Queues_Inc_Input = {
  readonly id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "queues" */
export type Queues_Insert_Input = {
  readonly category?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly starting?: InputMaybe<Scalars['Boolean']['input']>;
  readonly task_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Queues_Max_Fields = {
  readonly __typename?: 'queues_max_fields';
  readonly category?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly task_id?: Maybe<Scalars['uuid']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Queues_Min_Fields = {
  readonly __typename?: 'queues_min_fields';
  readonly category?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly task_id?: Maybe<Scalars['uuid']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "queues" */
export type Queues_Mutation_Response = {
  readonly __typename?: 'queues_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Queues>;
};

/** on_conflict condition type for table "queues" */
export type Queues_On_Conflict = {
  readonly constraint: Queues_Constraint;
  readonly update_columns?: ReadonlyArray<Queues_Update_Column>;
  readonly where?: InputMaybe<Queues_Bool_Exp>;
};

/** Ordering options when selecting data from "queues". */
export type Queues_Order_By = {
  readonly category?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly starting?: InputMaybe<Order_By>;
  readonly task_id?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: queues */
export type Queues_Pk_Columns_Input = {
  readonly id: Scalars['Int']['input'];
};

/** select columns of table "queues" */
export enum Queues_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Starting = 'starting',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "queues" */
export type Queues_Set_Input = {
  readonly category?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly starting?: InputMaybe<Scalars['Boolean']['input']>;
  readonly task_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Queues_Stddev_Fields = {
  readonly __typename?: 'queues_stddev_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Queues_Stddev_Pop_Fields = {
  readonly __typename?: 'queues_stddev_pop_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Queues_Stddev_Samp_Fields = {
  readonly __typename?: 'queues_stddev_samp_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "queues" */
export type Queues_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Queues_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Queues_Stream_Cursor_Value_Input = {
  readonly category?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly starting?: InputMaybe<Scalars['Boolean']['input']>;
  readonly task_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Queues_Sum_Fields = {
  readonly __typename?: 'queues_sum_fields';
  readonly id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "queues" */
export enum Queues_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Starting = 'starting',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Type = 'type'
}

export type Queues_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Queues_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Queues_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Queues_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Queues_Var_Pop_Fields = {
  readonly __typename?: 'queues_var_pop_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Queues_Var_Samp_Fields = {
  readonly __typename?: 'queues_var_samp_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Queues_Variance_Fields = {
  readonly __typename?: 'queues_variance_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
};

export type Remove_Club_Requirement_Args = {
  readonly _club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly _requirement?: InputMaybe<Scalars['String']['input']>;
  readonly _user_id?: InputMaybe<Scalars['String']['input']>;
};

export type Remove_Progression_Claimed_Levels_Args = {
  readonly _levels?: InputMaybe<Scalars['String']['input']>;
  readonly _user_id?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "render_jobs" */
export type Render_Jobs = {
  readonly __typename?: 'render_jobs';
  readonly created_at: Scalars['timestamptz']['output'];
  readonly duration?: Maybe<Scalars['Int']['output']>;
  readonly id: Scalars['uuid']['output'];
  readonly meta: Scalars['jsonb']['output'];
  readonly project_id: Scalars['String']['output'];
  readonly server?: Maybe<Scalars['String']['output']>;
  readonly source_bucket: Scalars['String']['output'];
  readonly source_path: Scalars['String']['output'];
  readonly status: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "render_jobs" */
export type Render_JobsMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "render_jobs" */
export type Render_Jobs_Aggregate = {
  readonly __typename?: 'render_jobs_aggregate';
  readonly aggregate?: Maybe<Render_Jobs_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Render_Jobs>;
};

/** aggregate fields of "render_jobs" */
export type Render_Jobs_Aggregate_Fields = {
  readonly __typename?: 'render_jobs_aggregate_fields';
  readonly avg?: Maybe<Render_Jobs_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Render_Jobs_Max_Fields>;
  readonly min?: Maybe<Render_Jobs_Min_Fields>;
  readonly stddev?: Maybe<Render_Jobs_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Render_Jobs_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Render_Jobs_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Render_Jobs_Sum_Fields>;
  readonly var_pop?: Maybe<Render_Jobs_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Render_Jobs_Var_Samp_Fields>;
  readonly variance?: Maybe<Render_Jobs_Variance_Fields>;
};


/** aggregate fields of "render_jobs" */
export type Render_Jobs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Render_Jobs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Render_Jobs_Append_Input = {
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Render_Jobs_Avg_Fields = {
  readonly __typename?: 'render_jobs_avg_fields';
  readonly duration?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "render_jobs". All fields are combined with a logical 'AND'. */
export type Render_Jobs_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Render_Jobs_Bool_Exp>>;
  readonly _not?: InputMaybe<Render_Jobs_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Render_Jobs_Bool_Exp>>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly duration?: InputMaybe<Int_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly meta?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly project_id?: InputMaybe<String_Comparison_Exp>;
  readonly server?: InputMaybe<String_Comparison_Exp>;
  readonly source_bucket?: InputMaybe<String_Comparison_Exp>;
  readonly source_path?: InputMaybe<String_Comparison_Exp>;
  readonly status?: InputMaybe<String_Comparison_Exp>;
  readonly type?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "render_jobs" */
export enum Render_Jobs_Constraint {
  /** unique or primary key constraint on columns "id" */
  RenderJobsPkey = 'render_jobs_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Render_Jobs_Delete_At_Path_Input = {
  readonly meta?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Render_Jobs_Delete_Elem_Input = {
  readonly meta?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Render_Jobs_Delete_Key_Input = {
  readonly meta?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "render_jobs" */
export type Render_Jobs_Inc_Input = {
  readonly duration?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "render_jobs" */
export type Render_Jobs_Insert_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly duration?: InputMaybe<Scalars['Int']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
  readonly server?: InputMaybe<Scalars['String']['input']>;
  readonly source_bucket?: InputMaybe<Scalars['String']['input']>;
  readonly source_path?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Render_Jobs_Max_Fields = {
  readonly __typename?: 'render_jobs_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly duration?: Maybe<Scalars['Int']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly project_id?: Maybe<Scalars['String']['output']>;
  readonly server?: Maybe<Scalars['String']['output']>;
  readonly source_bucket?: Maybe<Scalars['String']['output']>;
  readonly source_path?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Render_Jobs_Min_Fields = {
  readonly __typename?: 'render_jobs_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly duration?: Maybe<Scalars['Int']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly project_id?: Maybe<Scalars['String']['output']>;
  readonly server?: Maybe<Scalars['String']['output']>;
  readonly source_bucket?: Maybe<Scalars['String']['output']>;
  readonly source_path?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "render_jobs" */
export type Render_Jobs_Mutation_Response = {
  readonly __typename?: 'render_jobs_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Render_Jobs>;
};

/** on_conflict condition type for table "render_jobs" */
export type Render_Jobs_On_Conflict = {
  readonly constraint: Render_Jobs_Constraint;
  readonly update_columns?: ReadonlyArray<Render_Jobs_Update_Column>;
  readonly where?: InputMaybe<Render_Jobs_Bool_Exp>;
};

/** Ordering options when selecting data from "render_jobs". */
export type Render_Jobs_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly duration?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly meta?: InputMaybe<Order_By>;
  readonly project_id?: InputMaybe<Order_By>;
  readonly server?: InputMaybe<Order_By>;
  readonly source_bucket?: InputMaybe<Order_By>;
  readonly source_path?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: render_jobs */
export type Render_Jobs_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Render_Jobs_Prepend_Input = {
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "render_jobs" */
export enum Render_Jobs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Server = 'server',
  /** column name */
  SourceBucket = 'source_bucket',
  /** column name */
  SourcePath = 'source_path',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "render_jobs" */
export type Render_Jobs_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly duration?: InputMaybe<Scalars['Int']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
  readonly server?: InputMaybe<Scalars['String']['input']>;
  readonly source_bucket?: InputMaybe<Scalars['String']['input']>;
  readonly source_path?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Render_Jobs_Stddev_Fields = {
  readonly __typename?: 'render_jobs_stddev_fields';
  readonly duration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Render_Jobs_Stddev_Pop_Fields = {
  readonly __typename?: 'render_jobs_stddev_pop_fields';
  readonly duration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Render_Jobs_Stddev_Samp_Fields = {
  readonly __typename?: 'render_jobs_stddev_samp_fields';
  readonly duration?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "render_jobs" */
export type Render_Jobs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Render_Jobs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Render_Jobs_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly duration?: InputMaybe<Scalars['Int']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly project_id?: InputMaybe<Scalars['String']['input']>;
  readonly server?: InputMaybe<Scalars['String']['input']>;
  readonly source_bucket?: InputMaybe<Scalars['String']['input']>;
  readonly source_path?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Render_Jobs_Sum_Fields = {
  readonly __typename?: 'render_jobs_sum_fields';
  readonly duration?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "render_jobs" */
export enum Render_Jobs_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Server = 'server',
  /** column name */
  SourceBucket = 'source_bucket',
  /** column name */
  SourcePath = 'source_path',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Render_Jobs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Render_Jobs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Render_Jobs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Render_Jobs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Render_Jobs_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Render_Jobs_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Render_Jobs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Render_Jobs_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Render_Jobs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Render_Jobs_Var_Pop_Fields = {
  readonly __typename?: 'render_jobs_var_pop_fields';
  readonly duration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Render_Jobs_Var_Samp_Fields = {
  readonly __typename?: 'render_jobs_var_samp_fields';
  readonly duration?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Render_Jobs_Variance_Fields = {
  readonly __typename?: 'render_jobs_variance_fields';
  readonly duration?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "reports" */
export type Reports = {
  readonly __typename?: 'reports';
  readonly association_id: Scalars['String']['output'];
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id: Scalars['uuid']['output'];
  /** An object relationship */
  readonly posts?: Maybe<Posts>;
  /** An object relationship */
  readonly reporter: Users;
  readonly reporter_id: Scalars['String']['output'];
  readonly status: Scalars['String']['output'];
  readonly text: Scalars['String']['output'];
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "reports" */
export type Reports_Aggregate = {
  readonly __typename?: 'reports_aggregate';
  readonly aggregate?: Maybe<Reports_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Reports>;
};

/** aggregate fields of "reports" */
export type Reports_Aggregate_Fields = {
  readonly __typename?: 'reports_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Reports_Max_Fields>;
  readonly min?: Maybe<Reports_Min_Fields>;
};


/** aggregate fields of "reports" */
export type Reports_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Reports_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "reports". All fields are combined with a logical 'AND'. */
export type Reports_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Reports_Bool_Exp>>;
  readonly _not?: InputMaybe<Reports_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Reports_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly posts?: InputMaybe<Posts_Bool_Exp>;
  readonly reporter?: InputMaybe<Users_Bool_Exp>;
  readonly reporter_id?: InputMaybe<String_Comparison_Exp>;
  readonly status?: InputMaybe<String_Comparison_Exp>;
  readonly text?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "reports" */
export enum Reports_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReportsPkey = 'reports_pkey'
}

/** input type for inserting data into table "reports" */
export type Reports_Insert_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly posts?: InputMaybe<Posts_Obj_Rel_Insert_Input>;
  readonly reporter?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly reporter_id?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly text?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Reports_Max_Fields = {
  readonly __typename?: 'reports_max_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly reporter_id?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly text?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Reports_Min_Fields = {
  readonly __typename?: 'reports_min_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly reporter_id?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly text?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "reports" */
export type Reports_Mutation_Response = {
  readonly __typename?: 'reports_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Reports>;
};

/** on_conflict condition type for table "reports" */
export type Reports_On_Conflict = {
  readonly constraint: Reports_Constraint;
  readonly update_columns?: ReadonlyArray<Reports_Update_Column>;
  readonly where?: InputMaybe<Reports_Bool_Exp>;
};

/** Ordering options when selecting data from "reports". */
export type Reports_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly posts?: InputMaybe<Posts_Order_By>;
  readonly reporter?: InputMaybe<Users_Order_By>;
  readonly reporter_id?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly text?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reports */
export type Reports_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "reports" */
export enum Reports_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "reports" */
export type Reports_Set_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly reporter_id?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly text?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "reports" */
export type Reports_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Reports_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reports_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly reporter_id?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly text?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "reports" */
export enum Reports_Update_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReporterId = 'reporter_id',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Reports_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Reports_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Reports_Bool_Exp;
};

export enum Room_Color {
  Blue = 'blue',
  Cyan = 'cyan',
  Green = 'green',
  Orange = 'orange',
  Pink = 'pink',
  Purple = 'purple',
  Red = 'red',
  Teal = 'teal',
  Yellow = 'yellow'
}

export type Room_Color_Bool_Exp = {
  readonly _eq?: InputMaybe<Room_Color>;
  readonly _in?: InputMaybe<ReadonlyArray<Room_Color>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Room_Color>;
  readonly _nin?: InputMaybe<ReadonlyArray<Room_Color>>;
};

export enum Room_Kind {
  BreakoutContainer = 'breakout_container',
  Orientation = 'orientation',
  Standard = 'standard'
}

export type Room_Kind_Bool_Exp = {
  readonly _eq?: InputMaybe<Room_Kind>;
  readonly _in?: InputMaybe<ReadonlyArray<Room_Kind>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Room_Kind>;
  readonly _nin?: InputMaybe<ReadonlyArray<Room_Kind>>;
};

export enum Room_Privacy {
  Private = 'private',
  Public = 'public'
}

export type Room_Privacy_Bool_Exp = {
  readonly _eq?: InputMaybe<Room_Privacy>;
  readonly _in?: InputMaybe<ReadonlyArray<Room_Privacy>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Room_Privacy>;
  readonly _nin?: InputMaybe<ReadonlyArray<Room_Privacy>>;
};

export enum Room_Status {
  Closed = 'closed',
  Hidden = 'hidden',
  Locked = 'locked',
  Open = 'open'
}

export type Room_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<Room_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<Room_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Room_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<Room_Status>>;
};

/** columns and relationships of "rooms" */
export type Rooms = {
  readonly __typename?: 'rooms';
  readonly association_id: Scalars['String']['output'];
  readonly background_image_url?: Maybe<Scalars['String']['output']>;
  readonly bluemap_url?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly breakouts: ReadonlyArray<Breakouts>;
  /** An aggregate relationship */
  readonly breakouts_aggregate: Breakouts_Aggregate;
  /** An object relationship */
  readonly channel?: Maybe<Channels>;
  readonly channel_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  readonly channel_users: ReadonlyArray<Channels_Users>;
  /** An aggregate relationship */
  readonly channel_users_aggregate: Channels_Users_Aggregate;
  readonly clipboard_prompt?: Maybe<Scalars['clipboard_prompt']['output']>;
  /** An object relationship */
  readonly club: Clubs;
  readonly club_id: Scalars['uuid']['output'];
  readonly color: Room_Color;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly feedback_question?: Maybe<Scalars['String']['output']>;
  readonly host_user_ids: Scalars['rooms_host_user_ids']['output'];
  readonly icon_url?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['uuid']['output'];
  readonly kind: Room_Kind;
  /** An array relationship */
  readonly live_events: ReadonlyArray<Live_Events>;
  /** An aggregate relationship */
  readonly live_events_aggregate: Live_Events_Aggregate;
  readonly lock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly name: Scalars['String']['output'];
  /** An object relationship */
  readonly owner?: Maybe<Users>;
  readonly owner_id?: Maybe<Scalars['String']['output']>;
  readonly privacy: Room_Privacy;
  /** An object relationship */
  readonly rooms_schedule?: Maybe<Schedules>;
  readonly schedule_id?: Maybe<Scalars['uuid']['output']>;
  readonly sort_order: Scalars['Int']['output'];
  readonly status: Room_Status;
  readonly tagline: Scalars['String']['output'];
  readonly unlock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at: Scalars['timestamptz']['output'];
  readonly url_name: Scalars['String']['output'];
};


/** columns and relationships of "rooms" */
export type RoomsBreakoutsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Breakouts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Breakouts_Order_By>>;
  where?: InputMaybe<Breakouts_Bool_Exp>;
};


/** columns and relationships of "rooms" */
export type RoomsBreakouts_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Breakouts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Breakouts_Order_By>>;
  where?: InputMaybe<Breakouts_Bool_Exp>;
};


/** columns and relationships of "rooms" */
export type RoomsChannel_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Users_Order_By>>;
  where?: InputMaybe<Channels_Users_Bool_Exp>;
};


/** columns and relationships of "rooms" */
export type RoomsChannel_Users_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Users_Order_By>>;
  where?: InputMaybe<Channels_Users_Bool_Exp>;
};


/** columns and relationships of "rooms" */
export type RoomsClipboard_PromptArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "rooms" */
export type RoomsHost_User_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "rooms" */
export type RoomsLive_EventsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Order_By>>;
  where?: InputMaybe<Live_Events_Bool_Exp>;
};


/** columns and relationships of "rooms" */
export type RoomsLive_Events_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Order_By>>;
  where?: InputMaybe<Live_Events_Bool_Exp>;
};

/** aggregated selection of "rooms" */
export type Rooms_Aggregate = {
  readonly __typename?: 'rooms_aggregate';
  readonly aggregate?: Maybe<Rooms_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Rooms>;
};

export type Rooms_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Rooms_Aggregate_Bool_Exp_Count>;
};

export type Rooms_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Rooms_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Rooms_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rooms" */
export type Rooms_Aggregate_Fields = {
  readonly __typename?: 'rooms_aggregate_fields';
  readonly avg?: Maybe<Rooms_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Rooms_Max_Fields>;
  readonly min?: Maybe<Rooms_Min_Fields>;
  readonly stddev?: Maybe<Rooms_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Rooms_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Rooms_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Rooms_Sum_Fields>;
  readonly var_pop?: Maybe<Rooms_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Rooms_Var_Samp_Fields>;
  readonly variance?: Maybe<Rooms_Variance_Fields>;
};


/** aggregate fields of "rooms" */
export type Rooms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Rooms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rooms" */
export type Rooms_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Rooms_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Rooms_Max_Order_By>;
  readonly min?: InputMaybe<Rooms_Min_Order_By>;
  readonly stddev?: InputMaybe<Rooms_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Rooms_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Rooms_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Rooms_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Rooms_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Rooms_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Rooms_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Rooms_Append_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['jsonb']['input']>;
  readonly host_user_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "rooms" */
export type Rooms_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Rooms_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Rooms_On_Conflict>;
};

/** aggregate avg on columns */
export type Rooms_Avg_Fields = {
  readonly __typename?: 'rooms_avg_fields';
  readonly sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rooms" */
export type Rooms_Avg_Order_By = {
  readonly sort_order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rooms". All fields are combined with a logical 'AND'. */
export type Rooms_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Rooms_Bool_Exp>>;
  readonly _not?: InputMaybe<Rooms_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Rooms_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly background_image_url?: InputMaybe<String_Comparison_Exp>;
  readonly bluemap_url?: InputMaybe<String_Comparison_Exp>;
  readonly breakouts?: InputMaybe<Breakouts_Bool_Exp>;
  readonly breakouts_aggregate?: InputMaybe<Breakouts_Aggregate_Bool_Exp>;
  readonly channel?: InputMaybe<Channels_Bool_Exp>;
  readonly channel_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly channel_users?: InputMaybe<Channels_Users_Bool_Exp>;
  readonly channel_users_aggregate?: InputMaybe<Channels_Users_Aggregate_Bool_Exp>;
  readonly clipboard_prompt?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly club_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly color?: InputMaybe<Room_Color_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly feedback_question?: InputMaybe<String_Comparison_Exp>;
  readonly host_user_ids?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly icon_url?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly kind?: InputMaybe<Room_Kind_Bool_Exp>;
  readonly live_events?: InputMaybe<Live_Events_Bool_Exp>;
  readonly live_events_aggregate?: InputMaybe<Live_Events_Aggregate_Bool_Exp>;
  readonly lock_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly owner?: InputMaybe<Users_Bool_Exp>;
  readonly owner_id?: InputMaybe<String_Comparison_Exp>;
  readonly privacy?: InputMaybe<Room_Privacy_Bool_Exp>;
  readonly rooms_schedule?: InputMaybe<Schedules_Bool_Exp>;
  readonly schedule_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly sort_order?: InputMaybe<Int_Comparison_Exp>;
  readonly status?: InputMaybe<Room_Status_Bool_Exp>;
  readonly tagline?: InputMaybe<String_Comparison_Exp>;
  readonly unlock_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly url_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "rooms" */
export enum Rooms_Constraint {
  /** unique or primary key constraint on columns "association_id" */
  RoomsAssociationIdKey = 'rooms_association_id_key',
  /** unique or primary key constraint on columns "club_id", "url_name" */
  RoomsClubIdUrlNameKey = 'rooms_club_id_url_name_key',
  /** unique or primary key constraint on columns "id" */
  RoomsPkey = 'rooms_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Rooms_Delete_At_Path_Input = {
  readonly clipboard_prompt?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly host_user_ids?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Rooms_Delete_Elem_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['Int']['input']>;
  readonly host_user_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Rooms_Delete_Key_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['String']['input']>;
  readonly host_user_ids?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "rooms" */
export type Rooms_Inc_Input = {
  readonly sort_order?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "rooms" */
export type Rooms_Insert_Input = {
  readonly background_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly bluemap_url?: InputMaybe<Scalars['String']['input']>;
  readonly breakouts?: InputMaybe<Breakouts_Arr_Rel_Insert_Input>;
  readonly channel?: InputMaybe<Channels_Obj_Rel_Insert_Input>;
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly channel_users?: InputMaybe<Channels_Users_Arr_Rel_Insert_Input>;
  readonly clipboard_prompt?: InputMaybe<Scalars['clipboard_prompt']['input']>;
  readonly club?: InputMaybe<Clubs_Obj_Rel_Insert_Input>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly color?: InputMaybe<Room_Color>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly feedback_question?: InputMaybe<Scalars['String']['input']>;
  readonly host_user_ids?: InputMaybe<Scalars['rooms_host_user_ids']['input']>;
  readonly icon_url?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly kind?: InputMaybe<Room_Kind>;
  readonly live_events?: InputMaybe<Live_Events_Arr_Rel_Insert_Input>;
  readonly lock_time?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly owner?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly owner_id?: InputMaybe<Scalars['String']['input']>;
  readonly privacy?: InputMaybe<Room_Privacy>;
  readonly rooms_schedule?: InputMaybe<Schedules_Obj_Rel_Insert_Input>;
  readonly schedule_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly sort_order?: InputMaybe<Scalars['Int']['input']>;
  readonly status?: InputMaybe<Room_Status>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly unlock_time?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly url_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rooms_Max_Fields = {
  readonly __typename?: 'rooms_max_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly background_image_url?: Maybe<Scalars['String']['output']>;
  readonly bluemap_url?: Maybe<Scalars['String']['output']>;
  readonly channel_id?: Maybe<Scalars['uuid']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly color?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly feedback_question?: Maybe<Scalars['String']['output']>;
  readonly icon_url?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly kind?: Maybe<Scalars['String']['output']>;
  readonly lock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly owner_id?: Maybe<Scalars['String']['output']>;
  readonly privacy?: Maybe<Scalars['String']['output']>;
  readonly schedule_id?: Maybe<Scalars['uuid']['output']>;
  readonly sort_order?: Maybe<Scalars['Int']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly unlock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly url_name?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rooms" */
export type Rooms_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly background_image_url?: InputMaybe<Order_By>;
  readonly bluemap_url?: InputMaybe<Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly color?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly feedback_question?: InputMaybe<Order_By>;
  readonly icon_url?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly kind?: InputMaybe<Order_By>;
  readonly lock_time?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly owner_id?: InputMaybe<Order_By>;
  readonly privacy?: InputMaybe<Order_By>;
  readonly schedule_id?: InputMaybe<Order_By>;
  readonly sort_order?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly tagline?: InputMaybe<Order_By>;
  readonly unlock_time?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly url_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rooms_Min_Fields = {
  readonly __typename?: 'rooms_min_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly background_image_url?: Maybe<Scalars['String']['output']>;
  readonly bluemap_url?: Maybe<Scalars['String']['output']>;
  readonly channel_id?: Maybe<Scalars['uuid']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly color?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly feedback_question?: Maybe<Scalars['String']['output']>;
  readonly icon_url?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly kind?: Maybe<Scalars['String']['output']>;
  readonly lock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly owner_id?: Maybe<Scalars['String']['output']>;
  readonly privacy?: Maybe<Scalars['String']['output']>;
  readonly schedule_id?: Maybe<Scalars['uuid']['output']>;
  readonly sort_order?: Maybe<Scalars['Int']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly unlock_time?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly url_name?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rooms" */
export type Rooms_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly background_image_url?: InputMaybe<Order_By>;
  readonly bluemap_url?: InputMaybe<Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly color?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly feedback_question?: InputMaybe<Order_By>;
  readonly icon_url?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly kind?: InputMaybe<Order_By>;
  readonly lock_time?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly owner_id?: InputMaybe<Order_By>;
  readonly privacy?: InputMaybe<Order_By>;
  readonly schedule_id?: InputMaybe<Order_By>;
  readonly sort_order?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly tagline?: InputMaybe<Order_By>;
  readonly unlock_time?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly url_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rooms" */
export type Rooms_Mutation_Response = {
  readonly __typename?: 'rooms_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Rooms>;
};

/** input type for inserting object relation for remote table "rooms" */
export type Rooms_Obj_Rel_Insert_Input = {
  readonly data: Rooms_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Rooms_On_Conflict>;
};

/** on_conflict condition type for table "rooms" */
export type Rooms_On_Conflict = {
  readonly constraint: Rooms_Constraint;
  readonly update_columns?: ReadonlyArray<Rooms_Update_Column>;
  readonly where?: InputMaybe<Rooms_Bool_Exp>;
};

/** Ordering options when selecting data from "rooms". */
export type Rooms_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly background_image_url?: InputMaybe<Order_By>;
  readonly bluemap_url?: InputMaybe<Order_By>;
  readonly breakouts_aggregate?: InputMaybe<Breakouts_Aggregate_Order_By>;
  readonly channel?: InputMaybe<Channels_Order_By>;
  readonly channel_id?: InputMaybe<Order_By>;
  readonly channel_users_aggregate?: InputMaybe<Channels_Users_Aggregate_Order_By>;
  readonly clipboard_prompt?: InputMaybe<Order_By>;
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly color?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly feedback_question?: InputMaybe<Order_By>;
  readonly host_user_ids?: InputMaybe<Order_By>;
  readonly icon_url?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly kind?: InputMaybe<Order_By>;
  readonly live_events_aggregate?: InputMaybe<Live_Events_Aggregate_Order_By>;
  readonly lock_time?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly owner?: InputMaybe<Users_Order_By>;
  readonly owner_id?: InputMaybe<Order_By>;
  readonly privacy?: InputMaybe<Order_By>;
  readonly rooms_schedule?: InputMaybe<Schedules_Order_By>;
  readonly schedule_id?: InputMaybe<Order_By>;
  readonly sort_order?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly tagline?: InputMaybe<Order_By>;
  readonly unlock_time?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly url_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rooms */
export type Rooms_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Rooms_Prepend_Input = {
  readonly clipboard_prompt?: InputMaybe<Scalars['jsonb']['input']>;
  readonly host_user_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "rooms" */
export enum Rooms_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  BackgroundImageUrl = 'background_image_url',
  /** column name */
  BluemapUrl = 'bluemap_url',
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  ClipboardPrompt = 'clipboard_prompt',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedbackQuestion = 'feedback_question',
  /** column name */
  HostUserIds = 'host_user_ids',
  /** column name */
  IconUrl = 'icon_url',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  LockTime = 'lock_time',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Privacy = 'privacy',
  /** column name */
  ScheduleId = 'schedule_id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Status = 'status',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  UnlockTime = 'unlock_time',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UrlName = 'url_name'
}

/** input type for updating data in table "rooms" */
export type Rooms_Set_Input = {
  readonly background_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly bluemap_url?: InputMaybe<Scalars['String']['input']>;
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly clipboard_prompt?: InputMaybe<Scalars['clipboard_prompt']['input']>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly color?: InputMaybe<Room_Color>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly feedback_question?: InputMaybe<Scalars['String']['input']>;
  readonly host_user_ids?: InputMaybe<Scalars['rooms_host_user_ids']['input']>;
  readonly icon_url?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly kind?: InputMaybe<Room_Kind>;
  readonly lock_time?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly owner_id?: InputMaybe<Scalars['String']['input']>;
  readonly privacy?: InputMaybe<Room_Privacy>;
  readonly schedule_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly sort_order?: InputMaybe<Scalars['Int']['input']>;
  readonly status?: InputMaybe<Room_Status>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly unlock_time?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly url_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rooms_Stddev_Fields = {
  readonly __typename?: 'rooms_stddev_fields';
  readonly sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rooms" */
export type Rooms_Stddev_Order_By = {
  readonly sort_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rooms_Stddev_Pop_Fields = {
  readonly __typename?: 'rooms_stddev_pop_fields';
  readonly sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rooms" */
export type Rooms_Stddev_Pop_Order_By = {
  readonly sort_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rooms_Stddev_Samp_Fields = {
  readonly __typename?: 'rooms_stddev_samp_fields';
  readonly sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rooms" */
export type Rooms_Stddev_Samp_Order_By = {
  readonly sort_order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "rooms" */
export type Rooms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Rooms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rooms_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly background_image_url?: InputMaybe<Scalars['String']['input']>;
  readonly bluemap_url?: InputMaybe<Scalars['String']['input']>;
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly clipboard_prompt?: InputMaybe<Scalars['jsonb']['input']>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly color?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly feedback_question?: InputMaybe<Scalars['String']['input']>;
  readonly host_user_ids?: InputMaybe<Scalars['jsonb']['input']>;
  readonly icon_url?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly kind?: InputMaybe<Scalars['String']['input']>;
  readonly lock_time?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly owner_id?: InputMaybe<Scalars['String']['input']>;
  readonly privacy?: InputMaybe<Scalars['String']['input']>;
  readonly schedule_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly sort_order?: InputMaybe<Scalars['Int']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly tagline?: InputMaybe<Scalars['String']['input']>;
  readonly unlock_time?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly url_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Rooms_Sum_Fields = {
  readonly __typename?: 'rooms_sum_fields';
  readonly sort_order?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "rooms" */
export type Rooms_Sum_Order_By = {
  readonly sort_order?: InputMaybe<Order_By>;
};

/** update columns of table "rooms" */
export enum Rooms_Update_Column {
  /** column name */
  BackgroundImageUrl = 'background_image_url',
  /** column name */
  BluemapUrl = 'bluemap_url',
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  ClipboardPrompt = 'clipboard_prompt',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedbackQuestion = 'feedback_question',
  /** column name */
  HostUserIds = 'host_user_ids',
  /** column name */
  IconUrl = 'icon_url',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  LockTime = 'lock_time',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Privacy = 'privacy',
  /** column name */
  ScheduleId = 'schedule_id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Status = 'status',
  /** column name */
  Tagline = 'tagline',
  /** column name */
  UnlockTime = 'unlock_time',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UrlName = 'url_name'
}

export type Rooms_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Rooms_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Rooms_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Rooms_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Rooms_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Rooms_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Rooms_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Rooms_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Rooms_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Rooms_Var_Pop_Fields = {
  readonly __typename?: 'rooms_var_pop_fields';
  readonly sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rooms" */
export type Rooms_Var_Pop_Order_By = {
  readonly sort_order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rooms_Var_Samp_Fields = {
  readonly __typename?: 'rooms_var_samp_fields';
  readonly sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rooms" */
export type Rooms_Var_Samp_Order_By = {
  readonly sort_order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rooms_Variance_Fields = {
  readonly __typename?: 'rooms_variance_fields';
  readonly sort_order?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rooms" */
export type Rooms_Variance_Order_By = {
  readonly sort_order?: InputMaybe<Order_By>;
};

/** Responsible for controlling the schedules for club rooms */
export type Schedules = {
  readonly __typename?: 'schedules';
  readonly id: Scalars['uuid']['output'];
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly schedule: Scalars['schedules_schedule']['output'];
  readonly timezone: Scalars['String']['output'];
};


/** Responsible for controlling the schedules for club rooms */
export type SchedulesScheduleArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "schedules" */
export type Schedules_Aggregate = {
  readonly __typename?: 'schedules_aggregate';
  readonly aggregate?: Maybe<Schedules_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Schedules>;
};

/** aggregate fields of "schedules" */
export type Schedules_Aggregate_Fields = {
  readonly __typename?: 'schedules_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Schedules_Max_Fields>;
  readonly min?: Maybe<Schedules_Min_Fields>;
};


/** aggregate fields of "schedules" */
export type Schedules_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Schedules_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Schedules_Append_Input = {
  readonly schedule?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "schedules". All fields are combined with a logical 'AND'. */
export type Schedules_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Schedules_Bool_Exp>>;
  readonly _not?: InputMaybe<Schedules_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Schedules_Bool_Exp>>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly schedule?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly timezone?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "schedules" */
export enum Schedules_Constraint {
  /** unique or primary key constraint on columns "id" */
  RoomsSchedulesPkey = 'rooms_schedules_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Schedules_Delete_At_Path_Input = {
  readonly schedule?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Schedules_Delete_Elem_Input = {
  readonly schedule?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Schedules_Delete_Key_Input = {
  readonly schedule?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "schedules" */
export type Schedules_Insert_Input = {
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly schedule?: InputMaybe<Scalars['schedules_schedule']['input']>;
  readonly timezone?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Schedules_Max_Fields = {
  readonly __typename?: 'schedules_max_fields';
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly timezone?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Schedules_Min_Fields = {
  readonly __typename?: 'schedules_min_fields';
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly timezone?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "schedules" */
export type Schedules_Mutation_Response = {
  readonly __typename?: 'schedules_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Schedules>;
};

/** input type for inserting object relation for remote table "schedules" */
export type Schedules_Obj_Rel_Insert_Input = {
  readonly data: Schedules_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Schedules_On_Conflict>;
};

/** on_conflict condition type for table "schedules" */
export type Schedules_On_Conflict = {
  readonly constraint: Schedules_Constraint;
  readonly update_columns?: ReadonlyArray<Schedules_Update_Column>;
  readonly where?: InputMaybe<Schedules_Bool_Exp>;
};

/** Ordering options when selecting data from "schedules". */
export type Schedules_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly schedule?: InputMaybe<Order_By>;
  readonly timezone?: InputMaybe<Order_By>;
};

/** primary key columns input for table: schedules */
export type Schedules_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Schedules_Prepend_Input = {
  readonly schedule?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "schedules" */
export enum Schedules_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  Timezone = 'timezone'
}

/** input type for updating data in table "schedules" */
export type Schedules_Set_Input = {
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly schedule?: InputMaybe<Scalars['schedules_schedule']['input']>;
  readonly timezone?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "schedules" */
export type Schedules_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Schedules_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Schedules_Stream_Cursor_Value_Input = {
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly schedule?: InputMaybe<Scalars['jsonb']['input']>;
  readonly timezone?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "schedules" */
export enum Schedules_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  Timezone = 'timezone'
}

export type Schedules_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Schedules_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Schedules_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Schedules_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Schedules_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Schedules_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Schedules_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Schedules_Bool_Exp;
};

export type SendPasswordResetOutput = {
  readonly __typename?: 'sendPasswordResetOutput';
  readonly success?: Maybe<Scalars['Boolean']['output']>;
};

/** columns and relationships of "shared_cached_queries" */
export type Shared_Cached_Queries = {
  readonly __typename?: 'shared_cached_queries';
  readonly club_id: Scalars['uuid']['output'];
  readonly id: Scalars['Int']['output'];
  readonly key: Scalars['String']['output'];
  readonly max_age_ms: Scalars['Int']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  readonly value: Scalars['String']['output'];
};

export type Shared_Cached_Queries_Aggregate = {
  readonly __typename?: 'shared_cached_queries_aggregate';
  readonly aggregate?: Maybe<Shared_Cached_Queries_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Shared_Cached_Queries>;
};

/** aggregate fields of "shared_cached_queries" */
export type Shared_Cached_Queries_Aggregate_Fields = {
  readonly __typename?: 'shared_cached_queries_aggregate_fields';
  readonly avg?: Maybe<Shared_Cached_Queries_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Shared_Cached_Queries_Max_Fields>;
  readonly min?: Maybe<Shared_Cached_Queries_Min_Fields>;
  readonly stddev?: Maybe<Shared_Cached_Queries_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Shared_Cached_Queries_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Shared_Cached_Queries_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Shared_Cached_Queries_Sum_Fields>;
  readonly var_pop?: Maybe<Shared_Cached_Queries_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Shared_Cached_Queries_Var_Samp_Fields>;
  readonly variance?: Maybe<Shared_Cached_Queries_Variance_Fields>;
};


/** aggregate fields of "shared_cached_queries" */
export type Shared_Cached_Queries_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Shared_Cached_Queries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Shared_Cached_Queries_Avg_Fields = {
  readonly __typename?: 'shared_cached_queries_avg_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly max_age_ms?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "shared_cached_queries". All fields are combined with a logical 'AND'. */
export type Shared_Cached_Queries_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Shared_Cached_Queries_Bool_Exp>>;
  readonly _not?: InputMaybe<Shared_Cached_Queries_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Shared_Cached_Queries_Bool_Exp>>;
  readonly club_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly id?: InputMaybe<Int_Comparison_Exp>;
  readonly key?: InputMaybe<String_Comparison_Exp>;
  readonly max_age_ms?: InputMaybe<Int_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "shared_cached_queries" */
export enum Shared_Cached_Queries_Constraint {
  /** unique or primary key constraint on columns "key", "club_id" */
  SharedCachedQueriesKeyClubIdKey = 'shared_cached_queries_key_club_id_key',
  /** unique or primary key constraint on columns "id" */
  SharedCachedQueriesPkey = 'shared_cached_queries_pkey'
}

/** input type for incrementing numeric columns in table "shared_cached_queries" */
export type Shared_Cached_Queries_Inc_Input = {
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly max_age_ms?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "shared_cached_queries" */
export type Shared_Cached_Queries_Insert_Input = {
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly key?: InputMaybe<Scalars['String']['input']>;
  readonly max_age_ms?: InputMaybe<Scalars['Int']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Shared_Cached_Queries_Max_Fields = {
  readonly __typename?: 'shared_cached_queries_max_fields';
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly key?: Maybe<Scalars['String']['output']>;
  readonly max_age_ms?: Maybe<Scalars['Int']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Shared_Cached_Queries_Min_Fields = {
  readonly __typename?: 'shared_cached_queries_min_fields';
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly key?: Maybe<Scalars['String']['output']>;
  readonly max_age_ms?: Maybe<Scalars['Int']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "shared_cached_queries" */
export type Shared_Cached_Queries_Mutation_Response = {
  readonly __typename?: 'shared_cached_queries_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Shared_Cached_Queries>;
};

/** on_conflict condition type for table "shared_cached_queries" */
export type Shared_Cached_Queries_On_Conflict = {
  readonly constraint: Shared_Cached_Queries_Constraint;
  readonly update_columns?: ReadonlyArray<Shared_Cached_Queries_Update_Column>;
  readonly where?: InputMaybe<Shared_Cached_Queries_Bool_Exp>;
};

/** Ordering options when selecting data from "shared_cached_queries". */
export type Shared_Cached_Queries_Order_By = {
  readonly club_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly key?: InputMaybe<Order_By>;
  readonly max_age_ms?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: shared_cached_queries */
export type Shared_Cached_Queries_Pk_Columns_Input = {
  readonly id: Scalars['Int']['input'];
};

/** select columns of table "shared_cached_queries" */
export enum Shared_Cached_Queries_Select_Column {
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  MaxAgeMs = 'max_age_ms',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "shared_cached_queries" */
export type Shared_Cached_Queries_Set_Input = {
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly key?: InputMaybe<Scalars['String']['input']>;
  readonly max_age_ms?: InputMaybe<Scalars['Int']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Shared_Cached_Queries_Stddev_Fields = {
  readonly __typename?: 'shared_cached_queries_stddev_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly max_age_ms?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Shared_Cached_Queries_Stddev_Pop_Fields = {
  readonly __typename?: 'shared_cached_queries_stddev_pop_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly max_age_ms?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Shared_Cached_Queries_Stddev_Samp_Fields = {
  readonly __typename?: 'shared_cached_queries_stddev_samp_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly max_age_ms?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "shared_cached_queries" */
export type Shared_Cached_Queries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Shared_Cached_Queries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Shared_Cached_Queries_Stream_Cursor_Value_Input = {
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly key?: InputMaybe<Scalars['String']['input']>;
  readonly max_age_ms?: InputMaybe<Scalars['Int']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Shared_Cached_Queries_Sum_Fields = {
  readonly __typename?: 'shared_cached_queries_sum_fields';
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly max_age_ms?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "shared_cached_queries" */
export enum Shared_Cached_Queries_Update_Column {
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  MaxAgeMs = 'max_age_ms',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Shared_Cached_Queries_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Shared_Cached_Queries_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Shared_Cached_Queries_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Shared_Cached_Queries_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Shared_Cached_Queries_Var_Pop_Fields = {
  readonly __typename?: 'shared_cached_queries_var_pop_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly max_age_ms?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Shared_Cached_Queries_Var_Samp_Fields = {
  readonly __typename?: 'shared_cached_queries_var_samp_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly max_age_ms?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Shared_Cached_Queries_Variance_Fields = {
  readonly __typename?: 'shared_cached_queries_variance_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly max_age_ms?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "short_urls" */
export type Short_Urls = {
  readonly __typename?: 'short_urls';
  readonly destination: Scalars['String']['output'];
  readonly expires_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly source: Scalars['String']['output'];
};

/** aggregated selection of "short_urls" */
export type Short_Urls_Aggregate = {
  readonly __typename?: 'short_urls_aggregate';
  readonly aggregate?: Maybe<Short_Urls_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Short_Urls>;
};

/** aggregate fields of "short_urls" */
export type Short_Urls_Aggregate_Fields = {
  readonly __typename?: 'short_urls_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Short_Urls_Max_Fields>;
  readonly min?: Maybe<Short_Urls_Min_Fields>;
};


/** aggregate fields of "short_urls" */
export type Short_Urls_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Short_Urls_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "short_urls". All fields are combined with a logical 'AND'. */
export type Short_Urls_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Short_Urls_Bool_Exp>>;
  readonly _not?: InputMaybe<Short_Urls_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Short_Urls_Bool_Exp>>;
  readonly destination?: InputMaybe<String_Comparison_Exp>;
  readonly expires_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly source?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "short_urls" */
export enum Short_Urls_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShortUrlsPkey = 'short_urls_pkey'
}

/** input type for inserting data into table "short_urls" */
export type Short_Urls_Insert_Input = {
  readonly destination?: InputMaybe<Scalars['String']['input']>;
  readonly expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly source?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Short_Urls_Max_Fields = {
  readonly __typename?: 'short_urls_max_fields';
  readonly destination?: Maybe<Scalars['String']['output']>;
  readonly expires_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly source?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Short_Urls_Min_Fields = {
  readonly __typename?: 'short_urls_min_fields';
  readonly destination?: Maybe<Scalars['String']['output']>;
  readonly expires_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly source?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "short_urls" */
export type Short_Urls_Mutation_Response = {
  readonly __typename?: 'short_urls_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Short_Urls>;
};

/** on_conflict condition type for table "short_urls" */
export type Short_Urls_On_Conflict = {
  readonly constraint: Short_Urls_Constraint;
  readonly update_columns?: ReadonlyArray<Short_Urls_Update_Column>;
  readonly where?: InputMaybe<Short_Urls_Bool_Exp>;
};

/** Ordering options when selecting data from "short_urls". */
export type Short_Urls_Order_By = {
  readonly destination?: InputMaybe<Order_By>;
  readonly expires_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly source?: InputMaybe<Order_By>;
};

/** primary key columns input for table: short_urls */
export type Short_Urls_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "short_urls" */
export enum Short_Urls_Select_Column {
  /** column name */
  Destination = 'destination',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  Source = 'source'
}

/** input type for updating data in table "short_urls" */
export type Short_Urls_Set_Input = {
  readonly destination?: InputMaybe<Scalars['String']['input']>;
  readonly expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly source?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "short_urls" */
export type Short_Urls_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Short_Urls_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Short_Urls_Stream_Cursor_Value_Input = {
  readonly destination?: InputMaybe<Scalars['String']['input']>;
  readonly expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly source?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "short_urls" */
export enum Short_Urls_Update_Column {
  /** column name */
  Destination = 'destination',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  Source = 'source'
}

export type Short_Urls_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Short_Urls_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Short_Urls_Bool_Exp;
};

export type Submit_Chat_Message_Args = {
  readonly _caps_threshold?: InputMaybe<Scalars['numeric']['input']>;
  readonly _channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly _created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly _id?: InputMaybe<Scalars['uuid']['input']>;
  readonly _last_message_period?: InputMaybe<Scalars['Int']['input']>;
  readonly _message?: InputMaybe<Scalars['String']['input']>;
  readonly _repetition_min_length?: InputMaybe<Scalars['Int']['input']>;
  readonly _repetition_threshold?: InputMaybe<Scalars['numeric']['input']>;
  readonly _sender_id?: InputMaybe<Scalars['String']['input']>;
  readonly _similarity_message_period?: InputMaybe<Scalars['Int']['input']>;
  readonly _similarity_threshold?: InputMaybe<Scalars['numeric']['input']>;
};

export type Subscription_Root = {
  readonly __typename?: 'subscription_root';
  /** An array relationship */
  readonly accounts: ReadonlyArray<Accounts>;
  /** An aggregate relationship */
  readonly accounts_aggregate: Accounts_Aggregate;
  /** fetch data from the table: "accounts" using primary key columns */
  readonly accounts_by_pk?: Maybe<Accounts>;
  /** fetch data from the table in a streaming manner: "accounts" */
  readonly accounts_stream: ReadonlyArray<Accounts>;
  /** fetch data from the table: "asa_user_quest_task" */
  readonly asa_user_quest_task: ReadonlyArray<Asa_User_Quest_Task>;
  /** fetch aggregated fields from the table: "asa_user_quest_task" */
  readonly asa_user_quest_task_aggregate: Asa_User_Quest_Task_Aggregate;
  /** fetch data from the table: "asa_user_quest_task" using primary key columns */
  readonly asa_user_quest_task_by_pk?: Maybe<Asa_User_Quest_Task>;
  /** fetch data from the table in a streaming manner: "asa_user_quest_task" */
  readonly asa_user_quest_task_stream: ReadonlyArray<Asa_User_Quest_Task>;
  /** fetch data from the table: "avatars" */
  readonly avatars: ReadonlyArray<Avatars>;
  /** fetch aggregated fields from the table: "avatars" */
  readonly avatars_aggregate: Avatars_Aggregate;
  /** fetch data from the table: "avatars" using primary key columns */
  readonly avatars_by_pk?: Maybe<Avatars>;
  /** fetch data from the table in a streaming manner: "avatars" */
  readonly avatars_stream: ReadonlyArray<Avatars>;
  /** An array relationship */
  readonly badges: ReadonlyArray<Badges>;
  /** An aggregate relationship */
  readonly badges_aggregate: Badges_Aggregate;
  /** fetch data from the table: "badges" using primary key columns */
  readonly badges_by_pk?: Maybe<Badges>;
  /** fetch data from the table in a streaming manner: "badges" */
  readonly badges_stream: ReadonlyArray<Badges>;
  /** fetch data from the table: "bookings" */
  readonly bookings: ReadonlyArray<Bookings>;
  /** fetch aggregated fields from the table: "bookings" */
  readonly bookings_aggregate: Bookings_Aggregate;
  /** fetch data from the table: "bookings" using primary key columns */
  readonly bookings_by_pk?: Maybe<Bookings>;
  /** fetch data from the table in a streaming manner: "bookings" */
  readonly bookings_stream: ReadonlyArray<Bookings>;
  /** An array relationship */
  readonly breakouts: ReadonlyArray<Breakouts>;
  /** An aggregate relationship */
  readonly breakouts_aggregate: Breakouts_Aggregate;
  /** fetch data from the table: "breakouts" using primary key columns */
  readonly breakouts_by_pk?: Maybe<Breakouts>;
  /** fetch data from the table in a streaming manner: "breakouts" */
  readonly breakouts_stream: ReadonlyArray<Breakouts>;
  /** fetch data from the table: "build_battles" */
  readonly build_battles: ReadonlyArray<Build_Battles>;
  /** fetch aggregated fields from the table: "build_battles" */
  readonly build_battles_aggregate: Build_Battles_Aggregate;
  /** fetch data from the table: "build_battles" using primary key columns */
  readonly build_battles_by_pk?: Maybe<Build_Battles>;
  /** fetch data from the table: "build_battles_projects" */
  readonly build_battles_projects: ReadonlyArray<Build_Battles_Projects>;
  /** fetch aggregated fields from the table: "build_battles_projects" */
  readonly build_battles_projects_aggregate: Build_Battles_Projects_Aggregate;
  /** fetch data from the table: "build_battles_projects" using primary key columns */
  readonly build_battles_projects_by_pk?: Maybe<Build_Battles_Projects>;
  /** fetch data from the table in a streaming manner: "build_battles_projects" */
  readonly build_battles_projects_stream: ReadonlyArray<Build_Battles_Projects>;
  /** fetch data from the table in a streaming manner: "build_battles" */
  readonly build_battles_stream: ReadonlyArray<Build_Battles>;
  /** fetch data from the table: "build_battles_themes" */
  readonly build_battles_themes: ReadonlyArray<Build_Battles_Themes>;
  /** fetch aggregated fields from the table: "build_battles_themes" */
  readonly build_battles_themes_aggregate: Build_Battles_Themes_Aggregate;
  /** fetch data from the table: "build_battles_themes" using primary key columns */
  readonly build_battles_themes_by_pk?: Maybe<Build_Battles_Themes>;
  /** fetch data from the table in a streaming manner: "build_battles_themes" */
  readonly build_battles_themes_stream: ReadonlyArray<Build_Battles_Themes>;
  /** fetch data from the table: "channels" */
  readonly channels: ReadonlyArray<Channels>;
  /** fetch aggregated fields from the table: "channels" */
  readonly channels_aggregate: Channels_Aggregate;
  /** fetch data from the table: "channels" using primary key columns */
  readonly channels_by_pk?: Maybe<Channels>;
  /** fetch data from the table: "channels_events" */
  readonly channels_events: ReadonlyArray<Channels_Events>;
  /** fetch aggregated fields from the table: "channels_events" */
  readonly channels_events_aggregate: Channels_Events_Aggregate;
  /** fetch data from the table: "channels_events" using primary key columns */
  readonly channels_events_by_pk?: Maybe<Channels_Events>;
  /** fetch data from the table in a streaming manner: "channels_events" */
  readonly channels_events_stream: ReadonlyArray<Channels_Events>;
  /** fetch data from the table in a streaming manner: "channels" */
  readonly channels_stream: ReadonlyArray<Channels>;
  /** An array relationship */
  readonly channels_users: ReadonlyArray<Channels_Users>;
  /** An aggregate relationship */
  readonly channels_users_aggregate: Channels_Users_Aggregate;
  /** fetch data from the table: "channels_users" using primary key columns */
  readonly channels_users_by_pk?: Maybe<Channels_Users>;
  /** fetch data from the table in a streaming manner: "channels_users" */
  readonly channels_users_stream: ReadonlyArray<Channels_Users>;
  /** An array relationship */
  readonly chats: ReadonlyArray<Chats>;
  /** An aggregate relationship */
  readonly chats_aggregate: Chats_Aggregate;
  /** fetch data from the table: "chats" using primary key columns */
  readonly chats_by_pk?: Maybe<Chats>;
  /** fetch data from the table in a streaming manner: "chats" */
  readonly chats_stream: ReadonlyArray<Chats>;
  /** An array relationship */
  readonly clubs: ReadonlyArray<Clubs>;
  /** An aggregate relationship */
  readonly clubs_aggregate: Clubs_Aggregate;
  /** fetch data from the table: "clubs" using primary key columns */
  readonly clubs_by_pk?: Maybe<Clubs>;
  /** fetch data from the table: "clubs_members" */
  readonly clubs_members: ReadonlyArray<Clubs_Members>;
  /** fetch aggregated fields from the table: "clubs_members" */
  readonly clubs_members_aggregate: Clubs_Members_Aggregate;
  /** fetch data from the table: "clubs_members" using primary key columns */
  readonly clubs_members_by_pk?: Maybe<Clubs_Members>;
  /** fetch data from the table in a streaming manner: "clubs_members" */
  readonly clubs_members_stream: ReadonlyArray<Clubs_Members>;
  /** fetch data from the table in a streaming manner: "clubs" */
  readonly clubs_stream: ReadonlyArray<Clubs>;
  /** An array relationship */
  readonly comments: ReadonlyArray<Comments>;
  /** An aggregate relationship */
  readonly comments_aggregate: Comments_Aggregate;
  /** fetch data from the table: "comments" using primary key columns */
  readonly comments_by_pk?: Maybe<Comments>;
  /** fetch data from the table in a streaming manner: "comments" */
  readonly comments_stream: ReadonlyArray<Comments>;
  /** An array relationship */
  readonly comments_votes: ReadonlyArray<Comments_Votes>;
  /** An aggregate relationship */
  readonly comments_votes_aggregate: Comments_Votes_Aggregate;
  /** fetch data from the table: "comments_votes" using primary key columns */
  readonly comments_votes_by_pk?: Maybe<Comments_Votes>;
  /** fetch data from the table in a streaming manner: "comments_votes" */
  readonly comments_votes_stream: ReadonlyArray<Comments_Votes>;
  /** An array relationship */
  readonly competitions: ReadonlyArray<Competitions>;
  /** An aggregate relationship */
  readonly competitions_aggregate: Competitions_Aggregate;
  /** fetch data from the table: "competitions" using primary key columns */
  readonly competitions_by_pk?: Maybe<Competitions>;
  /** fetch data from the table: "competitions_judges" */
  readonly competitions_judges: ReadonlyArray<Competitions_Judges>;
  /** fetch aggregated fields from the table: "competitions_judges" */
  readonly competitions_judges_aggregate: Competitions_Judges_Aggregate;
  /** fetch data from the table: "competitions_judges" using primary key columns */
  readonly competitions_judges_by_pk?: Maybe<Competitions_Judges>;
  /** fetch data from the table in a streaming manner: "competitions_judges" */
  readonly competitions_judges_stream: ReadonlyArray<Competitions_Judges>;
  /** fetch data from the table in a streaming manner: "competitions" */
  readonly competitions_stream: ReadonlyArray<Competitions>;
  /** fetch data from the table: "competitions_tags" */
  readonly competitions_tags: ReadonlyArray<Competitions_Tags>;
  /** fetch aggregated fields from the table: "competitions_tags" */
  readonly competitions_tags_aggregate: Competitions_Tags_Aggregate;
  /** fetch data from the table: "competitions_tags" using primary key columns */
  readonly competitions_tags_by_pk?: Maybe<Competitions_Tags>;
  /** fetch data from the table in a streaming manner: "competitions_tags" */
  readonly competitions_tags_stream: ReadonlyArray<Competitions_Tags>;
  /** fetch data from the table: "competitions_tutorials" */
  readonly competitions_tutorials: ReadonlyArray<Competitions_Tutorials>;
  /** fetch aggregated fields from the table: "competitions_tutorials" */
  readonly competitions_tutorials_aggregate: Competitions_Tutorials_Aggregate;
  /** fetch data from the table: "competitions_tutorials" using primary key columns */
  readonly competitions_tutorials_by_pk?: Maybe<Competitions_Tutorials>;
  /** fetch data from the table in a streaming manner: "competitions_tutorials" */
  readonly competitions_tutorials_stream: ReadonlyArray<Competitions_Tutorials>;
  /** fetch data from the table: "eban_comments" */
  readonly eban_comments: ReadonlyArray<Eban_Comments>;
  /** fetch aggregated fields from the table: "eban_comments" */
  readonly eban_comments_aggregate: Eban_Comments_Aggregate;
  /** fetch data from the table: "eban_comments" using primary key columns */
  readonly eban_comments_by_pk?: Maybe<Eban_Comments>;
  /** fetch data from the table in a streaming manner: "eban_comments" */
  readonly eban_comments_stream: ReadonlyArray<Eban_Comments>;
  /** fetch data from the table: "eban_types" */
  readonly eban_types: ReadonlyArray<Eban_Types>;
  /** fetch aggregated fields from the table: "eban_types" */
  readonly eban_types_aggregate: Eban_Types_Aggregate;
  /** fetch data from the table: "eban_types" using primary key columns */
  readonly eban_types_by_pk?: Maybe<Eban_Types>;
  /** fetch data from the table in a streaming manner: "eban_types" */
  readonly eban_types_stream: ReadonlyArray<Eban_Types>;
  /** fetch data from the table: "ebans" */
  readonly ebans: ReadonlyArray<Ebans>;
  /** fetch aggregated fields from the table: "ebans" */
  readonly ebans_aggregate: Ebans_Aggregate;
  /** fetch data from the table: "ebans" using primary key columns */
  readonly ebans_by_pk?: Maybe<Ebans>;
  /** fetch data from the table in a streaming manner: "ebans" */
  readonly ebans_stream: ReadonlyArray<Ebans>;
  /** fetch data from the table: "environment" */
  readonly environment: ReadonlyArray<Environment>;
  /** fetch aggregated fields from the table: "environment" */
  readonly environment_aggregate: Environment_Aggregate;
  /** fetch data from the table: "environment" using primary key columns */
  readonly environment_by_pk?: Maybe<Environment>;
  /** fetch data from the table in a streaming manner: "environment" */
  readonly environment_stream: ReadonlyArray<Environment>;
  /** Fetches a result from the shared_cached_queries. If no such entry exists or it's stale (max_age exceeded), hits /api/cache/shared to perform the query. */
  readonly execute_shared_cached_query: ReadonlyArray<Shared_Cached_Queries>;
  /** execute function "execute_shared_cached_query" and query aggregates on result of table type "shared_cached_queries" */
  readonly execute_shared_cached_query_aggregate: Shared_Cached_Queries_Aggregate;
  /** Taking into consideration max age, fetches a result from the users_cached_queries. If no such entry exists, hits /api/cache/user to perform the query. */
  readonly execute_users_cached_query: ReadonlyArray<Users_Cached_Queries>;
  /** execute function "execute_users_cached_query" and query aggregates on result of table type "users_cached_queries" */
  readonly execute_users_cached_query_aggregate: Users_Cached_Queries_Aggregate;
  /** fetch data from the table: "features" */
  readonly features: ReadonlyArray<Features>;
  /** fetch aggregated fields from the table: "features" */
  readonly features_aggregate: Features_Aggregate;
  /** fetch data from the table: "features" using primary key columns */
  readonly features_by_pk?: Maybe<Features>;
  /** fetch data from the table: "features_products" */
  readonly features_products: ReadonlyArray<Features_Products>;
  /** fetch aggregated fields from the table: "features_products" */
  readonly features_products_aggregate: Features_Products_Aggregate;
  /** fetch data from the table: "features_products" using primary key columns */
  readonly features_products_by_pk?: Maybe<Features_Products>;
  /** fetch data from the table in a streaming manner: "features_products" */
  readonly features_products_stream: ReadonlyArray<Features_Products>;
  /** fetch data from the table in a streaming manner: "features" */
  readonly features_stream: ReadonlyArray<Features>;
  /** An array relationship */
  readonly feedback: ReadonlyArray<Feedback>;
  /** An aggregate relationship */
  readonly feedback_aggregate: Feedback_Aggregate;
  /** fetch data from the table: "feedback" using primary key columns */
  readonly feedback_by_pk?: Maybe<Feedback>;
  /** fetch data from the table in a streaming manner: "feedback" */
  readonly feedback_stream: ReadonlyArray<Feedback>;
  /** An array relationship */
  readonly feeds: ReadonlyArray<Feeds>;
  /** An aggregate relationship */
  readonly feeds_aggregate: Feeds_Aggregate;
  /** fetch data from the table: "feeds" using primary key columns */
  readonly feeds_by_pk?: Maybe<Feeds>;
  /** fetch data from the table in a streaming manner: "feeds" */
  readonly feeds_stream: ReadonlyArray<Feeds>;
  /** fetch data from the table: "follows" */
  readonly follows: ReadonlyArray<Follows>;
  /** fetch aggregated fields from the table: "follows" */
  readonly follows_aggregate: Follows_Aggregate;
  /** fetch data from the table: "follows" using primary key columns */
  readonly follows_by_pk?: Maybe<Follows>;
  /** fetch data from the table in a streaming manner: "follows" */
  readonly follows_stream: ReadonlyArray<Follows>;
  /** fetch data from the table: "friends" */
  readonly friends: ReadonlyArray<Friends>;
  /** fetch aggregated fields from the table: "friends" */
  readonly friends_aggregate: Friends_Aggregate;
  /** fetch data from the table: "friends" using primary key columns */
  readonly friends_by_pk?: Maybe<Friends>;
  /** fetch data from the table in a streaming manner: "friends" */
  readonly friends_stream: ReadonlyArray<Friends>;
  /** fetch data from the table: "friendships" */
  readonly friendships: ReadonlyArray<Friendships>;
  /** fetch aggregated fields from the table: "friendships" */
  readonly friendships_aggregate: Friendships_Aggregate;
  /** fetch data from the table: "friendships" using primary key columns */
  readonly friendships_by_pk?: Maybe<Friendships>;
  /** fetch data from the table in a streaming manner: "friendships" */
  readonly friendships_stream: ReadonlyArray<Friendships>;
  /** An array relationship */
  readonly friendships_users: ReadonlyArray<Friendships_Users>;
  /** An aggregate relationship */
  readonly friendships_users_aggregate: Friendships_Users_Aggregate;
  /** fetch data from the table: "friendships_users" using primary key columns */
  readonly friendships_users_by_pk?: Maybe<Friendships_Users>;
  /** fetch data from the table in a streaming manner: "friendships_users" */
  readonly friendships_users_stream: ReadonlyArray<Friendships_Users>;
  /** fetch data from the table: "game_servers" */
  readonly game_servers: ReadonlyArray<Game_Servers>;
  /** fetch aggregated fields from the table: "game_servers" */
  readonly game_servers_aggregate: Game_Servers_Aggregate;
  /** fetch data from the table: "game_servers" using primary key columns */
  readonly game_servers_by_pk?: Maybe<Game_Servers>;
  /** fetch data from the table in a streaming manner: "game_servers" */
  readonly game_servers_stream: ReadonlyArray<Game_Servers>;
  /** fetch data from the table: "homes" */
  readonly homes: ReadonlyArray<Homes>;
  /** fetch aggregated fields from the table: "homes" */
  readonly homes_aggregate: Homes_Aggregate;
  /** fetch data from the table: "homes" using primary key columns */
  readonly homes_by_pk?: Maybe<Homes>;
  /** fetch data from the table in a streaming manner: "homes" */
  readonly homes_stream: ReadonlyArray<Homes>;
  /** fetch data from the table: "image_cache" */
  readonly image_cache: ReadonlyArray<Image_Cache>;
  /** fetch aggregated fields from the table: "image_cache" */
  readonly image_cache_aggregate: Image_Cache_Aggregate;
  /** fetch data from the table: "image_cache" using primary key columns */
  readonly image_cache_by_pk?: Maybe<Image_Cache>;
  /** fetch data from the table in a streaming manner: "image_cache" */
  readonly image_cache_stream: ReadonlyArray<Image_Cache>;
  /** fetch data from the table: "infrastructure_operations" */
  readonly infrastructure_operations: ReadonlyArray<Infrastructure_Operations>;
  /** fetch aggregated fields from the table: "infrastructure_operations" */
  readonly infrastructure_operations_aggregate: Infrastructure_Operations_Aggregate;
  /** fetch data from the table in a streaming manner: "infrastructure_operations" */
  readonly infrastructure_operations_stream: ReadonlyArray<Infrastructure_Operations>;
  /** fetch data from the table: "items" */
  readonly items: ReadonlyArray<Items>;
  /** fetch aggregated fields from the table: "items" */
  readonly items_aggregate: Items_Aggregate;
  /** fetch data from the table: "items" using primary key columns */
  readonly items_by_pk?: Maybe<Items>;
  /** fetch data from the table in a streaming manner: "items" */
  readonly items_stream: ReadonlyArray<Items>;
  /** fetch data from the table: "items_tags" */
  readonly items_tags: ReadonlyArray<Items_Tags>;
  /** fetch aggregated fields from the table: "items_tags" */
  readonly items_tags_aggregate: Items_Tags_Aggregate;
  /** fetch data from the table: "items_tags" using primary key columns */
  readonly items_tags_by_pk?: Maybe<Items_Tags>;
  /** fetch data from the table in a streaming manner: "items_tags" */
  readonly items_tags_stream: ReadonlyArray<Items_Tags>;
  /** fetch data from the table: "levels" */
  readonly levels: ReadonlyArray<Levels>;
  /** fetch aggregated fields from the table: "levels" */
  readonly levels_aggregate: Levels_Aggregate;
  /** fetch data from the table: "levels" using primary key columns */
  readonly levels_by_pk?: Maybe<Levels>;
  /** fetch data from the table in a streaming manner: "levels" */
  readonly levels_stream: ReadonlyArray<Levels>;
  /** An array relationship */
  readonly live_events: ReadonlyArray<Live_Events>;
  /** An aggregate relationship */
  readonly live_events_aggregate: Live_Events_Aggregate;
  /** fetch data from the table: "live_events" using primary key columns */
  readonly live_events_by_pk?: Maybe<Live_Events>;
  /** fetch data from the table: "live_events_hosts" */
  readonly live_events_hosts: ReadonlyArray<Live_Events_Hosts>;
  /** fetch aggregated fields from the table: "live_events_hosts" */
  readonly live_events_hosts_aggregate: Live_Events_Hosts_Aggregate;
  /** fetch data from the table: "live_events_hosts" using primary key columns */
  readonly live_events_hosts_by_pk?: Maybe<Live_Events_Hosts>;
  /** fetch data from the table in a streaming manner: "live_events_hosts" */
  readonly live_events_hosts_stream: ReadonlyArray<Live_Events_Hosts>;
  /** fetch data from the table in a streaming manner: "live_events" */
  readonly live_events_stream: ReadonlyArray<Live_Events>;
  /** fetch data from the table: "messages" */
  readonly messages: ReadonlyArray<Messages>;
  /** fetch aggregated fields from the table: "messages" */
  readonly messages_aggregate: Messages_Aggregate;
  /** fetch data from the table: "messages" using primary key columns */
  readonly messages_by_pk?: Maybe<Messages>;
  /** fetch data from the table in a streaming manner: "messages" */
  readonly messages_stream: ReadonlyArray<Messages>;
  /** fetch data from the table: "minecraft_announcement" */
  readonly minecraft_announcement: ReadonlyArray<Minecraft_Announcement>;
  /** fetch aggregated fields from the table: "minecraft_announcement" */
  readonly minecraft_announcement_aggregate: Minecraft_Announcement_Aggregate;
  /** fetch data from the table: "minecraft_announcement" using primary key columns */
  readonly minecraft_announcement_by_pk?: Maybe<Minecraft_Announcement>;
  /** fetch data from the table in a streaming manner: "minecraft_announcement" */
  readonly minecraft_announcement_stream: ReadonlyArray<Minecraft_Announcement>;
  /** fetch data from the table: "minecraft_servers" */
  readonly minecraft_servers: ReadonlyArray<Minecraft_Servers>;
  /** fetch aggregated fields from the table: "minecraft_servers" */
  readonly minecraft_servers_aggregate: Minecraft_Servers_Aggregate;
  /** fetch data from the table: "minecraft_servers" using primary key columns */
  readonly minecraft_servers_by_pk?: Maybe<Minecraft_Servers>;
  /** fetch data from the table in a streaming manner: "minecraft_servers" */
  readonly minecraft_servers_stream: ReadonlyArray<Minecraft_Servers>;
  /** fetch data from the table: "minecraft_skins" */
  readonly minecraft_skins: ReadonlyArray<Minecraft_Skins>;
  /** fetch aggregated fields from the table: "minecraft_skins" */
  readonly minecraft_skins_aggregate: Minecraft_Skins_Aggregate;
  /** fetch data from the table: "minecraft_skins" using primary key columns */
  readonly minecraft_skins_by_pk?: Maybe<Minecraft_Skins>;
  /** fetch data from the table in a streaming manner: "minecraft_skins" */
  readonly minecraft_skins_stream: ReadonlyArray<Minecraft_Skins>;
  /** fetch data from the table: "minecraft_tutorial" */
  readonly minecraft_tutorial: ReadonlyArray<Minecraft_Tutorial>;
  /** fetch aggregated fields from the table: "minecraft_tutorial" */
  readonly minecraft_tutorial_aggregate: Minecraft_Tutorial_Aggregate;
  /** fetch data from the table: "minecraft_tutorial" using primary key columns */
  readonly minecraft_tutorial_by_pk?: Maybe<Minecraft_Tutorial>;
  /** fetch data from the table: "minecraft_tutorial_status" */
  readonly minecraft_tutorial_status: ReadonlyArray<Minecraft_Tutorial_Status>;
  /** fetch aggregated fields from the table: "minecraft_tutorial_status" */
  readonly minecraft_tutorial_status_aggregate: Minecraft_Tutorial_Status_Aggregate;
  /** fetch data from the table: "minecraft_tutorial_status" using primary key columns */
  readonly minecraft_tutorial_status_by_pk?: Maybe<Minecraft_Tutorial_Status>;
  /** fetch data from the table in a streaming manner: "minecraft_tutorial_status" */
  readonly minecraft_tutorial_status_stream: ReadonlyArray<Minecraft_Tutorial_Status>;
  /** fetch data from the table in a streaming manner: "minecraft_tutorial" */
  readonly minecraft_tutorial_stream: ReadonlyArray<Minecraft_Tutorial>;
  /** fetch data from the table: "minecraft_user" */
  readonly minecraft_user: ReadonlyArray<Minecraft_User>;
  /** fetch aggregated fields from the table: "minecraft_user" */
  readonly minecraft_user_aggregate: Minecraft_User_Aggregate;
  /** fetch data from the table in a streaming manner: "minecraft_user" */
  readonly minecraft_user_stream: ReadonlyArray<Minecraft_User>;
  /** An array relationship */
  readonly notifications: ReadonlyArray<Notifications>;
  /** An aggregate relationship */
  readonly notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  readonly notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table in a streaming manner: "notifications" */
  readonly notifications_stream: ReadonlyArray<Notifications>;
  /** An array relationship */
  readonly orders: ReadonlyArray<Orders>;
  /** An aggregate relationship */
  readonly orders_aggregate: Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  readonly orders_by_pk?: Maybe<Orders>;
  /** fetch data from the table in a streaming manner: "orders" */
  readonly orders_stream: ReadonlyArray<Orders>;
  /** fetch data from the table: "otps" */
  readonly otps: ReadonlyArray<Otps>;
  /** fetch aggregated fields from the table: "otps" */
  readonly otps_aggregate: Otps_Aggregate;
  /** fetch data from the table: "otps" using primary key columns */
  readonly otps_by_pk?: Maybe<Otps>;
  /** fetch data from the table in a streaming manner: "otps" */
  readonly otps_stream: ReadonlyArray<Otps>;
  /** fetch data from the table: "pages" */
  readonly pages: ReadonlyArray<Pages>;
  /** fetch aggregated fields from the table: "pages" */
  readonly pages_aggregate: Pages_Aggregate;
  /** fetch data from the table: "pages" using primary key columns */
  readonly pages_by_pk?: Maybe<Pages>;
  /** fetch data from the table in a streaming manner: "pages" */
  readonly pages_stream: ReadonlyArray<Pages>;
  /** fetch data from the table: "player_worlds" */
  readonly player_worlds: ReadonlyArray<Player_Worlds>;
  /** fetch aggregated fields from the table: "player_worlds" */
  readonly player_worlds_aggregate: Player_Worlds_Aggregate;
  /** fetch data from the table: "player_worlds" using primary key columns */
  readonly player_worlds_by_pk?: Maybe<Player_Worlds>;
  /** fetch data from the table in a streaming manner: "player_worlds" */
  readonly player_worlds_stream: ReadonlyArray<Player_Worlds>;
  /** An array relationship */
  readonly posts: ReadonlyArray<Posts>;
  /** An aggregate relationship */
  readonly posts_aggregate: Posts_Aggregate;
  /** fetch data from the table: "posts" using primary key columns */
  readonly posts_by_pk?: Maybe<Posts>;
  /** fetch data from the table in a streaming manner: "posts" */
  readonly posts_stream: ReadonlyArray<Posts>;
  /** An array relationship */
  readonly projects: ReadonlyArray<Projects>;
  /** An aggregate relationship */
  readonly projects_aggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  readonly projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table: "projects_collaborators" */
  readonly projects_collaborators: ReadonlyArray<Projects_Collaborators>;
  /** fetch aggregated fields from the table: "projects_collaborators" */
  readonly projects_collaborators_aggregate: Projects_Collaborators_Aggregate;
  /** fetch data from the table: "projects_collaborators" using primary key columns */
  readonly projects_collaborators_by_pk?: Maybe<Projects_Collaborators>;
  /** fetch data from the table in a streaming manner: "projects_collaborators" */
  readonly projects_collaborators_stream: ReadonlyArray<Projects_Collaborators>;
  /** fetch data from the table: "projects_editables" */
  readonly projects_editables: ReadonlyArray<Projects_Editables>;
  /** fetch aggregated fields from the table: "projects_editables" */
  readonly projects_editables_aggregate: Projects_Editables_Aggregate;
  /** fetch data from the table in a streaming manner: "projects_editables" */
  readonly projects_editables_stream: ReadonlyArray<Projects_Editables>;
  /** fetch data from the table: "projects_leaderboards" */
  readonly projects_leaderboards: ReadonlyArray<Projects_Leaderboards>;
  /** fetch aggregated fields from the table: "projects_leaderboards" */
  readonly projects_leaderboards_aggregate: Projects_Leaderboards_Aggregate;
  /** fetch data from the table: "projects_leaderboards" using primary key columns */
  readonly projects_leaderboards_by_pk?: Maybe<Projects_Leaderboards>;
  /** fetch data from the table in a streaming manner: "projects_leaderboards" */
  readonly projects_leaderboards_stream: ReadonlyArray<Projects_Leaderboards>;
  /** An array relationship */
  readonly projects_publications: ReadonlyArray<Projects_Publications>;
  /** An aggregate relationship */
  readonly projects_publications_aggregate: Projects_Publications_Aggregate;
  /** fetch data from the table: "projects_publications" using primary key columns */
  readonly projects_publications_by_pk?: Maybe<Projects_Publications>;
  /** fetch data from the table in a streaming manner: "projects_publications" */
  readonly projects_publications_stream: ReadonlyArray<Projects_Publications>;
  /** fetch data from the table: "projects_publications_votes" */
  readonly projects_publications_votes: ReadonlyArray<Projects_Publications_Votes>;
  /** fetch aggregated fields from the table: "projects_publications_votes" */
  readonly projects_publications_votes_aggregate: Projects_Publications_Votes_Aggregate;
  /** fetch data from the table: "projects_publications_votes" using primary key columns */
  readonly projects_publications_votes_by_pk?: Maybe<Projects_Publications_Votes>;
  /** fetch data from the table in a streaming manner: "projects_publications_votes" */
  readonly projects_publications_votes_stream: ReadonlyArray<Projects_Publications_Votes>;
  /** fetch data from the table in a streaming manner: "projects" */
  readonly projects_stream: ReadonlyArray<Projects>;
  /** fetch data from the table: "projects_templates" */
  readonly projects_templates: ReadonlyArray<Projects_Templates>;
  /** fetch aggregated fields from the table: "projects_templates" */
  readonly projects_templates_aggregate: Projects_Templates_Aggregate;
  /** fetch data from the table: "projects_templates" using primary key columns */
  readonly projects_templates_by_pk?: Maybe<Projects_Templates>;
  /** fetch data from the table in a streaming manner: "projects_templates" */
  readonly projects_templates_stream: ReadonlyArray<Projects_Templates>;
  /** fetch data from the table: "prospective_users" */
  readonly prospective_users: ReadonlyArray<Prospective_Users>;
  /** fetch aggregated fields from the table: "prospective_users" */
  readonly prospective_users_aggregate: Prospective_Users_Aggregate;
  /** fetch data from the table: "prospective_users" using primary key columns */
  readonly prospective_users_by_pk?: Maybe<Prospective_Users>;
  /** An array relationship */
  readonly prospective_users_purchases: ReadonlyArray<Prospective_Users_Purchases>;
  /** An aggregate relationship */
  readonly prospective_users_purchases_aggregate: Prospective_Users_Purchases_Aggregate;
  /** fetch data from the table: "prospective_users_purchases" using primary key columns */
  readonly prospective_users_purchases_by_pk?: Maybe<Prospective_Users_Purchases>;
  /** fetch data from the table in a streaming manner: "prospective_users_purchases" */
  readonly prospective_users_purchases_stream: ReadonlyArray<Prospective_Users_Purchases>;
  /** fetch data from the table in a streaming manner: "prospective_users" */
  readonly prospective_users_stream: ReadonlyArray<Prospective_Users>;
  /** An array relationship */
  readonly quests: ReadonlyArray<Quests>;
  /** An aggregate relationship */
  readonly quests_aggregate: Quests_Aggregate;
  /** fetch data from the table: "quests" using primary key columns */
  readonly quests_by_pk?: Maybe<Quests>;
  /** fetch data from the table in a streaming manner: "quests" */
  readonly quests_stream: ReadonlyArray<Quests>;
  /** fetch data from the table: "queue_user_info" */
  readonly queue_user_info: ReadonlyArray<Queue_User_Info>;
  /** fetch aggregated fields from the table: "queue_user_info" */
  readonly queue_user_info_aggregate: Queue_User_Info_Aggregate;
  /** fetch data from the table: "queue_user_info" using primary key columns */
  readonly queue_user_info_by_pk?: Maybe<Queue_User_Info>;
  /** fetch data from the table in a streaming manner: "queue_user_info" */
  readonly queue_user_info_stream: ReadonlyArray<Queue_User_Info>;
  /** fetch data from the table: "queues" */
  readonly queues: ReadonlyArray<Queues>;
  /** fetch aggregated fields from the table: "queues" */
  readonly queues_aggregate: Queues_Aggregate;
  /** fetch data from the table: "queues" using primary key columns */
  readonly queues_by_pk?: Maybe<Queues>;
  /** fetch data from the table in a streaming manner: "queues" */
  readonly queues_stream: ReadonlyArray<Queues>;
  /** fetch data from the table: "render_jobs" */
  readonly render_jobs: ReadonlyArray<Render_Jobs>;
  /** fetch aggregated fields from the table: "render_jobs" */
  readonly render_jobs_aggregate: Render_Jobs_Aggregate;
  /** fetch data from the table: "render_jobs" using primary key columns */
  readonly render_jobs_by_pk?: Maybe<Render_Jobs>;
  /** fetch data from the table in a streaming manner: "render_jobs" */
  readonly render_jobs_stream: ReadonlyArray<Render_Jobs>;
  /** fetch data from the table: "reports" */
  readonly reports: ReadonlyArray<Reports>;
  /** fetch aggregated fields from the table: "reports" */
  readonly reports_aggregate: Reports_Aggregate;
  /** fetch data from the table: "reports" using primary key columns */
  readonly reports_by_pk?: Maybe<Reports>;
  /** fetch data from the table in a streaming manner: "reports" */
  readonly reports_stream: ReadonlyArray<Reports>;
  /** An array relationship */
  readonly rooms: ReadonlyArray<Rooms>;
  /** An aggregate relationship */
  readonly rooms_aggregate: Rooms_Aggregate;
  /** fetch data from the table: "rooms" using primary key columns */
  readonly rooms_by_pk?: Maybe<Rooms>;
  /** fetch data from the table in a streaming manner: "rooms" */
  readonly rooms_stream: ReadonlyArray<Rooms>;
  /** fetch data from the table: "schedules" */
  readonly schedules: ReadonlyArray<Schedules>;
  /** fetch aggregated fields from the table: "schedules" */
  readonly schedules_aggregate: Schedules_Aggregate;
  /** fetch data from the table: "schedules" using primary key columns */
  readonly schedules_by_pk?: Maybe<Schedules>;
  /** fetch data from the table in a streaming manner: "schedules" */
  readonly schedules_stream: ReadonlyArray<Schedules>;
  /** fetch data from the table: "shared_cached_queries" */
  readonly shared_cached_queries: ReadonlyArray<Shared_Cached_Queries>;
  /** fetch aggregated fields from the table: "shared_cached_queries" */
  readonly shared_cached_queries_aggregate: Shared_Cached_Queries_Aggregate;
  /** fetch data from the table: "shared_cached_queries" using primary key columns */
  readonly shared_cached_queries_by_pk?: Maybe<Shared_Cached_Queries>;
  /** fetch data from the table in a streaming manner: "shared_cached_queries" */
  readonly shared_cached_queries_stream: ReadonlyArray<Shared_Cached_Queries>;
  /** fetch data from the table: "short_urls" */
  readonly short_urls: ReadonlyArray<Short_Urls>;
  /** fetch aggregated fields from the table: "short_urls" */
  readonly short_urls_aggregate: Short_Urls_Aggregate;
  /** fetch data from the table: "short_urls" using primary key columns */
  readonly short_urls_by_pk?: Maybe<Short_Urls>;
  /** fetch data from the table in a streaming manner: "short_urls" */
  readonly short_urls_stream: ReadonlyArray<Short_Urls>;
  /** An array relationship */
  readonly subscriptions: ReadonlyArray<Subscriptions>;
  /** An aggregate relationship */
  readonly subscriptions_aggregate: Subscriptions_Aggregate;
  /** fetch data from the table: "subscriptions" using primary key columns */
  readonly subscriptions_by_pk?: Maybe<Subscriptions>;
  /** fetch data from the table in a streaming manner: "subscriptions" */
  readonly subscriptions_stream: ReadonlyArray<Subscriptions>;
  /** fetch data from the table: "tags" */
  readonly tags: ReadonlyArray<Tags>;
  /** fetch aggregated fields from the table: "tags" */
  readonly tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "tags_associations" */
  readonly tags_associations: ReadonlyArray<Tags_Associations>;
  /** fetch aggregated fields from the table: "tags_associations" */
  readonly tags_associations_aggregate: Tags_Associations_Aggregate;
  /** fetch data from the table: "tags_associations" using primary key columns */
  readonly tags_associations_by_pk?: Maybe<Tags_Associations>;
  /** fetch data from the table in a streaming manner: "tags_associations" */
  readonly tags_associations_stream: ReadonlyArray<Tags_Associations>;
  /** fetch data from the table: "tags" using primary key columns */
  readonly tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table in a streaming manner: "tags" */
  readonly tags_stream: ReadonlyArray<Tags>;
  /** An array relationship */
  readonly themes: ReadonlyArray<Themes>;
  /** An aggregate relationship */
  readonly themes_aggregate: Themes_Aggregate;
  /** fetch data from the table: "themes" using primary key columns */
  readonly themes_by_pk?: Maybe<Themes>;
  /** fetch data from the table in a streaming manner: "themes" */
  readonly themes_stream: ReadonlyArray<Themes>;
  /** fetch data from the table: "tools" */
  readonly tools: ReadonlyArray<Tools>;
  /** fetch aggregated fields from the table: "tools" */
  readonly tools_aggregate: Tools_Aggregate;
  /** fetch data from the table: "tools" using primary key columns */
  readonly tools_by_pk?: Maybe<Tools>;
  /** fetch data from the table in a streaming manner: "tools" */
  readonly tools_stream: ReadonlyArray<Tools>;
  /** fetch data from the table: "topics" */
  readonly topics: ReadonlyArray<Topics>;
  /** fetch aggregated fields from the table: "topics" */
  readonly topics_aggregate: Topics_Aggregate;
  /** fetch data from the table: "topics" using primary key columns */
  readonly topics_by_pk?: Maybe<Topics>;
  /** fetch data from the table in a streaming manner: "topics" */
  readonly topics_stream: ReadonlyArray<Topics>;
  /** fetch data from the table: "topics_tools" */
  readonly topics_tools: ReadonlyArray<Topics_Tools>;
  /** fetch aggregated fields from the table: "topics_tools" */
  readonly topics_tools_aggregate: Topics_Tools_Aggregate;
  /** fetch data from the table: "topics_tools" using primary key columns */
  readonly topics_tools_by_pk?: Maybe<Topics_Tools>;
  /** fetch data from the table in a streaming manner: "topics_tools" */
  readonly topics_tools_stream: ReadonlyArray<Topics_Tools>;
  /** An array relationship */
  readonly transactions: ReadonlyArray<Transactions>;
  /** An aggregate relationship */
  readonly transactions_aggregate: Transactions_Aggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  readonly transactions_by_pk?: Maybe<Transactions>;
  /** fetch data from the table in a streaming manner: "transactions" */
  readonly transactions_stream: ReadonlyArray<Transactions>;
  /** An array relationship */
  readonly tutorials: ReadonlyArray<Tutorials>;
  /** An aggregate relationship */
  readonly tutorials_aggregate: Tutorials_Aggregate;
  /** fetch data from the table: "tutorials" using primary key columns */
  readonly tutorials_by_pk?: Maybe<Tutorials>;
  /** fetch data from the table in a streaming manner: "tutorials" */
  readonly tutorials_stream: ReadonlyArray<Tutorials>;
  /** fetch data from the table: "tutorials_tags" */
  readonly tutorials_tags: ReadonlyArray<Tutorials_Tags>;
  /** fetch aggregated fields from the table: "tutorials_tags" */
  readonly tutorials_tags_aggregate: Tutorials_Tags_Aggregate;
  /** fetch data from the table: "tutorials_tags" using primary key columns */
  readonly tutorials_tags_by_pk?: Maybe<Tutorials_Tags>;
  /** fetch data from the table in a streaming manner: "tutorials_tags" */
  readonly tutorials_tags_stream: ReadonlyArray<Tutorials_Tags>;
  /** An array relationship */
  readonly user_collected_avatars: ReadonlyArray<User_Collected_Avatars>;
  /** An aggregate relationship */
  readonly user_collected_avatars_aggregate: User_Collected_Avatars_Aggregate;
  /** fetch data from the table: "user_collected_avatars" using primary key columns */
  readonly user_collected_avatars_by_pk?: Maybe<User_Collected_Avatars>;
  /** fetch data from the table in a streaming manner: "user_collected_avatars" */
  readonly user_collected_avatars_stream: ReadonlyArray<User_Collected_Avatars>;
  /** An array relationship */
  readonly user_invites: ReadonlyArray<User_Invites>;
  /** An aggregate relationship */
  readonly user_invites_aggregate: User_Invites_Aggregate;
  /** fetch data from the table: "user_invites" using primary key columns */
  readonly user_invites_by_pk?: Maybe<User_Invites>;
  /** fetch data from the table in a streaming manner: "user_invites" */
  readonly user_invites_stream: ReadonlyArray<User_Invites>;
  /** fetch data from the table: "user_presence" */
  readonly user_presence: ReadonlyArray<User_Presence>;
  /** fetch aggregated fields from the table: "user_presence" */
  readonly user_presence_aggregate: User_Presence_Aggregate;
  /** fetch data from the table: "user_presence" using primary key columns */
  readonly user_presence_by_pk?: Maybe<User_Presence>;
  /** fetch data from the table in a streaming manner: "user_presence" */
  readonly user_presence_stream: ReadonlyArray<User_Presence>;
  /** fetch data from the table: "users" */
  readonly users: ReadonlyArray<Users>;
  /** fetch data from the table: "users_actions" */
  readonly users_actions: ReadonlyArray<Users_Actions>;
  /** fetch aggregated fields from the table: "users_actions" */
  readonly users_actions_aggregate: Users_Actions_Aggregate;
  /** fetch data from the table: "users_actions" using primary key columns */
  readonly users_actions_by_pk?: Maybe<Users_Actions>;
  /** fetch data from the table in a streaming manner: "users_actions" */
  readonly users_actions_stream: ReadonlyArray<Users_Actions>;
  /** fetch aggregated fields from the table: "users" */
  readonly users_aggregate: Users_Aggregate;
  /** An array relationship */
  readonly users_badges: ReadonlyArray<Users_Badges>;
  /** An aggregate relationship */
  readonly users_badges_aggregate: Users_Badges_Aggregate;
  /** fetch data from the table: "users_badges" using primary key columns */
  readonly users_badges_by_pk?: Maybe<Users_Badges>;
  /** fetch data from the table in a streaming manner: "users_badges" */
  readonly users_badges_stream: ReadonlyArray<Users_Badges>;
  /** fetch data from the table: "users" using primary key columns */
  readonly users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "users_cached_queries" */
  readonly users_cached_queries: ReadonlyArray<Users_Cached_Queries>;
  /** fetch aggregated fields from the table: "users_cached_queries" */
  readonly users_cached_queries_aggregate: Users_Cached_Queries_Aggregate;
  /** fetch data from the table: "users_cached_queries" using primary key columns */
  readonly users_cached_queries_by_pk?: Maybe<Users_Cached_Queries>;
  /** fetch data from the table in a streaming manner: "users_cached_queries" */
  readonly users_cached_queries_stream: ReadonlyArray<Users_Cached_Queries>;
  /** fetch data from the table: "users_features" */
  readonly users_features: ReadonlyArray<Users_Features>;
  /** fetch aggregated fields from the table: "users_features" */
  readonly users_features_aggregate: Users_Features_Aggregate;
  /** fetch data from the table: "users_features" using primary key columns */
  readonly users_features_by_pk?: Maybe<Users_Features>;
  /** fetch data from the table in a streaming manner: "users_features" */
  readonly users_features_stream: ReadonlyArray<Users_Features>;
  /** An array relationship */
  readonly users_levels: ReadonlyArray<Users_Levels>;
  /** An aggregate relationship */
  readonly users_levels_aggregate: Users_Levels_Aggregate;
  /** fetch data from the table: "users_levels" using primary key columns */
  readonly users_levels_by_pk?: Maybe<Users_Levels>;
  /** fetch data from the table in a streaming manner: "users_levels" */
  readonly users_levels_stream: ReadonlyArray<Users_Levels>;
  /** An array relationship */
  readonly users_notifications: ReadonlyArray<Users_Notifications>;
  /** An aggregate relationship */
  readonly users_notifications_aggregate: Users_Notifications_Aggregate;
  /** fetch data from the table: "users_notifications" using primary key columns */
  readonly users_notifications_by_pk?: Maybe<Users_Notifications>;
  /** fetch data from the table in a streaming manner: "users_notifications" */
  readonly users_notifications_stream: ReadonlyArray<Users_Notifications>;
  /** fetch data from the table: "users_presence" */
  readonly users_presence: ReadonlyArray<Users_Presence>;
  /** fetch aggregated fields from the table: "users_presence" */
  readonly users_presence_aggregate: Users_Presence_Aggregate;
  /** fetch data from the table in a streaming manner: "users_presence" */
  readonly users_presence_stream: ReadonlyArray<Users_Presence>;
  /** fetch data from the table: "users_progression" */
  readonly users_progression: ReadonlyArray<Users_Progression>;
  /** fetch aggregated fields from the table: "users_progression" */
  readonly users_progression_aggregate: Users_Progression_Aggregate;
  /** fetch data from the table: "users_progression" using primary key columns */
  readonly users_progression_by_pk?: Maybe<Users_Progression>;
  /** fetch data from the table in a streaming manner: "users_progression" */
  readonly users_progression_stream: ReadonlyArray<Users_Progression>;
  /** An array relationship */
  readonly users_quests: ReadonlyArray<Users_Quests>;
  /** An aggregate relationship */
  readonly users_quests_aggregate: Users_Quests_Aggregate;
  /** fetch data from the table: "users_quests" using primary key columns */
  readonly users_quests_by_pk?: Maybe<Users_Quests>;
  /** fetch data from the table in a streaming manner: "users_quests" */
  readonly users_quests_stream: ReadonlyArray<Users_Quests>;
  /** fetch data from the table: "users_stale_web_presence" */
  readonly users_stale_web_presence: ReadonlyArray<Users_Stale_Web_Presence>;
  /** fetch aggregated fields from the table: "users_stale_web_presence" */
  readonly users_stale_web_presence_aggregate: Users_Stale_Web_Presence_Aggregate;
  /** fetch data from the table in a streaming manner: "users_stale_web_presence" */
  readonly users_stale_web_presence_stream: ReadonlyArray<Users_Stale_Web_Presence>;
  /** fetch data from the table: "users_stats" */
  readonly users_stats: ReadonlyArray<Users_Stats>;
  /** fetch aggregated fields from the table: "users_stats" */
  readonly users_stats_aggregate: Users_Stats_Aggregate;
  /** fetch data from the table: "users_stats" using primary key columns */
  readonly users_stats_by_pk?: Maybe<Users_Stats>;
  /** fetch data from the table in a streaming manner: "users_stats" */
  readonly users_stats_stream: ReadonlyArray<Users_Stats>;
  /** fetch data from the table in a streaming manner: "users" */
  readonly users_stream: ReadonlyArray<Users>;
  /** An array relationship */
  readonly votes: ReadonlyArray<Votes>;
  /** An aggregate relationship */
  readonly votes_aggregate: Votes_Aggregate;
  /** fetch data from the table: "votes" using primary key columns */
  readonly votes_by_pk?: Maybe<Votes>;
  /** fetch data from the table in a streaming manner: "votes" */
  readonly votes_stream: ReadonlyArray<Votes>;
};


export type Subscription_RootAccountsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Subscription_RootAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Subscription_RootAccounts_By_PkArgs = {
  club_id: Scalars['uuid']['input'];
  id: Scalars['String']['input'];
};


export type Subscription_RootAccounts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Accounts_Stream_Cursor_Input>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Subscription_RootAsa_User_Quest_TaskArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Asa_User_Quest_Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Asa_User_Quest_Task_Order_By>>;
  where?: InputMaybe<Asa_User_Quest_Task_Bool_Exp>;
};


export type Subscription_RootAsa_User_Quest_Task_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Asa_User_Quest_Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Asa_User_Quest_Task_Order_By>>;
  where?: InputMaybe<Asa_User_Quest_Task_Bool_Exp>;
};


export type Subscription_RootAsa_User_Quest_Task_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootAsa_User_Quest_Task_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Asa_User_Quest_Task_Stream_Cursor_Input>>;
  where?: InputMaybe<Asa_User_Quest_Task_Bool_Exp>;
};


export type Subscription_RootAvatarsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Avatars_Order_By>>;
  where?: InputMaybe<Avatars_Bool_Exp>;
};


export type Subscription_RootAvatars_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Avatars_Order_By>>;
  where?: InputMaybe<Avatars_Bool_Exp>;
};


export type Subscription_RootAvatars_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootAvatars_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Avatars_Stream_Cursor_Input>>;
  where?: InputMaybe<Avatars_Bool_Exp>;
};


export type Subscription_RootBadgesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Subscription_RootBadges_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Badges_Order_By>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Subscription_RootBadges_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootBadges_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Badges_Stream_Cursor_Input>>;
  where?: InputMaybe<Badges_Bool_Exp>;
};


export type Subscription_RootBookingsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Bookings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Bookings_Order_By>>;
  where?: InputMaybe<Bookings_Bool_Exp>;
};


export type Subscription_RootBookings_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Bookings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Bookings_Order_By>>;
  where?: InputMaybe<Bookings_Bool_Exp>;
};


export type Subscription_RootBookings_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootBookings_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Bookings_Stream_Cursor_Input>>;
  where?: InputMaybe<Bookings_Bool_Exp>;
};


export type Subscription_RootBreakoutsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Breakouts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Breakouts_Order_By>>;
  where?: InputMaybe<Breakouts_Bool_Exp>;
};


export type Subscription_RootBreakouts_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Breakouts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Breakouts_Order_By>>;
  where?: InputMaybe<Breakouts_Bool_Exp>;
};


export type Subscription_RootBreakouts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootBreakouts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Breakouts_Stream_Cursor_Input>>;
  where?: InputMaybe<Breakouts_Bool_Exp>;
};


export type Subscription_RootBuild_BattlesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Build_Battles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Build_Battles_Order_By>>;
  where?: InputMaybe<Build_Battles_Bool_Exp>;
};


export type Subscription_RootBuild_Battles_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Build_Battles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Build_Battles_Order_By>>;
  where?: InputMaybe<Build_Battles_Bool_Exp>;
};


export type Subscription_RootBuild_Battles_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootBuild_Battles_ProjectsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Build_Battles_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Build_Battles_Projects_Order_By>>;
  where?: InputMaybe<Build_Battles_Projects_Bool_Exp>;
};


export type Subscription_RootBuild_Battles_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Build_Battles_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Build_Battles_Projects_Order_By>>;
  where?: InputMaybe<Build_Battles_Projects_Bool_Exp>;
};


export type Subscription_RootBuild_Battles_Projects_By_PkArgs = {
  build_battle_id: Scalars['uuid']['input'];
  project_id: Scalars['String']['input'];
};


export type Subscription_RootBuild_Battles_Projects_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Build_Battles_Projects_Stream_Cursor_Input>>;
  where?: InputMaybe<Build_Battles_Projects_Bool_Exp>;
};


export type Subscription_RootBuild_Battles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Build_Battles_Stream_Cursor_Input>>;
  where?: InputMaybe<Build_Battles_Bool_Exp>;
};


export type Subscription_RootBuild_Battles_ThemesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Build_Battles_Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Build_Battles_Themes_Order_By>>;
  where?: InputMaybe<Build_Battles_Themes_Bool_Exp>;
};


export type Subscription_RootBuild_Battles_Themes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Build_Battles_Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Build_Battles_Themes_Order_By>>;
  where?: InputMaybe<Build_Battles_Themes_Bool_Exp>;
};


export type Subscription_RootBuild_Battles_Themes_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootBuild_Battles_Themes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Build_Battles_Themes_Stream_Cursor_Input>>;
  where?: InputMaybe<Build_Battles_Themes_Bool_Exp>;
};


export type Subscription_RootChannelsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Subscription_RootChannels_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Subscription_RootChannels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootChannels_EventsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Events_Order_By>>;
  where?: InputMaybe<Channels_Events_Bool_Exp>;
};


export type Subscription_RootChannels_Events_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Events_Order_By>>;
  where?: InputMaybe<Channels_Events_Bool_Exp>;
};


export type Subscription_RootChannels_Events_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootChannels_Events_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Channels_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Channels_Events_Bool_Exp>;
};


export type Subscription_RootChannels_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Channels_Stream_Cursor_Input>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Subscription_RootChannels_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Users_Order_By>>;
  where?: InputMaybe<Channels_Users_Bool_Exp>;
};


export type Subscription_RootChannels_Users_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Users_Order_By>>;
  where?: InputMaybe<Channels_Users_Bool_Exp>;
};


export type Subscription_RootChannels_Users_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootChannels_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Channels_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Channels_Users_Bool_Exp>;
};


export type Subscription_RootChatsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Chats_Order_By>>;
  where?: InputMaybe<Chats_Bool_Exp>;
};


export type Subscription_RootChats_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Chats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Chats_Order_By>>;
  where?: InputMaybe<Chats_Bool_Exp>;
};


export type Subscription_RootChats_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootChats_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Chats_Stream_Cursor_Input>>;
  where?: InputMaybe<Chats_Bool_Exp>;
};


export type Subscription_RootClubsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Order_By>>;
  where?: InputMaybe<Clubs_Bool_Exp>;
};


export type Subscription_RootClubs_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Order_By>>;
  where?: InputMaybe<Clubs_Bool_Exp>;
};


export type Subscription_RootClubs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootClubs_MembersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Members_Order_By>>;
  where?: InputMaybe<Clubs_Members_Bool_Exp>;
};


export type Subscription_RootClubs_Members_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Members_Order_By>>;
  where?: InputMaybe<Clubs_Members_Bool_Exp>;
};


export type Subscription_RootClubs_Members_By_PkArgs = {
  club_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


export type Subscription_RootClubs_Members_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Clubs_Members_Stream_Cursor_Input>>;
  where?: InputMaybe<Clubs_Members_Bool_Exp>;
};


export type Subscription_RootClubs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Clubs_Stream_Cursor_Input>>;
  where?: InputMaybe<Clubs_Bool_Exp>;
};


export type Subscription_RootCommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


export type Subscription_RootComments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


export type Subscription_RootComments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootComments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Comments_Stream_Cursor_Input>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


export type Subscription_RootComments_VotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Votes_Order_By>>;
  where?: InputMaybe<Comments_Votes_Bool_Exp>;
};


export type Subscription_RootComments_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Votes_Order_By>>;
  where?: InputMaybe<Comments_Votes_Bool_Exp>;
};


export type Subscription_RootComments_Votes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootComments_Votes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Comments_Votes_Stream_Cursor_Input>>;
  where?: InputMaybe<Comments_Votes_Bool_Exp>;
};


export type Subscription_RootCompetitionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


export type Subscription_RootCompetitions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


export type Subscription_RootCompetitions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootCompetitions_JudgesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Judges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Judges_Order_By>>;
  where?: InputMaybe<Competitions_Judges_Bool_Exp>;
};


export type Subscription_RootCompetitions_Judges_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Judges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Judges_Order_By>>;
  where?: InputMaybe<Competitions_Judges_Bool_Exp>;
};


export type Subscription_RootCompetitions_Judges_By_PkArgs = {
  competition_id: Scalars['String']['input'];
  judge_id: Scalars['String']['input'];
};


export type Subscription_RootCompetitions_Judges_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Competitions_Judges_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Judges_Bool_Exp>;
};


export type Subscription_RootCompetitions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Competitions_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


export type Subscription_RootCompetitions_TagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tags_Order_By>>;
  where?: InputMaybe<Competitions_Tags_Bool_Exp>;
};


export type Subscription_RootCompetitions_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tags_Order_By>>;
  where?: InputMaybe<Competitions_Tags_Bool_Exp>;
};


export type Subscription_RootCompetitions_Tags_By_PkArgs = {
  competition_id: Scalars['String']['input'];
  tag_id: Scalars['String']['input'];
};


export type Subscription_RootCompetitions_Tags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Competitions_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Tags_Bool_Exp>;
};


export type Subscription_RootCompetitions_TutorialsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Order_By>>;
  where?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
};


export type Subscription_RootCompetitions_Tutorials_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Order_By>>;
  where?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
};


export type Subscription_RootCompetitions_Tutorials_By_PkArgs = {
  competition_id: Scalars['String']['input'];
  tutorial_id: Scalars['String']['input'];
};


export type Subscription_RootCompetitions_Tutorials_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Competitions_Tutorials_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
};


export type Subscription_RootEban_CommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Eban_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Eban_Comments_Order_By>>;
  where?: InputMaybe<Eban_Comments_Bool_Exp>;
};


export type Subscription_RootEban_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Eban_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Eban_Comments_Order_By>>;
  where?: InputMaybe<Eban_Comments_Bool_Exp>;
};


export type Subscription_RootEban_Comments_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootEban_Comments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Eban_Comments_Stream_Cursor_Input>>;
  where?: InputMaybe<Eban_Comments_Bool_Exp>;
};


export type Subscription_RootEban_TypesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Eban_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Eban_Types_Order_By>>;
  where?: InputMaybe<Eban_Types_Bool_Exp>;
};


export type Subscription_RootEban_Types_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Eban_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Eban_Types_Order_By>>;
  where?: InputMaybe<Eban_Types_Bool_Exp>;
};


export type Subscription_RootEban_Types_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootEban_Types_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Eban_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Eban_Types_Bool_Exp>;
};


export type Subscription_RootEbansArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Ebans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Ebans_Order_By>>;
  where?: InputMaybe<Ebans_Bool_Exp>;
};


export type Subscription_RootEbans_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Ebans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Ebans_Order_By>>;
  where?: InputMaybe<Ebans_Bool_Exp>;
};


export type Subscription_RootEbans_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootEbans_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Ebans_Stream_Cursor_Input>>;
  where?: InputMaybe<Ebans_Bool_Exp>;
};


export type Subscription_RootEnvironmentArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Environment_Order_By>>;
  where?: InputMaybe<Environment_Bool_Exp>;
};


export type Subscription_RootEnvironment_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Environment_Order_By>>;
  where?: InputMaybe<Environment_Bool_Exp>;
};


export type Subscription_RootEnvironment_By_PkArgs = {
  key: Scalars['String']['input'];
};


export type Subscription_RootEnvironment_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Environment_Stream_Cursor_Input>>;
  where?: InputMaybe<Environment_Bool_Exp>;
};


export type Subscription_RootExecute_Shared_Cached_QueryArgs = {
  args: Execute_Shared_Cached_Query_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Shared_Cached_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Shared_Cached_Queries_Order_By>>;
  where?: InputMaybe<Shared_Cached_Queries_Bool_Exp>;
};


export type Subscription_RootExecute_Shared_Cached_Query_AggregateArgs = {
  args: Execute_Shared_Cached_Query_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Shared_Cached_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Shared_Cached_Queries_Order_By>>;
  where?: InputMaybe<Shared_Cached_Queries_Bool_Exp>;
};


export type Subscription_RootExecute_Users_Cached_QueryArgs = {
  args: Execute_Users_Cached_Query_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Users_Cached_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Cached_Queries_Order_By>>;
  where?: InputMaybe<Users_Cached_Queries_Bool_Exp>;
};


export type Subscription_RootExecute_Users_Cached_Query_AggregateArgs = {
  args: Execute_Users_Cached_Query_Args;
  distinct_on?: InputMaybe<ReadonlyArray<Users_Cached_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Cached_Queries_Order_By>>;
  where?: InputMaybe<Users_Cached_Queries_Bool_Exp>;
};


export type Subscription_RootFeaturesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Features_Order_By>>;
  where?: InputMaybe<Features_Bool_Exp>;
};


export type Subscription_RootFeatures_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Features_Order_By>>;
  where?: InputMaybe<Features_Bool_Exp>;
};


export type Subscription_RootFeatures_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootFeatures_ProductsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Features_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Features_Products_Order_By>>;
  where?: InputMaybe<Features_Products_Bool_Exp>;
};


export type Subscription_RootFeatures_Products_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Features_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Features_Products_Order_By>>;
  where?: InputMaybe<Features_Products_Bool_Exp>;
};


export type Subscription_RootFeatures_Products_By_PkArgs = {
  feature_id: Scalars['uuid']['input'];
  product_id: Scalars['String']['input'];
};


export type Subscription_RootFeatures_Products_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Features_Products_Stream_Cursor_Input>>;
  where?: InputMaybe<Features_Products_Bool_Exp>;
};


export type Subscription_RootFeatures_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Features_Stream_Cursor_Input>>;
  where?: InputMaybe<Features_Bool_Exp>;
};


export type Subscription_RootFeedbackArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feedback_Order_By>>;
  where?: InputMaybe<Feedback_Bool_Exp>;
};


export type Subscription_RootFeedback_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feedback_Order_By>>;
  where?: InputMaybe<Feedback_Bool_Exp>;
};


export type Subscription_RootFeedback_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootFeedback_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Feedback_Stream_Cursor_Input>>;
  where?: InputMaybe<Feedback_Bool_Exp>;
};


export type Subscription_RootFeedsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


export type Subscription_RootFeeds_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


export type Subscription_RootFeeds_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootFeeds_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Feeds_Stream_Cursor_Input>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


export type Subscription_RootFollowsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


export type Subscription_RootFollows_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


export type Subscription_RootFollows_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootFollows_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Follows_Stream_Cursor_Input>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


export type Subscription_RootFriendsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friends_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friends_Order_By>>;
  where?: InputMaybe<Friends_Bool_Exp>;
};


export type Subscription_RootFriends_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friends_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friends_Order_By>>;
  where?: InputMaybe<Friends_Bool_Exp>;
};


export type Subscription_RootFriends_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootFriends_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Friends_Stream_Cursor_Input>>;
  where?: InputMaybe<Friends_Bool_Exp>;
};


export type Subscription_RootFriendshipsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friendships_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friendships_Order_By>>;
  where?: InputMaybe<Friendships_Bool_Exp>;
};


export type Subscription_RootFriendships_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friendships_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friendships_Order_By>>;
  where?: InputMaybe<Friendships_Bool_Exp>;
};


export type Subscription_RootFriendships_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootFriendships_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Friendships_Stream_Cursor_Input>>;
  where?: InputMaybe<Friendships_Bool_Exp>;
};


export type Subscription_RootFriendships_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friendships_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friendships_Users_Order_By>>;
  where?: InputMaybe<Friendships_Users_Bool_Exp>;
};


export type Subscription_RootFriendships_Users_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friendships_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friendships_Users_Order_By>>;
  where?: InputMaybe<Friendships_Users_Bool_Exp>;
};


export type Subscription_RootFriendships_Users_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootFriendships_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Friendships_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Friendships_Users_Bool_Exp>;
};


export type Subscription_RootGame_ServersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Game_Servers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Game_Servers_Order_By>>;
  where?: InputMaybe<Game_Servers_Bool_Exp>;
};


export type Subscription_RootGame_Servers_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Game_Servers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Game_Servers_Order_By>>;
  where?: InputMaybe<Game_Servers_Bool_Exp>;
};


export type Subscription_RootGame_Servers_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Subscription_RootGame_Servers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Game_Servers_Stream_Cursor_Input>>;
  where?: InputMaybe<Game_Servers_Bool_Exp>;
};


export type Subscription_RootHomesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Homes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Homes_Order_By>>;
  where?: InputMaybe<Homes_Bool_Exp>;
};


export type Subscription_RootHomes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Homes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Homes_Order_By>>;
  where?: InputMaybe<Homes_Bool_Exp>;
};


export type Subscription_RootHomes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootHomes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Homes_Stream_Cursor_Input>>;
  where?: InputMaybe<Homes_Bool_Exp>;
};


export type Subscription_RootImage_CacheArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Image_Cache_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Image_Cache_Order_By>>;
  where?: InputMaybe<Image_Cache_Bool_Exp>;
};


export type Subscription_RootImage_Cache_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Image_Cache_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Image_Cache_Order_By>>;
  where?: InputMaybe<Image_Cache_Bool_Exp>;
};


export type Subscription_RootImage_Cache_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootImage_Cache_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Image_Cache_Stream_Cursor_Input>>;
  where?: InputMaybe<Image_Cache_Bool_Exp>;
};


export type Subscription_RootInfrastructure_OperationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Infrastructure_Operations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Infrastructure_Operations_Order_By>>;
  where?: InputMaybe<Infrastructure_Operations_Bool_Exp>;
};


export type Subscription_RootInfrastructure_Operations_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Infrastructure_Operations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Infrastructure_Operations_Order_By>>;
  where?: InputMaybe<Infrastructure_Operations_Bool_Exp>;
};


export type Subscription_RootInfrastructure_Operations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Infrastructure_Operations_Stream_Cursor_Input>>;
  where?: InputMaybe<Infrastructure_Operations_Bool_Exp>;
};


export type Subscription_RootItemsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Subscription_RootItems_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Subscription_RootItems_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootItems_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Subscription_RootItems_TagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Items_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Items_Tags_Order_By>>;
  where?: InputMaybe<Items_Tags_Bool_Exp>;
};


export type Subscription_RootItems_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Items_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Items_Tags_Order_By>>;
  where?: InputMaybe<Items_Tags_Bool_Exp>;
};


export type Subscription_RootItems_Tags_By_PkArgs = {
  item_id: Scalars['String']['input'];
  tag_id: Scalars['String']['input'];
};


export type Subscription_RootItems_Tags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Items_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Tags_Bool_Exp>;
};


export type Subscription_RootLevelsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Levels_Order_By>>;
  where?: InputMaybe<Levels_Bool_Exp>;
};


export type Subscription_RootLevels_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Levels_Order_By>>;
  where?: InputMaybe<Levels_Bool_Exp>;
};


export type Subscription_RootLevels_By_PkArgs = {
  level: Scalars['Int']['input'];
};


export type Subscription_RootLevels_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Levels_Stream_Cursor_Input>>;
  where?: InputMaybe<Levels_Bool_Exp>;
};


export type Subscription_RootLive_EventsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Order_By>>;
  where?: InputMaybe<Live_Events_Bool_Exp>;
};


export type Subscription_RootLive_Events_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Order_By>>;
  where?: InputMaybe<Live_Events_Bool_Exp>;
};


export type Subscription_RootLive_Events_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootLive_Events_HostsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Order_By>>;
  where?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
};


export type Subscription_RootLive_Events_Hosts_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Live_Events_Hosts_Order_By>>;
  where?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
};


export type Subscription_RootLive_Events_Hosts_By_PkArgs = {
  live_event_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


export type Subscription_RootLive_Events_Hosts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Live_Events_Hosts_Stream_Cursor_Input>>;
  where?: InputMaybe<Live_Events_Hosts_Bool_Exp>;
};


export type Subscription_RootLive_Events_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Live_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Live_Events_Bool_Exp>;
};


export type Subscription_RootMessagesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


export type Subscription_RootMessages_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


export type Subscription_RootMessages_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootMessages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Messages_Stream_Cursor_Input>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


export type Subscription_RootMinecraft_AnnouncementArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_Announcement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_Announcement_Order_By>>;
  where?: InputMaybe<Minecraft_Announcement_Bool_Exp>;
};


export type Subscription_RootMinecraft_Announcement_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_Announcement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_Announcement_Order_By>>;
  where?: InputMaybe<Minecraft_Announcement_Bool_Exp>;
};


export type Subscription_RootMinecraft_Announcement_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootMinecraft_Announcement_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Minecraft_Announcement_Stream_Cursor_Input>>;
  where?: InputMaybe<Minecraft_Announcement_Bool_Exp>;
};


export type Subscription_RootMinecraft_ServersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_Servers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_Servers_Order_By>>;
  where?: InputMaybe<Minecraft_Servers_Bool_Exp>;
};


export type Subscription_RootMinecraft_Servers_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_Servers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_Servers_Order_By>>;
  where?: InputMaybe<Minecraft_Servers_Bool_Exp>;
};


export type Subscription_RootMinecraft_Servers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootMinecraft_Servers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Minecraft_Servers_Stream_Cursor_Input>>;
  where?: InputMaybe<Minecraft_Servers_Bool_Exp>;
};


export type Subscription_RootMinecraft_SkinsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_Skins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_Skins_Order_By>>;
  where?: InputMaybe<Minecraft_Skins_Bool_Exp>;
};


export type Subscription_RootMinecraft_Skins_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_Skins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_Skins_Order_By>>;
  where?: InputMaybe<Minecraft_Skins_Bool_Exp>;
};


export type Subscription_RootMinecraft_Skins_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootMinecraft_Skins_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Minecraft_Skins_Stream_Cursor_Input>>;
  where?: InputMaybe<Minecraft_Skins_Bool_Exp>;
};


export type Subscription_RootMinecraft_TutorialArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Order_By>>;
  where?: InputMaybe<Minecraft_Tutorial_Bool_Exp>;
};


export type Subscription_RootMinecraft_Tutorial_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Order_By>>;
  where?: InputMaybe<Minecraft_Tutorial_Bool_Exp>;
};


export type Subscription_RootMinecraft_Tutorial_By_PkArgs = {
  requirement_id: Scalars['uuid']['input'];
  stage_id: Scalars['uuid']['input'];
  tutorial_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


export type Subscription_RootMinecraft_Tutorial_StatusArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Status_Order_By>>;
  where?: InputMaybe<Minecraft_Tutorial_Status_Bool_Exp>;
};


export type Subscription_RootMinecraft_Tutorial_Status_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_Tutorial_Status_Order_By>>;
  where?: InputMaybe<Minecraft_Tutorial_Status_Bool_Exp>;
};


export type Subscription_RootMinecraft_Tutorial_Status_By_PkArgs = {
  tutorial_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


export type Subscription_RootMinecraft_Tutorial_Status_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Minecraft_Tutorial_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Minecraft_Tutorial_Status_Bool_Exp>;
};


export type Subscription_RootMinecraft_Tutorial_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Minecraft_Tutorial_Stream_Cursor_Input>>;
  where?: InputMaybe<Minecraft_Tutorial_Bool_Exp>;
};


export type Subscription_RootMinecraft_UserArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_User_Order_By>>;
  where?: InputMaybe<Minecraft_User_Bool_Exp>;
};


export type Subscription_RootMinecraft_User_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Minecraft_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Minecraft_User_Order_By>>;
  where?: InputMaybe<Minecraft_User_Bool_Exp>;
};


export type Subscription_RootMinecraft_User_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Minecraft_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Minecraft_User_Bool_Exp>;
};


export type Subscription_RootNotificationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootNotifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootOrdersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


export type Subscription_RootOrders_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


export type Subscription_RootOrders_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootOrders_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Orders_Stream_Cursor_Input>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


export type Subscription_RootOtpsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Otps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Otps_Order_By>>;
  where?: InputMaybe<Otps_Bool_Exp>;
};


export type Subscription_RootOtps_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Otps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Otps_Order_By>>;
  where?: InputMaybe<Otps_Bool_Exp>;
};


export type Subscription_RootOtps_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootOtps_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Otps_Stream_Cursor_Input>>;
  where?: InputMaybe<Otps_Bool_Exp>;
};


export type Subscription_RootPagesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Pages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Pages_Order_By>>;
  where?: InputMaybe<Pages_Bool_Exp>;
};


export type Subscription_RootPages_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Pages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Pages_Order_By>>;
  where?: InputMaybe<Pages_Bool_Exp>;
};


export type Subscription_RootPages_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Pages_Stream_Cursor_Input>>;
  where?: InputMaybe<Pages_Bool_Exp>;
};


export type Subscription_RootPlayer_WorldsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Player_Worlds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Player_Worlds_Order_By>>;
  where?: InputMaybe<Player_Worlds_Bool_Exp>;
};


export type Subscription_RootPlayer_Worlds_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Player_Worlds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Player_Worlds_Order_By>>;
  where?: InputMaybe<Player_Worlds_Bool_Exp>;
};


export type Subscription_RootPlayer_Worlds_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootPlayer_Worlds_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Player_Worlds_Stream_Cursor_Input>>;
  where?: InputMaybe<Player_Worlds_Bool_Exp>;
};


export type Subscription_RootPostsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


export type Subscription_RootPosts_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


export type Subscription_RootPosts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPosts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Posts_Stream_Cursor_Input>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


export type Subscription_RootProjectsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Subscription_RootProjects_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Subscription_RootProjects_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootProjects_CollaboratorsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Collaborators_Order_By>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


export type Subscription_RootProjects_Collaborators_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Collaborators_Order_By>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


export type Subscription_RootProjects_Collaborators_By_PkArgs = {
  collaborator_id: Scalars['String']['input'];
  project_id: Scalars['String']['input'];
};


export type Subscription_RootProjects_Collaborators_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Projects_Collaborators_Stream_Cursor_Input>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


export type Subscription_RootProjects_EditablesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Editables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Editables_Order_By>>;
  where?: InputMaybe<Projects_Editables_Bool_Exp>;
};


export type Subscription_RootProjects_Editables_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Editables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Editables_Order_By>>;
  where?: InputMaybe<Projects_Editables_Bool_Exp>;
};


export type Subscription_RootProjects_Editables_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Projects_Editables_Stream_Cursor_Input>>;
  where?: InputMaybe<Projects_Editables_Bool_Exp>;
};


export type Subscription_RootProjects_LeaderboardsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Leaderboards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Leaderboards_Order_By>>;
  where?: InputMaybe<Projects_Leaderboards_Bool_Exp>;
};


export type Subscription_RootProjects_Leaderboards_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Leaderboards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Leaderboards_Order_By>>;
  where?: InputMaybe<Projects_Leaderboards_Bool_Exp>;
};


export type Subscription_RootProjects_Leaderboards_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProjects_Leaderboards_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Projects_Leaderboards_Stream_Cursor_Input>>;
  where?: InputMaybe<Projects_Leaderboards_Bool_Exp>;
};


export type Subscription_RootProjects_PublicationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Publications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Publications_Order_By>>;
  where?: InputMaybe<Projects_Publications_Bool_Exp>;
};


export type Subscription_RootProjects_Publications_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Publications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Publications_Order_By>>;
  where?: InputMaybe<Projects_Publications_Bool_Exp>;
};


export type Subscription_RootProjects_Publications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProjects_Publications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Projects_Publications_Stream_Cursor_Input>>;
  where?: InputMaybe<Projects_Publications_Bool_Exp>;
};


export type Subscription_RootProjects_Publications_VotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Publications_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Publications_Votes_Order_By>>;
  where?: InputMaybe<Projects_Publications_Votes_Bool_Exp>;
};


export type Subscription_RootProjects_Publications_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Publications_Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Publications_Votes_Order_By>>;
  where?: InputMaybe<Projects_Publications_Votes_Bool_Exp>;
};


export type Subscription_RootProjects_Publications_Votes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProjects_Publications_Votes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Projects_Publications_Votes_Stream_Cursor_Input>>;
  where?: InputMaybe<Projects_Publications_Votes_Bool_Exp>;
};


export type Subscription_RootProjects_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Projects_Stream_Cursor_Input>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Subscription_RootProjects_TemplatesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Templates_Order_By>>;
  where?: InputMaybe<Projects_Templates_Bool_Exp>;
};


export type Subscription_RootProjects_Templates_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Templates_Order_By>>;
  where?: InputMaybe<Projects_Templates_Bool_Exp>;
};


export type Subscription_RootProjects_Templates_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootProjects_Templates_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Projects_Templates_Stream_Cursor_Input>>;
  where?: InputMaybe<Projects_Templates_Bool_Exp>;
};


export type Subscription_RootProspective_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Prospective_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Prospective_Users_Order_By>>;
  where?: InputMaybe<Prospective_Users_Bool_Exp>;
};


export type Subscription_RootProspective_Users_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Prospective_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Prospective_Users_Order_By>>;
  where?: InputMaybe<Prospective_Users_Bool_Exp>;
};


export type Subscription_RootProspective_Users_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootProspective_Users_PurchasesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Order_By>>;
  where?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
};


export type Subscription_RootProspective_Users_Purchases_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Prospective_Users_Purchases_Order_By>>;
  where?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
};


export type Subscription_RootProspective_Users_Purchases_By_PkArgs = {
  club_id: Scalars['uuid']['input'];
  prospective_user_id: Scalars['uuid']['input'];
};


export type Subscription_RootProspective_Users_Purchases_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Prospective_Users_Purchases_Stream_Cursor_Input>>;
  where?: InputMaybe<Prospective_Users_Purchases_Bool_Exp>;
};


export type Subscription_RootProspective_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Prospective_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Prospective_Users_Bool_Exp>;
};


export type Subscription_RootQuestsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Quests_Order_By>>;
  where?: InputMaybe<Quests_Bool_Exp>;
};


export type Subscription_RootQuests_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Quests_Order_By>>;
  where?: InputMaybe<Quests_Bool_Exp>;
};


export type Subscription_RootQuests_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootQuests_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Quests_Stream_Cursor_Input>>;
  where?: InputMaybe<Quests_Bool_Exp>;
};


export type Subscription_RootQueue_User_InfoArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Queue_User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Queue_User_Info_Order_By>>;
  where?: InputMaybe<Queue_User_Info_Bool_Exp>;
};


export type Subscription_RootQueue_User_Info_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Queue_User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Queue_User_Info_Order_By>>;
  where?: InputMaybe<Queue_User_Info_Bool_Exp>;
};


export type Subscription_RootQueue_User_Info_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootQueue_User_Info_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Queue_User_Info_Stream_Cursor_Input>>;
  where?: InputMaybe<Queue_User_Info_Bool_Exp>;
};


export type Subscription_RootQueuesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Queues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Queues_Order_By>>;
  where?: InputMaybe<Queues_Bool_Exp>;
};


export type Subscription_RootQueues_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Queues_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Queues_Order_By>>;
  where?: InputMaybe<Queues_Bool_Exp>;
};


export type Subscription_RootQueues_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootQueues_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Queues_Stream_Cursor_Input>>;
  where?: InputMaybe<Queues_Bool_Exp>;
};


export type Subscription_RootRender_JobsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Render_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Render_Jobs_Order_By>>;
  where?: InputMaybe<Render_Jobs_Bool_Exp>;
};


export type Subscription_RootRender_Jobs_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Render_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Render_Jobs_Order_By>>;
  where?: InputMaybe<Render_Jobs_Bool_Exp>;
};


export type Subscription_RootRender_Jobs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootRender_Jobs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Render_Jobs_Stream_Cursor_Input>>;
  where?: InputMaybe<Render_Jobs_Bool_Exp>;
};


export type Subscription_RootReportsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Subscription_RootReports_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Subscription_RootReports_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootReports_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Reports_Stream_Cursor_Input>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Subscription_RootRoomsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


export type Subscription_RootRooms_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


export type Subscription_RootRooms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootRooms_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Rooms_Stream_Cursor_Input>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


export type Subscription_RootSchedulesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Schedules_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Schedules_Order_By>>;
  where?: InputMaybe<Schedules_Bool_Exp>;
};


export type Subscription_RootSchedules_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Schedules_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Schedules_Order_By>>;
  where?: InputMaybe<Schedules_Bool_Exp>;
};


export type Subscription_RootSchedules_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootSchedules_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Schedules_Stream_Cursor_Input>>;
  where?: InputMaybe<Schedules_Bool_Exp>;
};


export type Subscription_RootShared_Cached_QueriesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Shared_Cached_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Shared_Cached_Queries_Order_By>>;
  where?: InputMaybe<Shared_Cached_Queries_Bool_Exp>;
};


export type Subscription_RootShared_Cached_Queries_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Shared_Cached_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Shared_Cached_Queries_Order_By>>;
  where?: InputMaybe<Shared_Cached_Queries_Bool_Exp>;
};


export type Subscription_RootShared_Cached_Queries_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootShared_Cached_Queries_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Shared_Cached_Queries_Stream_Cursor_Input>>;
  where?: InputMaybe<Shared_Cached_Queries_Bool_Exp>;
};


export type Subscription_RootShort_UrlsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Short_Urls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Short_Urls_Order_By>>;
  where?: InputMaybe<Short_Urls_Bool_Exp>;
};


export type Subscription_RootShort_Urls_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Short_Urls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Short_Urls_Order_By>>;
  where?: InputMaybe<Short_Urls_Bool_Exp>;
};


export type Subscription_RootShort_Urls_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootShort_Urls_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Short_Urls_Stream_Cursor_Input>>;
  where?: InputMaybe<Short_Urls_Bool_Exp>;
};


export type Subscription_RootSubscriptionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Subscription_RootSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Subscription_RootSubscriptions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootSubscriptions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Subscriptions_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Subscription_RootTagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootTags_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootTags_AssociationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tags_Associations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tags_Associations_Order_By>>;
  where?: InputMaybe<Tags_Associations_Bool_Exp>;
};


export type Subscription_RootTags_Associations_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tags_Associations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tags_Associations_Order_By>>;
  where?: InputMaybe<Tags_Associations_Bool_Exp>;
};


export type Subscription_RootTags_Associations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootTags_Associations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Tags_Associations_Stream_Cursor_Input>>;
  where?: InputMaybe<Tags_Associations_Bool_Exp>;
};


export type Subscription_RootTags_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootThemesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Themes_Order_By>>;
  where?: InputMaybe<Themes_Bool_Exp>;
};


export type Subscription_RootThemes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Themes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Themes_Order_By>>;
  where?: InputMaybe<Themes_Bool_Exp>;
};


export type Subscription_RootThemes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootThemes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Themes_Stream_Cursor_Input>>;
  where?: InputMaybe<Themes_Bool_Exp>;
};


export type Subscription_RootToolsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tools_Order_By>>;
  where?: InputMaybe<Tools_Bool_Exp>;
};


export type Subscription_RootTools_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tools_Order_By>>;
  where?: InputMaybe<Tools_Bool_Exp>;
};


export type Subscription_RootTools_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTools_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Tools_Stream_Cursor_Input>>;
  where?: InputMaybe<Tools_Bool_Exp>;
};


export type Subscription_RootTopicsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Topics_Order_By>>;
  where?: InputMaybe<Topics_Bool_Exp>;
};


export type Subscription_RootTopics_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Topics_Order_By>>;
  where?: InputMaybe<Topics_Bool_Exp>;
};


export type Subscription_RootTopics_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTopics_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Topics_Stream_Cursor_Input>>;
  where?: InputMaybe<Topics_Bool_Exp>;
};


export type Subscription_RootTopics_ToolsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Topics_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Topics_Tools_Order_By>>;
  where?: InputMaybe<Topics_Tools_Bool_Exp>;
};


export type Subscription_RootTopics_Tools_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Topics_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Topics_Tools_Order_By>>;
  where?: InputMaybe<Topics_Tools_Bool_Exp>;
};


export type Subscription_RootTopics_Tools_By_PkArgs = {
  tool_id: Scalars['String']['input'];
  topic_id: Scalars['String']['input'];
};


export type Subscription_RootTopics_Tools_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Topics_Tools_Stream_Cursor_Input>>;
  where?: InputMaybe<Topics_Tools_Bool_Exp>;
};


export type Subscription_RootTransactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


export type Subscription_RootTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


export type Subscription_RootTransactions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTransactions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


export type Subscription_RootTutorialsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Order_By>>;
  where?: InputMaybe<Tutorials_Bool_Exp>;
};


export type Subscription_RootTutorials_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Order_By>>;
  where?: InputMaybe<Tutorials_Bool_Exp>;
};


export type Subscription_RootTutorials_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTutorials_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Tutorials_Stream_Cursor_Input>>;
  where?: InputMaybe<Tutorials_Bool_Exp>;
};


export type Subscription_RootTutorials_TagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Tags_Order_By>>;
  where?: InputMaybe<Tutorials_Tags_Bool_Exp>;
};


export type Subscription_RootTutorials_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Tags_Order_By>>;
  where?: InputMaybe<Tutorials_Tags_Bool_Exp>;
};


export type Subscription_RootTutorials_Tags_By_PkArgs = {
  tag_id: Scalars['String']['input'];
  tutorial_id: Scalars['String']['input'];
};


export type Subscription_RootTutorials_Tags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Tutorials_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Tutorials_Tags_Bool_Exp>;
};


export type Subscription_RootUser_Collected_AvatarsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Collected_Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Collected_Avatars_Order_By>>;
  where?: InputMaybe<User_Collected_Avatars_Bool_Exp>;
};


export type Subscription_RootUser_Collected_Avatars_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Collected_Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Collected_Avatars_Order_By>>;
  where?: InputMaybe<User_Collected_Avatars_Bool_Exp>;
};


export type Subscription_RootUser_Collected_Avatars_By_PkArgs = {
  avatar_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


export type Subscription_RootUser_Collected_Avatars_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<User_Collected_Avatars_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Collected_Avatars_Bool_Exp>;
};


export type Subscription_RootUser_InvitesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Subscription_RootUser_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Subscription_RootUser_Invites_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUser_Invites_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<User_Invites_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Subscription_RootUser_PresenceArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Presence_Order_By>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};


export type Subscription_RootUser_Presence_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Presence_Order_By>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};


export type Subscription_RootUser_Presence_By_PkArgs = {
  platform: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type Subscription_RootUser_Presence_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<User_Presence_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_ActionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Actions_Order_By>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


export type Subscription_RootUsers_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Actions_Order_By>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


export type Subscription_RootUsers_Actions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUsers_Actions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Users_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_BadgesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Badges_Order_By>>;
  where?: InputMaybe<Users_Badges_Bool_Exp>;
};


export type Subscription_RootUsers_Badges_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Badges_Order_By>>;
  where?: InputMaybe<Users_Badges_Bool_Exp>;
};


export type Subscription_RootUsers_Badges_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootUsers_Badges_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Users_Badges_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Badges_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUsers_Cached_QueriesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Cached_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Cached_Queries_Order_By>>;
  where?: InputMaybe<Users_Cached_Queries_Bool_Exp>;
};


export type Subscription_RootUsers_Cached_Queries_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Cached_Queries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Cached_Queries_Order_By>>;
  where?: InputMaybe<Users_Cached_Queries_Bool_Exp>;
};


export type Subscription_RootUsers_Cached_Queries_By_PkArgs = {
  key: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
};


export type Subscription_RootUsers_Cached_Queries_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Users_Cached_Queries_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Cached_Queries_Bool_Exp>;
};


export type Subscription_RootUsers_FeaturesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Features_Order_By>>;
  where?: InputMaybe<Users_Features_Bool_Exp>;
};


export type Subscription_RootUsers_Features_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Features_Order_By>>;
  where?: InputMaybe<Users_Features_Bool_Exp>;
};


export type Subscription_RootUsers_Features_By_PkArgs = {
  feature_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


export type Subscription_RootUsers_Features_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Users_Features_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Features_Bool_Exp>;
};


export type Subscription_RootUsers_LevelsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Levels_Order_By>>;
  where?: InputMaybe<Users_Levels_Bool_Exp>;
};


export type Subscription_RootUsers_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Levels_Order_By>>;
  where?: InputMaybe<Users_Levels_Bool_Exp>;
};


export type Subscription_RootUsers_Levels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUsers_Levels_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Users_Levels_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Levels_Bool_Exp>;
};


export type Subscription_RootUsers_NotificationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Notifications_Order_By>>;
  where?: InputMaybe<Users_Notifications_Bool_Exp>;
};


export type Subscription_RootUsers_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Notifications_Order_By>>;
  where?: InputMaybe<Users_Notifications_Bool_Exp>;
};


export type Subscription_RootUsers_Notifications_By_PkArgs = {
  notification_id: Scalars['uuid']['input'];
  user_id: Scalars['String']['input'];
};


export type Subscription_RootUsers_Notifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Users_Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Notifications_Bool_Exp>;
};


export type Subscription_RootUsers_PresenceArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Presence_Order_By>>;
  where?: InputMaybe<Users_Presence_Bool_Exp>;
};


export type Subscription_RootUsers_Presence_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Presence_Order_By>>;
  where?: InputMaybe<Users_Presence_Bool_Exp>;
};


export type Subscription_RootUsers_Presence_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Users_Presence_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Presence_Bool_Exp>;
};


export type Subscription_RootUsers_ProgressionArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Progression_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Progression_Order_By>>;
  where?: InputMaybe<Users_Progression_Bool_Exp>;
};


export type Subscription_RootUsers_Progression_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Progression_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Progression_Order_By>>;
  where?: InputMaybe<Users_Progression_Bool_Exp>;
};


export type Subscription_RootUsers_Progression_By_PkArgs = {
  user_id: Scalars['String']['input'];
};


export type Subscription_RootUsers_Progression_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Users_Progression_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Progression_Bool_Exp>;
};


export type Subscription_RootUsers_QuestsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Quests_Order_By>>;
  where?: InputMaybe<Users_Quests_Bool_Exp>;
};


export type Subscription_RootUsers_Quests_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Quests_Order_By>>;
  where?: InputMaybe<Users_Quests_Bool_Exp>;
};


export type Subscription_RootUsers_Quests_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUsers_Quests_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Users_Quests_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Quests_Bool_Exp>;
};


export type Subscription_RootUsers_Stale_Web_PresenceArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Stale_Web_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Stale_Web_Presence_Order_By>>;
  where?: InputMaybe<Users_Stale_Web_Presence_Bool_Exp>;
};


export type Subscription_RootUsers_Stale_Web_Presence_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Stale_Web_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Stale_Web_Presence_Order_By>>;
  where?: InputMaybe<Users_Stale_Web_Presence_Bool_Exp>;
};


export type Subscription_RootUsers_Stale_Web_Presence_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Users_Stale_Web_Presence_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Stale_Web_Presence_Bool_Exp>;
};


export type Subscription_RootUsers_StatsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Stats_Order_By>>;
  where?: InputMaybe<Users_Stats_Bool_Exp>;
};


export type Subscription_RootUsers_Stats_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Stats_Order_By>>;
  where?: InputMaybe<Users_Stats_Bool_Exp>;
};


export type Subscription_RootUsers_Stats_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootUsers_Stats_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Users_Stats_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Stats_Bool_Exp>;
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootVotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


export type Subscription_RootVotes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


export type Subscription_RootVotes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootVotes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: ReadonlyArray<InputMaybe<Votes_Stream_Cursor_Input>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};

/** columns and relationships of "subscriptions" */
export type Subscriptions = {
  readonly __typename?: 'subscriptions';
  readonly cancel_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly cancel_at_period_end: Scalars['Boolean']['output'];
  readonly canceled_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly created: Scalars['timestamptz']['output'];
  readonly current_period_end: Scalars['timestamptz']['output'];
  readonly current_period_start: Scalars['timestamptz']['output'];
  readonly customer_id: Scalars['String']['output'];
  readonly ended_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly entitled: Scalars['Boolean']['output'];
  readonly id: Scalars['String']['output'];
  readonly metadata: Scalars['jsonb']['output'];
  readonly pause_collection_behavior?: Maybe<Scalars['String']['output']>;
  readonly pause_collection_behavior_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly pause_collection_resumes_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly price_id: Scalars['String']['output'];
  readonly quantity: Scalars['Int']['output'];
  readonly status: Scalars['String']['output'];
  readonly trial_end?: Maybe<Scalars['timestamptz']['output']>;
  readonly trial_start?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  readonly user?: Maybe<Users>;
};


/** columns and relationships of "subscriptions" */
export type SubscriptionsMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "subscriptions" */
export type Subscriptions_Aggregate = {
  readonly __typename?: 'subscriptions_aggregate';
  readonly aggregate?: Maybe<Subscriptions_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Subscriptions>;
};

export type Subscriptions_Aggregate_Bool_Exp = {
  readonly bool_and?: InputMaybe<Subscriptions_Aggregate_Bool_Exp_Bool_And>;
  readonly bool_or?: InputMaybe<Subscriptions_Aggregate_Bool_Exp_Bool_Or>;
  readonly count?: InputMaybe<Subscriptions_Aggregate_Bool_Exp_Count>;
};

export type Subscriptions_Aggregate_Bool_Exp_Bool_And = {
  readonly arguments: Subscriptions_Select_Column_Subscriptions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Subscriptions_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Subscriptions_Aggregate_Bool_Exp_Bool_Or = {
  readonly arguments: Subscriptions_Select_Column_Subscriptions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Subscriptions_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Subscriptions_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Subscriptions_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Subscriptions_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "subscriptions" */
export type Subscriptions_Aggregate_Fields = {
  readonly __typename?: 'subscriptions_aggregate_fields';
  readonly avg?: Maybe<Subscriptions_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Subscriptions_Max_Fields>;
  readonly min?: Maybe<Subscriptions_Min_Fields>;
  readonly stddev?: Maybe<Subscriptions_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Subscriptions_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Subscriptions_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Subscriptions_Sum_Fields>;
  readonly var_pop?: Maybe<Subscriptions_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Subscriptions_Var_Samp_Fields>;
  readonly variance?: Maybe<Subscriptions_Variance_Fields>;
};


/** aggregate fields of "subscriptions" */
export type Subscriptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriptions" */
export type Subscriptions_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Subscriptions_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Subscriptions_Max_Order_By>;
  readonly min?: InputMaybe<Subscriptions_Min_Order_By>;
  readonly stddev?: InputMaybe<Subscriptions_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Subscriptions_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Subscriptions_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Subscriptions_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Subscriptions_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Subscriptions_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Subscriptions_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Subscriptions_Append_Input = {
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "subscriptions" */
export type Subscriptions_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Subscriptions_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Subscriptions_On_Conflict>;
};

/** aggregate avg on columns */
export type Subscriptions_Avg_Fields = {
  readonly __typename?: 'subscriptions_avg_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriptions" */
export type Subscriptions_Avg_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "subscriptions". All fields are combined with a logical 'AND'. */
export type Subscriptions_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Subscriptions_Bool_Exp>>;
  readonly _not?: InputMaybe<Subscriptions_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Subscriptions_Bool_Exp>>;
  readonly cancel_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly cancel_at_period_end?: InputMaybe<Boolean_Comparison_Exp>;
  readonly canceled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly created?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly current_period_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly current_period_start?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly customer_id?: InputMaybe<String_Comparison_Exp>;
  readonly ended_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly entitled?: InputMaybe<Boolean_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly pause_collection_behavior?: InputMaybe<String_Comparison_Exp>;
  readonly pause_collection_behavior_updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly pause_collection_resumes_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly price_id?: InputMaybe<String_Comparison_Exp>;
  readonly quantity?: InputMaybe<Int_Comparison_Exp>;
  readonly status?: InputMaybe<String_Comparison_Exp>;
  readonly trial_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly trial_start?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "subscriptions" */
export enum Subscriptions_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionsPkey = 'subscriptions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Subscriptions_Delete_At_Path_Input = {
  readonly metadata?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Subscriptions_Delete_Elem_Input = {
  readonly metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Subscriptions_Delete_Key_Input = {
  readonly metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "subscriptions" */
export type Subscriptions_Inc_Input = {
  readonly quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriptions" */
export type Subscriptions_Insert_Input = {
  readonly cancel_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly cancel_at_period_end?: InputMaybe<Scalars['Boolean']['input']>;
  readonly canceled_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly created?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly current_period_end?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly current_period_start?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly customer_id?: InputMaybe<Scalars['String']['input']>;
  readonly ended_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
  readonly pause_collection_behavior?: InputMaybe<Scalars['String']['input']>;
  readonly pause_collection_behavior_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly pause_collection_resumes_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly price_id?: InputMaybe<Scalars['String']['input']>;
  readonly quantity?: InputMaybe<Scalars['Int']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly trial_end?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly trial_start?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Subscriptions_Max_Fields = {
  readonly __typename?: 'subscriptions_max_fields';
  readonly cancel_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly canceled_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly created?: Maybe<Scalars['timestamptz']['output']>;
  readonly current_period_end?: Maybe<Scalars['timestamptz']['output']>;
  readonly current_period_start?: Maybe<Scalars['timestamptz']['output']>;
  readonly customer_id?: Maybe<Scalars['String']['output']>;
  readonly ended_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly pause_collection_behavior?: Maybe<Scalars['String']['output']>;
  readonly pause_collection_behavior_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly pause_collection_resumes_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly price_id?: Maybe<Scalars['String']['output']>;
  readonly quantity?: Maybe<Scalars['Int']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly trial_end?: Maybe<Scalars['timestamptz']['output']>;
  readonly trial_start?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "subscriptions" */
export type Subscriptions_Max_Order_By = {
  readonly cancel_at?: InputMaybe<Order_By>;
  readonly canceled_at?: InputMaybe<Order_By>;
  readonly created?: InputMaybe<Order_By>;
  readonly current_period_end?: InputMaybe<Order_By>;
  readonly current_period_start?: InputMaybe<Order_By>;
  readonly customer_id?: InputMaybe<Order_By>;
  readonly ended_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly pause_collection_behavior?: InputMaybe<Order_By>;
  readonly pause_collection_behavior_updated_at?: InputMaybe<Order_By>;
  readonly pause_collection_resumes_at?: InputMaybe<Order_By>;
  readonly price_id?: InputMaybe<Order_By>;
  readonly quantity?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly trial_end?: InputMaybe<Order_By>;
  readonly trial_start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subscriptions_Min_Fields = {
  readonly __typename?: 'subscriptions_min_fields';
  readonly cancel_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly canceled_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly created?: Maybe<Scalars['timestamptz']['output']>;
  readonly current_period_end?: Maybe<Scalars['timestamptz']['output']>;
  readonly current_period_start?: Maybe<Scalars['timestamptz']['output']>;
  readonly customer_id?: Maybe<Scalars['String']['output']>;
  readonly ended_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly pause_collection_behavior?: Maybe<Scalars['String']['output']>;
  readonly pause_collection_behavior_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly pause_collection_resumes_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly price_id?: Maybe<Scalars['String']['output']>;
  readonly quantity?: Maybe<Scalars['Int']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly trial_end?: Maybe<Scalars['timestamptz']['output']>;
  readonly trial_start?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "subscriptions" */
export type Subscriptions_Min_Order_By = {
  readonly cancel_at?: InputMaybe<Order_By>;
  readonly canceled_at?: InputMaybe<Order_By>;
  readonly created?: InputMaybe<Order_By>;
  readonly current_period_end?: InputMaybe<Order_By>;
  readonly current_period_start?: InputMaybe<Order_By>;
  readonly customer_id?: InputMaybe<Order_By>;
  readonly ended_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly pause_collection_behavior?: InputMaybe<Order_By>;
  readonly pause_collection_behavior_updated_at?: InputMaybe<Order_By>;
  readonly pause_collection_resumes_at?: InputMaybe<Order_By>;
  readonly price_id?: InputMaybe<Order_By>;
  readonly quantity?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly trial_end?: InputMaybe<Order_By>;
  readonly trial_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subscriptions" */
export type Subscriptions_Mutation_Response = {
  readonly __typename?: 'subscriptions_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Subscriptions>;
};

/** on_conflict condition type for table "subscriptions" */
export type Subscriptions_On_Conflict = {
  readonly constraint: Subscriptions_Constraint;
  readonly update_columns?: ReadonlyArray<Subscriptions_Update_Column>;
  readonly where?: InputMaybe<Subscriptions_Bool_Exp>;
};

/** Ordering options when selecting data from "subscriptions". */
export type Subscriptions_Order_By = {
  readonly cancel_at?: InputMaybe<Order_By>;
  readonly cancel_at_period_end?: InputMaybe<Order_By>;
  readonly canceled_at?: InputMaybe<Order_By>;
  readonly created?: InputMaybe<Order_By>;
  readonly current_period_end?: InputMaybe<Order_By>;
  readonly current_period_start?: InputMaybe<Order_By>;
  readonly customer_id?: InputMaybe<Order_By>;
  readonly ended_at?: InputMaybe<Order_By>;
  readonly entitled?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly metadata?: InputMaybe<Order_By>;
  readonly pause_collection_behavior?: InputMaybe<Order_By>;
  readonly pause_collection_behavior_updated_at?: InputMaybe<Order_By>;
  readonly pause_collection_resumes_at?: InputMaybe<Order_By>;
  readonly price_id?: InputMaybe<Order_By>;
  readonly quantity?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly trial_end?: InputMaybe<Order_By>;
  readonly trial_start?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: subscriptions */
export type Subscriptions_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Subscriptions_Prepend_Input = {
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "subscriptions" */
export enum Subscriptions_Select_Column {
  /** column name */
  CancelAt = 'cancel_at',
  /** column name */
  CancelAtPeriodEnd = 'cancel_at_period_end',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  Created = 'created',
  /** column name */
  CurrentPeriodEnd = 'current_period_end',
  /** column name */
  CurrentPeriodStart = 'current_period_start',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Entitled = 'entitled',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PauseCollectionBehavior = 'pause_collection_behavior',
  /** column name */
  PauseCollectionBehaviorUpdatedAt = 'pause_collection_behavior_updated_at',
  /** column name */
  PauseCollectionResumesAt = 'pause_collection_resumes_at',
  /** column name */
  PriceId = 'price_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Status = 'status',
  /** column name */
  TrialEnd = 'trial_end',
  /** column name */
  TrialStart = 'trial_start'
}

/** select "subscriptions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "subscriptions" */
export enum Subscriptions_Select_Column_Subscriptions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  CancelAtPeriodEnd = 'cancel_at_period_end',
  /** column name */
  Entitled = 'entitled'
}

/** select "subscriptions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "subscriptions" */
export enum Subscriptions_Select_Column_Subscriptions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  CancelAtPeriodEnd = 'cancel_at_period_end',
  /** column name */
  Entitled = 'entitled'
}

/** input type for updating data in table "subscriptions" */
export type Subscriptions_Set_Input = {
  readonly cancel_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly cancel_at_period_end?: InputMaybe<Scalars['Boolean']['input']>;
  readonly canceled_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly created?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly current_period_end?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly current_period_start?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly customer_id?: InputMaybe<Scalars['String']['input']>;
  readonly ended_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
  readonly pause_collection_behavior?: InputMaybe<Scalars['String']['input']>;
  readonly pause_collection_behavior_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly pause_collection_resumes_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly price_id?: InputMaybe<Scalars['String']['input']>;
  readonly quantity?: InputMaybe<Scalars['Int']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly trial_end?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly trial_start?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Subscriptions_Stddev_Fields = {
  readonly __typename?: 'subscriptions_stddev_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriptions" */
export type Subscriptions_Stddev_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Subscriptions_Stddev_Pop_Fields = {
  readonly __typename?: 'subscriptions_stddev_pop_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriptions" */
export type Subscriptions_Stddev_Pop_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Subscriptions_Stddev_Samp_Fields = {
  readonly __typename?: 'subscriptions_stddev_samp_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriptions" */
export type Subscriptions_Stddev_Samp_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "subscriptions" */
export type Subscriptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Subscriptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscriptions_Stream_Cursor_Value_Input = {
  readonly cancel_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly cancel_at_period_end?: InputMaybe<Scalars['Boolean']['input']>;
  readonly canceled_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly created?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly current_period_end?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly current_period_start?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly customer_id?: InputMaybe<Scalars['String']['input']>;
  readonly ended_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly entitled?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
  readonly pause_collection_behavior?: InputMaybe<Scalars['String']['input']>;
  readonly pause_collection_behavior_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly pause_collection_resumes_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly price_id?: InputMaybe<Scalars['String']['input']>;
  readonly quantity?: InputMaybe<Scalars['Int']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly trial_end?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly trial_start?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Subscriptions_Sum_Fields = {
  readonly __typename?: 'subscriptions_sum_fields';
  readonly quantity?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriptions" */
export type Subscriptions_Sum_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** update columns of table "subscriptions" */
export enum Subscriptions_Update_Column {
  /** column name */
  CancelAt = 'cancel_at',
  /** column name */
  CancelAtPeriodEnd = 'cancel_at_period_end',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  Created = 'created',
  /** column name */
  CurrentPeriodEnd = 'current_period_end',
  /** column name */
  CurrentPeriodStart = 'current_period_start',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PauseCollectionBehavior = 'pause_collection_behavior',
  /** column name */
  PauseCollectionBehaviorUpdatedAt = 'pause_collection_behavior_updated_at',
  /** column name */
  PauseCollectionResumesAt = 'pause_collection_resumes_at',
  /** column name */
  PriceId = 'price_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Status = 'status',
  /** column name */
  TrialEnd = 'trial_end',
  /** column name */
  TrialStart = 'trial_start'
}

export type Subscriptions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Subscriptions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Subscriptions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Subscriptions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Subscriptions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Subscriptions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Subscriptions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Subscriptions_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Subscriptions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Subscriptions_Var_Pop_Fields = {
  readonly __typename?: 'subscriptions_var_pop_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriptions" */
export type Subscriptions_Var_Pop_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Subscriptions_Var_Samp_Fields = {
  readonly __typename?: 'subscriptions_var_samp_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriptions" */
export type Subscriptions_Var_Samp_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Subscriptions_Variance_Fields = {
  readonly __typename?: 'subscriptions_variance_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriptions" */
export type Subscriptions_Variance_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

export enum Tag_Type {
  Items = 'items',
  Projects = 'projects',
  Tutorials = 'tutorials'
}

export type Tag_Type_Bool_Exp = {
  readonly _eq?: InputMaybe<Tag_Type>;
  readonly _in?: InputMaybe<ReadonlyArray<Tag_Type>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Tag_Type>;
  readonly _nin?: InputMaybe<ReadonlyArray<Tag_Type>>;
};

/** columns and relationships of "tags" */
export type Tags = {
  readonly __typename?: 'tags';
  /** An array relationship */
  readonly competitions: ReadonlyArray<Competitions_Tags>;
  /** An aggregate relationship */
  readonly competitions_aggregate: Competitions_Tags_Aggregate;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  /** An array relationship */
  readonly tutorials: ReadonlyArray<Tutorials_Tags>;
  /** An aggregate relationship */
  readonly tutorials_aggregate: Tutorials_Tags_Aggregate;
  readonly type: Tag_Type;
};


/** columns and relationships of "tags" */
export type TagsCompetitionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tags_Order_By>>;
  where?: InputMaybe<Competitions_Tags_Bool_Exp>;
};


/** columns and relationships of "tags" */
export type TagsCompetitions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tags_Order_By>>;
  where?: InputMaybe<Competitions_Tags_Bool_Exp>;
};


/** columns and relationships of "tags" */
export type TagsTutorialsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Tags_Order_By>>;
  where?: InputMaybe<Tutorials_Tags_Bool_Exp>;
};


/** columns and relationships of "tags" */
export type TagsTutorials_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Tags_Order_By>>;
  where?: InputMaybe<Tutorials_Tags_Bool_Exp>;
};

/** aggregated selection of "tags" */
export type Tags_Aggregate = {
  readonly __typename?: 'tags_aggregate';
  readonly aggregate?: Maybe<Tags_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Tags>;
};

/** aggregate fields of "tags" */
export type Tags_Aggregate_Fields = {
  readonly __typename?: 'tags_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Tags_Max_Fields>;
  readonly min?: Maybe<Tags_Min_Fields>;
};


/** aggregate fields of "tags" */
export type Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Table for storing tags */
export type Tags_Associations = {
  readonly __typename?: 'tags_associations';
  readonly association_id: Scalars['String']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly user_id: Scalars['String']['output'];
  readonly value: Scalars['String']['output'];
};

/** aggregated selection of "tags_associations" */
export type Tags_Associations_Aggregate = {
  readonly __typename?: 'tags_associations_aggregate';
  readonly aggregate?: Maybe<Tags_Associations_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Tags_Associations>;
};

/** aggregate fields of "tags_associations" */
export type Tags_Associations_Aggregate_Fields = {
  readonly __typename?: 'tags_associations_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Tags_Associations_Max_Fields>;
  readonly min?: Maybe<Tags_Associations_Min_Fields>;
};


/** aggregate fields of "tags_associations" */
export type Tags_Associations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Tags_Associations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "tags_associations". All fields are combined with a logical 'AND'. */
export type Tags_Associations_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Tags_Associations_Bool_Exp>>;
  readonly _not?: InputMaybe<Tags_Associations_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Tags_Associations_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
  readonly value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tags_associations" */
export enum Tags_Associations_Constraint {
  /** unique or primary key constraint on columns "id" */
  TagsAssociationsPkey = 'tags_associations_pkey'
}

/** input type for inserting data into table "tags_associations" */
export type Tags_Associations_Insert_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tags_Associations_Max_Fields = {
  readonly __typename?: 'tags_associations_max_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
  readonly value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Tags_Associations_Min_Fields = {
  readonly __typename?: 'tags_associations_min_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
  readonly value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "tags_associations" */
export type Tags_Associations_Mutation_Response = {
  readonly __typename?: 'tags_associations_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Tags_Associations>;
};

/** on_conflict condition type for table "tags_associations" */
export type Tags_Associations_On_Conflict = {
  readonly constraint: Tags_Associations_Constraint;
  readonly update_columns?: ReadonlyArray<Tags_Associations_Update_Column>;
  readonly where?: InputMaybe<Tags_Associations_Bool_Exp>;
};

/** Ordering options when selecting data from "tags_associations". */
export type Tags_Associations_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tags_associations */
export type Tags_Associations_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "tags_associations" */
export enum Tags_Associations_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "tags_associations" */
export type Tags_Associations_Set_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tags_associations" */
export type Tags_Associations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Tags_Associations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tags_Associations_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tags_associations" */
export enum Tags_Associations_Update_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type Tags_Associations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Tags_Associations_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Tags_Associations_Bool_Exp;
};

/** Boolean expression to filter rows from the table "tags". All fields are combined with a logical 'AND'. */
export type Tags_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Tags_Bool_Exp>>;
  readonly _not?: InputMaybe<Tags_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Tags_Bool_Exp>>;
  readonly competitions?: InputMaybe<Competitions_Tags_Bool_Exp>;
  readonly competitions_aggregate?: InputMaybe<Competitions_Tags_Aggregate_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly description?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly tutorials?: InputMaybe<Tutorials_Tags_Bool_Exp>;
  readonly tutorials_aggregate?: InputMaybe<Tutorials_Tags_Aggregate_Bool_Exp>;
  readonly type?: InputMaybe<Tag_Type_Bool_Exp>;
};

/** unique or primary key constraints on table "tags" */
export enum Tags_Constraint {
  /** unique or primary key constraint on columns "name" */
  TagsNameKey = 'tags_name_key',
  /** unique or primary key constraint on columns "id" */
  TagsPkey = 'tags_pkey'
}

/** input type for inserting data into table "tags" */
export type Tags_Insert_Input = {
  readonly competitions?: InputMaybe<Competitions_Tags_Arr_Rel_Insert_Input>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly tutorials?: InputMaybe<Tutorials_Tags_Arr_Rel_Insert_Input>;
  readonly type?: InputMaybe<Tag_Type>;
};

/** aggregate max on columns */
export type Tags_Max_Fields = {
  readonly __typename?: 'tags_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Tags_Min_Fields = {
  readonly __typename?: 'tags_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "tags" */
export type Tags_Mutation_Response = {
  readonly __typename?: 'tags_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Tags>;
};

/** input type for inserting object relation for remote table "tags" */
export type Tags_Obj_Rel_Insert_Input = {
  readonly data: Tags_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** on_conflict condition type for table "tags" */
export type Tags_On_Conflict = {
  readonly constraint: Tags_Constraint;
  readonly update_columns?: ReadonlyArray<Tags_Update_Column>;
  readonly where?: InputMaybe<Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "tags". */
export type Tags_Order_By = {
  readonly competitions_aggregate?: InputMaybe<Competitions_Tags_Aggregate_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly tutorials_aggregate?: InputMaybe<Tutorials_Tags_Aggregate_Order_By>;
  readonly type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tags */
export type Tags_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** select columns of table "tags" */
export enum Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "tags" */
export type Tags_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Tag_Type>;
};

/** Streaming cursor of the table "tags" */
export type Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tags_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tags" */
export enum Tags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type'
}

export type Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Tags_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Tags_Bool_Exp;
};

export enum Theme_Status {
  Active = 'active',
  Inactive = 'inactive'
}

export type Theme_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<Theme_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<Theme_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Theme_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<Theme_Status>>;
};

/** columns and relationships of "themes" */
export type Themes = {
  readonly __typename?: 'themes';
  readonly active_at: Scalars['timestamptz']['output'];
  readonly association_id: Scalars['String']['output'];
  readonly background_image?: Maybe<Scalars['String']['output']>;
  readonly banner_title?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly club?: Maybe<Clubs>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly color: Scalars['String']['output'];
  /** An array relationship */
  readonly comments: ReadonlyArray<Comments>;
  /** An aggregate relationship */
  readonly comments_aggregate: Comments_Aggregate;
  /** An array relationship */
  readonly competitions: ReadonlyArray<Competitions>;
  /** An aggregate relationship */
  readonly competitions_aggregate: Competitions_Aggregate;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly description: Scalars['String']['output'];
  /** An array relationship */
  readonly feeds: ReadonlyArray<Feeds>;
  /** An aggregate relationship */
  readonly feeds_aggregate: Feeds_Aggregate;
  readonly id: Scalars['uuid']['output'];
  readonly image: Scalars['String']['output'];
  readonly inactive_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly name: Scalars['String']['output'];
  readonly season_id?: Maybe<Scalars['uuid']['output']>;
  readonly status: Theme_Status;
  readonly title?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly topic: Topics;
  readonly topic_id: Scalars['String']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  readonly votes: ReadonlyArray<Votes>;
  /** An aggregate relationship */
  readonly votes_aggregate: Votes_Aggregate;
};


/** columns and relationships of "themes" */
export type ThemesCommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "themes" */
export type ThemesComments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "themes" */
export type ThemesCompetitionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


/** columns and relationships of "themes" */
export type ThemesCompetitions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


/** columns and relationships of "themes" */
export type ThemesFeedsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


/** columns and relationships of "themes" */
export type ThemesFeeds_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feeds_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feeds_Order_By>>;
  where?: InputMaybe<Feeds_Bool_Exp>;
};


/** columns and relationships of "themes" */
export type ThemesVotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


/** columns and relationships of "themes" */
export type ThemesVotes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};

/** aggregated selection of "themes" */
export type Themes_Aggregate = {
  readonly __typename?: 'themes_aggregate';
  readonly aggregate?: Maybe<Themes_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Themes>;
};

export type Themes_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Themes_Aggregate_Bool_Exp_Count>;
};

export type Themes_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Themes_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Themes_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "themes" */
export type Themes_Aggregate_Fields = {
  readonly __typename?: 'themes_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Themes_Max_Fields>;
  readonly min?: Maybe<Themes_Min_Fields>;
};


/** aggregate fields of "themes" */
export type Themes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Themes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "themes" */
export type Themes_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Themes_Max_Order_By>;
  readonly min?: InputMaybe<Themes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "themes" */
export type Themes_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Themes_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Themes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "themes". All fields are combined with a logical 'AND'. */
export type Themes_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Themes_Bool_Exp>>;
  readonly _not?: InputMaybe<Themes_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Themes_Bool_Exp>>;
  readonly active_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly background_image?: InputMaybe<String_Comparison_Exp>;
  readonly banner_title?: InputMaybe<String_Comparison_Exp>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly club_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly color?: InputMaybe<String_Comparison_Exp>;
  readonly comments?: InputMaybe<Comments_Bool_Exp>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  readonly competitions?: InputMaybe<Competitions_Bool_Exp>;
  readonly competitions_aggregate?: InputMaybe<Competitions_Aggregate_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly description?: InputMaybe<String_Comparison_Exp>;
  readonly feeds?: InputMaybe<Feeds_Bool_Exp>;
  readonly feeds_aggregate?: InputMaybe<Feeds_Aggregate_Bool_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly image?: InputMaybe<String_Comparison_Exp>;
  readonly inactive_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly season_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly status?: InputMaybe<Theme_Status_Bool_Exp>;
  readonly title?: InputMaybe<String_Comparison_Exp>;
  readonly topic?: InputMaybe<Topics_Bool_Exp>;
  readonly topic_id?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly votes?: InputMaybe<Votes_Bool_Exp>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "themes" */
export enum Themes_Constraint {
  /** unique or primary key constraint on columns "id" */
  ThemesPkey = 'themes_pkey'
}

/** input type for inserting data into table "themes" */
export type Themes_Insert_Input = {
  readonly active_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly background_image?: InputMaybe<Scalars['String']['input']>;
  readonly banner_title?: InputMaybe<Scalars['String']['input']>;
  readonly club?: InputMaybe<Clubs_Obj_Rel_Insert_Input>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly color?: InputMaybe<Scalars['String']['input']>;
  readonly comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  readonly competitions?: InputMaybe<Competitions_Arr_Rel_Insert_Input>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly feeds?: InputMaybe<Feeds_Arr_Rel_Insert_Input>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly image?: InputMaybe<Scalars['String']['input']>;
  readonly inactive_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly season_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly status?: InputMaybe<Theme_Status>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly topic?: InputMaybe<Topics_Obj_Rel_Insert_Input>;
  readonly topic_id?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly votes?: InputMaybe<Votes_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Themes_Max_Fields = {
  readonly __typename?: 'themes_max_fields';
  readonly active_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly background_image?: Maybe<Scalars['String']['output']>;
  readonly banner_title?: Maybe<Scalars['String']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly color?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly image?: Maybe<Scalars['String']['output']>;
  readonly inactive_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly season_id?: Maybe<Scalars['uuid']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly topic_id?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "themes" */
export type Themes_Max_Order_By = {
  readonly active_at?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly background_image?: InputMaybe<Order_By>;
  readonly banner_title?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly color?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly image?: InputMaybe<Order_By>;
  readonly inactive_at?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly season_id?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly title?: InputMaybe<Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Themes_Min_Fields = {
  readonly __typename?: 'themes_min_fields';
  readonly active_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly background_image?: Maybe<Scalars['String']['output']>;
  readonly banner_title?: Maybe<Scalars['String']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly color?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly image?: Maybe<Scalars['String']['output']>;
  readonly inactive_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly season_id?: Maybe<Scalars['uuid']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly topic_id?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "themes" */
export type Themes_Min_Order_By = {
  readonly active_at?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly background_image?: InputMaybe<Order_By>;
  readonly banner_title?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly color?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly image?: InputMaybe<Order_By>;
  readonly inactive_at?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly season_id?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly title?: InputMaybe<Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "themes" */
export type Themes_Mutation_Response = {
  readonly __typename?: 'themes_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Themes>;
};

/** input type for inserting object relation for remote table "themes" */
export type Themes_Obj_Rel_Insert_Input = {
  readonly data: Themes_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Themes_On_Conflict>;
};

/** on_conflict condition type for table "themes" */
export type Themes_On_Conflict = {
  readonly constraint: Themes_Constraint;
  readonly update_columns?: ReadonlyArray<Themes_Update_Column>;
  readonly where?: InputMaybe<Themes_Bool_Exp>;
};

/** Ordering options when selecting data from "themes". */
export type Themes_Order_By = {
  readonly active_at?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly background_image?: InputMaybe<Order_By>;
  readonly banner_title?: InputMaybe<Order_By>;
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly color?: InputMaybe<Order_By>;
  readonly comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  readonly competitions_aggregate?: InputMaybe<Competitions_Aggregate_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly feeds_aggregate?: InputMaybe<Feeds_Aggregate_Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly image?: InputMaybe<Order_By>;
  readonly inactive_at?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly season_id?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly title?: InputMaybe<Order_By>;
  readonly topic?: InputMaybe<Topics_Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly votes_aggregate?: InputMaybe<Votes_Aggregate_Order_By>;
};

/** primary key columns input for table: themes */
export type Themes_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "themes" */
export enum Themes_Select_Column {
  /** column name */
  ActiveAt = 'active_at',
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  BackgroundImage = 'background_image',
  /** column name */
  BannerTitle = 'banner_title',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  InactiveAt = 'inactive_at',
  /** column name */
  Name = 'name',
  /** column name */
  SeasonId = 'season_id',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "themes" */
export type Themes_Set_Input = {
  readonly active_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly background_image?: InputMaybe<Scalars['String']['input']>;
  readonly banner_title?: InputMaybe<Scalars['String']['input']>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly color?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly image?: InputMaybe<Scalars['String']['input']>;
  readonly inactive_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly season_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly status?: InputMaybe<Theme_Status>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly topic_id?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "themes" */
export type Themes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Themes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Themes_Stream_Cursor_Value_Input = {
  readonly active_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly background_image?: InputMaybe<Scalars['String']['input']>;
  readonly banner_title?: InputMaybe<Scalars['String']['input']>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly color?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly image?: InputMaybe<Scalars['String']['input']>;
  readonly inactive_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly season_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly topic_id?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "themes" */
export enum Themes_Update_Column {
  /** column name */
  ActiveAt = 'active_at',
  /** column name */
  BackgroundImage = 'background_image',
  /** column name */
  BannerTitle = 'banner_title',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  InactiveAt = 'inactive_at',
  /** column name */
  Name = 'name',
  /** column name */
  SeasonId = 'season_id',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Themes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Themes_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Themes_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  readonly _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['timestamptz']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['timestamptz']['input']>>;
};

export type Toggle_Host_User_Id_Args = {
  readonly room_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "tools" */
export type Tools = {
  readonly __typename?: 'tools';
  /** An array relationship */
  readonly competitions: ReadonlyArray<Competitions>;
  /** An aggregate relationship */
  readonly competitions_aggregate: Competitions_Aggregate;
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly photo: Scalars['String']['output'];
  readonly rubric_keys?: Maybe<Scalars['jsonb']['output']>;
  /** An array relationship */
  readonly topics: ReadonlyArray<Topics_Tools>;
  /** An aggregate relationship */
  readonly topics_aggregate: Topics_Tools_Aggregate;
};


/** columns and relationships of "tools" */
export type ToolsCompetitionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


/** columns and relationships of "tools" */
export type ToolsCompetitions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


/** columns and relationships of "tools" */
export type ToolsRubric_KeysArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "tools" */
export type ToolsTopicsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Topics_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Topics_Tools_Order_By>>;
  where?: InputMaybe<Topics_Tools_Bool_Exp>;
};


/** columns and relationships of "tools" */
export type ToolsTopics_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Topics_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Topics_Tools_Order_By>>;
  where?: InputMaybe<Topics_Tools_Bool_Exp>;
};

/** aggregated selection of "tools" */
export type Tools_Aggregate = {
  readonly __typename?: 'tools_aggregate';
  readonly aggregate?: Maybe<Tools_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Tools>;
};

/** aggregate fields of "tools" */
export type Tools_Aggregate_Fields = {
  readonly __typename?: 'tools_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Tools_Max_Fields>;
  readonly min?: Maybe<Tools_Min_Fields>;
};


/** aggregate fields of "tools" */
export type Tools_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Tools_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tools_Append_Input = {
  readonly rubric_keys?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "tools". All fields are combined with a logical 'AND'. */
export type Tools_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Tools_Bool_Exp>>;
  readonly _not?: InputMaybe<Tools_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Tools_Bool_Exp>>;
  readonly competitions?: InputMaybe<Competitions_Bool_Exp>;
  readonly competitions_aggregate?: InputMaybe<Competitions_Aggregate_Bool_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly photo?: InputMaybe<String_Comparison_Exp>;
  readonly rubric_keys?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly topics?: InputMaybe<Topics_Tools_Bool_Exp>;
  readonly topics_aggregate?: InputMaybe<Topics_Tools_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "tools" */
export enum Tools_Constraint {
  /** unique or primary key constraint on columns "name" */
  ToolsNameKey = 'tools_name_key',
  /** unique or primary key constraint on columns "id" */
  ToolsPkey = 'tools_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tools_Delete_At_Path_Input = {
  readonly rubric_keys?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tools_Delete_Elem_Input = {
  readonly rubric_keys?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tools_Delete_Key_Input = {
  readonly rubric_keys?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "tools" */
export type Tools_Insert_Input = {
  readonly competitions?: InputMaybe<Competitions_Arr_Rel_Insert_Input>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly rubric_keys?: InputMaybe<Scalars['jsonb']['input']>;
  readonly topics?: InputMaybe<Topics_Tools_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tools_Max_Fields = {
  readonly __typename?: 'tools_max_fields';
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly photo?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Tools_Min_Fields = {
  readonly __typename?: 'tools_min_fields';
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly photo?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "tools" */
export type Tools_Mutation_Response = {
  readonly __typename?: 'tools_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Tools>;
};

/** input type for inserting object relation for remote table "tools" */
export type Tools_Obj_Rel_Insert_Input = {
  readonly data: Tools_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Tools_On_Conflict>;
};

/** on_conflict condition type for table "tools" */
export type Tools_On_Conflict = {
  readonly constraint: Tools_Constraint;
  readonly update_columns?: ReadonlyArray<Tools_Update_Column>;
  readonly where?: InputMaybe<Tools_Bool_Exp>;
};

/** Ordering options when selecting data from "tools". */
export type Tools_Order_By = {
  readonly competitions_aggregate?: InputMaybe<Competitions_Aggregate_Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly rubric_keys?: InputMaybe<Order_By>;
  readonly topics_aggregate?: InputMaybe<Topics_Tools_Aggregate_Order_By>;
};

/** primary key columns input for table: tools */
export type Tools_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tools_Prepend_Input = {
  readonly rubric_keys?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tools" */
export enum Tools_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Photo = 'photo',
  /** column name */
  RubricKeys = 'rubric_keys'
}

/** input type for updating data in table "tools" */
export type Tools_Set_Input = {
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly rubric_keys?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "tools" */
export type Tools_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Tools_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tools_Stream_Cursor_Value_Input = {
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly rubric_keys?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "tools" */
export enum Tools_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Photo = 'photo',
  /** column name */
  RubricKeys = 'rubric_keys'
}

export type Tools_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Tools_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Tools_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Tools_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Tools_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Tools_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Tools_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Tools_Bool_Exp;
};

/** columns and relationships of "topics" */
export type Topics = {
  readonly __typename?: 'topics';
  /** An array relationship */
  readonly competitions: ReadonlyArray<Competitions>;
  /** An aggregate relationship */
  readonly competitions_aggregate: Competitions_Aggregate;
  readonly icon?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly photo: Scalars['String']['output'];
  /** An array relationship */
  readonly projects: ReadonlyArray<Projects>;
  /** An aggregate relationship */
  readonly projects_aggregate: Projects_Aggregate;
  /** An array relationship */
  readonly tools: ReadonlyArray<Topics_Tools>;
  /** An aggregate relationship */
  readonly tools_aggregate: Topics_Tools_Aggregate;
};


/** columns and relationships of "topics" */
export type TopicsCompetitionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


/** columns and relationships of "topics" */
export type TopicsCompetitions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


/** columns and relationships of "topics" */
export type TopicsProjectsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


/** columns and relationships of "topics" */
export type TopicsProjects_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


/** columns and relationships of "topics" */
export type TopicsToolsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Topics_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Topics_Tools_Order_By>>;
  where?: InputMaybe<Topics_Tools_Bool_Exp>;
};


/** columns and relationships of "topics" */
export type TopicsTools_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Topics_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Topics_Tools_Order_By>>;
  where?: InputMaybe<Topics_Tools_Bool_Exp>;
};

/** aggregated selection of "topics" */
export type Topics_Aggregate = {
  readonly __typename?: 'topics_aggregate';
  readonly aggregate?: Maybe<Topics_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Topics>;
};

/** aggregate fields of "topics" */
export type Topics_Aggregate_Fields = {
  readonly __typename?: 'topics_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Topics_Max_Fields>;
  readonly min?: Maybe<Topics_Min_Fields>;
};


/** aggregate fields of "topics" */
export type Topics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Topics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "topics". All fields are combined with a logical 'AND'. */
export type Topics_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Topics_Bool_Exp>>;
  readonly _not?: InputMaybe<Topics_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Topics_Bool_Exp>>;
  readonly competitions?: InputMaybe<Competitions_Bool_Exp>;
  readonly competitions_aggregate?: InputMaybe<Competitions_Aggregate_Bool_Exp>;
  readonly icon?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly photo?: InputMaybe<String_Comparison_Exp>;
  readonly projects?: InputMaybe<Projects_Bool_Exp>;
  readonly projects_aggregate?: InputMaybe<Projects_Aggregate_Bool_Exp>;
  readonly tools?: InputMaybe<Topics_Tools_Bool_Exp>;
  readonly tools_aggregate?: InputMaybe<Topics_Tools_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "topics" */
export enum Topics_Constraint {
  /** unique or primary key constraint on columns "id" */
  TopicsPkey = 'topics_pkey'
}

/** input type for inserting data into table "topics" */
export type Topics_Insert_Input = {
  readonly competitions?: InputMaybe<Competitions_Arr_Rel_Insert_Input>;
  readonly icon?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly projects?: InputMaybe<Projects_Arr_Rel_Insert_Input>;
  readonly tools?: InputMaybe<Topics_Tools_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Topics_Max_Fields = {
  readonly __typename?: 'topics_max_fields';
  readonly icon?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly photo?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Topics_Min_Fields = {
  readonly __typename?: 'topics_min_fields';
  readonly icon?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly photo?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "topics" */
export type Topics_Mutation_Response = {
  readonly __typename?: 'topics_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Topics>;
};

/** input type for inserting object relation for remote table "topics" */
export type Topics_Obj_Rel_Insert_Input = {
  readonly data: Topics_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Topics_On_Conflict>;
};

/** on_conflict condition type for table "topics" */
export type Topics_On_Conflict = {
  readonly constraint: Topics_Constraint;
  readonly update_columns?: ReadonlyArray<Topics_Update_Column>;
  readonly where?: InputMaybe<Topics_Bool_Exp>;
};

/** Ordering options when selecting data from "topics". */
export type Topics_Order_By = {
  readonly competitions_aggregate?: InputMaybe<Competitions_Aggregate_Order_By>;
  readonly icon?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly projects_aggregate?: InputMaybe<Projects_Aggregate_Order_By>;
  readonly tools_aggregate?: InputMaybe<Topics_Tools_Aggregate_Order_By>;
};

/** primary key columns input for table: topics */
export type Topics_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** select columns of table "topics" */
export enum Topics_Select_Column {
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Photo = 'photo'
}

/** input type for updating data in table "topics" */
export type Topics_Set_Input = {
  readonly icon?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "topics" */
export type Topics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Topics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Topics_Stream_Cursor_Value_Input = {
  readonly icon?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "topics_tools" */
export type Topics_Tools = {
  readonly __typename?: 'topics_tools';
  /** An object relationship */
  readonly tool: Tools;
  readonly tool_id: Scalars['String']['output'];
  /** An object relationship */
  readonly topic: Topics;
  readonly topic_id: Scalars['String']['output'];
};

/** aggregated selection of "topics_tools" */
export type Topics_Tools_Aggregate = {
  readonly __typename?: 'topics_tools_aggregate';
  readonly aggregate?: Maybe<Topics_Tools_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Topics_Tools>;
};

export type Topics_Tools_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Topics_Tools_Aggregate_Bool_Exp_Count>;
};

export type Topics_Tools_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Topics_Tools_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Topics_Tools_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "topics_tools" */
export type Topics_Tools_Aggregate_Fields = {
  readonly __typename?: 'topics_tools_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Topics_Tools_Max_Fields>;
  readonly min?: Maybe<Topics_Tools_Min_Fields>;
};


/** aggregate fields of "topics_tools" */
export type Topics_Tools_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Topics_Tools_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "topics_tools" */
export type Topics_Tools_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Topics_Tools_Max_Order_By>;
  readonly min?: InputMaybe<Topics_Tools_Min_Order_By>;
};

/** input type for inserting array relation for remote table "topics_tools" */
export type Topics_Tools_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Topics_Tools_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Topics_Tools_On_Conflict>;
};

/** Boolean expression to filter rows from the table "topics_tools". All fields are combined with a logical 'AND'. */
export type Topics_Tools_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Topics_Tools_Bool_Exp>>;
  readonly _not?: InputMaybe<Topics_Tools_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Topics_Tools_Bool_Exp>>;
  readonly tool?: InputMaybe<Tools_Bool_Exp>;
  readonly tool_id?: InputMaybe<String_Comparison_Exp>;
  readonly topic?: InputMaybe<Topics_Bool_Exp>;
  readonly topic_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "topics_tools" */
export enum Topics_Tools_Constraint {
  /** unique or primary key constraint on columns "topic_id", "tool_id" */
  TopicsToolsPkey = 'topics_tools_pkey'
}

/** input type for inserting data into table "topics_tools" */
export type Topics_Tools_Insert_Input = {
  readonly tool?: InputMaybe<Tools_Obj_Rel_Insert_Input>;
  readonly tool_id?: InputMaybe<Scalars['String']['input']>;
  readonly topic?: InputMaybe<Topics_Obj_Rel_Insert_Input>;
  readonly topic_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Topics_Tools_Max_Fields = {
  readonly __typename?: 'topics_tools_max_fields';
  readonly tool_id?: Maybe<Scalars['String']['output']>;
  readonly topic_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "topics_tools" */
export type Topics_Tools_Max_Order_By = {
  readonly tool_id?: InputMaybe<Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Topics_Tools_Min_Fields = {
  readonly __typename?: 'topics_tools_min_fields';
  readonly tool_id?: Maybe<Scalars['String']['output']>;
  readonly topic_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "topics_tools" */
export type Topics_Tools_Min_Order_By = {
  readonly tool_id?: InputMaybe<Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "topics_tools" */
export type Topics_Tools_Mutation_Response = {
  readonly __typename?: 'topics_tools_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Topics_Tools>;
};

/** on_conflict condition type for table "topics_tools" */
export type Topics_Tools_On_Conflict = {
  readonly constraint: Topics_Tools_Constraint;
  readonly update_columns?: ReadonlyArray<Topics_Tools_Update_Column>;
  readonly where?: InputMaybe<Topics_Tools_Bool_Exp>;
};

/** Ordering options when selecting data from "topics_tools". */
export type Topics_Tools_Order_By = {
  readonly tool?: InputMaybe<Tools_Order_By>;
  readonly tool_id?: InputMaybe<Order_By>;
  readonly topic?: InputMaybe<Topics_Order_By>;
  readonly topic_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: topics_tools */
export type Topics_Tools_Pk_Columns_Input = {
  readonly tool_id: Scalars['String']['input'];
  readonly topic_id: Scalars['String']['input'];
};

/** select columns of table "topics_tools" */
export enum Topics_Tools_Select_Column {
  /** column name */
  ToolId = 'tool_id',
  /** column name */
  TopicId = 'topic_id'
}

/** input type for updating data in table "topics_tools" */
export type Topics_Tools_Set_Input = {
  readonly tool_id?: InputMaybe<Scalars['String']['input']>;
  readonly topic_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "topics_tools" */
export type Topics_Tools_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Topics_Tools_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Topics_Tools_Stream_Cursor_Value_Input = {
  readonly tool_id?: InputMaybe<Scalars['String']['input']>;
  readonly topic_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "topics_tools" */
export enum Topics_Tools_Update_Column {
  /** column name */
  ToolId = 'tool_id',
  /** column name */
  TopicId = 'topic_id'
}

export type Topics_Tools_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Topics_Tools_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Topics_Tools_Bool_Exp;
};

/** update columns of table "topics" */
export enum Topics_Update_Column {
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Photo = 'photo'
}

export type Topics_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Topics_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Topics_Bool_Exp;
};

export enum Transaction_Kind {
  Money = 'money',
  Xp = 'xp'
}

export type Transaction_Kind_Bool_Exp = {
  readonly _eq?: InputMaybe<Transaction_Kind>;
  readonly _in?: InputMaybe<ReadonlyArray<Transaction_Kind>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Transaction_Kind>;
  readonly _nin?: InputMaybe<ReadonlyArray<Transaction_Kind>>;
};

/** columns and relationships of "transactions" */
export type Transactions = {
  readonly __typename?: 'transactions';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly competition?: Maybe<Competitions>;
  readonly competition_id?: Maybe<Scalars['String']['output']>;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly description: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  /** An object relationship */
  readonly item?: Maybe<Items>;
  readonly item_id?: Maybe<Scalars['String']['output']>;
  readonly kind: Transaction_Kind;
  /** An object relationship */
  readonly order?: Maybe<Orders>;
  readonly order_id?: Maybe<Scalars['String']['output']>;
  readonly payload?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  readonly project?: Maybe<Projects>;
  readonly quantity: Scalars['numeric']['output'];
  /** An object relationship */
  readonly recipient: Users;
  readonly recipient_id: Scalars['String']['output'];
  readonly team_id?: Maybe<Scalars['String']['output']>;
  readonly type: Scalars['String']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "transactions" */
export type TransactionsPayloadArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "transactions" */
export type Transactions_Aggregate = {
  readonly __typename?: 'transactions_aggregate';
  readonly aggregate?: Maybe<Transactions_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Transactions>;
};

export type Transactions_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Transactions_Aggregate_Bool_Exp_Count>;
};

export type Transactions_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Transactions_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "transactions" */
export type Transactions_Aggregate_Fields = {
  readonly __typename?: 'transactions_aggregate_fields';
  readonly avg?: Maybe<Transactions_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Transactions_Max_Fields>;
  readonly min?: Maybe<Transactions_Min_Fields>;
  readonly stddev?: Maybe<Transactions_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Transactions_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Transactions_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Transactions_Sum_Fields>;
  readonly var_pop?: Maybe<Transactions_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Transactions_Var_Samp_Fields>;
  readonly variance?: Maybe<Transactions_Variance_Fields>;
};


/** aggregate fields of "transactions" */
export type Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "transactions" */
export type Transactions_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Transactions_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Transactions_Max_Order_By>;
  readonly min?: InputMaybe<Transactions_Min_Order_By>;
  readonly stddev?: InputMaybe<Transactions_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Transactions_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Transactions_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Transactions_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Transactions_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Transactions_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Transactions_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Transactions_Append_Input = {
  readonly payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "transactions" */
export type Transactions_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Transactions_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Transactions_On_Conflict>;
};

/** aggregate avg on columns */
export type Transactions_Avg_Fields = {
  readonly __typename?: 'transactions_avg_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "transactions" */
export type Transactions_Avg_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "transactions". All fields are combined with a logical 'AND'. */
export type Transactions_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Transactions_Bool_Exp>>;
  readonly _not?: InputMaybe<Transactions_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Transactions_Bool_Exp>>;
  readonly associated_object?: InputMaybe<String_Comparison_Exp>;
  readonly competition?: InputMaybe<Competitions_Bool_Exp>;
  readonly competition_id?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly description?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly item?: InputMaybe<Items_Bool_Exp>;
  readonly item_id?: InputMaybe<String_Comparison_Exp>;
  readonly kind?: InputMaybe<Transaction_Kind_Bool_Exp>;
  readonly order?: InputMaybe<Orders_Bool_Exp>;
  readonly order_id?: InputMaybe<String_Comparison_Exp>;
  readonly payload?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly project?: InputMaybe<Projects_Bool_Exp>;
  readonly quantity?: InputMaybe<Numeric_Comparison_Exp>;
  readonly recipient?: InputMaybe<Users_Bool_Exp>;
  readonly recipient_id?: InputMaybe<String_Comparison_Exp>;
  readonly team_id?: InputMaybe<String_Comparison_Exp>;
  readonly type?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "transactions" */
export enum Transactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  TransactionLogPkey = 'transaction_log_pkey',
  /** unique or primary key constraint on columns "kind", "recipient_id", "associated_object", "type" */
  TransactionsTypeAssociatedObjectRecipientIdKindKey = 'transactions_type_associated_object_recipient_id_kind_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Transactions_Delete_At_Path_Input = {
  readonly payload?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Transactions_Delete_Elem_Input = {
  readonly payload?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Transactions_Delete_Key_Input = {
  readonly payload?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "transactions" */
export type Transactions_Inc_Input = {
  readonly quantity?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "transactions" */
export type Transactions_Insert_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly competition?: InputMaybe<Competitions_Obj_Rel_Insert_Input>;
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly item?: InputMaybe<Items_Obj_Rel_Insert_Input>;
  readonly item_id?: InputMaybe<Scalars['String']['input']>;
  readonly kind?: InputMaybe<Transaction_Kind>;
  readonly order?: InputMaybe<Orders_Obj_Rel_Insert_Input>;
  readonly order_id?: InputMaybe<Scalars['String']['input']>;
  readonly payload?: InputMaybe<Scalars['jsonb']['input']>;
  readonly project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  readonly quantity?: InputMaybe<Scalars['numeric']['input']>;
  readonly recipient?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly recipient_id?: InputMaybe<Scalars['String']['input']>;
  readonly team_id?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Transactions_Max_Fields = {
  readonly __typename?: 'transactions_max_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly competition_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly item_id?: Maybe<Scalars['String']['output']>;
  readonly kind?: Maybe<Scalars['String']['output']>;
  readonly order_id?: Maybe<Scalars['String']['output']>;
  readonly quantity?: Maybe<Scalars['numeric']['output']>;
  readonly recipient_id?: Maybe<Scalars['String']['output']>;
  readonly team_id?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "transactions" */
export type Transactions_Max_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly competition_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly item_id?: InputMaybe<Order_By>;
  readonly kind?: InputMaybe<Order_By>;
  readonly order_id?: InputMaybe<Order_By>;
  readonly quantity?: InputMaybe<Order_By>;
  readonly recipient_id?: InputMaybe<Order_By>;
  readonly team_id?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Transactions_Min_Fields = {
  readonly __typename?: 'transactions_min_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly competition_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly item_id?: Maybe<Scalars['String']['output']>;
  readonly kind?: Maybe<Scalars['String']['output']>;
  readonly order_id?: Maybe<Scalars['String']['output']>;
  readonly quantity?: Maybe<Scalars['numeric']['output']>;
  readonly recipient_id?: Maybe<Scalars['String']['output']>;
  readonly team_id?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "transactions" */
export type Transactions_Min_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly competition_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly item_id?: InputMaybe<Order_By>;
  readonly kind?: InputMaybe<Order_By>;
  readonly order_id?: InputMaybe<Order_By>;
  readonly quantity?: InputMaybe<Order_By>;
  readonly recipient_id?: InputMaybe<Order_By>;
  readonly team_id?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "transactions" */
export type Transactions_Mutation_Response = {
  readonly __typename?: 'transactions_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Transactions>;
};

/** input type for inserting object relation for remote table "transactions" */
export type Transactions_Obj_Rel_Insert_Input = {
  readonly data: Transactions_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Transactions_On_Conflict>;
};

/** on_conflict condition type for table "transactions" */
export type Transactions_On_Conflict = {
  readonly constraint: Transactions_Constraint;
  readonly update_columns?: ReadonlyArray<Transactions_Update_Column>;
  readonly where?: InputMaybe<Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "transactions". */
export type Transactions_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly competition?: InputMaybe<Competitions_Order_By>;
  readonly competition_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly item?: InputMaybe<Items_Order_By>;
  readonly item_id?: InputMaybe<Order_By>;
  readonly kind?: InputMaybe<Order_By>;
  readonly order?: InputMaybe<Orders_Order_By>;
  readonly order_id?: InputMaybe<Order_By>;
  readonly payload?: InputMaybe<Order_By>;
  readonly project?: InputMaybe<Projects_Order_By>;
  readonly quantity?: InputMaybe<Order_By>;
  readonly recipient?: InputMaybe<Users_Order_By>;
  readonly recipient_id?: InputMaybe<Order_By>;
  readonly team_id?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: transactions */
export type Transactions_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Transactions_Prepend_Input = {
  readonly payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "transactions" */
export enum Transactions_Select_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  Kind = 'kind',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RecipientId = 'recipient_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "transactions" */
export type Transactions_Set_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly item_id?: InputMaybe<Scalars['String']['input']>;
  readonly kind?: InputMaybe<Transaction_Kind>;
  readonly order_id?: InputMaybe<Scalars['String']['input']>;
  readonly payload?: InputMaybe<Scalars['jsonb']['input']>;
  readonly quantity?: InputMaybe<Scalars['numeric']['input']>;
  readonly recipient_id?: InputMaybe<Scalars['String']['input']>;
  readonly team_id?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Transactions_Stddev_Fields = {
  readonly __typename?: 'transactions_stddev_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "transactions" */
export type Transactions_Stddev_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Transactions_Stddev_Pop_Fields = {
  readonly __typename?: 'transactions_stddev_pop_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "transactions" */
export type Transactions_Stddev_Pop_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Transactions_Stddev_Samp_Fields = {
  readonly __typename?: 'transactions_stddev_samp_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "transactions" */
export type Transactions_Stddev_Samp_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "transactions" */
export type Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Transactions_Stream_Cursor_Value_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly competition_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly item_id?: InputMaybe<Scalars['String']['input']>;
  readonly kind?: InputMaybe<Scalars['String']['input']>;
  readonly order_id?: InputMaybe<Scalars['String']['input']>;
  readonly payload?: InputMaybe<Scalars['jsonb']['input']>;
  readonly quantity?: InputMaybe<Scalars['numeric']['input']>;
  readonly recipient_id?: InputMaybe<Scalars['String']['input']>;
  readonly team_id?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Transactions_Sum_Fields = {
  readonly __typename?: 'transactions_sum_fields';
  readonly quantity?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "transactions" */
export type Transactions_Sum_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** update columns of table "transactions" */
export enum Transactions_Update_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  Kind = 'kind',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RecipientId = 'recipient_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Transactions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Transactions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Transactions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Transactions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Transactions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Transactions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Transactions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Transactions_Var_Pop_Fields = {
  readonly __typename?: 'transactions_var_pop_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "transactions" */
export type Transactions_Var_Pop_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Transactions_Var_Samp_Fields = {
  readonly __typename?: 'transactions_var_samp_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "transactions" */
export type Transactions_Var_Samp_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Transactions_Variance_Fields = {
  readonly __typename?: 'transactions_variance_fields';
  readonly quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "transactions" */
export type Transactions_Variance_Order_By = {
  readonly quantity?: InputMaybe<Order_By>;
};

export enum Tutorial_Difficulty {
  Advanced = 'advanced',
  Beginner = 'beginner',
  Intermediate = 'intermediate'
}

export type Tutorial_Difficulty_Bool_Exp = {
  readonly _eq?: InputMaybe<Tutorial_Difficulty>;
  readonly _in?: InputMaybe<ReadonlyArray<Tutorial_Difficulty>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Tutorial_Difficulty>;
  readonly _nin?: InputMaybe<ReadonlyArray<Tutorial_Difficulty>>;
};

/** columns and relationships of "tutorials" */
export type Tutorials = {
  readonly __typename?: 'tutorials';
  readonly association_id: Scalars['String']['output'];
  /** An object relationship */
  readonly club?: Maybe<Clubs>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  readonly competitions: ReadonlyArray<Competitions_Tutorials>;
  /** An aggregate relationship */
  readonly competitions_aggregate: Competitions_Tutorials_Aggregate;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly description: Scalars['String']['output'];
  readonly difficulty: Tutorial_Difficulty;
  readonly id: Scalars['String']['output'];
  readonly link: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly order: Scalars['Int']['output'];
  readonly photo: Scalars['String']['output'];
  /** An array relationship */
  readonly tags: ReadonlyArray<Tutorials_Tags>;
  /** An aggregate relationship */
  readonly tags_aggregate: Tutorials_Tags_Aggregate;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "tutorials" */
export type TutorialsCompetitionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Order_By>>;
  where?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
};


/** columns and relationships of "tutorials" */
export type TutorialsCompetitions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Tutorials_Order_By>>;
  where?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
};


/** columns and relationships of "tutorials" */
export type TutorialsTagsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Tags_Order_By>>;
  where?: InputMaybe<Tutorials_Tags_Bool_Exp>;
};


/** columns and relationships of "tutorials" */
export type TutorialsTags_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Tutorials_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Tutorials_Tags_Order_By>>;
  where?: InputMaybe<Tutorials_Tags_Bool_Exp>;
};

/** aggregated selection of "tutorials" */
export type Tutorials_Aggregate = {
  readonly __typename?: 'tutorials_aggregate';
  readonly aggregate?: Maybe<Tutorials_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Tutorials>;
};

export type Tutorials_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Tutorials_Aggregate_Bool_Exp_Count>;
};

export type Tutorials_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Tutorials_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Tutorials_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tutorials" */
export type Tutorials_Aggregate_Fields = {
  readonly __typename?: 'tutorials_aggregate_fields';
  readonly avg?: Maybe<Tutorials_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Tutorials_Max_Fields>;
  readonly min?: Maybe<Tutorials_Min_Fields>;
  readonly stddev?: Maybe<Tutorials_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Tutorials_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Tutorials_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Tutorials_Sum_Fields>;
  readonly var_pop?: Maybe<Tutorials_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Tutorials_Var_Samp_Fields>;
  readonly variance?: Maybe<Tutorials_Variance_Fields>;
};


/** aggregate fields of "tutorials" */
export type Tutorials_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Tutorials_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tutorials" */
export type Tutorials_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Tutorials_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Tutorials_Max_Order_By>;
  readonly min?: InputMaybe<Tutorials_Min_Order_By>;
  readonly stddev?: InputMaybe<Tutorials_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Tutorials_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Tutorials_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Tutorials_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Tutorials_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Tutorials_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Tutorials_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tutorials" */
export type Tutorials_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Tutorials_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Tutorials_On_Conflict>;
};

/** aggregate avg on columns */
export type Tutorials_Avg_Fields = {
  readonly __typename?: 'tutorials_avg_fields';
  readonly order?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "tutorials" */
export type Tutorials_Avg_Order_By = {
  readonly order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tutorials". All fields are combined with a logical 'AND'. */
export type Tutorials_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Tutorials_Bool_Exp>>;
  readonly _not?: InputMaybe<Tutorials_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Tutorials_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly club?: InputMaybe<Clubs_Bool_Exp>;
  readonly club_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly competitions?: InputMaybe<Competitions_Tutorials_Bool_Exp>;
  readonly competitions_aggregate?: InputMaybe<Competitions_Tutorials_Aggregate_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly description?: InputMaybe<String_Comparison_Exp>;
  readonly difficulty?: InputMaybe<Tutorial_Difficulty_Bool_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly link?: InputMaybe<String_Comparison_Exp>;
  readonly name?: InputMaybe<String_Comparison_Exp>;
  readonly order?: InputMaybe<Int_Comparison_Exp>;
  readonly photo?: InputMaybe<String_Comparison_Exp>;
  readonly tags?: InputMaybe<Tutorials_Tags_Bool_Exp>;
  readonly tags_aggregate?: InputMaybe<Tutorials_Tags_Aggregate_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tutorials" */
export enum Tutorials_Constraint {
  /** unique or primary key constraint on columns "name" */
  TutorialsNameKey = 'tutorials_name_key',
  /** unique or primary key constraint on columns "id" */
  TutorialsPkey = 'tutorials_pkey'
}

/** input type for incrementing numeric columns in table "tutorials" */
export type Tutorials_Inc_Input = {
  readonly order?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "tutorials" */
export type Tutorials_Insert_Input = {
  readonly club?: InputMaybe<Clubs_Obj_Rel_Insert_Input>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly competitions?: InputMaybe<Competitions_Tutorials_Arr_Rel_Insert_Input>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly difficulty?: InputMaybe<Tutorial_Difficulty>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly link?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly order?: InputMaybe<Scalars['Int']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly tags?: InputMaybe<Tutorials_Tags_Arr_Rel_Insert_Input>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Tutorials_Max_Fields = {
  readonly __typename?: 'tutorials_max_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly difficulty?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly link?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly order?: Maybe<Scalars['Int']['output']>;
  readonly photo?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "tutorials" */
export type Tutorials_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly difficulty?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly link?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly order?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tutorials_Min_Fields = {
  readonly __typename?: 'tutorials_min_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly club_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly difficulty?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly link?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly order?: Maybe<Scalars['Int']['output']>;
  readonly photo?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "tutorials" */
export type Tutorials_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly difficulty?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly link?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly order?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tutorials" */
export type Tutorials_Mutation_Response = {
  readonly __typename?: 'tutorials_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Tutorials>;
};

/** input type for inserting object relation for remote table "tutorials" */
export type Tutorials_Obj_Rel_Insert_Input = {
  readonly data: Tutorials_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Tutorials_On_Conflict>;
};

/** on_conflict condition type for table "tutorials" */
export type Tutorials_On_Conflict = {
  readonly constraint: Tutorials_Constraint;
  readonly update_columns?: ReadonlyArray<Tutorials_Update_Column>;
  readonly where?: InputMaybe<Tutorials_Bool_Exp>;
};

/** Ordering options when selecting data from "tutorials". */
export type Tutorials_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly club?: InputMaybe<Clubs_Order_By>;
  readonly club_id?: InputMaybe<Order_By>;
  readonly competitions_aggregate?: InputMaybe<Competitions_Tutorials_Aggregate_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly description?: InputMaybe<Order_By>;
  readonly difficulty?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly link?: InputMaybe<Order_By>;
  readonly name?: InputMaybe<Order_By>;
  readonly order?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly tags_aggregate?: InputMaybe<Tutorials_Tags_Aggregate_Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tutorials */
export type Tutorials_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** select columns of table "tutorials" */
export enum Tutorials_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Difficulty = 'difficulty',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Photo = 'photo',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tutorials" */
export type Tutorials_Set_Input = {
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly difficulty?: InputMaybe<Tutorial_Difficulty>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly link?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly order?: InputMaybe<Scalars['Int']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Tutorials_Stddev_Fields = {
  readonly __typename?: 'tutorials_stddev_fields';
  readonly order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "tutorials" */
export type Tutorials_Stddev_Order_By = {
  readonly order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tutorials_Stddev_Pop_Fields = {
  readonly __typename?: 'tutorials_stddev_pop_fields';
  readonly order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "tutorials" */
export type Tutorials_Stddev_Pop_Order_By = {
  readonly order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tutorials_Stddev_Samp_Fields = {
  readonly __typename?: 'tutorials_stddev_samp_fields';
  readonly order?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "tutorials" */
export type Tutorials_Stddev_Samp_Order_By = {
  readonly order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tutorials" */
export type Tutorials_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Tutorials_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tutorials_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly club_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly difficulty?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly link?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly order?: InputMaybe<Scalars['Int']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Tutorials_Sum_Fields = {
  readonly __typename?: 'tutorials_sum_fields';
  readonly order?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "tutorials" */
export type Tutorials_Sum_Order_By = {
  readonly order?: InputMaybe<Order_By>;
};

/** columns and relationships of "tutorials_tags" */
export type Tutorials_Tags = {
  readonly __typename?: 'tutorials_tags';
  /** An object relationship */
  readonly tag: Tags;
  readonly tag_id: Scalars['String']['output'];
  /** An object relationship */
  readonly tutorial: Tutorials;
  readonly tutorial_id: Scalars['String']['output'];
};

/** aggregated selection of "tutorials_tags" */
export type Tutorials_Tags_Aggregate = {
  readonly __typename?: 'tutorials_tags_aggregate';
  readonly aggregate?: Maybe<Tutorials_Tags_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Tutorials_Tags>;
};

export type Tutorials_Tags_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Tutorials_Tags_Aggregate_Bool_Exp_Count>;
};

export type Tutorials_Tags_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Tutorials_Tags_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Tutorials_Tags_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tutorials_tags" */
export type Tutorials_Tags_Aggregate_Fields = {
  readonly __typename?: 'tutorials_tags_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Tutorials_Tags_Max_Fields>;
  readonly min?: Maybe<Tutorials_Tags_Min_Fields>;
};


/** aggregate fields of "tutorials_tags" */
export type Tutorials_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Tutorials_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tutorials_tags" */
export type Tutorials_Tags_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Tutorials_Tags_Max_Order_By>;
  readonly min?: InputMaybe<Tutorials_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tutorials_tags" */
export type Tutorials_Tags_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Tutorials_Tags_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Tutorials_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tutorials_tags". All fields are combined with a logical 'AND'. */
export type Tutorials_Tags_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Tutorials_Tags_Bool_Exp>>;
  readonly _not?: InputMaybe<Tutorials_Tags_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Tutorials_Tags_Bool_Exp>>;
  readonly tag?: InputMaybe<Tags_Bool_Exp>;
  readonly tag_id?: InputMaybe<String_Comparison_Exp>;
  readonly tutorial?: InputMaybe<Tutorials_Bool_Exp>;
  readonly tutorial_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tutorials_tags" */
export enum Tutorials_Tags_Constraint {
  /** unique or primary key constraint on columns "tutorial_id", "tag_id" */
  TutorialsTagsPkey = 'tutorials_tags_pkey'
}

/** input type for inserting data into table "tutorials_tags" */
export type Tutorials_Tags_Insert_Input = {
  readonly tag?: InputMaybe<Tags_Obj_Rel_Insert_Input>;
  readonly tag_id?: InputMaybe<Scalars['String']['input']>;
  readonly tutorial?: InputMaybe<Tutorials_Obj_Rel_Insert_Input>;
  readonly tutorial_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Tutorials_Tags_Max_Fields = {
  readonly __typename?: 'tutorials_tags_max_fields';
  readonly tag_id?: Maybe<Scalars['String']['output']>;
  readonly tutorial_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tutorials_tags" */
export type Tutorials_Tags_Max_Order_By = {
  readonly tag_id?: InputMaybe<Order_By>;
  readonly tutorial_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tutorials_Tags_Min_Fields = {
  readonly __typename?: 'tutorials_tags_min_fields';
  readonly tag_id?: Maybe<Scalars['String']['output']>;
  readonly tutorial_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tutorials_tags" */
export type Tutorials_Tags_Min_Order_By = {
  readonly tag_id?: InputMaybe<Order_By>;
  readonly tutorial_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tutorials_tags" */
export type Tutorials_Tags_Mutation_Response = {
  readonly __typename?: 'tutorials_tags_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Tutorials_Tags>;
};

/** on_conflict condition type for table "tutorials_tags" */
export type Tutorials_Tags_On_Conflict = {
  readonly constraint: Tutorials_Tags_Constraint;
  readonly update_columns?: ReadonlyArray<Tutorials_Tags_Update_Column>;
  readonly where?: InputMaybe<Tutorials_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "tutorials_tags". */
export type Tutorials_Tags_Order_By = {
  readonly tag?: InputMaybe<Tags_Order_By>;
  readonly tag_id?: InputMaybe<Order_By>;
  readonly tutorial?: InputMaybe<Tutorials_Order_By>;
  readonly tutorial_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tutorials_tags */
export type Tutorials_Tags_Pk_Columns_Input = {
  readonly tag_id: Scalars['String']['input'];
  readonly tutorial_id: Scalars['String']['input'];
};

/** select columns of table "tutorials_tags" */
export enum Tutorials_Tags_Select_Column {
  /** column name */
  TagId = 'tag_id',
  /** column name */
  TutorialId = 'tutorial_id'
}

/** input type for updating data in table "tutorials_tags" */
export type Tutorials_Tags_Set_Input = {
  readonly tag_id?: InputMaybe<Scalars['String']['input']>;
  readonly tutorial_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tutorials_tags" */
export type Tutorials_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Tutorials_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tutorials_Tags_Stream_Cursor_Value_Input = {
  readonly tag_id?: InputMaybe<Scalars['String']['input']>;
  readonly tutorial_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tutorials_tags" */
export enum Tutorials_Tags_Update_Column {
  /** column name */
  TagId = 'tag_id',
  /** column name */
  TutorialId = 'tutorial_id'
}

export type Tutorials_Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Tutorials_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Tutorials_Tags_Bool_Exp;
};

/** update columns of table "tutorials" */
export enum Tutorials_Update_Column {
  /** column name */
  ClubId = 'club_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Difficulty = 'difficulty',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Photo = 'photo',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Tutorials_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Tutorials_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Tutorials_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Tutorials_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tutorials_Var_Pop_Fields = {
  readonly __typename?: 'tutorials_var_pop_fields';
  readonly order?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "tutorials" */
export type Tutorials_Var_Pop_Order_By = {
  readonly order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tutorials_Var_Samp_Fields = {
  readonly __typename?: 'tutorials_var_samp_fields';
  readonly order?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "tutorials" */
export type Tutorials_Var_Samp_Order_By = {
  readonly order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tutorials_Variance_Fields = {
  readonly __typename?: 'tutorials_variance_fields';
  readonly order?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "tutorials" */
export type Tutorials_Variance_Order_By = {
  readonly order?: InputMaybe<Order_By>;
};

export type UnlinkDiscordOutput = {
  readonly __typename?: 'unlinkDiscordOutput';
  readonly message: Scalars['String']['output'];
};

export type UnlinkXboxOutput = {
  readonly __typename?: 'unlinkXboxOutput';
  readonly message: Scalars['String']['output'];
};

export type Update_Channel_Mute_Enabled_Args = {
  readonly channel_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly cooldown?: InputMaybe<Scalars['Int']['input']>;
  readonly mute_enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Update_Club_Schedule_Lock_Unlock_Times_Args = {
  readonly generic_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly is_schedule_id?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Update_Club_Status_Args = {
  readonly _id?: InputMaybe<Scalars['uuid']['input']>;
  readonly _now?: InputMaybe<Scalars['timestamptz']['input']>;
};

export type Update_Live_Events_Schedule_Args = {
  readonly is_schedule_id?: InputMaybe<Scalars['Boolean']['input']>;
  readonly live_event_or_schedule_id?: InputMaybe<Scalars['uuid']['input']>;
};

export type Update_Room_Schedule_Lock_Unlock_Times_Args = {
  readonly generic_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly is_schedule_id?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Update_Room_Status_Args = {
  readonly _id?: InputMaybe<Scalars['uuid']['input']>;
  readonly _now?: InputMaybe<Scalars['timestamptz']['input']>;
};

export type Update_User_Presence_Delete_Platform_State_Keys_Args = {
  readonly _platform?: InputMaybe<Scalars['String']['input']>;
  readonly _state_keys?: InputMaybe<Scalars['String']['input']>;
  readonly _user_id?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatedData = {
  readonly __typename?: 'updatedData';
  readonly data?: Maybe<ReadonlyArray<Maybe<Scalars['jsonb']['output']>>>;
  readonly message?: Maybe<Scalars['String']['output']>;
};

export type Upsert_User_Action_Args = {
  readonly _action?: InputMaybe<Scalars['jsonb']['input']>;
  readonly _issuer?: InputMaybe<Scalars['String']['input']>;
  readonly _key?: InputMaybe<Scalars['String']['input']>;
  readonly _userid?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "user_collected_avatars" */
export type User_Collected_Avatars = {
  readonly __typename?: 'user_collected_avatars';
  /** An object relationship */
  readonly avatar: Avatars;
  readonly avatar_id: Scalars['uuid']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};

/** aggregated selection of "user_collected_avatars" */
export type User_Collected_Avatars_Aggregate = {
  readonly __typename?: 'user_collected_avatars_aggregate';
  readonly aggregate?: Maybe<User_Collected_Avatars_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<User_Collected_Avatars>;
};

export type User_Collected_Avatars_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<User_Collected_Avatars_Aggregate_Bool_Exp_Count>;
};

export type User_Collected_Avatars_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<User_Collected_Avatars_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<User_Collected_Avatars_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_collected_avatars" */
export type User_Collected_Avatars_Aggregate_Fields = {
  readonly __typename?: 'user_collected_avatars_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<User_Collected_Avatars_Max_Fields>;
  readonly min?: Maybe<User_Collected_Avatars_Min_Fields>;
};


/** aggregate fields of "user_collected_avatars" */
export type User_Collected_Avatars_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<User_Collected_Avatars_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_collected_avatars" */
export type User_Collected_Avatars_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<User_Collected_Avatars_Max_Order_By>;
  readonly min?: InputMaybe<User_Collected_Avatars_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_collected_avatars" */
export type User_Collected_Avatars_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<User_Collected_Avatars_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<User_Collected_Avatars_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_collected_avatars". All fields are combined with a logical 'AND'. */
export type User_Collected_Avatars_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<User_Collected_Avatars_Bool_Exp>>;
  readonly _not?: InputMaybe<User_Collected_Avatars_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<User_Collected_Avatars_Bool_Exp>>;
  readonly avatar?: InputMaybe<Avatars_Bool_Exp>;
  readonly avatar_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_collected_avatars" */
export enum User_Collected_Avatars_Constraint {
  /** unique or primary key constraint on columns "user_id", "avatar_id" */
  UserCollectedAvatarsPkey = 'user_collected_avatars_pkey'
}

/** input type for inserting data into table "user_collected_avatars" */
export type User_Collected_Avatars_Insert_Input = {
  readonly avatar?: InputMaybe<Avatars_Obj_Rel_Insert_Input>;
  readonly avatar_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Collected_Avatars_Max_Fields = {
  readonly __typename?: 'user_collected_avatars_max_fields';
  readonly avatar_id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "user_collected_avatars" */
export type User_Collected_Avatars_Max_Order_By = {
  readonly avatar_id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Collected_Avatars_Min_Fields = {
  readonly __typename?: 'user_collected_avatars_min_fields';
  readonly avatar_id?: Maybe<Scalars['uuid']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "user_collected_avatars" */
export type User_Collected_Avatars_Min_Order_By = {
  readonly avatar_id?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_collected_avatars" */
export type User_Collected_Avatars_Mutation_Response = {
  readonly __typename?: 'user_collected_avatars_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<User_Collected_Avatars>;
};

/** on_conflict condition type for table "user_collected_avatars" */
export type User_Collected_Avatars_On_Conflict = {
  readonly constraint: User_Collected_Avatars_Constraint;
  readonly update_columns?: ReadonlyArray<User_Collected_Avatars_Update_Column>;
  readonly where?: InputMaybe<User_Collected_Avatars_Bool_Exp>;
};

/** Ordering options when selecting data from "user_collected_avatars". */
export type User_Collected_Avatars_Order_By = {
  readonly avatar?: InputMaybe<Avatars_Order_By>;
  readonly avatar_id?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_collected_avatars */
export type User_Collected_Avatars_Pk_Columns_Input = {
  readonly avatar_id: Scalars['uuid']['input'];
  readonly user_id: Scalars['String']['input'];
};

/** select columns of table "user_collected_avatars" */
export enum User_Collected_Avatars_Select_Column {
  /** column name */
  AvatarId = 'avatar_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_collected_avatars" */
export type User_Collected_Avatars_Set_Input = {
  readonly avatar_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_collected_avatars" */
export type User_Collected_Avatars_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: User_Collected_Avatars_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Collected_Avatars_Stream_Cursor_Value_Input = {
  readonly avatar_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_collected_avatars" */
export enum User_Collected_Avatars_Update_Column {
  /** column name */
  AvatarId = 'avatar_id',
  /** column name */
  UserId = 'user_id'
}

export type User_Collected_Avatars_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<User_Collected_Avatars_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: User_Collected_Avatars_Bool_Exp;
};

export enum User_Division {
  Beginner = 'beginner',
  Intermediate = 'intermediate',
  Open = 'open'
}

export type User_Division_Bool_Exp = {
  readonly _eq?: InputMaybe<User_Division>;
  readonly _in?: InputMaybe<ReadonlyArray<User_Division>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<User_Division>;
  readonly _nin?: InputMaybe<ReadonlyArray<User_Division>>;
};

export enum User_Invite_Kind {
  Platform = 'platform',
  Team = 'team'
}

export type User_Invite_Kind_Bool_Exp = {
  readonly _eq?: InputMaybe<User_Invite_Kind>;
  readonly _in?: InputMaybe<ReadonlyArray<User_Invite_Kind>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<User_Invite_Kind>;
  readonly _nin?: InputMaybe<ReadonlyArray<User_Invite_Kind>>;
};

export enum User_Invite_Status {
  Accepted = 'accepted',
  Autoaccepted = 'autoaccepted',
  Awarded = 'awarded',
  Declined = 'declined',
  Deleted = 'deleted',
  Expired = 'expired',
  Invited = 'invited',
  Joined = 'joined',
  Signedup = 'signedup'
}

export type User_Invite_Status_Bool_Exp = {
  readonly _eq?: InputMaybe<User_Invite_Status>;
  readonly _in?: InputMaybe<ReadonlyArray<User_Invite_Status>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<User_Invite_Status>;
  readonly _nin?: InputMaybe<ReadonlyArray<User_Invite_Status>>;
};

export enum User_Invite_Type {
  Discord = 'discord',
  Email = 'email',
  Phone = 'phone',
  Username = 'username'
}

export type User_Invite_Type_Bool_Exp = {
  readonly _eq?: InputMaybe<User_Invite_Type>;
  readonly _in?: InputMaybe<ReadonlyArray<User_Invite_Type>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<User_Invite_Type>;
  readonly _nin?: InputMaybe<ReadonlyArray<User_Invite_Type>>;
};

/** columns and relationships of "user_invites" */
export type User_Invites = {
  readonly __typename?: 'user_invites';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly association_id: Scalars['String']['output'];
  /** An object relationship */
  readonly channel?: Maybe<Channels>;
  readonly code: Scalars['String']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['uuid']['output'];
  /** An object relationship */
  readonly invited?: Maybe<Users>;
  readonly invitee: Scalars['String']['output'];
  readonly invitee_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly inviter: Users;
  readonly inviter_id: Scalars['String']['output'];
  readonly kind: User_Invite_Kind;
  readonly status: User_Invite_Status;
  readonly type: User_Invite_Type;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "user_invites" */
export type User_Invites_Aggregate = {
  readonly __typename?: 'user_invites_aggregate';
  readonly aggregate?: Maybe<User_Invites_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<User_Invites>;
};

export type User_Invites_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<User_Invites_Aggregate_Bool_Exp_Count>;
};

export type User_Invites_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<User_Invites_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<User_Invites_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_invites" */
export type User_Invites_Aggregate_Fields = {
  readonly __typename?: 'user_invites_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<User_Invites_Max_Fields>;
  readonly min?: Maybe<User_Invites_Min_Fields>;
};


/** aggregate fields of "user_invites" */
export type User_Invites_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<User_Invites_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_invites" */
export type User_Invites_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<User_Invites_Max_Order_By>;
  readonly min?: InputMaybe<User_Invites_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_invites" */
export type User_Invites_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<User_Invites_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<User_Invites_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_invites". All fields are combined with a logical 'AND'. */
export type User_Invites_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<User_Invites_Bool_Exp>>;
  readonly _not?: InputMaybe<User_Invites_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<User_Invites_Bool_Exp>>;
  readonly associated_object?: InputMaybe<String_Comparison_Exp>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly channel?: InputMaybe<Channels_Bool_Exp>;
  readonly code?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly invited?: InputMaybe<Users_Bool_Exp>;
  readonly invitee?: InputMaybe<String_Comparison_Exp>;
  readonly invitee_id?: InputMaybe<String_Comparison_Exp>;
  readonly inviter?: InputMaybe<Users_Bool_Exp>;
  readonly inviter_id?: InputMaybe<String_Comparison_Exp>;
  readonly kind?: InputMaybe<User_Invite_Kind_Bool_Exp>;
  readonly status?: InputMaybe<User_Invite_Status_Bool_Exp>;
  readonly type?: InputMaybe<User_Invite_Type_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_invites" */
export enum User_Invites_Constraint {
  /** unique or primary key constraint on columns "invitee", "inviter_id", "associated_object" */
  UserInvitesInviterIdInviteeAssociatedObjectKey = 'user_invites_inviter_id_invitee_associated_object_key',
  /** unique or primary key constraint on columns "id" */
  UserInvitesPkey = 'user_invites_pkey'
}

/** input type for inserting data into table "user_invites" */
export type User_Invites_Insert_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly channel?: InputMaybe<Channels_Obj_Rel_Insert_Input>;
  readonly code?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly invited?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly invitee?: InputMaybe<Scalars['String']['input']>;
  readonly invitee_id?: InputMaybe<Scalars['String']['input']>;
  readonly inviter?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly inviter_id?: InputMaybe<Scalars['String']['input']>;
  readonly kind?: InputMaybe<User_Invite_Kind>;
  readonly status?: InputMaybe<User_Invite_Status>;
  readonly type?: InputMaybe<User_Invite_Type>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type User_Invites_Max_Fields = {
  readonly __typename?: 'user_invites_max_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly code?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly invitee?: Maybe<Scalars['String']['output']>;
  readonly invitee_id?: Maybe<Scalars['String']['output']>;
  readonly inviter_id?: Maybe<Scalars['String']['output']>;
  readonly kind?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "user_invites" */
export type User_Invites_Max_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly code?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly invitee?: InputMaybe<Order_By>;
  readonly invitee_id?: InputMaybe<Order_By>;
  readonly inviter_id?: InputMaybe<Order_By>;
  readonly kind?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Invites_Min_Fields = {
  readonly __typename?: 'user_invites_min_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly code?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly invitee?: Maybe<Scalars['String']['output']>;
  readonly invitee_id?: Maybe<Scalars['String']['output']>;
  readonly inviter_id?: Maybe<Scalars['String']['output']>;
  readonly kind?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "user_invites" */
export type User_Invites_Min_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly code?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly invitee?: InputMaybe<Order_By>;
  readonly invitee_id?: InputMaybe<Order_By>;
  readonly inviter_id?: InputMaybe<Order_By>;
  readonly kind?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_invites" */
export type User_Invites_Mutation_Response = {
  readonly __typename?: 'user_invites_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<User_Invites>;
};

/** on_conflict condition type for table "user_invites" */
export type User_Invites_On_Conflict = {
  readonly constraint: User_Invites_Constraint;
  readonly update_columns?: ReadonlyArray<User_Invites_Update_Column>;
  readonly where?: InputMaybe<User_Invites_Bool_Exp>;
};

/** Ordering options when selecting data from "user_invites". */
export type User_Invites_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly association_id?: InputMaybe<Order_By>;
  readonly channel?: InputMaybe<Channels_Order_By>;
  readonly code?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly invited?: InputMaybe<Users_Order_By>;
  readonly invitee?: InputMaybe<Order_By>;
  readonly invitee_id?: InputMaybe<Order_By>;
  readonly inviter?: InputMaybe<Users_Order_By>;
  readonly inviter_id?: InputMaybe<Order_By>;
  readonly kind?: InputMaybe<Order_By>;
  readonly status?: InputMaybe<Order_By>;
  readonly type?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_invites */
export type User_Invites_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "user_invites" */
export enum User_Invites_Select_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Invitee = 'invitee',
  /** column name */
  InviteeId = 'invitee_id',
  /** column name */
  InviterId = 'inviter_id',
  /** column name */
  Kind = 'kind',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user_invites" */
export type User_Invites_Set_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly code?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly invitee?: InputMaybe<Scalars['String']['input']>;
  readonly invitee_id?: InputMaybe<Scalars['String']['input']>;
  readonly inviter_id?: InputMaybe<Scalars['String']['input']>;
  readonly kind?: InputMaybe<User_Invite_Kind>;
  readonly status?: InputMaybe<User_Invite_Status>;
  readonly type?: InputMaybe<User_Invite_Type>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "user_invites" */
export type User_Invites_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: User_Invites_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Invites_Stream_Cursor_Value_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly code?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly invitee?: InputMaybe<Scalars['String']['input']>;
  readonly invitee_id?: InputMaybe<Scalars['String']['input']>;
  readonly inviter_id?: InputMaybe<Scalars['String']['input']>;
  readonly kind?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "user_invites" */
export enum User_Invites_Update_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Invitee = 'invitee',
  /** column name */
  InviteeId = 'invitee_id',
  /** column name */
  InviterId = 'inviter_id',
  /** column name */
  Kind = 'kind',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type User_Invites_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<User_Invites_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: User_Invites_Bool_Exp;
};

/** columns and relationships of "user_presence" */
export type User_Presence = {
  readonly __typename?: 'user_presence';
  readonly metadata: Scalars['jsonb']['output'];
  readonly online: Scalars['Boolean']['output'];
  readonly platform: Scalars['String']['output'];
  readonly platform_state: Scalars['jsonb']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};


/** columns and relationships of "user_presence" */
export type User_PresenceMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "user_presence" */
export type User_PresencePlatform_StateArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "user_presence" */
export type User_Presence_Aggregate = {
  readonly __typename?: 'user_presence_aggregate';
  readonly aggregate?: Maybe<User_Presence_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<User_Presence>;
};

export type User_Presence_Aggregate_Bool_Exp = {
  readonly bool_and?: InputMaybe<User_Presence_Aggregate_Bool_Exp_Bool_And>;
  readonly bool_or?: InputMaybe<User_Presence_Aggregate_Bool_Exp_Bool_Or>;
  readonly count?: InputMaybe<User_Presence_Aggregate_Bool_Exp_Count>;
};

export type User_Presence_Aggregate_Bool_Exp_Bool_And = {
  readonly arguments: User_Presence_Select_Column_User_Presence_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<User_Presence_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type User_Presence_Aggregate_Bool_Exp_Bool_Or = {
  readonly arguments: User_Presence_Select_Column_User_Presence_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<User_Presence_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type User_Presence_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<User_Presence_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<User_Presence_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_presence" */
export type User_Presence_Aggregate_Fields = {
  readonly __typename?: 'user_presence_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<User_Presence_Max_Fields>;
  readonly min?: Maybe<User_Presence_Min_Fields>;
};


/** aggregate fields of "user_presence" */
export type User_Presence_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<User_Presence_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_presence" */
export type User_Presence_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<User_Presence_Max_Order_By>;
  readonly min?: InputMaybe<User_Presence_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Presence_Append_Input = {
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
  readonly platform_state?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "user_presence" */
export type User_Presence_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<User_Presence_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<User_Presence_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_presence". All fields are combined with a logical 'AND'. */
export type User_Presence_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<User_Presence_Bool_Exp>>;
  readonly _not?: InputMaybe<User_Presence_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<User_Presence_Bool_Exp>>;
  readonly metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly online?: InputMaybe<Boolean_Comparison_Exp>;
  readonly platform?: InputMaybe<String_Comparison_Exp>;
  readonly platform_state?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_presence" */
export enum User_Presence_Constraint {
  /** unique or primary key constraint on columns "user_id", "platform" */
  UserPresencePkey = 'user_presence_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Presence_Delete_At_Path_Input = {
  readonly metadata?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly platform_state?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Presence_Delete_Elem_Input = {
  readonly metadata?: InputMaybe<Scalars['Int']['input']>;
  readonly platform_state?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Presence_Delete_Key_Input = {
  readonly metadata?: InputMaybe<Scalars['String']['input']>;
  readonly platform_state?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "user_presence" */
export type User_Presence_Insert_Input = {
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
  readonly online?: InputMaybe<Scalars['Boolean']['input']>;
  readonly platform?: InputMaybe<Scalars['String']['input']>;
  readonly platform_state?: InputMaybe<Scalars['jsonb']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Presence_Max_Fields = {
  readonly __typename?: 'user_presence_max_fields';
  readonly platform?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "user_presence" */
export type User_Presence_Max_Order_By = {
  readonly platform?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Presence_Min_Fields = {
  readonly __typename?: 'user_presence_min_fields';
  readonly platform?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "user_presence" */
export type User_Presence_Min_Order_By = {
  readonly platform?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_presence" */
export type User_Presence_Mutation_Response = {
  readonly __typename?: 'user_presence_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<User_Presence>;
};

/** on_conflict condition type for table "user_presence" */
export type User_Presence_On_Conflict = {
  readonly constraint: User_Presence_Constraint;
  readonly update_columns?: ReadonlyArray<User_Presence_Update_Column>;
  readonly where?: InputMaybe<User_Presence_Bool_Exp>;
};

/** Ordering options when selecting data from "user_presence". */
export type User_Presence_Order_By = {
  readonly metadata?: InputMaybe<Order_By>;
  readonly online?: InputMaybe<Order_By>;
  readonly platform?: InputMaybe<Order_By>;
  readonly platform_state?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_presence */
export type User_Presence_Pk_Columns_Input = {
  readonly platform: Scalars['String']['input'];
  readonly user_id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Presence_Prepend_Input = {
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
  readonly platform_state?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "user_presence" */
export enum User_Presence_Select_Column {
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Online = 'online',
  /** column name */
  Platform = 'platform',
  /** column name */
  PlatformState = 'platform_state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "user_presence_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_presence" */
export enum User_Presence_Select_Column_User_Presence_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Online = 'online'
}

/** select "user_presence_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_presence" */
export enum User_Presence_Select_Column_User_Presence_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Online = 'online'
}

/** input type for updating data in table "user_presence" */
export type User_Presence_Set_Input = {
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
  readonly online?: InputMaybe<Scalars['Boolean']['input']>;
  readonly platform?: InputMaybe<Scalars['String']['input']>;
  readonly platform_state?: InputMaybe<Scalars['jsonb']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_presence" */
export type User_Presence_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: User_Presence_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Presence_Stream_Cursor_Value_Input = {
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
  readonly online?: InputMaybe<Scalars['Boolean']['input']>;
  readonly platform?: InputMaybe<Scalars['String']['input']>;
  readonly platform_state?: InputMaybe<Scalars['jsonb']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_presence" */
export enum User_Presence_Update_Column {
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Online = 'online',
  /** column name */
  Platform = 'platform',
  /** column name */
  PlatformState = 'platform_state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Presence_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<User_Presence_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<User_Presence_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<User_Presence_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<User_Presence_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<User_Presence_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<User_Presence_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: User_Presence_Bool_Exp;
};

export enum User_Role {
  Adult = 'adult',
  Disabled = 'disabled',
  Team = 'team',
  User = 'user'
}

export type User_Role_Bool_Exp = {
  readonly _eq?: InputMaybe<User_Role>;
  readonly _in?: InputMaybe<ReadonlyArray<User_Role>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<User_Role>;
  readonly _nin?: InputMaybe<ReadonlyArray<User_Role>>;
};

/** columns and relationships of "users" */
export type Users = {
  readonly __typename?: 'users';
  /** An array relationship */
  readonly accounts: ReadonlyArray<Accounts>;
  /** An aggregate relationship */
  readonly accounts_aggregate: Accounts_Aggregate;
  readonly address?: Maybe<Scalars['String']['output']>;
  readonly age?: Maybe<Scalars['Int']['output']>;
  readonly bio?: Maybe<Scalars['String']['output']>;
  readonly birthday?: Maybe<Scalars['date']['output']>;
  /** An array relationship */
  readonly channels_users: ReadonlyArray<Channels_Users>;
  /** An aggregate relationship */
  readonly channels_users_aggregate: Channels_Users_Aggregate;
  /** An array relationship */
  readonly children: ReadonlyArray<Users>;
  /** An aggregate relationship */
  readonly children_aggregate: Users_Aggregate;
  /** An array relationship */
  readonly club_memberships: ReadonlyArray<Clubs_Members>;
  /** An aggregate relationship */
  readonly club_memberships_aggregate: Clubs_Members_Aggregate;
  /** An array relationship */
  readonly competitions_hosting: ReadonlyArray<Competitions>;
  /** An aggregate relationship */
  readonly competitions_hosting_aggregate: Competitions_Aggregate;
  /** An array relationship */
  readonly competitions_judging: ReadonlyArray<Competitions_Judges>;
  /** An aggregate relationship */
  readonly competitions_judging_aggregate: Competitions_Judges_Aggregate;
  readonly created_at: Scalars['timestamptz']['output'];
  /** A computed field, executes function "is_current_user" */
  readonly current?: Maybe<Scalars['Boolean']['output']>;
  readonly discord_id?: Maybe<Scalars['String']['output']>;
  readonly discord_user?: Maybe<Scalars['jsonb']['output']>;
  readonly division: User_Division;
  readonly dream_job?: Maybe<Scalars['String']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly email_verified?: Maybe<Scalars['Boolean']['output']>;
  readonly facebook_id?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly features: ReadonlyArray<Users_Features>;
  /** An aggregate relationship */
  readonly features_aggregate: Users_Features_Aggregate;
  /** An array relationship */
  readonly feedback: ReadonlyArray<Feedback>;
  /** An aggregate relationship */
  readonly feedback_aggregate: Feedback_Aggregate;
  readonly first_name?: Maybe<Scalars['String']['output']>;
  readonly follower_count: Scalars['Int']['output'];
  /** An array relationship */
  readonly followers: ReadonlyArray<Follows>;
  /** An aggregate relationship */
  readonly followers_aggregate: Follows_Aggregate;
  /** An array relationship */
  readonly following: ReadonlyArray<Follows>;
  /** An aggregate relationship */
  readonly following_aggregate: Follows_Aggregate;
  readonly following_count: Scalars['Int']['output'];
  /** An array relationship */
  readonly friendships_users: ReadonlyArray<Friendships_Users>;
  /** An aggregate relationship */
  readonly friendships_users_aggregate: Friendships_Users_Aggregate;
  readonly gender?: Maybe<Scalars['String']['output']>;
  readonly google_id?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly intercom_id?: Maybe<Scalars['String']['output']>;
  readonly interested_reason?: Maybe<Scalars['String']['output']>;
  readonly interests?: Maybe<Scalars['String']['output']>;
  readonly invite_code?: Maybe<Scalars['String']['output']>;
  readonly last_name?: Maybe<Scalars['String']['output']>;
  readonly level: Scalars['Int']['output'];
  readonly meta: Scalars['users_meta']['output'];
  /** An object relationship */
  readonly minecraft_skin?: Maybe<Minecraft_Skins>;
  /** An object relationship */
  readonly minecraft_user?: Maybe<Minecraft_User>;
  readonly money: Scalars['numeric']['output'];
  /** An array relationship */
  readonly notifications: ReadonlyArray<Notifications>;
  /** An aggregate relationship */
  readonly notifications_aggregate: Notifications_Aggregate;
  readonly onboarded: Scalars['Boolean']['output'];
  /** An array relationship */
  readonly orders: ReadonlyArray<Orders>;
  /** An aggregate relationship */
  readonly orders_aggregate: Orders_Aggregate;
  /** An object relationship */
  readonly parent?: Maybe<Users>;
  readonly parent_email?: Maybe<Scalars['String']['output']>;
  readonly parent_id?: Maybe<Scalars['String']['output']>;
  readonly password_hash?: Maybe<Scalars['String']['output']>;
  readonly phone?: Maybe<Scalars['String']['output']>;
  readonly phone_verified?: Maybe<Scalars['Boolean']['output']>;
  readonly photo: Scalars['String']['output'];
  /** An array relationship */
  readonly posts: ReadonlyArray<Posts>;
  /** An aggregate relationship */
  readonly posts_aggregate: Posts_Aggregate;
  readonly presence: Scalars['users_presence_meta']['output'];
  /** An array relationship */
  readonly projects_collaborated: ReadonlyArray<Projects_Collaborators>;
  /** An aggregate relationship */
  readonly projects_collaborated_aggregate: Projects_Collaborators_Aggregate;
  /** An array relationship */
  readonly projects_comments: ReadonlyArray<Comments>;
  /** An aggregate relationship */
  readonly projects_comments_aggregate: Comments_Aggregate;
  /** An array relationship */
  readonly projects_created: ReadonlyArray<Projects>;
  /** An aggregate relationship */
  readonly projects_created_aggregate: Projects_Aggregate;
  /** An array relationship */
  readonly projects_votes: ReadonlyArray<Votes>;
  /** An aggregate relationship */
  readonly projects_votes_aggregate: Votes_Aggregate;
  /** An object relationship */
  readonly prospective_user?: Maybe<Prospective_Users>;
  readonly role: User_Role;
  readonly season_xp: Scalars['Int']['output'];
  /** An array relationship */
  readonly stats: ReadonlyArray<Users_Stats>;
  /** An aggregate relationship */
  readonly stats_aggregate: Users_Stats_Aggregate;
  readonly stripe_customer_id?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly subscriptions: ReadonlyArray<Subscriptions>;
  /** An aggregate relationship */
  readonly subscriptions_aggregate: Subscriptions_Aggregate;
  readonly title?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly transactions: ReadonlyArray<Transactions>;
  /** An aggregate relationship */
  readonly transactions_aggregate: Transactions_Aggregate;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  readonly user_invites: ReadonlyArray<User_Invites>;
  /** An aggregate relationship */
  readonly user_invites_aggregate: User_Invites_Aggregate;
  /** An array relationship */
  readonly user_invites_received: ReadonlyArray<User_Invites>;
  /** An aggregate relationship */
  readonly user_invites_received_aggregate: User_Invites_Aggregate;
  /** An array relationship */
  readonly user_presences: ReadonlyArray<User_Presence>;
  /** An aggregate relationship */
  readonly user_presences_aggregate: User_Presence_Aggregate;
  readonly username?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  readonly users_badges: ReadonlyArray<Users_Badges>;
  /** An aggregate relationship */
  readonly users_badges_aggregate: Users_Badges_Aggregate;
  /** An array relationship */
  readonly users_levels: ReadonlyArray<Users_Levels>;
  /** An aggregate relationship */
  readonly users_levels_aggregate: Users_Levels_Aggregate;
  /** An array relationship */
  readonly users_notifications: ReadonlyArray<Users_Notifications>;
  /** An aggregate relationship */
  readonly users_notifications_aggregate: Users_Notifications_Aggregate;
  /** An object relationship */
  readonly users_presence?: Maybe<Users_Presence>;
  /** An array relationship */
  readonly users_quests: ReadonlyArray<Users_Quests>;
  /** An aggregate relationship */
  readonly users_quests_aggregate: Users_Quests_Aggregate;
  readonly verified: Scalars['Boolean']['output'];
  readonly xp: Scalars['Int']['output'];
};


/** columns and relationships of "users" */
export type UsersAccountsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersChannels_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Users_Order_By>>;
  where?: InputMaybe<Channels_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersChannels_Users_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Channels_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Channels_Users_Order_By>>;
  where?: InputMaybe<Channels_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersChildrenArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersChildren_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersClub_MembershipsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Members_Order_By>>;
  where?: InputMaybe<Clubs_Members_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersClub_Memberships_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Clubs_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Clubs_Members_Order_By>>;
  where?: InputMaybe<Clubs_Members_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCompetitions_HostingArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCompetitions_Hosting_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Order_By>>;
  where?: InputMaybe<Competitions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCompetitions_JudgingArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Judges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Judges_Order_By>>;
  where?: InputMaybe<Competitions_Judges_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCompetitions_Judging_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Competitions_Judges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Competitions_Judges_Order_By>>;
  where?: InputMaybe<Competitions_Judges_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersDiscord_UserArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "users" */
export type UsersFeaturesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Features_Order_By>>;
  where?: InputMaybe<Users_Features_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFeatures_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Features_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Features_Order_By>>;
  where?: InputMaybe<Users_Features_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFeedbackArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feedback_Order_By>>;
  where?: InputMaybe<Feedback_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFeedback_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Feedback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Feedback_Order_By>>;
  where?: InputMaybe<Feedback_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFollowersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFollowers_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFollowingArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFollowing_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFriendships_UsersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friendships_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friendships_Users_Order_By>>;
  where?: InputMaybe<Friendships_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFriendships_Users_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Friendships_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Friendships_Users_Order_By>>;
  where?: InputMaybe<Friendships_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "users" */
export type UsersNotificationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersOrdersArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersOrders_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Orders_Order_By>>;
  where?: InputMaybe<Orders_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPostsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPosts_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPresenceArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "users" */
export type UsersProjects_CollaboratedArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Collaborators_Order_By>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProjects_Collaborated_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Collaborators_Order_By>>;
  where?: InputMaybe<Projects_Collaborators_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProjects_CommentsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProjects_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProjects_CreatedArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProjects_Created_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProjects_VotesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProjects_Votes_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Votes_Order_By>>;
  where?: InputMaybe<Votes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersStatsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Stats_Order_By>>;
  where?: InputMaybe<Users_Stats_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersStats_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Stats_Order_By>>;
  where?: InputMaybe<Users_Stats_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSubscriptionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTransactionsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_InvitesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Invites_ReceivedArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Invites_Received_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_PresencesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Presence_Order_By>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Presences_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<User_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<User_Presence_Order_By>>;
  where?: InputMaybe<User_Presence_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_BadgesArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Badges_Order_By>>;
  where?: InputMaybe<Users_Badges_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_Badges_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Badges_Order_By>>;
  where?: InputMaybe<Users_Badges_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_LevelsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Levels_Order_By>>;
  where?: InputMaybe<Users_Levels_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Levels_Order_By>>;
  where?: InputMaybe<Users_Levels_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_NotificationsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Notifications_Order_By>>;
  where?: InputMaybe<Users_Notifications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Notifications_Order_By>>;
  where?: InputMaybe<Users_Notifications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_QuestsArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Quests_Order_By>>;
  where?: InputMaybe<Users_Quests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_Quests_AggregateArgs = {
  distinct_on?: InputMaybe<ReadonlyArray<Users_Quests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<ReadonlyArray<Users_Quests_Order_By>>;
  where?: InputMaybe<Users_Quests_Bool_Exp>;
};

/** columns and relationships of "users_actions" */
export type Users_Actions = {
  readonly __typename?: 'users_actions';
  readonly action: Scalars['user_action']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly executed: Scalars['Boolean']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly issuer?: Maybe<Scalars['String']['output']>;
  readonly key?: Maybe<Scalars['String']['output']>;
  readonly updated_at: Scalars['timestamptz']['output'];
  readonly user_id: Scalars['String']['output'];
};


/** columns and relationships of "users_actions" */
export type Users_ActionsActionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "users_actions" */
export type Users_Actions_Aggregate = {
  readonly __typename?: 'users_actions_aggregate';
  readonly aggregate?: Maybe<Users_Actions_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Users_Actions>;
};

/** aggregate fields of "users_actions" */
export type Users_Actions_Aggregate_Fields = {
  readonly __typename?: 'users_actions_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Users_Actions_Max_Fields>;
  readonly min?: Maybe<Users_Actions_Min_Fields>;
};


/** aggregate fields of "users_actions" */
export type Users_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Users_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Actions_Append_Input = {
  readonly action?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "users_actions". All fields are combined with a logical 'AND'. */
export type Users_Actions_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Users_Actions_Bool_Exp>>;
  readonly _not?: InputMaybe<Users_Actions_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Users_Actions_Bool_Exp>>;
  readonly action?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly executed?: InputMaybe<Boolean_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly issuer?: InputMaybe<String_Comparison_Exp>;
  readonly key?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_actions" */
export enum Users_Actions_Constraint {
  /** unique or primary key constraint on columns "issuer", "user_id" */
  UniqueActionTypeIssuerUserId = 'unique_action_type_issuer_user_id',
  /** unique or primary key constraint on columns "id" */
  UsersActionsPkey = 'users_actions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Actions_Delete_At_Path_Input = {
  readonly action?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Actions_Delete_Elem_Input = {
  readonly action?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Actions_Delete_Key_Input = {
  readonly action?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "users_actions" */
export type Users_Actions_Insert_Input = {
  readonly action: Scalars['user_action']['input'];
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly executed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly issuer?: InputMaybe<Scalars['String']['input']>;
  readonly key?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Users_Actions_Max_Fields = {
  readonly __typename?: 'users_actions_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly issuer?: Maybe<Scalars['String']['output']>;
  readonly key?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Users_Actions_Min_Fields = {
  readonly __typename?: 'users_actions_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly issuer?: Maybe<Scalars['String']['output']>;
  readonly key?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "users_actions" */
export type Users_Actions_Mutation_Response = {
  readonly __typename?: 'users_actions_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Users_Actions>;
};

/** on_conflict condition type for table "users_actions" */
export type Users_Actions_On_Conflict = {
  readonly constraint: Users_Actions_Constraint;
  readonly update_columns?: ReadonlyArray<Users_Actions_Update_Column>;
  readonly where?: InputMaybe<Users_Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "users_actions". */
export type Users_Actions_Order_By = {
  readonly action?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly executed?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly issuer?: InputMaybe<Order_By>;
  readonly key?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_actions */
export type Users_Actions_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Actions_Prepend_Input = {
  readonly action?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "users_actions" */
export enum Users_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Executed = 'executed',
  /** column name */
  Id = 'id',
  /** column name */
  Issuer = 'issuer',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "users_actions" */
export type Users_Actions_Set_Input = {
  readonly action: Scalars['user_action']['input'];
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly executed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly issuer?: InputMaybe<Scalars['String']['input']>;
  readonly key?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "users_actions" */
export type Users_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Users_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Actions_Stream_Cursor_Value_Input = {
  readonly action?: InputMaybe<Scalars['jsonb']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly executed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly issuer?: InputMaybe<Scalars['String']['input']>;
  readonly key?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "users_actions" */
export enum Users_Actions_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Executed = 'executed',
  /** column name */
  Id = 'id',
  /** column name */
  Issuer = 'issuer',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Users_Actions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Users_Actions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Users_Actions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Users_Actions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Users_Actions_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Users_Actions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Users_Actions_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Users_Actions_Bool_Exp;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  readonly __typename?: 'users_aggregate';
  readonly aggregate?: Maybe<Users_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Users>;
};

export type Users_Aggregate_Bool_Exp = {
  readonly bool_and?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_And>;
  readonly bool_or?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_Or>;
  readonly count?: InputMaybe<Users_Aggregate_Bool_Exp_Count>;
};

export type Users_Aggregate_Bool_Exp_Bool_And = {
  readonly arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Users_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Bool_Or = {
  readonly arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Users_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Users_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Users_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  readonly __typename?: 'users_aggregate_fields';
  readonly avg?: Maybe<Users_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Users_Max_Fields>;
  readonly min?: Maybe<Users_Min_Fields>;
  readonly stddev?: Maybe<Users_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Users_Sum_Fields>;
  readonly var_pop?: Maybe<Users_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Users_Var_Samp_Fields>;
  readonly variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Users_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Users_Max_Order_By>;
  readonly min?: InputMaybe<Users_Min_Order_By>;
  readonly stddev?: InputMaybe<Users_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Users_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Users_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Users_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Users_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Users_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Users_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Append_Input = {
  readonly discord_user?: InputMaybe<Scalars['jsonb']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly presence?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Users_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  readonly __typename?: 'users_avg_fields';
  readonly age?: Maybe<Scalars['Float']['output']>;
  readonly follower_count?: Maybe<Scalars['Float']['output']>;
  readonly following_count?: Maybe<Scalars['Float']['output']>;
  readonly level?: Maybe<Scalars['Float']['output']>;
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly season_xp?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "users" */
export type Users_Avg_Order_By = {
  readonly age?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** columns and relationships of "users_badges" */
export type Users_Badges = {
  readonly __typename?: 'users_badges';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  readonly badge: Badges;
  readonly badge_id: Scalars['uuid']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly expiry?: Maybe<Scalars['timestamptz']['output']>;
  readonly id: Scalars['Int']['output'];
  readonly level_level?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  readonly project?: Maybe<Projects>;
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};

/** aggregated selection of "users_badges" */
export type Users_Badges_Aggregate = {
  readonly __typename?: 'users_badges_aggregate';
  readonly aggregate?: Maybe<Users_Badges_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Users_Badges>;
};

export type Users_Badges_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Users_Badges_Aggregate_Bool_Exp_Count>;
};

export type Users_Badges_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Users_Badges_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Users_Badges_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_badges" */
export type Users_Badges_Aggregate_Fields = {
  readonly __typename?: 'users_badges_aggregate_fields';
  readonly avg?: Maybe<Users_Badges_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Users_Badges_Max_Fields>;
  readonly min?: Maybe<Users_Badges_Min_Fields>;
  readonly stddev?: Maybe<Users_Badges_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Users_Badges_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Users_Badges_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Users_Badges_Sum_Fields>;
  readonly var_pop?: Maybe<Users_Badges_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Users_Badges_Var_Samp_Fields>;
  readonly variance?: Maybe<Users_Badges_Variance_Fields>;
};


/** aggregate fields of "users_badges" */
export type Users_Badges_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Users_Badges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users_badges" */
export type Users_Badges_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Users_Badges_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Users_Badges_Max_Order_By>;
  readonly min?: InputMaybe<Users_Badges_Min_Order_By>;
  readonly stddev?: InputMaybe<Users_Badges_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Users_Badges_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Users_Badges_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Users_Badges_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Users_Badges_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Users_Badges_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Users_Badges_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "users_badges" */
export type Users_Badges_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Users_Badges_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Users_Badges_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Badges_Avg_Fields = {
  readonly __typename?: 'users_badges_avg_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly level_level?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "users_badges" */
export type Users_Badges_Avg_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users_badges". All fields are combined with a logical 'AND'. */
export type Users_Badges_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Users_Badges_Bool_Exp>>;
  readonly _not?: InputMaybe<Users_Badges_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Users_Badges_Bool_Exp>>;
  readonly associated_object?: InputMaybe<String_Comparison_Exp>;
  readonly badge?: InputMaybe<Badges_Bool_Exp>;
  readonly badge_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly expiry?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Int_Comparison_Exp>;
  readonly level_level?: InputMaybe<Int_Comparison_Exp>;
  readonly project?: InputMaybe<Projects_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_badges" */
export enum Users_Badges_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersBadgesPkey = 'users_badges_pkey',
  /** unique or primary key constraint on columns "user_id", "badge_id", "associated_object" */
  UsersBadgesUserIdBadgeIdAssociatedObjectKey = 'users_badges_user_id_badge_id_associated_object_key'
}

/** input type for incrementing numeric columns in table "users_badges" */
export type Users_Badges_Inc_Input = {
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly level_level?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "users_badges" */
export type Users_Badges_Insert_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly badge?: InputMaybe<Badges_Obj_Rel_Insert_Input>;
  readonly badge_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly expiry?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly level_level?: InputMaybe<Scalars['Int']['input']>;
  readonly project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Users_Badges_Max_Fields = {
  readonly __typename?: 'users_badges_max_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly badge_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly expiry?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly level_level?: Maybe<Scalars['Int']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "users_badges" */
export type Users_Badges_Max_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly badge_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly expiry?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Badges_Min_Fields = {
  readonly __typename?: 'users_badges_min_fields';
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly badge_id?: Maybe<Scalars['uuid']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly expiry?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly level_level?: Maybe<Scalars['Int']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "users_badges" */
export type Users_Badges_Min_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly badge_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly expiry?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_badges" */
export type Users_Badges_Mutation_Response = {
  readonly __typename?: 'users_badges_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Users_Badges>;
};

/** on_conflict condition type for table "users_badges" */
export type Users_Badges_On_Conflict = {
  readonly constraint: Users_Badges_Constraint;
  readonly update_columns?: ReadonlyArray<Users_Badges_Update_Column>;
  readonly where?: InputMaybe<Users_Badges_Bool_Exp>;
};

/** Ordering options when selecting data from "users_badges". */
export type Users_Badges_Order_By = {
  readonly associated_object?: InputMaybe<Order_By>;
  readonly badge?: InputMaybe<Badges_Order_By>;
  readonly badge_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly expiry?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
  readonly project?: InputMaybe<Projects_Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_badges */
export type Users_Badges_Pk_Columns_Input = {
  readonly id: Scalars['Int']['input'];
};

/** select columns of table "users_badges" */
export enum Users_Badges_Select_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  BadgeId = 'badge_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Expiry = 'expiry',
  /** column name */
  Id = 'id',
  /** column name */
  LevelLevel = 'level_level',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "users_badges" */
export type Users_Badges_Set_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly badge_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly expiry?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly level_level?: InputMaybe<Scalars['Int']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Users_Badges_Stddev_Fields = {
  readonly __typename?: 'users_badges_stddev_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly level_level?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "users_badges" */
export type Users_Badges_Stddev_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Badges_Stddev_Pop_Fields = {
  readonly __typename?: 'users_badges_stddev_pop_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly level_level?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "users_badges" */
export type Users_Badges_Stddev_Pop_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Badges_Stddev_Samp_Fields = {
  readonly __typename?: 'users_badges_stddev_samp_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly level_level?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "users_badges" */
export type Users_Badges_Stddev_Samp_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users_badges" */
export type Users_Badges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Users_Badges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Badges_Stream_Cursor_Value_Input = {
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly badge_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly expiry?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['Int']['input']>;
  readonly level_level?: InputMaybe<Scalars['Int']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Users_Badges_Sum_Fields = {
  readonly __typename?: 'users_badges_sum_fields';
  readonly id?: Maybe<Scalars['Int']['output']>;
  readonly level_level?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "users_badges" */
export type Users_Badges_Sum_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
};

/** update columns of table "users_badges" */
export enum Users_Badges_Update_Column {
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  BadgeId = 'badge_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Expiry = 'expiry',
  /** column name */
  Id = 'id',
  /** column name */
  LevelLevel = 'level_level',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Users_Badges_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Users_Badges_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Users_Badges_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Users_Badges_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Badges_Var_Pop_Fields = {
  readonly __typename?: 'users_badges_var_pop_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly level_level?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "users_badges" */
export type Users_Badges_Var_Pop_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Badges_Var_Samp_Fields = {
  readonly __typename?: 'users_badges_var_samp_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly level_level?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "users_badges" */
export type Users_Badges_Var_Samp_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Badges_Variance_Fields = {
  readonly __typename?: 'users_badges_variance_fields';
  readonly id?: Maybe<Scalars['Float']['output']>;
  readonly level_level?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "users_badges" */
export type Users_Badges_Variance_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Users_Bool_Exp>>;
  readonly _not?: InputMaybe<Users_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Users_Bool_Exp>>;
  readonly accounts?: InputMaybe<Accounts_Bool_Exp>;
  readonly accounts_aggregate?: InputMaybe<Accounts_Aggregate_Bool_Exp>;
  readonly address?: InputMaybe<String_Comparison_Exp>;
  readonly age?: InputMaybe<Int_Comparison_Exp>;
  readonly bio?: InputMaybe<String_Comparison_Exp>;
  readonly birthday?: InputMaybe<Date_Comparison_Exp>;
  readonly channels_users?: InputMaybe<Channels_Users_Bool_Exp>;
  readonly channels_users_aggregate?: InputMaybe<Channels_Users_Aggregate_Bool_Exp>;
  readonly children?: InputMaybe<Users_Bool_Exp>;
  readonly children_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
  readonly club_memberships?: InputMaybe<Clubs_Members_Bool_Exp>;
  readonly club_memberships_aggregate?: InputMaybe<Clubs_Members_Aggregate_Bool_Exp>;
  readonly competitions_hosting?: InputMaybe<Competitions_Bool_Exp>;
  readonly competitions_hosting_aggregate?: InputMaybe<Competitions_Aggregate_Bool_Exp>;
  readonly competitions_judging?: InputMaybe<Competitions_Judges_Bool_Exp>;
  readonly competitions_judging_aggregate?: InputMaybe<Competitions_Judges_Aggregate_Bool_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly current?: InputMaybe<Boolean_Comparison_Exp>;
  readonly discord_id?: InputMaybe<String_Comparison_Exp>;
  readonly discord_user?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly division?: InputMaybe<User_Division_Bool_Exp>;
  readonly dream_job?: InputMaybe<String_Comparison_Exp>;
  readonly email?: InputMaybe<String_Comparison_Exp>;
  readonly email_verified?: InputMaybe<Boolean_Comparison_Exp>;
  readonly facebook_id?: InputMaybe<String_Comparison_Exp>;
  readonly features?: InputMaybe<Users_Features_Bool_Exp>;
  readonly features_aggregate?: InputMaybe<Users_Features_Aggregate_Bool_Exp>;
  readonly feedback?: InputMaybe<Feedback_Bool_Exp>;
  readonly feedback_aggregate?: InputMaybe<Feedback_Aggregate_Bool_Exp>;
  readonly first_name?: InputMaybe<String_Comparison_Exp>;
  readonly follower_count?: InputMaybe<Int_Comparison_Exp>;
  readonly followers?: InputMaybe<Follows_Bool_Exp>;
  readonly followers_aggregate?: InputMaybe<Follows_Aggregate_Bool_Exp>;
  readonly following?: InputMaybe<Follows_Bool_Exp>;
  readonly following_aggregate?: InputMaybe<Follows_Aggregate_Bool_Exp>;
  readonly following_count?: InputMaybe<Int_Comparison_Exp>;
  readonly friendships_users?: InputMaybe<Friendships_Users_Bool_Exp>;
  readonly friendships_users_aggregate?: InputMaybe<Friendships_Users_Aggregate_Bool_Exp>;
  readonly gender?: InputMaybe<String_Comparison_Exp>;
  readonly google_id?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly intercom_id?: InputMaybe<String_Comparison_Exp>;
  readonly interested_reason?: InputMaybe<String_Comparison_Exp>;
  readonly interests?: InputMaybe<String_Comparison_Exp>;
  readonly invite_code?: InputMaybe<String_Comparison_Exp>;
  readonly last_name?: InputMaybe<String_Comparison_Exp>;
  readonly level?: InputMaybe<Int_Comparison_Exp>;
  readonly meta?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly minecraft_skin?: InputMaybe<Minecraft_Skins_Bool_Exp>;
  readonly minecraft_user?: InputMaybe<Minecraft_User_Bool_Exp>;
  readonly money?: InputMaybe<Numeric_Comparison_Exp>;
  readonly notifications?: InputMaybe<Notifications_Bool_Exp>;
  readonly notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  readonly onboarded?: InputMaybe<Boolean_Comparison_Exp>;
  readonly orders?: InputMaybe<Orders_Bool_Exp>;
  readonly orders_aggregate?: InputMaybe<Orders_Aggregate_Bool_Exp>;
  readonly parent?: InputMaybe<Users_Bool_Exp>;
  readonly parent_email?: InputMaybe<String_Comparison_Exp>;
  readonly parent_id?: InputMaybe<String_Comparison_Exp>;
  readonly password_hash?: InputMaybe<String_Comparison_Exp>;
  readonly phone?: InputMaybe<String_Comparison_Exp>;
  readonly phone_verified?: InputMaybe<Boolean_Comparison_Exp>;
  readonly photo?: InputMaybe<String_Comparison_Exp>;
  readonly posts?: InputMaybe<Posts_Bool_Exp>;
  readonly posts_aggregate?: InputMaybe<Posts_Aggregate_Bool_Exp>;
  readonly presence?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly projects_collaborated?: InputMaybe<Projects_Collaborators_Bool_Exp>;
  readonly projects_collaborated_aggregate?: InputMaybe<Projects_Collaborators_Aggregate_Bool_Exp>;
  readonly projects_comments?: InputMaybe<Comments_Bool_Exp>;
  readonly projects_comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  readonly projects_created?: InputMaybe<Projects_Bool_Exp>;
  readonly projects_created_aggregate?: InputMaybe<Projects_Aggregate_Bool_Exp>;
  readonly projects_votes?: InputMaybe<Votes_Bool_Exp>;
  readonly projects_votes_aggregate?: InputMaybe<Votes_Aggregate_Bool_Exp>;
  readonly prospective_user?: InputMaybe<Prospective_Users_Bool_Exp>;
  readonly role?: InputMaybe<User_Role_Bool_Exp>;
  readonly season_xp?: InputMaybe<Int_Comparison_Exp>;
  readonly stats?: InputMaybe<Users_Stats_Bool_Exp>;
  readonly stats_aggregate?: InputMaybe<Users_Stats_Aggregate_Bool_Exp>;
  readonly stripe_customer_id?: InputMaybe<String_Comparison_Exp>;
  readonly subscriptions?: InputMaybe<Subscriptions_Bool_Exp>;
  readonly subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Bool_Exp>;
  readonly title?: InputMaybe<String_Comparison_Exp>;
  readonly transactions?: InputMaybe<Transactions_Bool_Exp>;
  readonly transactions_aggregate?: InputMaybe<Transactions_Aggregate_Bool_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user_invites?: InputMaybe<User_Invites_Bool_Exp>;
  readonly user_invites_aggregate?: InputMaybe<User_Invites_Aggregate_Bool_Exp>;
  readonly user_invites_received?: InputMaybe<User_Invites_Bool_Exp>;
  readonly user_invites_received_aggregate?: InputMaybe<User_Invites_Aggregate_Bool_Exp>;
  readonly user_presences?: InputMaybe<User_Presence_Bool_Exp>;
  readonly user_presences_aggregate?: InputMaybe<User_Presence_Aggregate_Bool_Exp>;
  readonly username?: InputMaybe<String_Comparison_Exp>;
  readonly users_badges?: InputMaybe<Users_Badges_Bool_Exp>;
  readonly users_badges_aggregate?: InputMaybe<Users_Badges_Aggregate_Bool_Exp>;
  readonly users_levels?: InputMaybe<Users_Levels_Bool_Exp>;
  readonly users_levels_aggregate?: InputMaybe<Users_Levels_Aggregate_Bool_Exp>;
  readonly users_notifications?: InputMaybe<Users_Notifications_Bool_Exp>;
  readonly users_notifications_aggregate?: InputMaybe<Users_Notifications_Aggregate_Bool_Exp>;
  readonly users_presence?: InputMaybe<Users_Presence_Bool_Exp>;
  readonly users_quests?: InputMaybe<Users_Quests_Bool_Exp>;
  readonly users_quests_aggregate?: InputMaybe<Users_Quests_Aggregate_Bool_Exp>;
  readonly verified?: InputMaybe<Boolean_Comparison_Exp>;
  readonly xp?: InputMaybe<Int_Comparison_Exp>;
};

/** columns and relationships of "users_cached_queries" */
export type Users_Cached_Queries = {
  readonly __typename?: 'users_cached_queries';
  readonly key: Scalars['String']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  readonly user_id: Scalars['String']['output'];
  readonly value: Scalars['String']['output'];
};

export type Users_Cached_Queries_Aggregate = {
  readonly __typename?: 'users_cached_queries_aggregate';
  readonly aggregate?: Maybe<Users_Cached_Queries_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Users_Cached_Queries>;
};

/** aggregate fields of "users_cached_queries" */
export type Users_Cached_Queries_Aggregate_Fields = {
  readonly __typename?: 'users_cached_queries_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Users_Cached_Queries_Max_Fields>;
  readonly min?: Maybe<Users_Cached_Queries_Min_Fields>;
};


/** aggregate fields of "users_cached_queries" */
export type Users_Cached_Queries_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Users_Cached_Queries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "users_cached_queries". All fields are combined with a logical 'AND'. */
export type Users_Cached_Queries_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Users_Cached_Queries_Bool_Exp>>;
  readonly _not?: InputMaybe<Users_Cached_Queries_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Users_Cached_Queries_Bool_Exp>>;
  readonly key?: InputMaybe<String_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
  readonly value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_cached_queries" */
export enum Users_Cached_Queries_Constraint {
  /** unique or primary key constraint on columns "key", "user_id" */
  UsersCachedQueriesPkey = 'users_cached_queries_pkey'
}

/** input type for inserting data into table "users_cached_queries" */
export type Users_Cached_Queries_Insert_Input = {
  readonly key?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Users_Cached_Queries_Max_Fields = {
  readonly __typename?: 'users_cached_queries_max_fields';
  readonly key?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
  readonly value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Users_Cached_Queries_Min_Fields = {
  readonly __typename?: 'users_cached_queries_min_fields';
  readonly key?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
  readonly value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "users_cached_queries" */
export type Users_Cached_Queries_Mutation_Response = {
  readonly __typename?: 'users_cached_queries_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Users_Cached_Queries>;
};

/** on_conflict condition type for table "users_cached_queries" */
export type Users_Cached_Queries_On_Conflict = {
  readonly constraint: Users_Cached_Queries_Constraint;
  readonly update_columns?: ReadonlyArray<Users_Cached_Queries_Update_Column>;
  readonly where?: InputMaybe<Users_Cached_Queries_Bool_Exp>;
};

/** Ordering options when selecting data from "users_cached_queries". */
export type Users_Cached_Queries_Order_By = {
  readonly key?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_cached_queries */
export type Users_Cached_Queries_Pk_Columns_Input = {
  readonly key: Scalars['String']['input'];
  readonly user_id: Scalars['String']['input'];
};

/** select columns of table "users_cached_queries" */
export enum Users_Cached_Queries_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "users_cached_queries" */
export type Users_Cached_Queries_Set_Input = {
  readonly key?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "users_cached_queries" */
export type Users_Cached_Queries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Users_Cached_Queries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Cached_Queries_Stream_Cursor_Value_Input = {
  readonly key?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "users_cached_queries" */
export enum Users_Cached_Queries_Update_Column {
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

export type Users_Cached_Queries_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Users_Cached_Queries_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Users_Cached_Queries_Bool_Exp;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "discord_id" */
  UsersDiscordIdKey = 'users_discord_id_key',
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint on columns "facebook_id" */
  UsersFacebookIdKey = 'users_facebook_id_key',
  /** unique or primary key constraint on columns "google_id" */
  UsersGoogleIdKey = 'users_google_id_key',
  /** unique or primary key constraint on columns "phone" */
  UsersPhoneKey = 'users_phone_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
  /** unique or primary key constraint on columns "stripe_customer_id" */
  UsersStripeCustomerIdKey = 'users_stripe_customer_id_key',
  /** unique or primary key constraint on columns "username" */
  UsersUsernameKey = 'users_username_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Delete_At_Path_Input = {
  readonly discord_user?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly meta?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly presence?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Delete_Elem_Input = {
  readonly discord_user?: InputMaybe<Scalars['Int']['input']>;
  readonly meta?: InputMaybe<Scalars['Int']['input']>;
  readonly presence?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Delete_Key_Input = {
  readonly discord_user?: InputMaybe<Scalars['String']['input']>;
  readonly meta?: InputMaybe<Scalars['String']['input']>;
  readonly presence?: InputMaybe<Scalars['String']['input']>;
};

/** Association table to determine which features users are entitled to */
export type Users_Features = {
  readonly __typename?: 'users_features';
  readonly amount?: Maybe<Scalars['Int']['output']>;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly enabled: Scalars['Boolean']['output'];
  readonly ends_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  readonly feature: Features;
  readonly feature_id: Scalars['uuid']['output'];
  readonly metadata: Scalars['jsonb']['output'];
  readonly starts_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};


/** Association table to determine which features users are entitled to */
export type Users_FeaturesMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "users_features" */
export type Users_Features_Aggregate = {
  readonly __typename?: 'users_features_aggregate';
  readonly aggregate?: Maybe<Users_Features_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Users_Features>;
};

export type Users_Features_Aggregate_Bool_Exp = {
  readonly bool_and?: InputMaybe<Users_Features_Aggregate_Bool_Exp_Bool_And>;
  readonly bool_or?: InputMaybe<Users_Features_Aggregate_Bool_Exp_Bool_Or>;
  readonly count?: InputMaybe<Users_Features_Aggregate_Bool_Exp_Count>;
};

export type Users_Features_Aggregate_Bool_Exp_Bool_And = {
  readonly arguments: Users_Features_Select_Column_Users_Features_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Users_Features_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Users_Features_Aggregate_Bool_Exp_Bool_Or = {
  readonly arguments: Users_Features_Select_Column_Users_Features_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Users_Features_Bool_Exp>;
  readonly predicate: Boolean_Comparison_Exp;
};

export type Users_Features_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Users_Features_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Users_Features_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_features" */
export type Users_Features_Aggregate_Fields = {
  readonly __typename?: 'users_features_aggregate_fields';
  readonly avg?: Maybe<Users_Features_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Users_Features_Max_Fields>;
  readonly min?: Maybe<Users_Features_Min_Fields>;
  readonly stddev?: Maybe<Users_Features_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Users_Features_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Users_Features_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Users_Features_Sum_Fields>;
  readonly var_pop?: Maybe<Users_Features_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Users_Features_Var_Samp_Fields>;
  readonly variance?: Maybe<Users_Features_Variance_Fields>;
};


/** aggregate fields of "users_features" */
export type Users_Features_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Users_Features_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users_features" */
export type Users_Features_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Users_Features_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Users_Features_Max_Order_By>;
  readonly min?: InputMaybe<Users_Features_Min_Order_By>;
  readonly stddev?: InputMaybe<Users_Features_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Users_Features_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Users_Features_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Users_Features_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Users_Features_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Users_Features_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Users_Features_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Features_Append_Input = {
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "users_features" */
export type Users_Features_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Users_Features_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Users_Features_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Features_Avg_Fields = {
  readonly __typename?: 'users_features_avg_fields';
  readonly amount?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "users_features" */
export type Users_Features_Avg_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users_features". All fields are combined with a logical 'AND'. */
export type Users_Features_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Users_Features_Bool_Exp>>;
  readonly _not?: InputMaybe<Users_Features_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Users_Features_Bool_Exp>>;
  readonly amount?: InputMaybe<Int_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly enabled?: InputMaybe<Boolean_Comparison_Exp>;
  readonly ends_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly feature?: InputMaybe<Features_Bool_Exp>;
  readonly feature_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly starts_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_features" */
export enum Users_Features_Constraint {
  /** unique or primary key constraint on columns "user_id", "feature_id" */
  UsersFeaturesPkey = 'users_features_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Features_Delete_At_Path_Input = {
  readonly metadata?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Features_Delete_Elem_Input = {
  readonly metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Features_Delete_Key_Input = {
  readonly metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "users_features" */
export type Users_Features_Inc_Input = {
  readonly amount?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "users_features" */
export type Users_Features_Insert_Input = {
  readonly amount?: InputMaybe<Scalars['Int']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly enabled?: InputMaybe<Scalars['Boolean']['input']>;
  readonly ends_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly feature?: InputMaybe<Features_Obj_Rel_Insert_Input>;
  readonly feature_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
  readonly starts_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Users_Features_Max_Fields = {
  readonly __typename?: 'users_features_max_fields';
  readonly amount?: Maybe<Scalars['Int']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly ends_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly feature_id?: Maybe<Scalars['uuid']['output']>;
  readonly starts_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "users_features" */
export type Users_Features_Max_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly ends_at?: InputMaybe<Order_By>;
  readonly feature_id?: InputMaybe<Order_By>;
  readonly starts_at?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Features_Min_Fields = {
  readonly __typename?: 'users_features_min_fields';
  readonly amount?: Maybe<Scalars['Int']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly ends_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly feature_id?: Maybe<Scalars['uuid']['output']>;
  readonly starts_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "users_features" */
export type Users_Features_Min_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly ends_at?: InputMaybe<Order_By>;
  readonly feature_id?: InputMaybe<Order_By>;
  readonly starts_at?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_features" */
export type Users_Features_Mutation_Response = {
  readonly __typename?: 'users_features_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Users_Features>;
};

/** on_conflict condition type for table "users_features" */
export type Users_Features_On_Conflict = {
  readonly constraint: Users_Features_Constraint;
  readonly update_columns?: ReadonlyArray<Users_Features_Update_Column>;
  readonly where?: InputMaybe<Users_Features_Bool_Exp>;
};

/** Ordering options when selecting data from "users_features". */
export type Users_Features_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly enabled?: InputMaybe<Order_By>;
  readonly ends_at?: InputMaybe<Order_By>;
  readonly feature?: InputMaybe<Features_Order_By>;
  readonly feature_id?: InputMaybe<Order_By>;
  readonly metadata?: InputMaybe<Order_By>;
  readonly starts_at?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_features */
export type Users_Features_Pk_Columns_Input = {
  readonly feature_id: Scalars['uuid']['input'];
  readonly user_id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Features_Prepend_Input = {
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "users_features" */
export enum Users_Features_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  FeatureId = 'feature_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "users_features_aggregate_bool_exp_bool_and_arguments_columns" columns of table "users_features" */
export enum Users_Features_Select_Column_Users_Features_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Enabled = 'enabled'
}

/** select "users_features_aggregate_bool_exp_bool_or_arguments_columns" columns of table "users_features" */
export enum Users_Features_Select_Column_Users_Features_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Enabled = 'enabled'
}

/** input type for updating data in table "users_features" */
export type Users_Features_Set_Input = {
  readonly amount?: InputMaybe<Scalars['Int']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly enabled?: InputMaybe<Scalars['Boolean']['input']>;
  readonly ends_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly feature_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
  readonly starts_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Users_Features_Stddev_Fields = {
  readonly __typename?: 'users_features_stddev_fields';
  readonly amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "users_features" */
export type Users_Features_Stddev_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Features_Stddev_Pop_Fields = {
  readonly __typename?: 'users_features_stddev_pop_fields';
  readonly amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "users_features" */
export type Users_Features_Stddev_Pop_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Features_Stddev_Samp_Fields = {
  readonly __typename?: 'users_features_stddev_samp_fields';
  readonly amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "users_features" */
export type Users_Features_Stddev_Samp_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users_features" */
export type Users_Features_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Users_Features_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Features_Stream_Cursor_Value_Input = {
  readonly amount?: InputMaybe<Scalars['Int']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly enabled?: InputMaybe<Scalars['Boolean']['input']>;
  readonly ends_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly feature_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly metadata?: InputMaybe<Scalars['jsonb']['input']>;
  readonly starts_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Users_Features_Sum_Fields = {
  readonly __typename?: 'users_features_sum_fields';
  readonly amount?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "users_features" */
export type Users_Features_Sum_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** update columns of table "users_features" */
export enum Users_Features_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  EndsAt = 'ends_at',
  /** column name */
  FeatureId = 'feature_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  StartsAt = 'starts_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Users_Features_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Users_Features_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Users_Features_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Users_Features_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Users_Features_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Users_Features_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Users_Features_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Users_Features_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Users_Features_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Features_Var_Pop_Fields = {
  readonly __typename?: 'users_features_var_pop_fields';
  readonly amount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "users_features" */
export type Users_Features_Var_Pop_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Features_Var_Samp_Fields = {
  readonly __typename?: 'users_features_var_samp_fields';
  readonly amount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "users_features" */
export type Users_Features_Var_Samp_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Features_Variance_Fields = {
  readonly __typename?: 'users_features_variance_fields';
  readonly amount?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "users_features" */
export type Users_Features_Variance_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  readonly age?: InputMaybe<Scalars['Int']['input']>;
  readonly follower_count?: InputMaybe<Scalars['Int']['input']>;
  readonly following_count?: InputMaybe<Scalars['Int']['input']>;
  readonly level?: InputMaybe<Scalars['Int']['input']>;
  readonly money?: InputMaybe<Scalars['numeric']['input']>;
  readonly season_xp?: InputMaybe<Scalars['Int']['input']>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  readonly accounts?: InputMaybe<Accounts_Arr_Rel_Insert_Input>;
  readonly address?: InputMaybe<Scalars['String']['input']>;
  readonly age?: InputMaybe<Scalars['Int']['input']>;
  readonly bio?: InputMaybe<Scalars['String']['input']>;
  readonly birthday?: InputMaybe<Scalars['date']['input']>;
  readonly channels_users?: InputMaybe<Channels_Users_Arr_Rel_Insert_Input>;
  readonly children?: InputMaybe<Users_Arr_Rel_Insert_Input>;
  readonly club_memberships?: InputMaybe<Clubs_Members_Arr_Rel_Insert_Input>;
  readonly competitions_hosting?: InputMaybe<Competitions_Arr_Rel_Insert_Input>;
  readonly competitions_judging?: InputMaybe<Competitions_Judges_Arr_Rel_Insert_Input>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly discord_id?: InputMaybe<Scalars['String']['input']>;
  readonly discord_user?: InputMaybe<Scalars['jsonb']['input']>;
  readonly division?: InputMaybe<User_Division>;
  readonly dream_job?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly email_verified?: InputMaybe<Scalars['Boolean']['input']>;
  readonly facebook_id?: InputMaybe<Scalars['String']['input']>;
  readonly features?: InputMaybe<Users_Features_Arr_Rel_Insert_Input>;
  readonly feedback?: InputMaybe<Feedback_Arr_Rel_Insert_Input>;
  readonly first_name?: InputMaybe<Scalars['String']['input']>;
  readonly follower_count?: InputMaybe<Scalars['Int']['input']>;
  readonly followers?: InputMaybe<Follows_Arr_Rel_Insert_Input>;
  readonly following?: InputMaybe<Follows_Arr_Rel_Insert_Input>;
  readonly following_count?: InputMaybe<Scalars['Int']['input']>;
  readonly friendships_users?: InputMaybe<Friendships_Users_Arr_Rel_Insert_Input>;
  readonly gender?: InputMaybe<Scalars['String']['input']>;
  readonly google_id?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly intercom_id?: InputMaybe<Scalars['String']['input']>;
  readonly interested_reason?: InputMaybe<Scalars['String']['input']>;
  readonly interests?: InputMaybe<Scalars['String']['input']>;
  readonly invite_code?: InputMaybe<Scalars['String']['input']>;
  readonly last_name?: InputMaybe<Scalars['String']['input']>;
  readonly level?: InputMaybe<Scalars['Int']['input']>;
  readonly meta?: InputMaybe<Scalars['users_meta']['input']>;
  readonly minecraft_skin?: InputMaybe<Minecraft_Skins_Obj_Rel_Insert_Input>;
  readonly minecraft_user?: InputMaybe<Minecraft_User_Obj_Rel_Insert_Input>;
  readonly money?: InputMaybe<Scalars['numeric']['input']>;
  readonly notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  readonly onboarded?: InputMaybe<Scalars['Boolean']['input']>;
  readonly orders?: InputMaybe<Orders_Arr_Rel_Insert_Input>;
  readonly parent?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly parent_email?: InputMaybe<Scalars['String']['input']>;
  readonly parent_id?: InputMaybe<Scalars['String']['input']>;
  readonly password_hash?: InputMaybe<Scalars['String']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly phone_verified?: InputMaybe<Scalars['Boolean']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly posts?: InputMaybe<Posts_Arr_Rel_Insert_Input>;
  readonly presence?: InputMaybe<Scalars['users_presence_meta']['input']>;
  readonly projects_collaborated?: InputMaybe<Projects_Collaborators_Arr_Rel_Insert_Input>;
  readonly projects_comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  readonly projects_created?: InputMaybe<Projects_Arr_Rel_Insert_Input>;
  readonly projects_votes?: InputMaybe<Votes_Arr_Rel_Insert_Input>;
  readonly prospective_user?: InputMaybe<Prospective_Users_Obj_Rel_Insert_Input>;
  readonly role?: InputMaybe<User_Role>;
  readonly season_xp?: InputMaybe<Scalars['Int']['input']>;
  readonly stats?: InputMaybe<Users_Stats_Arr_Rel_Insert_Input>;
  readonly stripe_customer_id?: InputMaybe<Scalars['String']['input']>;
  readonly subscriptions?: InputMaybe<Subscriptions_Arr_Rel_Insert_Input>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly transactions?: InputMaybe<Transactions_Arr_Rel_Insert_Input>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_invites?: InputMaybe<User_Invites_Arr_Rel_Insert_Input>;
  readonly user_invites_received?: InputMaybe<User_Invites_Arr_Rel_Insert_Input>;
  readonly user_presences?: InputMaybe<User_Presence_Arr_Rel_Insert_Input>;
  readonly username?: InputMaybe<Scalars['String']['input']>;
  readonly users_badges?: InputMaybe<Users_Badges_Arr_Rel_Insert_Input>;
  readonly users_levels?: InputMaybe<Users_Levels_Arr_Rel_Insert_Input>;
  readonly users_notifications?: InputMaybe<Users_Notifications_Arr_Rel_Insert_Input>;
  readonly users_presence?: InputMaybe<Users_Presence_Obj_Rel_Insert_Input>;
  readonly users_quests?: InputMaybe<Users_Quests_Arr_Rel_Insert_Input>;
  readonly verified?: InputMaybe<Scalars['Boolean']['input']>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "users_levels" */
export type Users_Levels = {
  readonly __typename?: 'users_levels';
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly level_level: Scalars['Int']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
  readonly xp: Scalars['Int']['output'];
};

/** aggregated selection of "users_levels" */
export type Users_Levels_Aggregate = {
  readonly __typename?: 'users_levels_aggregate';
  readonly aggregate?: Maybe<Users_Levels_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Users_Levels>;
};

export type Users_Levels_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Users_Levels_Aggregate_Bool_Exp_Count>;
};

export type Users_Levels_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Users_Levels_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Users_Levels_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_levels" */
export type Users_Levels_Aggregate_Fields = {
  readonly __typename?: 'users_levels_aggregate_fields';
  readonly avg?: Maybe<Users_Levels_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Users_Levels_Max_Fields>;
  readonly min?: Maybe<Users_Levels_Min_Fields>;
  readonly stddev?: Maybe<Users_Levels_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Users_Levels_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Users_Levels_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Users_Levels_Sum_Fields>;
  readonly var_pop?: Maybe<Users_Levels_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Users_Levels_Var_Samp_Fields>;
  readonly variance?: Maybe<Users_Levels_Variance_Fields>;
};


/** aggregate fields of "users_levels" */
export type Users_Levels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Users_Levels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users_levels" */
export type Users_Levels_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Users_Levels_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Users_Levels_Max_Order_By>;
  readonly min?: InputMaybe<Users_Levels_Min_Order_By>;
  readonly stddev?: InputMaybe<Users_Levels_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Users_Levels_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Users_Levels_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Users_Levels_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Users_Levels_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Users_Levels_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Users_Levels_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "users_levels" */
export type Users_Levels_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Users_Levels_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Users_Levels_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Levels_Avg_Fields = {
  readonly __typename?: 'users_levels_avg_fields';
  readonly level_level?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "users_levels" */
export type Users_Levels_Avg_Order_By = {
  readonly level_level?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users_levels". All fields are combined with a logical 'AND'. */
export type Users_Levels_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Users_Levels_Bool_Exp>>;
  readonly _not?: InputMaybe<Users_Levels_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Users_Levels_Bool_Exp>>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly level_level?: InputMaybe<Int_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
  readonly xp?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_levels" */
export enum Users_Levels_Constraint {
  /** unique or primary key constraint on columns "user_id", "level_level" */
  UsersLevelsLevelLevelUserIdKey = 'users_levels_level_level_user_id_key',
  /** unique or primary key constraint on columns "id" */
  UsersLevelsPkey = 'users_levels_pkey'
}

/** input type for incrementing numeric columns in table "users_levels" */
export type Users_Levels_Inc_Input = {
  readonly level_level?: InputMaybe<Scalars['Int']['input']>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "users_levels" */
export type Users_Levels_Insert_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly level_level?: InputMaybe<Scalars['Int']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Users_Levels_Max_Fields = {
  readonly __typename?: 'users_levels_max_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly level_level?: Maybe<Scalars['Int']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
  readonly xp?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "users_levels" */
export type Users_Levels_Max_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Levels_Min_Fields = {
  readonly __typename?: 'users_levels_min_fields';
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly level_level?: Maybe<Scalars['Int']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
  readonly xp?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "users_levels" */
export type Users_Levels_Min_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_levels" */
export type Users_Levels_Mutation_Response = {
  readonly __typename?: 'users_levels_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Users_Levels>;
};

/** on_conflict condition type for table "users_levels" */
export type Users_Levels_On_Conflict = {
  readonly constraint: Users_Levels_Constraint;
  readonly update_columns?: ReadonlyArray<Users_Levels_Update_Column>;
  readonly where?: InputMaybe<Users_Levels_Bool_Exp>;
};

/** Ordering options when selecting data from "users_levels". */
export type Users_Levels_Order_By = {
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly level_level?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_levels */
export type Users_Levels_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "users_levels" */
export enum Users_Levels_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LevelLevel = 'level_level',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Xp = 'xp'
}

/** input type for updating data in table "users_levels" */
export type Users_Levels_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly level_level?: InputMaybe<Scalars['Int']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Users_Levels_Stddev_Fields = {
  readonly __typename?: 'users_levels_stddev_fields';
  readonly level_level?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "users_levels" */
export type Users_Levels_Stddev_Order_By = {
  readonly level_level?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Levels_Stddev_Pop_Fields = {
  readonly __typename?: 'users_levels_stddev_pop_fields';
  readonly level_level?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "users_levels" */
export type Users_Levels_Stddev_Pop_Order_By = {
  readonly level_level?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Levels_Stddev_Samp_Fields = {
  readonly __typename?: 'users_levels_stddev_samp_fields';
  readonly level_level?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "users_levels" */
export type Users_Levels_Stddev_Samp_Order_By = {
  readonly level_level?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users_levels" */
export type Users_Levels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Users_Levels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Levels_Stream_Cursor_Value_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly level_level?: InputMaybe<Scalars['Int']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Users_Levels_Sum_Fields = {
  readonly __typename?: 'users_levels_sum_fields';
  readonly level_level?: Maybe<Scalars['Int']['output']>;
  readonly xp?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "users_levels" */
export type Users_Levels_Sum_Order_By = {
  readonly level_level?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** update columns of table "users_levels" */
export enum Users_Levels_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LevelLevel = 'level_level',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Xp = 'xp'
}

export type Users_Levels_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Users_Levels_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Users_Levels_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Users_Levels_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Levels_Var_Pop_Fields = {
  readonly __typename?: 'users_levels_var_pop_fields';
  readonly level_level?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "users_levels" */
export type Users_Levels_Var_Pop_Order_By = {
  readonly level_level?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Levels_Var_Samp_Fields = {
  readonly __typename?: 'users_levels_var_samp_fields';
  readonly level_level?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "users_levels" */
export type Users_Levels_Var_Samp_Order_By = {
  readonly level_level?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Levels_Variance_Fields = {
  readonly __typename?: 'users_levels_variance_fields';
  readonly level_level?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "users_levels" */
export type Users_Levels_Variance_Order_By = {
  readonly level_level?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  readonly __typename?: 'users_max_fields';
  readonly address?: Maybe<Scalars['String']['output']>;
  readonly age?: Maybe<Scalars['Int']['output']>;
  readonly bio?: Maybe<Scalars['String']['output']>;
  readonly birthday?: Maybe<Scalars['date']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly discord_id?: Maybe<Scalars['String']['output']>;
  readonly division?: Maybe<Scalars['String']['output']>;
  readonly dream_job?: Maybe<Scalars['String']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly facebook_id?: Maybe<Scalars['String']['output']>;
  readonly first_name?: Maybe<Scalars['String']['output']>;
  readonly follower_count?: Maybe<Scalars['Int']['output']>;
  readonly following_count?: Maybe<Scalars['Int']['output']>;
  readonly gender?: Maybe<Scalars['String']['output']>;
  readonly google_id?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly intercom_id?: Maybe<Scalars['String']['output']>;
  readonly interested_reason?: Maybe<Scalars['String']['output']>;
  readonly interests?: Maybe<Scalars['String']['output']>;
  readonly invite_code?: Maybe<Scalars['String']['output']>;
  readonly last_name?: Maybe<Scalars['String']['output']>;
  readonly level?: Maybe<Scalars['Int']['output']>;
  readonly money?: Maybe<Scalars['numeric']['output']>;
  readonly parent_email?: Maybe<Scalars['String']['output']>;
  readonly parent_id?: Maybe<Scalars['String']['output']>;
  readonly password_hash?: Maybe<Scalars['String']['output']>;
  readonly phone?: Maybe<Scalars['String']['output']>;
  readonly photo?: Maybe<Scalars['String']['output']>;
  readonly role?: Maybe<Scalars['String']['output']>;
  readonly season_xp?: Maybe<Scalars['Int']['output']>;
  readonly stripe_customer_id?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly username?: Maybe<Scalars['String']['output']>;
  readonly xp?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  readonly address?: InputMaybe<Order_By>;
  readonly age?: InputMaybe<Order_By>;
  readonly bio?: InputMaybe<Order_By>;
  readonly birthday?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly discord_id?: InputMaybe<Order_By>;
  readonly division?: InputMaybe<Order_By>;
  readonly dream_job?: InputMaybe<Order_By>;
  readonly email?: InputMaybe<Order_By>;
  readonly facebook_id?: InputMaybe<Order_By>;
  readonly first_name?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly gender?: InputMaybe<Order_By>;
  readonly google_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly intercom_id?: InputMaybe<Order_By>;
  readonly interested_reason?: InputMaybe<Order_By>;
  readonly interests?: InputMaybe<Order_By>;
  readonly invite_code?: InputMaybe<Order_By>;
  readonly last_name?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly parent_email?: InputMaybe<Order_By>;
  readonly parent_id?: InputMaybe<Order_By>;
  readonly password_hash?: InputMaybe<Order_By>;
  readonly phone?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly role?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly stripe_customer_id?: InputMaybe<Order_By>;
  readonly title?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly username?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  readonly __typename?: 'users_min_fields';
  readonly address?: Maybe<Scalars['String']['output']>;
  readonly age?: Maybe<Scalars['Int']['output']>;
  readonly bio?: Maybe<Scalars['String']['output']>;
  readonly birthday?: Maybe<Scalars['date']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly discord_id?: Maybe<Scalars['String']['output']>;
  readonly division?: Maybe<Scalars['String']['output']>;
  readonly dream_job?: Maybe<Scalars['String']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly facebook_id?: Maybe<Scalars['String']['output']>;
  readonly first_name?: Maybe<Scalars['String']['output']>;
  readonly follower_count?: Maybe<Scalars['Int']['output']>;
  readonly following_count?: Maybe<Scalars['Int']['output']>;
  readonly gender?: Maybe<Scalars['String']['output']>;
  readonly google_id?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly intercom_id?: Maybe<Scalars['String']['output']>;
  readonly interested_reason?: Maybe<Scalars['String']['output']>;
  readonly interests?: Maybe<Scalars['String']['output']>;
  readonly invite_code?: Maybe<Scalars['String']['output']>;
  readonly last_name?: Maybe<Scalars['String']['output']>;
  readonly level?: Maybe<Scalars['Int']['output']>;
  readonly money?: Maybe<Scalars['numeric']['output']>;
  readonly parent_email?: Maybe<Scalars['String']['output']>;
  readonly parent_id?: Maybe<Scalars['String']['output']>;
  readonly password_hash?: Maybe<Scalars['String']['output']>;
  readonly phone?: Maybe<Scalars['String']['output']>;
  readonly photo?: Maybe<Scalars['String']['output']>;
  readonly role?: Maybe<Scalars['String']['output']>;
  readonly season_xp?: Maybe<Scalars['Int']['output']>;
  readonly stripe_customer_id?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly username?: Maybe<Scalars['String']['output']>;
  readonly xp?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  readonly address?: InputMaybe<Order_By>;
  readonly age?: InputMaybe<Order_By>;
  readonly bio?: InputMaybe<Order_By>;
  readonly birthday?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly discord_id?: InputMaybe<Order_By>;
  readonly division?: InputMaybe<Order_By>;
  readonly dream_job?: InputMaybe<Order_By>;
  readonly email?: InputMaybe<Order_By>;
  readonly facebook_id?: InputMaybe<Order_By>;
  readonly first_name?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly gender?: InputMaybe<Order_By>;
  readonly google_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly intercom_id?: InputMaybe<Order_By>;
  readonly interested_reason?: InputMaybe<Order_By>;
  readonly interests?: InputMaybe<Order_By>;
  readonly invite_code?: InputMaybe<Order_By>;
  readonly last_name?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly parent_email?: InputMaybe<Order_By>;
  readonly parent_id?: InputMaybe<Order_By>;
  readonly password_hash?: InputMaybe<Order_By>;
  readonly phone?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly role?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly stripe_customer_id?: InputMaybe<Order_By>;
  readonly title?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly username?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  readonly __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Users>;
};

/** columns and relationships of "users_notifications" */
export type Users_Notifications = {
  readonly __typename?: 'users_notifications';
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  readonly notification: Notifications;
  readonly notification_id: Scalars['uuid']['output'];
  readonly read_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};

/** aggregated selection of "users_notifications" */
export type Users_Notifications_Aggregate = {
  readonly __typename?: 'users_notifications_aggregate';
  readonly aggregate?: Maybe<Users_Notifications_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Users_Notifications>;
};

export type Users_Notifications_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Users_Notifications_Aggregate_Bool_Exp_Count>;
};

export type Users_Notifications_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Users_Notifications_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Users_Notifications_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_notifications" */
export type Users_Notifications_Aggregate_Fields = {
  readonly __typename?: 'users_notifications_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Users_Notifications_Max_Fields>;
  readonly min?: Maybe<Users_Notifications_Min_Fields>;
};


/** aggregate fields of "users_notifications" */
export type Users_Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Users_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users_notifications" */
export type Users_Notifications_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Users_Notifications_Max_Order_By>;
  readonly min?: InputMaybe<Users_Notifications_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users_notifications" */
export type Users_Notifications_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Users_Notifications_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Users_Notifications_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users_notifications". All fields are combined with a logical 'AND'. */
export type Users_Notifications_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Users_Notifications_Bool_Exp>>;
  readonly _not?: InputMaybe<Users_Notifications_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Users_Notifications_Bool_Exp>>;
  readonly deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly notification?: InputMaybe<Notifications_Bool_Exp>;
  readonly notification_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly read_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_notifications" */
export enum Users_Notifications_Constraint {
  /** unique or primary key constraint on columns "user_id", "notification_id" */
  UsersNotificationsPkey = 'users_notifications_pkey'
}

/** input type for inserting data into table "users_notifications" */
export type Users_Notifications_Insert_Input = {
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly notification?: InputMaybe<Notifications_Obj_Rel_Insert_Input>;
  readonly notification_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Users_Notifications_Max_Fields = {
  readonly __typename?: 'users_notifications_max_fields';
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly notification_id?: Maybe<Scalars['uuid']['output']>;
  readonly read_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "users_notifications" */
export type Users_Notifications_Max_Order_By = {
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly notification_id?: InputMaybe<Order_By>;
  readonly read_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Notifications_Min_Fields = {
  readonly __typename?: 'users_notifications_min_fields';
  readonly deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly notification_id?: Maybe<Scalars['uuid']['output']>;
  readonly read_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "users_notifications" */
export type Users_Notifications_Min_Order_By = {
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly notification_id?: InputMaybe<Order_By>;
  readonly read_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_notifications" */
export type Users_Notifications_Mutation_Response = {
  readonly __typename?: 'users_notifications_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Users_Notifications>;
};

/** on_conflict condition type for table "users_notifications" */
export type Users_Notifications_On_Conflict = {
  readonly constraint: Users_Notifications_Constraint;
  readonly update_columns?: ReadonlyArray<Users_Notifications_Update_Column>;
  readonly where?: InputMaybe<Users_Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "users_notifications". */
export type Users_Notifications_Order_By = {
  readonly deleted_at?: InputMaybe<Order_By>;
  readonly notification?: InputMaybe<Notifications_Order_By>;
  readonly notification_id?: InputMaybe<Order_By>;
  readonly read_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_notifications */
export type Users_Notifications_Pk_Columns_Input = {
  readonly notification_id: Scalars['uuid']['input'];
  readonly user_id: Scalars['String']['input'];
};

/** select columns of table "users_notifications" */
export enum Users_Notifications_Select_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "users_notifications" */
export type Users_Notifications_Set_Input = {
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly notification_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "users_notifications" */
export type Users_Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Users_Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Notifications_Stream_Cursor_Value_Input = {
  readonly deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly notification_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "users_notifications" */
export enum Users_Notifications_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  UserId = 'user_id'
}

export type Users_Notifications_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Users_Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Users_Notifications_Bool_Exp;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  readonly data: Users_Insert_Input;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  readonly constraint: Users_Constraint;
  readonly update_columns?: ReadonlyArray<Users_Update_Column>;
  readonly where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  readonly accounts_aggregate?: InputMaybe<Accounts_Aggregate_Order_By>;
  readonly address?: InputMaybe<Order_By>;
  readonly age?: InputMaybe<Order_By>;
  readonly bio?: InputMaybe<Order_By>;
  readonly birthday?: InputMaybe<Order_By>;
  readonly channels_users_aggregate?: InputMaybe<Channels_Users_Aggregate_Order_By>;
  readonly children_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  readonly club_memberships_aggregate?: InputMaybe<Clubs_Members_Aggregate_Order_By>;
  readonly competitions_hosting_aggregate?: InputMaybe<Competitions_Aggregate_Order_By>;
  readonly competitions_judging_aggregate?: InputMaybe<Competitions_Judges_Aggregate_Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly current?: InputMaybe<Order_By>;
  readonly discord_id?: InputMaybe<Order_By>;
  readonly discord_user?: InputMaybe<Order_By>;
  readonly division?: InputMaybe<Order_By>;
  readonly dream_job?: InputMaybe<Order_By>;
  readonly email?: InputMaybe<Order_By>;
  readonly email_verified?: InputMaybe<Order_By>;
  readonly facebook_id?: InputMaybe<Order_By>;
  readonly features_aggregate?: InputMaybe<Users_Features_Aggregate_Order_By>;
  readonly feedback_aggregate?: InputMaybe<Feedback_Aggregate_Order_By>;
  readonly first_name?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly followers_aggregate?: InputMaybe<Follows_Aggregate_Order_By>;
  readonly following_aggregate?: InputMaybe<Follows_Aggregate_Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly friendships_users_aggregate?: InputMaybe<Friendships_Users_Aggregate_Order_By>;
  readonly gender?: InputMaybe<Order_By>;
  readonly google_id?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly intercom_id?: InputMaybe<Order_By>;
  readonly interested_reason?: InputMaybe<Order_By>;
  readonly interests?: InputMaybe<Order_By>;
  readonly invite_code?: InputMaybe<Order_By>;
  readonly last_name?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly meta?: InputMaybe<Order_By>;
  readonly minecraft_skin?: InputMaybe<Minecraft_Skins_Order_By>;
  readonly minecraft_user?: InputMaybe<Minecraft_User_Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  readonly onboarded?: InputMaybe<Order_By>;
  readonly orders_aggregate?: InputMaybe<Orders_Aggregate_Order_By>;
  readonly parent?: InputMaybe<Users_Order_By>;
  readonly parent_email?: InputMaybe<Order_By>;
  readonly parent_id?: InputMaybe<Order_By>;
  readonly password_hash?: InputMaybe<Order_By>;
  readonly phone?: InputMaybe<Order_By>;
  readonly phone_verified?: InputMaybe<Order_By>;
  readonly photo?: InputMaybe<Order_By>;
  readonly posts_aggregate?: InputMaybe<Posts_Aggregate_Order_By>;
  readonly presence?: InputMaybe<Order_By>;
  readonly projects_collaborated_aggregate?: InputMaybe<Projects_Collaborators_Aggregate_Order_By>;
  readonly projects_comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  readonly projects_created_aggregate?: InputMaybe<Projects_Aggregate_Order_By>;
  readonly projects_votes_aggregate?: InputMaybe<Votes_Aggregate_Order_By>;
  readonly prospective_user?: InputMaybe<Prospective_Users_Order_By>;
  readonly role?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly stats_aggregate?: InputMaybe<Users_Stats_Aggregate_Order_By>;
  readonly stripe_customer_id?: InputMaybe<Order_By>;
  readonly subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Order_By>;
  readonly title?: InputMaybe<Order_By>;
  readonly transactions_aggregate?: InputMaybe<Transactions_Aggregate_Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_invites_aggregate?: InputMaybe<User_Invites_Aggregate_Order_By>;
  readonly user_invites_received_aggregate?: InputMaybe<User_Invites_Aggregate_Order_By>;
  readonly user_presences_aggregate?: InputMaybe<User_Presence_Aggregate_Order_By>;
  readonly username?: InputMaybe<Order_By>;
  readonly users_badges_aggregate?: InputMaybe<Users_Badges_Aggregate_Order_By>;
  readonly users_levels_aggregate?: InputMaybe<Users_Levels_Aggregate_Order_By>;
  readonly users_notifications_aggregate?: InputMaybe<Users_Notifications_Aggregate_Order_By>;
  readonly users_presence?: InputMaybe<Users_Presence_Order_By>;
  readonly users_quests_aggregate?: InputMaybe<Users_Quests_Aggregate_Order_By>;
  readonly verified?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Prepend_Input = {
  readonly discord_user?: InputMaybe<Scalars['jsonb']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly presence?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "users_presence" */
export type Users_Presence = {
  readonly __typename?: 'users_presence';
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly online?: Maybe<Scalars['Boolean']['output']>;
  readonly online_meta?: Maybe<Scalars['jsonb']['output']>;
};


/** columns and relationships of "users_presence" */
export type Users_PresenceOnline_MetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "users_presence" */
export type Users_Presence_Aggregate = {
  readonly __typename?: 'users_presence_aggregate';
  readonly aggregate?: Maybe<Users_Presence_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Users_Presence>;
};

/** aggregate fields of "users_presence" */
export type Users_Presence_Aggregate_Fields = {
  readonly __typename?: 'users_presence_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Users_Presence_Max_Fields>;
  readonly min?: Maybe<Users_Presence_Min_Fields>;
};


/** aggregate fields of "users_presence" */
export type Users_Presence_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Users_Presence_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Presence_Append_Input = {
  readonly online_meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "users_presence". All fields are combined with a logical 'AND'. */
export type Users_Presence_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Users_Presence_Bool_Exp>>;
  readonly _not?: InputMaybe<Users_Presence_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Users_Presence_Bool_Exp>>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly online?: InputMaybe<Boolean_Comparison_Exp>;
  readonly online_meta?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Presence_Delete_At_Path_Input = {
  readonly online_meta?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Presence_Delete_Elem_Input = {
  readonly online_meta?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Presence_Delete_Key_Input = {
  readonly online_meta?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "users_presence" */
export type Users_Presence_Insert_Input = {
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly online?: InputMaybe<Scalars['Boolean']['input']>;
  readonly online_meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type Users_Presence_Max_Fields = {
  readonly __typename?: 'users_presence_max_fields';
  readonly id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Users_Presence_Min_Fields = {
  readonly __typename?: 'users_presence_min_fields';
  readonly id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "users_presence" */
export type Users_Presence_Mutation_Response = {
  readonly __typename?: 'users_presence_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Users_Presence>;
};

/** input type for inserting object relation for remote table "users_presence" */
export type Users_Presence_Obj_Rel_Insert_Input = {
  readonly data: Users_Presence_Insert_Input;
};

/** Ordering options when selecting data from "users_presence". */
export type Users_Presence_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly online?: InputMaybe<Order_By>;
  readonly online_meta?: InputMaybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Presence_Prepend_Input = {
  readonly online_meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "users_presence" */
export enum Users_Presence_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Online = 'online',
  /** column name */
  OnlineMeta = 'online_meta'
}

/** input type for updating data in table "users_presence" */
export type Users_Presence_Set_Input = {
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly online?: InputMaybe<Scalars['Boolean']['input']>;
  readonly online_meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "users_presence" */
export type Users_Presence_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Users_Presence_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Presence_Stream_Cursor_Value_Input = {
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly online?: InputMaybe<Scalars['Boolean']['input']>;
  readonly online_meta?: InputMaybe<Scalars['jsonb']['input']>;
};

export type Users_Presence_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Users_Presence_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Users_Presence_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Users_Presence_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Users_Presence_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Users_Presence_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Users_Presence_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Users_Presence_Bool_Exp;
};

/** A table for tracking user level and experience. */
export type Users_Progression = {
  readonly __typename?: 'users_progression';
  readonly claimed_levels: Scalars['jsonb']['output'];
  readonly experience: Scalars['Int']['output'];
  readonly updated_at: Scalars['timestamptz']['output'];
  readonly user_id: Scalars['String']['output'];
};


/** A table for tracking user level and experience. */
export type Users_ProgressionClaimed_LevelsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "users_progression" */
export type Users_Progression_Aggregate = {
  readonly __typename?: 'users_progression_aggregate';
  readonly aggregate?: Maybe<Users_Progression_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Users_Progression>;
};

/** aggregate fields of "users_progression" */
export type Users_Progression_Aggregate_Fields = {
  readonly __typename?: 'users_progression_aggregate_fields';
  readonly avg?: Maybe<Users_Progression_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Users_Progression_Max_Fields>;
  readonly min?: Maybe<Users_Progression_Min_Fields>;
  readonly stddev?: Maybe<Users_Progression_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Users_Progression_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Users_Progression_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Users_Progression_Sum_Fields>;
  readonly var_pop?: Maybe<Users_Progression_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Users_Progression_Var_Samp_Fields>;
  readonly variance?: Maybe<Users_Progression_Variance_Fields>;
};


/** aggregate fields of "users_progression" */
export type Users_Progression_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Users_Progression_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Progression_Append_Input = {
  readonly claimed_levels?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Users_Progression_Avg_Fields = {
  readonly __typename?: 'users_progression_avg_fields';
  readonly experience?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "users_progression". All fields are combined with a logical 'AND'. */
export type Users_Progression_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Users_Progression_Bool_Exp>>;
  readonly _not?: InputMaybe<Users_Progression_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Users_Progression_Bool_Exp>>;
  readonly claimed_levels?: InputMaybe<Jsonb_Comparison_Exp>;
  readonly experience?: InputMaybe<Int_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_progression" */
export enum Users_Progression_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UsersProgressionPkey = 'users_progression_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Progression_Delete_At_Path_Input = {
  readonly claimed_levels?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Progression_Delete_Elem_Input = {
  readonly claimed_levels?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Progression_Delete_Key_Input = {
  readonly claimed_levels?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "users_progression" */
export type Users_Progression_Inc_Input = {
  readonly experience?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "users_progression" */
export type Users_Progression_Insert_Input = {
  readonly claimed_levels?: InputMaybe<Scalars['jsonb']['input']>;
  readonly experience?: InputMaybe<Scalars['Int']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Users_Progression_Max_Fields = {
  readonly __typename?: 'users_progression_max_fields';
  readonly experience?: Maybe<Scalars['Int']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Users_Progression_Min_Fields = {
  readonly __typename?: 'users_progression_min_fields';
  readonly experience?: Maybe<Scalars['Int']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "users_progression" */
export type Users_Progression_Mutation_Response = {
  readonly __typename?: 'users_progression_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Users_Progression>;
};

/** on_conflict condition type for table "users_progression" */
export type Users_Progression_On_Conflict = {
  readonly constraint: Users_Progression_Constraint;
  readonly update_columns?: ReadonlyArray<Users_Progression_Update_Column>;
  readonly where?: InputMaybe<Users_Progression_Bool_Exp>;
};

/** Ordering options when selecting data from "users_progression". */
export type Users_Progression_Order_By = {
  readonly claimed_levels?: InputMaybe<Order_By>;
  readonly experience?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_progression */
export type Users_Progression_Pk_Columns_Input = {
  readonly user_id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Progression_Prepend_Input = {
  readonly claimed_levels?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "users_progression" */
export enum Users_Progression_Select_Column {
  /** column name */
  ClaimedLevels = 'claimed_levels',
  /** column name */
  Experience = 'experience',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "users_progression" */
export type Users_Progression_Set_Input = {
  readonly claimed_levels?: InputMaybe<Scalars['jsonb']['input']>;
  readonly experience?: InputMaybe<Scalars['Int']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Users_Progression_Stddev_Fields = {
  readonly __typename?: 'users_progression_stddev_fields';
  readonly experience?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Users_Progression_Stddev_Pop_Fields = {
  readonly __typename?: 'users_progression_stddev_pop_fields';
  readonly experience?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Users_Progression_Stddev_Samp_Fields = {
  readonly __typename?: 'users_progression_stddev_samp_fields';
  readonly experience?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "users_progression" */
export type Users_Progression_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Users_Progression_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Progression_Stream_Cursor_Value_Input = {
  readonly claimed_levels?: InputMaybe<Scalars['jsonb']['input']>;
  readonly experience?: InputMaybe<Scalars['Int']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Users_Progression_Sum_Fields = {
  readonly __typename?: 'users_progression_sum_fields';
  readonly experience?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "users_progression" */
export enum Users_Progression_Update_Column {
  /** column name */
  ClaimedLevels = 'claimed_levels',
  /** column name */
  Experience = 'experience',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Users_Progression_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Users_Progression_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Users_Progression_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Users_Progression_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Users_Progression_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Users_Progression_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Users_Progression_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Users_Progression_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Users_Progression_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Progression_Var_Pop_Fields = {
  readonly __typename?: 'users_progression_var_pop_fields';
  readonly experience?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Users_Progression_Var_Samp_Fields = {
  readonly __typename?: 'users_progression_var_samp_fields';
  readonly experience?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Users_Progression_Variance_Fields = {
  readonly __typename?: 'users_progression_variance_fields';
  readonly experience?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "users_quests" */
export type Users_Quests = {
  readonly __typename?: 'users_quests';
  readonly completed_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly created_at: Scalars['timestamptz']['output'];
  readonly id: Scalars['uuid']['output'];
  /** An object relationship */
  readonly quest: Quests;
  readonly quest_id: Scalars['uuid']['output'];
  readonly rewarded_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
  readonly viewed_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "users_quests" */
export type Users_Quests_Aggregate = {
  readonly __typename?: 'users_quests_aggregate';
  readonly aggregate?: Maybe<Users_Quests_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Users_Quests>;
};

export type Users_Quests_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Users_Quests_Aggregate_Bool_Exp_Count>;
};

export type Users_Quests_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Users_Quests_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Users_Quests_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_quests" */
export type Users_Quests_Aggregate_Fields = {
  readonly __typename?: 'users_quests_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Users_Quests_Max_Fields>;
  readonly min?: Maybe<Users_Quests_Min_Fields>;
};


/** aggregate fields of "users_quests" */
export type Users_Quests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Users_Quests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users_quests" */
export type Users_Quests_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Users_Quests_Max_Order_By>;
  readonly min?: InputMaybe<Users_Quests_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users_quests" */
export type Users_Quests_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Users_Quests_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Users_Quests_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users_quests". All fields are combined with a logical 'AND'. */
export type Users_Quests_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Users_Quests_Bool_Exp>>;
  readonly _not?: InputMaybe<Users_Quests_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Users_Quests_Bool_Exp>>;
  readonly completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly quest?: InputMaybe<Quests_Bool_Exp>;
  readonly quest_id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly rewarded_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
  readonly viewed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_quests" */
export enum Users_Quests_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersQuestsPkey = 'users_quests_pkey',
  /** unique or primary key constraint on columns "quest_id", "user_id" */
  UsersQuestsUserIdQuestIdKey = 'users_quests_user_id_quest_id_key'
}

/** input type for inserting data into table "users_quests" */
export type Users_Quests_Insert_Input = {
  readonly completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly quest?: InputMaybe<Quests_Obj_Rel_Insert_Input>;
  readonly quest_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly rewarded_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly viewed_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Users_Quests_Max_Fields = {
  readonly __typename?: 'users_quests_max_fields';
  readonly completed_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly quest_id?: Maybe<Scalars['uuid']['output']>;
  readonly rewarded_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
  readonly viewed_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "users_quests" */
export type Users_Quests_Max_Order_By = {
  readonly completed_at?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly quest_id?: InputMaybe<Order_By>;
  readonly rewarded_at?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly viewed_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Quests_Min_Fields = {
  readonly __typename?: 'users_quests_min_fields';
  readonly completed_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly quest_id?: Maybe<Scalars['uuid']['output']>;
  readonly rewarded_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly updated_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
  readonly viewed_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "users_quests" */
export type Users_Quests_Min_Order_By = {
  readonly completed_at?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly quest_id?: InputMaybe<Order_By>;
  readonly rewarded_at?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly viewed_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_quests" */
export type Users_Quests_Mutation_Response = {
  readonly __typename?: 'users_quests_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Users_Quests>;
};

/** on_conflict condition type for table "users_quests" */
export type Users_Quests_On_Conflict = {
  readonly constraint: Users_Quests_Constraint;
  readonly update_columns?: ReadonlyArray<Users_Quests_Update_Column>;
  readonly where?: InputMaybe<Users_Quests_Bool_Exp>;
};

/** Ordering options when selecting data from "users_quests". */
export type Users_Quests_Order_By = {
  readonly completed_at?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly quest?: InputMaybe<Quests_Order_By>;
  readonly quest_id?: InputMaybe<Order_By>;
  readonly rewarded_at?: InputMaybe<Order_By>;
  readonly updated_at?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
  readonly viewed_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_quests */
export type Users_Quests_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "users_quests" */
export enum Users_Quests_Select_Column {
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestId = 'quest_id',
  /** column name */
  RewardedAt = 'rewarded_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ViewedAt = 'viewed_at'
}

/** input type for updating data in table "users_quests" */
export type Users_Quests_Set_Input = {
  readonly completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly quest_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly rewarded_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly viewed_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "users_quests" */
export type Users_Quests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Users_Quests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Quests_Stream_Cursor_Value_Input = {
  readonly completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly quest_id?: InputMaybe<Scalars['uuid']['input']>;
  readonly rewarded_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
  readonly viewed_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "users_quests" */
export enum Users_Quests_Update_Column {
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestId = 'quest_id',
  /** column name */
  RewardedAt = 'rewarded_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ViewedAt = 'viewed_at'
}

export type Users_Quests_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Users_Quests_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Users_Quests_Bool_Exp;
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Age = 'age',
  /** column name */
  Bio = 'bio',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiscordId = 'discord_id',
  /** column name */
  DiscordUser = 'discord_user',
  /** column name */
  Division = 'division',
  /** column name */
  DreamJob = 'dream_job',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  FacebookId = 'facebook_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FollowerCount = 'follower_count',
  /** column name */
  FollowingCount = 'following_count',
  /** column name */
  Gender = 'gender',
  /** column name */
  GoogleId = 'google_id',
  /** column name */
  Id = 'id',
  /** column name */
  IntercomId = 'intercom_id',
  /** column name */
  InterestedReason = 'interested_reason',
  /** column name */
  Interests = 'interests',
  /** column name */
  InviteCode = 'invite_code',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Level = 'level',
  /** column name */
  Meta = 'meta',
  /** column name */
  Money = 'money',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  ParentEmail = 'parent_email',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Phone = 'phone',
  /** column name */
  PhoneVerified = 'phone_verified',
  /** column name */
  Photo = 'photo',
  /** column name */
  Presence = 'presence',
  /** column name */
  Role = 'role',
  /** column name */
  SeasonXp = 'season_xp',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username',
  /** column name */
  Verified = 'verified',
  /** column name */
  Xp = 'xp'
}

/** select "users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  PhoneVerified = 'phone_verified',
  /** column name */
  Verified = 'verified'
}

/** select "users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  PhoneVerified = 'phone_verified',
  /** column name */
  Verified = 'verified'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  readonly address?: InputMaybe<Scalars['String']['input']>;
  readonly age?: InputMaybe<Scalars['Int']['input']>;
  readonly bio?: InputMaybe<Scalars['String']['input']>;
  readonly birthday?: InputMaybe<Scalars['date']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly discord_id?: InputMaybe<Scalars['String']['input']>;
  readonly discord_user?: InputMaybe<Scalars['jsonb']['input']>;
  readonly division?: InputMaybe<User_Division>;
  readonly dream_job?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly email_verified?: InputMaybe<Scalars['Boolean']['input']>;
  readonly facebook_id?: InputMaybe<Scalars['String']['input']>;
  readonly first_name?: InputMaybe<Scalars['String']['input']>;
  readonly follower_count?: InputMaybe<Scalars['Int']['input']>;
  readonly following_count?: InputMaybe<Scalars['Int']['input']>;
  readonly gender?: InputMaybe<Scalars['String']['input']>;
  readonly google_id?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly intercom_id?: InputMaybe<Scalars['String']['input']>;
  readonly interested_reason?: InputMaybe<Scalars['String']['input']>;
  readonly interests?: InputMaybe<Scalars['String']['input']>;
  readonly invite_code?: InputMaybe<Scalars['String']['input']>;
  readonly last_name?: InputMaybe<Scalars['String']['input']>;
  readonly level?: InputMaybe<Scalars['Int']['input']>;
  readonly meta?: InputMaybe<Scalars['users_meta']['input']>;
  readonly money?: InputMaybe<Scalars['numeric']['input']>;
  readonly onboarded?: InputMaybe<Scalars['Boolean']['input']>;
  readonly parent_email?: InputMaybe<Scalars['String']['input']>;
  readonly parent_id?: InputMaybe<Scalars['String']['input']>;
  readonly password_hash?: InputMaybe<Scalars['String']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly phone_verified?: InputMaybe<Scalars['Boolean']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly presence?: InputMaybe<Scalars['users_presence_meta']['input']>;
  readonly role?: InputMaybe<User_Role>;
  readonly season_xp?: InputMaybe<Scalars['Int']['input']>;
  readonly stripe_customer_id?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly username?: InputMaybe<Scalars['String']['input']>;
  readonly verified?: InputMaybe<Scalars['Boolean']['input']>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "users_stale_web_presence" */
export type Users_Stale_Web_Presence = {
  readonly __typename?: 'users_stale_web_presence';
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly presence?: Maybe<Scalars['jsonb']['output']>;
};


/** columns and relationships of "users_stale_web_presence" */
export type Users_Stale_Web_PresencePresenceArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "users_stale_web_presence" */
export type Users_Stale_Web_Presence_Aggregate = {
  readonly __typename?: 'users_stale_web_presence_aggregate';
  readonly aggregate?: Maybe<Users_Stale_Web_Presence_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Users_Stale_Web_Presence>;
};

/** aggregate fields of "users_stale_web_presence" */
export type Users_Stale_Web_Presence_Aggregate_Fields = {
  readonly __typename?: 'users_stale_web_presence_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Users_Stale_Web_Presence_Max_Fields>;
  readonly min?: Maybe<Users_Stale_Web_Presence_Min_Fields>;
};


/** aggregate fields of "users_stale_web_presence" */
export type Users_Stale_Web_Presence_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Users_Stale_Web_Presence_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Stale_Web_Presence_Append_Input = {
  readonly presence?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "users_stale_web_presence". All fields are combined with a logical 'AND'. */
export type Users_Stale_Web_Presence_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Users_Stale_Web_Presence_Bool_Exp>>;
  readonly _not?: InputMaybe<Users_Stale_Web_Presence_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Users_Stale_Web_Presence_Bool_Exp>>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly presence?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Stale_Web_Presence_Delete_At_Path_Input = {
  readonly presence?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Stale_Web_Presence_Delete_Elem_Input = {
  readonly presence?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Stale_Web_Presence_Delete_Key_Input = {
  readonly presence?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "users_stale_web_presence" */
export type Users_Stale_Web_Presence_Insert_Input = {
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly presence?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type Users_Stale_Web_Presence_Max_Fields = {
  readonly __typename?: 'users_stale_web_presence_max_fields';
  readonly id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Users_Stale_Web_Presence_Min_Fields = {
  readonly __typename?: 'users_stale_web_presence_min_fields';
  readonly id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "users_stale_web_presence" */
export type Users_Stale_Web_Presence_Mutation_Response = {
  readonly __typename?: 'users_stale_web_presence_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Users_Stale_Web_Presence>;
};

/** Ordering options when selecting data from "users_stale_web_presence". */
export type Users_Stale_Web_Presence_Order_By = {
  readonly id?: InputMaybe<Order_By>;
  readonly presence?: InputMaybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Stale_Web_Presence_Prepend_Input = {
  readonly presence?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "users_stale_web_presence" */
export enum Users_Stale_Web_Presence_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Presence = 'presence'
}

/** input type for updating data in table "users_stale_web_presence" */
export type Users_Stale_Web_Presence_Set_Input = {
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly presence?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "users_stale_web_presence" */
export type Users_Stale_Web_Presence_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Users_Stale_Web_Presence_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stale_Web_Presence_Stream_Cursor_Value_Input = {
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly presence?: InputMaybe<Scalars['jsonb']['input']>;
};

export type Users_Stale_Web_Presence_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Users_Stale_Web_Presence_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Users_Stale_Web_Presence_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Users_Stale_Web_Presence_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Users_Stale_Web_Presence_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Users_Stale_Web_Presence_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Users_Stale_Web_Presence_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Users_Stale_Web_Presence_Bool_Exp;
};

/** columns and relationships of "users_stats" */
export type Users_Stats = {
  readonly __typename?: 'users_stats';
  readonly amount: Scalars['Int']['output'];
  readonly associated_object: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly last_updated: Scalars['timestamptz']['output'];
  readonly stat: Scalars['String']['output'];
  /** An object relationship */
  readonly user?: Maybe<Users>;
  readonly user_id: Scalars['String']['output'];
};

/** aggregated selection of "users_stats" */
export type Users_Stats_Aggregate = {
  readonly __typename?: 'users_stats_aggregate';
  readonly aggregate?: Maybe<Users_Stats_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Users_Stats>;
};

export type Users_Stats_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Users_Stats_Aggregate_Bool_Exp_Count>;
};

export type Users_Stats_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Users_Stats_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Users_Stats_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_stats" */
export type Users_Stats_Aggregate_Fields = {
  readonly __typename?: 'users_stats_aggregate_fields';
  readonly avg?: Maybe<Users_Stats_Avg_Fields>;
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Users_Stats_Max_Fields>;
  readonly min?: Maybe<Users_Stats_Min_Fields>;
  readonly stddev?: Maybe<Users_Stats_Stddev_Fields>;
  readonly stddev_pop?: Maybe<Users_Stats_Stddev_Pop_Fields>;
  readonly stddev_samp?: Maybe<Users_Stats_Stddev_Samp_Fields>;
  readonly sum?: Maybe<Users_Stats_Sum_Fields>;
  readonly var_pop?: Maybe<Users_Stats_Var_Pop_Fields>;
  readonly var_samp?: Maybe<Users_Stats_Var_Samp_Fields>;
  readonly variance?: Maybe<Users_Stats_Variance_Fields>;
};


/** aggregate fields of "users_stats" */
export type Users_Stats_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Users_Stats_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users_stats" */
export type Users_Stats_Aggregate_Order_By = {
  readonly avg?: InputMaybe<Users_Stats_Avg_Order_By>;
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Users_Stats_Max_Order_By>;
  readonly min?: InputMaybe<Users_Stats_Min_Order_By>;
  readonly stddev?: InputMaybe<Users_Stats_Stddev_Order_By>;
  readonly stddev_pop?: InputMaybe<Users_Stats_Stddev_Pop_Order_By>;
  readonly stddev_samp?: InputMaybe<Users_Stats_Stddev_Samp_Order_By>;
  readonly sum?: InputMaybe<Users_Stats_Sum_Order_By>;
  readonly var_pop?: InputMaybe<Users_Stats_Var_Pop_Order_By>;
  readonly var_samp?: InputMaybe<Users_Stats_Var_Samp_Order_By>;
  readonly variance?: InputMaybe<Users_Stats_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "users_stats" */
export type Users_Stats_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Users_Stats_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Users_Stats_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Stats_Avg_Fields = {
  readonly __typename?: 'users_stats_avg_fields';
  readonly amount?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "users_stats" */
export type Users_Stats_Avg_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users_stats". All fields are combined with a logical 'AND'. */
export type Users_Stats_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Users_Stats_Bool_Exp>>;
  readonly _not?: InputMaybe<Users_Stats_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Users_Stats_Bool_Exp>>;
  readonly amount?: InputMaybe<Int_Comparison_Exp>;
  readonly associated_object?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<String_Comparison_Exp>;
  readonly last_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly stat?: InputMaybe<String_Comparison_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_stats" */
export enum Users_Stats_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersStatsPkey = 'users_stats_pkey',
  /** unique or primary key constraint on columns "user_id", "stat", "associated_object" */
  UsersStatsUserIdStatAssociatedObjectKey = 'users_stats_user_id_stat_associated_object_key'
}

/** input type for incrementing numeric columns in table "users_stats" */
export type Users_Stats_Inc_Input = {
  readonly amount?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "users_stats" */
export type Users_Stats_Insert_Input = {
  readonly amount?: InputMaybe<Scalars['Int']['input']>;
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly last_updated?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly stat?: InputMaybe<Scalars['String']['input']>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Users_Stats_Max_Fields = {
  readonly __typename?: 'users_stats_max_fields';
  readonly amount?: Maybe<Scalars['Int']['output']>;
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly last_updated?: Maybe<Scalars['timestamptz']['output']>;
  readonly stat?: Maybe<Scalars['String']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "users_stats" */
export type Users_Stats_Max_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
  readonly associated_object?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly last_updated?: InputMaybe<Order_By>;
  readonly stat?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Stats_Min_Fields = {
  readonly __typename?: 'users_stats_min_fields';
  readonly amount?: Maybe<Scalars['Int']['output']>;
  readonly associated_object?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly last_updated?: Maybe<Scalars['timestamptz']['output']>;
  readonly stat?: Maybe<Scalars['String']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "users_stats" */
export type Users_Stats_Min_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
  readonly associated_object?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly last_updated?: InputMaybe<Order_By>;
  readonly stat?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_stats" */
export type Users_Stats_Mutation_Response = {
  readonly __typename?: 'users_stats_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Users_Stats>;
};

/** on_conflict condition type for table "users_stats" */
export type Users_Stats_On_Conflict = {
  readonly constraint: Users_Stats_Constraint;
  readonly update_columns?: ReadonlyArray<Users_Stats_Update_Column>;
  readonly where?: InputMaybe<Users_Stats_Bool_Exp>;
};

/** Ordering options when selecting data from "users_stats". */
export type Users_Stats_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
  readonly associated_object?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly last_updated?: InputMaybe<Order_By>;
  readonly stat?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_stats */
export type Users_Stats_Pk_Columns_Input = {
  readonly id: Scalars['String']['input'];
};

/** select columns of table "users_stats" */
export enum Users_Stats_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  Id = 'id',
  /** column name */
  LastUpdated = 'last_updated',
  /** column name */
  Stat = 'stat',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "users_stats" */
export type Users_Stats_Set_Input = {
  readonly amount?: InputMaybe<Scalars['Int']['input']>;
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly last_updated?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly stat?: InputMaybe<Scalars['String']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Users_Stats_Stddev_Fields = {
  readonly __typename?: 'users_stats_stddev_fields';
  readonly amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "users_stats" */
export type Users_Stats_Stddev_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Stats_Stddev_Pop_Fields = {
  readonly __typename?: 'users_stats_stddev_pop_fields';
  readonly amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "users_stats" */
export type Users_Stats_Stddev_Pop_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Stats_Stddev_Samp_Fields = {
  readonly __typename?: 'users_stats_stddev_samp_fields';
  readonly amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "users_stats" */
export type Users_Stats_Stddev_Samp_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users_stats" */
export type Users_Stats_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Users_Stats_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stats_Stream_Cursor_Value_Input = {
  readonly amount?: InputMaybe<Scalars['Int']['input']>;
  readonly associated_object?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly last_updated?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly stat?: InputMaybe<Scalars['String']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Users_Stats_Sum_Fields = {
  readonly __typename?: 'users_stats_sum_fields';
  readonly amount?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "users_stats" */
export type Users_Stats_Sum_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** update columns of table "users_stats" */
export enum Users_Stats_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AssociatedObject = 'associated_object',
  /** column name */
  Id = 'id',
  /** column name */
  LastUpdated = 'last_updated',
  /** column name */
  Stat = 'stat',
  /** column name */
  UserId = 'user_id'
}

export type Users_Stats_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Users_Stats_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Users_Stats_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Users_Stats_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Stats_Var_Pop_Fields = {
  readonly __typename?: 'users_stats_var_pop_fields';
  readonly amount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "users_stats" */
export type Users_Stats_Var_Pop_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Stats_Var_Samp_Fields = {
  readonly __typename?: 'users_stats_var_samp_fields';
  readonly amount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "users_stats" */
export type Users_Stats_Var_Samp_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Stats_Variance_Fields = {
  readonly __typename?: 'users_stats_variance_fields';
  readonly amount?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "users_stats" */
export type Users_Stats_Variance_Order_By = {
  readonly amount?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  readonly __typename?: 'users_stddev_fields';
  readonly age?: Maybe<Scalars['Float']['output']>;
  readonly follower_count?: Maybe<Scalars['Float']['output']>;
  readonly following_count?: Maybe<Scalars['Float']['output']>;
  readonly level?: Maybe<Scalars['Float']['output']>;
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly season_xp?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "users" */
export type Users_Stddev_Order_By = {
  readonly age?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  readonly __typename?: 'users_stddev_pop_fields';
  readonly age?: Maybe<Scalars['Float']['output']>;
  readonly follower_count?: Maybe<Scalars['Float']['output']>;
  readonly following_count?: Maybe<Scalars['Float']['output']>;
  readonly level?: Maybe<Scalars['Float']['output']>;
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly season_xp?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "users" */
export type Users_Stddev_Pop_Order_By = {
  readonly age?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  readonly __typename?: 'users_stddev_samp_fields';
  readonly age?: Maybe<Scalars['Float']['output']>;
  readonly follower_count?: Maybe<Scalars['Float']['output']>;
  readonly following_count?: Maybe<Scalars['Float']['output']>;
  readonly level?: Maybe<Scalars['Float']['output']>;
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly season_xp?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "users" */
export type Users_Stddev_Samp_Order_By = {
  readonly age?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  readonly address?: InputMaybe<Scalars['String']['input']>;
  readonly age?: InputMaybe<Scalars['Int']['input']>;
  readonly bio?: InputMaybe<Scalars['String']['input']>;
  readonly birthday?: InputMaybe<Scalars['date']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly discord_id?: InputMaybe<Scalars['String']['input']>;
  readonly discord_user?: InputMaybe<Scalars['jsonb']['input']>;
  readonly division?: InputMaybe<Scalars['String']['input']>;
  readonly dream_job?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly email_verified?: InputMaybe<Scalars['Boolean']['input']>;
  readonly facebook_id?: InputMaybe<Scalars['String']['input']>;
  readonly first_name?: InputMaybe<Scalars['String']['input']>;
  readonly follower_count?: InputMaybe<Scalars['Int']['input']>;
  readonly following_count?: InputMaybe<Scalars['Int']['input']>;
  readonly gender?: InputMaybe<Scalars['String']['input']>;
  readonly google_id?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly intercom_id?: InputMaybe<Scalars['String']['input']>;
  readonly interested_reason?: InputMaybe<Scalars['String']['input']>;
  readonly interests?: InputMaybe<Scalars['String']['input']>;
  readonly invite_code?: InputMaybe<Scalars['String']['input']>;
  readonly last_name?: InputMaybe<Scalars['String']['input']>;
  readonly level?: InputMaybe<Scalars['Int']['input']>;
  readonly meta?: InputMaybe<Scalars['jsonb']['input']>;
  readonly money?: InputMaybe<Scalars['numeric']['input']>;
  readonly onboarded?: InputMaybe<Scalars['Boolean']['input']>;
  readonly parent_email?: InputMaybe<Scalars['String']['input']>;
  readonly parent_id?: InputMaybe<Scalars['String']['input']>;
  readonly password_hash?: InputMaybe<Scalars['String']['input']>;
  readonly phone?: InputMaybe<Scalars['String']['input']>;
  readonly phone_verified?: InputMaybe<Scalars['Boolean']['input']>;
  readonly photo?: InputMaybe<Scalars['String']['input']>;
  readonly presence?: InputMaybe<Scalars['jsonb']['input']>;
  readonly role?: InputMaybe<Scalars['String']['input']>;
  readonly season_xp?: InputMaybe<Scalars['Int']['input']>;
  readonly stripe_customer_id?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly username?: InputMaybe<Scalars['String']['input']>;
  readonly verified?: InputMaybe<Scalars['Boolean']['input']>;
  readonly xp?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  readonly __typename?: 'users_sum_fields';
  readonly age?: Maybe<Scalars['Int']['output']>;
  readonly follower_count?: Maybe<Scalars['Int']['output']>;
  readonly following_count?: Maybe<Scalars['Int']['output']>;
  readonly level?: Maybe<Scalars['Int']['output']>;
  readonly money?: Maybe<Scalars['numeric']['output']>;
  readonly season_xp?: Maybe<Scalars['Int']['output']>;
  readonly xp?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "users" */
export type Users_Sum_Order_By = {
  readonly age?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Age = 'age',
  /** column name */
  Bio = 'bio',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiscordId = 'discord_id',
  /** column name */
  DiscordUser = 'discord_user',
  /** column name */
  Division = 'division',
  /** column name */
  DreamJob = 'dream_job',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  FacebookId = 'facebook_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FollowerCount = 'follower_count',
  /** column name */
  FollowingCount = 'following_count',
  /** column name */
  Gender = 'gender',
  /** column name */
  GoogleId = 'google_id',
  /** column name */
  Id = 'id',
  /** column name */
  IntercomId = 'intercom_id',
  /** column name */
  InterestedReason = 'interested_reason',
  /** column name */
  Interests = 'interests',
  /** column name */
  InviteCode = 'invite_code',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Level = 'level',
  /** column name */
  Meta = 'meta',
  /** column name */
  Money = 'money',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  ParentEmail = 'parent_email',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Phone = 'phone',
  /** column name */
  PhoneVerified = 'phone_verified',
  /** column name */
  Photo = 'photo',
  /** column name */
  Presence = 'presence',
  /** column name */
  Role = 'role',
  /** column name */
  SeasonXp = 'season_xp',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username',
  /** column name */
  Verified = 'verified',
  /** column name */
  Xp = 'xp'
}

export type Users_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  readonly _append?: InputMaybe<Users_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  readonly _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  readonly _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  readonly _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  readonly _inc?: InputMaybe<Users_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  readonly _prepend?: InputMaybe<Users_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  readonly __typename?: 'users_var_pop_fields';
  readonly age?: Maybe<Scalars['Float']['output']>;
  readonly follower_count?: Maybe<Scalars['Float']['output']>;
  readonly following_count?: Maybe<Scalars['Float']['output']>;
  readonly level?: Maybe<Scalars['Float']['output']>;
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly season_xp?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "users" */
export type Users_Var_Pop_Order_By = {
  readonly age?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  readonly __typename?: 'users_var_samp_fields';
  readonly age?: Maybe<Scalars['Float']['output']>;
  readonly follower_count?: Maybe<Scalars['Float']['output']>;
  readonly following_count?: Maybe<Scalars['Float']['output']>;
  readonly level?: Maybe<Scalars['Float']['output']>;
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly season_xp?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "users" */
export type Users_Var_Samp_Order_By = {
  readonly age?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  readonly __typename?: 'users_variance_fields';
  readonly age?: Maybe<Scalars['Float']['output']>;
  readonly follower_count?: Maybe<Scalars['Float']['output']>;
  readonly following_count?: Maybe<Scalars['Float']['output']>;
  readonly level?: Maybe<Scalars['Float']['output']>;
  readonly money?: Maybe<Scalars['Float']['output']>;
  readonly season_xp?: Maybe<Scalars['Float']['output']>;
  readonly xp?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "users" */
export type Users_Variance_Order_By = {
  readonly age?: InputMaybe<Order_By>;
  readonly follower_count?: InputMaybe<Order_By>;
  readonly following_count?: InputMaybe<Order_By>;
  readonly level?: InputMaybe<Order_By>;
  readonly money?: InputMaybe<Order_By>;
  readonly season_xp?: InputMaybe<Order_By>;
  readonly xp?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  readonly _contained_in?: InputMaybe<ReadonlyArray<Scalars['uuid']['input']>>;
  /** does the array contain the given value */
  readonly _contains?: InputMaybe<ReadonlyArray<Scalars['uuid']['input']>>;
  readonly _eq?: InputMaybe<ReadonlyArray<Scalars['uuid']['input']>>;
  readonly _gt?: InputMaybe<ReadonlyArray<Scalars['uuid']['input']>>;
  readonly _gte?: InputMaybe<ReadonlyArray<Scalars['uuid']['input']>>;
  readonly _in?: InputMaybe<ReadonlyArray<ReadonlyArray<Scalars['uuid']['input']>>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lt?: InputMaybe<ReadonlyArray<Scalars['uuid']['input']>>;
  readonly _lte?: InputMaybe<ReadonlyArray<Scalars['uuid']['input']>>;
  readonly _neq?: InputMaybe<ReadonlyArray<Scalars['uuid']['input']>>;
  readonly _nin?: InputMaybe<ReadonlyArray<ReadonlyArray<Scalars['uuid']['input']>>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  readonly _eq?: InputMaybe<Scalars['uuid']['input']>;
  readonly _gt?: InputMaybe<Scalars['uuid']['input']>;
  readonly _gte?: InputMaybe<Scalars['uuid']['input']>;
  readonly _in?: InputMaybe<ReadonlyArray<Scalars['uuid']['input']>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _lt?: InputMaybe<Scalars['uuid']['input']>;
  readonly _lte?: InputMaybe<Scalars['uuid']['input']>;
  readonly _neq?: InputMaybe<Scalars['uuid']['input']>;
  readonly _nin?: InputMaybe<ReadonlyArray<Scalars['uuid']['input']>>;
};

export enum Vote_Object_Type {
  Competitions = 'competitions',
  Orders = 'orders',
  Posts = 'posts',
  Projects = 'projects',
  Themes = 'themes'
}

export type Vote_Object_Type_Bool_Exp = {
  readonly _eq?: InputMaybe<Vote_Object_Type>;
  readonly _in?: InputMaybe<ReadonlyArray<Vote_Object_Type>>;
  readonly _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  readonly _neq?: InputMaybe<Vote_Object_Type>;
  readonly _nin?: InputMaybe<ReadonlyArray<Vote_Object_Type>>;
};

/** columns and relationships of "votes" */
export type Votes = {
  readonly __typename?: 'votes';
  readonly association_id: Scalars['String']['output'];
  readonly created_at: Scalars['timestamptz']['output'];
  readonly emote_hex: Scalars['String']['output'];
  readonly emote_name: Scalars['String']['output'];
  readonly id: Scalars['uuid']['output'];
  readonly object_id: Scalars['String']['output'];
  readonly object_type: Vote_Object_Type;
  /** An object relationship */
  readonly user: Users;
  readonly user_id: Scalars['String']['output'];
};

/** aggregated selection of "votes" */
export type Votes_Aggregate = {
  readonly __typename?: 'votes_aggregate';
  readonly aggregate?: Maybe<Votes_Aggregate_Fields>;
  readonly nodes: ReadonlyArray<Votes>;
};

export type Votes_Aggregate_Bool_Exp = {
  readonly count?: InputMaybe<Votes_Aggregate_Bool_Exp_Count>;
};

export type Votes_Aggregate_Bool_Exp_Count = {
  readonly arguments?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  readonly distinct?: InputMaybe<Scalars['Boolean']['input']>;
  readonly filter?: InputMaybe<Votes_Bool_Exp>;
  readonly predicate: Int_Comparison_Exp;
};

/** aggregate fields of "votes" */
export type Votes_Aggregate_Fields = {
  readonly __typename?: 'votes_aggregate_fields';
  readonly count: Scalars['Int']['output'];
  readonly max?: Maybe<Votes_Max_Fields>;
  readonly min?: Maybe<Votes_Min_Fields>;
};


/** aggregate fields of "votes" */
export type Votes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<ReadonlyArray<Votes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "votes" */
export type Votes_Aggregate_Order_By = {
  readonly count?: InputMaybe<Order_By>;
  readonly max?: InputMaybe<Votes_Max_Order_By>;
  readonly min?: InputMaybe<Votes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "votes" */
export type Votes_Arr_Rel_Insert_Input = {
  readonly data: ReadonlyArray<Votes_Insert_Input>;
  /** upsert condition */
  readonly on_conflict?: InputMaybe<Votes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "votes". All fields are combined with a logical 'AND'. */
export type Votes_Bool_Exp = {
  readonly _and?: InputMaybe<ReadonlyArray<Votes_Bool_Exp>>;
  readonly _not?: InputMaybe<Votes_Bool_Exp>;
  readonly _or?: InputMaybe<ReadonlyArray<Votes_Bool_Exp>>;
  readonly association_id?: InputMaybe<String_Comparison_Exp>;
  readonly created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  readonly emote_hex?: InputMaybe<String_Comparison_Exp>;
  readonly emote_name?: InputMaybe<String_Comparison_Exp>;
  readonly id?: InputMaybe<Uuid_Comparison_Exp>;
  readonly object_id?: InputMaybe<String_Comparison_Exp>;
  readonly object_type?: InputMaybe<Vote_Object_Type_Bool_Exp>;
  readonly user?: InputMaybe<Users_Bool_Exp>;
  readonly user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "votes" */
export enum Votes_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectsVotesPkey = 'projects_votes_pkey',
  /** unique or primary key constraint on columns "object_id", "user_id", "emote_name", "object_type" */
  VotesObjectIdEmoteNameUserIdObjectTypeKey = 'votes_object_id_emote_name_user_id_object_type_key'
}

/** input type for inserting data into table "votes" */
export type Votes_Insert_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly emote_hex?: InputMaybe<Scalars['String']['input']>;
  readonly emote_name?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly object_id?: InputMaybe<Scalars['String']['input']>;
  readonly object_type?: InputMaybe<Vote_Object_Type>;
  readonly user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Votes_Max_Fields = {
  readonly __typename?: 'votes_max_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly emote_hex?: Maybe<Scalars['String']['output']>;
  readonly emote_name?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly object_id?: Maybe<Scalars['String']['output']>;
  readonly object_type?: Maybe<Scalars['String']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "votes" */
export type Votes_Max_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly emote_hex?: InputMaybe<Order_By>;
  readonly emote_name?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly object_id?: InputMaybe<Order_By>;
  readonly object_type?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Votes_Min_Fields = {
  readonly __typename?: 'votes_min_fields';
  readonly association_id?: Maybe<Scalars['String']['output']>;
  readonly created_at?: Maybe<Scalars['timestamptz']['output']>;
  readonly emote_hex?: Maybe<Scalars['String']['output']>;
  readonly emote_name?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['uuid']['output']>;
  readonly object_id?: Maybe<Scalars['String']['output']>;
  readonly object_type?: Maybe<Scalars['String']['output']>;
  readonly user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "votes" */
export type Votes_Min_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly emote_hex?: InputMaybe<Order_By>;
  readonly emote_name?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly object_id?: InputMaybe<Order_By>;
  readonly object_type?: InputMaybe<Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "votes" */
export type Votes_Mutation_Response = {
  readonly __typename?: 'votes_mutation_response';
  /** number of rows affected by the mutation */
  readonly affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  readonly returning: ReadonlyArray<Votes>;
};

/** on_conflict condition type for table "votes" */
export type Votes_On_Conflict = {
  readonly constraint: Votes_Constraint;
  readonly update_columns?: ReadonlyArray<Votes_Update_Column>;
  readonly where?: InputMaybe<Votes_Bool_Exp>;
};

/** Ordering options when selecting data from "votes". */
export type Votes_Order_By = {
  readonly association_id?: InputMaybe<Order_By>;
  readonly created_at?: InputMaybe<Order_By>;
  readonly emote_hex?: InputMaybe<Order_By>;
  readonly emote_name?: InputMaybe<Order_By>;
  readonly id?: InputMaybe<Order_By>;
  readonly object_id?: InputMaybe<Order_By>;
  readonly object_type?: InputMaybe<Order_By>;
  readonly user?: InputMaybe<Users_Order_By>;
  readonly user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: votes */
export type Votes_Pk_Columns_Input = {
  readonly id: Scalars['uuid']['input'];
};

/** select columns of table "votes" */
export enum Votes_Select_Column {
  /** column name */
  AssociationId = 'association_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmoteHex = 'emote_hex',
  /** column name */
  EmoteName = 'emote_name',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectId = 'object_id',
  /** column name */
  ObjectType = 'object_type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "votes" */
export type Votes_Set_Input = {
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly emote_hex?: InputMaybe<Scalars['String']['input']>;
  readonly emote_name?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly object_id?: InputMaybe<Scalars['String']['input']>;
  readonly object_type?: InputMaybe<Vote_Object_Type>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "votes" */
export type Votes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  readonly initial_value: Votes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  readonly ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Votes_Stream_Cursor_Value_Input = {
  readonly association_id?: InputMaybe<Scalars['String']['input']>;
  readonly created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  readonly emote_hex?: InputMaybe<Scalars['String']['input']>;
  readonly emote_name?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['uuid']['input']>;
  readonly object_id?: InputMaybe<Scalars['String']['input']>;
  readonly object_type?: InputMaybe<Scalars['String']['input']>;
  readonly user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "votes" */
export enum Votes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmoteHex = 'emote_hex',
  /** column name */
  EmoteName = 'emote_name',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectId = 'object_id',
  /** column name */
  ObjectType = 'object_type',
  /** column name */
  UserId = 'user_id'
}

export type Votes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  readonly _set?: InputMaybe<Votes_Set_Input>;
  /** filter the rows which have to be updated */
  readonly where: Votes_Bool_Exp;
};
