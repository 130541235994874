import hasura from './services/hasura';

export const login = async ({
  username,
  email,
  phone,
  password,
  turnstileToken,
}: {
  username?: string | null;
  email?: string | null;
  phone?: string | null;
  password?: string;
  turnstileToken?: string;
}) => {
  if ((!username && !email && !phone) || !password) {
    return {
      error: {
        type: 'credentials',
      },
    };
  }
  const res = await fetch('/api/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      email,
      phone,
      password,
      turnstileToken,
    }),
  });

  const data = await res.json();

  if (res.status === 403) {
    return {
      error: {
        type: 'disabled',
        message: data.message,
      },
    };
  }

  return data;
};

export const getCurrentUser = async () => {
  const data = await fetch('/api/user').then(async (r) => await r.json());
  return data?.user;
};

export const getUserByEmail = async (email: string) => {
  const query = `
    query GetUserByEmail($email: String!) {
      users(where: { email: { _eq: $email } }) {
        id
        photo
        email
      }
    }
  `;

  const variables = {
    email,
  };

  const { data } = await hasura.userRequest(query, variables);

  return data.users.length ? data.users[0] : null;
};
