import type { ComponentType } from 'react';

import type { ExtractProps, WrappedComponentBuilder } from '@/client/core/types/react';
import type { TrackingSource } from '@/client/features/analytics/events/tracking-events';

import { AnalyticsSource } from '../providers';

export const withAnalyticsSource: WrappedComponentBuilder<[TrackingSource]> = (source) => (Component) => {
  const wrapper: ComponentType<ExtractProps<typeof Component>> = (props) => (
    <AnalyticsSource value={source}>
      <Component {...props} />
    </AnalyticsSource>
  );

  wrapper.displayName = Component.displayName || Component.name;

  return wrapper;
};
