// @ts-nocheck
import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type InsertChannelEventMutationVariables = Types.Exact<{
  channelId: Types.Scalars['uuid']['input'];
  channelEvent: Types.Scalars['channel_event']['input'];
}>;


export type InsertChannelEventMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly insert_channels_events_one?: Types.Maybe<(
    { readonly __typename?: 'channels_events' }
    & Pick<Types.Channels_Events, 'id'>
  )> }
);



export const InsertChannelEventDocument = `
    mutation InsertChannelEvent($channelId: uuid!, $channelEvent: channel_event!) {
  insert_channels_events_one(
    object: {data: $channelEvent, channel_id: $channelId}
  ) {
    id
  }
}
    `;

export const useInsertChannelEventMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<InsertChannelEventMutation, TError, InsertChannelEventMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<InsertChannelEventMutation, TError, InsertChannelEventMutationVariables, TContext>(
      ['InsertChannelEvent'],
      (variables?: InsertChannelEventMutationVariables) => fetcher<InsertChannelEventMutation, InsertChannelEventMutationVariables>(client, InsertChannelEventDocument, variables, headers)(),
      options
    )};

useInsertChannelEventMutation.getKey = () => ['InsertChannelEvent'];


useInsertChannelEventMutation.fetcher = (client: GraphQLClient, variables: InsertChannelEventMutationVariables, headers?: RequestInit['headers']) => fetcher<InsertChannelEventMutation, InsertChannelEventMutationVariables>(client, InsertChannelEventDocument, variables, headers);
