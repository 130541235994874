// @ts-nocheck
import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { useQuery, useInfiniteQuery, type UseQueryOptions, type UseInfiniteQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetUserPresenceQueryVariables = Types.Exact<{
  user_id: Types.Scalars['String']['input'];
}>;


export type GetUserPresenceQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly users_by_pk?: Types.Maybe<(
    { readonly __typename?: 'users' }
    & Pick<Types.Users, 'presence'>
  )> }
);



export const GetUserPresenceDocument = `
    query GetUserPresence($user_id: String!) {
  users_by_pk(id: $user_id) {
    presence
  }
}
    `;

export const useGetUserPresenceQuery = <
      TData = GetUserPresenceQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetUserPresenceQueryVariables,
      options?: UseQueryOptions<GetUserPresenceQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetUserPresenceQuery, TError, TData>(
      ['GetUserPresence', variables],
      fetcher<GetUserPresenceQuery, GetUserPresenceQueryVariables>(client, GetUserPresenceDocument, variables, headers),
      options
    )};

useGetUserPresenceQuery.getKey = (variables: GetUserPresenceQueryVariables) => ['GetUserPresence', variables];

export const useInfiniteGetUserPresenceQuery = <
      TData = GetUserPresenceQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetUserPresenceQueryVariables,
      options?: UseInfiniteQueryOptions<GetUserPresenceQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetUserPresenceQuery, TError, TData>(
      ['GetUserPresence.infinite', variables],
      (metaData) => fetcher<GetUserPresenceQuery, GetUserPresenceQueryVariables>(client, GetUserPresenceDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetUserPresenceQuery.getKey = (variables: GetUserPresenceQueryVariables) => ['GetUserPresence.infinite', variables];


useGetUserPresenceQuery.fetcher = (client: GraphQLClient, variables: GetUserPresenceQueryVariables, headers?: RequestInit['headers']) => fetcher<GetUserPresenceQuery, GetUserPresenceQueryVariables>(client, GetUserPresenceDocument, variables, headers);
