// @ts-nocheck
import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { useQuery, useInfiniteQuery, type UseQueryOptions, type UseInfiniteQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type UserGetRoomKindQueryVariables = Types.Exact<{
  roomId: Types.Scalars['uuid']['input'];
}>;


export type UserGetRoomKindQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly rooms_by_pk?: Types.Maybe<(
    { readonly __typename?: 'rooms' }
    & Pick<Types.Rooms, 'kind'>
  )> }
);



export const UserGetRoomKindDocument = `
    query UserGetRoomKind($roomId: uuid!) {
  rooms_by_pk(id: $roomId) {
    kind
  }
}
    `;

export const useUserGetRoomKindQuery = <
      TData = UserGetRoomKindQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: UserGetRoomKindQueryVariables,
      options?: UseQueryOptions<UserGetRoomKindQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<UserGetRoomKindQuery, TError, TData>(
      ['UserGetRoomKind', variables],
      fetcher<UserGetRoomKindQuery, UserGetRoomKindQueryVariables>(client, UserGetRoomKindDocument, variables, headers),
      options
    )};

useUserGetRoomKindQuery.getKey = (variables: UserGetRoomKindQueryVariables) => ['UserGetRoomKind', variables];

export const useInfiniteUserGetRoomKindQuery = <
      TData = UserGetRoomKindQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: UserGetRoomKindQueryVariables,
      options?: UseInfiniteQueryOptions<UserGetRoomKindQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<UserGetRoomKindQuery, TError, TData>(
      ['UserGetRoomKind.infinite', variables],
      (metaData) => fetcher<UserGetRoomKindQuery, UserGetRoomKindQueryVariables>(client, UserGetRoomKindDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteUserGetRoomKindQuery.getKey = (variables: UserGetRoomKindQueryVariables) => ['UserGetRoomKind.infinite', variables];


useUserGetRoomKindQuery.fetcher = (client: GraphQLClient, variables: UserGetRoomKindQueryVariables, headers?: RequestInit['headers']) => fetcher<UserGetRoomKindQuery, UserGetRoomKindQueryVariables>(client, UserGetRoomKindDocument, variables, headers);
