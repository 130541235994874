'use client';

import { useDailyEvent, useDevices } from '@daily-co/daily-react';
import React, { createContext, useCallback, useContext, useEffect } from 'react';
import { useIntervalWhen } from 'rooks';
import { UAParser } from 'ua-parser-js';

import type {
  MediaDevicesContext,
  MediaDevicesProviderProps,
  MediaPermissionsDeviceErrors,
  MediaPermissionsErrorType,
} from '@/providers/daily/media-devices/media-devices-provider.types';
import { useMediaDevicesStore } from '@/stores/media-devices/media-devices-store';

const parseError = ({ name: errorName, message: errorMessage }: any): MediaPermissionsErrorType => {
  const parser = new UAParser(window.navigator.userAgent);
  const browserName = parser.getBrowser().name?.toLowerCase();

  const name = errorName.toLowerCase();
  const message = errorMessage.toLowerCase();
  let errorType: MediaPermissionsErrorType = 'UnknownDenied';

  if (browserName === 'chrome') {
    if (name === 'notallowederror') {
      if (message === 'permission denied by system') {
        errorType = 'SystemDenied';
      } else if (message === 'permission denied') {
        errorType = 'UserDenied';
      }
    } else if (name === 'notreadableerror') {
      errorType = 'Unavailable';
    }
  } else if (browserName === 'safari') {
    if (name === 'notallowederror') {
      errorType = 'UserDenied';
    }
  } else if (browserName === 'edge') {
    if (name === 'notallowederror') {
      errorType = 'UserDenied';
    } else if (name === 'notreadableerror') {
      errorType = 'Unavailable';
    }
  } else if (browserName === 'firefox') {
    if (name === 'notfounderror' || name === 'notreadableerror') {
      errorType = 'SystemDenied';
    } else if (name === 'notallowederror') {
      errorType = 'UserDenied';
    } else if (name === 'aborterror') {
      errorType = 'Unavailable';
    }
  }
  return errorType;
};

const Context = createContext<MediaDevicesContext | null>(null);

const formatDailyError = (devices: Array<'video' | 'audio'>, error: MediaPermissionsErrorType) =>
  devices.reduce<MediaPermissionsDeviceErrors>(
    (acc, currentValue) => {
      acc[currentValue] = error;

      return acc;
    },
    { video: null, audio: null }
  );

export const MediaDevicesProvider = ({ children }: MediaDevicesProviderProps) => {
  const { refreshDevices, hasCamError, hasMicError } = useDevices();
  const setDeviceErrors = useMediaDevicesStore((state) => state.setDeviceErrors);
  const deviceErrors = useMediaDevicesStore((state) => state.deviceErrors);

  const getPermission = async (name: 'camera' | 'microphone') => {
    try {
      return (await window.navigator.permissions.query({ name: name as PermissionName })).state;
    } catch (_error: unknown) {
      return undefined;
    }
  };

  const requestSingleDevicePermissions = useCallback(
    async (type: 'video' | 'audio'): Promise<null | MediaPermissionsErrorType> => {
      try {
        const stream = await window.navigator.mediaDevices.getUserMedia({
          video: type === 'video',
          audio: type === 'audio',
        });

        if (stream) {
          stream.getTracks().forEach((track) => {
            track.stop();
          });

          void refreshDevices();
        }
        return null;
      } catch (err) {
        return parseError(err);
      }
    },
    [refreshDevices]
  );

  const requestVideoDevicePermissions = useCallback(
    async (updateState = true): Promise<null | MediaPermissionsErrorType> => {
      const res = await requestSingleDevicePermissions('video');

      if (updateState) {
        setDeviceErrors({ video: res });
      }
      return res;
    },
    [requestSingleDevicePermissions, setDeviceErrors]
  );

  const requestAudioDevicePermissions = useCallback(
    async (updateState = true): Promise<null | MediaPermissionsErrorType> => {
      const res = await requestSingleDevicePermissions('audio');

      if (updateState) {
        setDeviceErrors({ audio: res });
      }
      return res;
    },
    [requestSingleDevicePermissions, setDeviceErrors]
  );

  const requestBothDevicePermissions = useCallback(async (): Promise<MediaPermissionsDeviceErrors | null> => {
    const errorState: MediaPermissionsDeviceErrors = { audio: null, video: null };
    try {
      const stream = await window.navigator.mediaDevices.getUserMedia({ video: true, audio: true });

      stream.getTracks().forEach((track) => {
        track.stop();
      });
    } catch (err) {
      const errorType = parseError(err);

      errorState.audio = errorType;
      errorState.video = errorType;
    }
    setDeviceErrors(errorState);

    return errorState.audio || errorState.video ? errorState : null;
  }, [setDeviceErrors]);

  const requestBothDevicePermissionsIndividually =
    useCallback(async (): Promise<MediaPermissionsDeviceErrors | null> => {
      const errorState: MediaPermissionsDeviceErrors = { audio: null, video: null };
      const videoError = await requestVideoDevicePermissions(false);
      const audioError = await requestAudioDevicePermissions(false);

      if (videoError) {
        errorState.video = videoError;
      }
      if (audioError) {
        errorState.audio = audioError;
      }
      setDeviceErrors(errorState);

      return errorState.audio || errorState.video ? errorState : null;
    }, [requestVideoDevicePermissions, requestAudioDevicePermissions, setDeviceErrors]);

  const _requestDevicePermissions = useCallback(
    async (combined: boolean): Promise<MediaPermissionsDeviceErrors | null> => {
      const result = combined ? await requestBothDevicePermissions() : await requestBothDevicePermissionsIndividually();

      void refreshDevices();

      return result;
    },
    [requestBothDevicePermissions, requestBothDevicePermissionsIndividually, refreshDevices]
  );

  useEffect(() => {
    if (hasCamError) {
      return;
    }
    setDeviceErrors((errors) => ({ ...errors, video: null }));
  }, [hasCamError, setDeviceErrors]);

  useEffect(() => {
    if (hasMicError) {
      return;
    }
    setDeviceErrors((errors) => ({ ...errors, audio: null }));
  }, [hasMicError, setDeviceErrors]);

  useDailyEvent(
    'camera-error',
    useCallback(
      (error) => {
        const errorType = error.error.type;
        const blockedMedia = 'blockedMedia' in error.error ? error.error.blockedMedia : null;

        if (!blockedMedia) {
          setDeviceErrors({ audio: 'Unavailable', video: 'Unavailable' });

          return;
        }
        switch (errorType) {
          case 'permissions':
            if (error.error.blockedBy === 'user') {
              setDeviceErrors(formatDailyError(blockedMedia, 'UserDenied'));
            } else {
              setDeviceErrors(formatDailyError(blockedMedia, 'SystemDenied'));
            }
            break;
          case 'not-found':
          case 'mic-in-use':
          case 'cam-in-use':
          case 'cam-mic-in-use':
            setDeviceErrors(formatDailyError(blockedMedia, 'Unavailable'));

            break;
          case 'constraints':
          case 'undefined-mediadevices':
          case 'unknown':
            setDeviceErrors(formatDailyError(blockedMedia, 'UnknownDenied'));

            break;
        }
      },
      [setDeviceErrors]
    )
  );

  useIntervalWhen(
    useCallback(() => {
      void Promise.all([getPermission('microphone'), getPermission('camera')]).then(([micState, camState]) => {
        if (!micState || !camState) {
          return;
        }
        const state: MediaPermissionsDeviceErrors = { audio: null, video: null };

        if (micState !== 'granted') {
          state.audio = 'UserDenied';
        }
        if (camState !== 'granted') {
          state.video = 'UserDenied';
        }
        setDeviceErrors(state);
      });
    }, [setDeviceErrors]),
    2500,
    typeof window !== 'undefined' && 'query' in window.navigator.permissions,
    true
  );

  useEffect(() => {
    console.debug('Current device errors', deviceErrors);
  }, [deviceErrors]);

  const requestDevicePermissions = useCallback(
    async (
      constraints:
        | { audio: true; video: true; combined: boolean }
        | { audio?: false | undefined; video: true }
        | { audio: true; video?: false | undefined }
    ): Promise<MediaPermissionsDeviceErrors | null> => {
      if ('combined' in constraints) {
        const result = await _requestDevicePermissions(constraints.combined);
        console.debug('Requested permissions with combined field', constraints);
        console.debug('Request results', result);

        return result;
      }
      if (constraints.audio) {
        const result = await requestAudioDevicePermissions(true);

        console.debug('Requested individual audio permissions', constraints);
        if (!result) {
          console.debug('Request results', result);
          return null;
        }
        console.debug('Request results', result);
        return {
          audio: result,
          video: null,
        };
      }
      const result = await requestVideoDevicePermissions(true);

      console.debug('Requested individual video permissions', constraints);
      if (!result) {
        console.debug('Request results', result);
        return null;
      }
      console.debug('Request results', result);
      return {
        video: result,
        audio: null,
      };
    },
    [_requestDevicePermissions, requestAudioDevicePermissions, requestVideoDevicePermissions]
  );

  const context = {
    deviceErrors,
    requestDevicePermissions,
  };

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const useMediaDevicesContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useMediaDevicesContext must be used within a MediaDevicesProvider');
  }
  return context;
};
