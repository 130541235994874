// @ts-nocheck
import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { useQuery, useInfiniteQuery, type UseQueryOptions, type UseInfiniteQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetCurrentUserQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetCurrentUserQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly users_by_pk?: Types.Maybe<(
    { readonly __typename?: 'users' }
    & Pick<Types.Users, 'address' | 'bio' | 'birthday' | 'created_at' | 'discord_id' | 'discord_user' | 'email' | 'email_verified' | 'first_name' | 'id' | 'last_name' | 'meta' | 'onboarded' | 'parent_id' | 'phone' | 'phone_verified' | 'photo' | 'role' | 'stripe_customer_id' | 'title' | 'username' | 'verified'>
    & { readonly club_memberships: ReadonlyArray<(
      { readonly __typename?: 'clubs_members' }
      & Pick<Types.Clubs_Members, 'onboarded' | 'club_id'>
    )>, readonly accounts: ReadonlyArray<(
      { readonly __typename?: 'accounts' }
      & Pick<Types.Accounts, 'id' | 'username' | 'avatar_url' | 'metadata' | 'club_id'>
    )>, readonly stats: ReadonlyArray<(
      { readonly __typename?: 'users_stats' }
      & Pick<Types.Users_Stats, 'stat' | 'amount'>
    )>, readonly subscriptions: ReadonlyArray<(
      { readonly __typename?: 'subscriptions' }
      & Pick<Types.Subscriptions, 'entitled' | 'status' | 'trial_end'>
    )>, readonly parent?: Types.Maybe<(
      { readonly __typename?: 'users' }
      & Pick<Types.Users, 'id' | 'first_name' | 'email' | 'role' | 'stripe_customer_id'>
      & { readonly subscriptions: ReadonlyArray<(
        { readonly __typename?: 'subscriptions' }
        & Pick<Types.Subscriptions, 'entitled' | 'status' | 'trial_end'>
      )> }
    )>, readonly users_badges: ReadonlyArray<(
      { readonly __typename?: 'users_badges' }
      & { readonly badge: (
        { readonly __typename?: 'badges' }
        & Pick<Types.Badges, 'name' | 'slug' | 'image'>
      ) }
    )> }
  )> }
);



export const GetCurrentUserDocument = `
    query GetCurrentUser($id: String!) {
  users_by_pk(id: $id) {
    address
    bio
    birthday
    created_at
    discord_id
    discord_user
    email
    email_verified
    first_name
    id
    last_name
    meta
    onboarded
    parent_id
    phone
    phone_verified
    photo
    role
    stripe_customer_id
    title
    username
    verified
    club_memberships {
      onboarded
      club_id
    }
    accounts {
      id
      username
      avatar_url
      metadata
      club_id
    }
    stats {
      stat
      amount
    }
    subscriptions {
      entitled
      status
      trial_end
    }
    parent {
      id
      first_name
      email
      role
      stripe_customer_id
      subscriptions {
        entitled
        status
        trial_end
      }
    }
    users_badges(
      where: {level_level: {_is_null: true}, _or: [{expiry: {_gt: "now()"}}, {expiry: {_is_null: true}}]}
      order_by: {expiry: desc_nulls_last}
      limit: 1
    ) {
      badge {
        name
        slug
        image
      }
    }
  }
}
    `;

export const useGetCurrentUserQuery = <
      TData = GetCurrentUserQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetCurrentUserQueryVariables,
      options?: UseQueryOptions<GetCurrentUserQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetCurrentUserQuery, TError, TData>(
      ['GetCurrentUser', variables],
      fetcher<GetCurrentUserQuery, GetCurrentUserQueryVariables>(client, GetCurrentUserDocument, variables, headers),
      options
    )};

useGetCurrentUserQuery.getKey = (variables: GetCurrentUserQueryVariables) => ['GetCurrentUser', variables];

export const useInfiniteGetCurrentUserQuery = <
      TData = GetCurrentUserQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetCurrentUserQueryVariables,
      options?: UseInfiniteQueryOptions<GetCurrentUserQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetCurrentUserQuery, TError, TData>(
      ['GetCurrentUser.infinite', variables],
      (metaData) => fetcher<GetCurrentUserQuery, GetCurrentUserQueryVariables>(client, GetCurrentUserDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetCurrentUserQuery.getKey = (variables: GetCurrentUserQueryVariables) => ['GetCurrentUser.infinite', variables];


useGetCurrentUserQuery.fetcher = (client: GraphQLClient, variables: GetCurrentUserQueryVariables, headers?: RequestInit['headers']) => fetcher<GetCurrentUserQuery, GetCurrentUserQueryVariables>(client, GetCurrentUserDocument, variables, headers);
