import { H } from '@highlight-run/next/client';
import _ from 'lodash';
import { type JSX, type PropsWithChildren, useEffect, useMemo, useState } from 'react';

import type { ApiUser } from '@/api/auth/user';
import { useDependencyTrackingEffect } from '@/client/core/hooks/use-dependency-tracking-effect';
import {
  GetCurrentUserDocument,
  type GetCurrentUserQuery,
  type GetCurrentUserQueryVariables,
} from '@/client/features/user/operations/generated/get-current-user.user';
import { getCurrentUser } from '@/legacy/lib/queries';

import { useGraphQLSubscription } from '../../../core/hooks/use-graphql-subscription';
import { createStrictContext } from '../../../core/utils/react';

interface ApiUserContext {
  user: ApiUser | null;
  isLoading: boolean;
  refetch: () => Promise<void>;
}

const [UserContextProvider, useUserContext] = createStrictContext<ApiUserContext>();

interface Props {
  onUserIdChanged?: (userId: null | string) => void;
  onUserRoleChanged?: (role: null | string) => void;
  user?: ApiUser;
}

export function UserProvider({
  children,
  onUserIdChanged,
  onUserRoleChanged,
  user: apiUser,
}: PropsWithChildren<Props>): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [persistedUser, setUser] = useState<ApiUser | null>(apiUser ?? null);

  // If an authenticated user was to perform a client-side route push from a page that does not require auth to one that
  // does, then we may receive an updated apiUser from the server before our own getCurrentUser() finishes returning.
  const user = persistedUser ?? apiUser ?? null;

  const fetchData = async (): Promise<void> => {
    const data = await getCurrentUser();

    if (data != null) {
      H.identify(data.id, {
        highlightDisplayName: data.dataname ?? data.first_name ?? '',
        role: data.role,
        meta: JSON.stringify(data.meta),
      });
    }
    setUser(data);
    setIsLoading(false);
  };

  useEffect(() => {
    try {
      void fetchData();
    } catch (error: any) {
      console.error('User not found', error);
      setIsLoading(false);
    }
  }, []);

  const memoisedUserValue = useMemo(
    () => ({
      user,
      isLoading,
      refetch: fetchData,
    }),
    [isLoading, user]
  );

  const { id, role } = user ?? { id: null, role: null };

  useDependencyTrackingEffect(
    (previous) => {
      if (previous) {
        onUserIdChanged?.(id);
      }
    },
    [onUserIdChanged, id]
  );

  useDependencyTrackingEffect(
    (previous) => {
      if (previous) {
        onUserRoleChanged?.(role);
      }
    },
    [onUserRoleChanged, role]
  );

  useGraphQLSubscription<GetCurrentUserQuery, GetCurrentUserQueryVariables>({
    ...(user
      ? {
        enabled: true,
        variables: {
          id: user.id,
        },
      }
      : {
        enabled: false,
      }),
    query: GetCurrentUserDocument,
    onNext: (data) => {
      const newUser = data.users_by_pk as ApiUser;

      if (newUser && !_.isEqual(user, newUser)) {
        setUser(newUser);
      }
    },
    onError: console.error,
  });

  return <UserContextProvider value={memoisedUserValue}>{children}</UserContextProvider>;
}

export { useUserContext };
