import Script from 'next/script';

import { REFINER_APP_ID } from '../../legacy/lib/constants';

const ScriptsLoader = (): any => (
  <>
    {/* init refiner app */}
    <Script strategy='afterInteractive' src='https://js.refiner.io/v001/client.js' />
    <Script
      id='refiner-init'
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{
        __html: `
            window._refinerQueue = window._refinerQueue || [];
            function refiner(){_refinerQueue.push(arguments);}
            refiner('setProject', "${REFINER_APP_ID}");
          `,
      }}
    />
  </>
);

export default ScriptsLoader;
