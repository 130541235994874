'use client';

import type { ExecutionResult } from 'graphql';
import type { Client } from 'graphql-ws';
import _ from 'lodash';
import { useEffect, useMemo, useRef } from 'react';

import { useStableCallback } from '@/client/core/hooks/use-stable-callback';

import { useGraphQLSubscriptionClient } from '../providers/graphql-subscription-provider';
import type { Callback } from '../types/react';

type Variables<V> = V extends undefined ? { variables?: never } : { variables: V };

export type GraphQLSubscriptionOptions<R, V, E extends undefined | boolean> = {
  query: string;
  onNext: Callback<R>;
  onError: Callback<unknown>;
} & (E extends undefined | true ? { enabled?: true } & Variables<V> : { enabled: boolean } & Variables<undefined>) & {
    useRealtimeSubscription?: boolean;
  };

export function useGraphQLSubscription<R = any, V = any>(
  options: GraphQLSubscriptionOptions<R, V, undefined | true>
): void;
export function useGraphQLSubscription<R = any, V = any>(options: GraphQLSubscriptionOptions<R, V, boolean>): void;
export function useGraphQLSubscription<R = any, V = any>({
  query,
  variables: latestVariables,
  onNext,
  onError,
  enabled = true,
  useRealtimeSubscription = false,
}: GraphQLSubscriptionOptions<R, V, boolean>): void {
  let subscriptionClient: Client;
  const { realtimeSubscriptionClient, hasuraSubscriptionClient } = useGraphQLSubscriptionClient();
  if (useRealtimeSubscription) {
    if (
      process.env.NEXT_PUBLIC_REALTIME_SUBSCRIPTION_ENDPOINT_REVAMP ||
      process.env.NEXT_PUBLIC_REALTIME_SUBSCRIPTION_ENDPOINT
    ) {
      subscriptionClient = realtimeSubscriptionClient;
    } else {
      console.warn('Realtime subscription endpoint not available, falling back to Hasura subscription endpoint');
      subscriptionClient = hasuraSubscriptionClient;
    }
  } else {
    subscriptionClient = hasuraSubscriptionClient;
  }

  const cachedVariables = useRef<undefined | V>(undefined);
  const variables = useMemo(() => {
    if (!_.isEqual(cachedVariables.current, latestVariables)) {
      cachedVariables.current = latestVariables;
    }

    return cachedVariables.current;
  }, [latestVariables]);

  const next = useStableCallback(({ data, errors }: ExecutionResult<R>) => {
    if (data) {
      void onNext(data);
    }
    if (errors) {
      console.error(errors);
      void onError(errors);
    }
  });

  const error = useStableCallback((error: unknown) => {
    void onError(error);
  });

  useEffect(() => {
    if (!enabled) {
      return;
    }

    return subscriptionClient.subscribe<R>(
      // NOTE: We're using query rather than subscription in our .graphql files because at the time of writing GraphQL
      //       react-query codegen generates uncompilable output when you use subscription.
      { query: query.replace(/^\s*query /, 'subscription '), variables: variables ?? null },
      {
        next,
        error,
        complete: () => {},
      }
    );
  }, [subscriptionClient, query, variables, enabled, next, error]);
}
