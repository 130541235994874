import { type JSX, type PropsWithChildren, useCallback, useMemo, useState } from 'react';

import type { ClubDetailFragment } from '@/client/api/clubs/generated/operations.user';
import type { RoomDetailFragment } from '@/client/api/rooms/generated/operations.user';
import { useGraphQLSubscription } from '@/client/core/hooks/use-graphql-subscription';
import { createStrictContext } from '@/client/core/utils/react';
import {
  GetClubRoomsDocument,
  type GetClubRoomsQuery,
  type GetClubRoomsQueryVariables,
} from '@/client/features/clubs/generated/operations.user';
import type {
  GetUserClubInfoQuery,
  GetUserClubInfoQueryVariables,
} from '@/client/features/clubs/providers/generated/operations.user';
import { GetUserClubInfoDocument } from '@/client/features/clubs/providers/generated/operations.user';
import { useUser } from '@/hooks/use-user';

interface ClubContextValue {
  activeClub: ClubDetailFragment;
  setActiveClub: (clubId: string) => void;
  activeClubRooms: readonly RoomDetailFragment[];
  clubRooms: readonly RoomDetailFragment[];
  clubs: readonly ClubDetailFragment[];
}

const [ClubContextProvider, useClubContext] = createStrictContext<ClubContextValue>();

export interface SidebarProviderProps {
  activeClubId?: string;
  initialClubs: readonly ClubDetailFragment[];
  initialActiveRooms?: readonly RoomDetailFragment[];
}

export function ClubProvider({
  children,
  activeClubId: _activeClubId,
  initialActiveRooms = [],
  initialClubs,
}: PropsWithChildren<SidebarProviderProps>): JSX.Element {
  const { user } = useUser();
  const [activeClubId, _setActiveClubId] = useState<string | undefined>(_activeClubId);
  const [clubs, setClubs] = useState<readonly ClubDetailFragment[]>(initialClubs);
  const [clubRooms, setClubRooms] = useState<readonly RoomDetailFragment[]>(initialActiveRooms);

  useGraphQLSubscription<GetUserClubInfoQuery, GetUserClubInfoQueryVariables>({
    ...(user
      ? { variables: { userId: user.id, parentId: user.parent_id ?? user.id }, enabled: true }
      : { enabled: false }),
    query: GetUserClubInfoDocument,
    onNext: (data) => {
      setClubs(data.clubs);
    },
    onError: console.error,
  });

  const activeClub = useMemo(() => {
    const club = activeClubId == null ? null : clubs.find((club) => club.id === activeClubId);

    if (club == null) {
      throw new Error(
        'The active club was set to a club that the user does not have access to or that does not exist.'
      );
    }
    return club;
  }, [activeClubId, clubs]);

  useGraphQLSubscription<GetClubRoomsQuery, GetClubRoomsQueryVariables>({
    ...(clubs && clubs.length > 0 && user?.id
      ? {
        enabled: true,
        variables: {
          clubIds: clubs.map((club) => club.id) ?? [],
          userId: user.id,
        },
      }
      : {
        enabled: false,
      }),
    query: GetClubRoomsDocument,
    onNext: ({ rooms }) => {
      setClubRooms(rooms);
    },
    onError: console.error,
  });

  const activeClubRooms = useMemo(() => {
    return clubRooms.filter((room) => room.club.id === activeClub?.id);
  }, [activeClub?.id, clubRooms]);

  const setActiveClub = useCallback((clubId: string) => {
    _setActiveClubId(clubId);
  }, []);

  const memoisedSidebarValue = useMemo(
    () => ({
      activeClub,
      clubRooms,
      clubs,
      activeClubRooms,
      setActiveClub,
    }),
    [activeClub, clubRooms, clubs, activeClubRooms, setActiveClub]
  );

  return <ClubContextProvider value={memoisedSidebarValue}>{children}</ClubContextProvider>;
}

export { useClubContext };
