import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { type JSX, type ReactNode, useEffect } from 'react';

import { Transitions } from '@/client/design-system/animations/framer-motion/transitions';
import { Icon } from '@/client/design-system/components/icon';
import { useAlert } from '@/client/features/alert/providers/alert-provider';

interface Props {
  children: ReactNode;
  sticky?: boolean;
  onClose?: () => void;
}

export const Alert = ({ children, sticky = false, onClose, ...props }: Props): JSX.Element => {
  const { clearAlert } = useAlert();
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (_url: string, { shallow }: { shallow: boolean }): void => {
      if (!shallow) {
        clearAlert();
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [clearAlert, router.events]);

  return (
    <div {...props} className='group/toast relative box-border'>
      <motion.div
        initial={{ opacity: 0, y: 24 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -24 }}
        transition={Transitions.Ease}
      >
        {children}

        {!sticky && (
          <button
            className='absolute right-3 top-3 size-4 text-secondary hover:text-primary'
            onClick={onClose}
            aria-label='Close'
          >
            <Icon icon='close' size={16} />
          </button>
        )}
      </motion.div>
    </div>
  );
};
