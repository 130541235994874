'use client';

import React, { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';

import { type GraphQLSubscriptionOptions, useGraphQLSubscription } from '@/client/core/hooks/use-graphql-subscription';
import {
  GetChannelUsersDocument,
  type GetChannelUsersQuery,
  type GetChannelUsersQueryVariables,
} from '@/client/features/calls/operations/generated/get-channel-users.user';
import { useSetChannelPresenceMutation } from '@/client/features/channels/operations/generated/set-channel-presence.user';
import { Channel_User_Status } from '@/generated/graphql/global-types.admin';
import { User_Role } from '@/generated/graphql/global-types.user';
import { wrapFetcher } from '@/shared/graphql/client';

interface UpdateProps {
  channelId: string;
}

interface RemoveProps {
  channelId: string;
}

const setChannelPresence = wrapFetcher(useSetChannelPresenceMutation.fetcher);

export const setPresence = async ({ channelId }: UpdateProps) => {
  const { update_channels_users } = await setChannelPresence({ channelId, status: Channel_User_Status.Active });

  return (update_channels_users?.affected_rows ?? 0) > 0;
};

export const removePresence = async ({ channelId }: RemoveProps): Promise<void> => {
  await setChannelPresence({ channelId, status: Channel_User_Status.Joined });
};

export interface ChannelUser {
  photoUrl?: string;
  handRaised: boolean;
  forceMuted: boolean;
  status: Channel_User_Status;
  username: string;
  admin: boolean;
  connection: 'good' | 'low' | 'very-low';
}

interface ChannelUsersState {
  users?: Record<string, ChannelUser>;
  loading: boolean;
  error?: unknown;
}

interface ChannelUsersContextType {
  subscribeToChannel: (channelId: string) => void;
  unsubscribeFromChannel: (channelId: string) => void;
  getChannelUsers: (channelId: string) => ChannelUsersState;
}

const ChannelUsersContext = createContext<ChannelUsersContextType | undefined>(undefined);

type ChannelUsersAction =
  | { type: 'SUBSCRIBE'; channelId: string }
  | { type: 'UNSUBSCRIBE'; channelId: string }
  | { type: 'UPDATE_USERS'; channelId: string; users: Record<string, ChannelUser> }
  | { type: 'SET_ERROR'; channelId: string; error: unknown };

interface ChannelUsersProviderState {
  channels: Record<string, ChannelUsersState>;
  subscriptions: Record<string, number>;
}

const channelUsersReducer = (
  state: ChannelUsersProviderState,
  action: ChannelUsersAction
): ChannelUsersProviderState => {
  switch (action.type) {
    case 'SUBSCRIBE':
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [action.channelId]: (state.subscriptions[action.channelId] || 0) + 1,
        },
      };
    case 'UNSUBSCRIBE': {
      const newSubscriptions = { ...state.subscriptions };
      newSubscriptions[action.channelId] = Math.max((newSubscriptions[action.channelId] || 0) - 1, 0);
      if (newSubscriptions[action.channelId] === 0) {
        delete newSubscriptions[action.channelId];
        const newChannels = { ...state.channels };
        delete newChannels[action.channelId];
        return { ...state, subscriptions: newSubscriptions, channels: newChannels };
      }
      return { ...state, subscriptions: newSubscriptions };
    }
    case 'UPDATE_USERS':
      return {
        ...state,
        channels: {
          ...state.channels,
          [action.channelId]: { loading: false, users: action.users },
        },
      };
    case 'SET_ERROR':
      return {
        ...state,
        channels: {
          ...state.channels,
          [action.channelId]: { loading: false, error: action.error },
        },
      };
    default:
      return state;
  }
};

export const ChannelUsersProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(channelUsersReducer, { channels: {}, subscriptions: {} });

  const subscribeToChannel = useCallback((channelId: string) => {
    dispatch({ type: 'SUBSCRIBE', channelId });
  }, []);

  const unsubscribeFromChannel = useCallback((channelId: string) => {
    dispatch({ type: 'UNSUBSCRIBE', channelId });
  }, []);

  const getChannelUsers = useCallback(
    (channelId: string): ChannelUsersState => state.channels[channelId] || { loading: true },
    [state.channels]
  );

  const contextValue: ChannelUsersContextType = useMemo(
    () => ({
      subscribeToChannel,
      unsubscribeFromChannel,
      getChannelUsers,
    }),
    [getChannelUsers, subscribeToChannel, unsubscribeFromChannel]
  );

  return (
    <ChannelUsersContext.Provider value={contextValue}>
      {children}
      {Object.keys(state.subscriptions).map((channelId) => (
        <ChannelSubscription key={channelId} channelId={channelId} dispatch={dispatch} />
      ))}
    </ChannelUsersContext.Provider>
  );
};

const ChannelSubscription: React.FC<{ channelId: string; dispatch: React.Dispatch<ChannelUsersAction> }> = ({
  channelId,
  dispatch,
}) => {
  const subscriptionOptions: GraphQLSubscriptionOptions<GetChannelUsersQuery, GetChannelUsersQueryVariables, true> = {
    query: GetChannelUsersDocument,
    useRealtimeSubscription: true,
    variables: { channelId },
    onNext: (data) => {
      const users: Record<string, ChannelUser> = {};
      for (const user of data.channels_users) {
        users[user.user_id] = {
          photoUrl: user.profile.photo,
          handRaised: user.hand_raised,
          forceMuted: user.force_muted,
          status: user.status as Channel_User_Status,
          username: user.profile.username ?? user.profile.first_name ?? 'Someone',
          admin: user.profile.role === User_Role.Team,
          connection:
            user.connection === 'good' || user.connection === 'low' || user.connection === 'very-low'
              ? user.connection
              : 'good',
        };
      }
      dispatch({ type: 'UPDATE_USERS', channelId, users });
    },
    onError: (error) => {
      dispatch({ type: 'SET_ERROR', channelId, error });
    },
  };

  useGraphQLSubscription(subscriptionOptions);

  return null;
};

export const useChannelUsers = (channelId?: string): ChannelUsersState => {
  const context = useContext(ChannelUsersContext);

  if (!context) {
    throw new Error('useChannelUsers must be used within a ChannelUsersProvider');
  }
  const { subscribeToChannel, unsubscribeFromChannel, getChannelUsers } = context;

  useEffect(() => {
    if (!channelId) {
      return;
    }
    subscribeToChannel(channelId);

    return () => {
      unsubscribeFromChannel(channelId);
    };
  }, [channelId, subscribeToChannel, unsubscribeFromChannel]);

  return useMemo(() => (channelId ? getChannelUsers(channelId) : { loading: true }), [channelId, getChannelUsers]);
};
