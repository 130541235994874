// @ts-nocheck
import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { useQuery, useInfiniteQuery, type UseQueryOptions, type UseInfiniteQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type BreakoutDetailsFragment = (
  { readonly __typename?: 'breakouts' }
  & Pick<Types.Breakouts, 'id' | 'name' | 'type' | 'sort_order' | 'platform_settings' | 'room_id' | 'channel_id'>
  & { readonly room?: Types.Maybe<(
    { readonly __typename?: 'rooms' }
    & Pick<Types.Rooms, 'club_id' | 'kind'>
  )> }
);

export type GetFullDefaultRoomBreakoutQueryVariables = Types.Exact<{
  roomId: Types.Scalars['uuid']['input'];
}>;


export type GetFullDefaultRoomBreakoutQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly breakouts: ReadonlyArray<(
    { readonly __typename?: 'breakouts' }
    & Pick<Types.Breakouts, 'id' | 'name' | 'type' | 'sort_order' | 'platform_settings' | 'room_id' | 'channel_id'>
    & { readonly room?: Types.Maybe<(
      { readonly __typename?: 'rooms' }
      & Pick<Types.Rooms, 'club_id' | 'kind'>
    )> }
  )> }
);

export type GetFullBreakoutQueryVariables = Types.Exact<{
  breakoutId: Types.Scalars['uuid']['input'];
}>;


export type GetFullBreakoutQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly breakouts_by_pk?: Types.Maybe<(
    { readonly __typename?: 'breakouts' }
    & Pick<Types.Breakouts, 'id' | 'name' | 'type' | 'sort_order' | 'platform_settings' | 'room_id' | 'channel_id'>
    & { readonly room?: Types.Maybe<(
      { readonly __typename?: 'rooms' }
      & Pick<Types.Rooms, 'club_id' | 'kind'>
    )> }
  )> }
);

export type GetAllClubBreakoutsQueryVariables = Types.Exact<{
  clubIds: ReadonlyArray<Types.Scalars['uuid']['input']> | Types.Scalars['uuid']['input'];
}>;


export type GetAllClubBreakoutsQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly breakouts: ReadonlyArray<(
    { readonly __typename?: 'breakouts' }
    & Pick<Types.Breakouts, 'id' | 'name' | 'type' | 'sort_order' | 'platform_settings' | 'room_id' | 'channel_id'>
    & { readonly room?: Types.Maybe<(
      { readonly __typename?: 'rooms' }
      & Pick<Types.Rooms, 'club_id' | 'kind'>
    )> }
  )> }
);


export const BreakoutDetailsFragmentDoc = `
    fragment BreakoutDetails on breakouts {
  id
  name
  type
  sort_order
  platform_settings
  room_id
  channel_id
  room {
    club_id
    kind
  }
}
    `;
export const GetFullDefaultRoomBreakoutDocument = `
    query GetFullDefaultRoomBreakout($roomId: uuid!) {
  breakouts(
    where: {room_id: {_eq: $roomId}}
    limit: 1
    order_by: {sort_order: asc, created_at: asc}
  ) {
    ...BreakoutDetails
  }
}
    ${BreakoutDetailsFragmentDoc}`;

export const useGetFullDefaultRoomBreakoutQuery = <
      TData = GetFullDefaultRoomBreakoutQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetFullDefaultRoomBreakoutQueryVariables,
      options?: UseQueryOptions<GetFullDefaultRoomBreakoutQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetFullDefaultRoomBreakoutQuery, TError, TData>(
      ['GetFullDefaultRoomBreakout', variables],
      fetcher<GetFullDefaultRoomBreakoutQuery, GetFullDefaultRoomBreakoutQueryVariables>(client, GetFullDefaultRoomBreakoutDocument, variables, headers),
      options
    )};

useGetFullDefaultRoomBreakoutQuery.getKey = (variables: GetFullDefaultRoomBreakoutQueryVariables) => ['GetFullDefaultRoomBreakout', variables];

export const useInfiniteGetFullDefaultRoomBreakoutQuery = <
      TData = GetFullDefaultRoomBreakoutQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetFullDefaultRoomBreakoutQueryVariables,
      options?: UseInfiniteQueryOptions<GetFullDefaultRoomBreakoutQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetFullDefaultRoomBreakoutQuery, TError, TData>(
      ['GetFullDefaultRoomBreakout.infinite', variables],
      (metaData) => fetcher<GetFullDefaultRoomBreakoutQuery, GetFullDefaultRoomBreakoutQueryVariables>(client, GetFullDefaultRoomBreakoutDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetFullDefaultRoomBreakoutQuery.getKey = (variables: GetFullDefaultRoomBreakoutQueryVariables) => ['GetFullDefaultRoomBreakout.infinite', variables];


useGetFullDefaultRoomBreakoutQuery.fetcher = (client: GraphQLClient, variables: GetFullDefaultRoomBreakoutQueryVariables, headers?: RequestInit['headers']) => fetcher<GetFullDefaultRoomBreakoutQuery, GetFullDefaultRoomBreakoutQueryVariables>(client, GetFullDefaultRoomBreakoutDocument, variables, headers);

export const GetFullBreakoutDocument = `
    query GetFullBreakout($breakoutId: uuid!) {
  breakouts_by_pk(id: $breakoutId) {
    ...BreakoutDetails
  }
}
    ${BreakoutDetailsFragmentDoc}`;

export const useGetFullBreakoutQuery = <
      TData = GetFullBreakoutQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetFullBreakoutQueryVariables,
      options?: UseQueryOptions<GetFullBreakoutQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetFullBreakoutQuery, TError, TData>(
      ['GetFullBreakout', variables],
      fetcher<GetFullBreakoutQuery, GetFullBreakoutQueryVariables>(client, GetFullBreakoutDocument, variables, headers),
      options
    )};

useGetFullBreakoutQuery.getKey = (variables: GetFullBreakoutQueryVariables) => ['GetFullBreakout', variables];

export const useInfiniteGetFullBreakoutQuery = <
      TData = GetFullBreakoutQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetFullBreakoutQueryVariables,
      options?: UseInfiniteQueryOptions<GetFullBreakoutQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetFullBreakoutQuery, TError, TData>(
      ['GetFullBreakout.infinite', variables],
      (metaData) => fetcher<GetFullBreakoutQuery, GetFullBreakoutQueryVariables>(client, GetFullBreakoutDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetFullBreakoutQuery.getKey = (variables: GetFullBreakoutQueryVariables) => ['GetFullBreakout.infinite', variables];


useGetFullBreakoutQuery.fetcher = (client: GraphQLClient, variables: GetFullBreakoutQueryVariables, headers?: RequestInit['headers']) => fetcher<GetFullBreakoutQuery, GetFullBreakoutQueryVariables>(client, GetFullBreakoutDocument, variables, headers);

export const GetAllClubBreakoutsDocument = `
    query GetAllClubBreakouts($clubIds: [uuid!]!) {
  breakouts(
    where: {room: {club_id: {_in: $clubIds}}}
    order_by: {sort_order: asc, created_at: asc}
  ) {
    ...BreakoutDetails
  }
}
    ${BreakoutDetailsFragmentDoc}`;

export const useGetAllClubBreakoutsQuery = <
      TData = GetAllClubBreakoutsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetAllClubBreakoutsQueryVariables,
      options?: UseQueryOptions<GetAllClubBreakoutsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetAllClubBreakoutsQuery, TError, TData>(
      ['GetAllClubBreakouts', variables],
      fetcher<GetAllClubBreakoutsQuery, GetAllClubBreakoutsQueryVariables>(client, GetAllClubBreakoutsDocument, variables, headers),
      options
    )};

useGetAllClubBreakoutsQuery.getKey = (variables: GetAllClubBreakoutsQueryVariables) => ['GetAllClubBreakouts', variables];

export const useInfiniteGetAllClubBreakoutsQuery = <
      TData = GetAllClubBreakoutsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetAllClubBreakoutsQueryVariables,
      options?: UseInfiniteQueryOptions<GetAllClubBreakoutsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetAllClubBreakoutsQuery, TError, TData>(
      ['GetAllClubBreakouts.infinite', variables],
      (metaData) => fetcher<GetAllClubBreakoutsQuery, GetAllClubBreakoutsQueryVariables>(client, GetAllClubBreakoutsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetAllClubBreakoutsQuery.getKey = (variables: GetAllClubBreakoutsQueryVariables) => ['GetAllClubBreakouts.infinite', variables];


useGetAllClubBreakoutsQuery.fetcher = (client: GraphQLClient, variables: GetAllClubBreakoutsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetAllClubBreakoutsQuery, GetAllClubBreakoutsQueryVariables>(client, GetAllClubBreakoutsDocument, variables, headers);
