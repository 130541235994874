// @ts-nocheck
import type * as Types from '@/generated/graphql/global-types.user';

export type ChannelAssociatedObjectsFragment = (
  { readonly __typename?: 'channels' }
  & { readonly breakout?: Types.Maybe<(
    { readonly __typename?: 'breakouts' }
    & Pick<Types.Breakouts, 'association_id' | 'id' | 'name' | 'type'>
    & { readonly room?: Types.Maybe<(
      { readonly __typename?: 'rooms' }
      & Pick<Types.Rooms, 'kind'>
    )> }
  )>, readonly room?: Types.Maybe<(
    { readonly __typename?: 'rooms' }
    & Pick<Types.Rooms, 'association_id' | 'feedback_question' | 'id' | 'kind' | 'name' | 'status' | 'url_name'>
    & { readonly club: (
      { readonly __typename?: 'clubs' }
      & Pick<Types.Clubs, 'url_name'>
    ) }
  )> }
);


export const ChannelAssociatedObjectsFragmentDoc = `
    fragment ChannelAssociatedObjects on channels {
  breakout {
    association_id
    id
    name
    type
    room {
      kind
    }
  }
  room {
    association_id
    feedback_question
    id
    kind
    name
    status
    url_name
    club {
      url_name
    }
  }
}
    `;