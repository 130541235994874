import { RadioGroup } from '@headlessui/react';
import Image from 'next/image';
import { type ChangeEventHandler, useCallback, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { useDependencyTrackingEffect } from '@/client/core/hooks/use-dependency-tracking-effect';
import { Button } from '@/client/design-system/components/button-next';
import { Textarea } from '@/client/design-system/components/fields';
import { useAnalytics } from '@/client/features/analytics';
import { useSubmitFeedbackMutation } from '@/client/features/feedback/operations/generated/operations.user';
import { useToast } from '@/client/features/toast/providers/toast-provider';
import { Feedback_Sentiment } from '@/generated/graphql/global-types.user';
import hasuraGraphQLClient from '@/shared/graphql/client';
import { FeedbackSource } from '@/shared/graphql/schema-extensions/scalars/feedback-source';

import Happy from './emoji/happy.png';
import Sad from './emoji/sad.png';
import Unsure from './emoji/unsure.png';

const DefaultQuestion = 'Do you have any feedback or suggestions?';
const MaxLength = 250;

const emojiClassName = (checked: boolean) =>
  twMerge('cursor-pointer transition-all duration-150', !checked && 'grayscale hover:grayscale-0');

export interface Props {
  associatedObject?: string;
  badQuestion?: null | string;
  goodQuestion?: null | string;
  neutralQuestion?: null | string;
  onCancel: () => void;
  onSubmit: () => void;
}

export const FeedbackForm = (props: Props) => {
  const { associatedObject, badQuestion, goodQuestion, neutralQuestion, onCancel, onSubmit } = props;

  const questions = { badQuestion, goodQuestion, neutralQuestion };

  const {
    mutateAsync: submitFeedback,
    isError,
    isLoading,
    isSuccess,
  } = useSubmitFeedbackMutation(hasuraGraphQLClient.Client);

  const { trackEvent } = useAnalytics();
  const { showSimpleToast } = useToast();

  const [feedback, setFeedback] = useState('');
  const [sentiment, setSentiment] = useState<null | Feedback_Sentiment>(null);

  const onSentimentChange = useCallback((value: Feedback_Sentiment) => {
    setSentiment(value);
  }, []);

  const onTextChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback((event) => {
    setFeedback(event.target.value);
  }, []);

  const submit = async () => {
    await submitFeedback({
      associated_object: associatedObject,
      idea: feedback,
      sentiment,
      source: FeedbackSource.PlatformFeedback,
    });

    void trackEvent({
      action: 'submitted',
      object: 'feedbackForm',
      properties: {
        associatedObject,
        sentiment,
        text: feedback,
      },
    });
  };

  const feedbackProvided = feedback.length > 0;

  useDependencyTrackingEffect(
    (previous) => {
      const previousSuccess = previous?.[0] || false;

      if (isSuccess && isSuccess !== previousSuccess) {
        const improvement = feedbackProvided || sentiment !== Feedback_Sentiment.Good;
        const description = `We've received your feedback. ${
          improvement ? 'Thanks for helping us improve.' : 'Thanks for your support.'
        }`;

        showSimpleToast({
          title: 'Feedback Submitted',
          description,
          color: 'success',
        });

        onSubmit();
      }
    },
    [isSuccess, feedbackProvided, onSubmit, sentiment, showSimpleToast]
  );

  useDependencyTrackingEffect(
    (previous) => {
      const previousError = previous?.[0] || false;

      if (isError && isError !== previousError) {
        showSimpleToast({
          title: 'Feedback Submission Failed',
          description: 'Network connectivity issue. If this keeps occurring, please contact support.',
          color: 'danger',
        });
      }
    },
    [isError, feedbackProvided, isSuccess, onSubmit, sentiment, showSimpleToast]
  );

  const submitDisabled = feedback.length > MaxLength || (feedback.length === 0 && sentiment === null);
  const question = (sentiment != null && questions[`${sentiment}Question`]) || DefaultQuestion;

  return (
    <div>
      <div className='px-8 pb-7 pt-2 text-paragraph3 text-light-grey-2'>
        We love hearing your feedback, it helps us improve!
      </div>
      <RadioGroup
        className='mx-8 flex justify-between rounded-sm border-1 border-light-grey-5 bg-dark-grey-3 px-5 py-3 hover:border-light-grey-2 sm:px-10 sm:py-5'
        disabled={isLoading}
        onChange={onSentimentChange}
      >
        <RadioGroup.Option value={Feedback_Sentiment.Bad}>
          {({ checked }) => <Image className={emojiClassName(checked)} width={64} src={Sad} alt='Sad' />}
        </RadioGroup.Option>
        <RadioGroup.Option value={Feedback_Sentiment.Neutral}>
          {({ checked }) => <Image className={emojiClassName(checked)} width={64} src={Unsure} alt='Unsure' />}
        </RadioGroup.Option>
        <RadioGroup.Option value={Feedback_Sentiment.Good}>
          {({ checked }) => <Image className={emojiClassName(checked)} width={64} src={Happy} alt='Happy' />}
        </RadioGroup.Option>
      </RadioGroup>
      <div className='px-8 pt-7 text-subtitle3 font-medium text-light-grey-3'>{question}</div>
      <Textarea
        className='mx-8 mt-1.5'
        disabled={isLoading}
        fontOverride='text-headline4 font-normal'
        heightOverride='h-24 py-1.5'
        onChange={onTextChange}
        placeholder='Let us know what you think!'
      />
      <div
        className={twMerge(
          'px-8 pt-1 text-right text-paragraph4 transition-colors duration-300',
          feedback.length > MaxLength ? 'text-accent-red' : 'text-light-grey-3'
        )}
      >
        {feedback.length} / {MaxLength}
      </div>
      <hr className='mx-1 mt-9 border-1 border-border' />
      <div className='flex justify-end gap-4 bg-dark-grey-1 px-8 py-7'>
        <Button color='gray' onClick={onCancel}>
          CANCEL
        </Button>
        <Button color='solidWhite' onClick={submit} disabled={submitDisabled}>
          SUBMIT
        </Button>
      </div>
    </div>
  );
};
