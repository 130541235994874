import React from 'react';

import { Logo } from '@/client/design-system/brand';

interface Props {
  props?: any;
}

const MaintenanceView = ({ ...props }: Props) => (
  <div {...props}>
    <div className='flex h-screen w-full items-center justify-center gap-6'>
      <Logo className='absolute left-14 top-8 z-10 size-6' />
      <div className='flex flex-col items-center gap-4'>
        <h1 className='px-4 text-center text-title2 font-bold'>
          Ender is currently <span className='text-accent'>under maintenance.</span>
        </h1>
        <p className='text-paragraph2 font-medium text-light-grey-2'>Please check back in a few minutes.</p>
      </div>
    </div>
  </div>
);

export default MaintenanceView;
