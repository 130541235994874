import { AnalyticsBrowser } from '@segment/analytics-next';

import type { Function } from '@/client/core/types/functions';
import type { AnalyticsService } from '@/client/features/analytics/service';
import { fromSegmentAdapter } from '@/shared/services/analytics/segment';

const WRITE_KEY = process.env.NEXT_PUBLIC_SEGMENT_KEY!;

let analyticsServicePromise: null | Promise<AnalyticsService> = null;

// Restrictive ad-blockers can block Segment's settings request,
// leading to tracking calls taking forever and freezing the application.
// We have to eagerly load Segment's analytics and use a fallback handler
// when initialization was blocked.
const loadAnalyticsFromSegmentAdapter = async (): Promise<AnalyticsService> => {
  if (!global.document) {
    return noopAnalytics();
  }

  return await AnalyticsBrowser.load({ writeKey: WRITE_KEY })
    .then(([adapter]) => fromSegmentAdapter(adapter))
    .catch(noopAnalytics);
};

const noopAnalytics = (): AnalyticsService => ({
  anonymousId: noopHandler,
  trackEvent: noopHandler,
  trackPageView: noopHandler,
  aliasUser: noopHandler,
  identifyUser: noopHandler,
  resetUser: noopHandler,
});

const noopHandler: Function<any, any> = () => undefined;

const wrapAnalyticsMethod = <MethodName extends keyof AnalyticsService>(
  methodName: MethodName
): AnalyticsService[MethodName] =>
  (async (...args: Parameters<AnalyticsService[MethodName]>) => {
    if (analyticsServicePromise === null) {
      analyticsServicePromise = loadAnalyticsFromSegmentAdapter();
    }

    const service = await analyticsServicePromise;

    return (service[methodName] as Function<any, any>)(...args);
  }) as AnalyticsService[MethodName];

const wrappedAnalytics: AnalyticsService = {
  anonymousId: wrapAnalyticsMethod('anonymousId'),
  trackEvent: wrapAnalyticsMethod('trackEvent'),
  trackPageView: wrapAnalyticsMethod('trackPageView'),
  aliasUser: wrapAnalyticsMethod('aliasUser'),
  identifyUser: wrapAnalyticsMethod('identifyUser'),
  resetUser: wrapAnalyticsMethod('resetUser'),
};

export const useSegmentAnalyticsService = (): AnalyticsService => wrappedAnalytics;
