import type { JSX } from 'react';

import { Icon } from '@/client/design-system/components/icon';
import { Modal, ModalContent } from '@/client/design-system/components/modal';
import { useIntercom } from '@/client/features/intercom/use-intercom';
import { SUPPORT_EMAIL } from '@/legacy/lib/constants';

interface BannedModalProps {
  reason?: string;
}

export const BannedModal = ({ reason }: BannedModalProps): JSX.Element => {
  const { openIntercomMessenger } = useIntercom();

  return (
    <div className='fixed left-0 top-0 z-interactive flex h-screen w-screen items-center justify-center bg-black'>
      <Modal open>
        <ModalContent closeable={false} className='z-interactive'>
          <div className='flex w-118 flex-col gap-5 px-8 py-7'>
            <h1 className='flex items-center gap-1 text-xl font-bold'>
              <span className='stroke-accent-red'>
                <Icon icon='forbid' size={28} />
              </span>
              Your account has been locked.
            </h1>

            <div className='flex flex-col gap-2'>
              {reason && <p className='text-sm font-semibold leading-6 text-accent-yellow'>Reason: {reason}</p>}

              <p className='text-sm leading-6 text-light-grey-2'>
                If you think this is a mistake, please{' '}
                <a
                  onClick={() => {
                    openIntercomMessenger();
                  }}
                  className='inline cursor-pointer text-white transition-all hover:text-light-grey-1'
                >
                  contact support
                </a>{' '}
                or email us at{' '}
                <a
                  href={`mailto:${SUPPORT_EMAIL}`}
                  className='inline text-white transition-all hover:text-light-grey-1'
                >
                  {SUPPORT_EMAIL}
                </a>
                . You have been signed out.
              </p>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
};
