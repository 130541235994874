import { useEffect, useRef } from 'react';

type CallbackReturnType = ReturnType<Parameters<typeof useEffect>[0]>;

export function useDependencyTrackingEffect<const D extends readonly unknown[]>(
  callback: (previous: undefined | D) => CallbackReturnType,
  deps: D
) {
  const persistedDeps = useRef<D>(undefined);
  useEffect(() => {
    const previousDeps = persistedDeps.current;
    persistedDeps.current = deps;
    return callback(previousDeps);
  }, deps);
}
