import { useEffectOnceWhen } from 'rooks';

import type { PageViewEvent, PageViewEvents } from '@/client/features/analytics/events/page-view-events';

import { useAnalytics } from './use-analytics';

export const useAnalyticsPageView = <P extends keyof PageViewEvents>(
  options: PageViewEvent<P> | false,
  condition: boolean = true
): void => {
  const { trackPageView } = useAnalytics();

  useEffectOnceWhen(
    () => {
      if (options) {
        void trackPageView(options);
      }
    },
    Boolean(options) && condition
  );
};
