import { type Client, createClient } from 'graphql-ws';
import type { ClientOptions } from 'graphql-ws/lib/client';
import { type JSX, type ReactNode, useEffect, useReducer, useState } from 'react';

import { HASURA_SUBSCRIPTION_ENDPOINT, REALTIME_SUBSCRIPTION_ENDPOINT } from '@/legacy/lib/constants';

import { createStrictContext } from '../utils/react';

interface AppSubscriptionClients {
  hasuraSubscriptionClient: Client;
  realtimeSubscriptionClient: Client;
}

const [GraphQLSubscriptionClientContextProvider, useGraphQLSubscriptionClient] =
  createStrictContext<AppSubscriptionClients>({
    displayName: 'GraphQLSubscriptionClient',
  });

export { useGraphQLSubscriptionClient };

const SERVER_SIDE_SUBSCRIPTION_CLIENT_MOCK: Client = {
  dispose: () => {},
  on: () => () => {},
  terminate: () => {},
  subscribe: () => () => {},
  iterate: async function* () {},
};

type InvalidateSubscriptionClient = () => void;

interface Props {
  children: (invalidateSubscriptionClient: InvalidateSubscriptionClient) => ReactNode;
}

export const GraphQLSubscriptionClientProvider = ({ children }: Props): JSX.Element => {
  const [hasuraSubscriptionClient, setHasuraSubscriptionClient] = useState(SERVER_SIDE_SUBSCRIPTION_CLIENT_MOCK);
  const [realtimeSubscriptionClient, setRealtimeSubscriptionClient] = useState(SERVER_SIDE_SUBSCRIPTION_CLIENT_MOCK);
  const [subscriptionClientStale, invalidateSubscriptionClient] = useReducer((version: number) => version + 1, 0);

  useEffect(() => {
    let onTimedOut: (() => void) | null = null;
    let timeout: NodeJS.Timeout | null = null;

    const clientOptions: Partial<ClientOptions> = {
      retryAttempts: 30,
      keepAlive: 10_000,
      shouldRetry: () => true,
      on: {
        connected: (socket: any) => {
          onTimedOut = () => {
            if (socket.readyState === WebSocket.OPEN) {
              socket.close(4408, 'Client Restart');
            }
          };
        },
        ping: (received) => {
          if (received) {
            return;
          }
          timeout = setTimeout(onTimedOut!, 5_000);
        },
        pong: (received) => {
          if (received && timeout) {
            clearTimeout(timeout);

            timeout = null;
          }
        },
      },
    };

    setHasuraSubscriptionClient(
      createClient({
        ...clientOptions,
        url: `${HASURA_SUBSCRIPTION_ENDPOINT}/v1/graphql`,
      })
    );
    setRealtimeSubscriptionClient(
      createClient({
        ...clientOptions,
        url: `${REALTIME_SUBSCRIPTION_ENDPOINT}/graphql`,
      })
    );
  }, [subscriptionClientStale]);

  return (
    <GraphQLSubscriptionClientContextProvider
      value={{
        hasuraSubscriptionClient,
        realtimeSubscriptionClient,
      }}
    >
      {children(invalidateSubscriptionClient)}
    </GraphQLSubscriptionClientContextProvider>
  );
};
