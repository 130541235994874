import type { JSX } from 'react';

import type { ISimpleAlert } from '../types/simple-alert';

export const SimpleAlert = ({ content, className }: ISimpleAlert): JSX.Element => (
  <div
    className={`${className} max-w-[440px] rounded-xl bg-page-item-surface px-4 py-3 pr-8 shadow-[0_2px_4px_0_rgba(0,0,0,0.40)]`}
  >
    <div className='relative flex items-center gap-3 text-small text-primary'>{content}</div>
  </div>
);
