import type { ChannelAssociatedObjectsFragment } from '@/client/features/channels/associated-objects/generated/channel-associated-objects.user';
import { ROUTES } from '@/legacy/lib/constants';
import { ExhaustiveCheckError, safeExhaustiveCheckFallback } from '@/shared/utils/correctness';
import type { ValueToEnum } from '@/shared/utils/types';

export enum ChannelAssociatedObjectType {
  Breakout = 'breakout',
  Room = 'room',
}

interface ChannelAssociatedObjectTypeTextStrings {
  noun: string;
  nounPlural: string;
  titleNoun: string;
  titleNounPlural: string;
}

// Obviously could dynamically down case on use or on start-up
export const ChannelAssociatedObjectTypeText: Record<
  ChannelAssociatedObjectType,
  ChannelAssociatedObjectTypeTextStrings
> = {
  [ChannelAssociatedObjectType.Breakout]: {
    noun: 'breakout',
    nounPlural: 'breakouts',
    titleNoun: 'Breakout',
    titleNounPlural: 'Breakouts',
  },
  [ChannelAssociatedObjectType.Room]: {
    noun: 'room',
    nounPlural: 'rooms',
    titleNoun: 'Room',
    titleNounPlural: 'Rooms',
  },
};

export type ChannelAssociatedObjectData<T extends ChannelAssociatedObjectType = ChannelAssociatedObjectType> =
  NonNullable<ChannelAssociatedObjectsFragment[T]>;
export type ChannelAssociatedObject<T extends ChannelAssociatedObjectType = ChannelAssociatedObjectType> =
  T extends ChannelAssociatedObjectType
    ? {
        type: T;
        object: ChannelAssociatedObjectData<T>;
      }
    : never;

export function getAssociatedObject(channel: Pick<ChannelAssociatedObjectsFragment, ChannelAssociatedObjectType>) {
  for (const key of Object.values(ChannelAssociatedObjectType)) {
    if (channel[key]) {
      return {
        // Add your new associated object type to ChannelAssociatedObjectType ;)
        type: key satisfies ValueToEnum<
          typeof ChannelAssociatedObjectType,
          Exclude<keyof ChannelAssociatedObjectsFragment, '__typename'>
        >,
        object: channel[key],
      } as ChannelAssociatedObject;
    }
  }

  return null;
}

export function getAssociatedObjectName(associatedObject: ChannelAssociatedObject): string {
  switch (associatedObject.type) {
    case ChannelAssociatedObjectType.Breakout:
      return associatedObject.object.name;
    case ChannelAssociatedObjectType.Room:
      return associatedObject.object.name;
    default:
      throw new ExhaustiveCheckError(associatedObject);
  }
}

export function getAssociatedObjectUrl(associatedObject: ChannelAssociatedObject) {
  switch (associatedObject.type) {
    case ChannelAssociatedObjectType.Breakout:
      return ROUTES.root; // TODO: Pull out URL from nested associated object relationships.
    case ChannelAssociatedObjectType.Room:
      return `${ROUTES.room.prefix}/${associatedObject.object.id}`;
    default:
      return safeExhaustiveCheckFallback(associatedObject, ROUTES.root);
  }
}
