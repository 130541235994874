import type { ReactNode } from 'react';

import { Toast } from '@/client/features/toast/components/toast';

interface ToastContainerProps {
  toasts: Record<string, { content: ReactNode; onclose: () => void; clearOnRouteChange?: boolean }>;
  onClose: (identifier: string) => void;
  className?: string;
  clearOnRouteChange?: boolean;
}

export const ToastContainer = ({ toasts, onClose, className }: ToastContainerProps) => (
  <div className={className}>
    {Object.keys(toasts).map((identifier, index) => {
      const { [identifier]: toast } = toasts;
      const content = toast?.content;
      const clearOnRouteChange = toast?.clearOnRouteChange;

      return (
        <Toast
          key={index}
          onClose={() => {
            onClose(identifier);
          }}
          clearOnRouteChange={clearOnRouteChange}
        >
          {content}
        </Toast>
      );
    })}
  </div>
);
