import { useUpdateChannelUserHandRaisedMutation } from '@/client/features/calls/operations/generated/operations.user';
import { wrapFetcher } from '@/shared/graphql/client';

const updateChannelUserHandRaised = wrapFetcher(useUpdateChannelUserHandRaisedMutation.fetcher);

export const updateUserHandRaised = async (
  channelId: string,
  userId: string,
  handRaised: boolean
): Promise<boolean> => {
  const result = await updateChannelUserHandRaised({
    channelId,
    userId,
    handRaised,
  });

  return !!result.updateHandRaised?.updated;
};
