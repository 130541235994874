'use client';

import { useEffect } from 'react';

import type { ApiUser } from '@/api/auth/user';
import { useDialog } from '@/client/features/dialog';
import { BannedModal } from '@/client/features/user/components/banned-modal/banned-modal';
import { useUserContext } from '@/client/features/user/providers/user-provider';
import { User_Role } from '@/generated/graphql/global-types.admin';
import { useRouter } from '@/hooks/use-compatible-router';

interface UseUserOptions {
  redirectTo?: string | ((user: ApiUser) => undefined | null | string);
  redirectIfNotFound?: string;
}

interface UseUserResult<T extends undefined | ApiUser> {
  user: T;
  isLoading: boolean;
  refetch: () => Promise<void>;
}

export const useUser = ({ redirectTo, redirectIfNotFound }: UseUserOptions = {}): UseUserResult<
  undefined | ApiUser
> => {
  const { user, isLoading, refetch } = useUserContext();
  const { showDialog } = useDialog();
  const router = useRouter();

  useEffect(() => {
    if (!isLoading) {
      if (user) {
        if (user.role === User_Role.Disabled) {
          const reason = user.meta.ban_reason;

          showDialog({
            dialog: <BannedModal reason={reason} />,
          });

          void refetch();
        } else if (redirectTo) {
          // User found and redirectTo route has been specified
          const destination = typeof redirectTo === 'string' ? redirectTo : redirectTo(user);

          if (destination) {
            void router.replace(destination);
          }
        }
      } else if (redirectIfNotFound) {
        // User not found and redirectIfNotFound route has been specified
        void router.replace(redirectIfNotFound);
      }
    }
  }, [redirectTo, redirectIfNotFound, user, isLoading, router, refetch, showDialog]);

  return {
    user: user ?? undefined,
    isLoading,
    refetch,
  };
};

/**
 * Only call this in context where you *know* a user is available e.g. inside page route that's getServerSideProps is
 * wrapped with withRequiredAuth, or inside components that inherently can only be rendered for authenticated users.
 * NOTE: In the latter case, prefer simply passing in the user as prop, then the requirement is explicit.
 */
export const useUnsafeRequiredUser = useUser as (options?: UseUserOptions) => UseUserResult<ApiUser>;
