import type { JSX } from 'react';

import { cn } from '../../utils/tailwind';
import { Icon, type IconType } from '../icon';

type Size = 'small' | 'medium' | 'large' | 'extralarge';
type Color = 'default' | 'white' | 'solidWhite' | 'red' | 'solidRed' | 'yellow' | 'green' | 'gray';
type Roundedness = 'rounded' | 'sharp';

interface Props extends React.ComponentProps<'button'> {
  iconLeft?: IconType;
  iconRight?: IconType;
  size?: Size;
  color?: Color;
  roundedness?: Roundedness;
  fullWidth?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

const ROOT_CLS = 'relative box-border border-1 transition-all';

const SIZE_CLS: Record<Size, string> = {
  small: 'px-3.5 py-0.5',
  medium: 'px-4 py-1',
  large: 'px-4.5 py-2',
  extralarge: 'px-8 py-2',
};

const ROUNDEDNESS_CLS: Record<Roundedness, string> = {
  rounded: 'rounded-[4px]',
  sharp: 'rounded-sm',
};

const COLOR_CLS: Record<Color, string> = {
  default: 'text-white/70 bg-transparent border-white/50 hover:bg-white/10',
  white: 'text-white bg-white/15 border-white hover:bg-white/25',
  solidWhite: 'text-black bg-white border-white hover:bg-white/25 hover:text-white',
  green: 'text-accent bg-accent/15 border-accent hover:bg-accent/25',
  red: 'text-accent-red bg-accent-red/15 border-accent-red hover:bg-accent-red/25',
  solidRed: 'text-white bg-accent-red hover:text-accent-red border-accent-red hover:bg-accent-red/25',
  yellow: 'text-accent-yellow bg-accent-yellow/15 border-accent-yellow hover:bg-accent-yellow/25',
  gray: 'bg-border text-light-grey-1 border-transparent hover:border-white/50',
};

const ICON_ROOT_CLS = 'flex-none grow-0';
const ICON_COLOR_CLS: Record<Color, string> = {
  default: 'stroke-white/70 fill-white/70',
  white: 'stroke-white fill-white',
  solidWhite: 'stroke-black fill-black',
  green: 'stroke-accent fill-accent',
  red: 'stroke-accent-red fill-accent-red',
  solidRed: 'stroke-white fill-white',
  yellow: 'stroke-accent-yellow fill-accent-yellow',
  gray: 'stroke-light-grey-1 fill-light-grey-1',
};

const SPINNER_ROOT_CLS = 'absolute top-0 left-0 flex flex-row items-center justify-center w-full h-full animate-spin';

export const Button = ({
  children,
  iconLeft,
  iconRight,
  size = 'medium',
  roundedness = 'rounded',
  fullWidth = false,
  color = 'default',
  loading = false,
  disabled = false,
  ...props
}: Props): JSX.Element => (
  <button
    {...props}
    className={cn(
      ROOT_CLS,
      SIZE_CLS[size],
      ROUNDEDNESS_CLS[roundedness],
      COLOR_CLS[color],
      fullWidth && 'w-full',
      disabled && 'pointer-events-none cursor-default opacity-50',
      props.className
    )}
  >
    <div
      className={cn(
        'flex flex-row items-center justify-center gap-2 text-small-plus font-semibold',
        loading && 'invisible'
      )}
    >
      {iconLeft && (
        <div className={cn(ICON_ROOT_CLS, ICON_COLOR_CLS[color])}>
          <Icon icon={iconLeft} size={18} />
        </div>
      )}
      {children}
      {iconRight && (
        <div className={cn(ICON_ROOT_CLS, ICON_COLOR_CLS[color])}>
          <Icon icon={iconRight} size={18} />
        </div>
      )}
    </div>
    {loading && (
      <div className={cn(SPINNER_ROOT_CLS, ICON_COLOR_CLS[color])}>
        <Icon icon='spinner' size={18} />
      </div>
    )}
  </button>
);
