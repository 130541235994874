import type { JSX, ReactNode } from 'react';
import { useIntervalWhen } from 'rooks';

import { useUserPresence } from '@/client/features/user/hooks';
import { useUpdateUserWebPresenceMutation } from '@/client/features/user/operations/generated/update-user-presence.user';
import { useUser } from '@/hooks/use-user';
import hasuraGraphQLClient from '@/shared/graphql/client';
import { UsersPresenceMetaWebState } from '@/shared/graphql/scalars';

import { useWindowEventListener } from '../../../core/hooks/use-window-event-listener';

interface Props {
  children: ReactNode;
}

const HEARTBEAT_MS = 3000;

export const UserPresenceProvider = ({ children }: Props): JSX.Element => {
  const { user } = useUser();

  const presence = useUserPresence(user?.id);
  const { mutate } = useUpdateUserWebPresenceMutation(hasuraGraphQLClient.Client, {});

  useIntervalWhen(
    () => {
      const { error, loading, web } = presence;

      if (!error && !loading && web && user) {
        const state =
          web.state === UsersPresenceMetaWebState.Offline
            ? UsersPresenceMetaWebState.Idle
            : UsersPresenceMetaWebState.Online;

        mutate({
          id: user.id,
          web: {
            ...web,
            state,
            updatedAt: Date.now(),
          },
        });
      }
    },
    HEARTBEAT_MS,
    user && presence.web !== undefined
  );

  // TODO: This isn't reliable, should use visibilitychange and sendBeacon — even then, that's not perfect.
  useWindowEventListener('blur', () => {
    if (user && presence !== undefined) {
      mutate({
        id: user.id,
        web: {
          state: UsersPresenceMetaWebState.Idle,
          updatedAt: Date.now(),
        },
      });
    }
  });

  useWindowEventListener('focus', () => {
    if (user) {
      mutate({
        id: user.id,
        web: {
          state: UsersPresenceMetaWebState.Online,
          updatedAt: Date.now(),
        },
      });
    }
  });

  return <>{children}</>;
};
