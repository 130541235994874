'use client';

import { clsx } from 'clsx';
import React, { type JSX, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

import { useRouter } from '@/hooks/use-compatible-router';
import { ROUTES } from '@/legacy/lib/constants';

type RedirectType = 'app' | 'website' | false;

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  fill?: string;
  id?: string;
  className?: string;
  homeLink?: boolean;
  redirect?: RedirectType;
}

const getRedirectRoute = (route: Exclude<RedirectType, false>) => {
  switch (route) {
    case 'app':
      return ROUTES.root;
    case 'website':
      return 'https://joinender.com/';
  }
};

export const Logo = ({
  fill = 'white',
  id,
  className,
  homeLink = true,
  redirect = 'app',
  ...props
}: Props): JSX.Element => {
  const router = useRouter();

  const handleClick = () => {
    if (!homeLink || !redirect) {
      return;
    }
    void router.push(getRedirectRoute(redirect));
  };

  useEffect(() => {
    if (!homeLink) {
      return;
    }
    void router.prefetch(ROUTES.root);
  }, [router, homeLink]);

  return (
    <div
      className={clsx('flex w-min items-center gap-1.5', redirect && 'cursor-pointer')}
      {...props}
      onClick={handleClick}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill={fill}
        viewBox='0 0 72.96 82.19'
        className={twMerge('h-8 w-8 shrink-0 grow-0', className)}
      >
        <title>Ender Logo</title>
        <g id='Layer_2' data-name='ender-logo-outer'>
          <g id='Layer_1-2' data-name='ender-logo-inner'>
            <path d='M73,55.46Z' />
            <path d='M53.06,44h0V24.82l11.6,6.7V50.68h0ZM69.64,24.82,28.19.89a6.6,6.6,0,0,0-6.63,0L3.32,11.42A6.64,6.64,0,0,0,0,17.17V65a6.63,6.63,0,0,0,3.32,5.74L21.56,81.3a6.6,6.6,0,0,0,6.63,0L69.64,57.37A6.66,6.66,0,0,0,73,51.62V30.56A6.62,6.62,0,0,0,69.64,24.82ZM33.16,13.34,44.77,20V39.19L33.16,32.52ZM8.29,18.12,24.87,8.55V22L8.29,31.52Zm0,23,16.58-9.57V44.93L8.29,54.49ZM24.87,73.64,8.29,64.06,19.9,57.37l16.58,9.57Zm19.9-11.48L28.18,52.58l11.58-6.72,16.61,9.59Z' />
          </g>
        </g>
      </svg>
    </div>
  );
};
