import type { BreakoutDetailsFragment } from '@/client/api/breakouts/generated/operations.user';
import type { RoomDetailFragment } from '@/client/api/rooms/generated/operations.user';
import type { Breakout } from '@/client/features/breakouts/types/rooms-breakouts-context';
import type { CallChannel } from '@/client/features/calls/operations/get-call-channel';

export type CallStatus = 'inactive' | 'joining' | 'leaving' | 'joined-call' | 'joined-preview';

/**
 * Interface representing the state of the call store.
 */
export interface CallState {
  /**
   * Whether the current "call" is a preview.
   */
  previewing: false | 'orientation' | 'regular';

  /**
   * The initial action that should occur upon initializing in a page
   * expecting calls.
   */
  initialAction: { type: 'join-call' } | { type: 'enter-preview'; previewRoomId: string } | undefined;

  /**
   * The current room the user is in.
   */
  currentRoom: RoomDetailFragment | undefined;

  /**
   * The current breakout the user is in.
   */
  currentBreakout: Breakout | undefined;

  /**
   * The current call channel.
   */
  currentChannel: CallChannel | undefined;

  /**
   * A map of all breakouts and the club they belong to.
   */
  breakouts: Record<string, BreakoutDetailsFragment[]>;

  /**
   * A list of session IDs.
   */
  sessions: string[];

  /**
   * Whether the call is force-muted.
   */
  isCallForceMuted?: boolean;

  /**
   * Whether the user is force-muted.
   */
  isUserForceMuted?: boolean;

  /**
   * The ID of the pinned participant.
   */
  pinned: null | string;

  /**
   * Whether the user starts muted.
   */
  startMuted: boolean;

  /**
   * Whether calls are expected.
   */
  expectCalls: boolean | 'preview-only';
  /**
   * Whether the provider is ready.
   */
  ready: boolean;

  /**
   * The current status of the call.
   */
  status: CallStatus;

  /**
   * The current transitioning state.
   */
  transitioning: null | 'breakout' | 'room' | 'preview';
}

/**
 * Interface representing the actions for the call store.
 */
export interface CallActions {
  /**
   * Sets whether the current "call" is a preview.
   *
   * @param previewing - The previewing status to set.
   */
  setPreviewing: (previewing: CallState['previewing']) => void;

  /**
   * Sets the current room.
   * @param room - The room to set.
   */
  setCurrentRoom: (
    room: CallState['currentRoom'] | ((prevState: CallState['currentRoom']) => CallState['currentRoom'])
  ) => void;

  /**
   * Sets the current breakout.
   * @param breakout - The breakout to set.
   */
  setCurrentBreakout: (breakout: CallState['currentBreakout']) => void;

  /**
   * Sets the current call channel.
   * @param channel - The call channel to set.
   */
  setCurrentChannel: (channel: CallState['currentChannel']) => void;

  /**
   * Sets the sessions.
   * @param sessions - The sessions to set.
   */
  setSessions: (
    sessions: CallState['sessions'] | ((prevState: CallState['sessions']) => CallState['sessions'])
  ) => void;

  /**
   * Sets the breakouts
   * @param breakouts - The breakouts to set.
   */
  setBreakouts: (
    breakouts: CallState['breakouts'] | ((prevState: CallState['breakouts']) => CallState['breakouts'])
  ) => void;

  /**
   * Sets whether the call is force-muted.
   * @param value - The value to set.
   */
  setIsCallForceMuted: (value: CallState['isCallForceMuted']) => void;

  /**
   * Sets whether the user is force-muted.
   * @param value - The value to set.
   */
  setIsUserForceMuted: (value: CallState['isUserForceMuted']) => void;

  /**
   * Sets the pinned participant.
   * @param value - The participant ID to set.
   */
  setPinned: (value: CallState['pinned']) => void;

  /**
   * Sets whether the user starts muted.
   * @param value - The value to set.
   */
  setStartMuted: (value: CallState['startMuted']) => void;

  /**
   * Sets whether calls are expected.
   * @param value - The value to set.
   */
  setExpectCalls: (value: CallState['expectCalls']) => void;

  /**
   * Sets whether the provider is ready.
   * @param value - The value to set.
   */
  setReady: (value: CallState['ready']) => void;

  /**
   * Sets the status of the call.
   * @param value - The status to set.
   */
  setStatus: (status: CallState['status'] | ((prevState: CallState['status']) => CallState['status'])) => void;

  /**
   * Sets the transitioning state.
   * @param value - The value to set.
   */
  setTransitioning: (value: CallState['transitioning']) => void;
}

/**
 * Interface representing the call store, combining state and actions.
 */
export interface CallStore extends CallState, CallActions {}

/**
 * Type representing call error types.
 */
export type CallErrorType =
  | 'already-transitioning'
  | 'presence-fail'
  | 'bad-access-token'
  | 'join-fail'
  | 'invalid-channel'
  | 'malformed-room'
  | 'malformed-breakout'
  | 'page-not-expecting-calls'
  | 'unauthorized'
  | 'same-call';

/**
 * Custom error class for call-related errors.
 */
export class CallError extends Error {
  /**
   * The type of call error.
   */
  public readonly errorType: CallErrorType;

  public readonly leaveCall: boolean;

  constructor(errorType: CallErrorType, message?: string, leaveCall = true) {
    super(message);

    this.errorType = errorType;
    this.leaveCall = leaveCall;
  }
}
