import { AnimatePresence } from 'framer-motion';
import { type JSX, type ReactNode, useCallback } from 'react';
import { useMapState } from 'rooks';

import type { Callback } from '../../../core/types/react';
import { createStrictContext } from '../../../core/utils/react';
import { AlertContainer } from '../components/alert-container';
import { SimpleAlert } from '../components/simple-alert';
import type { ISimpleAlert } from '../types/simple-alert';

interface IAlertContext {
  showAlert: Callback<[ReactNode, () => void]>;
  showSimpleAlert: (toast: ISimpleAlert) => void;
  clearAlert: () => void;
}

const TOAST_TIMEOUT_IN_SECONDS = 10;

const [AlertContext, useAlert] = createStrictContext<IAlertContext>();

export function AlertProvider({ children }: any): JSX.Element {
  const [alerts, alertMapControls] = useMapState<
    Record<
      string,
      {
        content: ReactNode;
        onclose: () => void;
          }
          >,
    string
          >({});
  const { set, remove, removeAll } = alertMapControls;

  const showAlert = useCallback(
    (alert: ReactNode, onclose = () => {}) => {
      const identifier = `${Date.now()}-${Math.random() * 100}`;

      const timeout = setTimeout(() => {
        onclose();
        remove(identifier);
      }, TOAST_TIMEOUT_IN_SECONDS * 1000);

      const oncloseWithCleanup = (): void => {
        clearTimeout(timeout);
        onclose();
      };

      set(identifier, { content: alert, onclose: oncloseWithCleanup });
    },
    [remove, set]
  );

  const showSimpleAlert = useCallback(
    ({ content }: ISimpleAlert): void => {
      const AlertContent = <SimpleAlert content={content} />;
      showAlert(AlertContent);
    },
    [showAlert]
  );

  const contextValue = {
    showAlert,
    showSimpleAlert,
    clearAlert: removeAll,
  };

  const handleAlertClose = (identifier: string): void => {
    alerts[identifier]?.onclose();
    remove(identifier);
  };

  return (
    <AlertContext value={contextValue}>
      {children}

      <AnimatePresence>
        <AlertContainer
          className='fixed inset-x-0 top-[84px] z-max mx-auto w-full max-w-[440px]'
          alerts={alerts}
          onClose={handleAlertClose}
        />
      </AnimatePresence>
    </AlertContext>
  );
}

export { useAlert };
