import { motion } from 'framer-motion';
import { type JSX, type ReactNode, useEffect } from 'react';

import usePreviousNonNull from '@/client/core/hooks/use-previous';
import { Transitions } from '@/client/design-system/animations/framer-motion/transitions';
import { Icon } from '@/client/design-system/components/icon';
import { useToast } from '@/client/features/toast/providers/toast-provider';
import { useRouter } from '@/hooks/use-compatible-router';
import { useIsMounted } from '@/hooks/use-is-mounted';

interface Props {
  children: ReactNode;
  onClose?: () => void;
  clearOnRouteChange?: boolean;
}

export const Toast = ({ children, onClose, clearOnRouteChange = true, ...props }: Props): JSX.Element => {
  const { clearToast } = useToast();
  const router = useRouter();
  const mounted = useIsMounted();

  const previousPathName = usePreviousNonNull(router.pathname);

  useEffect(() => {
    if (!clearOnRouteChange) {
      return;
    }
    if (!mounted || previousPathName === router.pathname) {
      return;
    }
    clearToast();
  }, [clearOnRouteChange, clearToast, mounted, previousPathName, router.pathname]);

  return (
    <div {...props} className='group/toast relative box-border'>
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 20 }}
        transition={Transitions.Spring}
      >
        {children}

        <button
          className='absolute right-3 top-3 size-4 text-secondary hover:text-primary'
          onClick={onClose}
          aria-label='Close'
        >
          <Icon icon='close' size={16} />
        </button>
      </motion.div>
    </div>
  );
};
