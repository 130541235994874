// @ts-nocheck
import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { useQuery, useInfiniteQuery, useMutation, type UseQueryOptions, type UseInfiniteQueryOptions, type UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetChannelUserVolumeQueryVariables = Types.Exact<{
  channelId: Types.Scalars['uuid']['input'];
  userId: Types.Scalars['String']['input'];
}>;


export type GetChannelUserVolumeQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly channels_users: ReadonlyArray<(
    { readonly __typename?: 'channels_users' }
    & Pick<Types.Channels_Users, 'id' | 'volume'>
  )> }
);

export type GetChannelUserHandRaisedQueryVariables = Types.Exact<{
  channelId: Types.Scalars['uuid']['input'];
  userId: Types.Scalars['String']['input'];
}>;


export type GetChannelUserHandRaisedQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly channels_users: ReadonlyArray<(
    { readonly __typename?: 'channels_users' }
    & Pick<Types.Channels_Users, 'hand_raised'>
  )> }
);

export type UpdateChannelUserHandRaisedMutationVariables = Types.Exact<{
  channelId: Types.Scalars['uuid']['input'];
  userId: Types.Scalars['String']['input'];
  handRaised: Types.Scalars['Boolean']['input'];
}>;


export type UpdateChannelUserHandRaisedMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly updateHandRaised?: Types.Maybe<(
    { readonly __typename?: 'UpdateHandRaisedOutput' }
    & Pick<Types.UpdateHandRaisedOutput, 'updated'>
  )> }
);

export type UpdateChannelsUserForceMutedMutationVariables = Types.Exact<{
  channelId: Types.Scalars['uuid']['input'];
  userId: Types.Scalars['String']['input'];
  forceMuted: Types.Scalars['Boolean']['input'];
}>;


export type UpdateChannelsUserForceMutedMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly updateChannelsUserForceMuted?: Types.Maybe<(
    { readonly __typename?: 'UpdateChannelsUserForceMutedOutput' }
    & Pick<Types.UpdateChannelsUserForceMutedOutput, 'success'>
  )> }
);

export type UpdateChannelsUserConnectionMutationVariables = Types.Exact<{
  channelId: Types.Scalars['uuid']['input'];
  userId: Types.Scalars['String']['input'];
  connection: Types.Scalars['String']['input'];
}>;


export type UpdateChannelsUserConnectionMutation = (
  { readonly __typename?: 'mutation_root' }
  & { readonly update_channels_users?: Types.Maybe<(
    { readonly __typename?: 'channels_users_mutation_response' }
    & Pick<Types.Channels_Users_Mutation_Response, 'affected_rows'>
  )> }
);



export const GetChannelUserVolumeDocument = `
    query GetChannelUserVolume($channelId: uuid!, $userId: String!) {
  channels_users(
    where: {channel_id: {_eq: $channelId}, user_id: {_eq: $userId}, status: {_eq: active}}
  ) {
    id
    volume
  }
}
    `;

export const useGetChannelUserVolumeQuery = <
      TData = GetChannelUserVolumeQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetChannelUserVolumeQueryVariables,
      options?: UseQueryOptions<GetChannelUserVolumeQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetChannelUserVolumeQuery, TError, TData>(
      ['GetChannelUserVolume', variables],
      fetcher<GetChannelUserVolumeQuery, GetChannelUserVolumeQueryVariables>(client, GetChannelUserVolumeDocument, variables, headers),
      options
    )};

useGetChannelUserVolumeQuery.getKey = (variables: GetChannelUserVolumeQueryVariables) => ['GetChannelUserVolume', variables];

export const useInfiniteGetChannelUserVolumeQuery = <
      TData = GetChannelUserVolumeQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetChannelUserVolumeQueryVariables,
      options?: UseInfiniteQueryOptions<GetChannelUserVolumeQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetChannelUserVolumeQuery, TError, TData>(
      ['GetChannelUserVolume.infinite', variables],
      (metaData) => fetcher<GetChannelUserVolumeQuery, GetChannelUserVolumeQueryVariables>(client, GetChannelUserVolumeDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetChannelUserVolumeQuery.getKey = (variables: GetChannelUserVolumeQueryVariables) => ['GetChannelUserVolume.infinite', variables];


useGetChannelUserVolumeQuery.fetcher = (client: GraphQLClient, variables: GetChannelUserVolumeQueryVariables, headers?: RequestInit['headers']) => fetcher<GetChannelUserVolumeQuery, GetChannelUserVolumeQueryVariables>(client, GetChannelUserVolumeDocument, variables, headers);

export const GetChannelUserHandRaisedDocument = `
    query GetChannelUserHandRaised($channelId: uuid!, $userId: String!) {
  channels_users(
    where: {channel_id: {_eq: $channelId}, user_id: {_eq: $userId}, status: {_eq: active}}
  ) {
    hand_raised
  }
}
    `;

export const useGetChannelUserHandRaisedQuery = <
      TData = GetChannelUserHandRaisedQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetChannelUserHandRaisedQueryVariables,
      options?: UseQueryOptions<GetChannelUserHandRaisedQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetChannelUserHandRaisedQuery, TError, TData>(
      ['GetChannelUserHandRaised', variables],
      fetcher<GetChannelUserHandRaisedQuery, GetChannelUserHandRaisedQueryVariables>(client, GetChannelUserHandRaisedDocument, variables, headers),
      options
    )};

useGetChannelUserHandRaisedQuery.getKey = (variables: GetChannelUserHandRaisedQueryVariables) => ['GetChannelUserHandRaised', variables];

export const useInfiniteGetChannelUserHandRaisedQuery = <
      TData = GetChannelUserHandRaisedQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetChannelUserHandRaisedQueryVariables,
      options?: UseInfiniteQueryOptions<GetChannelUserHandRaisedQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetChannelUserHandRaisedQuery, TError, TData>(
      ['GetChannelUserHandRaised.infinite', variables],
      (metaData) => fetcher<GetChannelUserHandRaisedQuery, GetChannelUserHandRaisedQueryVariables>(client, GetChannelUserHandRaisedDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetChannelUserHandRaisedQuery.getKey = (variables: GetChannelUserHandRaisedQueryVariables) => ['GetChannelUserHandRaised.infinite', variables];


useGetChannelUserHandRaisedQuery.fetcher = (client: GraphQLClient, variables: GetChannelUserHandRaisedQueryVariables, headers?: RequestInit['headers']) => fetcher<GetChannelUserHandRaisedQuery, GetChannelUserHandRaisedQueryVariables>(client, GetChannelUserHandRaisedDocument, variables, headers);

export const UpdateChannelUserHandRaisedDocument = `
    mutation UpdateChannelUserHandRaised($channelId: uuid!, $userId: String!, $handRaised: Boolean!) {
  updateHandRaised(
    channelId: $channelId
    userId: $userId
    handRaised: $handRaised
  ) {
    updated
  }
}
    `;

export const useUpdateChannelUserHandRaisedMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateChannelUserHandRaisedMutation, TError, UpdateChannelUserHandRaisedMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateChannelUserHandRaisedMutation, TError, UpdateChannelUserHandRaisedMutationVariables, TContext>(
      ['UpdateChannelUserHandRaised'],
      (variables?: UpdateChannelUserHandRaisedMutationVariables) => fetcher<UpdateChannelUserHandRaisedMutation, UpdateChannelUserHandRaisedMutationVariables>(client, UpdateChannelUserHandRaisedDocument, variables, headers)(),
      options
    )};

useUpdateChannelUserHandRaisedMutation.getKey = () => ['UpdateChannelUserHandRaised'];


useUpdateChannelUserHandRaisedMutation.fetcher = (client: GraphQLClient, variables: UpdateChannelUserHandRaisedMutationVariables, headers?: RequestInit['headers']) => fetcher<UpdateChannelUserHandRaisedMutation, UpdateChannelUserHandRaisedMutationVariables>(client, UpdateChannelUserHandRaisedDocument, variables, headers);

export const UpdateChannelsUserForceMutedDocument = `
    mutation UpdateChannelsUserForceMuted($channelId: uuid!, $userId: String!, $forceMuted: Boolean!) {
  updateChannelsUserForceMuted(
    channelId: $channelId
    userId: $userId
    forceMuted: $forceMuted
  ) {
    success
  }
}
    `;

export const useUpdateChannelsUserForceMutedMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateChannelsUserForceMutedMutation, TError, UpdateChannelsUserForceMutedMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateChannelsUserForceMutedMutation, TError, UpdateChannelsUserForceMutedMutationVariables, TContext>(
      ['UpdateChannelsUserForceMuted'],
      (variables?: UpdateChannelsUserForceMutedMutationVariables) => fetcher<UpdateChannelsUserForceMutedMutation, UpdateChannelsUserForceMutedMutationVariables>(client, UpdateChannelsUserForceMutedDocument, variables, headers)(),
      options
    )};

useUpdateChannelsUserForceMutedMutation.getKey = () => ['UpdateChannelsUserForceMuted'];


useUpdateChannelsUserForceMutedMutation.fetcher = (client: GraphQLClient, variables: UpdateChannelsUserForceMutedMutationVariables, headers?: RequestInit['headers']) => fetcher<UpdateChannelsUserForceMutedMutation, UpdateChannelsUserForceMutedMutationVariables>(client, UpdateChannelsUserForceMutedDocument, variables, headers);

export const UpdateChannelsUserConnectionDocument = `
    mutation UpdateChannelsUserConnection($channelId: uuid!, $userId: String!, $connection: String!) {
  update_channels_users(
    where: {channel_id: {_eq: $channelId}, user_id: {_eq: $userId}}
    _set: {connection: $connection}
  ) {
    affected_rows
  }
}
    `;

export const useUpdateChannelsUserConnectionMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateChannelsUserConnectionMutation, TError, UpdateChannelsUserConnectionMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateChannelsUserConnectionMutation, TError, UpdateChannelsUserConnectionMutationVariables, TContext>(
      ['UpdateChannelsUserConnection'],
      (variables?: UpdateChannelsUserConnectionMutationVariables) => fetcher<UpdateChannelsUserConnectionMutation, UpdateChannelsUserConnectionMutationVariables>(client, UpdateChannelsUserConnectionDocument, variables, headers)(),
      options
    )};

useUpdateChannelsUserConnectionMutation.getKey = () => ['UpdateChannelsUserConnection'];


useUpdateChannelsUserConnectionMutation.fetcher = (client: GraphQLClient, variables: UpdateChannelsUserConnectionMutationVariables, headers?: RequestInit['headers']) => fetcher<UpdateChannelsUserConnectionMutation, UpdateChannelsUserConnectionMutationVariables>(client, UpdateChannelsUserConnectionDocument, variables, headers);
