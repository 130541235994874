import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone); // Dependent on utc

export { dayjs };

export const NamedDays = Object.freeze(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']);
export const NamedMonths = Object.freeze([
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]);
export const AbbreviatedMonths = Object.freeze([
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]);

export const DefaultTimezone = 'America/Chicago';

interface FormatDateOptions {
  /**
   * Format relative dates according to timezone. null is the local timezone. Default: Local timezone.
   */
  timezone?: null | string;
}

interface FormatRelativeDateOptions extends FormatDateOptions {
  /**
   * Use 3 letter month abbreviation. Default: false
   */
  abbreviateMonth?: boolean;
}

export const formatRelativeDate = (
  date: number | string | Date,
  relativeToDate: number | string | Date,
  options?: FormatRelativeDateOptions
) => {
  let day = dayjs(date);
  let relativeToDay = dayjs(relativeToDate);

  if (options?.timezone) {
    day = day.tz(options.timezone);
    relativeToDay = relativeToDay.tz(options.timezone);
  }

  const startOfDay = day.startOf('day');
  const relativeStartOfDay = relativeToDay.startOf('day');

  if (startOfDay.isSame(relativeStartOfDay)) {
    return 'today';
  }

  const pastDate = startOfDay.isBefore(relativeStartOfDay);

  if (pastDate) {
    const relativeStartOfYesterday = relativeStartOfDay.add(-1, 'day');

    if (startOfDay.isSame(relativeStartOfYesterday)) {
      return 'yesterday';
    }
  } else {
    const relativeStartOfTomorrow = relativeStartOfDay.add(1, 'day');

    if (startOfDay.isSame(relativeStartOfTomorrow)) {
      return 'tomorrow';
    }

    const relativeNextWeek = relativeStartOfDay.add(7, 'days');

    if (startOfDay.isBefore(relativeNextWeek)) {
      return NamedDays[startOfDay.day()];
    }
  }

  const namedMonths = options?.abbreviateMonth ? AbbreviatedMonths : NamedMonths;
  const namedMonth = namedMonths[startOfDay.month()];

  const relativeThreeMonthsAgo = relativeStartOfDay.add(-3, 'months');
  const relative6MonthsAway = relativeStartOfDay.add(6, 'months');

  if (startOfDay.isAfter(relativeThreeMonthsAgo) && startOfDay.isBefore(relative6MonthsAway)) {
    return `${namedMonth} ${startOfDay.date()}`;
  }

  return `${namedMonth} ${startOfDay.date()}, ${startOfDay.year()}`;
};

/**
 * Values are relative offsets in milliseconds e.g. within the last minute would be { min: -60000, max: 0 }
 */
interface RelativeTimeRange {
  min: number;
  minInclusive: boolean;
  max: number;
  maxInclusive: boolean;
}

export const withinRelativeTimeRange = (
  time: number,
  relativeToTime: number,
  { min, minInclusive, max, maxInclusive }: RelativeTimeRange
) => {
  const difference = time - relativeToTime;
  return (minInclusive ? difference >= min : difference > min) && (maxInclusive ? difference <= max : difference < max);
};

interface FormatRelativeTimeOptions extends FormatRelativeDateOptions {
  /**
   * Use a single letter abbreviation for time components e.g. 1h 4m. Also omits "and" and commas. Default: false
   */
  abbreviateSentenceTimeComponents?: boolean;
  /**
   * When formatting as a sentence, omit components with the value zero from the formatted string. For example, if
   * enabled and maximumSentenceComponents is 2, then the relative time 3 hours ago, will simply render as "3 hours".
   * Default: true
   */
  omitSubsequentZeroSentenceComponents?: boolean;
  /**
   * When formatting as a sentence, the maximum number of components to include. When components are omitted due to
   * omitSubsequentZeroSentenceComponents, the omitted components still count toward this limit. For example, with
   * omitSubsequentZeroSentenceComponents enabled and a maximumSentenceComponents of 2, the relative time is 1 hour and
   * 12 seconds ago, will just render as "1 hour" i.e. "0 minutes" was omitted and "12 seconds" did not render.
   * Default: 2
   */
  maximumSentenceComponents?: number;
  /**
   * Dates falling within recentTimeRange will be formatted as this string. To disable this entirely, set
   * recentTimeRange as null. Default: "soon".
   */
  recentString?: string;
  /**
   * Dates falling within this time range will be formatted as recentString. To disable, use null. Default: [-60000, 0)
   */
  recentTimeRange?: null | RelativeTimeRange;
  /**
   * When non-null, dates inside the range will be formatted as a sentence e.g. 1 hour and 13 minutes. The components
   * will not be a negative — to discern if the relative time refers to the past, inspect the returned 'past' property.
   * Default: (-21600000, 21600000)
   */
  sentenceTimeRange?: null | RelativeTimeRange;
}

export enum RelativeTimeFormat {
  Recent,
  Sentence,
  Time,
  TimeAndDate,
}

export interface FormattedRelativeTime {
  format: RelativeTimeFormat;
  formatted: string;
  past: boolean; // If the relative date is in the past.
}

export const NamedTimeComponents = ['day', 'hour', 'minute', 'second'];

export const AbbreviatedTimeComponents = ['d', 'h', 'm', 's'];

export const formatRelativeTime = (
  date: number | string | Date,
  relativeToDate: number | string | Date,
  options: FormatRelativeTimeOptions = {}
): FormattedRelativeTime => {
  let day = dayjs(date);
  let relativeToDay = dayjs(relativeToDate);

  const past = date.valueOf() < relativeToDate.valueOf();

  const {
    abbreviateSentenceTimeComponents = false,
    maximumSentenceComponents = 2,
    omitSubsequentZeroSentenceComponents = true,
    recentString = 'soon',
    recentTimeRange = {
      min: -60000,
      minInclusive: false,
      max: 0,
      maxInclusive: true,
    },
    sentenceTimeRange = {
      min: -21600000, // 6 hours = 1000*60*60*6 = 21600000
      minInclusive: true,
      max: 21600000,
      maxInclusive: true,
    },
    timezone,
  } = options;

  if (recentTimeRange && withinRelativeTimeRange(day.valueOf(), relativeToDay.valueOf(), recentTimeRange)) {
    return {
      format: RelativeTimeFormat.Recent,
      formatted: recentString,
      past,
    };
  }

  if (timezone) {
    day = day.tz(timezone);
    relativeToDay = relativeToDay.tz(timezone);
  }

  if (sentenceTimeRange && withinRelativeTimeRange(day.valueOf(), relativeToDay.valueOf(), sentenceTimeRange)) {
    const days = Math.abs(day.diff(relativeToDay, 'days'));
    const hours = Math.abs(day.diff(relativeToDay, 'hours') % 24);
    const minutes = Math.abs(day.diff(relativeToDay, 'minutes') % 60);
    const seconds = Math.abs(day.diff(relativeToDay, 'seconds') % 60);

    const components = [days, hours, minutes, seconds];
    let usedComponentCount = 0;

    const componentNames = abbreviateSentenceTimeComponents ? AbbreviatedTimeComponents : NamedTimeComponents;
    const componentStrings: string[] = [];

    for (let i = 0; i < components.length; i++) {
      const value = components[i]!;

      if (value > 0 || (componentStrings.length > 0 && !omitSubsequentZeroSentenceComponents)) {
        const name = componentNames[i];
        componentStrings.push(value === 1 ? `1 ${name}` : `${value} ${name}s`);
      }

      if (componentStrings.length > 0 && ++usedComponentCount >= maximumSentenceComponents) {
        break;
      }
    }

    if (componentStrings.length === 0) {
      componentStrings.push(`0 ${componentNames[componentNames.length - 1]}s`);
    }

    const formatted = [...componentStrings.slice(0, -2), componentStrings.slice(-2).join(' and ')].join(', ');

    return {
      format: RelativeTimeFormat.Sentence,
      formatted,
      past,
    };
  }

  const isToday = day.startOf('day').isSame(relativeToDay.startOf('day'));
  const timeString = day.format('h:mm A');

  if (isToday) {
    return {
      format: RelativeTimeFormat.Time,
      formatted: timeString,
      past,
    };
  }

  return {
    format: RelativeTimeFormat.TimeAndDate,
    formatted: `${timeString} ${formatRelativeDate(date, relativeToDate, options)}`,
    past,
  };
};
