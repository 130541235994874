import Head from 'next/head';
import { useEffect, useState } from 'react';

import { DEFAULT_META_TAG_IMAGE, ROUTES } from '../lib/constants';

function Meta({
  tabTitle = 'Ender',
  pageTitle = 'Ender',
  description = 'After school clubs for Kids.',
  image = DEFAULT_META_TAG_IMAGE,
}) {
  const [hostName, setHostName] = useState<string>();

  if (image === undefined || image === null) {
    image = DEFAULT_META_TAG_IMAGE;
  }

  useEffect(() => {
    setHostName((location.host.indexOf('www.') && location.host) || location.host.replace('www.', ''));
  }, []);

  return (
    <Head>
      <title key='tabTitle'>{tabTitle}</title>
      <link rel='icon' href='/favicon.svg' />
      <meta name='viewport' content='width=device-width, initial-scale=1' key='viewport' />

      {/* Primary Meta Tags */}
      {/* <meta name='title' content={pageTitle} key={'pageTitle'} /> */}
      <meta name='description' content={description} key='description' />

      {/* Open Graph / Facebook */}
      <meta property='og:type' content='website' key='og-type' />
      <meta property='og:url' content={hostName} key='og-url' />
      {/* <meta property='og:title' content={pageTitle} key={'og-title'} /> */}
      <meta property='og:description' content={description} key='og-description' />
      <meta property='og:image' content={image} key='og-image' />
      <link type='application/json+oembed' href={ROUTES.oembed} />

      {/* Twitter */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta property='twitter:card' content={image} key='twitter-card' />
      <meta property='twitter:url' content={hostName} key='twitter-url' />
      <meta property='twitter:title' content={pageTitle} key='twitter-title' />
      <meta property='twitter:description' content={description} key='twitter-description' />
      <meta property='twitter:image' content={image} key='twitter-image' />
    </Head>
  );
}

export default Meta;
