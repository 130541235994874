'use client';

import React, { createContext, useContext, useRef } from 'react';
import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';

import type { RoomDetailFragment } from '@/client/api/rooms/generated/operations.user';
import type { Breakout } from '@/client/features/breakouts/types/rooms-breakouts-context';
import type { CallChannel } from '@/client/features/calls/operations/get-call-channel';

import type { CallState, CallStore } from './call-store.types';

export const createCallStore = (initialState?: Partial<CallState>) =>
  createStore<CallStore>((set) => ({
    currentRoom: undefined,
    currentRoomSection: undefined,
    currentBreakout: undefined,
    currentChannel: undefined,
    roomSections: [],
    sessions: [],
    breakouts: {},
    isCallForceMuted: undefined,
    isUserForceMuted: undefined,
    pinned: null,
    startMuted: false,
    expectCalls: true,
    ready: false,
    status: 'inactive',
    transitioning: null,
    previewing: false,
    initialAction: undefined,
    setCurrentRoom: (room) => {
      if (typeof room === 'function') {
        set((state) => ({ currentRoom: room(state.currentRoom) }));
      } else {
        set({ currentRoom: room });
      }
    },
    setCurrentBreakout: (breakout) => {
      set({ currentBreakout: breakout });
    },
    setCurrentChannel: (channel) => {
      set({ currentChannel: channel });
    },
    setBreakouts: (breakouts) => {
      if (typeof breakouts === 'function') {
        set((state) => ({ breakouts: breakouts(state.breakouts) }));
      } else {
        set({ breakouts });
      }
    },
    setSessions: (sessions) => {
      if (typeof sessions === 'function') {
        set((state) => ({ sessions: sessions(state.sessions) }));
      } else {
        set({ sessions });
      }
    },
    setIsCallForceMuted: (value) => {
      set({ isCallForceMuted: value });
    },
    setIsUserForceMuted: (value) => {
      set({ isUserForceMuted: value });
    },
    setPinned: (value) => {
      set({ pinned: value });
    },
    setStartMuted: (value) => {
      set({ startMuted: value });
    },
    setExpectCalls: (value) => {
      set({ expectCalls: value });
    },
    setReady: (value) => {
      set({ ready: value });
    },
    setStatus: (value) => {
      if (typeof value === 'function') {
        set((state) => ({ status: value(state.status) }));
      } else {
        set({ status: value });
      }
    },
    setTransitioning: (transitioning) => {
      set({ transitioning });
    },
    setPreviewing: (previewing) => {
      set({ previewing });
    },
    ...initialState,
  }));

export type CallStoreApi = ReturnType<typeof createCallStore>;

export const CallStoreContext = createContext<CallStoreApi | undefined>(undefined);

export interface CallStoreProviderProps {
  children: React.ReactNode;
  room?: RoomDetailFragment;
  breakout?: Breakout;
  channel?: CallChannel;
  expectCalls?: boolean | 'preview-only';
  initialAction?: { type: 'join-call' } | { type: 'enter-preview'; previewRoomId: string };
}

export const CallStoreProvider = ({
  children,
  expectCalls = true,
  initialAction,
  ...props
}: CallStoreProviderProps) => {
  const storeRef = useRef<CallStoreApi>(undefined);

  if (!storeRef.current) {
    const initialData: Partial<CallState> = {
      currentRoom: props.room,
      currentBreakout: props.breakout,
      currentChannel: props.channel,
      expectCalls,
      initialAction,
    };

    storeRef.current = createCallStore(initialData);
  }
  return <CallStoreContext.Provider value={storeRef.current}>{children}</CallStoreContext.Provider>;
};

export const useCallStore = <T,>(selector: (state: CallStore) => T): T => {
  const store = useContext(CallStoreContext);

  if (!store) {
    throw new Error('useCallStore must be used within a CallStoreProvider');
  }
  return useStore(store, selector);
};
