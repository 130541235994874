import * as DialogPrimitive from '@radix-ui/react-dialog';
import styled from 'styled-components';

export const StyledOverlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 90;
`;

export const StyledContent = styled(DialogPrimitive.Content)`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 90;
  transform: translate(-50%, -50%);
  background-color: #101010;
  border: 1px solid #1e1e1e;
  border-radius: 2px;

  &:focus {
    outline: none;
  }
`;

export const StyledClose = styled(DialogPrimitive.Close)`
  position: fixed;
  z-index: 1;
  top: 16px;
  right: 16px;

  svg {
    stroke: #7d7d7d;
    transition: stroke 0.1s ease-in-out;
  }

  &:hover {
    svg {
      stroke: #ffffff;
    }
  }
`;
