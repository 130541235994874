'use client';

import { useEffect, useMemo, useState } from 'react';

import hasuraGraphQLClient from '@/shared/graphql/client';
import type { UsersPresenceMeta } from '@/shared/graphql/scalars';

import { useUserPresence } from '../use-user-presence';
import {
  useCompetitionNameForActivityQuery,
  useUserCreationDateForActivityQuery,
} from './generated/use-user-activity.user';
import { getPresenceStatusString, type StatusStringData } from './use-user-activity.utils';

interface UserActivityOutput {
  activity: string;
  competition?: {
    id: string;
    name: string;
  };
}

interface UseUserActivityProps {
  userId: string;
  presence?: UsersPresenceMeta;
}

export const useUserActivity = ({ userId, presence }: UseUserActivityProps): UserActivityOutput => {
  const { web, loading } = useUserPresence(userId);
  const minecraft = presence?.minecraft;
  const [activity, setActivity] = useState<StatusStringData>({
    string: '',
  });

  const { data } = useCompetitionNameForActivityQuery(
    hasuraGraphQLClient.Client,
    {
      competitionId: activity.competitionId!,
    },
    {
      enabled: !!activity.competitionId,
    }
  );
  const { data: userData } = useUserCreationDateForActivityQuery(hasuraGraphQLClient.Client, {
    userId,
  });

  useEffect(() => {
    if (!loading && userData?.users_by_pk?.created_at != null) {
      const a = getPresenceStatusString({
        web,
        minecraft,
        accountCreated: userData.users_by_pk?.created_at,
      });

      setActivity(a);
    }
  }, [data, loading, minecraft, userData?.users_by_pk?.created_at, web]);

  return useMemo(
    () => ({
      activity: activity.string,
      competition: data?.competitions_by_pk?.name
        ? {
          id: activity.competitionId!,
          name: data.competitions_by_pk.name,
        }
        : undefined,
    }),
    [activity.competitionId, activity.string, data?.competitions_by_pk?.name]
  );
};
