// @ts-nocheck
import type * as Types from '@/generated/graphql/global-types.user';

import type { GraphQLClient } from 'graphql-request';
import { ChannelAssociatedObjectsFragmentDoc } from '../../../channels/associated-objects/generated/channel-associated-objects.user';
import { useQuery, useInfiniteQuery, type UseQueryOptions, type UseInfiniteQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetCallChannelQueryVariables = Types.Exact<{
  channelId: Types.Scalars['uuid']['input'];
}>;


export type GetCallChannelQuery = (
  { readonly __typename?: 'query_root' }
  & { readonly channels_by_pk?: Types.Maybe<(
    { readonly __typename?: 'channels' }
    & Pick<Types.Channels, 'id' | 'frozen' | 'name' | 'type' | 'mute' | 'sticky'>
    & { readonly channels_users: ReadonlyArray<(
      { readonly __typename?: 'channels_users' }
      & Pick<Types.Channels_Users, 'user_id' | 'volume' | 'hand_raised'>
    )>, readonly member_count: (
      { readonly __typename?: 'channels_users_aggregate' }
      & { readonly aggregate?: Types.Maybe<(
        { readonly __typename?: 'channels_users_aggregate_fields' }
        & Pick<Types.Channels_Users_Aggregate_Fields, 'count'>
      )> }
    ), readonly breakout?: Types.Maybe<(
      { readonly __typename?: 'breakouts' }
      & Pick<Types.Breakouts, 'association_id' | 'id' | 'name' | 'type'>
      & { readonly room?: Types.Maybe<(
        { readonly __typename?: 'rooms' }
        & Pick<Types.Rooms, 'kind'>
      )> }
    )>, readonly room?: Types.Maybe<(
      { readonly __typename?: 'rooms' }
      & Pick<Types.Rooms, 'association_id' | 'feedback_question' | 'id' | 'kind' | 'name' | 'status' | 'url_name'>
      & { readonly club: (
        { readonly __typename?: 'clubs' }
        & Pick<Types.Clubs, 'url_name'>
      ) }
    )> }
  )> }
);



export const GetCallChannelDocument = `
    query GetCallChannel($channelId: uuid!) {
  channels_by_pk(id: $channelId) {
    id
    frozen
    name
    type
    mute
    sticky
    ...ChannelAssociatedObjects
    channels_users(where: {status: {_eq: active}}) {
      user_id
      volume
      hand_raised
    }
    member_count: channels_users_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${ChannelAssociatedObjectsFragmentDoc}`;

export const useGetCallChannelQuery = <
      TData = GetCallChannelQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetCallChannelQueryVariables,
      options?: UseQueryOptions<GetCallChannelQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetCallChannelQuery, TError, TData>(
      ['GetCallChannel', variables],
      fetcher<GetCallChannelQuery, GetCallChannelQueryVariables>(client, GetCallChannelDocument, variables, headers),
      options
    )};

useGetCallChannelQuery.getKey = (variables: GetCallChannelQueryVariables) => ['GetCallChannel', variables];

export const useInfiniteGetCallChannelQuery = <
      TData = GetCallChannelQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetCallChannelQueryVariables,
      options?: UseInfiniteQueryOptions<GetCallChannelQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) => {
    
    return useInfiniteQuery<GetCallChannelQuery, TError, TData>(
      ['GetCallChannel.infinite', variables],
      (metaData) => fetcher<GetCallChannelQuery, GetCallChannelQueryVariables>(client, GetCallChannelDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    )};

useInfiniteGetCallChannelQuery.getKey = (variables: GetCallChannelQueryVariables) => ['GetCallChannel.infinite', variables];


useGetCallChannelQuery.fetcher = (client: GraphQLClient, variables: GetCallChannelQueryVariables, headers?: RequestInit['headers']) => fetcher<GetCallChannelQuery, GetCallChannelQueryVariables>(client, GetCallChannelDocument, variables, headers);
