import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { IntercomProvider as ReactUseIntercomProvider } from 'react-use-intercom';

if (!process.env.NEXT_PUBLIC_INTERCOM_APP_ID) {
  throw new Error('NEXT_PUBLIC_INTERCOM_APP_ID environment variable must be set');
}

const intercomAppId = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;

function isDiv(node: Node): node is HTMLDivElement {
  return node.nodeName === 'DIV';
}

function isIframe(node: Node): node is HTMLIFrameElement {
  return node.nodeName === 'IFRAME';
}

function insertIframeColorScheme(node: Node) {
  if (isDiv(node)) {
    if (node.className.includes('intercom')) {
      for (const childNode of node.childNodes) {
        insertIframeColorScheme(childNode);
      }
    }

    return;
  }

  if (!isIframe(node) || !node.getAttribute('data-intercom-frame') || !node.contentWindow) {
    return;
  }

  const { document } = node.contentWindow;
  const colorSchemeMeta = document.createElement('meta');
  colorSchemeMeta.setAttribute('name', 'color-scheme');
  colorSchemeMeta.setAttribute('content', 'light dark');
  document.querySelector('head')?.appendChild(colorSchemeMeta);
}

export const IntercomProvider = ({ children }: PropsWithChildren) => {
  useEffect(() => {
    // So... Our page has light/dark mode styling enabled. Intercom's inserted iframes do not. Turns out if a child
    // iframe does not have this turned on whilst the parent page does, then the iframe background color is forced to
    // white. No amount of !important styles will make it transparent again. Only solution is this monstrosity...
    const iframeObserver = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        for (const addedNode of mutation.addedNodes) {
          insertIframeColorScheme(addedNode);
        }
      }
    });

    iframeObserver.observe(document.body, {
      attributes: false,
      characterData: false,
      childList: true,
      subtree: true,
      attributeOldValue: false,
      characterDataOldValue: false,
    });

    return () => {
      iframeObserver.disconnect();
    };
  }, []);

  return <ReactUseIntercomProvider appId={intercomAppId}>{children}</ReactUseIntercomProvider>;
};
