export interface UsersPresenceMeta {
  web?: UsersPresenceMetaWeb;
  minecraft?: UsersPresenceMetaMinecraft;
}

export interface UsersPresenceMetaMinecraft {
  online: boolean;
  location?: UsersPresenceMetaMinecraftLocation;
  activity?: UsersPresenceMetaMinecraftActivity;
  competition_id?: string;
  server_name?: string;
  updated_at: string;
}

export interface UsersPresenceMetaWeb {
  state?: UsersPresenceMetaWebState;
  updatedAt?: number;
}

export enum UsersPresenceMetaWebState {
  Online = 'online',
  Idle = 'idle',
  Offline = 'offline',
}

type UsersPresenceMetaMinecraftLocation = 'offline' | 'lobby' | 'competition';
type UsersPresenceMetaMinecraftActivity =
  | 'competition-building'
  | 'competition-browsing'
  | 'competition-tutorial'
  | 'lobby-idle';
