import {
  type DetailedHTMLProps,
  type FocusEventHandler,
  forwardRef,
  type SelectHTMLAttributes,
  useCallback,
  useState,
} from 'react';

import { FloatingLabel } from '@/client/design-system/components/fields/components/floating-label';
import { InputContainer } from '@/client/design-system/components/fields/components/input-container';

import { cn } from '../../utils/tailwind';

interface SelectOption {
  name: string;
  value: string;
}

export interface SelectInputProps
  extends DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  checkmark?: boolean;
  error?: null | string;
  hideError?: boolean;
  label?: string;
  options: SelectOption[];
  thin?: boolean;
  tooltip?: string;
}

export const SelectInput = forwardRef<HTMLSelectElement, SelectInputProps>(function SelectInput(props, ref) {
  const {
    checkmark,
    className,
    disabled = false,
    error,
    hideError = false,
    id,
    label,
    onBlur,
    onFocus,
    options,
    thin = false,
    tooltip,
    ...selectProps
  } = props;

  const [focus, setFocus] = useState(false);

  const innerOnFocus: FocusEventHandler<HTMLSelectElement> = useCallback(
    (event) => {
      setFocus(true);
      onFocus?.(event);
    },
    [onFocus]
  );

  const innerOnBlur: FocusEventHandler<HTMLSelectElement> = useCallback(
    (event) => {
      setFocus(false);
      onBlur?.(event);
    },
    [onBlur]
  );

  return (
    <InputContainer
      id={id}
      checkmark={checkmark}
      tooltip={tooltip}
      hideError={hideError}
      error={error}
      className={className}
    >
      {!thin && label && <FloatingLabel label={label} focus={focus} hasError={!!error} small />}
      <select
        {...selectProps}
        className={cn(
          'w-full rounded-sm border bg-dark-grey-1 px-3 text-sm font-medium text-white outline-none transition-colors duration-100 placeholder:text-transparent disabled:opacity-50',
          !thin ? 'pt-4' : 'pt-0',
          !error && !focus && !disabled && 'group-hover:border-disabled-1',
          error ? 'border-accent-red/40' : 'border-disabled-2 focus:border-accent/40',
          thin ? 'h-10 placeholder:text-light-grey-4' : 'h-14'
        )}
        disabled={disabled}
        onFocus={innerOnFocus}
        onBlur={innerOnBlur}
        ref={ref}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
    </InputContainer>
  );
});
